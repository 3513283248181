import { useEffect, useState } from "react";
import { ITodoListItem } from "../../components/extras/Todo";
import Card, { CardBody, CardHeader, CardLabel, CardSubTitle, CardTitle } from "../../components/bootstrap/Card";
import Progress from "../../components/bootstrap/Progress";
import Timeline, { TimelineItem } from "../../components/extras/Timeline";
import Button from "../../components/bootstrap/Button";
import PageWrapper from "../../layout/PageWrapper/PageWrapper";
import Page from "../../layout/Page/Page";
import Badge from "../../components/bootstrap/Badge";
import { toasts } from "../../services/toast.service";
import { checkDataAvailableOrNot } from "../../services/common.service";
import { getSchoolDetails } from "../../services/school.service";


const LetsStarted = () => {

    useEffect(() => {
        checkDataAvailable();
        getSchool();
    }, []);

    const [tableDataStatus, setTableDataStatus] = useState<any>([]);
    const [list, setList] = useState<ITodoListItem[]>([]);
    const [schoolDetailsData, setSchoolDetailsData] = useState<any>([]);

    function checkDataAvailable() {
        checkDataAvailableOrNot(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.checkDataAvailableOrNot;
                    if (data != undefined) {
                        setTableDataStatus(data)

                        let list = [{
                            id: 1,
                            status: data[0].getAcademicPeriodDataCount,
                        },
                        {
                            id: 2,
                            status: data[0].getBatchMasterDataCount,
                        },
                        {
                            id: 3,
                            status: data[0].getClassDetailsDataCount,
                        },
                        {
                            id: 4,
                            status: data[0].getClassCapacityDataCount,
                        },
                        {
                            id: 5,
                            status: data[0].getPeriodDetailsDataCount,
                        },
                        {
                            id: 6,
                            status: data[0].getDayOrderDataCount,
                        },
                        {
                            id: 7,
                            status: data[0].getFeeMasterDataCount,
                        },
                        {
                            id: 8,
                            status: data[0].getAdmissionFormFeeDataCount,
                        }]
                        setList(list)
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getSchool() {
        getSchoolDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.schoolDetails[0];
                    if (data != undefined) {
                        setSchoolDetailsData(data);
                    } else {
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    toasts(response.data.message, 'Error');
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, 'Error');
                }
            },
            (error) => {
                toasts(error, 'Error');
            },
        );
    }


    const listLength = list.length;
    const completeTaskLength = list.filter((i) => i.status == true).length;

    return (
        <PageWrapper title="Following steps to have">
            <Page container='fluid'>
                <div className="row">
                    <div className="col-1"></div>
                    <div className="col-10">
                        <Card stretch>
                            <CardHeader>
                                <CardLabel icon='PlaylistAddCheck' iconColor='warning'>
                                    <CardTitle tag='div' className='h5 '>
                                        Get started with <span className='text-danger'>{schoolDetailsData.schoolName}</span> !
                                    </CardTitle>

                                    <CardSubTitle tag='div'>
                                        Complete the following steps to have a hassle-free {schoolDetailsData.schoolName} experience.
                                        <div className='mt-1'>
                                            <Progress
                                                isAnimated
                                                height={8}
                                                max={listLength}
                                                value={completeTaskLength}
                                                color={completeTaskLength === listLength ? 'success' : 'primary'}
                                            />
                                        </div>
                                    </CardSubTitle>
                                </CardLabel>
                            </CardHeader>
                            <CardBody>
                                {tableDataStatus.map((data: any, index: any) => (
                                    <Timeline key={index}>
                                        <div className="row">
                                            <div className="col-8">
                                                <TimelineItem label='' color='primary'>
                                                    Add Academic Period details
                                                </TimelineItem>
                                            </div>
                                            <div className="col-2">
                                                {data.getAcademicPeriodDataCount ?
                                                    <Button
                                                        tag='a'
                                                        to={`../general/addAcademicPeriod`}
                                                        size='sm'
                                                        isLink
                                                        color='success'
                                                        icon='Report'
                                                        className='text-nowrap'>
                                                        Completed
                                                    </Button> :
                                                    <Button
                                                        tag='a'
                                                        to={`../general/addAcademicPeriod`}
                                                        size='sm'
                                                        isLink
                                                        color='danger'
                                                        icon='Report'
                                                        className='text-nowrap'>
                                                        Pending
                                                    </Button>
                                                }
                                            </div>

                                            <div className="col-8">
                                                <TimelineItem label='' color='primary'>
                                                    Term Details
                                                    <li>Add Term(For Current Year)</li>
                                                </TimelineItem>
                                            </div>
                                            <div className="col-2">
                                                {data.getBatchMasterDataCount ?
                                                    <Button
                                                        tag='a'
                                                        to={`../general/batchMaster`}
                                                        size='sm'
                                                        isLink
                                                        color='success'
                                                        icon='Report'
                                                        className='text-nowrap'>
                                                        Completed
                                                    </Button> :
                                                    <Button
                                                        tag='a'
                                                        to={`../general/batchMaster`}
                                                        size='sm'
                                                        isLink
                                                        color='danger'
                                                        icon='Report'
                                                        className='text-nowrap'>
                                                        Pending
                                                    </Button>
                                                }
                                            </div>
                                            <div className="col-8">
                                                <TimelineItem label='' color='success'>
                                                    Add Class
                                                </TimelineItem>
                                            </div>
                                            <div className="col-2">
                                                {data.getClassDetailsDataCount ?
                                                    <Button
                                                        tag='a'
                                                        to={`../general/addSectionDetails`}
                                                        size='sm'
                                                        isLink
                                                        color='success'
                                                        icon='Report'
                                                        className='text-nowrap'>
                                                        Completed
                                                    </Button> :
                                                    <Button
                                                        tag='a'
                                                        to={`../general/addSectionDetails`}
                                                        size='sm'
                                                        isLink
                                                        color='danger'
                                                        icon='Report'
                                                        className='text-nowrap'>
                                                        Pending
                                                    </Button>
                                                }
                                            </div>
                                            
                                            <div className="col-8">
                                                <TimelineItem label='' color='primary'>
                                                    Class Capacity
                                                    <li>Class Add For Std</li>
                                                </TimelineItem>
                                            </div>
                                            <div className="col-2">
                                                {data.getClassCapacityDataCount ?
                                                    <Button
                                                        tag='a'
                                                        to={`../general/addClassCapacity`}
                                                        size='sm'
                                                        isLink
                                                        color='success'
                                                        icon='Report'
                                                        className='text-nowrap'>
                                                        Completed
                                                    </Button> :
                                                    <Button
                                                        tag='a'
                                                        to={`../general/addClassCapacity`}
                                                        size='sm'
                                                        isLink
                                                        color='danger'
                                                        icon='Report'
                                                        className='text-nowrap'>
                                                        Pending
                                                    </Button>
                                                }
                                            </div>

                                            <div className="col-8">
                                                <TimelineItem label='' color='warning'>
                                                    Add Period Details
                                                </TimelineItem>
                                            </div>
                                            <div className="col-2">
                                                {data.getPeriodDetailsDataCount ?
                                                    <Button
                                                        tag='a'
                                                        to={`../academic/addPeriod`}
                                                        size='sm'
                                                        isLink
                                                        color='success'
                                                        icon='Report'
                                                        className='text-nowrap'>
                                                        Completed
                                                    </Button> :
                                                    <Button
                                                        tag='a'
                                                        to={`../academic/addPeriod`}
                                                        size='sm'
                                                        isLink
                                                        color='danger'
                                                        icon='Report'
                                                        className='text-nowrap'>
                                                        Pending
                                                    </Button>
                                                }
                                            </div>

                                            <div className="col-8">
                                                <TimelineItem label='' color='secondary'>
                                                    Add Day Order
                                                </TimelineItem>
                                            </div>
                                            <div className="col-2">
                                                {data.getDayOrderDataCount ?
                                                    <Button
                                                        tag='a'
                                                        to={`../general/dayOrderList`}
                                                        size='sm'
                                                        isLink
                                                        color='success'
                                                        icon='Report'
                                                        className='text-nowrap'>
                                                        Completed
                                                    </Button> :
                                                    <Button
                                                        tag='a'
                                                        to={`../general/dayOrderList`}
                                                        size='sm'
                                                        isLink
                                                        color='danger'
                                                        icon='Report'
                                                        className='text-nowrap'>
                                                        Pending
                                                    </Button>
                                                }
                                            </div>

                                            <div className="col-8">
                                                <TimelineItem label='' color='dark'>
                                                    Add Admission Form Fee
                                                </TimelineItem>
                                            </div>
                                            <div className="col-2">
                                                {data.getAdmissionFormFeeDataCount ?
                                                    <Button
                                                        tag='a'
                                                        to={`../general/addApplictionFormFee`}
                                                        size='sm'
                                                        isLink
                                                        color='success'
                                                        icon='Report'
                                                        className='text-nowrap'>
                                                        Completed
                                                    </Button> :
                                                    <Button
                                                        tag='a'
                                                        to={`../general/addApplictionFormFee`}
                                                        size='sm'
                                                        isLink
                                                        color='danger'
                                                        icon='Report'
                                                        className='text-nowrap'>
                                                        Pending
                                                    </Button>
                                                }
                                            </div>

                                            <div className="col-8">
                                                <TimelineItem label='' color='danger'>
                                                    Add Fee Master
                                                </TimelineItem>
                                            </div>
                                            <div className="col-2">
                                                {data.getFeeMasterDataCount ?
                                                    <Button
                                                        tag='a'
                                                        to={`../general/feeMaster`}
                                                        size='sm'
                                                        isLink
                                                        color='success'
                                                        icon='Report'
                                                        className='text-nowrap'>
                                                        Completed
                                                    </Button> :
                                                    <Button
                                                        tag='a'
                                                        to={`../general/feeMaster`}
                                                        size='sm'
                                                        isLink
                                                        color='danger'
                                                        icon='Report'
                                                        className='text-nowrap'>
                                                        Pending
                                                    </Button>
                                                }
                                            </div>

                                            {/* <div className="col-8">
                                                <TimelineItem label='' color='primary'>
                                                    Add Caste Details
                                                </TimelineItem>
                                            </div>
                                            <div className="col-2">
                                                {data.getCasteDetailsDataCount ?
                                                    <Button
                                                        size='sm'
                                                        isLink
                                                        color='success'
                                                        icon='Report'
                                                        className='text-nowrap'>
                                                        Completed
                                                    </Button> :
                                                    <Button
                                                        tag='a'
                                                        to={`../general/caste`}
                                                        size='sm'
                                                        isLink
                                                        color='danger'
                                                        icon='Report'
                                                        className='text-nowrap'>
                                                        Pending
                                                    </Button>
                                                }
                                            </div>

                                            <div className="col-8">
                                                <TimelineItem label='' color='success'>
                                                    Add Student 12th School Name
                                                </TimelineItem>
                                            </div>
                                            <div className="col-2">
                                                {data.getStudent12thSchoolNameDataCount ?
                                                    <Button
                                                        size='sm'
                                                        isLink
                                                        color='success'
                                                        icon='Report'
                                                        className='text-nowrap'>
                                                        Completed
                                                    </Button> :
                                                    <Button
                                                        tag='a'
                                                        to={`../general/addStudentSchool`}
                                                        size='sm'
                                                        isLink
                                                        color='danger'
                                                        icon='Report'
                                                        className='text-nowrap'>
                                                        Pending
                                                    </Button>
                                                }
                                            </div>

                                            <div className="col-8">
                                                <TimelineItem label='' color='success'>
                                                    Add Student College Name
                                                </TimelineItem>
                                            </div>
                                            <div className="col-2">
                                                {data.getStudentCollegeNameDataCount ?
                                                    <Button
                                                        size='sm'
                                                        isLink
                                                        color='success'
                                                        icon='Report'
                                                        className='text-nowrap'>
                                                        Completed
                                                    </Button> :
                                                    <Button
                                                        tag='a'
                                                        to={`../general/addStudentCollege`}
                                                        size='sm'
                                                        isLink
                                                        color='danger'
                                                        icon='Report'
                                                        className='text-nowrap'>
                                                        Pending
                                                    </Button>
                                                }
                                            </div>

                                            <div className="col-8">
                                                <TimelineItem label='' color='light'>
                                                    Add Screens
                                                </TimelineItem>
                                            </div>
                                            <div className="col-2">
                                                {data.getScreensDataCount ?
                                                    <Button
                                                        size='sm'
                                                        isLink
                                                        color='success'
                                                        icon='Report'
                                                        className='text-nowrap'>
                                                        Completed
                                                    </Button> :
                                                    <Button
                                                        tag='a'
                                                        to={`../general/addStudentCollege`}
                                                        size='sm'
                                                        isLink
                                                        color='danger'
                                                        icon='Report'
                                                        className='text-nowrap'>
                                                        Pending
                                                    </Button>
                                                }
                                            </div>

                                            <div className="col-8">
                                                <TimelineItem label='' color='info'>
                                                    Add Staff Details
                                                </TimelineItem>
                                            </div>
                                            <div className="col-2">
                                                {data.getStaffDetailsDataCount ?
                                                    <Button
                                                        size='sm'
                                                        isLink
                                                        color='success'
                                                        icon='Report'
                                                        className='text-nowrap'>
                                                        Completed
                                                    </Button> :
                                                    <Button
                                                        tag='a'
                                                        to={`../academic/addStaff`}
                                                        size='sm'
                                                        isLink
                                                        color='danger'
                                                        icon='Report'
                                                        className='text-nowrap'>
                                                        Pending
                                                    </Button>
                                                }
                                            </div> */}
                                        </div>
                                    </Timeline>
                                ))}
                            </CardBody>
                        </Card>
                    </div>
                    <div className="col-1"></div>
                </div>

            </Page>
        </PageWrapper>
    );
};

export default LetsStarted;
