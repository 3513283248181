import { useContext, useEffect, useState } from "react";
import Page from "../../../layout/Page/Page";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import Checks from "../../bootstrap/forms/Checks";
import FormGroup from "../../bootstrap/forms/FormGroup";
import Input from "../../bootstrap/forms/Input";
import Select from "../../bootstrap/forms/Select";
import { toasts } from "../../../services/toast.service";
import { useFormik } from "formik";
import AlertService from "../../../services/AlertService";
import { useNavigate, useParams } from "react-router-dom";
import SubHeader, { SubHeaderLeft, SubheaderSeparator } from "../../../layout/SubHeader/SubHeader";
import Button from "../../bootstrap/Button";
import { getProfileForStudent, getStudentDetailsByStudentDetailsId, updateStudentDetails } from "../../../services/student.service";
import { getBlood, getCategory, getCity, getConcessionType, getCourseDetails, getGenderDetails, getMedium, getMotherTongue, getReligion, getStandardDetails, getStates, onFileSelected, onlyAllowNumber, pictNotLoading, profilePic, profilePicUpload } from "../../../services/common.service";
import { getLicenseKey } from "../../../services/application.settings";
import AuthContext from "../../../contexts/authContext";
import { getBatchMasterDetails, getCasteDetails, getReferralType, getSectionDetails, getUserListByUserTypeId } from "../../../services/master.service";
import { getTransportAreaMaster } from "../../../services/transport.service";
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from "../../bootstrap/Card";
import Popovers from "../../bootstrap/Popovers";
import classNames from "classnames";
import useDarkMode from "../../../hooks/useDarkMode";
import { showLoader } from "../../../services/loader.services";
import SearchableSelect from "../../../common/components/SearchableSelect";
import { getStudentSchoolName, getStudentSchoolSubject } from "../../../services/administration.service";

const UpdateStudentWizard = () => {

    const navigate = useNavigate();
    const { studentDetailsId } = useParams();
    const [step, setStep] = useState<number>(0);
    const [extraStep, setExtraStep] = useState<number>(4);
    const totalSteps: number = extraStep;

    const handleNext = () => {
        if (step < totalSteps) {
            setStep(step + 1);
        }
    };

    const handlePrevious = () => {
        setStep(step - 1);
    };

    const handleStepClick = (newStep: any) => {
        setStep(newStep);
    };

    useEffect(() => {
        getMediumList();
        getBatchList();
        getGender();
        getBloodList();
        getMotherTongueList();
        getCategoryDetails();
        getReligionDetails();
        getCaste();
        getStatesList();
        getPermAddr_State();
        getStudentSchoolNameList();
        getStudentSchoolSubjectList();
        getReferral();
        getConcessionTypeList();
        getTransportAreaMasterDetails();
        getStudentDetailsByStudentDetails(studentDetailsId);
        getStudentProfile(studentDetailsId);
    }, [])

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const { themeStatus, darkModeStatus } = useDarkMode();

    const [graduationTypeId, setGraduationTypeId] = useState('')
    const [commAddr_StateId, setCommAddr_StateId] = useState<any>('')
    const [commAddr_CityId, setCommAddr_CityId] = useState<any>('')

    const [permAddr_StateId, setPermAddr_StateId] = useState<any>('')
    const [permAddr_CityId, setPermAddr_CityId] = useState<any>('')


    const [graduationTypeData, setGraduationTypeData] = useState<any>([])
    const [genderData, setGenderData] = useState<any>([])
    const [bloodData, setBloodData] = useState<any>([])
    const [motherTongueData, setMotherTongueData] = useState<any>([])
    const [categoryData, setCategoryData] = useState<any>([])
    const [religionData, setReligionData] = useState<any>([])
    const [casteDetailsData, setCasteDetailsData] = useState<any>([])

    const [statesData, setStatesData] = useState<any>([])
    const [cityData, setCityData] = useState<any>([])
    const [permAddr_StateData, setPermAddr_StateData] = useState<any>([])
    const [permAddr_CityData, setPermAddr_CityData] = useState<any>([])

    const [batchMasterId, setBatchMasterId] = useState<any>('')
    const [sectionDetailsId, setSectionDetailsId] = useState<any>('')

    const [batchMasterData, setBatchMasterData] = useState<any>([])
    const [sectionDetailsData, setSectionDetailsData] = useState<any>([])
    //XI subject
    const [studentSchoolSubjectData, setStudentSchoolSubjectData] = useState<any>([])

    const [interestedCourseData, setInterestedCourseData] = useState<any>([])
    const [referralTypeId, setReferralTypeId] = useState<any>('')
    const [referralTypeData, setReferralTypeData] = useState<any>([])
    const [userListByUserTypeData, setUserListByUserTypeData] = useState<any>([])
    const [concessionData, setConcessionData] = useState<any>([])
    const [transportAreaMasterData, setTransportAreaMasterData] = useState<any>([])

    const [studentDetailsData, setStudentDetailsData] = useState([])
    const [permAddr_FlatNo, setPermAddr_FlatNo] = useState<any>('')
    const [permAddr_Street, setPermAddr_Street] = useState<any>('')
    const [permAddr_Area, setPermAddr_Area] = useState<any>('')
    const [permAddr_Pincode, setPermAddr_Pincode] = useState<any>('')

    const [isReferralTypeOthers, setIsReferralTypeOthers] = useState(false)
    const [selectedFile, setSelectedFile] = useState<any>()
    const [isCollegeTransport, setTransportId] = useState('')
    const [isTransportDetails, setIsTransportDetails] = useState('isTransportDetails')
    const [nationality, setIsNationality] = useState('')
    const [isOtherNation, setIsOtherNation] = useState('isOthers')
    const [physicallyChallenged, setPhysicallyChallenged] = useState('')
    const [isPhysicallyChallengedDetails, setIsPhysicallyChallengedDetails] = useState('isPhysicallyChallengedDetails')
    const [interimSchoolTransfer, setIsInterimSchoolTransfer] = useState('')
    const [totalPercentageForX, setTotalPercentageForX] = useState('')

    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);
    const [isNavigate, setIsNavigate] = useState<any>(null);


    const [studentProfileData, setStudentProfileData] = useState<any>([])
    const [profilePath, setProfilePath] = useState(null);

    const [genderId, setGenderId] = useState<any>('')
    const [bloodGroupId, setBloodGroupId] = useState<any>('')
    const [motherTongue, setMotherTongue] = useState<any>('')
    const [religionId, setReligionId] = useState<any>('')
    const [category, setCategory] = useState<any>('')
    const [casteDetailsId, setCasteDetailsId] = useState<any>('')
    const [transferCourseId, setTransferCourseId] = useState<any>('')
    const [referredId, setReferredId] = useState<any>('')
    const [referredName, setReferredName] = useState('')
    const [concessionTypeId, setConcessionTypeId] = useState<any>('')
    const [concessionRemarks, setConcessionRemarks] = useState<any>('')

    const [XIISchoolNameId, setXIISchoolNameId] = useState<any>('')
    const [X_S1_studentSchoolSubject, setX_S1_studentSchoolSubject] = useState<any>('')
    const [X_S2_studentSchoolSubject, setX_S2_studentSchoolSubject] = useState<any>('')
    const [X_S3_studentSchoolSubject, setX_S3_studentSchoolSubject] = useState<any>('')
    const [X_S4_studentSchoolSubject, setX_S4_studentSchoolSubject] = useState<any>('')
    const [X_S5_studentSchoolSubject, setX_S5_studentSchoolSubject] = useState<any>('')
    const [X_S6_studentSchoolSubject, setX_S6_studentSchoolSubject] = useState<any>('')

    const [standardDetailsId, setStandardDetailsId] = useState<any>('')
    const [mediumId, setMediumId] = useState<any>('')
    const [standardData, setStandardData] = useState<any>([])
    const [mediumData, setMediumData] = useState<any>([])

    const updateStudentForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            ///----General Info----////
            studentDetailsId: '',
            profilePath: '',
            standardDetailsId: '',
            mediumId: '',
            admissionNo: '',
            tempNo: '',
            firstName: '',
            lastName: '',
            surName: '',
            studentNameTamil: '',
            surNameTamil: '',
            dateOfBirth: '',
            genderId: '',
            bloodGroupId: '',
            motherTongue: '',
            nationality: '',
            otherNation: '',
            religionId: '',
            casteUniqueId: '',
            casteDetailsId: '',
            subcaste: '',
            category: '',
            aadharCard: '',
            physicallyChallenged: '',
            physicallyChallengedType: '',
            physicallyChallengedPercentage: '',
            martialStatus: '',
            email: '',
            emisNo: '',
            smsToBeSentNumber: '',
            courseDetailsId: '',
        },
        validate: (values: any) => {
            const errors: {
                mediumId?: string;
                standardDetailsId?: string;
                admissionNo?: string;
                firstName?: string;
                aadharCard?: string;
                email?: string;
                smsToBeSentNumber?: string;
                courseDetailsId?: string;

            } = {};
            if (!mediumId) {
                errors.mediumId = 'Required';
            }
            if (!standardDetailsId) {
                errors.standardDetailsId = 'Required';
            }
            if (!values.admissionNo) {
                errors.admissionNo = 'Required';
            }
            if (!values.firstName) {
                errors.firstName = 'Required'
            }
            if (!courseDetailsId && (standardDetailsId?.value == 11 || standardDetailsId?.value == 12)) {
                errors.courseDetailsId = 'Required';
            }
            else if (values.firstName.length < 3) {
                errors.firstName = 'Must be 3 characters or more';
            } else if (values.firstName.length > 20) {
                errors.firstName = 'Must be 20 characters or less';
            }
            if (values.aadharCard && !/^\d{12}$/.test(values.aadharCard)) {
                errors.aadharCard = 'Must be 12 Numbers';
            }
            // if (values.email) {
            //     errors.email = 'Required'
            // }
            // else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            //     errors.email = 'Invalid email address';
            // }
            if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
                errors.email = 'Invalid email address';
            }
            if (!values.smsToBeSentNumber) {
                errors.smsToBeSentNumber = 'Required'
            }
            else if (!/^\d{10}$/.test(values.smsToBeSentNumber)) {
                errors.smsToBeSentNumber = 'Must be 10 Numbers';
            }

            return errors;
        },
        //validateOnChange: false,
        onSubmit: (values) => { handleNext() },
    })

    const addressForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            commAddr_FlatNo: '',
            commAddr_Street: '',
            commAddr_Area: '',
            commAddr_StateId: '',
            commAddr_CityId: '',
            commAddr_Pincode: '',
            permAddr_FlatNo: '',
            permAddr_Street: '',
            permAddr_Area: '',
            permAddr_StateId: '',
            permAddr_CityId: '',
            permAddr_Pincode: '',

            bankName: '',
            bankAccountNo: '',
            branch: '',
            ifscCode: '',
            micrCode: '',
        },
        validate: (values) => {
            const errors: {
                commAddr_Pincode?: string;
                permAddr_Pincode?: string;
            } = {};
            if (values.commAddr_Pincode && !/^\d{6}$/.test(values.commAddr_Pincode)) {
                errors.commAddr_Pincode = 'Must be 6 Numbers';
            }
            if (values.permAddr_Pincode && !/^\d{6}$/.test(values.permAddr_Pincode)) {
                errors.permAddr_Pincode = 'Must be 6 Numbers';
            }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: () => { handleNext() },
    });

    const classForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            // ///----Class INFO----////
            sectionDetailsId: '',
            batchMasterId: '',
            studentRollNo: '',
            admissionDateStamp: '',
            sourceName: '',
            transferCourseId: '',
            referralTypeId: '',
            referredId: '',
            referredName: '',
            interimSchoolTransfer: '',
            schoolName: '',
            concessionTypeId: '',
            concessionAmount: '',
            concessionRemarks: '',
            transportAreaMasterId: '',
            modeOfWays: '',
            isCollegeTransport: '',
        },
        validate: (values) => { },
        //validateOnChange: false,
        onSubmit: () => { handleNext() },
    });

    const familyForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            ///----Family INFO----////
            fatherName: '',
            father_AdharNumber: '',
            fatherOccupation: '',
            natureofJoborBusiness: '',
            fatherContactNumber: '',
            fatherEmailId: '',
            motherName: '',
            mother_AdharNumber: '',
            yearlyIncome: '',
            positionHeld: '',
            motherContactNumber: '',
            guardian_Name: '',
            guardian_email: '',
            guardian_phone: '',
            guardian_AdharNumber: '',
            isGuardian: '',
        },
        validate: (values) => {
            const errors: {
                motherContactNumber?: string;
                father_AdharNumber?: string;
                fatherContactNumber?: string;
                fatherEmailId?: string;
                mother_AdharNumber?: string;
                guardian_email?: string;
                guardian_phone?: string;
                guardian_AdharNumber?: string;
            } = {};
            if (values.father_AdharNumber && !/^\d{12}$/.test(values.father_AdharNumber)) {
                errors.father_AdharNumber = 'Must be 12 Numbers';
            }
            if (values.fatherContactNumber && !/^\d{10}$/.test(values.fatherContactNumber)) {
                errors.fatherContactNumber = 'Must be 10 Numbers';
            }
            if (values.fatherEmailId && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.fatherEmailId)) {
                errors.fatherEmailId = 'Invalid email address';
            }
            if (values.mother_AdharNumber && !/^\d{12}$/.test(values.mother_AdharNumber)) {
                errors.mother_AdharNumber = 'Must be 12 Numbers';
            }
            if (values.motherContactNumber && !/^\d{10}$/.test(values.motherContactNumber)) {
                errors.motherContactNumber = 'Must be 10 Numbers';
            }
            if (values.guardian_AdharNumber && !/^\d{12}$/.test(values.guardian_AdharNumber)) {
                errors.guardian_AdharNumber = 'Must be 12 Numbers';
            }
            if (values.guardian_phone && !/^\d{10}$/.test(values.guardian_phone)) {
                errors.guardian_phone = 'Must be 10 Numbers';
            }
            if (values.guardian_email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.guardian_email)) {
                errors.guardian_email = 'Invalid email address';
            }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: () => { standardDetailsId?.value == 11 || standardDetailsId?.value == 12 ? handleNext() : updateStudentDetailsSubmit() },
    });

    const markForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            // // ///----MARK INFO----////
            registerNo_X: '',
            institutionNameAndAddress_X: '',
            boardName_X: '',
            mediumOfInstitution_X: '',
            monthAndYearOfPassing_X: '',

            registerNo_XI: '',
            institutionNameAndAddress_XI: '',
            boardName_XI: '',
            mediumOfInstitution_XI: '',
            monthAndYearOfPassing_XI: '',

            X_S1_studentSchoolSubject: '',
            X_S1_markSecured: '',
            X_S1_maximumMarks: '',
            X_S1_monthAndYearOfPassing: '',
            X_S1_registerNo: '',
            X_S1_noOfAttempts: '',
            X_S2_studentSchoolSubject: '',
            X_S2_markSecured: '',
            X_S2_maximumMarks: '',
            X_S2_monthAndYearOfPassing: '',
            X_S2_registerNo: '',
            X_S2_noOfAttempts: '',
            X_S3_studentSchoolSubject: '',
            X_S3_markSecured: '',
            X_S3_maximumMarks: '',
            X_S3_monthAndYearOfPassing: '',
            X_S3_registerNo: '',
            X_S3_noOfAttempts: '',
            X_S4_studentSchoolSubject: '',
            X_S4_markSecured: '',
            X_S4_maximumMarks: '',
            X_S4_monthAndYearOfPassing: '',
            X_S4_registerNo: '',
            X_S4_noOfAttempts: '',
            X_S5_studentSchoolSubject: '',
            X_S5_markSecured: '',
            X_S5_maximumMarks: '',
            X_S5_monthAndYearOfPassing: '',
            X_S5_registerNo: '',
            X_S5_noOfAttempts: '',
            X_S6_studentSchoolSubject: '',
            X_S6_markSecured: '',
            X_S6_maximumMarks: '',
            X_S6_monthAndYearOfPassing: '',
            X_S6_registerNo: '',
            X_S6_noOfAttempts: '',
            X_OverAll_markSecured: '',
            X_OverAll_maximumMarks: 600,

        },
        validate: (values) => { },
        //validateOnChange: false,
        onSubmit: () => { updateStudentDetailsSubmit() },
    });

    const selectMedium = (e: any) => {
        setStandardDetailsId('')
        let mediumId = e
        setMediumId(mediumId)
        if (mediumId?.value != undefined) {
            getStandardList();
        } else {
            setStandardData([])
        }
    }

    const selectStandard = (e: any) => {
        let standardDetailsId = e
        setStandardDetailsId(standardDetailsId)
        if (standardDetailsId?.value != undefined) {
            getSectionDetailsList(mediumId?.value, standardDetailsId?.value)
            if (standardDetailsId?.value == 11 || standardDetailsId?.value == 12) {
                setExtraStep(5)
                getCourseList()
            } else {
                setExtraStep(4)
            }
        }
    }

    const [courseDetailsId, setCourseDetailsId] = useState<any>('')

    const selectCourse = (e: any) => {
        let courseDetailsId = e
        setCourseDetailsId(courseDetailsId)
    }

    const selectBatch = (e: any) => {
        let batchMasterId = e
        setBatchMasterId(batchMasterId)
    }

    function getStandardList() {
        getStandardDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.standardDetails;
                    if (data != undefined) {
                        setStandardData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setStandardData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getMediumList() {
        getMedium(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.medium;
                    if (data != undefined) {
                        setMediumData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setMediumData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getBatchList() {
        getBatchMasterDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.batchMasterDetails;
                    if (data != undefined) {
                        setBatchMasterData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setBatchMasterData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getSectionDetailsList(mediumId: any, standardDetailsId: any) {
        getSectionDetails(mediumId, standardDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.sectionDetails;
                    if (data != undefined) {
                        setSectionDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setSectionDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    const [courseDetailsData, setCourseDetailsData] = useState<any>([])

    function getCourseList() {
        getCourseDetails(0,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.courseDetails;
                    if (data != undefined) {
                        setCourseDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setCourseDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    const selectReferralType = (e: any) => {
        setReferredId('')
        setReferredName('')
        let referralTypeId = e
        setReferralTypeId(referralTypeId);
        if (referralTypeId?.value != undefined) {
            getUserListByUserType(referralTypeId?.value)
            if (referralTypeId?.value == '11') {
                setIsReferralTypeOthers(false)
            }
            else {
                setIsReferralTypeOthers(true)
            }
        }
    }

    const selectState = (e: any) => {
        setCommAddr_CityId('')
        setCityData([])
        let commAddr_StateId = e
        setCommAddr_StateId(commAddr_StateId)
        if (commAddr_StateId?.value != undefined) {
            getCityList(commAddr_StateId?.value)
        }
    }

    const selectCity = (e: any) => {
        let commAddr_CityId = e
        setCommAddr_CityId(commAddr_CityId)
    }

    const selectPermAddr_State = (e: any) => {
        setPermAddr_CityId('')
        setPermAddr_CityData([])
        let permAddr_StateId = e
        setPermAddr_StateId(permAddr_StateId)
        if (permAddr_StateId?.value != undefined) {
            getPermAddr_StateAndCity(permAddr_StateId?.value)
        }
    }

    const selectPermAddr_City = (e: any) => {
        let permAddr_CityId = e
        setPermAddr_CityId(permAddr_CityId)
    }

    const selectSection = (e: any) => {
        let sectionDetailsId = e
        setSectionDetailsId(sectionDetailsId)
    }
    const [transportAreaValue, setTransportAreaValue] = useState('')
    const [modeValue, setModeValue] = useState('')

    const selectTransport = (e: any) => {
        let isCollegeTransport = e.target.values
        setTransportId(isCollegeTransport)
        getTransportAreaMasterDetails();
        if (isCollegeTransport == 'Yes') {
            setIsTransportDetails('isAreaDetails')
        }
        else {
            setIsTransportDetails('isTransportDetails')
            setTransportAreaValue('')
            setModeValue('')
        }
    }

    const selectConcessionTypeId = (e: any) => {
        setConcessionRemarks('')
        let concessionTypeId = e
        setConcessionTypeId(concessionTypeId)
    }

    function getGender() {
        getGenderDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.genderDetails;
                    if (data != undefined) {
                        setGenderData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setGenderData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getBloodList() {
        getBlood(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.blood;
                    if (data != undefined) {
                        setBloodData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setBloodData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getMotherTongueList() {
        getMotherTongue(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.motherTongue;
                    if (data != undefined) {
                        setMotherTongueData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setMotherTongueData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getReligionDetails() {
        getReligion(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.religion;
                    if (data != undefined) {
                        setReligionData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setReligionData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getCategoryDetails() {
        getCategory(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.category;
                    if (data != undefined) {
                        setCategoryData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setCategoryData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getCaste() {
        getCasteDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.casteDetails;
                    if (data != undefined) {
                        setCasteDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setCasteDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStatesList() {
        getStates(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.statesAndCity;
                    if (data != undefined) {
                        setStatesData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setStatesData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getCityList(stateId: any) {
        getCity(stateId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.statesAndCity[0]['city'];
                    if (data != undefined) {
                        setCityData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setCityData([]);
                } else {

                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getPermAddr_State() {
        getStates(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.statesAndCity;
                    if (data != undefined) {
                        setPermAddr_StateData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setPermAddr_StateData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getPermAddr_StateAndCity(stateId: any) {
        getCity(stateId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.statesAndCity[0]['city'];
                    if (data != undefined) {
                        setPermAddr_CityData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setPermAddr_CityData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    const [studentSchoolNameData, setStudentSchoolNameData] = useState([])

    function getStudentSchoolNameList() {
        getStudentSchoolName(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentSchoolName;
                    if (data != undefined) {
                        setStudentSchoolNameData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setStudentSchoolNameData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        );
    }

    function getStudentSchoolSubjectList() {
        getStudentSchoolSubject(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentSchoolSubject;
                    if (data != undefined) {
                        setStudentSchoolSubjectData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setStudentSchoolSubjectData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getInterestedCourse(departmentId: any, graduationTypeId: any) {
        // getCourse(departmentId, graduationTypeId,
        //     (response) => {
        //         if (response.data.success) {
        //             let data = response.data.data.course;
        //             if (data != undefined) {
        //                 setInterestedCourseData(data);
        //             } else {
        //                 toasts("Undefined Data", "Error")
        //             }
        //         }
        //         else if (response.data.success === false) {
        //             //toasts(response.data.message, "Error")
        //             setInterestedCourseData([]);
        //         } else {
        //             let errorCode = response.data.error[0].error_code;
        //             let errorDescription = response.data.error[0].error_description;
        //             toasts(errorDescription, "Error")
        //         }
        //     }, error => {
        //         toasts(error, "Error")
        //     }
        // )
    }

    function getReferral() {
        getReferralType(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.referralType;
                    if (data != undefined) {
                        setReferralTypeData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setReferralTypeData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getUserListByUserType(referralTypeId: any) {
        getUserListByUserTypeId(referralTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.userListByUserTypeId;
                    if (data != undefined) {
                        setUserListByUserTypeData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setUserListByUserTypeData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getConcessionTypeList() {
        getConcessionType(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.concessionType;
                    if (data != undefined) {
                        setConcessionData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setConcessionData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getTransportAreaMasterDetails() {
        getTransportAreaMaster(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.transportAreaMaster;
                    if (data != undefined) {
                        setTransportAreaMasterData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setTransportAreaMasterData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStudentDetailsByStudentDetails(studentDetailsId: any) {
        getStudentDetailsByStudentDetailsId(studentDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentDetailsByStudentDetailsId[0];
                    if (data != undefined) {
                        setStudentDetailsData(data)
                        setProfilePath(data.profilePath);
                        setIsGuardian(data.isGuardian)
                        setStandardDetailsId(data.standardDetailsId != null ? { value: data.standardDetailsId, label: data.standardName } : '')
                        setMediumId(data.mediumId != null ? { value: data.mediumId, label: data.medium } : '')
                        setSectionDetailsId(data.sectionDetailsId != null ? { value: data.sectionDetailsId, label: data.sectionName } : '')
                        setBatchMasterId(data.batchMasterId != null ? { value: data.batchMasterId, label: data.batchName } : null)
                        setPermAddr_FlatNo(data.permAddr_FlatNo)
                        setPermAddr_Street(data.permAddr_Street)
                        setPermAddr_Area(data.permAddr_Area)
                        setPermAddr_Pincode(data.permAddr_Pincode)

                        if (data.commAddr_StateId != null) {
                            getCityList(data.commAddr_StateId)
                        }
                        if (data.permAddr_StateId != null) {
                            getPermAddr_StateAndCity(data.permAddr_StateId)
                        }
                        if (data.standardDetailsId == 11 || data.standardDetailsId == 12) {
                            getCourseList()
                        }
                        setCommAddr_StateId(data.commAddr_StateId != null ? { value: data.commAddr_StateId, label: data.stateName } : '')
                        setCommAddr_CityId(data.commAddr_CityId != null ? { value: data.commAddr_CityId, label: data.cityName } : '')
                        setPermAddr_StateId(data.permAddr_StateId != null ? { value: data.permAddr_StateId, label: data.stateName } : '')
                        setPermAddr_CityId(data.permAddr_CityId != null ? { value: data.permAddr_CityId, label: data.cityName } : '')
                        setCourseDetailsId(data.courseDetailsId > 0 ? { value: data.courseDetailsId, label: data.courseName } : '')

                        getInterestedCourse(0, data.graduationTypeId)


                        setReferralTypeId(data.referralTypeId != null ? { value: data.referralTypeId, label: data.referralType } : '')
                        setReferredId(data.referredId != null ? { value: data.referredId, label: data.registerNoForStudentAndStaff } : '')
                        setReferredName(data.referredName != null ? data.referredName : '')

                        if (data.referralTypeId != null) {
                            getUserListByUserType(data.referralTypeId)
                        }
                        if (data.referralTypeId == 11) {
                            setIsReferralTypeOthers(false)
                        } else {
                            setIsReferralTypeOthers(true)
                        }

                        setModeValue(data.modeOfWays)
                        setTransportAreaValue(data.transportAreaMasterId)
                        setTransportId(data.isCollegeTransport);
                        if (data.isCollegeTransport == 'Yes') {
                            setIsTransportDetails('isAreaDetails')
                        }
                        else {
                            setIsTransportDetails('isTransportDetails')
                            setTransportAreaValue('')
                            setModeValue('')
                        }

                        setOtherNationValue(data.otherNation)
                        setIsNationality(data.nationality)
                        if (data.nationality == 'Others') {
                            setIsOtherNation('Other')
                        }
                        else {
                            setIsOtherNation('isOthers')
                            setOtherNationValue('')
                        }

                        setPhysicallyChallengedTypeValue(data.physicallyChallengedType)
                        setPhysicallyChallengedPercentageValue(data.physicallyChallengedPercentage)
                        setPhysicallyChallenged(data.physicallyChallenged)
                        if (data.physicallyChallenged == 'Yes') {
                            setIsPhysicallyChallengedDetails('YES')
                        }
                        else {
                            setIsPhysicallyChallengedDetails('isPhysicallyChallengedDetails')
                            setPhysicallyChallengedPercentageValue('')
                            setPhysicallyChallengedTypeValue('')
                        }

                        setIsInterimSchoolTransfer(data.interimSchoolTransfer)
                        setSchoolNameValue(data.schoolName != null ? data.schoolName : '')
                        setStudentSchoolNameId(data.studentSchoolNameId > 0 ? { value: data.studentSchoolNameId, label: data.schoolName } : '')

                        setGenderId(data.genderId != 0 ? { value: data.genderId, label: data.gender } : null)
                        setBloodGroupId(data.bloodGroupId != null ? { value: data.bloodGroupId, label: data.groupName } : '')
                        setMotherTongue(data.motherTongue != null ? { value: data.motherTongue, label: data.motherTongue } : '')
                        setReligionId(data.religionId != null ? { value: data.religionId, label: data.religion } : '')
                        setCategory(data.category != null ? { value: data.category, label: data.category } : '')
                        setCasteDetailsId(data.casteDetailsId != null ? { value: data.casteDetailsId, label: data.casteName } : '')
                        setTransferCourseId(data.transferCourseId != null ? { value: data.transferCourseId, label: data.transferCourseName } : '')
                        setConcessionTypeId(data.concessionTypeId != null ? { value: data.concessionTypeId, label: data.concessionType } : '')
                        setConcessionRemarks(data.concessionRemarks)

                        setXIISchoolNameId(data.XIISchoolNameId != null ? { value: data.XIISchoolNameId, label: data.schoolName } : '')
                        setX_S1_studentSchoolSubject(data.X_S1_studentSchoolSubject != null ? { value: data.X_S1_studentSchoolSubject, label: data.X_S1_studentSchoolSubjectName } : '')
                        setX_S2_studentSchoolSubject(data.X_S2_studentSchoolSubject != null ? { value: data.X_S2_studentSchoolSubject, label: data.X_S2_studentSchoolSubjectName } : '')
                        setX_S3_studentSchoolSubject(data.X_S3_studentSchoolSubject != null ? { value: data.X_S3_studentSchoolSubject, label: data.X_S3_studentSchoolSubjectName } : '')
                        setX_S4_studentSchoolSubject(data.X_S4_studentSchoolSubject != null ? { value: data.X_S4_studentSchoolSubject, label: data.X_S4_studentSchoolSubjectName } : '')
                        setX_S5_studentSchoolSubject(data.X_S5_studentSchoolSubject != null ? { value: data.X_S5_studentSchoolSubject, label: data.X_S5_studentSchoolSubjectName } : '')
                        setX_S6_studentSchoolSubject(data.X_S6_studentSchoolSubject != null ? { value: data.X_S6_studentSchoolSubject, label: data.X_S6_studentSchoolSubjectName } : '')

                        updateStudentForm.setValues({
                            studentDetailsId: data['studentDetailsId'],
                            standardDetailsId: data['standardDetailsId'] ? data['standardDetailsId'] : '',
                            mediumId: data['mediumId'] ? data['mediumId'] : '',
                            profilePath: data['profilePath'] ? data['profilePath'] : '',
                            admissionNo: data['admissionNo'] ? data['admissionNo'] : '',
                            tempNo: data['tempNo'] ? data['tempNo'] : '',
                            firstName: data['firstname'] ? data['firstname'] : '',
                            lastName: data['lastname'] ? data['lastname'] : '',
                            surName: data['surName'] ? data['surName'] : '',
                            studentNameTamil: data['studentNameTamil'] ? data['studentNameTamil'] : '',
                            surNameTamil: data['surNameTamil'] ? data['surNameTamil'] : '',
                            dateOfBirth: data['dateOfBirth'] ? data['dateOfBirth'] : '',
                            genderId: data['genderId'] ? data['genderId'] : '',
                            bloodGroupId: data['bloodGroupId'] ? data['bloodGroupId'] : '',
                            motherTongue: data['motherTongue'] ? data['motherTongue'] : '',
                            nationality: data['nationality'] ? data['nationality'] : '',
                            otherNation: data['otherNation'] ? data['otherNation'] : '',
                            religionId: data['religionId'] ? data['religionId'] : '',
                            casteUniqueId: data['casteUniqueId'] ? data['casteUniqueId'] : '',
                            casteDetailsId: data['casteDetailsId'] ? data['casteDetailsId'] : '',
                            subcaste: data['subcaste'] ? data['subcaste'] : '',
                            category: data['category'] ? data['category'] : '',
                            aadharCard: data['aadharCard'] ? data['aadharCard'] : '',
                            physicallyChallenged: data['physicallyChallenged'] ? data['physicallyChallenged'] : '',
                            physicallyChallengedType: data['physicallyChallengedType'] ? data['physicallyChallengedType'] : '',
                            physicallyChallengedPercentage: data['physicallyChallengedPercentage'] ? data['physicallyChallengedPercentage'] : '',
                            martialStatus: data['martialStatus'] ? data['martialStatus'] : '',
                            email: data['email'] ? data['email'] : '',
                            emisNo: data['emisNo'] ? data['emisNo'] : '',
                            smsToBeSentNumber: data['smsToBeSentNumber'] ? data['smsToBeSentNumber'] : '',
                            courseDetailsId: data['courseDetailsId'] ? data['courseDetailsId'] : '',
                        })
                        addressForm.setValues({
                            commAddr_FlatNo: data['commAddr_FlatNo'] ? data['commAddr_FlatNo'] : '',
                            commAddr_Street: data['commAddr_Street'] ? data['commAddr_Street'] : '',
                            commAddr_Area: data['commAddr_Area'] ? data['commAddr_Area'] : '',
                            commAddr_StateId: data['commAddr_StateId'] ? data['commAddr_StateId'] : '',
                            commAddr_CityId: data['commAddr_CityId'] ? data['commAddr_CityId'] : '',
                            commAddr_Pincode: data['commAddr_Pincode'] ? data['commAddr_Pincode'] : '',
                            permAddr_FlatNo: data['permAddr_FlatNo'] ? data['permAddr_FlatNo'] : '',
                            permAddr_Street: data['permAddr_Street'] ? data['permAddr_Street'] : '',
                            permAddr_Area: data['permAddr_Area'] ? data['permAddr_Area'] : '',
                            permAddr_StateId: data['permAddr_StateId'] ? data['permAddr_StateId'] : '',
                            permAddr_CityId: data['permAddr_CityId'] ? data['permAddr_CityId'] : '',
                            permAddr_Pincode: data['permAddr_Pincode'] ? data['permAddr_Pincode'] : '',
                            bankName: data['bankName'] ? data['bankName'] : '',
                            bankAccountNo: data['bankAccountNo'] ? data['bankAccountNo'] : '',
                            branch: data['branch'] ? data['branch'] : '',
                            ifscCode: data['ifscCode'] ? data['ifscCode'] : '',
                            micrCode: data['micrCode'] ? data['micrCode'] : '',
                        })
                        classForm.setValues({
                            sectionDetailsId: data['sectionDetailsId'] ? data['sectionDetailsId'] : '',
                            batchMasterId: data['batchMasterId'] ? data['batchMasterId'] : '',
                            studentRollNo: data['studentRollNo'] ? data['studentRollNo'] : '',
                            admissionDateStamp: data['admissionDateStamp'] ? data['admissionDateStamp'] : '',
                            sourceName: data['sourceName'] ? data['sourceName'] : '',
                            transferCourseId: data['transferCourseId'] ? data['transferCourseId'] : '',
                            interimSchoolTransfer: data['interimSchoolTransfer'] ? data['interimSchoolTransfer'] : '',
                            schoolName: data['schoolName'] ? data['schoolName'] : '',
                            concessionTypeId: data['concessionTypeId'] ? data['concessionTypeId'] : '',
                            concessionAmount: data['concessionAmount'] ? data['concessionAmount'] : '',
                            concessionRemarks: data['concessionRemarks'] ? data['concessionRemarks'] : '',
                            transportAreaMasterId: data['transportAreaMasterId'] ? data['transportAreaMasterId'] : '',
                            modeOfWays: data['modeOfWays'] ? data['modeOfWays'] : '',
                            referralTypeId: data['referralTypeId'] != null ? data['referralTypeId'] : 0,
                            referredId: data['referredId'] ? data['referredId'] : '',
                            referredName: data['referredName'] ? data['referredName'] : '',
                            isCollegeTransport: data['isCollegeTransport'] ? data['isCollegeTransport'] : '',
                        })
                        familyForm.setValues({
                            fatherName: data['fatherName'] ? data['fatherName'] : '',
                            father_AdharNumber: data['father_AdharNumber'] ? data['father_AdharNumber'] : '',
                            fatherOccupation: data['fatherOccupation'] ? data['fatherOccupation'] : '',
                            natureofJoborBusiness: data['natureofJoborBusiness'] ? data['natureofJoborBusiness'] : '',
                            fatherContactNumber: data['fatherContactNumber'] ? data['fatherContactNumber'] : '',
                            fatherEmailId: data['fatherEmailId'] ? data['fatherEmailId'] : '',
                            motherName: data['motherName'] ? data['motherName'] : '',
                            mother_AdharNumber: data['mother_AdharNumber'] ? data['mother_AdharNumber'] : '',
                            yearlyIncome: data['yearlyIncome'] ? data['yearlyIncome'] : '',
                            positionHeld: data['positionHeld'] ? data['positionHeld'] : '',
                            motherContactNumber: data['motherContactNumber'] ? data['motherContactNumber'] : '',

                            guardian_Name: data['guardian_Name'] ? data['guardian_Name'] : '',
                            guardian_email: data['guardian_email'] ? data['guardian_email'] : '',
                            guardian_phone: data['guardian_phone'] ? data['guardian_phone'] : '',
                            guardian_AdharNumber: data['guardian_AdharNumber'] ? data['guardian_AdharNumber'] : '',
                            isGuardian: data['isGuardian'] ? data['isGuardian'] : '',
                        })
                        markForm.setValues({
                            registerNo_X: data['registerNo_X'] ? data['registerNo_X'] : '',
                            institutionNameAndAddress_X: data['institutionNameAndAddress_X'] ? data['institutionNameAndAddress_X'] : '',
                            boardName_X: data['boardName_X'] ? data['boardName_X'] : '',
                            mediumOfInstitution_X: data['mediumOfInstitution_X'] ? data['mediumOfInstitution_X'] : '',
                            monthAndYearOfPassing_X: data['monthAndYearOfPassing_X'] ? data['monthAndYearOfPassing_X'] : '',

                            registerNo_XI: data['registerNo_XI'] ? data['registerNo_XI'] : '',
                            institutionNameAndAddress_XI: data['institutionNameAndAddress_XI'] ? data['institutionNameAndAddress_XI'] : '',
                            boardName_XI: data['boardName_XI'] ? data['boardName_XI'] : '',
                            mediumOfInstitution_XI: data['mediumOfInstitution_XI'] ? data['mediumOfInstitution_XI'] : '',
                            monthAndYearOfPassing_XI: data['monthAndYearOfPassing_XI'] ? data['monthAndYearOfPassing_XI'] : '',

                            X_S1_studentSchoolSubject: data['X_S1_studentSchoolSubject'] ? data['X_S1_studentSchoolSubject'] : '',
                            X_S1_markSecured: data['X_S1_markSecured'] ? data['X_S1_markSecured'] : '',
                            X_S1_maximumMarks: data['X_S1_maximumMarks'] ? data['X_S1_maximumMarks'] : '',
                            X_S1_monthAndYearOfPassing: data['X_S1_monthAndYearOfPassing'] ? data['X_S1_monthAndYearOfPassing'] : '',
                            X_S1_registerNo: data['X_S1_registerNo'] ? data['X_S1_registerNo'] : '',
                            X_S1_noOfAttempts: data['X_S1_noOfAttempts'] ? data['X_S1_noOfAttempts'] : '',

                            X_S2_studentSchoolSubject: data['X_S2_studentSchoolSubject'] ? data['X_S2_studentSchoolSubject'] : '',
                            X_S2_markSecured: data['X_S2_markSecured'] ? data['X_S2_markSecured'] : '',
                            X_S2_maximumMarks: data['X_S2_maximumMarks'] ? data['X_S2_maximumMarks'] : '',
                            X_S2_monthAndYearOfPassing: data['X_S2_monthAndYearOfPassing'] ? data['X_S2_monthAndYearOfPassing'] : '',
                            X_S2_registerNo: data['X_S2_registerNo'] ? data['X_S2_registerNo'] : '',
                            X_S2_noOfAttempts: data['X_S2_noOfAttempts'] ? data['X_S2_noOfAttempts'] : '',

                            X_S3_studentSchoolSubject: data['X_S3_studentSchoolSubject'] ? data['X_S3_studentSchoolSubject'] : '',
                            X_S3_markSecured: data['X_S3_markSecured'] ? data['X_S3_markSecured'] : '',
                            X_S3_maximumMarks: data['X_S3_maximumMarks'] ? data['X_S3_maximumMarks'] : '',
                            X_S3_monthAndYearOfPassing: data['X_S3_monthAndYearOfPassing'] ? data['X_S3_monthAndYearOfPassing'] : '',
                            X_S3_registerNo: data['X_S3_registerNo'] ? data['X_S3_registerNo'] : '',
                            X_S3_noOfAttempts: data['X_S3_noOfAttempts'] ? data['X_S3_noOfAttempts'] : '',

                            X_S4_studentSchoolSubject: data['X_S4_studentSchoolSubject'] ? data['X_S4_studentSchoolSubject'] : '',
                            X_S4_markSecured: data['X_S4_markSecured'] ? data['X_S4_markSecured'] : '',
                            X_S4_maximumMarks: data['X_S4_maximumMarks'] ? data['X_S4_maximumMarks'] : '',
                            X_S4_monthAndYearOfPassing: data['X_S4_monthAndYearOfPassing'] ? data['X_S4_monthAndYearOfPassing'] : '',
                            X_S4_registerNo: data['X_S4_registerNo'] ? data['X_S4_registerNo'] : '',
                            X_S4_noOfAttempts: data['X_S4_noOfAttempts'] ? data['X_S4_noOfAttempts'] : '',

                            X_S5_studentSchoolSubject: data['X_S5_studentSchoolSubject'] ? data['X_S5_studentSchoolSubject'] : '',
                            X_S5_markSecured: data['X_S5_markSecured'] ? data['X_S5_markSecured'] : '',
                            X_S5_maximumMarks: data['X_S5_maximumMarks'] ? data['X_S5_maximumMarks'] : '',
                            X_S5_monthAndYearOfPassing: data['X_S5_monthAndYearOfPassing'] ? data['X_S5_monthAndYearOfPassing'] : '',
                            X_S5_registerNo: data['X_S5_registerNo'] ? data['X_S5_registerNo'] : '',
                            X_S5_noOfAttempts: data['X_S5_noOfAttempts'] ? data['X_S5_noOfAttempts'] : '',

                            X_S6_studentSchoolSubject: data['X_S6_studentSchoolSubject'] ? data['X_S6_studentSchoolSubject'] : '',
                            X_S6_markSecured: data['X_S6_markSecured'] ? data['X_S6_markSecured'] : '',
                            X_S6_maximumMarks: data['X_S6_maximumMarks'] ? data['X_S6_maximumMarks'] : '',
                            X_S6_monthAndYearOfPassing: data['X_S6_monthAndYearOfPassing'] ? data['X_S6_monthAndYearOfPassing'] : '',
                            X_S6_registerNo: data['X_S6_registerNo'] ? data['X_S6_registerNo'] : '',
                            X_S6_noOfAttempts: data['X_S6_noOfAttempts'] ? data['X_S6_noOfAttempts'] : '',

                            X_OverAll_markSecured: data['X_OverAll_markSecured'] ? data['X_OverAll_markSecured'] : '',
                            X_OverAll_maximumMarks: data['X_OverAll_maximumMarks'] ? data['X_OverAll_maximumMarks'] : '',
                        })

                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStudentProfile(studentDetailsId: any) {
        getProfileForStudent(studentDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentProfile;
                    if (data != undefined) {
                        setStudentProfileData(data);
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function onUpload(studentOrStaffDetailsId: any) {
        const filedata = new FormData();
        filedata.append('profilePic', selectedFile, selectedFile.name)
        filedata.append('licenseKey', getLicenseKey.toString())
        filedata.append('userAccountId', userAccountId)
        filedata.append('studentOrStaffDetailsId', studentOrStaffDetailsId)
        filedata.append('userTypeId', "10")

        profilePicUpload(filedata,
            (response) => {
                showLoader(false)
                console.log('Profile pic upload');
                setAlertStatus({ message: response.data.message, type: "success" });
                setIsOpen(true);
                setIsNavigate(`../academic/studentList`)
            }
            , (error) => {
                showLoader(false)
                setAlertStatus({ message: 'Profile pic not upload', type: 'error' });
                setIsOpen(true);
                setIsNavigate(null);
            }
        )
    }

    function onUpload1(studentOrStaffDetailsId: any) {
        const filedata = new FormData();
        filedata.append('profilePic', selectedFile, selectedFile.name)
        filedata.append('licenseKey', getLicenseKey.toString())
        filedata.append('userAccountId', userAccountId)
        filedata.append('studentOrStaffDetailsId', studentOrStaffDetailsId)
        filedata.append('userTypeId', "10")

        profilePicUpload(filedata,
            (response) => {
                console.log('Profile pic upload');
            }
            , (error) => {
                console.log('Profile pic not upload');

            }
        )
    }

    function setUpdateStudentDetails() {

        return ({
            ///----General Info----////
            studentDetailsId: updateStudentForm.values.studentDetailsId ? updateStudentForm.values.studentDetailsId : null,
            standardDetailsId: standardDetailsId?.value != undefined ? standardDetailsId?.value : null,
            mediumId: mediumId?.value != undefined ? mediumId?.value : null,
            courseDetailsId: courseDetailsId?.value != undefined ? courseDetailsId?.value : null,
            admissionNo: updateStudentForm.values.admissionNo != "" ? updateStudentForm.values.admissionNo : null,
            tempNo: updateStudentForm.values.tempNo != "" ? updateStudentForm.values.tempNo : null,
            firstName: updateStudentForm.values.firstName != "" ? updateStudentForm.values.firstName : null,
            lastName: updateStudentForm.values.lastName != "" ? updateStudentForm.values.lastName : null,
            surName: updateStudentForm.values.surName != "" ? updateStudentForm.values.surName : null,
            studentNameTamil: updateStudentForm.values.studentNameTamil != "" ? updateStudentForm.values.studentNameTamil : null,
            surNameTamil: updateStudentForm.values.surNameTamil != "" ? updateStudentForm.values.surNameTamil : null,
            dateOfBirth: updateStudentForm.values.dateOfBirth != "" ? updateStudentForm.values.dateOfBirth : null,
            genderId: genderId?.value != undefined ? genderId?.value : null,
            bloodGroupId: bloodGroupId?.value != undefined ? bloodGroupId?.value : null,
            motherTongue: motherTongue?.value != undefined ? motherTongue?.value : null,
            nationality: nationality != "" ? nationality : null,
            otherNation: otherNationValue != "" ? otherNationValue : null,
            religionId: religionId?.value != undefined ? religionId?.value : null,
            casteUniqueId: updateStudentForm.values.casteUniqueId != "" ? updateStudentForm.values.casteUniqueId : null,
            casteDetailsId: casteDetailsId?.value != undefined ? casteDetailsId?.value : null,
            subcaste: updateStudentForm.values.subcaste != "" ? updateStudentForm.values.subcaste : null,
            category: category?.value != undefined ? category?.value : null,
            aadharCard: updateStudentForm.values.aadharCard != "" ? updateStudentForm.values.aadharCard : null,
            physicallyChallenged: physicallyChallenged != "" ? physicallyChallenged : null,
            physicallyChallengedType: physicallyChallengedTypeValue != "" ? physicallyChallengedTypeValue : null,
            physicallyChallengedPercentage: physicallyChallengedPercentageValue != "" ? physicallyChallengedPercentageValue : 0,
            martialStatus: updateStudentForm.values.martialStatus != "" ? updateStudentForm.values.martialStatus : null,
            email: updateStudentForm.values.email != "" ? updateStudentForm.values.email : null,
            emisNo: updateStudentForm.values.emisNo != "" ? updateStudentForm.values.emisNo : null,
            smsToBeSentNumber: updateStudentForm.values.smsToBeSentNumber != "" ? updateStudentForm.values.smsToBeSentNumber : '',
            ///----Address INFO----////

            commAddr_FlatNo: addressForm.values.commAddr_FlatNo != "" ? addressForm.values.commAddr_FlatNo : null,
            commAddr_Street: addressForm.values.commAddr_Street != "" ? addressForm.values.commAddr_Street : null,
            commAddr_Area: addressForm.values.commAddr_Area != "" ? addressForm.values.commAddr_Area : null,
            commAddr_StateId: commAddr_StateId?.value != undefined ? commAddr_StateId?.value : null,
            commAddr_CityId: commAddr_CityId?.value != undefined ? commAddr_CityId?.value : null,
            commAddr_Pincode: addressForm.values.commAddr_Pincode != "" ? addressForm.values.commAddr_Pincode : null,

            permAddr_FlatNo: permAddr_FlatNo != "" ? permAddr_FlatNo : null,
            permAddr_Street: permAddr_Street != "" ? permAddr_Street : null,
            permAddr_Area: permAddr_Area != "" ? permAddr_Area : null,
            permAddr_StateId: permAddr_StateId?.value != undefined ? permAddr_StateId?.value : null,
            permAddr_CityId: permAddr_CityId?.value != undefined ? permAddr_CityId?.value : null,
            permAddr_Pincode: permAddr_Pincode != "" ? permAddr_Pincode : null,

            bankName: addressForm.values.bankName != "" ? addressForm.values.bankName : null,
            bankAccountNo: addressForm.values.bankAccountNo != "" ? addressForm.values.bankAccountNo : null,
            branch: addressForm.values.branch != "" ? addressForm.values.branch : null,
            ifscCode: addressForm.values.ifscCode != "" ? addressForm.values.ifscCode : null,
            micrCode: addressForm.values.micrCode != "" ? addressForm.values.micrCode : null,

            // ///----Class INFO----////

            sectionDetailsId: sectionDetailsId?.value != undefined ? sectionDetailsId?.value : null,
            batchMasterId: batchMasterId?.value != undefined ? batchMasterId?.value : null,
            studentRollNo: classForm.values.studentRollNo != "" ? classForm.values.studentRollNo : null,
            admissionDateStamp: classForm.values.admissionDateStamp != "" ? classForm.values.admissionDateStamp : null,
            sourceName: classForm.values.sourceName != "" ? classForm.values.sourceName : null,
            transferCourseId: transferCourseId?.value != undefined ? transferCourseId?.value : null,
            referralTypeId: referralTypeId?.value != undefined ? referralTypeId?.value : null,
            referredId: referredId?.value != undefined ? referredId?.value : null,
            referredName: referredName != "" ? referredName : null,
            interimSchoolTransfer: interimSchoolTransfer != "" ? interimSchoolTransfer : null,
            schoolName: schoolNameValue != "" ? schoolNameValue : null,
            studentSchoolNameId: studentSchoolNameId != "" ? studentSchoolNameId?.value : null,
            concessionTypeId: concessionTypeId?.value != undefined ? concessionTypeId?.value : null,
            concessionAmount: classForm.values.concessionAmount != undefined ? classForm.values.concessionAmount : null,
            concessionRemarks: concessionRemarks != "" ? concessionRemarks : null,
            transportAreaMasterId: transportAreaValue != "" ? transportAreaValue : null,
            modeOfWays: modeValue ? modeValue != "" : null,
            isCollegeTransport: isCollegeTransport != "" ? isCollegeTransport : null,

            ///----Family INFO----////

            fatherName: familyForm.values.fatherName != "" ? familyForm.values.fatherName : null,
            father_AdharNumber: familyForm.values.father_AdharNumber != "" ? familyForm.values.father_AdharNumber : null,
            fatherOccupation: familyForm.values.fatherOccupation != "" ? familyForm.values.fatherOccupation : null,
            natureofJoborBusiness: familyForm.values.natureofJoborBusiness != "" ? familyForm.values.natureofJoborBusiness : null,
            fatherContactNumber: familyForm.values.fatherContactNumber != "" ? familyForm.values.fatherContactNumber : null,
            fatherEmailId: familyForm.values.fatherEmailId != "" ? familyForm.values.fatherEmailId : null,
            motherName: familyForm.values.motherName != "" ? familyForm.values.motherName : null,
            mother_AdharNumber: familyForm.values.mother_AdharNumber != "" ? familyForm.values.mother_AdharNumber : null,
            yearlyIncome: familyForm.values.yearlyIncome != "" ? familyForm.values.yearlyIncome : null,
            positionHeld: familyForm.values.positionHeld != "" ? familyForm.values.positionHeld : null,
            motherContactNumber: familyForm.values.motherContactNumber != "" ? familyForm.values.motherContactNumber : null,
            guardian_Name: familyForm.values.guardian_Name != "" ? familyForm.values.guardian_Name : null,
            guardian_email: familyForm.values.guardian_email != "" ? familyForm.values.guardian_email : null,
            guardian_phone: familyForm.values.guardian_phone != "" ? familyForm.values.guardian_phone : null,
            guardian_AdharNumber: familyForm.values.guardian_AdharNumber != "" ? familyForm.values.guardian_AdharNumber : null,
            isGuardian: isGuardian ? 1 : 0,

            // // ///----MARK INFO----////
            registerNo_X: markForm.values.registerNo_X ? markForm.values.registerNo_X : null,
            institutionNameAndAddress_X: markForm.values.institutionNameAndAddress_X ? markForm.values.institutionNameAndAddress_X : null,
            boardName_X: markForm.values.boardName_X ? markForm.values.boardName_X : null,
            mediumOfInstitution_X: markForm.values.mediumOfInstitution_X ? markForm.values.mediumOfInstitution_X : null,
            monthAndYearOfPassing_X: markForm.values.monthAndYearOfPassing_X ? markForm.values.monthAndYearOfPassing_X : null,

            registerNo_XI: markForm.values.registerNo_XI ? markForm.values.registerNo_X : null,
            institutionNameAndAddress_XI: markForm.values.institutionNameAndAddress_XI ? markForm.values.institutionNameAndAddress_XI : null,
            boardName_XI: markForm.values.boardName_XI ? markForm.values.boardName_XI : null,
            mediumOfInstitution_XI: markForm.values.mediumOfInstitution_XI ? markForm.values.mediumOfInstitution_XI : null,
            monthAndYearOfPassing_XI: markForm.values.monthAndYearOfPassing_XI ? markForm.values.monthAndYearOfPassing_XI : null,

            X_S1_studentSchoolSubject: X_S1_studentSchoolSubject?.value != undefined ? X_S1_studentSchoolSubject?.value : null,
            X_S1_markSecured: markForm.values.X_S1_markSecured,
            X_S1_maximumMarks: markForm.values.X_S1_maximumMarks,
            X_S1_monthAndYearOfPassing: markForm.values.X_S1_monthAndYearOfPassing,
            X_S1_registerNo: markForm.values.X_S1_registerNo,
            X_S1_noOfAttempts: markForm.values.X_S1_noOfAttempts,
            X_S2_studentSchoolSubject: X_S2_studentSchoolSubject?.value != undefined ? X_S2_studentSchoolSubject?.value : null,
            X_S2_markSecured: markForm.values.X_S2_markSecured,
            X_S2_maximumMarks: markForm.values.X_S2_maximumMarks,
            X_S2_monthAndYearOfPassing: markForm.values.X_S2_monthAndYearOfPassing,
            X_S2_registerNo: markForm.values.X_S2_registerNo,
            X_S2_noOfAttempts: markForm.values.X_S2_noOfAttempts,
            X_S3_studentSchoolSubject: X_S3_studentSchoolSubject?.value != undefined ? X_S3_studentSchoolSubject?.value : null,
            X_S3_markSecured: markForm.values.X_S3_markSecured,
            X_S3_maximumMarks: markForm.values.X_S3_maximumMarks,
            X_S3_monthAndYearOfPassing: markForm.values.X_S3_monthAndYearOfPassing,
            X_S3_registerNo: markForm.values.X_S3_registerNo,
            X_S3_noOfAttempts: markForm.values.X_S3_noOfAttempts,
            X_S4_studentSchoolSubject: X_S4_studentSchoolSubject?.value != undefined ? X_S4_studentSchoolSubject?.value : null,
            X_S4_markSecured: markForm.values.X_S4_markSecured,
            X_S4_maximumMarks: markForm.values.X_S4_maximumMarks,
            X_S4_monthAndYearOfPassing: markForm.values.X_S4_monthAndYearOfPassing,
            X_S4_registerNo: markForm.values.X_S4_registerNo,
            X_S4_noOfAttempts: markForm.values.X_S4_noOfAttempts,
            X_S5_studentSchoolSubject: X_S5_studentSchoolSubject?.value != undefined ? X_S5_studentSchoolSubject?.value : null,
            X_S5_markSecured: markForm.values.X_S5_markSecured,
            X_S5_maximumMarks: markForm.values.X_S5_maximumMarks,
            X_S5_monthAndYearOfPassing: markForm.values.X_S5_monthAndYearOfPassing,
            X_S5_registerNo: markForm.values.X_S5_registerNo,
            X_S5_noOfAttempts: markForm.values.X_S5_noOfAttempts,
            X_S6_studentSchoolSubject: X_S6_studentSchoolSubject?.value != undefined ? X_S6_studentSchoolSubject?.value : null,
            X_S6_markSecured: markForm.values.X_S6_markSecured,
            X_S6_maximumMarks: markForm.values.X_S6_maximumMarks,
            X_S6_monthAndYearOfPassing: markForm.values.X_S6_monthAndYearOfPassing,
            X_S6_registerNo: markForm.values.X_S6_registerNo,
            X_S6_noOfAttempts: markForm.values.X_S6_noOfAttempts,

            X_OverAll_markSecured: markForm.values.X_OverAll_markSecured,
            X_OverAll_maximumMarks: markForm.values.X_OverAll_maximumMarks,


            licenseKey: getLicenseKey,
            userAccountId: userAccountId,
        })
    }

    function updateStudentDetailsSubmit() {
        showLoader(true)
        if (updateStudentForm.isValid) {
            let updateStudentDetailsPostData = setUpdateStudentDetails()
            updateStudentDetails(updateStudentDetailsPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        setIsNavigate(`../academic/studentList`)
                        if (selectedFile != undefined) {
                            onUpload1(studentDetailsId);
                        }
                        updateStudentForm.resetForm();
                    }
                    else if (data.success == false) {
                        if (selectedFile != undefined) {
                            onUpload(studentDetailsId);
                        } else {
                            showLoader(false)
                            setAlertStatus({ message: data.message, type: "error" });
                            setIsOpen(true);
                            setIsNavigate(null);
                        }
                    }
                    else {
                        showLoader(false)
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                        setIsNavigate(null);
                    }
                }, (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                    setIsNavigate(null);
                }
            )
        } else if (updateStudentForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
            setIsNavigate(null);
        }
    }

    const [otherNationValue, setOtherNationValue] = useState('')

    const selectNationality = (e: any) => {
        let nationality = e.target.value
        setIsNationality(nationality)
        if (nationality == 'Others') {
            setIsOtherNation('Other')
        }
        else {
            setIsOtherNation('isOthers')
            setOtherNationValue('')
        }
    }

    const [physicallyChallengedTypeValue, setPhysicallyChallengedTypeValue] = useState('')
    const [physicallyChallengedPercentageValue, setPhysicallyChallengedPercentageValue] = useState('')
    const selectPhysicallyChallenged = (e: any) => {
        let physicallyChallenged = e.target.value
        setPhysicallyChallenged(physicallyChallenged)
        if (physicallyChallenged == 'Yes') {
            setIsPhysicallyChallengedDetails('YES')
        }
        else {
            setIsPhysicallyChallengedDetails('isPhysicallyChallengedDetails')
            setPhysicallyChallengedPercentageValue('')
            setPhysicallyChallengedTypeValue('')
        }
    }

    const [schoolNameValue, setSchoolNameValue] = useState('')

    const selectInterimSchoolTransfer = (e: any) => {
        setSchoolNameValue('')
        let interimSchoolTransfer = e.target.value
        setIsInterimSchoolTransfer(interimSchoolTransfer)
    }

    const [studentSchoolNameId, setStudentSchoolNameId] = useState<any>('')

    const selectSchoolName = (e: any) => {
        setStudentSchoolNameId(e)
        if (e?.value != undefined) {
            setSchoolNameValue(e?.label)
        }
    }

    const [isGuardian, setIsGuardian] = useState<any>(0);

    const handleCheckboxChange = (e: any) => {
        setIsGuardian(e.target.checked);
    };
    const [isChecked, setIsChecked] = useState(false)

    function address(e: any) {
        setIsChecked(e.target.checked)

        if (e.target.checked == true) {
            setPermAddr_FlatNo(addressForm.values.commAddr_FlatNo)
            setPermAddr_Street(addressForm.values.commAddr_Street)
            setPermAddr_Area(addressForm.values.commAddr_Area)
            setPermAddr_Pincode(addressForm.values.commAddr_Pincode)
            setPermAddr_StateId(commAddr_StateId)
            setPermAddr_CityId(commAddr_CityId)

            if (commAddr_StateId?.value != undefined) {
                getPermAddr_StateAndCity(commAddr_StateId?.value)
            }
        }
        else {
            setPermAddr_FlatNo('')
            setPermAddr_Street('')
            setPermAddr_Area('')
            setPermAddr_Pincode('')
            setPermAddr_StateId('')
            setPermAddr_CityId('')
        }
    }

    const titles = [
        'General Info',
        'Address Info',
        'Class Info',
        'Family Info',
        'Mark Info',
        'Certificate Info',
        'Admission Info'
    ];

    const handleFileChange = (e: any) => {
        if (e.target.files && e.target.files[0]) {
            setSelectedFile(e.target.files[0]);
        }
    };

    const handleDeleteAvatar = () => {
        setSelectedFile(null);
        const fileInput = document.getElementById('fileInput') as HTMLInputElement;
        if (fileInput) {
            fileInput.value = '';
        }
    };
    return (
        <>
            <PageWrapper title="Update Student">
                <SubHeader>
                    <SubHeaderLeft>
                        <Button color='info' isLink icon='ArrowBack' onClick={() => navigate(`../academic/studentList`)}>
                            Back to List
                        </Button>
                        <SubheaderSeparator />
                        {studentProfileData.map((student: any, index: any) => (
                            <div key={index}>
                                <img style={{ borderRadius: '50%' }}
                                    src={student.profilePath != null ? student.profilePath : profilePic(studentProfileData.genderId)}
                                    height='40' width='40' onError={(e: any) => pictNotLoading(e, studentProfileData.genderId)}
                                />
                                <span>
                                    <strong className='ms-3'>{student.studentName} </strong>
                                </span>
                                <span className='border border-success border-2 text-success fw-bold px-3 py-2 rounded'>
                                    {student.admissionNo}
                                </span>
                            </div>))}
                    </SubHeaderLeft>
                </SubHeader>
                <Page container='fluid'>
                    <Card>
                        <CardHeader>
                            <CardLabel icon="Assignment" color="info">
                                {[...Array(totalSteps)].map((_, index) => (
                                    <CardTitle
                                        key={index}
                                        className={index !== step ? 'd-none' : undefined}
                                    >{titles[step]}
                                    </CardTitle>
                                ))}
                            </CardLabel>
                        </CardHeader>
                        <CardBody>
                            <div className='wizard-progress position-relative'>
                                <div className='progress'>
                                    <div
                                        className='progress-bar bg-primary'
                                        role='progressbar'
                                        style={{ width: `${(100 / (totalSteps - 1)) * step}%` }}
                                        aria-valuenow={(100 / (totalSteps - 1)) * step}
                                        aria-valuemin={0}
                                        aria-valuemax={100}
                                        aria-label='progress'
                                    />
                                </div>
                                {[...Array(totalSteps)].map((child: any, index: any) => (
                                    <Popovers
                                        key={index}
                                        desc={titles[index]}
                                        trigger='hover'>
                                        <button
                                            type='button'
                                            className={classNames(
                                                'wizard-progress-btn',
                                                'position-absolute p-0 top-0',
                                                'translate-middle',
                                                'btn btn-sm',
                                                {
                                                    [`btn-primary`]: step >= index,
                                                    [`btn-${themeStatus}`]: step < index,
                                                },
                                                'rounded-pill',
                                            )}
                                            style={{
                                                left: `${(100 / (totalSteps - 1)) * index}%`,
                                            }}
                                            onClick={() => { handleStepClick(index) }}>
                                            {index + 1}
                                        </button>
                                    </Popovers>
                                ))}
                            </div>
                            {step == 0 && (
                                <Card shadow='none' title="GeneralInfo" stretch>
                                    <form noValidate onSubmit={updateStudentForm.handleSubmit}>
                                        <div className="row g-4">
                                            <div className='col-12'>
                                                <div className='row g-4 align-items-center'>
                                                    <div className="col-6 g-4">
                                                        <div className="col-lg-12 g-4">
                                                            <div className="row g-4">
                                                                <div className="col-lg-6">
                                                                    <FormGroup id='mediumId' label='Select Medium' isFloating>
                                                                        <SearchableSelect isFloating ariaLabel='' placeholder="Select Medium"
                                                                            onChange={selectMedium}
                                                                            onBlur={updateStudentForm.handleBlur}
                                                                            value={mediumId}
                                                                            isValid={updateStudentForm.isValid}
                                                                            isTouched={updateStudentForm.touched.mediumId} invalidFeedback={updateStudentForm.errors.mediumId}
                                                                            list={mediumData.map((data: any) => ({ value: data.mediumId, label: data.medium }))} />

                                                                    </FormGroup>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <FormGroup id='standardDetailsId' label='Select Standard' isFloating>
                                                                        <SearchableSelect isFloating ariaLabel='' placeholder="Select Standard"
                                                                            onChange={selectStandard}
                                                                            onBlur={updateStudentForm.handleBlur}
                                                                            value={standardDetailsId}
                                                                            isValid={updateStudentForm.isValid}
                                                                            isTouched={updateStudentForm.touched.standardDetailsId} invalidFeedback={updateStudentForm.errors.standardDetailsId}
                                                                            list={standardData.map((data: any) => ({ value: data.standardDetailsId, label: data.standardName }))} />

                                                                    </FormGroup>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            {standardDetailsId?.value == 11 || standardDetailsId?.value == 12 ?
                                                                <div className='col-lg-6 mt-4'>
                                                                    <FormGroup id='courseDetailsId' label='Course' isFloating>
                                                                        <SearchableSelect isFloating
                                                                            ariaLabel='Course'
                                                                            onChange={selectCourse}
                                                                            onBlur={updateStudentForm.handleBlur}
                                                                            value={courseDetailsId}
                                                                            isValid={updateStudentForm.isValid}
                                                                            isTouched={updateStudentForm.touched.courseDetailsId}
                                                                            invalidFeedback={updateStudentForm.errors.courseDetailsId}
                                                                            list={courseDetailsData.map((data: any) => (
                                                                                { value: data.courseDetailsId, label: data.courseName }
                                                                            ))}
                                                                            required />
                                                                    </FormGroup>
                                                                </div> : null
                                                            }
                                                            <div className="col-lg-6 mt-3">
                                                                <FormGroup id="tempNo" label="Temp No" isFloating>
                                                                    <Input type="text" placeholder="Select Temp No"
                                                                        onChange={updateStudentForm.handleChange}
                                                                        value={updateStudentForm.values.tempNo}
                                                                        disabled />
                                                                </FormGroup>
                                                            </div>
                                                            <div className="col-lg-6 mt-3">
                                                                <FormGroup id="admissionNo" label="Admission No" isFloating>
                                                                    <Input type="text" placeholder="Admission No"
                                                                        onChange={updateStudentForm.handleChange}
                                                                        value={updateStudentForm.values.admissionNo} disabled={userTypeId != 1} />
                                                                </FormGroup>
                                                            </div>
                                                            {standardDetailsId?.value == 11 || standardDetailsId?.value == 12 ?
                                                                <div className="col-lg-6 mt-3">
                                                                    <FormGroup id="firstName" label="First Name" isFloating>
                                                                        <Input type="text" placeholder="Enter First Name"
                                                                            onChange={updateStudentForm.handleChange}
                                                                            value={updateStudentForm.values.firstName}
                                                                            onBlur={updateStudentForm.handleBlur}
                                                                            isValid={updateStudentForm.isValid}
                                                                            isTouched={updateStudentForm.touched.firstName}
                                                                            invalidFeedback={updateStudentForm.errors.firstName} />
                                                                    </FormGroup>
                                                                </div> : null
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className='col-12'>
                                                            <div className='row g-4 align-items-center'>
                                                                <div className='col-lg-auto'>
                                                                    {selectedFile ?
                                                                        <img style={{ borderRadius: '50%' }}
                                                                            src={URL.createObjectURL(selectedFile)}
                                                                            height='130' width='130' />
                                                                        :
                                                                        <img style={{ borderRadius: '50%' }}
                                                                            src={profilePath != null ? profilePath : profilePic(studentProfileData.genderId)}
                                                                            height='130' width='130' onError={(e: any) => pictNotLoading(e, studentProfileData.genderId)}
                                                                        />}
                                                                </div>
                                                                <div className='col-lg mt-3'>
                                                                    <div className='row g-4'>
                                                                        <div className='col-10'>
                                                                            <Input
                                                                                id="fileInput"
                                                                                type='file'
                                                                                autoComplete='photo'
                                                                                ariaLabel='Upload image file'
                                                                                onChange={handleFileChange}
                                                                            />
                                                                        </div>
                                                                        <div className='col-12'>
                                                                            <Button
                                                                                color='dark'
                                                                                isLight
                                                                                icon='Delete'
                                                                                onClick={handleDeleteAvatar}>
                                                                                Delete Avatar
                                                                            </Button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {standardDetailsId?.value != 11 && standardDetailsId?.value != 12 ?
                                                <div className="col-lg-3 mg-t-20 mg-lg-t-0">
                                                    <FormGroup id="firstName" label="First Name" isFloating>
                                                        <Input type="text" placeholder="Enter First Name"
                                                            onChange={updateStudentForm.handleChange}
                                                            value={updateStudentForm.values.firstName}
                                                            onBlur={updateStudentForm.handleBlur}
                                                            isValid={updateStudentForm.isValid}
                                                            isTouched={updateStudentForm.touched.firstName}
                                                            invalidFeedback={updateStudentForm.errors.firstName} />
                                                    </FormGroup>
                                                </div> : null
                                            }
                                            <div className="col-lg-3 mg-t-20 mg-lg-t-0">
                                                <FormGroup id="lastName" label="Last Name" isFloating>
                                                    <Input type="text" placeholder="Enter Last Name"
                                                        onChange={updateStudentForm.handleChange}
                                                        value={updateStudentForm.values.lastName} />
                                                </FormGroup>
                                            </div>

                                            <div className="col-lg-3 mg-t-20 mg-lg-t-15">
                                                <FormGroup id="studentNameTamil" label="Student Name (Tamil)" isFloating>
                                                    <Input type="text" placeholder="Enter Student Name (Tamil)"
                                                        onChange={updateStudentForm.handleChange}
                                                        value={updateStudentForm.values.studentNameTamil} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-3 mg-t-20 mg-lg-t-15" >
                                                <FormGroup id="genderId" label="Gender" isFloating>
                                                    <SearchableSelect isFloating ariaLabel="" placeholder="Select Gender"
                                                        onChange={(e: any) => setGenderId(e)}
                                                        value={genderId}
                                                        list={genderData.map((data: any) => (
                                                            { value: data.genderId, label: data.gender }
                                                        ))} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-3 mg-t-20 mg-lg-t-15">
                                                <FormGroup id="dateOfBirth" label="Date Of Birth" isFloating>
                                                    <Input type="date" placeholder="Date Of Birth"
                                                        onChange={updateStudentForm.handleChange}
                                                        value={updateStudentForm.values.dateOfBirth} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-3 mg-t-20 mg-lg-t-15">
                                                <FormGroup id="smsToBeSentNumber" label="SMS Sent Number" isFloating>
                                                    <Input type="text" placeholder="SMS Sent Number"
                                                        onChange={updateStudentForm.handleChange}
                                                        value={updateStudentForm.values.smsToBeSentNumber}
                                                        onBlur={updateStudentForm.handleBlur}
                                                        isValid={updateStudentForm.isValid}
                                                        isTouched={updateStudentForm.touched.smsToBeSentNumber}
                                                        invalidFeedback={updateStudentForm.errors.smsToBeSentNumber} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-3 mg-t-20 mg-lg-t-15">
                                                <FormGroup id="email" label="Email" isFloating>
                                                    <Input type="text" placeholder="Enter Email"
                                                        onChange={updateStudentForm.handleChange}
                                                        value={updateStudentForm.values.email}
                                                        onBlur={updateStudentForm.handleBlur}
                                                        isValid={updateStudentForm.isValid}
                                                        isTouched={updateStudentForm.touched.email}
                                                        invalidFeedback={updateStudentForm.errors.email} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-3 mg-t-20 mg-lg-t-15">
                                                <FormGroup id="emisNo" label="EMIS No" isFloating>
                                                    <Input type="text" placeholder="Enter EMIS No"
                                                        onChange={updateStudentForm.handleChange}
                                                        value={updateStudentForm.values.emisNo} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-3 mg-t-20 mg-lg-t-15">
                                                <FormGroup id="aadharCard" label="Aadhar Number" isFloating>
                                                    <Input type="text" placeholder="Enter Aadhar Number"
                                                        onChange={updateStudentForm.handleChange}
                                                        value={updateStudentForm.values.aadharCard}
                                                        onBlur={updateStudentForm.handleBlur}
                                                        isValid={updateStudentForm.isValid}
                                                        isTouched={updateStudentForm.touched.aadharCard}
                                                        invalidFeedback={updateStudentForm.errors.aadharCard} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-3 mg-t-20 mg-lg-t-15">
                                                <FormGroup id="bloodGroupId" label="Blood Group" isFloating>
                                                    <SearchableSelect isFloating ariaLabel="" placeholder="Select Blood Group"
                                                        onChange={(e: any) => setBloodGroupId(e)}
                                                        value={bloodGroupId}
                                                        list={bloodData.map((data: any) => (
                                                            { value: data.bloodGroupId, label: data.groupName }
                                                        ))} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-3 mg-t-20 mg-lg-t-15">
                                                <FormGroup id="motherTongue" label="Mother Tongue" isFloating>
                                                    <SearchableSelect isFloating ariaLabel="" placeholder="Select Mother Tongue"
                                                        onChange={(e: any) => setMotherTongue(e)}
                                                        value={motherTongue}
                                                        list={motherTongueData.map((data: any) => (
                                                            { value: data.motherTongue, label: data.motherTongue }
                                                        ))} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-3 mg-t-20 mg-lg-t-15">
                                                <FormGroup id="nationality" label="Nationality" isFloating>
                                                    <Select ariaLabel="" placeholder="Select Nationality"
                                                        onChange={selectNationality}
                                                        value={nationality}>
                                                        <option value='Indian'>Indian</option>
                                                        <option value='Others'>Others</option>
                                                    </Select>
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-3 mg-t-20 mg-lg-t-15 ">
                                                <FormGroup id="otherNation" label="Other Nation" isFloating>
                                                    <Input type="text" placeholder="Enter Country"
                                                        onInput={(e: any) => setOtherNationValue(e.target.value)}
                                                        value={otherNationValue} disabled={isOtherNation == 'Other' ? false : true} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-3 mg-t-20 mg-lg-t-15">
                                                <FormGroup id="religionId" label="Religion" isFloating>
                                                    <SearchableSelect isFloating ariaLabel="" placeholder="Select Religion"
                                                        onChange={(e: any) => setReligionId(e)}
                                                        value={religionId}
                                                        list={religionData.map((data: any) => (
                                                            { value: data.religionId, label: data.religion }
                                                        ))} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-3 mg-t-20 mg-lg-t-15">
                                                <FormGroup id="category" label="Category" isFloating>
                                                    <SearchableSelect isFloating ariaLabel="" placeholder="Select Category"
                                                        onChange={(e: any) => setCategory(e)}
                                                        value={category}
                                                        list={categoryData.map((data: any) => (
                                                            { value: data.category, label: data.category }
                                                        ))} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-3 mg-t-20 mg-lg-t-15">
                                                <FormGroup id="casteDetailsId" label="Caste" isFloating>
                                                    <SearchableSelect isFloating ariaLabel="" placeholder="Select Caste"
                                                        onChange={(e: any) => setCasteDetailsId(e)}
                                                        value={casteDetailsId}
                                                        list={casteDetailsData.map((data: any) => (
                                                            { value: data.casteDetailsId, label: data.casteName }
                                                        ))} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-3 mg-t-20 mg-lg-t-15">
                                                <FormGroup id="subcaste" label="Sub Caste" isFloating>
                                                    <Input type="text" placeholder="Select Sub Caste"
                                                        onChange={updateStudentForm.handleChange}
                                                        value={updateStudentForm.values.subcaste} />
                                                </FormGroup>
                                            </div><div className="col-lg-3 mg-t-20 mg-lg-t-15" >
                                                <FormGroup id="physicallyChallenged" label="Physically Challenged" isFloating>
                                                    <Select ariaLabel="" placeholder="Select Physically Challenged"
                                                        onChange={selectPhysicallyChallenged}
                                                        value={physicallyChallenged}>
                                                        <option value='Yes'>Yes</option>
                                                        <option value='No'>No</option>
                                                    </Select>
                                                </FormGroup>
                                            </div>
                                            {isPhysicallyChallengedDetails == 'YES' ? <>
                                                <div className="col-lg-3 mg-t-20 mg-lg-t-15">
                                                    <FormGroup id="physicallyChallengedType" label="physically Challenged Type" isFloating>
                                                        <Input type="text" placeholder="physically Challenged Type"
                                                            onChange={(e: any) => setPhysicallyChallengedTypeValue(e.target.value)}
                                                            value={physicallyChallengedTypeValue} />
                                                    </FormGroup>
                                                </div>
                                                <div className="col-lg-3 mg-t-20 mg-lg-t-15">
                                                    <FormGroup id="physicallyChallengedPercentage" label="physically Challenged Percentage" isFloating>
                                                        <Input type="text" placeholder="physically Challenged Percentage"
                                                            onChange={(e: any) => setPhysicallyChallengedPercentageValue(e.target.value)}
                                                            value={physicallyChallengedPercentageValue}
                                                            onKeyDown={(e: any) => onlyAllowNumber(e)} />
                                                    </FormGroup>
                                                </div>
                                            </> : null}
                                        </div>
                                        <div style={{ textAlign: 'right' }} className="mt-4">
                                            <Button color="primary" type="submit" isLight>Next</Button>
                                        </div>
                                    </form>
                                </Card>
                            )}
                            {step == 1 && (
                                <Card shadow='none'>
                                    <form noValidate onSubmit={addressForm.handleSubmit}>

                                        <div className="row g-4">
                                            <CardLabel icon="LocationOn" color="info">
                                                <CardTitle className="h5">ADDRESS INFO</CardTitle>
                                            </CardLabel>
                                            <div className="col-lg-4">
                                                <FormGroup id="commAddr_FlatNo" label="Flat No Or Name" isFloating>
                                                    <Input type="text" placeholder="Flat No or Name"
                                                        onChange={addressForm.handleChange}
                                                        value={addressForm.values.commAddr_FlatNo} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-4">
                                                <FormGroup id="commAddr_Street" label="Street" isFloating>
                                                    <Input type="text" placeholder="Street"
                                                        onChange={addressForm.handleChange}
                                                        value={addressForm.values.commAddr_Street} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-4">
                                                <FormGroup id="commAddr_Area" label="Area/Locality" isFloating>
                                                    <Input type="text" placeholder="Area/Locality"
                                                        onChange={addressForm.handleChange}
                                                        value={addressForm.values.commAddr_Area} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-4">
                                                <FormGroup id="commAddr_StateId" label="State" isFloating>
                                                    <SearchableSelect isFloating ariaLabel="" placeholder="Select State"
                                                        onChange={selectState}
                                                        value={commAddr_StateId}
                                                        list={statesData.map((data: any) => (
                                                            { value: data.stateId, label: data.stateName }
                                                        ))} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-4">
                                                <FormGroup id="commAddr_CityId" label="City" isFloating>
                                                    <SearchableSelect isFloating ariaLabel="" placeholder="Select City"
                                                        onChange={selectCity}
                                                        value={commAddr_CityId}
                                                        list={cityData.map((data: any) => (
                                                            { value: data.cityId, label: data.cityName }
                                                        ))} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-4">
                                                <FormGroup id="commAddr_Pincode" label="PinCode" isFloating>
                                                    <Input type="text" placeholder="Enter PinCode"
                                                        onChange={addressForm.handleChange}
                                                        value={addressForm.values.commAddr_Pincode}
                                                        onBlur={addressForm.handleBlur}
                                                        isValid={addressForm.isValid}
                                                        isTouched={addressForm.touched.commAddr_Pincode}
                                                        invalidFeedback={addressForm.errors.commAddr_Pincode} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-12">
                                                <FormGroup id="checkbox">
                                                    <Checks
                                                        type='checkbox'
                                                        label='Permanent Address Same As Address For Communication Click Checkbox.'
                                                        onChange={address}
                                                        //checked={isChecked}
                                                        checked={permAddr_FlatNo != '' && permAddr_Street != '' && permAddr_Area != '' && permAddr_StateId?.value != undefined && permAddr_CityId?.value != undefined && permAddr_Pincode != '' ? true : isChecked}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-4">
                                                <FormGroup id="permAddr_FlatNo" label="Flat No Or Name" isFloating>
                                                    <Input type="text" placeholder="Flat No or Name"
                                                        onInput={(e: any) => setPermAddr_FlatNo(e.target.value)}
                                                        value={permAddr_FlatNo} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-4">
                                                <FormGroup id="permAddr_Street" label="Street" isFloating>
                                                    <Input type="text" placeholder="Street"
                                                        onInput={(e: any) => setPermAddr_Street(e.target.value)}
                                                        value={permAddr_Street} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-4">
                                                <FormGroup id="permAddr_Area" label="Area/Locality" isFloating>
                                                    <Input type="text" placeholder="Area/Locality"
                                                        onInput={(e: any) => setPermAddr_Area(e.target.value)}
                                                        value={permAddr_Area} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-4">
                                                <FormGroup id="permAddr_StateId" label="State" isFloating>
                                                    <SearchableSelect isFloating ariaLabel="" placeholder="Select State"
                                                        onChange={selectPermAddr_State}
                                                        value={permAddr_StateId}
                                                        list={permAddr_StateData.map((data: any) => (
                                                            { value: data.stateId, label: data.stateName }
                                                        ))} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-4">
                                                <FormGroup id="permAddr_CityId" label="City" isFloating>
                                                    <SearchableSelect isFloating ariaLabel="" placeholder="Select City"
                                                        onChange={selectPermAddr_City}
                                                        value={permAddr_CityId}
                                                        list={permAddr_CityData.map((data: any) => (
                                                            { value: data.cityId, label: data.cityName }
                                                        ))} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-4">
                                                <FormGroup id="permAddr_Pincode" label="PinCode" isFloating>
                                                    <Input type="text" placeholder="Enter PinCode"
                                                        onInput={(e: any) => setPermAddr_Pincode(e.target.value)}
                                                        onChange={addressForm.handleChange}
                                                        value={permAddr_Pincode}
                                                        onBlur={addressForm.handleBlur}
                                                        isValid={addressForm.isValid}
                                                        isTouched={addressForm.touched.permAddr_Pincode}
                                                        invalidFeedback={addressForm.errors.permAddr_Pincode} />
                                                </FormGroup>
                                            </div>

                                            <div className="col-lg-12">
                                                <CardLabel icon="MonetizationOn" color="info">
                                                    <CardTitle className="h5">BANK INFO</CardTitle>
                                                </CardLabel>
                                            </div>
                                            <div className="col-lg-4">
                                                <FormGroup id="bankName" label="Bank Name" isFloating>
                                                    <Input type="text" placeholder="Enter Bank Name"
                                                        onChange={addressForm.handleChange}
                                                        value={addressForm.values.bankName} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-4">
                                                <FormGroup id="branch" label="Branch" isFloating>
                                                    <Input type="text" placeholder="Enter Branch"
                                                        onChange={addressForm.handleChange}
                                                        value={addressForm.values.branch} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-4">
                                                <FormGroup id="bankAccountNo" label="Bank Account No" isFloating>
                                                    <Input type="text" placeholder="Enter Bank Account No"
                                                        onChange={addressForm.handleChange}
                                                        value={addressForm.values.bankAccountNo} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-4">
                                                <FormGroup id="ifscCode" label="IFSC Code" isFloating>
                                                    <Input type="text" placeholder="Enter IFSC Code"
                                                        onChange={addressForm.handleChange}
                                                        value={addressForm.values.ifscCode} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-4">
                                                <FormGroup id="micrCode" label="MICR Code" isFloating>
                                                    <Input type="text" placeholder="Enter MICR Code"
                                                        onChange={addressForm.handleChange}
                                                        value={addressForm.values.micrCode} />
                                                </FormGroup>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }} className="mt-4">
                                            <Button color="primary" onClick={handlePrevious} type="submit" isLink>Previous</Button>
                                            <Button color="primary" type="submit" isLight>Next</Button>
                                        </div>
                                        {/* <CardFooter>
                                            <CardFooterLeft>
                                                <Button color="primary" onClick={handlePrevious} type="submit" isLink>Previous</Button>
                                            </CardFooterLeft>
                                            <CardFooterRight>
                                                <Button color="primary" type="submit" isLight>Next</Button>
                                            </CardFooterRight>
                                        </CardFooter> */}
                                    </form>
                                </Card>
                            )}
                            {step == 2 && (
                                <Card shadow='none'>
                                    <form noValidate onSubmit={classForm.handleSubmit}>
                                        <div className="row g-4">
                                            <div className="col-lg-3">
                                                <FormGroup id="batchMasterId" label="Batch" isFloating>
                                                    <SearchableSelect isFloating ariaLabel=""
                                                        onChange={selectBatch}
                                                        value={batchMasterId}
                                                        list={batchMasterData.map((data: any) => (
                                                            { value: data.batchMasterId, label: data.batchName }
                                                        ))} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-3">
                                                <FormGroup id="sectionDetailsId" label="Section" isFloating>
                                                    <SearchableSelect isFloating ariaLabel="" placeholder="Select Section"
                                                        onChange={selectSection}
                                                        value={sectionDetailsId}
                                                        list={sectionDetailsData.map((data: any) => (
                                                            { value: data.sectionDetailsId, label: data.sectionName }
                                                        ))} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-3">
                                                <FormGroup id="admissionDateStamp" label="Admission Date" isFloating>
                                                    <Input type='date' placeholder="Enter Admission Date"
                                                        onChange={classForm.handleChange}
                                                        value={classForm.values.admissionDateStamp} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-3">
                                                <FormGroup id="sourceName" label="Source" isFloating>
                                                    <Input type="text" placeholder="Enter Source"
                                                        onChange={classForm.handleChange}
                                                        value={classForm.values.sourceName} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-3">
                                                <FormGroup id="referralTypeId" label="Reference (if any)" isFloating>
                                                    <SearchableSelect isFloating ariaLabel="" placeholder="Select Reference (if any)"
                                                        onChange={selectReferralType}
                                                        value={referralTypeId}
                                                        list={referralTypeData.map((data: any) => (
                                                            { value: data.referralTypeId, label: data.referralType }
                                                        ))} />
                                                </FormGroup>
                                            </div>
                                            {isReferralTypeOthers ?
                                                <div className="col-lg-3">
                                                    <FormGroup id="referredId" label="Reference" isFloating>
                                                        <SearchableSelect isFloating ariaLabel="" placeholder="Select Referred Person"
                                                            onChange={(e: any) => { setReferredId(e) }}
                                                            value={referredId}
                                                            list={userListByUserTypeData.map((data: any) => (
                                                                { value: data.referredId, label: data.registerNoForStudentAndStaff }
                                                            ))} />
                                                    </FormGroup>
                                                </div> : <div className="col-lg-3">
                                                    <FormGroup id="referredName" label="Referred Person" isFloating>
                                                        <Input type="text" placeholder="Enter Referred Person"
                                                            onInput={(e: any) => { setReferredName(e.target.value) }}
                                                            value={referredName} />
                                                    </FormGroup>
                                                </div>}
                                            <div className="col-lg-3">
                                                <FormGroup id="concessionTypeId" label="Concession Type" isFloating>
                                                    <SearchableSelect isFloating ariaLabel="" placeholder="Select Concession Type"
                                                        onChange={selectConcessionTypeId}
                                                        value={concessionTypeId}
                                                        list={concessionData.map((data: any) => (
                                                            { value: data.concessionTypeId, label: data.concessionType }
                                                        ))} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-3">
                                                <FormGroup id="concessionAmount" label="Total Concession Amount" isFloating>
                                                    <Input type="number" placeholder="Total Concession Amount"
                                                        onChange={classForm.handleChange}
                                                        value={classForm.values.concessionAmount} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-3">
                                                <FormGroup id="concessionRemarks" label="Concession Remark" isFloating>
                                                    <Input type="text" placeholder="Enter Concession Remark"
                                                        onChange={classForm.handleChange}
                                                        onInput={(e: any) => setConcessionRemarks(e.target.value)}
                                                        value={concessionRemarks} disabled={concessionTypeId?.value != undefined ? false : true} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-3">
                                                <FormGroup id="interimSchoolTransfer" label="Interim School Transfer ?" isFloating>
                                                    <Select ariaLabel="" placeholder="Select Interim School Transfer ?"
                                                        onChange={selectInterimSchoolTransfer}
                                                        value={interimSchoolTransfer}>
                                                        <option value='Yes'>Yes</option>
                                                        <option value='No'>No</option>
                                                    </Select>
                                                </FormGroup>
                                            </div>

                                            <div className="col-lg-3">
                                                <FormGroup id="schoolName" label="School Name" isFloating>
                                                    <SearchableSelect isFloating placeholder="Select School Name"
                                                        ariaLabel='School Name'
                                                        onChange={selectSchoolName}
                                                        value={studentSchoolNameId}
                                                        disabled={interimSchoolTransfer == 'Yes' ? false : true}
                                                        list={studentSchoolNameData.map((data: any) => (
                                                            { value: data.studentSchoolNameId, label: data.schoolName }
                                                        ))}
                                                    />
                                                </FormGroup>
                                            </div>

                                            {/* <div className="col-lg-3">
                                                <FormGroup id="schoolName" label="School Name" isFloating>
                                                    <Input type="text" placeholder="Enter School Name"
                                                        onInput={(e: any) => setSchoolNameValue(e.target.value)}
                                                        value={schoolNameValue} disabled={interimSchoolTransfer == 'Yes' ? false : true} />
                                                </FormGroup>
                                            </div> */}
                                            {/* <div className="col-lg-3 ">
                                                <FormGroup id="isCollegeTransport" label="Transport" isFloating>
                                                    <Select ariaLabel="" placeholder="Select Transport"
                                                        onChange={selectTransport}
                                                        value={isCollegeTransport}>
                                                        <option value='Yes'>Yes</option>
                                                        <option value='No'>No</option>
                                                    </Select>
                                                </FormGroup>
                                            </div> */}
                                            {/* {isTransportDetails == 'isAreaDetails' ?
                                                <>
                                                    <div className="col-lg-3 ">
                                                        <FormGroup id="transportAreaMasterId" label="Area of Student" isFloating>
                                                            <Select ariaLabel="" placeholder="Select Area"
                                                                onChange={(e: any) => { setTransportAreaValue(e.target.value) }}
                                                                value={transportAreaValue}
                                                                list={transportAreaMasterData.map((data: any) => (
                                                                    { value: data.transportAreaMasterId, text: data.areaName }
                                                                ))} />
                                                        </FormGroup>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <FormGroup id="modeOfWays" label="Mode Of Ways" isFloating>
                                                            <Select ariaLabel="" placeholder="Select Ways"
                                                                onChange={(e: any) => { setModeValue(e.target.value) }}
                                                                value={modeValue}>
                                                                <option value='1'>One Way</option>
                                                                <option value='2'>Two Way</option>
                                                            </Select>
                                                        </FormGroup>
                                                    </div>
                                                </> : null} */}

                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }} className="mt-4">
                                            <Button color="primary" onClick={handlePrevious} type="submit" isLink>Previous</Button>
                                            <Button color="primary" type="submit" isLight>Next</Button>
                                        </div>

                                        {/* <CardFooter>
                                            <CardFooterLeft>
                                                <Button color="primary" onClick={handlePrevious} type="submit" isLink>Previous</Button>
                                            </CardFooterLeft>
                                            <CardFooterRight>
                                                <Button color="primary" type="submit" isLight>Next</Button>
                                            </CardFooterRight>
                                        </CardFooter> */}
                                    </form>
                                </Card>
                            )}
                            {step == 3 && (
                                <Card shadow='none'>
                                    <form noValidate onSubmit={familyForm.handleSubmit}>
                                        <div className="row g-4">
                                            <div className="col-lg-3">
                                                <FormGroup id="fatherName" label="Father Name" isFloating>
                                                    <Input type="text" placeholder="Enter Father Name"
                                                        onChange={familyForm.handleChange}
                                                        value={familyForm.values.fatherName} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-3">
                                                <FormGroup id="father_AdharNumber" label="Father Aadhar Number" isFloating>
                                                    <Input type="text" placeholder="Enter Father Aadhar Number"
                                                        onChange={familyForm.handleChange}
                                                        value={familyForm.values.father_AdharNumber}
                                                        onBlur={familyForm.handleBlur}
                                                        isValid={familyForm.isValid}
                                                        isTouched={familyForm.touched.father_AdharNumber}
                                                        invalidFeedback={familyForm.errors.father_AdharNumber} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-3">
                                                <FormGroup id="fatherOccupation" label="Father Occupation" isFloating>
                                                    <Select ariaLabel="" placeholder="Select Father Occupation"
                                                        onChange={familyForm.handleChange}
                                                        value={familyForm.values.fatherOccupation}>
                                                        <option value='Job'>Job</option>
                                                        <option value='Business'>Business</option>
                                                    </Select>
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-3">
                                                <FormGroup id="natureofJoborBusiness" label="Nature of Job/Business" isFloating>
                                                    <Input type="text" placeholder="Enter Nature of Job/Business"
                                                        onChange={familyForm.handleChange}
                                                        value={familyForm.values.natureofJoborBusiness} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-3">
                                                <FormGroup id="fatherContactNumber" label="Mobile Number" isFloating>
                                                    <Input type="text" placeholder="Enter Mobile Number"
                                                        onChange={familyForm.handleChange}
                                                        value={familyForm.values.fatherContactNumber}
                                                        onBlur={familyForm.handleBlur}
                                                        isValid={familyForm.isValid}
                                                        isTouched={familyForm.touched.fatherContactNumber}
                                                        invalidFeedback={familyForm.errors.fatherContactNumber} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-3">
                                                <FormGroup id="fatherEmailId" label="Father Email Id" isFloating>
                                                    <Input type="text" placeholder="Enter Father Email Id"
                                                        onChange={familyForm.handleChange}
                                                        value={familyForm.values.fatherEmailId}
                                                        onBlur={familyForm.handleBlur}
                                                        isValid={familyForm.isValid}
                                                        isTouched={familyForm.touched.fatherEmailId}
                                                        invalidFeedback={familyForm.errors.fatherEmailId} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-3">
                                                <FormGroup id="motherName" label="Mother Name" isFloating>
                                                    <Input type="text" placeholder="Enter Mother Name"
                                                        onChange={familyForm.handleChange}
                                                        value={familyForm.values.motherName} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-3">
                                                <FormGroup id="mother_AdharNumber" label="Mother Aadhar Number" isFloating>
                                                    <Input type="text" placeholder="Enter Mother Aadhar Number"
                                                        onChange={familyForm.handleChange}
                                                        value={familyForm.values.mother_AdharNumber}
                                                        onBlur={familyForm.handleBlur}
                                                        isValid={familyForm.isValid}
                                                        isTouched={familyForm.touched.mother_AdharNumber}
                                                        invalidFeedback={familyForm.errors.mother_AdharNumber} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-3">
                                                <FormGroup id="yearlyIncome" label="Yearly Income" isFloating>
                                                    <Input type="text" placeholder="Enter Yearly Income"
                                                        onChange={familyForm.handleChange}
                                                        value={familyForm.values.yearlyIncome} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-3">
                                                <FormGroup id="positionHeld" label="Position held" isFloating>
                                                    <Input type="text" placeholder="Enter Position held"
                                                        onChange={familyForm.handleChange}
                                                        value={familyForm.values.positionHeld} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-3">
                                                <FormGroup id="motherContactNumber" label="Mother Mobile Number" isFloating>
                                                    <Input type="text" placeholder="Enter Mother Mobile Number"
                                                        onChange={familyForm.handleChange}
                                                        value={familyForm.values.motherContactNumber}
                                                        onBlur={familyForm.handleBlur}
                                                        isValid={familyForm.isValid}
                                                        isTouched={familyForm.touched.motherContactNumber}
                                                        invalidFeedback={familyForm.errors.motherContactNumber} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-12">
                                                <FormGroup id="isGuardian">
                                                    <Checks
                                                        type='checkbox'
                                                        label='Lives With Guardian Click Checkbox.'
                                                        onChange={handleCheckboxChange}
                                                        checked={isGuardian == 0 ? false : true}
                                                    />
                                                </FormGroup>
                                            </div>
                                            {isGuardian == '1' ? (<><div className="col-lg-3">
                                                <FormGroup id="guardian_Name" label="Guardian Name" isFloating>
                                                    <Input type="text" placeholder="Enter Guardian Name"
                                                        onChange={familyForm.handleChange}
                                                        value={familyForm.values.guardian_Name} />
                                                </FormGroup>
                                            </div>
                                                <div className="col-lg-3">
                                                    <FormGroup id="guardian_AdharNumber" label="Guardian Aadhar Number" isFloating>
                                                        <Input type="text" placeholder="Enter Guardian Aadhar Number"
                                                            onChange={familyForm.handleChange}
                                                            value={familyForm.values.guardian_AdharNumber}
                                                            onBlur={familyForm.handleBlur}
                                                            isValid={familyForm.isValid}
                                                            isTouched={familyForm.touched.guardian_AdharNumber}
                                                            invalidFeedback={familyForm.errors.guardian_AdharNumber} />
                                                    </FormGroup>
                                                </div>
                                                <div className="col-lg-3">
                                                    <FormGroup id="guardian_phone" label="Guardian Mobile Number" isFloating>
                                                        <Input type="text" placeholder="Enter Guardian Mobile Number"
                                                            onChange={familyForm.handleChange}
                                                            value={familyForm.values.guardian_phone}
                                                            onBlur={familyForm.handleBlur}
                                                            isValid={familyForm.isValid}
                                                            isTouched={familyForm.touched.guardian_phone}
                                                            invalidFeedback={familyForm.errors.guardian_phone} />
                                                    </FormGroup>
                                                </div>
                                                <div className="col-lg-3">
                                                    <FormGroup id="guardian_email" label="Guardian Email Id" isFloating>
                                                        <Input type="text" placeholder="Enter Guardian Email Id"
                                                            onChange={familyForm.handleChange}
                                                            value={familyForm.values.guardian_email}
                                                            onBlur={familyForm.handleBlur}
                                                            isValid={familyForm.isValid}
                                                            isTouched={familyForm.touched.guardian_email}
                                                            invalidFeedback={familyForm.errors.guardian_email} />
                                                    </FormGroup>
                                                </div> </>) : null}
                                        </div>

                                        {standardDetailsId?.value == 11 || standardDetailsId?.value == 12 ?
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }} className="mt-4">
                                                <Button color="primary" onClick={handlePrevious} type="submit" isLink>Previous</Button>
                                                <Button color="primary" type="submit" isLight>Next</Button>
                                            </div> :
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }} className="mt-4">
                                                <Button color="primary" onClick={handlePrevious} type="submit" isLink>Previous</Button>
                                                <Button color="primary" type="submit">Submit</Button>
                                            </div>
                                        }
                                    </form>
                                </Card>
                            )}
                            {step == 4 && (
                                <Card shadow='none'>
                                    <form noValidate onSubmit={markForm.handleSubmit}>
                                        {/* <CardBody> */}
                                        <div className="row gap-4">
                                            <CardLabel icon="school" color="info">
                                                <CardTitle className="h5">STUDENT MARK INFO</CardTitle>
                                            </CardLabel>

                                            <div className="row">
                                                <table className='table table-modern table-hover text-nowrap'>
                                                    <thead >
                                                        <tr className='table-primary'>
                                                            <th >S.NO</th>
                                                            <th >CLASS</th>
                                                            <th >REGISTER NO</th>
                                                            <th >NAME & ADDRESS OF THE INSTITUTION</th>
                                                            <th >NAME OF BOARD</th>
                                                            <th >MEDIUM OF INSTRUCTION</th>
                                                            <th >MONTH & YEAR OF PASSING</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="text-center fw-bold">1</td>
                                                            <td className="text-center fw-bold">10 STD</td>
                                                            <td><Input id='registerNo_X' type="text" style={{ width: '80' }}
                                                                onChange={markForm.handleChange}
                                                                value={markForm.values.registerNo_X} /></td>
                                                            <td><Input id='institutionNameAndAddress_X' type="text" style={{ width: '80' }}
                                                                onChange={markForm.handleChange}
                                                                value={markForm.values.institutionNameAndAddress_X} /></td>
                                                            <td><Input id='boardName_X' type="text" style={{ width: '80' }}
                                                                onChange={markForm.handleChange}
                                                                value={markForm.values.boardName_X} /></td>
                                                            <td><Input id='mediumOfInstitution_X' type="text" style={{ width: '80' }}
                                                                onChange={markForm.handleChange}
                                                                value={markForm.values.mediumOfInstitution_X} /></td>
                                                            <td><Input id='monthAndYearOfPassing_X' type="text" style={{ width: '80' }}
                                                                onChange={markForm.handleChange}
                                                                value={markForm.values.monthAndYearOfPassing_X} /></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center fw-bold">2</td>
                                                            <td className="text-center fw-bold">11 STD</td>
                                                            <td><Input id='registerNo_XI' type="text" style={{ width: '80' }}
                                                                onChange={markForm.handleChange}
                                                                value={markForm.values.registerNo_XI} /></td>
                                                            <td><Input id='institutionNameAndAddress_XI' type="text" style={{ width: '80' }}
                                                                onChange={markForm.handleChange}
                                                                value={markForm.values.institutionNameAndAddress_XI} /></td>
                                                            <td><Input id='boardName_XI' type="text" style={{ width: '80' }}
                                                                onChange={markForm.handleChange}
                                                                value={markForm.values.boardName_XI} /></td>
                                                            <td><Input id='mediumOfInstitution_XI' type="text" style={{ width: '80' }}
                                                                onChange={markForm.handleChange}
                                                                value={markForm.values.mediumOfInstitution_XI} /></td>
                                                            <td><Input id='monthAndYearOfPassing_XI' type="text" style={{ width: '80' }}
                                                                onChange={markForm.handleChange}
                                                                value={markForm.values.monthAndYearOfPassing_XI} /></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="row">
                                                <FormGroup>
                                                    <span className="text-info h5" >
                                                        Details of Qualifying Examination
                                                    </span>
                                                </FormGroup>
                                            </div>
                                            <div className="row">
                                                <FormGroup>
                                                    <span className="text-info h5" >
                                                        (1). Marks In X th Standard
                                                    </span>
                                                </FormGroup>
                                            </div>
                                            <div className="row">
                                                <table className='table table-modern table-hover text-nowrap'>
                                                    <thead >
                                                        <tr className='table-primary'>
                                                            <th >SUBJECT</th>
                                                            <th >MARKS SECURED</th>
                                                            <th >MAXIMUM  MARKS</th>
                                                            <th >MONTH & YEAR  OF PASSING</th>
                                                            <th >REGISTER NO</th>
                                                            <th >NO OF ATTEMPTS</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td><SearchableSelect id='X_S1_studentSchoolSubject' ariaLabel="" placeholder="Subject"
                                                                onChange={(e: any) => setX_S1_studentSchoolSubject(e)}
                                                                value={X_S1_studentSchoolSubject}
                                                                list={studentSchoolSubjectData.map((data: any) => (
                                                                    { value: data.studentSchoolSubjectId, label: data.subjectName }
                                                                ))} /></td>
                                                            <td><Input id='X_S1_markSecured' type="text" style={{ width: '80' }}
                                                                onChange={markForm.handleChange}
                                                                value={markForm.values.X_S1_markSecured} /></td>
                                                            <td><Input id='X_S1_maximumMarks' type="text" style={{ width: '80' }}
                                                                onChange={markForm.handleChange}
                                                                value={markForm.values.X_S1_maximumMarks} /></td>
                                                            <td><Input id='X_S1_monthAndYearOfPassing' type="text" style={{ width: '80' }}
                                                                onChange={markForm.handleChange}
                                                                value={markForm.values.X_S1_monthAndYearOfPassing} /></td>
                                                            <td><Input id='X_S1_registerNo' type="text" style={{ width: '80' }}
                                                                onChange={markForm.handleChange}
                                                                value={markForm.values.X_S1_registerNo} /></td>
                                                            <td><Input id='X_S1_noOfAttempts' type="text" style={{ width: '80' }}
                                                                onChange={markForm.handleChange}
                                                                value={markForm.values.X_S1_noOfAttempts} /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><SearchableSelect id='X_S2_studentSchoolSubject' ariaLabel="Subject" placeholder="Subject"
                                                                onChange={(e: any) => setX_S2_studentSchoolSubject(e)}
                                                                value={X_S2_studentSchoolSubject}
                                                                list={studentSchoolSubjectData.map((data: any) => (
                                                                    { value: data.studentSchoolSubjectId, label: data.subjectName }
                                                                ))} /></td>
                                                            <td><Input id='X_S2_markSecured' type="text" style={{ width: '80' }}
                                                                onChange={markForm.handleChange}
                                                                value={markForm.values.X_S2_markSecured} /></td>
                                                            <td><Input id='X_S2_maximumMarks' type="text" style={{ width: '80' }}
                                                                onChange={markForm.handleChange}
                                                                value={markForm.values.X_S2_maximumMarks} /></td>
                                                            <td><Input id='X_S2_monthAndYearOfPassing' type="text" style={{ width: '80' }}
                                                                onChange={markForm.handleChange}
                                                                value={markForm.values.X_S2_monthAndYearOfPassing} /></td>
                                                            <td><Input id='X_S2_registerNo' type="text" style={{ width: '80' }}
                                                                onChange={markForm.handleChange}
                                                                value={markForm.values.X_S2_registerNo} /></td>
                                                            <td><Input id='X_S2_noOfAttempts' type="text" style={{ width: '80' }}
                                                                onChange={markForm.handleChange}
                                                                value={markForm.values.X_S2_noOfAttempts} /></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center fw-bold">Part-III Subjects</td>
                                                        </tr>
                                                        <tr>
                                                            <td><SearchableSelect id='X_S3_studentSchoolSubject' ariaLabel="" placeholder="Subject"
                                                                onChange={(e: any) => setX_S3_studentSchoolSubject(e)}
                                                                value={X_S3_studentSchoolSubject}
                                                                list={studentSchoolSubjectData.map((data: any) => (
                                                                    { value: data.studentSchoolSubjectId, label: data.subjectName }
                                                                ))} /></td>
                                                            <td><Input id='X_S3_markSecured' type="text" style={{ width: '80' }}
                                                                onChange={markForm.handleChange}
                                                                value={markForm.values.X_S3_markSecured} /></td>
                                                            <td><Input id='X_S3_maximumMarks' type="text" style={{ width: '80' }}
                                                                onChange={markForm.handleChange}
                                                                value={markForm.values.X_S3_maximumMarks} /></td>
                                                            <td><Input id='X_S3_monthAndYearOfPassing' type="text" style={{ width: '80' }}
                                                                onChange={markForm.handleChange}
                                                                value={markForm.values.X_S3_monthAndYearOfPassing} /></td>
                                                            <td><Input id='X_S3_registerNo' type="text" style={{ width: '80' }}
                                                                onChange={markForm.handleChange}
                                                                value={markForm.values.X_S3_registerNo} /></td>
                                                            <td><Input id='X_S3_noOfAttempts' type="text" style={{ width: '80' }}
                                                                onChange={markForm.handleChange}
                                                                value={markForm.values.X_S3_noOfAttempts} /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><SearchableSelect id='X_S4_studentSchoolSubject' ariaLabel="" placeholder="Subject"
                                                                onChange={(e: any) => setX_S4_studentSchoolSubject(e)}
                                                                value={X_S4_studentSchoolSubject}
                                                                list={studentSchoolSubjectData.map((data: any) => (
                                                                    { value: data.studentSchoolSubjectId, label: data.subjectName }
                                                                ))} /></td>
                                                            <td><Input id='X_S4_markSecured' type="text" style={{ width: '80' }}
                                                                onChange={markForm.handleChange}
                                                                value={markForm.values.X_S4_markSecured} /></td>
                                                            <td><Input id='X_S4_maximumMarks' type="text" style={{ width: '80' }}
                                                                onChange={markForm.handleChange}
                                                                value={markForm.values.X_S4_maximumMarks} /></td>
                                                            <td><Input id='X_S4_monthAndYearOfPassing' type="text" style={{ width: '80' }}
                                                                onChange={markForm.handleChange}
                                                                value={markForm.values.X_S4_monthAndYearOfPassing} /></td>
                                                            <td><Input id='X_S4_registerNo' type="text" style={{ width: '80' }}
                                                                onChange={markForm.handleChange}
                                                                value={markForm.values.X_S4_registerNo} /></td>
                                                            <td><Input id='X_S4_noOfAttempts' type="text" style={{ width: '80' }}
                                                                onChange={markForm.handleChange}
                                                                value={markForm.values.X_S4_noOfAttempts} /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><SearchableSelect id='X_S5_studentSchoolSubject' ariaLabel="" placeholder="Subject"
                                                                onChange={(e: any) => setX_S5_studentSchoolSubject(e)}
                                                                value={X_S5_studentSchoolSubject}
                                                                list={studentSchoolSubjectData.map((data: any) => (
                                                                    { value: data.studentSchoolSubjectId, label: data.subjectName }
                                                                ))} /></td>
                                                            <td><Input id='X_S5_markSecured' type="text" style={{ width: '80' }}
                                                                onChange={markForm.handleChange}
                                                                value={markForm.values.X_S5_markSecured} /></td>
                                                            <td><Input id='X_S5_maximumMarks' type="text" style={{ width: '80' }}
                                                                onChange={markForm.handleChange}
                                                                value={markForm.values.X_S5_maximumMarks} /></td>
                                                            <td><Input id='X_S5_monthAndYearOfPassing' type="text" style={{ width: '80' }}
                                                                onChange={markForm.handleChange}
                                                                value={markForm.values.X_S5_monthAndYearOfPassing} /></td>
                                                            <td><Input id='X_S5_registerNo' type="text" style={{ width: '80' }}
                                                                onChange={markForm.handleChange}
                                                                value={markForm.values.X_S5_registerNo} /></td>
                                                            <td><Input id='X_S5_noOfAttempts' type="text" style={{ width: '80' }}
                                                                onChange={markForm.handleChange}
                                                                value={markForm.values.X_S5_noOfAttempts} /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><SearchableSelect id='X_S6_studentSchoolSubject' ariaLabel="" placeholder="Subject"
                                                                onChange={(e: any) => setX_S6_studentSchoolSubject(e)}
                                                                value={X_S6_studentSchoolSubject}
                                                                list={studentSchoolSubjectData.map((data: any) => (
                                                                    { value: data.studentSchoolSubjectId, label: data.subjectName }
                                                                ))} /></td>
                                                            <td><Input id='X_S6_markSecured' type="text" style={{ width: '80' }}
                                                                onChange={markForm.handleChange}
                                                                value={markForm.values.X_S6_markSecured} /></td>
                                                            <td><Input id='X_S6_maximumMarks' type="text" style={{ width: '80' }}
                                                                onChange={markForm.handleChange}
                                                                value={markForm.values.X_S6_maximumMarks} /></td>
                                                            <td><Input id='X_S6_monthAndYearOfPassing' type="text" style={{ width: '80' }}
                                                                onChange={markForm.handleChange}
                                                                value={markForm.values.X_S6_monthAndYearOfPassing} /></td>
                                                            <td><Input id='X_S6_registerNo' type="text" style={{ width: '80' }}
                                                                onChange={markForm.handleChange}
                                                                value={markForm.values.X_S6_registerNo} /></td>
                                                            <td><Input id='X_S6_noOfAttempts' type="text" style={{ width: '80' }}
                                                                onChange={markForm.handleChange}
                                                                value={markForm.values.X_S6_noOfAttempts} /></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center fw-bold">TOTAL</td>
                                                            <td><Input id='X_OverAll_markSecured' type="text" style={{ width: '80' }}
                                                                onChange={markForm.handleChange}
                                                                onInput={(e: any) => setTotalPercentageForX(e.target.value)}
                                                                value={markForm.values.X_OverAll_markSecured} /></td>
                                                            <td><Input id='X_OverAll_maximumMarks' type="text" style={{ width: '80' }}
                                                                onChange={markForm.handleChange}
                                                                value={markForm.values.X_OverAll_maximumMarks} /></td>
                                                            <td className="text-center fw-bold">TOTAL PERCENTAGE</td>
                                                            <td><Input id='' type="text" style={{ width: '80' }} disabled
                                                                value={(Number(totalPercentageForX) / Number(600) * 100).toFixed(2)} /></td>
                                                            <td><Input id='' type="text" style={{ width: '80' }} disabled /></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }} className="mt-4">
                                            <Button color="primary" onClick={handlePrevious} type="submit" isLink>Previous</Button>
                                            <Button color="primary" type="submit">Submit</Button>
                                        </div>

                                        {/* </CardBody> */}
                                    </form>
                                </Card>
                            )}
                        </CardBody>
                    </Card>
                </Page>

                <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} isNavigate={isNavigate} />

            </PageWrapper>
        </>
    )
}
export default UpdateStudentWizard;
