import { useFormik } from "formik";
import { useEffect, useState, useRef, useContext } from "react";
import { useReactToPrint } from "react-to-print";
import useDarkMode from "../../../../hooks/useDarkMode";
import useSortableData from "../../../../hooks/useSortableData";
import PaginationButtons, { dataPagination } from "../../../PaginationButtons";
import Button, { ButtonGroup } from "../../../bootstrap/Button";
import { toasts } from "../../../../services/toast.service";
import Page from "../../../../layout/Page/Page";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../../../bootstrap/Card";
import Icon from "../../../icon/Icon";
import Input from "../../../bootstrap/forms/Input";
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from "../../../bootstrap/Dropdown";
import FormGroup from "../../../bootstrap/forms/FormGroup";
import Checks, { ChecksGroup } from "../../../bootstrap/forms/Checks";
import { convertJsonToPdf, downloadExcel, downloadFile } from "../../../../services/ExportService";
import Select from "../../../bootstrap/forms/Select";
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from "../../../bootstrap/OffCanvas";
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";
import AlertService from "../../../../services/AlertService";
import DeleteComponents from "../../../../common/components/DeleteComponents";
import Modal, { ModalBody, ModalHeader, ModalTitle } from "../../../bootstrap/Modal";
import { addPaperType, addSubjectPaper, deletePaperType, deleteSubjectPaperDetails, getPaperType, getPart, getSubjectPaperDetails, updatePaperType, updateSubjectPaperDetails } from "../../../../services/subject.service";
import { getColumnsForDataTable, onlyAllowNumber, updateColumnsForDataTable, updateFilter } from "../../../../services/common.service";
import AuthContext from "../../../../contexts/authContext";
import { getLicenseKey } from "../../../../services/application.settings";
import { TableLoader, showLoader } from "../../../../services/loader.services";
import NoDataMsg from "../../../../common/components/NoDataMsg";
import SearchableSelect from "../../../../common/components/SearchableSelect";

function CreateSubject() {

    useEffect(() => {
        getSubjectPaper();
        getPartDetails();
        getPaperTypeList();
    }, [])

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const [subjectPaperData, setSubjectPaperData] = useState<any>([]);
    const [paperTypeData, setPaperTypeData] = useState<any>([])
    const [partData, setPartData] = useState([])
    const [columnVisibilityData, setColumnVisibilityData] = useState([]);
    const [allColumnsData, setAllColumnsData] = useState([]);
    const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
    const [columnDataById, setColumnDataById] = useState([])
    const componentRef = useRef(null);
    const [isChecked, setIsChecked] = useState(false)
    const [dataStatus, setDataStatus] = useState(false)
    // ForModal and Table
    const { themeStatus, darkModeStatus } = useDarkMode();
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(subjectPaperData);
    const onCurrentPageData = dataPagination(subjectPaperData, currentPage, perPage);
    const [addSubjectPaperOffCanvas, setAddSubjectPaperOffCanvas] = useState(false)
    const [editSubjectPaperOffCanvas, setEditSubjectPaperOffCanvas] = useState(false)
    const [isDeleteOpen, setIsDeleteOpen] = useState(false)
    const [noDataMsg, setNoDataMsg] = useState('')
    const [paperTypeId, setPaperTypeId] = useState<any>('')
    const [part, setPart] = useState<any>('')
    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);

    const subjectPaperForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            paperCode: '',
            paperName: '',
            paperTypeId: '',
            part: '',
            maxMark: '',
            credit: '',
            paperType: '',
        },
        validate: (values) => {
            const errors: {
                paperCode?: string;
                paperName?: string;
                paperTypeId?: string;
                part?: string;
                maxMark?: string;
                credit?: string;
                paperType?: string;
            } = {};
            if (!values.paperCode) {
                errors.paperCode = 'Required';
            }
            if (!values.paperName) {
                errors.paperName = 'Required';
            }
            if (values.paperType == "") {
                if (!paperTypeId?.value) {
                    errors.paperTypeId = 'Required';
                }
            }
            if (!paperTypeId) {
                if (!values.paperType) {
                    errors.paperType = 'Required';
                }
            }
            if (!part?.value) {
                errors.part = 'Required';
            }
            if (!values.maxMark) {
                errors.maxMark = 'Required';
            }
            if (!values.credit) {
                errors.credit = 'Required';
            }
            return errors;
        },

        //validateOnChange: false,
        onSubmit: () => { subjectPaperSubmit() },
    });

    const subjectPaperEditForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            paperCode: '',
            paperName: '',
            paperTypeId: '',
            part: '',
            maxMark: '',
            credit: '',
            subjectPaperDetailsId: '',
        },
        validate: (values) => {
            const errors: {
                paperCode?: string;
                paperName?: string;
                paperTypeId?: string;
                part?: string;
                maxMark?: string;
                credit?: string;
            } = {};
            if (!values.paperCode) {
                errors.paperCode = 'Required';
            }
            if (!values.paperName) {
                errors.paperName = 'Required';
            }
            // if (addPaperTypeForm.values.paperType == "") {
            if (!paperTypeId?.value) {
                errors.paperTypeId = 'Required';
            }
            // }
            if (!part?.value) {
                errors.part = 'Required';
            }
            if (!values.maxMark) {
                errors.maxMark = 'Required';
            }
            if (!values.credit) {
                errors.credit = 'Required';
            }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: () => { updateSubjectPaperSubmit() },
    });

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },
        //validateOnChange: false,
        onSubmit: () => { },
    });

    // Filter
    const filteredData = updateFilter(onCurrentPageData, columnVisibilityData, columnVisibilityForm);

    function onActivate(i: any) {
        if (i != undefined) {
            let selectedSubject = i
            setEditSubjectPaperOffCanvas(true)
            setPaperTypeId({ value: selectedSubject.paperTypeId, label: selectedSubject.paperType })
            setPart({ value: selectedSubject.part, label: selectedSubject.part })

            subjectPaperEditForm.setValues({
                paperName: selectedSubject.paperName,
                paperCode: selectedSubject.paperCode,
                paperTypeId: paperTypeId?.value,
                part: part?.value,
                maxMark: selectedSubject.maxMark,
                credit: selectedSubject.credit,
                subjectPaperDetailsId: selectedSubject.subjectPaperDetailsId
            });
        }
    }

    function getSubjectPaper() {
        setDataStatus(false)
        getSubjectPaperDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.subjectPaperDetails;
                    if (data != undefined) {
                        getColumnsForTable('getSubjectPaperDetails', 'get');
                        setSubjectPaperData(data);
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                        setDataStatus(true)
                    } else {
                        setDataStatus(true)
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setDataStatus(true)
                    getColumnsForTable('getSubjectPaperDetails', 'get');
                    setSubjectPaperData([]);
                    //toasts(response.data.message, "Error")
                    setNoDataMsg(response.data.message)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setDataStatus(true)
                    toasts(errorDescription, "Error")
                }
            }, error => {
                setDataStatus(true)
                toasts(error, "Error")
            }
        )
    }

    function setSubjectPaperDetails() {
        return ({
            paperCode: subjectPaperForm.values.paperCode,
            paperName: subjectPaperForm.values.paperName,
            paperTypeId: paperTypeId?.value,
            part: part?.value,
            maxMark: subjectPaperForm.values.maxMark,
            credit: subjectPaperForm.values.credit,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId
        })
    }

    function setEditSubjectPaperDetails() {
        return ({
            subjectPaperDetailsId: subjectPaperEditForm.values.subjectPaperDetailsId,
            paperCode: subjectPaperEditForm.values.paperCode,
            paperName: subjectPaperEditForm.values.paperName,
            paperTypeId: paperTypeId?.value,
            part: part?.value,
            maxMark: subjectPaperEditForm.values.maxMark,
            credit: subjectPaperEditForm.values.credit,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId
        })
    }

    function setPaperTypeDetails() {
        return ({
            paperType: subjectPaperForm.values.paperType,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId
        })
    }

    function subjectPaperSubmit() {
        showLoader(true)
        if (subjectPaperForm.values.paperType == "") {
            if (subjectPaperForm.isValid) {
                let subjectPostData = setSubjectPaperDetails()
                addSubjectPaper(subjectPostData,
                    (response) => {
                        const data = response.data;
                        if (data.success == true) {
                            showLoader(false)
                            setAlertStatus({ message: data.message, type: "success" });
                            setIsOpen(true);
                            closeAndReset();
                            getSubjectPaper();
                        }
                        else if (data.success == false) {
                            showLoader(false)
                            setAlertStatus({ message: data.message, type: "error" });
                            setIsOpen(true);
                        }
                        else {
                            showLoader(false)
                            let errorCode = response.data.error[0].error_code;
                            let errorDescription = response.data.error[0].error_description;
                            setAlertStatus({ message: errorDescription, type: "error" });
                            setIsOpen(true);
                        }
                    }
                    , (error) => {
                        showLoader(false)
                        setAlertStatus({ message: error, type: "error" });
                        setIsOpen(true);
                    }
                )
            } else if (subjectPaperForm.isValid == false) {
                showLoader(false)
                setAlertStatus({ message: 'Please fill all the details!', type: "error" });
                setIsOpen(true);
            }
        } else {
            if (subjectPaperForm.isValid) {
                let subjectPostData = setPaperTypeDetails()
                addPaperType(subjectPostData,
                    (response) => {
                        const data = response.data;
                        if (data.success == true) {
                            setPaperTypeId(data.data.paperTypeId)
                            let postData = {
                                paperCode: subjectPaperForm.values.paperCode,
                                paperName: subjectPaperForm.values.paperName,
                                paperTypeId: data.data.paperTypeId,
                                part: part?.value,
                                maxMark: subjectPaperForm.values.maxMark,
                                credit: subjectPaperForm.values.credit,
                                licenseKey: getLicenseKey,
                                userAccountId: userAccountId
                            }
                            subjectPaperSubmit1(postData);
                            getPaperTypeList();
                        }
                        else if (data.success == false) {
                            setPaperTypeId(data.data.paperTypeId)

                            let postData = {
                                paperCode: subjectPaperForm.values.paperCode,
                                paperName: subjectPaperForm.values.paperName,
                                paperTypeId: data.data.paperTypeId,
                                part: part?.value,
                                maxMark: subjectPaperForm.values.maxMark,
                                credit: subjectPaperForm.values.credit,
                                licenseKey: getLicenseKey,
                                userAccountId: userAccountId
                            }
                            subjectPaperSubmit1(postData);
                            //showLoader(false)
                            // setAlertStatus({ message: data.message, type: "error" });
                            // setIsOpen(true);
                        }
                        else {
                            let errorCode = response.data.error[0].error_code;
                            let errorDescription = response.data.error[0].error_description;
                            showLoader(false)
                            setAlertStatus({ message: errorDescription, type: "error" });
                            setIsOpen(true);
                        }
                    }
                    , (error) => {
                        showLoader(false)
                        setAlertStatus({ message: error, type: "error" });
                        setIsOpen(true);
                    }
                )
            } else if (subjectPaperForm.isValid == false) {
                //showLoader(true)
                // setAlertStatus({ message: 'Please fill all the details!', type: "error" });
                // setIsOpen(true);
            }
        }
    }

    function subjectPaperSubmit1(postData: any) {
        if (subjectPaperForm.isValid) {
            let subjectPostData = postData
            addSubjectPaper(subjectPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        closeAndReset();
                        getSubjectPaper();
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        showLoader(false)
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (subjectPaperForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function updateSubjectPaperSubmit() {
        showLoader(true)
        if (subjectPaperEditForm.isValid) {
            let subjectPostData = setEditSubjectPaperDetails()
            updateSubjectPaperDetails(subjectPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        closeAndReset();
                        getSubjectPaper();
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false)
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (subjectPaperEditForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function deleteSubjectPaperSubmit() {
        showLoader(true)
        // if (subjectPaperEditForm.isValid) {
        let subjectPostData = setEditSubjectPaperDetails()
        deleteSubjectPaperDetails(subjectPostData,
            (response) => {
                const data = response.data;
                if (data.success == true) {
                    showLoader(false)
                    setAlertStatus({ message: data.message, type: "success" });
                    setIsOpen(true);
                    closeAndReset();
                    getSubjectPaper();
                }
                else if (data.success == false) {
                    showLoader(false)
                    setAlertStatus({ message: data.message, type: "error" });
                    setIsOpen(true);
                }
                else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    showLoader(false)
                    setAlertStatus({ message: errorDescription, type: "error" });
                    setIsOpen(true);
                }
            }
            , (error) => {
                showLoader(false)
                setAlertStatus({ message: error, type: "error" });
                setIsOpen(true);
            }
        )
        // } else if (subjectPaperEditForm.isValid == false) {
        //     showLoader(false)
        //     setAlertStatus({ message: 'Please fill all the details!', type: "error" });
        //     setIsOpen(true);
        // }
    }

    function getPaperTypeList() {
        getPaperType(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.paperType;
                    if (data != undefined) {
                        setPaperTypeData(data);
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setPaperTypeData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getPartDetails() {
        getPart(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.part;
                    if (data != undefined) {
                        setPartData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setPartData([])
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getColumnsForTable(apiName: any, type: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setColumnVisibilityData(columnVisibilityData)

                        if (type == 'get') {
                            const allData = allColumnsData.map((item: any) => item.isDisplay);
                            const allColumns = allData.filter((item: any) => item == false);
                            setIsChecked(allColumns == '' ? true : false);
                        }
                        else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

                            for (let i = 0; i < nonDefaultValue.length; i++) {

                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0
                                }
                                all.push(obj);
                            }
                            updateColumnsSubmit('', '', all)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )

    }

    function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {

        if (columnVisibilityForm.isValid) {

            setColumnDataById(isDisplay)
            const columnVisibilityPostData = {
                licenseKey: getLicenseKey,
                userAccountId: userAccountId,
                columns: type ? type : [
                    {
                        columnVisibilityId: columnVisibilityId,
                        isDisplay: isDisplay ? 0 : 1
                    }
                ]
            }

            updateColumnsForDataTable(columnVisibilityPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        getColumnsForTable('getSubjectPaperDetails', 'get');
                    }
                    else if (data.success == false) {
                        // toasts(data.success, "Error")
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        toasts(errorDescription, "Error")
                    }
                }
                , (error) => {
                    toasts(error, "Error")
                }
            )
        } else if (columnVisibilityForm.isValid == false) {
            toasts("Please fill all the details!", "Error")
        }
    }

    function closeAndReset() {
        setAddSubjectPaperOffCanvas(false);
        setEditSubjectPaperOffCanvas(false);
        setIsDeleteOpen(false);
        setPaperTypeId('')
        setPart('')
        subjectPaperForm.resetForm();
        subjectPaperEditForm.resetForm();
        setIsVisible(true)
    }

    function resetStateAddNew() {
        setPaperTypeId('')
        setPart('')
        subjectPaperForm.resetForm();
        subjectPaperEditForm.resetForm();
    }
    // Print
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const [isVisible, setIsVisible] = useState<boolean>(true);

    const toggleVisibility = () => {
        setIsVisible(!isVisible);
        setPaperTypeId('')
        setPart('')

        subjectPaperForm.setValues({
            paperCode: subjectPaperForm.values.paperCode,
            paperName: subjectPaperForm.values.paperName,
            paperTypeId: '',
            part: part?.value,
            maxMark: subjectPaperForm.values.maxMark,
            credit: subjectPaperForm.values.credit,
            paperType: ""
        });
    };

    return (
        <PageWrapper title="Subject Paper Creation">
            <Page container='fluid'>
                <Card stretch data-tour='list' ref={componentRef}>
                    <CardHeader borderSize={1}>
                        <CardLabel icon='List' iconColor='info' className="col-lg-5">
                            <CardTitle tag='div' className='h5'>Paper Details List</CardTitle>
                        </CardLabel>&nbsp;&nbsp;

                        <ButtonGroup className="col-lg-3 d-print-none" color='primary'>
                            <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                            <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
                        </ButtonGroup>

                        <CardActions className="d-print-none">
                            <Dropdown isOpen={columnVisibilityMenu} setIsOpen={setColumnVisibilityMenu} isButtonGroup>
                                <DropdownToggle>
                                    <Button icon='FilterAlt' color='primary' isLight>Filter</Button>
                                </DropdownToggle>
                                <DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
                                    <DropdownItem>
                                        <div className='container py-2'>
                                            <form className='row g-3'>
                                                <div className='col-12'>
                                                    <FormGroup>
                                                        <h6>Select All</h6>
                                                        <Checks id='available' type='switch' label='Select All Columns' onChange={() => getColumnsForTable('getSubjectPaperDetails', 'post')} checked={isChecked} ariaLabel='Available status' />
                                                    </FormGroup>
                                                </div>
                                                <div className='col-12' style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                                                    <FormGroup>
                                                        <h6>Columns</h6>
                                                        <ChecksGroup>
                                                            {allColumnsData.map((i: any) => (
                                                                <Checks key={i.columnVisibilityId} id='{i.columnVisibilityId}' label={i.columnName} onChange={() => updateColumnsSubmit(i.columnVisibilityId, i.isDisplay, '')} checked={i.isDisplay} disabled={i.isDefault == true} />
                                                            ))}
                                                        </ChecksGroup>
                                                    </FormGroup>
                                                </div>
                                            </form>
                                        </div>
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                            <Dropdown isButtonGroup>
                                <DropdownToggle>
                                    <Button color='primary' isLight icon='CloudDownload'> Export </Button>
                                </DropdownToggle>
                                <DropdownMenu isAlignmentEnd>
                                    <DropdownItem>
                                        <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel("Subject Paper List", subjectPaperData, columnVisibilityData)}> Excel </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(subjectPaperData, columnVisibilityData, "Subject Paper List")}> PDF
                                        </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile("Subject Paper List", subjectPaperData, columnVisibilityData)}> CSV </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <Button color='primary' isLight icon='Print' onClick={handlePrint}> Print </Button>
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                            <Button color='primary' icon='Add' isLight onClick={() => { setAddSubjectPaperOffCanvas(true); resetStateAddNew() }}>
                                Add New
                            </Button>
                        </CardActions>
                    </CardHeader>
                    <CardBody className='table-responsive' isScrollable >
                        {!dataStatus ?
                            <TableLoader /> :
                            <table className='table table-modern table-hover text-nowrap' ref={componentRef}>
                                <thead>
                                    <tr>
                                        <th scope='col' onClick={() => requestSort('sno')}
                                            className='cursor-pointer text-decoration-underline'>S.No{' '}
                                            <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                        </th>
                                        {columnVisibilityData.map((column: any) => (
                                            <th key={column.keyName} scope='col' onClick={() => requestSort(column.keyName)}
                                                className='cursor-pointer text-decoration-underline'>
                                                {column.columnName}
                                                <Icon size='lg' className={getClassNamesFor(column.keyName)} icon='FilterList' />
                                            </th>
                                        ))}
                                        <th scope='col' className='d-print-none'>
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredData != '' ?
                                        <>
                                            {filteredData.map((rowData: any) => (
                                                <tr key={rowData.subjectPaperDetailsId}>
                                                    <td>{rowData.sno}</td>
                                                    {columnVisibilityData.map((column: any) => (
                                                        (column.isDisplay && rowData[column.keyName]) ?
                                                            <td key={`${rowData.subjectPaperDetailsId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />
                                                    ))}
                                                    <td className="d-print-none" >
                                                        <Button color='dark' isLight icon='Edit' aria-label='Edit' onClick={() => { onActivate(rowData) }} /></td>
                                                </tr>
                                            ))
                                            }</>
                                        : <NoDataMsg columnsCount={columnVisibilityData.length + 2} msg={noDataMsg} />}
                                </tbody>
                            </table>
                        }
                    </CardBody>
                    <PaginationButtons
                        className="d-print-none"
                        data={items}
                        label='items'
                        setCurrentPage={setCurrentPage}
                        currentPage={currentPage}
                        perPage={perPage}
                        setPerPage={setPerPage}
                    />
                </Card>

                <OffCanvas
                    setOpen={setAddSubjectPaperOffCanvas}
                    isOpen={addSubjectPaperOffCanvas}
                    titleId='addSubjectPaperOffCanvas'
                    isBodyScroll tag="form" noValidate onSubmit={subjectPaperForm.handleSubmit}>
                    <OffCanvasHeader setOpen={setAddSubjectPaperOffCanvas} onClick={closeAndReset}>
                        <OffCanvasTitle id="addSubjectPaperOffCanvas">Subject Paper Creation</OffCanvasTitle>
                    </OffCanvasHeader>
                    <OffCanvasBody>
                        <div className="row g-4">
                            {isVisible ? <div className='col-10'>
                                <FormGroup id='paperTypeId' label='Paper Type'>
                                    <SearchableSelect
                                        ariaLabel='Paper Type'
                                        onChange={(e: any) => { setPaperTypeId(e) }}
                                        value={paperTypeId}
                                        onBlur={subjectPaperForm.handleBlur}
                                        isValid={subjectPaperForm.isValid}
                                        isTouched={subjectPaperForm.touched.paperTypeId}
                                        invalidFeedback={subjectPaperForm.errors.paperTypeId}
                                        list={paperTypeData.map((data: any) => (
                                            { value: data.paperTypeId, label: data.paperType }
                                        ))}
                                        placeholder="Select Paper Type"
                                    />
                                </FormGroup>
                            </div> :
                                <div className='col-10'>
                                    <FormGroup id='paperType' label="Paper Type" >
                                        <Input onChange={subjectPaperForm.handleChange} value={subjectPaperForm.values.paperType}
                                            onBlur={subjectPaperForm.handleBlur}
                                            isValid={subjectPaperForm.isValid}
                                            isTouched={subjectPaperForm.touched.paperType}
                                            invalidFeedback={subjectPaperForm.errors.paperType}
                                            type='text' placeholder="Enter Paper Type" />
                                    </FormGroup>
                                </div>}

                            <div className='col-1 align-middle'>
                                <FormGroup id='' label={"      "}>
                                    <Button className='mt-2'
                                        color='primary' icon='Add' isLight
                                        hoverShadow='lg'
                                        onClick={toggleVisibility}
                                    />
                                </FormGroup>
                            </div>

                            <div className='col-12'>
                                <FormGroup id='paperCode' label='Paper Code' >
                                    <Input onChange={subjectPaperForm.handleChange} value={subjectPaperForm.values.paperCode}
                                        onBlur={subjectPaperForm.handleBlur}
                                        isValid={subjectPaperForm.isValid}
                                        isTouched={subjectPaperForm.touched.paperCode}
                                        invalidFeedback={subjectPaperForm.errors.paperCode}
                                        type='text' placeholder="Enter Paper Code" />
                                </FormGroup>
                            </div>

                            <div className='col-12'>
                                <FormGroup id='paperName' label='Paper Name'>
                                    <Input onChange={subjectPaperForm.handleChange} value={subjectPaperForm.values.paperName}
                                        onBlur={subjectPaperForm.handleBlur}
                                        isValid={subjectPaperForm.isValid}
                                        isTouched={subjectPaperForm.touched.paperName}
                                        invalidFeedback={subjectPaperForm.errors.paperName}
                                        type='text' placeholder="Enter Paper Name" />
                                </FormGroup>
                            </div>

                            <div className='col-12'>
                                <FormGroup id='part' label='Part'>
                                    <SearchableSelect
                                        ariaLabel='Part'
                                        onChange={(e: any) => { setPart(e) }}
                                        value={part}
                                        onBlur={subjectPaperForm.handleBlur}
                                        isValid={subjectPaperForm.isValid}
                                        isTouched={subjectPaperForm.touched.part}
                                        invalidFeedback={subjectPaperForm.errors.part}
                                        list={partData.map((data: any) => (
                                            { value: data.part, label: data.part }
                                        ))}
                                        placeholder="Select Part"
                                    />
                                </FormGroup>
                            </div>

                            <div className='col-12'>
                                <FormGroup id='credit' label='Credit'>
                                    <Input onChange={subjectPaperForm.handleChange} value={subjectPaperForm.values.credit}
                                        onBlur={subjectPaperForm.handleBlur}
                                        isValid={subjectPaperForm.isValid}
                                        isTouched={subjectPaperForm.touched.credit}
                                        invalidFeedback={subjectPaperForm.errors.credit}
                                        onKeyDown={(e: any) => onlyAllowNumber(e)}
                                        type='text' placeholder="Enter Credit" />
                                </FormGroup>
                            </div>

                            <div className='col-12'>
                                <FormGroup id='maxMark' label='Max Mark' >
                                    <Input onChange={subjectPaperForm.handleChange} value={subjectPaperForm.values.maxMark}
                                        onBlur={subjectPaperForm.handleBlur}
                                        isValid={subjectPaperForm.isValid}
                                        isTouched={subjectPaperForm.touched.maxMark}
                                        invalidFeedback={subjectPaperForm.errors.maxMark}
                                        onKeyDown={(e: any) => onlyAllowNumber(e)}
                                        type='text' placeholder="Enter Max Mark" />
                                </FormGroup>
                            </div>
                        </div>
                    </OffCanvasBody>
                    <div className='row m-0'>
                        <div className='col-12 p-3'>
                            <Button
                                color='info' icon="Save"
                                className='w-100' type="submit"
                                isDisable={!subjectPaperForm.isValid && !!subjectPaperForm.submitCount} >
                                Save
                            </Button>
                        </div>
                    </div>
                </OffCanvas>

                <OffCanvas
                    setOpen={setEditSubjectPaperOffCanvas}
                    isOpen={editSubjectPaperOffCanvas}
                    titleId='editSubjectPaperOffCanvas'
                    isBodyScroll tag="form" noValidate onSubmit={subjectPaperEditForm.handleSubmit}>
                    <OffCanvasHeader setOpen={setEditSubjectPaperOffCanvas} onClick={closeAndReset}>
                        <OffCanvasTitle id="editSubjectPaperOffCanvas">Update Subject Paper Details</OffCanvasTitle>
                    </OffCanvasHeader>
                    <OffCanvasBody>
                        <div className="row g-4">
                            <div className='col-12'>
                                <FormGroup id='paperTypeId' label='Paper Type'>
                                    <SearchableSelect
                                        ariaLabel='Paper Type'
                                        onChange={(e: any) => { setPaperTypeId(e) }}
                                        value={paperTypeId}
                                        onBlur={subjectPaperEditForm.handleBlur}
                                        isValid={subjectPaperEditForm.isValid}
                                        isTouched={subjectPaperEditForm.touched.paperTypeId}
                                        invalidFeedback={subjectPaperEditForm.errors.paperTypeId}
                                        list={paperTypeData.map((data: any) => (
                                            { value: data.paperTypeId, label: data.paperType }
                                        ))}
                                        placeholder="Select Paper"
                                    />
                                </FormGroup>
                            </div>

                            <div className='col-12'>
                                <FormGroup id='paperCode' label='Paper Code' >
                                    <Input onChange={subjectPaperEditForm.handleChange} value={subjectPaperEditForm.values.paperCode}
                                        onBlur={subjectPaperEditForm.handleBlur}
                                        isValid={subjectPaperEditForm.isValid}
                                        isTouched={subjectPaperEditForm.touched.paperCode}
                                        invalidFeedback={subjectPaperEditForm.errors.paperCode}
                                        type='text' />
                                </FormGroup>
                            </div>

                            <div className='col-12'>
                                <FormGroup id='paperName' label='Paper Name' >
                                    <Input onChange={subjectPaperEditForm.handleChange} value={subjectPaperEditForm.values.paperName}
                                        onBlur={subjectPaperEditForm.handleBlur}
                                        isValid={subjectPaperEditForm.isValid}
                                        isTouched={subjectPaperEditForm.touched.paperName}
                                        invalidFeedback={subjectPaperEditForm.errors.paperName}
                                        type='text' />
                                </FormGroup>
                            </div>

                            <div className='col-12'>
                                <FormGroup id='part' label='Part' >
                                    <SearchableSelect
                                        ariaLabel='Part'
                                        onChange={(e: any) => { setPart(e) }}
                                        value={part}
                                        onBlur={subjectPaperEditForm.handleBlur}
                                        isValid={subjectPaperEditForm.isValid}
                                        isTouched={subjectPaperEditForm.touched.part}
                                        invalidFeedback={subjectPaperEditForm.errors.part}
                                        list={partData.map((data: any) => (
                                            { value: data.part, label: data.part }
                                        ))}
                                        placeholder="Select Part"
                                    />
                                </FormGroup>
                            </div>

                            <div className='col-12'>
                                <FormGroup id='credit' label='Credit' >
                                    <Input onChange={subjectPaperEditForm.handleChange} value={subjectPaperEditForm.values.credit}
                                        onBlur={subjectPaperEditForm.handleBlur}
                                        isValid={subjectPaperEditForm.isValid}
                                        isTouched={subjectPaperEditForm.touched.credit}
                                        invalidFeedback={subjectPaperEditForm.errors.credit}
                                        onKeyDown={(e: any) => onlyAllowNumber(e)}
                                        type='text' />
                                </FormGroup>
                            </div>

                            <div className='col-12'>
                                <FormGroup id='maxMark' label='Max Mark' >
                                    <Input onChange={subjectPaperEditForm.handleChange} value={subjectPaperEditForm.values.maxMark}
                                        onBlur={subjectPaperEditForm.handleBlur}
                                        isValid={subjectPaperEditForm.isValid}
                                        isTouched={subjectPaperEditForm.touched.maxMark}
                                        invalidFeedback={subjectPaperEditForm.errors.maxMark}
                                        type='text'
                                        onKeyDown={(e: any) => onlyAllowNumber(e)} />
                                </FormGroup>
                            </div>
                        </div>
                    </OffCanvasBody>

                    <div className='row'>
                        <div className="d-flex justify-content-center position-absolute top-65 start-50 translate-middle">
                            <DeleteComponents isDeleteOpen={isDeleteOpen} setIsDeleteOpen={setIsDeleteOpen} deleteFunction={deleteSubjectPaperSubmit} />
                        </div>
                        <div className='col-6 position-absolute bottom-0 start-0 p-3'>
                            <Button color='danger' className='w-100' icon="Delete" onClick={() => setIsDeleteOpen(!isDeleteOpen)}>
                                Delete
                            </Button>
                        </div>
                        <div className='col-6 position-absolute bottom-0 end-0 p-3'>
                            <Button color='info' icon="Save" className='w-100' type="submit" isDisable={!subjectPaperEditForm.isValid && !!subjectPaperEditForm.submitCount}> Update</Button>
                        </div>
                    </div>
                </OffCanvas>
            </Page>
            <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
        </PageWrapper>
    )
} export default CreateSubject;