import React, { useContext, useEffect, useState } from 'react'
import PageWrapper from '../../../layout/PageWrapper/PageWrapper'
import Page from '../../../layout/Page/Page'
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from '../../bootstrap/Card'
import Button from '../../bootstrap/Button'
import { useFormik } from 'formik'
import { getBlood, getCity, getCourseDetails, getCurrentDateAndTime, getGenderDetails, getMedium, getStandardDetails, getStates, onlyAllowNumber } from '../../../services/common.service'
import { showLoader } from '../../../services/loader.services'
import { addStudentDetails, checkClassCapacity, getStudentTempListByStandardDetailsId, getTempStudentList } from '../../../services/student.service'
import AuthContext from '../../../contexts/authContext'
import { getBatchMasterDetails, getSectionDetails, getShift } from '../../../services/master.service'
import { toasts } from '../../../services/toast.service'
import { getLicenseKey } from '../../../services/application.settings'
import FormGroup from '../../bootstrap/forms/FormGroup'
import Select from '../../bootstrap/forms/Select'
import Input from '../../bootstrap/forms/Input'
import AlertService from '../../../services/AlertService'
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from '../../bootstrap/OffCanvas'
import Icon from '../../icon/Icon'
import useDarkMode from '../../../hooks/useDarkMode'
import SearchableSelect from '../../../common/components/SearchableSelect'
import { getTransportAreaMaster } from '../../../services/transport.service'
import { getClassCapacityByBatchMasterId, updateClassCapacity } from '../../../services/class.service'

const QuickStudentCreation = () => {

    useEffect(() => {
        getGender();
        getBloodList();
        getShiftList();
        selectState1(31);
        getStandardList();
        getMediumList();
        getBatchList();
    }, [])

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const [courseId, setCourseId] = useState<any>(Number)
    const [tempStudentDetailsId, setTempStudentDetailsId] = useState<any>(Number)
    const [commAddr_StateId, setCommAddr_StateId] = useState<any>({ value: 31, label: 'Tamil Nadu' })
    const [commAddr_CityId, setCommAddr_CityId] = useState<any>(Number)
    const [batchMasterId, setBatchMasterId] = useState<any>(Number)
    const [sectionDetailsId, setSectionDetailsId] = useState<any>('')
    const [genderId, setGenderId] = useState<any>('')
    const [shiftId, setShiftId] = useState<any>({ value: 1, label: 'Morning Shift' })
    const [bloodGroupId, setBloodGroupId] = useState<any>('')

    const [tempStudentData, setTempStudentData] = useState<any>([])
    const [genderData, setGenderData] = useState<any>([])
    const [statesData, setStatesData] = useState<any>([])
    const [cityData, setCityData] = useState<any>([])
    const [batchMasterData, setBatchMasterData] = useState<any>([])
    const [sectionDetailsData, setSectionDetailsData] = useState<any>([])
    const [bloodGroupData, setBloodGroupData] = useState<any>([])
    const [shiftData, setShiftData] = useState<any>([])

    const [courseCapacityCanvas, setCourseCapacityCanvas] = useState(false)
    const [courseCapacityData, setCourseCapacityData] = useState<any>([])

    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);
    const [isNavigate, setIsNavigate] = useState<any>(null);
    const { themeStatus, darkModeStatus } = useDarkMode();

    const [transportAreaMasterData, setTransportAreaMasterData] = useState<any>([])
    const [isSchoolTransport, setIsSchoolTransport] = useState('')
    const [transportAreaMasterId, setTransportAreaMasterId] = useState<any>('')
    const [modeOfWays, setModeOfWays] = useState('')

    const [standardDetailsId, setStandardDetailsId] = useState<any>('')
    const [mediumId, setMediumId] = useState<any>('')
    const [standardData, setStandardData] = useState<any>([])
    const [mediumData, setMediumData] = useState<any>([])


    const studentForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            standardDetailsId: '',
            mediumId: '',
            firstName: '',
            lastName: '',
            genderId: '',
            smsToBeSentNumber: '',
            batchMasterId: 0,
            sectionDetailsId: 0,
            commAddr_FlatNo: '',
            commAddr_Street: '',
            commAddr_Area: '',
            commAddr_StateId: '',
            commAddr_CityId: '',
            commAddr_Pincode: '',
            admissionNo: '',
            tempStudentDetailsId: 0,
            email: '',
            emisNo: '',
            aadharCard: '',
            bloodGroupId: '',
            dateOfBirth: '',
            shiftId: 1,
            admissionDateStamp: getCurrentDateAndTime('date'),
            transportAreaMasterId: '',
            modeOfWays: '',
            courseDetailsId: ''
        },
        validate: (values: any) => {
            const errors: {
                standardDetailsId?: any;
                mediumId?: any;
                firstName?: string;
                admissionNo?: string;
                genderId?: any;
                dateOfBirth?: any;
                smsToBeSentNumber?: string;
                email?: string;
                // aadharCard?: string;
                batchMasterId?: string;
                sectionDetailsId?: string;
                shiftId?: string;
                commAddr_FlatNo?: string;
                commAddr_StateId?: string;
                commAddr_CityId?: string;
                commAddr_Pincode?: string;
                transportAreaMasterId?: string;
                modeOfWays?: string;
                courseDetailsId?: string;
            } = {};

            if (!values.firstName) {
                errors.firstName = 'Required'
            }
            else if (values.firstName.length < 3) {
                errors.firstName = 'Must be 3 characters or more';
            } else if (values.firstName.length > 20) {
                errors.firstName = 'Must be 20 characters or less';
            }
            if (!values.admissionNo) {
                errors.admissionNo = 'Required';
            }
            if (!standardDetailsId) {
                errors.standardDetailsId = 'Required';
            }
            if (!mediumId) {
                errors.mediumId = 'Required';
            }
            if (!genderId) {
                errors.genderId = 'Required';
            }
            if (!courseDetailsId && (standardDetailsId?.value == 11 || standardDetailsId?.value == 12)) {
                errors.courseDetailsId = 'Required';
            }
            if (!values.dateOfBirth) {
                errors.dateOfBirth = 'Required';
            }
            if (!values.smsToBeSentNumber) {
                errors.smsToBeSentNumber = 'Required';
            } else if (values.smsToBeSentNumber.length < 10 || values.smsToBeSentNumber.length > 10) {
                errors.smsToBeSentNumber = 'Must be 10 Numbers'
            } else if (!/^[6-9]\d{9}$/i.test(values.smsToBeSentNumber)) {
                errors.smsToBeSentNumber = 'Please enter valid mobile number'
            }
            // if (values.email) {
            //     errors.email = 'Required'
            // }
            // else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            //     errors.email = 'Invalid email address';
            // }
            if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
                errors.email = 'Invalid email address';
            }
            // if (!values.aadharCard) {
            //     errors.aadharCard = 'Required'
            // }
            // else if (!/^\d{12}$/.test(values.aadharCard)) {
            //     errors.aadharCard = 'Must be 12 Numbers';
            // }
            if (!batchMasterId) {
                errors.batchMasterId = 'Required'
            }
            if (!sectionDetailsId) {
                errors.sectionDetailsId = 'Required'
            }
            if (!shiftId) {
                errors.shiftId = 'Required'
            }
            if (!values.commAddr_FlatNo) {
                errors.commAddr_FlatNo = 'Required'
            }
            if (!commAddr_StateId) {
                errors.commAddr_StateId = 'Required'
            }
            if (!commAddr_CityId) {
                errors.commAddr_CityId = 'Required'
            }
            if (!values.commAddr_Pincode) {
                errors.commAddr_Pincode = 'Required'
            }
            else if (!/^\d{6}$/.test(values.commAddr_Pincode)) {
                errors.commAddr_Pincode = 'Must be 6 Numbers';
            }
            if (isSchoolTransport == 'Yes') {
                if (!transportAreaMasterId) {
                    errors.transportAreaMasterId = 'Required'
                }
                if (!modeOfWays) {
                    errors.modeOfWays = 'Required'
                }
            }

            return errors;
        },
        validateOnChange: true,
        onSubmit: (values) => { studentSubmit() },
    });

    const classCapacityEditForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            classCapacityId: 0,
            govQuota: '',
            managementQuota: '',
            totalAllocated: '',
        },
        validate: (values: any) => {
            const errors: {
                govQuota?: string;
                managementQuota?: any;
                totalAllocated?: any;
            } = {};

            if (!values.govQuota) {
                errors.govQuota = 'Required';
            }
            if (!values.managementQuota) {
                errors.managementQuota = 'Required';
            }
            // if (!values.totalAllocated) {
            //     errors.totalAllocated = 'Required';
            // }

            return errors;
        },
        validateOnChange: true,
        onSubmit: (values) => { updateClassCapacitySubmit() },
    });

    const selectMedium = (e: any) => {
        setStandardDetailsId('')
        setTempStudentDetailsId('')
        setTempStudentData([])
        let mediumId = e
        setMediumId(mediumId)
        if (mediumId?.value != undefined) {
            getStandardList();
        } else {
            setStandardData([])
        }
    }

    const selectStandard = (e: any) => {
        setTempStudentDetailsId('')
        setTempStudentData([])
        let standardDetailsId = e
        setStandardDetailsId(standardDetailsId)
        if (standardDetailsId?.value != undefined) {
            getSectionDetailsList(mediumId?.value, standardDetailsId?.value)

            if (standardDetailsId?.value == 11 || standardDetailsId?.value == 12) {
                getCourseList()
            }
            else {
                getTempStudentListByStandardDetails(mediumId?.value, standardDetailsId?.value, 0)
            }
        }
    }

    const [courseDetailsId, setCourseDetailsId] = useState<any>('')

    const selectCourse = (e: any) => {
        setTempStudentDetailsId('')
        let courseDetailsId = e
        setCourseDetailsId(courseDetailsId)
        getTempStudentListByStandardDetails(mediumId?.value, standardDetailsId?.value, courseDetailsId?.value)
    }

    const selectGender = (e: any) => {
        let genderId = e
        setGenderId(genderId)
    }

    const selectBloodGroup = (e: any) => {
        let bloodGroupId = e
        setBloodGroupId(bloodGroupId)
    }

    const selectShift = (e: any) => {
        let shiftId = e
        setShiftId(shiftId)
    }

    const selectTempStudent = (e: any) => {
        let tempStudentDetailsId = e
        setTempStudentDetailsId(tempStudentDetailsId)
        if (tempStudentDetailsId?.value != undefined) {
            getTempStudent(tempStudentDetailsId?.value)
        }
    }

    const selectBatch = (e: any) => {
        let batchMasterId = e
        setBatchMasterId(batchMasterId)
        if (batchMasterId?.value != undefined) {
            checkCourseCapacitySubmit(courseId?.value, batchMasterId?.value)
        }
    }

    const selectSection = (e: any) => {
        let sectionDetailsId = e
        setSectionDetailsId(sectionDetailsId)
    }

    const selectState = (e: any) => {
        setCityData([])
        setCommAddr_StateId('')
        let commAddr_StateId = e
        setCommAddr_StateId(commAddr_StateId)
        if (commAddr_StateId?.value != undefined) {
            getCityList(commAddr_StateId?.value)
        }
    }

    const selectState1 = (e: any) => {
        let commAddr_StateId = e
        setCommAddr_StateId({ value: 31, label: 'Tamil Nadu' })
        getCityList(commAddr_StateId)
    }

    const selectCity = (e: any) => {
        let commAddr_CityId = e
        setCommAddr_CityId(commAddr_CityId)
    }

    function getStandardList() {
        getStandardDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.standardDetails;
                    if (data != undefined) {
                        setStandardData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setStandardData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getMediumList() {
        getMedium(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.medium;
                    if (data != undefined) {
                        setMediumData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setMediumData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    const [courseDetailsData, setCourseDetailsData] = useState<any>([])

    function getCourseList() {
        getCourseDetails(0,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.courseDetails;
                    if (data != undefined) {
                        setCourseDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setCourseDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getTempStudentListByStandardDetails(mediumId: any, standardDetailsId: any, courseDetailsId: any) {
        getStudentTempListByStandardDetailsId(mediumId, standardDetailsId, courseDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentTempListByStandardDetailsId;
                    if (data != undefined) {

                        setTempStudentData(data);

                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setTempStudentData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getTempStudent(tempStudentDetailsId: number) {
        getTempStudentList(tempStudentDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.tempStudentList[0];
                    if (data != undefined) {
                        setCommAddr_StateId({ value: data.commAddr_StateId, label: data.stateName })
                        setCommAddr_CityId({ value: data.commAddr_CityId, label: data.cityName })
                        setGenderId({ value: data.genderId, label: data.gender })
                        setCourseDetailsId({ value: data.courseDetailsId, label: data.courseName })
                        // setBloodGroupId([{ value: data.bloodGroupId, label: data.groupName }])
                        // setShiftId([{ value: data.shiftId, label: data.shift }])
                        getCityList(data.commAddr_StateId)

                        studentForm.setValues({
                            admissionNo: '',
                            tempStudentDetailsId: data.tempStudentDetailsId,
                            firstName: data.firstname,
                            lastName: data.lastname,
                            genderId: data.genderId,
                            standardDetailsId: standardDetailsId,
                            mediumId: mediumId,
                            batchMasterId: batchMasterId,
                            sectionDetailsId: sectionDetailsId,
                            smsToBeSentNumber: data.mobile,
                            commAddr_FlatNo: data.commAddr_FlatNo,
                            commAddr_Street: data.commAddr_Street,
                            commAddr_Area: data.commAddr_Area,
                            commAddr_StateId: data.commAddr_StateId,
                            commAddr_CityId: data.commAddr_CityId,
                            commAddr_Pincode: data.commAddr_Pincode,
                            email: '',
                            aadharCard: '',
                            bloodGroupId: '',
                            admissionDateStamp: getCurrentDateAndTime('date'),
                            dateOfBirth: '',
                            emisNo: '',
                            shiftId: 1,
                            transportAreaMasterId: '',
                            modeOfWays: '',
                            courseDetailsId: courseDetailsId,
                        })
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    // function getTempStudentListByGraduationType(graduationTypeId: number) {
    //     getTempStudentListByGraduationTypeId(graduationTypeId,
    //         (response) => {
    //             if (response.data.success) {
    //                 let data = response.data.data.tempStudentListByGraduationTypeId;
    //                 if (data != undefined) {
    //                     setTempStudentData(data);
    //                 } else {
    //                     toasts("Undefined Data", "Error")
    //                 }
    //             }
    //             else if (response.data.success === false) {
    //                 //toasts(response.data.message, "Error")
    //                 setTempStudentData([]);
    //             } else {
    //                 let errorCode = response.data.error[0].error_code;
    //                 let errorDescription = response.data.error[0].error_description;
    //                 toasts(errorDescription, "Error")
    //             }
    //         }, error => {
    //             toasts(error, "Error")
    //         }
    //     )
    // }

    // function getTempStudent(tempStudentDetailsId: number) {
    //     getTempStudentList(tempStudentDetailsId,
    //         (response) => {
    //             if (response.data.success) {
    //                 let data = response.data.data.tempStudentList[0];
    //                 if (data != undefined) {
    //                     setDepartmentId({ value: data.departmentId, label: data.departmentName })
    //                     setCourseId({ value: data.courseId, label: data.courseName })
    //                     setCommAddr_StateId({ value: data.commAddr_StateId, label: data.stateName })
    //                     setCommAddr_CityId({ value: data.commAddr_CityId, label: data.cityName })
    //                     setGenderId({ value: data.genderId, label: data.gender })
    //                     // setBloodGroupId([{ value: data.bloodGroupId, label: data.groupName }])
    //                     // setShiftId([{ value: data.shiftId, label: data.shift }])
    //                     setSectionDetailsId({ value: 1, label: 'A' })

    //                     getCourseList(data.departmentId, graduationTypeId?.value)
    //                     getCityList(data.commAddr_StateId)
    //                     getBatchList(data.courseId, graduationTypeId?.value)

    //                     studentForm.setValues({
    //                         admissionNo: '',
    //                         tempStudentDetailsId: data.tempStudentDetailsId,
    //                         firstName: data.firstname,
    //                         lastName: data.lastname,
    //                         genderId: data.genderId,
    //                         graduationTypeId: graduationTypeId,
    //                         departmentId: data.departmentId,
    //                         courseId: data.courseId,
    //                         batchMasterId: batchMasterId,
    //                         semesterDetailsId: semesterDetailsId,
    //                         sectionDetailsId: sectionDetailsId,
    //                         smsToBeSentNumber: data.mobile,
    //                         commAddr_FlatNo: data.commAddr_FlatNo,
    //                         commAddr_Street: data.commAddr_Street,
    //                         commAddr_Area: data.commAddr_Area,
    //                         commAddr_StateId: data.commAddr_StateId,
    //                         commAddr_CityId: data.commAddr_CityId,
    //                         commAddr_Pincode: data.commAddr_Pincode,
    //                         email: '',
    //                         aadharCard: '',
    //                         bloodGroupId: '',
    //                         admissionDateStamp: getCurrentDateAndTime('date'),
    //                         dateOfBirth: '',
    //                         emisNo: '',
    //                         shiftId: 1,
    //                         transportAreaMasterId: '',
    //                         modeOfWays: ''
    //                     })
    //                 } else {
    //                     toasts("Undefined Data", "Error")
    //                 }
    //             }
    //             else if (response.data.success === false) {
    //                 toasts(response.data.message, "Error")
    //             } else {
    //                 let errorCode = response.data.error[0].error_code;
    //                 let errorDescription = response.data.error[0].error_description;
    //                 toasts(errorDescription, "Error")
    //             }
    //         }, error => {
    //             toasts(error, "Error")
    //         }
    //     )
    // }

    function getBatchList() {
        getBatchMasterDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.batchMasterDetails;
                    if (data != undefined) {
                        setBatchMasterData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setBatchMasterData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getSectionDetailsList(mediumId: any, standardDetailsId: any) {
        getSectionDetails(mediumId, standardDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.sectionDetails;
                    if (data != undefined) {
                        setSectionDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setSectionDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getGender() {
        getGenderDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.genderDetails;
                    if (data != undefined) {
                        setGenderData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setGenderData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStatesList() {
        getStates(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.statesAndCity;
                    if (data != undefined) {
                        setStatesData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setStatesData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getCityList(stateId: number) {
        getCity(stateId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.statesAndCity[0]['city'];
                    if (data != undefined) {
                        setCityData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setCityData([]);
                } else {

                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getBloodList() {
        getBlood(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.blood;
                    if (data != undefined) {
                        setBloodGroupData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setBloodGroupData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getShiftList() {
        getShift(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.shift;
                    if (data != undefined) {
                        setShiftData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setShiftData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getTransportAreaMasterDetails() {
        getTransportAreaMaster(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.transportAreaMaster;
                    if (data != undefined) {
                        setTransportAreaMasterData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setTransportAreaMasterData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    const selectTransport = (e: any) => {
        let isSchoolTransport = e.target.value
        setIsSchoolTransport(isSchoolTransport)
        if (isSchoolTransport == 'No') {
            setTransportAreaMasterId('')
            setModeOfWays('')
        }
        getTransportAreaMasterDetails();
    }

    function selectTransportArea(e: any) {
        setModeOfWays('')
        let transportAreaMasterId = e
        setTransportAreaMasterId(transportAreaMasterId)
    }

    function setStudentDetails() {
        return ({
            ///----General Info----////
            standardDetailsId: standardDetailsId?.value,
            mediumId: mediumId?.value,
            batchMasterId: batchMasterId?.value,
            courseDetailsId: courseDetailsId?.value != undefined ? courseDetailsId?.value : null,
            admissionNo: studentForm.values.admissionNo,
            tempNo: tempStudentDetailsId?.value,
            firstName: studentForm.values.firstName ? studentForm.values.firstName : null,
            lastName: studentForm.values.lastName ? studentForm.values.lastName : null,
            surName: null,
            studentNameTamil: null,
            surNameTamil: null,
            dateOfBirth: studentForm.values.dateOfBirth ? studentForm.values.dateOfBirth : null,
            genderId: genderId?.value,
            bloodGroupId: bloodGroupId?.value,
            motherTongue: null,
            nationality: null,
            otherNation: null,
            religionId: null,
            casteUniqueId: null,
            casteDetailsId: null,
            subcaste: null,
            category: null,
            aadharCard: studentForm.values.aadharCard ? studentForm.values.aadharCard : null,
            physicallyChallenged: null,
            physicallyChallengedType: null,
            physicallyChallengedPercentage: null,
            martialStatus: null,
            email: studentForm.values.email ? studentForm.values.email : null,
            emisNo: studentForm.values.emisNo ? studentForm.values.emisNo : null,
            ///----Address INFO----////
            commAddr_FlatNo: studentForm.values.commAddr_FlatNo ? studentForm.values.commAddr_FlatNo : null,
            commAddr_Street: studentForm.values.commAddr_Street ? studentForm.values.commAddr_Street : null,
            commAddr_Area: studentForm.values.commAddr_Area ? studentForm.values.commAddr_Area : null,
            commAddr_StateId: commAddr_StateId?.value,
            commAddr_CityId: commAddr_CityId?.value,
            commAddr_Pincode: studentForm.values.commAddr_Pincode ? studentForm.values.commAddr_Pincode : null,

            permAddr_FlatNo: null,
            permAddr_Street: null,
            permAddr_Area: null,
            permAddr_StateId: null,
            permAddr_CityId: null,
            permAddr_Pincode: null,

            bankName: null,
            bankAccountNo: null,
            branch: null,
            ifscCode: null,
            micrCode: null,
            // ///----Class INFO----////
            sectionDetailsId: sectionDetailsId?.value ? sectionDetailsId?.value : null,
            shiftId: shiftId?.value ? shiftId?.value : null,
            studentRollNo: null,
            admissionDateStamp: studentForm.values.admissionDateStamp ? studentForm.values.admissionDateStamp : null,
            sourceName: null,
            transferCourseId: null,
            referralTypeId: null,
            referredId: null,
            referredName: null,
            interimCollegeTransfer: null,
            collegeName: null,
            concessionTypeId: null,
            concessionRemarks: null,
            transportAreaMasterId: transportAreaMasterId?.value ? transportAreaMasterId?.value : null,
            modeOfWays: modeOfWays != "" ? modeOfWays : null,
            isSchoolTransport: isSchoolTransport != "" ? isSchoolTransport : null,
            ///----Family INFO----////
            fatherName: null,
            father_AdharNumber: null,
            fatherOccupation: null,
            natureofJoborBusiness: null,
            fatherContactNumber: null,
            fatherEmailId: null,
            motherName: null,
            mother_AdharNumber: null,
            yearlyIncome: null,
            positionHeld: null,
            motherContactNumber: null,
            smsToBeSentNumber: studentForm.values.smsToBeSentNumber ? studentForm.values.smsToBeSentNumber : null,
            guardian_Name: null,
            guardian_email: null,
            guardian_phone: null,
            guardian_AdharNumber: null,
            isGuardian: 0,
            ///----MARK INFO----////
            registerNo_X: null,
            institutionNameAndAddress_X: null,
            boardName_X: null,
            mediumOfInstitution_X: null,
            monthAndYearOfPassing_X: null,

            registerNo_XI: null,
            institutionNameAndAddress_XI: null,
            boardName_XI: null,
            mediumOfInstitution_XI: null,
            monthAndYearOfPassing_XI: null,

            X_S1_studentSchoolSubject: null,
            X_S1_markSecured: null,
            X_S1_maximumMarks: null,
            X_S1_monthAndYearOfPassing: null,
            X_S1_registerNo: null,
            X_S1_noOfAttempts: null,
            X_S2_studentSchoolSubject: null,
            X_S2_markSecured: null,
            X_S2_maximumMarks: null,
            X_S2_monthAndYearOfPassing: null,
            X_S2_registerNo: null,
            X_S2_noOfAttempts: null,
            X_S3_studentSchoolSubject: null,
            X_S3_markSecured: null,
            X_S3_maximumMarks: null,
            X_S3_monthAndYearOfPassing: null,
            X_S3_registerNo: null,
            X_S3_noOfAttempts: null,
            X_S4_studentSchoolSubject: null,
            X_S4_markSecured: null,
            X_S4_maximumMarks: null,
            X_S4_monthAndYearOfPassing: null,
            X_S4_registerNo: null,
            X_S4_noOfAttempts: null,
            X_S5_studentSchoolSubject: null,
            X_S5_markSecured: null,
            X_S5_maximumMarks: null,
            X_S5_monthAndYearOfPassing: null,
            X_S5_registerNo: null,
            X_S5_noOfAttempts: null,
            X_S6_studentSchoolSubject: null,
            X_S6_markSecured: null,
            X_S6_maximumMarks: null,
            X_S6_monthAndYearOfPassing: null,
            X_S6_registerNo: null,
            X_S6_noOfAttempts: null,

            X_OverAll_markSecured: null,
            X_OverAll_maximumMarks: null,

            ///----Admission----////
            quotaTypeId: null,
            discription: null,
            isCitizenOfIndia: null,
            isClaimingNriQuotaSeat: null,
            isMedicalCertificateEnclosed: null,
            extraCurricularActivities: null,
            ///----Certificate----////
            certificateTypeId: [],
            submittedDate: null,
            status: null,
            ///----Scholarship Details----////
            scholarshipTypeId: [],
            assignedBy: null,
            // documentCertificateTypeId : studentForm.values.documentCertificateTypeId,
            // certificatePath :  "in",
            licenseKey: getLicenseKey,
            createdBy: userAccountId,
            userAccountId: userAccountId
        })
    }

    function studentSubmit() {
        showLoader(true)
        if (studentForm.isValid) {
            let studentPostData = setStudentDetails()
            addStudentDetails(studentPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        setIsNavigate(`../academic/studentList`)
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                        setIsNavigate(null);
                    }
                    else {
                        showLoader(false)
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                        setIsNavigate(null);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                    setIsNavigate(null);
                }
            )
        } else if (studentForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
            setIsNavigate(null);
        }
    }

    function setClassCapacityEditDetails() {
        return ({
            classCapacityId: classCapacityEditForm.values.classCapacityId,
            batchMasterId: batchMasterId?.value,
            courseId: standardDetailsId?.value,
            govQuota: classCapacityEditForm.values.govQuota,
            managementQuota: classCapacityEditForm.values.managementQuota,
            totalAllocated: Number(classCapacityEditForm.values.govQuota) + Number(classCapacityEditForm.values.totalAllocated),
            licenseKey: getLicenseKey,
            userAccountId: userAccountId,
        })
    }

    function checkCourseCapacitySubmit(standardDetailsId: any, batchMasterId: any) {

        if (standardDetailsId != '' && batchMasterId != "") {

            let courseCapacityStudentPostData = {
                standardDetailsId: standardDetailsId,
                batchMasterId: batchMasterId,
                licenseKey: getLicenseKey
            }
            checkClassCapacity(courseCapacityStudentPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        // setAlertStatus({ message: data.message, type: "success" });
                        // setIsOpen(true);
                    }
                    else if (data.success == false) {
                        // setAlertStatus({ message: data.message, type: "error" });
                        // setIsOpen(true);
                        setCourseCapacityCanvas(true)
                        getClassCapacityByBatchMaster(batchMasterId)

                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        // setAlertStatus({ message: errorDescription, type: "error" });
                        // setIsOpen(true);
                    }
                }
                , (error) => {
                    // setAlertStatus({ message: error, type: "error" });
                    // setIsOpen(true);
                }
            )
        } else if (standardDetailsId == '' && batchMasterId == "") {
            // setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            // setIsOpen(true);
        }
    }

    function updateClassCapacitySubmit() {
        showLoader(true)
        if (classCapacityEditForm.isValid) {
            let classCapacityEditPostData = setClassCapacityEditDetails()
            updateClassCapacity(classCapacityEditPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        classCapacityEditForm.resetForm();
                        setCourseCapacityCanvas(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        setIsNavigate(null)
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                        setIsNavigate(null)
                    }
                    else {
                        showLoader(false)
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                        setIsNavigate(null)
                    }
                }, (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                    setIsNavigate(null)
                }
            )
        } else if (classCapacityEditForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
            setIsNavigate(null)
        }
    }

    function getClassCapacityByBatchMaster(batchMasterId: any) {
        getClassCapacityByBatchMasterId(batchMasterId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.classCapacityByBatchMasterId[0];
                    if (data != undefined) {
                        setCourseCapacityData(data)
                        classCapacityEditForm.setValues({
                            classCapacityId: data.classCapacityId,
                            govQuota: data.govQuota,
                            managementQuota: data.managementQuota,
                            totalAllocated: data.totalAllocated,
                        });
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }

                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    // getBatchByGraduationTypeId(courseId, batchMasterId, graduationTypeId?.value,
                    //     (response) => {
                    //         if (response.data.success) {
                    //             let data = response.data.data.batchByCourseId[0];
                    //             if (data != undefined) {
                    //                 setCourseCapacityData(data)
                    //                 classCapacityEditForm.setValues({
                    //                     classCapacityId: 0,
                    //                     govQuota: '',
                    //                     managementQuota: '',
                    //                     totalAllocated: ''
                    //                 });
                    //             }
                    //         }
                    //     }, error => {
                    //         toasts(error, "Error")
                    //     }
                    // )
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }


    function resetState() {
        studentForm.resetForm();
        setTempStudentDetailsId('')
        setTempStudentData([]);
        setBatchMasterId('')
        setSectionDetailsId({ value: 1, label: 'A' })
        setCommAddr_StateId({ value: 31, label: 'Tamil Nadu' })
        setCommAddr_CityId('')
        selectState1(31)
        setShiftId({ value: 1, label: 'Morning Shift' })
        setGenderId('')
        setBloodGroupId('')
        setStandardDetailsId('');
        setMediumId('');
    }

    return (
        <PageWrapper title="Create Student">
            <Page container='fluid'>
                <Card stretch tag='form' noValidate onSubmit={studentForm.handleSubmit}>
                    <CardHeader borderSize={1}>
                        <CardLabel icon='List' iconColor='info'>
                            <CardTitle tag='div' className='h5'>Quick Student Creation</CardTitle>
                        </CardLabel>
                        <CardActions>
                            <Button color='info' isLight icon='PublishedWithChanges' onClick={() => { resetState() }}>
                                Refresh
                            </Button>
                            <Button
                                icon='Add'
                                color='primary'
                                isLight
                                tag='a'
                                to={`../academic/createStudent`}>
                                Create Student
                            </Button>
                        </CardActions>
                    </CardHeader>
                    <CardBody>
                        <div className='row g-4'>
                            <div className='col-3'>
                                <FormGroup id='mediumId' label='Medium' isFloating>
                                    <SearchableSelect isFloating ariaLabel='' placeholder="Medium"
                                        onChange={selectMedium}
                                        onBlur={studentForm.handleBlur}
                                        value={mediumId}
                                        isValid={studentForm.isValid}
                                        isTouched={studentForm.touched.mediumId} invalidFeedback={studentForm.errors.mediumId}
                                        list={mediumData.map((data: any) => ({ value: data.mediumId, label: data.medium }))} />

                                </FormGroup>
                            </div>
                            <div className='col-3'>
                                <FormGroup id='standardDetailsId' label='Standard' isFloating>
                                    <SearchableSelect isFloating ariaLabel='' placeholder="Standard"
                                        onChange={selectStandard}
                                        onBlur={studentForm.handleBlur}
                                        value={standardDetailsId}
                                        isValid={studentForm.isValid}
                                        isTouched={studentForm.touched.standardDetailsId} invalidFeedback={studentForm.errors.standardDetailsId}
                                        list={standardData.map((data: any) => ({ value: data.standardDetailsId, label: data.standardName }))} />

                                </FormGroup>
                            </div>

                            {standardDetailsId?.value == 11 || standardDetailsId?.value == 12 ?
                                <div className='col-md-3'>
                                    <FormGroup id='courseDetailsId' label='Course' isFloating>
                                        <SearchableSelect isFloating
                                            ariaLabel='Course'
                                            onChange={selectCourse}
                                            onBlur={studentForm.handleBlur}
                                            value={courseDetailsId}
                                            isValid={studentForm.isValid}
                                            isTouched={studentForm.touched.courseDetailsId}
                                            invalidFeedback={studentForm.errors.courseDetailsId}
                                            list={courseDetailsData.map((data: any) => (
                                                { value: data.courseDetailsId, label: data.courseName }
                                            ))}
                                            required />
                                    </FormGroup>
                                </div> : null
                            }

                            <div className='col-3'>
                                <FormGroup id='tempStudentDetailsId' label='Temp No' isFloating>
                                    <SearchableSelect isFloating
                                        ariaLabel='Temp No'
                                        onChange={selectTempStudent}
                                        value={tempStudentDetailsId}
                                        list={tempStudentData.map((data: any) => (
                                            { value: data.tempStudentDetailsId, label: data.tempNoAndStudentName }
                                        ))}
                                    />
                                </FormGroup>
                            </div>
                            <div className='col-3'>
                                <FormGroup id='admissionNo' label='Admission No' isFloating>
                                    <Input
                                        placeholder='Enter Admission No'
                                        onChange={studentForm.handleChange}
                                        onBlur={studentForm.handleBlur}
                                        value={studentForm.values.admissionNo}
                                        isValid={studentForm.isValid}
                                        isTouched={studentForm.touched.admissionNo}
                                        invalidFeedback={studentForm.errors.admissionNo}
                                    />
                                </FormGroup>
                            </div>
                            <div className='col-3'>
                                <FormGroup id='genderId' label='Gender' isFloating>
                                    <SearchableSelect isFloating
                                        ariaLabel='Gender'
                                        onChange={selectGender}
                                        onBlur={studentForm.handleBlur}
                                        value={genderId}
                                        isValid={studentForm.isValid}
                                        isTouched={studentForm.touched.genderId}
                                        invalidFeedback={studentForm.errors.genderId}
                                        list={genderData.map((data: any) => (
                                            { value: data.genderId, label: data.gender }
                                        ))}
                                    />
                                </FormGroup>
                            </div>
                            <div className='col-3'>
                                <FormGroup id='firstName' label='First Name' isFloating>
                                    <Input
                                        placeholder='Enter First Name'
                                        onChange={studentForm.handleChange}
                                        onBlur={studentForm.handleBlur}
                                        value={studentForm.values.firstName}
                                        isValid={studentForm.isValid}
                                        isTouched={studentForm.touched.firstName}
                                        invalidFeedback={studentForm.errors.firstName}
                                    />
                                </FormGroup>
                            </div>
                            <div className='col-3'>
                                <FormGroup id='lastName' label='Last Name' isFloating>
                                    <Input
                                        placeholder='Last Name'
                                        onChange={studentForm.handleChange}
                                        value={studentForm.values.lastName}
                                    />
                                </FormGroup>
                            </div>
                            <div className='col-3'>
                                <FormGroup id='dateOfBirth' label='Date Of Birth' isFloating>
                                    <Input placeholder='Date Of Birth'
                                        onChange={studentForm.handleChange}
                                        onBlur={studentForm.handleBlur}
                                        value={studentForm.values.dateOfBirth}
                                        isValid={studentForm.isValid}
                                        isTouched={studentForm.touched.dateOfBirth}
                                        invalidFeedback={studentForm.errors.dateOfBirth}
                                        type='date' />
                                </FormGroup>
                            </div>
                            <div className='col-3'>
                                <FormGroup id='smsToBeSentNumber' label='Mobile/ SMS Number' isFloating>
                                    <Input
                                        placeholder='Mobile No'
                                        onKeyDown={(e: any) => onlyAllowNumber(e)}
                                        onChange={studentForm.handleChange}
                                        onBlur={studentForm.handleBlur}
                                        value={studentForm.values.smsToBeSentNumber}
                                        isValid={studentForm.isValid}
                                        isTouched={studentForm.touched.smsToBeSentNumber}
                                        invalidFeedback={studentForm.errors.smsToBeSentNumber}
                                    />
                                </FormGroup>
                            </div>
                            {/* <div className='col-3'>
                                <FormGroup id='bloodGroupId' label='Blood Group' isFloating>
                                    <SearchableSelect isFloating
                                        ariaLabel='Blood Group'
                                        onChange={selectBloodGroup}
                                        value={bloodGroupId}
                                        list={bloodGroupData.map((data: any) => (
                                            { value: data.bloodGroupId, label: data.groupName }
                                        ))}
                                    />
                                </FormGroup>
                            </div> */}
                            <div className='col-3'>
                                <FormGroup id='emisNo' label='EMIS No' isFloating>
                                    <Input
                                        placeholder='Enter EMIS No'
                                        onKeyDown={(e: any) => onlyAllowNumber(e)}
                                        onChange={studentForm.handleChange}
                                        value={studentForm.values.emisNo}
                                    />
                                </FormGroup>
                            </div>
                            <div className='col-3'>
                                <FormGroup id='aadharCard' label='Aadhar Number' isFloating>
                                    <Input
                                        placeholder='Enter Aadhar Number'
                                        onKeyDown={(e: any) => onlyAllowNumber(e)}
                                        onChange={studentForm.handleChange}
                                        onBlur={studentForm.handleBlur}
                                        value={studentForm.values.aadharCard}
                                        isValid={studentForm.isValid}
                                        isTouched={studentForm.touched.aadharCard}
                                        invalidFeedback={studentForm.errors.aadharCard}
                                    />
                                </FormGroup>
                            </div>
                        </div>

                        <br />

                        <div className='row'>

                            <div className='col-6'>
                                <Card className='rounded-1 mb-2' borderSize={2}>
                                    <CardHeader>
                                        <CardLabel icon='Class'>
                                            <CardTitle>Academic Info</CardTitle>
                                        </CardLabel>
                                    </CardHeader>
                                    <CardBody>
                                        <div className="row g-4">
                                            <div className='col-6'>
                                                <FormGroup id='batchMasterId' label='Batch' isFloating>
                                                    <SearchableSelect isFloating
                                                        ariaLabel='Batch'
                                                        onChange={selectBatch}
                                                        onBlur={studentForm.handleBlur}
                                                        value={batchMasterId}
                                                        isValid={studentForm.isValid}
                                                        isTouched={studentForm.touched.batchMasterId}
                                                        invalidFeedback={studentForm.errors.batchMasterId}
                                                        list={batchMasterData.map((data: any) => (
                                                            { value: data.batchMasterId, label: data.batchName }
                                                        ))}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className='col-6'>
                                                <FormGroup id='sectionDetailsId' label='Section' isFloating>
                                                    <SearchableSelect isFloating
                                                        ariaLabel='Section'
                                                        onChange={selectSection}
                                                        onBlur={studentForm.handleBlur}
                                                        value={sectionDetailsId}
                                                        isValid={studentForm.isValid}
                                                        isTouched={studentForm.touched.sectionDetailsId}
                                                        invalidFeedback={studentForm.errors.sectionDetailsId}
                                                        list={sectionDetailsData.map((data: any) => (
                                                            { value: data.sectionDetailsId, label: data.sectionName }
                                                        ))}
                                                    />
                                                </FormGroup>
                                            </div>
                                            {/* <div className='col-6'>
                                                <FormGroup id='shiftId' label='Shift' isFloating>
                                                    <SearchableSelect isFloating
                                                        ariaLabel='Shift'
                                                        onBlur={studentForm.handleBlur}
                                                        onChange={selectShift}
                                                        value={shiftId}
                                                        isValid={studentForm.isValid}
                                                        isTouched={studentForm.touched.shiftId}
                                                        invalidFeedback={studentForm.errors.shiftId}
                                                        list={shiftData.map((data: any) => (
                                                            { value: data.shiftId, label: data.shift }
                                                        ))}
                                                    />
                                                </FormGroup>
                                            </div> */}
                                            <div className='col-6'>
                                                <FormGroup id='admissionDateStamp' label='Admission Date' isFloating>
                                                    <Input placeholder='Admission Date'
                                                        onChange={studentForm.handleChange}
                                                        // onBlur={studentForm.handleBlur}
                                                        value={studentForm.values.admissionDateStamp}
                                                        // isValid={studentForm.isValid}
                                                        // isTouched={studentForm.touched.admissionDateStamp}
                                                        // invalidFeedback={studentForm.errors.admissionDateStamp}
                                                        type='date' />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-6">
                                                <FormGroup id="isSchoolTransport" label="Transport" isFloating>
                                                    <Select ariaLabel="" placeholder="Select Transport"
                                                        onChange={selectTransport}
                                                        value={isSchoolTransport} onBlur={studentForm.handleBlur}>
                                                        <option value='Yes'>Yes</option>
                                                        <option value='No'>No</option>
                                                    </Select>
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-6">
                                                <FormGroup id="transportAreaMasterId" label="Area of Student" isFloating>
                                                    <SearchableSelect isFloating ariaLabel="" placeholder="Select Area"
                                                        onChange={selectTransportArea}
                                                        value={transportAreaMasterId}
                                                        list={transportAreaMasterData.map((data: any) => (
                                                            { value: data.transportAreaMasterId, label: data.areaName }
                                                        ))}
                                                        disabled={isSchoolTransport == 'Yes' ? false : true}
                                                        onBlur={studentForm.handleBlur}
                                                        isValid={studentForm.isValid}
                                                        isTouched={studentForm.touched.transportAreaMasterId}
                                                        invalidFeedback={studentForm.errors.transportAreaMasterId} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-6">
                                                <FormGroup id="modeOfWays" label="Mode Of Ways" isFloating>
                                                    <Select ariaLabel="" placeholder="Select Ways"
                                                        onChange={(e: any) => { setModeOfWays(e.target.value) }}
                                                        value={modeOfWays}
                                                        onBlur={studentForm.handleBlur}
                                                        isValid={studentForm.isValid}
                                                        disabled={isSchoolTransport == 'Yes' ? false : true}
                                                        isTouched={studentForm.touched.modeOfWays}
                                                        invalidFeedback={studentForm.errors.modeOfWays}>
                                                        <option value='1'>One Way</option>
                                                        <option value='2'>Two Way</option>
                                                    </Select>
                                                </FormGroup>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                            <div className='col-6'>
                                <Card className='rounded-1 mb-2' borderSize={2}>
                                    <CardHeader>
                                        <CardLabel icon='ReceiptLong'>
                                            <CardTitle>Address Info</CardTitle>
                                        </CardLabel>
                                    </CardHeader>
                                    <CardBody>
                                        <div className="row g-4">
                                            <div className='col-6'>
                                                <FormGroup id='commAddr_FlatNo' label='Flat No/Door No' isFloating>
                                                    <Input
                                                        placeholder='Enter Flat No/Door No'
                                                        onChange={studentForm.handleChange}
                                                        onBlur={studentForm.handleBlur}
                                                        value={studentForm.values.commAddr_FlatNo}
                                                        isValid={studentForm.isValid}
                                                        isTouched={studentForm.touched.commAddr_FlatNo}
                                                        invalidFeedback={studentForm.errors.commAddr_FlatNo}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className='col-6'>
                                                <FormGroup id='commAddr_Street' label='Street' isFloating>
                                                    <Input
                                                        placeholder='Enter Street'
                                                        onChange={studentForm.handleChange}
                                                        // onBlur={studentForm.handleBlur}
                                                        value={studentForm.values.commAddr_Street}
                                                    // isValid={studentForm.isValid}
                                                    // isTouched={studentForm.touched.commAddr_Street}
                                                    // invalidFeedback={studentForm.errors.commAddr_Street}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className='col-6'>
                                                <FormGroup id='commAddr_Area' label='Area/Locality' isFloating>
                                                    <Input
                                                        placeholder='Enter Area/Locality'
                                                        onChange={studentForm.handleChange}
                                                        // onBlur={studentForm.handleBlur}
                                                        value={studentForm.values.commAddr_Area}
                                                    // isValid={studentForm.isValid}
                                                    // isTouched={studentForm.touched.commAddr_Area}
                                                    // invalidFeedback={studentForm.errors.commAddr_Area}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className='col-6'>
                                                <FormGroup id='commAddr_StateId' label='State' isFloating>
                                                    <SearchableSelect isFloating
                                                        ariaLabel='State'
                                                        onChange={(e: any) => selectState(e)}
                                                        onBlur={studentForm.handleBlur}
                                                        value={commAddr_StateId}
                                                        isValid={studentForm.isValid}
                                                        isTouched={studentForm.touched.commAddr_StateId}
                                                        invalidFeedback={studentForm.errors.commAddr_StateId}
                                                        list={statesData.map((data: any) => (
                                                            { value: data.stateId, label: data.stateName }
                                                        ))}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className='col-6'>
                                                <FormGroup id='commAddr_CityId' label='City' isFloating>
                                                    <SearchableSelect isFloating
                                                        ariaLabel='City'
                                                        onChange={selectCity}
                                                        onBlur={studentForm.handleBlur}
                                                        value={commAddr_CityId}
                                                        isValid={studentForm.isValid}
                                                        isTouched={studentForm.touched.commAddr_CityId}
                                                        invalidFeedback={studentForm.errors.commAddr_CityId}
                                                        list={cityData.map((data: any) => (
                                                            { value: data.cityId, label: data.cityName }
                                                        ))}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className='col-6'>
                                                <FormGroup id='commAddr_Pincode' label='Pincode' isFloating>
                                                    <Input
                                                        placeholder='Enter Pincode'
                                                        onKeyDown={(e: any) => onlyAllowNumber(e)}
                                                        onChange={studentForm.handleChange}
                                                        onBlur={studentForm.handleBlur}
                                                        value={studentForm.values.commAddr_Pincode}
                                                        isValid={studentForm.isValid}
                                                        isTouched={studentForm.touched.commAddr_Pincode}
                                                        invalidFeedback={studentForm.errors.commAddr_Pincode}
                                                    />
                                                </FormGroup>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                        <div className="col-lg-12 d-flex justify-content-end">
                            <Button className='mt-2' icon="Save" color="info" type="submit" isDisable={!studentForm.isValid && !!studentForm.submitCount}>
                                Save
                            </Button>
                        </div>
                    </CardBody>
                </Card>
            </Page>

            <OffCanvas setOpen={setCourseCapacityCanvas} isOpen={courseCapacityCanvas} titleId='courseCapacityCanvas' placement='end' isBackdrop tag="form" noValidate onSubmit={classCapacityEditForm.handleSubmit} isNotClose>
                <OffCanvasHeader>
                    <OffCanvasTitle id='courseCapacityCanvas'>Update Course Capacity</OffCanvasTitle>
                </OffCanvasHeader>
                <OffCanvasBody>
                    <div className="row g-4">

                        <div className='col-12'>
                            <Card className={`bg-l${darkModeStatus ? 'o25' : '25'
                                }-info bg-l${darkModeStatus ? 'o50' : '10'
                                }-info-hover transition-base rounded-2 mb-4`}
                                shadow='sm'>
                                <CardBody>
                                    <div className='d-flex align-items-center pb-3'>
                                        <div className='flex-shrink-0 ms-2'>
                                            <Icon icon='Assignment' size='4x' color='info' />
                                        </div>
                                        <div className="row">
                                            <div className='flex-grow-1 ms-3'>
                                                <div className='fw-bold fs-5 text-dark mb-0'>
                                                    {courseCapacityData.batchName}
                                                </div>
                                                <div className='text-muted'>
                                                    Batch
                                                </div>
                                            </div>
                                            <div className='flex-grow-1 ms-3'>
                                                <div className='fw-bold fs-5 text-dark mb-0'>
                                                    {courseCapacityData.courseName}
                                                </div>
                                                <div className='text-muted'>
                                                    Course
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>

                        <div className='col-12'>
                            <FormGroup id='govQuota' label='Gov Quota'>
                                <Input onChange={classCapacityEditForm.handleChange} value={classCapacityEditForm.values.govQuota} isValid={classCapacityEditForm.isValid} onBlur={classCapacityEditForm.handleBlur} isTouched={classCapacityEditForm.touched.govQuota} invalidFeedback={classCapacityEditForm.errors.govQuota} type='text' placeholder="Gov Quota" required
                                    onKeyDown={(e: any) => onlyAllowNumber(e)} />
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='managementQuota' label='Management Quota'>
                                <Input onChange={classCapacityEditForm.handleChange} value={classCapacityEditForm.values.managementQuota} isValid={classCapacityEditForm.isValid} onBlur={classCapacityEditForm.handleBlur} isTouched={classCapacityEditForm.touched.managementQuota} invalidFeedback={classCapacityEditForm.errors.managementQuota} type='text' placeholder="Management Quota" required onKeyDown={(e: any) => onlyAllowNumber(e)} />
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='totalAllocated' label='Total Allocated'>
                                <Input onChange={classCapacityEditForm.handleChange} value={Number(classCapacityEditForm.values.govQuota) + Number(classCapacityEditForm.values.managementQuota)} isValid={classCapacityEditForm.isValid} onBlur={classCapacityEditForm.handleBlur} isTouched={classCapacityEditForm.touched.totalAllocated} invalidFeedback={classCapacityEditForm.errors.totalAllocated} type='text' placeholder="Total Allocated" required onKeyDown={(e: any) => onlyAllowNumber(e)} disabled />
                            </FormGroup>
                        </div>
                    </div>
                </OffCanvasBody>
                <div className='row m-0'>
                    <div className='col-12 p-3'>
                        <Button color='info' icon="Save" className='w-100' type="submit" isDisable={!classCapacityEditForm.isValid && !!classCapacityEditForm.submitCount}> Update</Button>
                    </div>
                </div>
            </OffCanvas>

            <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} isNavigate={isNavigate} />
        </PageWrapper>
    )
}

export default QuickStudentCreation
