import React, { useContext, useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import { useReactToPrint } from 'react-to-print';
import { getFailedStudentList } from '../../../../services/dashboard.service';
import NoDataMsg from '../../../../common/components/NoDataMsg';
import Avatar from '../../../../components/Avatar';
import Button from '../../../../components/bootstrap/Button';
import Card, { CardHeader, CardLabel, CardTitle, CardBody, CardSubTitle } from '../../../../components/bootstrap/Card';
import { dataPagination } from '../../../../components/PaginationButtons';
import useSortableData from '../../../../hooks/useSortableData';
import { getLicenseKey } from '../../../../services/application.settings';
import { getColumnsForDataTable, updateColumnsForDataTable, profilePic } from '../../../../services/common.service';
import { toasts } from '../../../../services/toast.service';
import AuthContext from '../../../../contexts/authContext';
import { TableLoader } from '../../../../services/loader.services';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../../../components/bootstrap/Modal';
import classNames from 'classnames';
import useDarkMode from '../../../../hooks/useDarkMode';

const FailedStudents = () => {
	useEffect(() => {
		getFailedStudent();
	}, []);

	const { userAccountId, userTypeId } = useContext(AuthContext);

	const [standardDetailsId, setStandardDetailsId] = useState<any>(0);

	const [FailedStudentData, setFailedStudentData] = useState<any>([]);
	const [columnVisibilityData, setColumnVisibilityData] = useState([]);
	const [allColumnsData, setAllColumnsData] = useState([]);
	const [columnDataById, setColumnDataById] = useState([]);

	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const { items, requestSort, getClassNamesFor } = useSortableData(FailedStudentData);
	const onCurrentPageData = dataPagination(items, currentPage, perPage);

	const [dataSuccess, setDataSuccess] = useState(false);
	const [noDataMsg, setNoDataMsg] = useState('');
	const [isChecked, setIsChecked] = useState(false);
	const componentRef = useRef(null);

	const columnVisibilityForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			searchInput: '',
			available: false,
		},
		validate: (values) => { },
		//validateOnChange: false,
		onSubmit: () => { },
	});

	const [standardName, setStandardName] = useState<any>('');

	function getFailedStudent() {
		getFailedStudentList(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.failedStudentList;
					if (data != undefined) {
						getColumnsForTable('getFailedStudentList', 'get');
						for (let i = 0; i < data.length; i++) {
							data[i].sno = i + 1;
						}
						setFailedStudentData(data);
						setDataSuccess(true);
					} else {
						toasts('Undefined Data', 'Error');
						setDataSuccess(true);
					}
				} else if (response.data.success === false) {
					// toasts(response.data.message, 'Error');
					setDataSuccess(true);
					setFailedStudentData([]);
					getColumnsForTable('getFailedStudentList', 'get');
					setNoDataMsg(response.data.message);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
					setDataSuccess(true);
				}
			},
			(error) => {
				toasts(error, 'Error');
				setDataSuccess(true);
			},
		);
	}

	function getColumnsForTable(apiName: any, type: any) {
		getColumnsForDataTable(
			userTypeId,
			apiName,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.columnsForDataTable;
					if (data != undefined) {
						let allColumnsData = data;
						setAllColumnsData(allColumnsData);
						let columnVisibilityData = allColumnsData.filter(
							(item: any) => item.isDisplay == 1,
						);
						setColumnVisibilityData(columnVisibilityData);

						const allData = allColumnsData.map((item: any) => item.isDisplay);
						const allColumns = allData.filter((item: any) => item == false);

						if (type == 'get') {
							setIsChecked(allColumns == '' ? true : false);
						} else if (type == 'post') {
							const all = [];
							let nonDefaultValue = allColumnsData.filter(
								(item: any) => item.isDefault == false,
							);

							for (let i = 0; i < nonDefaultValue.length; i++) {
								const obj = {
									columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
									isDisplay: allColumns == '' ? 0 : 1,
								};
								all.push(obj);
							}
							updateColumnsSubmit('', '', all);
						}
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					// toasts(response.data.message, "Error")
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {
		if (columnVisibilityForm.isValid) {
			setColumnDataById(isDisplay);
			const columnVisibilityPostData = {
				licenseKey: getLicenseKey,
				userAccountId: userAccountId,
				columns: type
					? type
					: [
						{
							columnVisibilityId: columnVisibilityId,
							isDisplay: isDisplay ? 0 : 1,
						},
					],
			};

			updateColumnsForDataTable(
				columnVisibilityPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						getColumnsForTable('getFailedStudentList', 'get');
					} else if (data.success == false) {
						// toasts(data.success, "Error")
					} else {
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						toasts(errorDescription, 'Error');
					}
				},
				(error) => {
					toasts(error, 'Error');
				},
			);
		} else if (columnVisibilityForm.isValid == false) {
			toasts('Please fill all the details!', 'Error');
		}
	}

	// Export Configuration
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	const [isModalOpen, setIsModalOpen] = useState(false)
	const [failedStudent, setFailedStudent] = useState<any>([])
	const { themeStatus, darkModeStatus } = useDarkMode();

	function onActivate(studentDetails: any) {
		setIsModalOpen(true)
		setFailedStudent(studentDetails)
	}

	return (
		<>
			<div className='col-12'>
				<Card stretch ref={componentRef} className='border border-light rounded-1'>
					<CardHeader borderSize={1}>
						<CardLabel icon='ContentPasteOff' iconColor='info' className='col-lg-5'>
							<CardTitle tag='div' className='h5'>
								Failed Students
							</CardTitle>
							<CardSubTitle>
								{FailedStudentData != '' ? FailedStudentData[0]?.examTypeName : ''}
							</CardSubTitle>
						</CardLabel>
					</CardHeader>
					<CardBody style={{ height: '40vh' }} className='table-responsive'>
						{!dataSuccess ? (
							<TableLoader />
						) : (
							<table className='table table-modern table-hover text-nowrap'>
								<thead>
									<tr>
										<th>S.No</th>
										<th>Standard</th>
										<th>Total Student</th>
										<th>Action</th>
									</tr>
								</thead>
								<tbody className='col-12'>
									{FailedStudentData != '' ? (
										<>
											{FailedStudentData.map((i: any) => (
												<tr key={i.studentDetailsId}>
													<td><div className="ms-3">{i.sno}</div></td>
													<td><div className="ms-4">{i.standardName}</div></td>
													<td><div className="ms-5">{i.studentCount}</div></td>
													<td>
														<Button icon='Person' size={'sm'} isLight color='primary' onClick={() => onActivate(i.studentDetails)}>
															View
														</Button>
													</td>
												</tr>
											))}
										</>
									) : (
										<NoDataMsg
											columnsCount={columnVisibilityData.length + 5}
											msg={noDataMsg}
										/>
									)}
								</tbody>
							</table>
						)}
					</CardBody>
				</Card>
			</div>

			<Modal
				setIsOpen={setIsModalOpen}
				isOpen={isModalOpen}
				titleId='attendanceNotSubmittedDepartments'
				isStaticBackdrop
				isScrollable
				size='lg'>
				<ModalHeader
					className="'modal-header '"
					setIsOpen={() => {
						setIsModalOpen(false);
					}}
					onClick={() => setIsModalOpen(false)}>
					<ModalTitle id='attendanceNotSubmittedDepartments'>
						Student List
					</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<div className='col-lg-5'></div>
					<div className='col-lg-12'>
						<table className='table table-modern table-hover '>
							<thead >
								<tr>
									<th className={classNames('bg-l25-info fs-6', { 'bg-l10-info': !darkModeStatus, 'bg-lo25-info': darkModeStatus })}>
										STANDARD
									</th>
									<th className={classNames('bg-l25-info fs-6', { 'bg-l10-info': !darkModeStatus, 'bg-lo25-info': darkModeStatus })}>
										STUDENT NAME
									</th>
									<th className={classNames('bg-l25-info fs-6', { 'bg-l10-info': !darkModeStatus, 'bg-lo25-info': darkModeStatus })}>
										TOTAL SCORED
									</th>
								</tr>
							</thead>
							<tbody>
								{failedStudent != '' ?
									<>
										{failedStudent.map((i: any, index: any) => (
											<tr key={i.studentDetailsId}>
												<td><div className="ms-4">{i.standardName}</div></td>
												<td>
													<div className='col d-flex'>
														<div className='flex-shrink-0'>
															{i.profilePath != null ? (
																<Avatar
																	src={i.profilePath}
																	size={36}
																/>
															) : (
																<img
																	src={profilePic(i.genderId)}
																	width='36'
																	height='36'
																/>
															)}
														</div>
														<div className='flex-grow-1 ms-3 d-flex justify-content-between align-items-center'>
															<div>
																<span className="fw-bold">{i.studentName}</span>
																<div className='text-muted mt-n1'>
																	<small>{i.admissionNo}</small>
																</div>
															</div>
														</div>
													</div>
												</td>
												<td >
													{i.totalScored} (
													<span className='text-info fw-bold'>
														{i.percentage}
													</span>
													)
												</td>
											</tr>
										))}
									</> : <NoDataMsg columnsCount={3} msg={noDataMsg} />}
							</tbody>
						</table>
					</div>
				</ModalBody>
			</Modal>
		</>
	);
};

export default FailedStudents;
