import { useContext, useEffect, useRef, useState } from 'react';
import useSortableData from '../../hooks/useSortableData';
import PaginationButtons, { dataPagination } from '../PaginationButtons';
import { useFormik } from 'formik';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Page from '../../layout/Page/Page';
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from '../bootstrap/Card';
import FormGroup from '../bootstrap/forms/FormGroup';
import Input from '../bootstrap/forms/Input';
import Button, { ButtonGroup } from '../bootstrap/Button';
import Icon from '../icon/Icon';
import Checks, { ChecksGroup } from '../bootstrap/forms/Checks';
import { toasts } from '../../services/toast.service';
import { getColumnsForDataTable, updateColumnsForDataTable, updateFilter } from '../../services/common.service';
import { getLicenseKey } from '../../services/application.settings';
import Dropdown, { DropdownToggle, DropdownMenu, DropdownItem } from '../bootstrap/Dropdown';
import AuthContext from '../../contexts/authContext';
import { downloadExcel, convertJsonToPdf, downloadFile } from '../../services/ExportService';
import { useReactToPrint } from 'react-to-print';
import { useNavigate } from 'react-router';
import NoDataMsg from '../../common/components/NoDataMsg';
import { TableLoader, showLoader } from '../../services/loader.services';
import { getStudentListByGenderId } from '../../services/student.service';
import { useParams } from 'react-router-dom';
import { getTournamentDetailsListByTournamentDetailsId } from '../../services/sportsManagement.service';
import SubHeader, { SubHeaderLeft, SubheaderSeparator } from '../../layout/SubHeader/SubHeader';

const ParticipantsList = () => {

    const navigate = useNavigate();
    const { tournamentDetailsId } = useParams();

    useEffect(() => {
        //getStudentListByGender(1);
        getTournamentDetails(tournamentDetailsId)
    }, [])

    const [studentListByGenderData, setStudentListByGenderData] = useState([]);
    const [participantsList, setParticipantsList] = useState<any>([])
    const [tournamentDetailsData, setTournamentDetailsData] = useState<any>([]);
    const [columnVisibilityData, setColumnVisibilityData] = useState([]);
    const [allColumnsData, setAllColumnsData] = useState<any>([]);
    const [isChecked, setIsChecked] = useState(false)
    const [columnDataById, setColumnDataById] = useState([])
    const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
    const { userAccountId, userTypeId } = useContext(AuthContext);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(participantsList);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);
    const componentRef = useRef(null);

    const [dataStatus, setDataStatus] = useState(false)
    const [noDataMsg, setNoDataMsg] = useState('')

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            genderId: '',
            dateOfBirth: '',
            available: false,
        },
        validate: (values) => { },
        onSubmit: () => { },
    });


    // Filter
    const filteredData = updateFilter(onCurrentPageData, columnVisibilityData, columnVisibilityForm);

    // function getStudentListByGender(genderId: any) {
    //     setDataStatus(false)
    //     getStudentListByGenderId(genderId,
    //         (response) => {
    //             if (response.data.success) {
    //                 let data = response.data.data.studentListByGenderId;
    //                 if (data != undefined) {
    //                     setStudentListByGenderData(data);
    //                     getColumnsForTable('getStudentListByGenderId', 'get');
    //                     for (let i = 0; i < data.length; i++) {
    //                         data[i].sno = i + 1;
    //                     }
    //                     setDataStatus(true)
    //                 } else {
    //                     toasts("Undefined Data", "Error")
    //                     setDataStatus(true)
    //                 }
    //             }
    //             else if (response.data.success === false) {
    //                 toasts(response.data.message, "Error")
    //                 setDataStatus(true)
    //                 getColumnsForTable('getStudentListByGenderId', 'get');
    //                 setStudentListByGenderData([]);
    //                 setNoDataMsg(response.data.message)
    //             } else {
    //                 let errorCode = response.data.error[0].error_code;
    //                 let errorDescription = response.data.error[0].error_description;
    //                 toasts(errorDescription, "Error")
    //                 setDataStatus(true)
    //             }
    //         }, error => {
    //             toasts(error, "Error")
    //             setDataStatus(true)
    //         }
    //     )
    // }

    function getTournamentDetails(tournamentDetailsId: any) {
        setDataStatus(false)
        getTournamentDetailsListByTournamentDetailsId(tournamentDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.tournamentDetailsList;
                    if (data != undefined) {
                        setTournamentDetailsData(data[0]);
                        setParticipantsList(data[0].participantsList);
                        console.log(data[0].participantsList);
                        for (let i = 0; i < data[0].participantsList.length; i++) {
                            data[0].participantsList[i].sno = i + 1;
                        }
                        getColumnsForTable('getTournamentParticipantsList', 'get');
                        setDataStatus(true)
                    } else {
                        toasts("Undefined Data", "Error")
                        setDataStatus(true)
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setDataStatus(true)
                    getColumnsForTable('getTournamentParticipantsList', 'get');
                    setTournamentDetailsData([]);
                    setNoDataMsg(response.data.message)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                    setDataStatus(true)
                }
            }, error => {
                toasts(error, "Error")
                setDataStatus(true)
            }
        )
    }

    function getColumnsForTable(apiName: any, type: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setColumnVisibilityData(columnVisibilityData)

                        const allData = allColumnsData.map((item: any) => item.isDisplay);
                        const allColumns = allData.filter((item: any) => item == false);

                        if (type == 'get') {
                            setIsChecked(allColumns == '' ? true : false);
                        }
                        else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

                            for (let i = 0; i < nonDefaultValue.length; i++) {

                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: allColumns == '' ? 0 : 1
                                }
                                all.push(obj);
                            }
                            updateColumnsSubmit('', '', all)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )

    }

    function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {

        if (columnVisibilityForm.isValid) {
            setColumnDataById(isDisplay)
            const columnVisibilityPostData = {
                licenseKey: getLicenseKey,
                userAccountId: userAccountId,
                columns: type ? type : [
                    {
                        columnVisibilityId: columnVisibilityId,
                        isDisplay: isDisplay ? 0 : 1
                    }
                ]
            }

            updateColumnsForDataTable(columnVisibilityPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        getColumnsForTable('getTournamentParticipantsList', 'get');
                    }
                    else if (data.success == false) {
                        // toasts(data.success, "Error")
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        toasts(errorDescription, "Error")
                    }
                }
                , (error) => {
                    toasts(error, "Error")
                }
            )
        } else if (columnVisibilityForm.isValid == false) {
            toasts("Please fill all the details!", "Error")
        }
    }

    // Export Configuration
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <PageWrapper title={`Participants List`}>
            <SubHeader>
                <SubHeaderLeft>
                    <Button color='info' isLink icon='ArrowBack' onClick={() => navigate(`../sportsManagement/eventManager`)}>
                        Back to List
                    </Button>
                </SubHeaderLeft>
            </SubHeader>
            <Page container='fluid'>
                <Card stretch data-tour='list' ref={componentRef} id='pdf'>
                    <CardHeader borderSize={1}>
                        <CardLabel icon='list' iconColor='info'>
                            <CardTitle tag='div' className='h5'>
                                Participants List
                            </CardTitle>
                        </CardLabel>
                        <CardActions className="d-print-none">
                            <ButtonGroup color='primary'>
                                <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                                <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
                            </ButtonGroup>
                            <Dropdown isOpen={columnVisibilityMenu} setIsOpen={setColumnVisibilityMenu} isButtonGroup>
                                <DropdownToggle>
                                    <Button icon='FilterAlt' color='primary' isLight>Filter</Button>
                                </DropdownToggle>
                                <DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
                                    <DropdownItem>
                                        <div className='container py-2'>
                                            <form className='row g-3'>
                                                <div className='col-12'>
                                                    <FormGroup>
                                                        <h6>Select All</h6>
                                                        <Checks id='available' type='switch' label='Select All Columns' onChange={() => getColumnsForTable('getTournamentParticipantsList', 'post')} checked={isChecked} ariaLabel='Available status' />
                                                    </FormGroup>
                                                </div>
                                                <div className='col-12' style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                                                    <FormGroup>
                                                        <h6>Columns</h6>
                                                        <ChecksGroup>
                                                            {allColumnsData.map((i: any) => (
                                                                <Checks key={i.columnVisibilityId} label={i.columnName} onChange={() => updateColumnsSubmit(i.columnVisibilityId, i.isDisplay, '')} checked={i.isDisplay} disabled={i.isDefault == true} />
                                                            ))}
                                                        </ChecksGroup>
                                                    </FormGroup>
                                                </div>
                                            </form>
                                        </div>
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                            <Dropdown isButtonGroup>
                                <DropdownToggle>
                                    <Button color='primary' isLight icon='CloudDownload'> Export </Button>
                                </DropdownToggle>
                                <DropdownMenu isAlignmentEnd>
                                    <DropdownItem>
                                        <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel("ParticipantsDetailsList", participantsList, columnVisibilityData)}> Excel </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(participantsList, columnVisibilityData, "ParticipantsDetailsList")}> PDF
                                        </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile("ParticipantsDetailsList", participantsList, columnVisibilityData)}> CSV </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <Button color='primary' isLight icon='Print' onClick={handlePrint}> Print </Button>
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                            {/* <Button color='primary' icon='Add' isLight onClick={() => setAddEventOffCanvas(true)}>
                                Add New
                            </Button> */}
                        </CardActions>
                    </CardHeader>
                    <CardBody className='table-responsive' isScrollable>
                        {!dataStatus ?
                            <TableLoader /> :
                            <table className='table table-modern table-hover text-nowrap'>
                                <thead>
                                    <tr className='table-primary' style={{ textAlign: 'center' }}>
                                        <td colSpan={13} style={{
                                            fontFamily: 'Bookman Old Style',
                                            color: 'rgb(61, 57, 57)'
                                        }}>
                                            <h5 style={{ justifyContent: 'space-evenly' }}>EVENT TYPE : {tournamentDetailsData.eventTypeName}  -  TOURNAMENT NAME : {tournamentDetailsData.tournamentName}  -  GENDER : {tournamentDetailsData.gender}  -  TOTAL MEMBER : {tournamentDetailsData.totalMember}</h5>
                                        </td>
                                    </tr>
                                    <tr style={{ height: '20px' }}></tr>
                                    <tr>
                                        <th scope='col' onClick={() => requestSort('sno')}
                                            className='cursor-pointer text-decoration-underline'>S.No{' '}
                                            <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                        </th>
                                        {columnVisibilityData.map((column: any) => (
                                            <th key={column.keyName} scope='col' onClick={() => requestSort(column.keyName)}
                                                className='cursor-pointer text-decoration-underline'>
                                                {column.columnName}
                                                <Icon size='lg' className={getClassNamesFor(column.keyName)} icon='FilterList' />
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredData != "" ?
                                        <>
                                            {filteredData.map((rowData: any) => (<tr key={rowData.studentDetailsId}>
                                                <td>{rowData.sno}</td>
                                                {columnVisibilityData.map((column: any) => (
                                                    (column.isDisplay && rowData[column.keyName]) ?
                                                        <td key={`${rowData.tournamentDetailsId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />))}
                                            </tr>))}</>
                                        :
                                        <NoDataMsg columnsCount={columnVisibilityData.length + 1} msg={noDataMsg} />}
                                </tbody>
                            </table>
                        }
                    </CardBody>
                    <PaginationButtons className='d-print-none' data={items} label='items' setCurrentPage={setCurrentPage} currentPage={currentPage} perPage={perPage} setPerPage={setPerPage} />
                </Card>
            </Page>
        </PageWrapper>
    );
};

export default ParticipantsList


