import { useFormik } from 'formik';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import NoDataMsg from '../../../common/components/NoDataMsg';
import OpenCardComponent from '../../../common/components/OpenCardComponent';
import SearchableSelect from '../../../common/components/SearchableSelect';
import AuthContext from '../../../contexts/authContext';
import useDarkMode from '../../../hooks/useDarkMode';
import useSortableData from '../../../hooks/useSortableData';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import { getLicenseKey } from '../../../services/application.settings';
import { updateFilter, getColumnsForDataTable, updateColumnsForDataTable, getStandardDetails, getStudentListByStandardDetailsId } from '../../../services/common.service';
import { downloadExcel, convertJsonToPdf, downloadFile } from '../../../services/ExportService';
import { toasts } from '../../../services/toast.service';
import Button, { ButtonGroup } from '../../bootstrap/Button';
import Card, { CardHeader, CardLabel, CardTitle, CardBody, CardActions } from '../../bootstrap/Card';
import Collapse from '../../bootstrap/Collapse';
import Dropdown, { DropdownToggle, DropdownMenu, DropdownItem } from '../../bootstrap/Dropdown';
import Checks, { ChecksGroup } from '../../bootstrap/forms/Checks';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Modal, { ModalHeader, ModalTitle, ModalBody } from '../../bootstrap/Modal';
import Icon from '../../icon/Icon';
import PaginationButtons, { dataPagination } from '../../PaginationButtons';
import Input from '../../bootstrap/forms/Input';

function StandardWiseStudentList() {
    useEffect(() => {
        getStandardDetailsList();
    }, []);

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const { themeStatus, darkModeStatus } = useDarkMode();
    const [standardDetailsId, setStandardDetailsId] = useState<any>('')
    const [studentListByStandardData, setStudentListByStandardData] = useState([]);
    const [dataSuccess, setDataSuccess] = useState(false);
    const [isOpenListCard, setIsOpenListCard] = useState(true);
    const [standardData, setStandardData] = useState<any>([]);
    const [columnVisibilityData, setColumnVisibilityData] = useState([]);
    const [allColumnsData, setAllColumnsData] = useState([]);
    const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
    const [columnDataById, setColumnDataById] = useState([]);
    const componentRef = useRef(null);
    const [isChecked, setIsChecked] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(studentListByStandardData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);
    const [userType, setUserType] = useState('');
    const [isLoader, setIsLoader] = useState(false);
    const [noDataMsg, setNoDataMsg] = useState('');

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },

        //validateOnChange: false,
        onSubmit: () => { },
    });

    // Filter
    const filteredData = updateFilter(
        onCurrentPageData,
        columnVisibilityData,
        columnVisibilityForm,   
    );

    const selectAcademicPeriod = (e: any) => {
        setDataSuccess(false);
        setIsOpenListCard(true);
        let standardDetailsId = e;
        setStandardDetailsId(standardDetailsId);
    };

    function getStandardDetailsList() {
        getStandardDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.standardDetails;
                    if (data != undefined) {
                        const standardData = [{ standardDetailsId: 0, standardName: 'Select All' }];
                        data.forEach((obj: { standardDetailsId: number; standardName: string }) => {
                            standardData.push(obj);
                        });
                        setStandardData(standardData);
                    } else {
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setStandardData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, 'Error');
                }
            },
            (error) => {
                toasts(error, 'Error');
            },
        );
    }

    function getStudentListByStandardDetails(standardDetailsId: string) {
        setIsLoader(true);
        getStudentListByStandardDetailsId(
            standardDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentListByStandardDetailsId;
                    if (data != undefined) {
                        setIsLoader(false);
                        setStudentListByStandardData(data);
                        setIsOpenListCard(false);
                        setDataSuccess(true);
                        getColumnsForTable('getStudentListByStandardDetailsId', 'get');
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        setIsLoader(false);
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    setIsLoader(false);
                    //toasts(response.data.message, "Error")
                    getColumnsForTable('getStudentListByStandardDetailsId', 'get');
                    setStudentListByStandardData([]);
                    setDataSuccess(true);
                    setIsOpenListCard(false);
                    setNoDataMsg(response.data.message);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setIsLoader(false);
                    toasts(errorDescription, 'Error');
                }
            },
            (error) => {
                setIsLoader(false);
                toasts(error, 'Error');
            },
        );
    }


    function getColumnsForTable(apiName: any, type: any) {
        getColumnsForDataTable(
            userTypeId,
            apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData);
                        let columnVisibilityData = allColumnsData.filter(
                            (item: any) => item.isDisplay == 1,
                        );
                        setColumnVisibilityData(columnVisibilityData);

                        const allData = allColumnsData.map((item: any) => item.isDisplay);
                        const allColumns = allData.filter((item: any) => item == false);

                        if (type == 'get') {
                            setIsChecked(allColumns == '' ? true : false);
                        } else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter(
                                (item: any) => item.isDefault == false,
                            );

                            for (let i = 0; i < nonDefaultValue.length; i++) {
                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: allColumns == '' ? 0 : 1,
                                };
                                all.push(obj);
                            }
                            updateColumnsSubmit('', '', all);
                        }
                    } else {
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    toasts(response.data.message, 'Error');
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, 'Error');
                }
            },
            (error) => {
                toasts(error, 'Error');
            },
        );
    }

    function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {
        if (columnVisibilityForm.isValid) {
            setColumnDataById(isDisplay);
            const columnVisibilityPostData = {
                licenseKey: getLicenseKey,
                userAccountId: userAccountId,
                columns: type
                    ? type
                    : [
                        {
                            columnVisibilityId: columnVisibilityId,
                            isDisplay: isDisplay ? 0 : 1,
                        },
                    ],
            };

            updateColumnsForDataTable(
                columnVisibilityPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        getColumnsForTable('getStudentListByStandardDetailsId', 'get');
                    } else if (data.success == false) {
                        // toasts(data.success, "Error")
                    } else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        toasts(errorDescription, 'Error');
                    }
                },
                (error) => {
                    toasts(error, 'Error');
                },
            );
        } else if (columnVisibilityForm.isValid == false) {
            toasts('Please fill all the details!', 'Error');
        }
    }

    function view() {
        setDataSuccess(false);
        setIsLoader(true);
        columnVisibilityForm.resetForm();
        if (standardDetailsId?.value != undefined) {
            getStudentListByStandardDetails(standardDetailsId?.value);
        }
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <>
            <PageWrapper title='Staff Referral Report'>
                <Page container='fluid'>
                    <Collapse isOpen={isOpenListCard}>
                        <Card>
                            <CardHeader borderSize={1}>
                                <CardLabel icon='list' iconColor='primary'>
                                    <CardTitle>Standard Wise Student Report</CardTitle>
                                </CardLabel>
                            </CardHeader>
                            <CardBody>
                                <div className='row'>
                                    <div className='col-4'>
                                        <FormGroup
                                            id='standardDetailsId'
                                            label='Standard'
                                            isFloating>
                                            <SearchableSelect
                                                isFloating
                                                ariaLabel='Standard'
                                                onChange={selectAcademicPeriod}
                                                value={standardDetailsId}
                                                list={standardData.map((data: any) => ({
                                                    value: data.standardDetailsId,
                                                    label: data.standardName,
                                                }))}
                                            />
                                        </FormGroup>
                                    </div>
                                    <div className='col-3 mt-2'>
                                        <Button icon='ArrowDownward' color='primary' onClick={view} isDisable={standardDetailsId?.value == undefined}>
                                            View
                                        </Button>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Collapse>

                    <OpenCardComponent
                        isOpenListCard={isOpenListCard}
                        setIsOpenListCard={setIsOpenListCard}
                        isLoader={isLoader}
                    />

                    {dataSuccess ? (
                        <Card stretch data-tour='list' ref={componentRef} id='pdf'>
                            <CardHeader borderSize={1}>
                                <CardLabel icon='List' iconColor='info' className='col-lg-6'>
                                    <CardTitle tag='div' className='h5'>
                                        Standard Wise Student List
                                    </CardTitle>
                                </CardLabel>
                                &nbsp;&nbsp;
                                <ButtonGroup
                                    className='col-lg-3 justify-content-end d-print-none'
                                    color='primary'>
                                    <Icon
                                        className='mt-1'
                                        icon='Search'
                                        size='2x'
                                        color='primary'
                                    />
                                    <Input
                                        id='searchInput'
                                        type='search'
                                        placeholder='Search...'
                                        onChange={columnVisibilityForm.handleChange}
                                        value={columnVisibilityForm.values.searchInput}
                                    />
                                </ButtonGroup>
                                <CardActions className='d-print-none'>
                                    <Dropdown
                                        isOpen={columnVisibilityMenu}
                                        setIsOpen={setColumnVisibilityMenu}
                                        isButtonGroup>
                                        <DropdownToggle>
                                            <Button icon='FilterAlt' color='primary' isLight>
                                                Filter
                                            </Button>
                                        </DropdownToggle>
                                        <DropdownMenu
                                            isAlignmentEnd
                                            size='lg'
                                            isCloseAfterLeave={false}>
                                            <DropdownItem>
                                                <div className='container py-2'>
                                                    <form className='row g-3'>
                                                        <div className='col-12'>
                                                            <FormGroup>
                                                                <h6>Select All</h6>
                                                                <Checks
                                                                    id='available'
                                                                    type='switch'
                                                                    label='Select All Columns'
                                                                    onChange={() =>
                                                                        getColumnsForTable(
                                                                            'getStudentListByStandardDetailsId',
                                                                            'post',
                                                                        )
                                                                    }
                                                                    checked={isChecked}
                                                                    ariaLabel='Available status'
                                                                />
                                                            </FormGroup>
                                                        </div>
                                                        <div
                                                            className='col-12'
                                                            style={{
                                                                maxHeight: '200px',
                                                                overflowY: 'scroll',
                                                            }}>
                                                            <FormGroup>
                                                                <h6>Columns</h6>
                                                                <ChecksGroup>
                                                                    {allColumnsData.map(
                                                                        (i: any) => (
                                                                            <Checks
                                                                                key={
                                                                                    i.columnVisibilityId
                                                                                }
                                                                                id='{i.columnVisibilityId}'
                                                                                label={i.columnName}
                                                                                onChange={() =>
                                                                                    updateColumnsSubmit(
                                                                                        i.columnVisibilityId,
                                                                                        i.isDisplay,
                                                                                        '',
                                                                                    )
                                                                                }
                                                                                checked={
                                                                                    i.isDisplay
                                                                                }
                                                                                disabled={
                                                                                    i.isDefault ==
                                                                                    true
                                                                                }
                                                                            />
                                                                        ),
                                                                    )}
                                                                </ChecksGroup>
                                                            </FormGroup>
                                                        </div>
                                                    </form>
                                                </div>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                    <Dropdown isButtonGroup>
                                        <DropdownToggle>
                                            <Button color='primary' isLight icon='CloudDownload'>
                                                {' '}
                                                Export{' '}
                                            </Button>
                                        </DropdownToggle>
                                        <DropdownMenu isAlignmentEnd>
                                            <DropdownItem>
                                                <Button
                                                    color='primary'
                                                    isLight
                                                    icon='CloudDownload'
                                                    onClick={() =>
                                                        downloadExcel(
                                                            'Standard Wise Student List',
                                                            studentListByStandardData,
                                                            columnVisibilityData,
                                                        )
                                                    }>
                                                    {' '}
                                                    Excel{' '}
                                                </Button>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <Button
                                                    color='primary'
                                                    isLight
                                                    icon='PictureAsPdf'
                                                    onClick={() =>
                                                        convertJsonToPdf(
                                                            studentListByStandardData,
                                                            columnVisibilityData,
                                                            'Standard Wise Student List',
                                                        )
                                                    }>
                                                    {' '}
                                                    PDF
                                                </Button>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <Button
                                                    color='primary'
                                                    isLight
                                                    icon='CloudDownload'
                                                    onClick={() =>
                                                        downloadFile(
                                                            'Standard Wise Student List',
                                                            studentListByStandardData,
                                                            columnVisibilityData,
                                                        )
                                                    }>
                                                    {' '}
                                                    CSV{' '}
                                                </Button>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <Button
                                                    color='primary'
                                                    isLight
                                                    icon='Print'
                                                    onClick={handlePrint}>
                                                    {' '}
                                                    Print{' '}
                                                </Button>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                </CardActions>
                            </CardHeader>
                            <CardBody className='table-responsive' isScrollable>
                                <table className='table table-modern table-hover text-nowrap'>
                                    <thead>
                                        <tr>
                                            <th
                                                scope='col'
                                                onClick={() => requestSort('sno')}
                                                className='cursor-pointer text-decoration-underline'>
                                                S.No{' '}
                                                <Icon
                                                    size='lg'
                                                    className={getClassNamesFor('sno')}
                                                    icon='FilterList'
                                                />
                                            </th>
                                            {columnVisibilityData.map((column: any) => (
                                                <th
                                                    key={column.keyName}
                                                    scope='col'
                                                    onClick={() => requestSort(column.keyName)}
                                                    className='cursor-pointer text-decoration-underline'>
                                                    {column.columnName}
                                                    <Icon
                                                        size='lg'
                                                        className={getClassNamesFor(column.keyName)}
                                                        icon='FilterList'
                                                    />
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredData != '' ? (
                                            <>
                                                {filteredData.map((rowData: any) => (
                                                    <tr key={rowData.referredId}>
                                                        <td>{rowData.sno}</td>
                                                        {columnVisibilityData.map((column: any) =>
                                                            column.isDisplay &&
                                                                rowData[column.keyName] ? (
                                                                <td
                                                                    key={`${rowData.referredId}-${column.keyName}`}>
                                                                    {rowData[column.keyName]}
                                                                </td>
                                                            ) : (
                                                                <td
                                                                    key={`empty-${column.keyName}`}
                                                                />
                                                            ),
                                                        )}

                                                    </tr>
                                                ))}{' '}
                                            </>
                                        ) : (
                                            <NoDataMsg
                                                columnsCount={columnVisibilityData.length + 2}
                                                msg={noDataMsg}
                                            />
                                        )}
                                    </tbody>
                                </table>
                            </CardBody>
                            <PaginationButtons
                                className='d-print-none'
                                data={items}
                                label='items'
                                setCurrentPage={setCurrentPage}
                                currentPage={currentPage}
                                perPage={perPage}
                                setPerPage={setPerPage}
                            />
                        </Card>
                    ) : null}
                </Page>
            </PageWrapper>
        </>
    );
}

export default StandardWiseStudentList;
