import { useFormik } from "formik";
import { useContext, useEffect, useRef, useState } from "react"
import { useReactToPrint } from "react-to-print";
import DeleteComponents from "../../../../common/components/DeleteComponents";
import NoDataMsg from "../../../../common/components/NoDataMsg";
import SearchableSelect from "../../../../common/components/SearchableSelect";
import AuthContext from "../../../../contexts/authContext";
import useSortableData from "../../../../hooks/useSortableData";
import Page from "../../../../layout/Page/Page";
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";
import SubHeader, { SubHeaderLeft, SubHeaderRight } from "../../../../layout/SubHeader/SubHeader";
import AlertService from "../../../../services/AlertService";
import { getLicenseKey } from "../../../../services/application.settings";
import { updateFilter, getColumnsForDataTable, updateColumnsForDataTable, onlyAllowNumber } from "../../../../services/common.service";
import { downloadExcel, convertJsonToPdf, downloadFile } from "../../../../services/ExportService";
import { showLoader, TableLoader } from "../../../../services/loader.services";
import { toasts } from "../../../../services/toast.service";
import { addVehicleTaxDetails, updateVehicleTaxDetails, deleteVehicleTaxDetails, addVehicleSparePartsDetails, updateVehicleSparePartsDetails, deleteVehicleSparePartsDetails, getVehicleTaxDetails, getVehicleSparePartsDetails, getSparePartsType, getVehicleDetails } from "../../../../services/transport.service";
import Button, { ButtonGroup } from "../../../bootstrap/Button";
import Card, { CardLabel, CardTitle, CardActions, CardHeader, CardBody } from "../../../bootstrap/Card";
import Dropdown, { DropdownToggle, DropdownMenu, DropdownItem } from "../../../bootstrap/Dropdown";
import Checks, { ChecksGroup } from "../../../bootstrap/forms/Checks";
import FormGroup from "../../../bootstrap/forms/FormGroup";
import OffCanvas, { OffCanvasHeader, OffCanvasTitle, OffCanvasBody } from "../../../bootstrap/OffCanvas";
import PaginationButtons, { dataPagination } from "../../../PaginationButtons";
import Icon from "../../../icon/Icon";
import Input from "../../../bootstrap/forms/Input";



const VehicleSpareAndTaxDetails = () => {


    useEffect(() => {
        setIsSpareOrTaxDetails(true)
        getVehicleTaxList()
        getVehicleDetailsList();
        getSparePartsTypeList();

    }, [])

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const [vehicleDetailsId, setVehicleDetailsId] = useState<any>('')
    const [sparePartsTypeId, setSparePartsTypeId] = useState<any>('')
    const [vehicleTaxData, setVehicleTaxData] = useState([]);
    const [vehicleDetailsData, setVehicleDetailsData] = useState<any>([])
    const [sparePartsData, setSparePartsData] = useState<any>([])
    const [vehicleSparePartsDetails, setVehicleSparePartsDetails] = useState([])
    const [columnVisibilityData, setColumnVisibilityData] = useState([]);
    const [allColumnsData, setAllColumnsData] = useState([]);
    const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
    const [columnDataById, setColumnDataById] = useState([])
    const [isChecked, setIsChecked] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);

    const { items: items1, requestSort: requestSort1, getClassNamesFor: getClassNamesFor1 } = useSortableData(vehicleSparePartsDetails);
    const { items: items, requestSort: requestSort, getClassNamesFor: getClassNamesFor } = useSortableData(vehicleTaxData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);
    const onCurrentPageData1 = dataPagination(items1, currentPage, perPage);

    const [dataStatus, setDataStatus] = useState(false)

    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);

    const [spareOrTaxDetails, setIsSpareOrTaxDetails] = useState(true)
    const [addVehicleTaxOffCanvas, setAddVehicleTaxOffCanvas] = useState(false);
    const [editVehicleTaxOffCanvas, setEditVehicleTaxOffCanvas] = useState(false);
    const [deleteVehicleTaxModal, setDeleteVehicleTaxModal] = useState(false)
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [addVehicleSparOffCanvas, setAddVehicleSpareOffCanvas] = useState(false);
    const [editVehicleSpareOffCanvas, setEditVehicleSpareOffCanvas] = useState(false);
    const [APIName, setAPIName] = useState('');

    const [noDataMsg, setNoDataMsg] = useState('')

    const componentRef = useRef(null);

    const columnVisibleForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },
        //validateOnChange: false,
        onSubmit: () => { },
    });

    const vehicleTaxForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            vehicleDetailsId: '',
            transactionNo: '',
            amount: '',
            transactionDate: '',
        },
        validate: (values) => {
            const errors: {
                vehicleDetailsId?: string;
                transactionNo?: string;
                amount?: string;
                transactionDate?: string;
            } = {};
            if (!vehicleDetailsId) {
                errors.vehicleDetailsId = 'Required';
            }
            if (!values.transactionNo) {
                errors.transactionNo = 'Required';
            }
            if (!values.amount) {
                errors.amount = 'Required';
            }
            if (!values.transactionDate) {
                errors.transactionDate = 'Required';
            }

            return errors;
        },
        //validateOnChange: false,
        onSubmit: (values) => { vehicleTaxSubmit() },
    });

    const editVehicleTaxForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            vehicleTaxDetailsId: '',
            vehicleDetailsId: '',
            transactionNo: '',
            amount: '',
            transactionDate: '',
        },
        validate: (values) => {
            const errors: {
                vehicleDetailsId?: string;
                transactionNo?: string;
                amount?: string;
                transactionDate?: string;
            } = {};
            if (!vehicleDetailsId) {
                errors.vehicleDetailsId = 'Required';
            }
            if (!values.transactionNo) {
                errors.transactionNo = 'Required';
            }
            if (!values.amount) {
                errors.amount = 'Required';
            }
            if (!values.transactionDate) {
                errors.transactionDate = 'Required';
            }

            return errors;
        },
        onSubmit: (values) => { updateVehicleTaxSubmit() },
    });

    const sparePartsForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            vehicleDetailsId: '',
            sparePartsTypeId: '',
            date: '',
            itemNo: '',
            vendorName: '',
            brandName: '',
            mha: '',
            manufacturers: '',
            warrantyDate: '',
            retriedDate: '',
        },
        validate: (values) => {
            const errors: {
                vehicleDetailsId?: string;
                sparePartsTypeId?: string;
                date?: string;
                itemNo?: string;
                vendorName?: string;
                brandName?: string;
                mha?: string;
                manufacturers?: string;
                warrantyDate?: string;
                retriedDate?: string;
            } = {};
            if (!vehicleDetailsId) {
                errors.vehicleDetailsId = 'Required';
            }
            if (!sparePartsTypeId) {
                errors.sparePartsTypeId = 'Required';
            }
            if (!values.date) {
                errors.date = 'Required';
            }
            if (!values.itemNo) {
                errors.itemNo = 'Required';
            }

            return errors;
        },
        //validateOnChange: false,
        onSubmit: (values) => { sparePartsSubmit() },
    });

    const sparePartsEditForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            vehicleSparePartsDetailsId: '',
            vehicleDetailsId: '',
            sparePartsTypeId: '',
            date: '',
            itemNo: '',
            vendorName: '',
            brandName: '',
            mha: '',
            manufacturers: '',
            warrantyDate: '',
            retriedDate: '',
        },
        validate: (values) => {
            const errors: {
                vehicleDetailsId?: string;
                sparePartsTypeId?: string;
                date?: string;
                itemNo?: string;
                vendorName?: string;
                brandName?: string;
                mha?: string;
                manufacturers?: string;
                warrantyDate?: string;
                retriedDate?: string;
            } = {};
            if (!vehicleDetailsId) {
                errors.vehicleDetailsId = 'Required';
            }
            if (!sparePartsTypeId) {
                errors.sparePartsTypeId = 'Required';
            }
            if (!values.date) {
                errors.date = 'Required';
            }
            if (!values.itemNo) {
                errors.itemNo = 'Required';
            }

            return errors;
        },
        //validateOnChange: false,
        onSubmit: (values) => { updateSparePartSubmit() },
    });

    const selectVehicle = (e: any) => {
        let vehicleDetailsId = e
        setVehicleDetailsId(vehicleDetailsId)
    }

    const selectSparePartsType = (e: any) => {
        let sparePartsTypeId = e
        setSparePartsTypeId(sparePartsTypeId)
    }

    // Filter
    const filteredData = updateFilter(onCurrentPageData, columnVisibilityData, columnVisibleForm);

    const filteredData1 = updateFilter(onCurrentPageData1, columnVisibilityData, columnVisibleForm);

    function onActiveForRoadTax(i: any) {
        if (i != undefined) {
            setEditVehicleTaxOffCanvas(true)
            let selectedRoadTax = i
            setVehicleDetailsId({ value: i.vehicleDetailsId, label: i.registrationNo })

            editVehicleTaxForm.setValues({
                vehicleTaxDetailsId: selectedRoadTax.vehicleTaxDetailsId,
                vehicleDetailsId: vehicleDetailsId?.value,
                transactionNo: selectedRoadTax.transactionNo,
                amount: selectedRoadTax.amount,
                transactionDate: selectedRoadTax.transactionDate
            });
        }
    }

    function onActive(i: any) {
        if (i != undefined) {
            setEditVehicleSpareOffCanvas(true)
            let selectedSpareParts = i
            setVehicleDetailsId({ value: selectedSpareParts.vehicleDetailsId, label: selectedSpareParts.registrationNo })
            setSparePartsTypeId({ value: selectedSpareParts.sparePartsTypeId, label: selectedSpareParts.sparePartsName })

            sparePartsEditForm.setValues({
                vehicleSparePartsDetailsId: selectedSpareParts.vehicleSparePartsDetailsId,
                vehicleDetailsId: vehicleDetailsId?.value,
                sparePartsTypeId: sparePartsTypeId?.value,
                date: selectedSpareParts.date,
                itemNo: selectedSpareParts.itemNo,
                vendorName: selectedSpareParts.vendorName,
                brandName: selectedSpareParts.brandName,
                mha: selectedSpareParts.mha,
                manufacturers: selectedSpareParts.manufacturers,
                warrantyDate: selectedSpareParts.warrantyDate,
                retriedDate: selectedSpareParts.retriedDate,
            });

        }
    }

    function setVehicleTaxDetails() {
        return ({
            vehicleDetailsId: vehicleDetailsId?.value,
            transactionNo: vehicleTaxForm.values.transactionNo,
            amount: vehicleTaxForm.values.amount,
            transactionDate: vehicleTaxForm.values.transactionDate,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId
        })
    }

    function setEditVehicleTaxDetails() {
        return ({
            vehicleTaxDetailsId: editVehicleTaxForm.values.vehicleTaxDetailsId,
            vehicleDetailsId: vehicleDetailsId?.value,
            transactionNo: editVehicleTaxForm.values.transactionNo,
            amount: editVehicleTaxForm.values.amount,
            transactionDate: editVehicleTaxForm.values.transactionDate,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId
        })
    }

    function vehicleTaxSubmit() {
        showLoader(true)
        if (vehicleTaxForm.isValid) {
            let vehicleTaxPostData = setVehicleTaxDetails()
            addVehicleTaxDetails(vehicleTaxPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        closeAndReset();
                        getVehicleTaxList()
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false)
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (vehicleTaxForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function updateVehicleTaxSubmit() {
        showLoader(true)
        if (editVehicleTaxForm.isValid) {
            let vehicleEditTaxPostData = setEditVehicleTaxDetails()
            updateVehicleTaxDetails(vehicleEditTaxPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        closeAndReset();
                        getVehicleTaxList()
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false)
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (editVehicleTaxForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function deleteVehicleTaxSubmit() {
        showLoader(true)
        let vehicleTaxPostData = setEditVehicleTaxDetails()
        deleteVehicleTaxDetails(vehicleTaxPostData,
            (response) => {
                const data = response.data;
                if (data.success == true) {
                    showLoader(false)
                    setAlertStatus({ message: data.message, type: "success" });
                    setIsOpen(true);
                    closeAndReset();
                    getVehicleTaxList()
                }
                else if (data.success == false) {
                    showLoader(false)
                    setAlertStatus({ message: data.message, type: "error" });
                    setIsOpen(true);
                }
                else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    showLoader(false)
                    setAlertStatus({ message: errorDescription, type: "error" });
                    setIsOpen(true);
                }
            }
            , (error) => {
                showLoader(false)
                setAlertStatus({ message: error, type: "error" });
                setIsOpen(true);
            }
        )
    }

    function setSparePartsDetails() {
        return ({
            vehicleDetailsId: vehicleDetailsId?.value,
            sparePartsTypeId: sparePartsTypeId?.value,
            date: sparePartsForm.values.date,
            itemNo: sparePartsForm.values.itemNo,
            vendorName: sparePartsForm.values.vendorName,
            brandName: sparePartsForm.values.brandName,
            mha: sparePartsForm.values.mha,
            manufacturers: sparePartsForm.values.manufacturers,
            warrantyDate: sparePartsForm.values.warrantyDate,
            retriedDate: sparePartsForm.values.retriedDate,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId
        })
    }

    function setEditSparePartsDetails() {
        return ({
            vehicleSparePartsDetailsId: sparePartsEditForm.values.vehicleSparePartsDetailsId,
            vehicleDetailsId: vehicleDetailsId?.value,
            sparePartsTypeId: sparePartsTypeId?.value,
            date: sparePartsEditForm.values.date,
            itemNo: sparePartsEditForm.values.itemNo,
            vendorName: sparePartsEditForm.values.vendorName,
            brandName: sparePartsEditForm.values.brandName,
            mha: sparePartsEditForm.values.mha,
            manufacturers: sparePartsEditForm.values.manufacturers,
            warrantyDate: sparePartsEditForm.values.warrantyDate,
            retriedDate: sparePartsEditForm.values.retriedDate,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId
        })
    }

    function sparePartsSubmit() {
        showLoader(true)
        if (sparePartsForm.isValid) {
            let sparePartsPostData = setSparePartsDetails()
            addVehicleSparePartsDetails(sparePartsPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        closeAndReset();
                        getVehicleSparePartsList();
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false)
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (sparePartsForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function updateSparePartSubmit() {
        showLoader(true)
        if (sparePartsEditForm.isValid) {
            let sparePartsPostData = setEditSparePartsDetails()
            updateVehicleSparePartsDetails(sparePartsPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        closeAndReset();
                        getVehicleSparePartsList();
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false)
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (sparePartsEditForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function deleteSparePartSubmit() {
        showLoader(true)
        let sparePartsPostData = setEditSparePartsDetails()
        deleteVehicleSparePartsDetails(sparePartsPostData,
            (response) => {
                const data = response.data;
                if (data.success == true) {
                    showLoader(false)
                    setAlertStatus({ message: data.message, type: "success" });
                    setIsOpen(true);
                    closeAndReset();
                    getVehicleSparePartsList();
                }
                else if (data.success == false) {
                    showLoader(false)
                    setAlertStatus({ message: data.message, type: "error" });
                    setIsOpen(true);
                }
                else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    showLoader(false)
                    setAlertStatus({ message: errorDescription, type: "error" });
                    setIsOpen(true);
                }
            }
            , (error) => {
                showLoader(false)
                setAlertStatus({ message: error, type: "error" });
                setIsOpen(true);
            }
        )
    }

    function getVehicleTaxList() {
        setDataStatus(false)
        getVehicleTaxDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.vehicleTaxDetails;
                    if (data != undefined) {
                        setDataStatus(true)
                        setVehicleTaxData(data);
                        getColumnsForTable('getVehicleTaxDetails', 'get');
                        setAPIName('getVehicleTaxDetails')
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        setDataStatus(true)
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setDataStatus(true)
                    //toasts(response.data.message, "Error")

                    getColumnsForTable('getVehicleTaxDetails', 'get');
                    setVehicleTaxData([]);
                    setNoDataMsg(response.data.message)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setDataStatus(true)
                    toasts(errorDescription, "Error")
                }
            }, error => {
                setDataStatus(true)
                toasts(error, "Error")
            }
        )
    }

    function getVehicleSparePartsList() {
        setDataStatus(false)
        getVehicleSparePartsDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.vehicleSparePartsDetails;
                    if (data != undefined) {
                        setDataStatus(true)
                        setVehicleSparePartsDetails(data);
                        getColumnsForTable('getVehicleSparePartsDetails', 'get');
                        setAPIName('getVehicleSparePartsDetails')
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        setDataStatus(true)
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setDataStatus(true)
                    //toasts(response.data.message, "Error")
                    getColumnsForTable('getVehicleSparePartsDetails', 'get');
                    setVehicleSparePartsDetails([]);
                    setNoDataMsg(response.data.message)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setDataStatus(true)
                    toasts(errorDescription, "Error")
                }
            }, error => {
                setDataStatus(true)
                toasts(error, "Error")
            }
        )
    }

    function getSparePartsTypeList() {
        getSparePartsType(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.sparePartsType;
                    if (data != undefined) {
                        setSparePartsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setSparePartsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getVehicleDetailsList() {
        getVehicleDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.vehicleDetails;
                    if (data != undefined) {
                        setVehicleDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setVehicleDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getColumnsForTable(apiName: any, type: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setColumnVisibilityData(columnVisibilityData)

                        const allData = allColumnsData.map((item: any) => item.isDisplay);
                        const allColumns = allData.filter((item: any) => item == false);

                        if (type == 'get') {
                            setIsChecked(allColumns == '' ? true : false);
                        }
                        else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

                            for (let i = 0; i < nonDefaultValue.length; i++) {

                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: allColumns == '' ? 0 : 1
                                }
                                all.push(obj);
                            }
                            updateColumnsSubmit('', '', all)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )

    }

    function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {

        if (columnVisibleForm.isValid) {

            setColumnDataById(isDisplay)
            const columnVisibilityPostData = {
                licenseKey: getLicenseKey,
                userAccountId: userAccountId,
                columns: type ? type : [
                    {
                        columnVisibilityId: columnVisibilityId,
                        isDisplay: isDisplay ? 0 : 1
                    }
                ]
            }

            updateColumnsForDataTable(columnVisibilityPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        getColumnsForTable(APIName, 'get');
                    }
                    else if (data.success == false) {
                        // toasts(data.success, "Error")
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        toasts(errorDescription, "Error")
                    }
                }
                , (error) => {
                    toasts(error, "Error")
                }
            )
        } else if (columnVisibleForm.isValid == false) {
            toasts("Please fill all the details!", "Error")
        }
    }

    function selectVehicleSpareDetails() {
        setDataStatus(false)
        setIsSpareOrTaxDetails(!spareOrTaxDetails)
        getVehicleSparePartsList();
    }

    function selectVehicleTaxDetails() {
        setDataStatus(false)
        setIsSpareOrTaxDetails(!spareOrTaxDetails)
        getVehicleTaxList();
    }

    function closeAndReset() {
        setAddVehicleTaxOffCanvas(false)
        setEditVehicleTaxOffCanvas(false)
        setDeleteVehicleTaxModal(false)
        setAddVehicleSpareOffCanvas(false)
        setEditVehicleSpareOffCanvas(false)
        setIsDeleteOpen(false);
        vehicleTaxForm.resetForm();
        editVehicleTaxForm.resetForm();
        //vehicleTaxDeleteForm.resetForm();
        setVehicleDetailsId('')
        setSparePartsTypeId('')
        sparePartsForm.resetForm();
        sparePartsEditForm.resetForm();
    }

    // Excel Configuration
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <PageWrapper title={`Spare And Tax Details`}>

            <SubHeader>
                <SubHeaderLeft>
                    <CardLabel icon='Settings' iconColor='info' className="col-lg-8">
                        <CardTitle tag='div' className='h5'>Vehicle Spare And Tax Details</CardTitle>
                    </CardLabel>
                </SubHeaderLeft>
                <SubHeaderRight>
                    <CardActions>
                        <Button
                            icon='Contacts'
                            color='info'
                            isLight={spareOrTaxDetails ? false : true}
                            onClick={selectVehicleTaxDetails}>
                            Road Tax Details
                        </Button>

                        <Button
                            icon='LocalPolice'
                            color='info'
                            isLight={spareOrTaxDetails ? true : false}
                            onClick={selectVehicleSpareDetails}>
                            Spare Part Details
                        </Button>
                    </CardActions>
                    <></>
                </SubHeaderRight>
            </SubHeader>
            <Page container='fluid'>

                {spareOrTaxDetails ?
                    <Card stretch data-tour='list' ref={componentRef}>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info' className="col-lg-5">
                                <CardTitle tag='div' className='h5'>Road Tax Details</CardTitle>
                            </CardLabel>&nbsp;&nbsp;

                            <ButtonGroup className="col-lg-3 d-print-none" color='primary'>
                                <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                                <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibleForm.handleChange} value={columnVisibleForm.values.searchInput} />
                            </ButtonGroup>

                            <CardActions className="d-print-none">
                                <Dropdown isOpen={columnVisibilityMenu} setIsOpen={setColumnVisibilityMenu} isButtonGroup>
                                    <DropdownToggle>
                                        <Button icon='FilterAlt' color='primary' isLight>Filter</Button>
                                    </DropdownToggle>
                                    <DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
                                        <DropdownItem>
                                            <div className='container py-2'>
                                                <form className='row g-3'>
                                                    <div className='col-12'>
                                                        <FormGroup>
                                                            <h6>Select All</h6>
                                                            <Checks id='available' type='switch' label='Select All Columns' onChange={() => getColumnsForTable(APIName, 'post')} checked={isChecked} ariaLabel='Available status' />
                                                        </FormGroup>
                                                    </div>
                                                    <div className='col-12' style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                                                        <FormGroup>
                                                            <h6>Columns</h6>
                                                            <ChecksGroup>
                                                                {allColumnsData.map((i: any) => (
                                                                    <Checks key={i.columnVisibilityId} label={i.columnName} onChange={() => updateColumnsSubmit(i.columnVisibilityId, i.isDisplay, '')} checked={i.isDisplay} disabled={i.isDefault == true} />
                                                                ))}
                                                            </ChecksGroup>
                                                        </FormGroup>
                                                    </div>
                                                </form>
                                            </div>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                <Dropdown isButtonGroup>
                                    <DropdownToggle>
                                        <Button color='primary' isLight icon='CloudDownload'> Export </Button>
                                    </DropdownToggle>
                                    <DropdownMenu isAlignmentEnd>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel("vehicle Tax List", vehicleTaxData, columnVisibilityData)}> Excel </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(vehicleTaxData, columnVisibilityData, "vehicle Tax List")}> PDF
                                            </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile("vehicle Tax List", vehicleTaxData, columnVisibilityData)}> CSV </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='Print' onClick={handlePrint}> Print </Button>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                <Button color='primary' icon='Add' isLight onClick={() => setAddVehicleTaxOffCanvas(true)}>
                                    Add New
                                </Button>
                            </CardActions>
                        </CardHeader>

                        <CardBody className='table-responsive' isScrollable>
                            <></>
                            {!dataStatus ?
                                <TableLoader /> :
                                <table className='table table-modern table-hover text-nowrap'>
                                    <thead>
                                        <tr>
                                            <th scope='col' onClick={() => requestSort('sno')}
                                                className='cursor-pointer text-decoration-underline'>S.No{' '}
                                                <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                            </th>

                                            {columnVisibilityData.map((column: any) => (
                                                <th key={column.keyName} scope='col' onClick={() => requestSort(column.keyName)}
                                                    className='cursor-pointer text-decoration-underline'>
                                                    {column.columnName}
                                                    <Icon size='lg' className={getClassNamesFor(column.keyName)} icon='FilterList' />
                                                </th>
                                            ))}
                                            <th scope='col' className='d-print-none'>
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredData != "" ?
                                            <>
                                                {filteredData.map((rowData: any) => (
                                                    <tr key={rowData.vehicleTaxDetailsId}>
                                                        <td>{rowData.sno}</td>
                                                        {columnVisibilityData.map((column: any) => (
                                                            (column.isDisplay && rowData[column.keyName]) ?
                                                                <td key={`${rowData.vehicleTaxDetailsId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />
                                                        ))}
                                                        <td className="d-print-none">
                                                            <Button color='dark' isLight icon='Edit' aria-label='Edit' onClick={() => { onActiveForRoadTax(rowData) }} />
                                                        </td>
                                                    </tr>
                                                ))
                                                } </>
                                            :
                                            <NoDataMsg columnsCount={columnVisibilityData.length + 2} msg={noDataMsg} />}
                                    </tbody>
                                </table>
                            }
                        </CardBody>
                        <PaginationButtons className="d-print-none" data={items} label='items' setCurrentPage={setCurrentPage} currentPage={currentPage} perPage={perPage} setPerPage={setPerPage} />
                    </Card>
                    :
                    <Card stretch data-tour='list' ref={componentRef}>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info' className="col-lg-5">
                                <CardTitle tag='div' className='h5'>Spare Part Details</CardTitle>
                            </CardLabel>&nbsp;&nbsp;

                            <ButtonGroup className="col-lg-3 d-print-none" color='primary'>
                                <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                                <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibleForm.handleChange} value={columnVisibleForm.values.searchInput} />
                            </ButtonGroup>

                            <CardActions className="d-print-none">
                                <Dropdown isOpen={columnVisibilityMenu} setIsOpen={setColumnVisibilityMenu} isButtonGroup>
                                    <DropdownToggle>
                                        <Button icon='FilterAlt' color='primary' isLight>Filter</Button>
                                    </DropdownToggle>
                                    <DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
                                        <DropdownItem>
                                            <div className='container py-2'>
                                                <form className='row g-3'>
                                                    <div className='col-12'>
                                                        <FormGroup>
                                                            <h6>Select All</h6>
                                                            <Checks id='available' type='switch' label='Select All Columns' onChange={() => getColumnsForTable(APIName, 'post')} checked={isChecked} ariaLabel='Available status' />
                                                        </FormGroup>
                                                    </div>
                                                    <div className='col-12' style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                                                        <FormGroup>
                                                            <h6>Columns</h6>
                                                            <ChecksGroup>
                                                                {allColumnsData.map((i: any) => (
                                                                    <Checks key={i.columnVisibilityId} label={i.columnName} onChange={() => updateColumnsSubmit(i.columnVisibilityId, i.isDisplay, '')} checked={i.isDisplay} disabled={i.isDefault == true} />
                                                                ))}
                                                            </ChecksGroup>
                                                        </FormGroup>
                                                    </div>
                                                </form>
                                            </div>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                <Dropdown isButtonGroup>
                                    <DropdownToggle>
                                        <Button color='primary' isLight icon='CloudDownload'> Export </Button>
                                    </DropdownToggle>
                                    <DropdownMenu isAlignmentEnd>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel("Spare Part Details", vehicleSparePartsDetails, columnVisibilityData)}> Excel </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(vehicleSparePartsDetails, columnVisibilityData, "Spare Part Details")}> PDF
                                            </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile("Spare Part Details", vehicleSparePartsDetails, columnVisibilityData)}> CSV </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='Print' onClick={handlePrint}> Print </Button>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                <Button color='primary' icon='Add' isLight onClick={() => setAddVehicleSpareOffCanvas(true)}>
                                    Add New
                                </Button>
                            </CardActions>
                        </CardHeader>

                        <CardBody className='table-responsive' isScrollable>
                            <></>
                            {!dataStatus ?
                                <TableLoader /> :
                                <table className='table table-modern table-hover text-nowrap'>
                                    <thead>
                                        <tr>
                                            <th scope='col' onClick={() => requestSort1('sno')}
                                                className='cursor-pointer text-decoration-underline'>S.No{' '}
                                                <Icon size='lg' className={getClassNamesFor1('sno')} icon='FilterList' />
                                            </th>

                                            {columnVisibilityData.map((column: any) => (
                                                <th key={column.keyName} scope='col' onClick={() => requestSort1(column.keyName)}
                                                    className='cursor-pointer text-decoration-underline'>
                                                    {column.columnName}
                                                    <Icon size='lg' className={getClassNamesFor1(column.keyName)} icon='FilterList' />
                                                </th>
                                            ))}
                                            <th scope='col' className='d-print-none'>
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredData1 != "" ?
                                            <>
                                                {filteredData1.map((rowData: any) => (
                                                    <tr key={rowData.vehicleTaxDetailsId}>
                                                        <td>{rowData.sno}</td>
                                                        {columnVisibilityData.map((column: any) => (
                                                            (column.isDisplay && rowData[column.keyName]) ?
                                                                <td key={`${rowData.vehicleTaxDetailsId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />
                                                        ))}
                                                        <td className="d-print-none">
                                                            <Button color='dark' isLight icon='Edit' aria-label='Edit' onClick={() => { onActive(rowData) }} />
                                                        </td>
                                                    </tr>
                                                ))
                                                } </>
                                            :
                                            <NoDataMsg columnsCount={columnVisibilityData.length + 2} msg={noDataMsg} />}
                                    </tbody>
                                </table>
                            }
                        </CardBody>
                        <PaginationButtons className="d-print-none" data={items} label='items' setCurrentPage={setCurrentPage} currentPage={currentPage} perPage={perPage} setPerPage={setPerPage} />
                    </Card>
                }

            </Page>

            <OffCanvas setOpen={setAddVehicleTaxOffCanvas} isOpen={addVehicleTaxOffCanvas} titleId='addVehicleTaxOffCanvas' isBodyScroll placement='end' tag="form" noValidate onSubmit={vehicleTaxForm.handleSubmit}>
                <OffCanvasHeader setOpen={setAddVehicleTaxOffCanvas} onClick={closeAndReset}>
                    <OffCanvasTitle id='addVehicleTaxOffCanvas'>Add Road Tax</OffCanvasTitle>
                </OffCanvasHeader>
                <OffCanvasBody>
                    <div className="row g-4">
                        <div className='col-12'>
                            <FormGroup id='vehicleDetailsId' label='Vehicle' onBlur={vehicleTaxForm.handleBlur}>
                                <SearchableSelect ariaLabel='Vehicle' placeholder="Select Vehicle" onChange={selectVehicle} value={vehicleDetailsId} isValid={vehicleTaxForm.isValid} onBlur={vehicleTaxForm.handleBlur} isTouched={vehicleTaxForm.touched.vehicleDetailsId} invalidFeedback={vehicleTaxForm.errors.vehicleDetailsId} list={vehicleDetailsData.map((data: any) => ({ value: data.vehicleDetailsId, label: data.vehicleId }))} required />
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='transactionNo' label='Transaction No'>
                                <Input onChange={vehicleTaxForm.handleChange} value={vehicleTaxForm.values.transactionNo} isValid={vehicleTaxForm.isValid} onBlur={vehicleTaxForm.handleBlur} isTouched={vehicleTaxForm.touched.transactionNo} invalidFeedback={vehicleTaxForm.errors.transactionNo} type='text' placeholder="Enter Transaction No" required />
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='amount' label='Amount'>
                                <Input onChange={vehicleTaxForm.handleChange} value={vehicleTaxForm.values.amount} isValid={vehicleTaxForm.isValid} onBlur={vehicleTaxForm.handleBlur} isTouched={vehicleTaxForm.touched.amount} invalidFeedback={vehicleTaxForm.errors.amount} type='text' placeholder="Enter Amount" required onKeyDown={(e: any) => onlyAllowNumber(e)} />
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='transactionDate' label='Transaction Date'>
                                <Input onChange={vehicleTaxForm.handleChange} value={vehicleTaxForm.values.transactionDate} isValid={vehicleTaxForm.isValid} onBlur={vehicleTaxForm.handleBlur} isTouched={vehicleTaxForm.touched.transactionDate} invalidFeedback={vehicleTaxForm.errors.transactionDate} type='date' placeholder="Enter Transaction Date" required />
                            </FormGroup>
                        </div>
                    </div>
                </OffCanvasBody>
                <div className='row m-0'>
                    <div className='col-12 p-3'>
                        <Button color='info' className='w-100' type="submit" isDisable={!vehicleTaxForm.isValid && !!vehicleTaxForm.submitCount}>
                            Save
                        </Button>
                    </div>
                </div>
            </OffCanvas>

            <OffCanvas setOpen={setEditVehicleTaxOffCanvas} isOpen={editVehicleTaxOffCanvas} titleId='editVehicleTaxOffCanvas' isBodyScroll placement='end' tag="form" noValidate onSubmit={editVehicleTaxForm.handleSubmit}>
                <OffCanvasHeader setOpen={setEditVehicleTaxOffCanvas} onClick={closeAndReset}>
                    <OffCanvasTitle id='editVehicleTaxOffCanvas'>Add Road Tax</OffCanvasTitle>
                </OffCanvasHeader>
                <OffCanvasBody>
                    <div className="row g-4">
                        <div className='col-12'>
                            <FormGroup id='vehicleDetailsId' label='Vehicle'>
                                <SearchableSelect ariaLabel='Vehicle' placeholder="Select Vehicle" onChange={selectVehicle} onBlur={editVehicleTaxForm.handleBlur} value={vehicleDetailsId} isValid={editVehicleTaxForm.isValid} isTouched={editVehicleTaxForm.touched.vehicleDetailsId} invalidFeedback={editVehicleTaxForm.errors.vehicleDetailsId} list={vehicleDetailsData.map((data: any) => ({ value: data.vehicleDetailsId, label: data.vehicleId }))} required />
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='transactionNo' label='Transaction No'>
                                <Input onChange={editVehicleTaxForm.handleChange} value={editVehicleTaxForm.values.transactionNo} isValid={editVehicleTaxForm.isValid} onBlur={editVehicleTaxForm.handleBlur} isTouched={editVehicleTaxForm.touched.transactionNo} invalidFeedback={editVehicleTaxForm.errors.transactionNo} type='text' placeholder="Enter Transaction No" required />
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='amount' label='Amount'>
                                <Input onChange={editVehicleTaxForm.handleChange} value={editVehicleTaxForm.values.amount} isValid={editVehicleTaxForm.isValid} onBlur={editVehicleTaxForm.handleBlur} isTouched={editVehicleTaxForm.touched.amount} invalidFeedback={editVehicleTaxForm.errors.amount} type='text' placeholder="Enter Amount" required onKeyDown={(e: any) => onlyAllowNumber(e)} />
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='transactionDate' label='Transaction Date'>
                                <Input onChange={editVehicleTaxForm.handleChange} value={editVehicleTaxForm.values.transactionDate} isValid={editVehicleTaxForm.isValid} onBlur={editVehicleTaxForm.handleBlur} isTouched={editVehicleTaxForm.touched.transactionDate} invalidFeedback={editVehicleTaxForm.errors.transactionDate} type='date' placeholder="Enter Transaction Date" required />
                            </FormGroup>
                        </div>

                        <div className="d-flex justify-content-center position-absolute top-65 start-50 translate-middle">
                            <DeleteComponents isDeleteOpen={isDeleteOpen} setIsDeleteOpen={setIsDeleteOpen}
                                deleteFunction={deleteVehicleTaxSubmit}
                            />
                        </div>
                    </div>
                </OffCanvasBody>
                <div className='row m-0'>
                    <div className='col-6 p-3'>
                        <Button color='danger' className='w-100' icon="Delete" onClick={() => setIsDeleteOpen(!isDeleteOpen)}>
                            Delete
                        </Button>
                    </div>
                    <div className='col-6 p-3'>
                        <Button color='info' className='w-100' type="submit" isDisable={!editVehicleTaxForm.isValid && !!editVehicleTaxForm.submitCount}>
                            update
                        </Button>
                    </div>
                </div>
            </OffCanvas>

            <OffCanvas setOpen={setAddVehicleSpareOffCanvas} isOpen={addVehicleSparOffCanvas} titleId='addVehicleSparOffCanvas' isBodyScroll placement='end' tag="form" noValidate onSubmit={sparePartsForm.handleSubmit}>
                <OffCanvasHeader setOpen={setAddVehicleSpareOffCanvas} onClick={closeAndReset}>
                    <OffCanvasTitle id='addVehicleSparOffCanvas'>Add Vehicle Spare Parts</OffCanvasTitle>
                </OffCanvasHeader>
                <OffCanvasBody>
                    <div className="row g-4">
                        <div className='col-12'>
                            <FormGroup id='vehicleDetailsId' label='Vehicle'>
                                <SearchableSelect ariaLabel='Vehicle' placeholder="Select Vehicle" onChange={selectVehicle} onBlur={sparePartsForm.handleBlur} value={vehicleDetailsId} isValid={sparePartsForm.isValid} isTouched={sparePartsForm.touched.vehicleDetailsId} invalidFeedback={sparePartsForm.errors.vehicleDetailsId} list={vehicleDetailsData.map((data: any) => ({ value: data.vehicleDetailsId, label: data.vehicleId }))} required />
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='sparePartsTypeId' label='Spare Parts Type'>
                                <SearchableSelect ariaLabel='Vehicle' placeholder="Select Spare Parts Type" onChange={selectSparePartsType} onBlur={sparePartsForm.handleBlur} value={sparePartsTypeId} isValid={sparePartsForm.isValid} isTouched={sparePartsForm.touched.sparePartsTypeId} invalidFeedback={sparePartsForm.errors.sparePartsTypeId} list={sparePartsData.map((data: any) => ({ value: data.sparePartsTypeId, label: data.sparePartsName }))} required />
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='date' label='Date'>
                                <Input onChange={sparePartsForm.handleChange} value={sparePartsForm.values.date} isValid={sparePartsForm.isValid} onBlur={sparePartsForm.handleBlur} isTouched={sparePartsForm.touched.date} invalidFeedback={sparePartsForm.errors.date} type='date' placeholder="Enter Date" required />
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='itemNo' label='Item No'>
                                <Input onChange={sparePartsForm.handleChange} value={sparePartsForm.values.itemNo} isValid={sparePartsForm.isValid} onBlur={sparePartsForm.handleBlur} isTouched={sparePartsForm.touched.itemNo} invalidFeedback={sparePartsForm.errors.itemNo} type='text' placeholder="Enter Item No" />
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='vendorName' label='Vendor Name'>
                                <Input onChange={sparePartsForm.handleChange} value={sparePartsForm.values.vendorName} isValid={sparePartsForm.isValid} onBlur={sparePartsForm.handleBlur} isTouched={sparePartsForm.touched.vendorName} invalidFeedback={sparePartsForm.errors.vendorName} type='text' placeholder="Enter Vendor Name" required />
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='brandName' label='Brand Name'>
                                <Input onChange={sparePartsForm.handleChange} value={sparePartsForm.values.brandName} isValid={sparePartsForm.isValid} onBlur={sparePartsForm.handleBlur} isTouched={sparePartsForm.touched.brandName} invalidFeedback={sparePartsForm.errors.brandName} type='text' placeholder="Enter Brand Name" required />
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='mha' label='MHA'>
                                <Input onChange={sparePartsForm.handleChange} value={sparePartsForm.values.mha} isValid={sparePartsForm.isValid} onBlur={sparePartsForm.handleBlur} isTouched={sparePartsForm.touched.mha} invalidFeedback={sparePartsForm.errors.mha} type='text' placeholder="Enter MHA" required />
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='manufacturers' label='Manufacturers'>
                                <Input onChange={sparePartsForm.handleChange} value={sparePartsForm.values.manufacturers} isValid={sparePartsForm.isValid} onBlur={sparePartsForm.handleBlur} isTouched={sparePartsForm.touched.manufacturers} invalidFeedback={sparePartsForm.errors.manufacturers} type='text' placeholder="Enter Manufacturers" required />
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='warrantyDate' label='Warranty Date'>
                                <Input onChange={sparePartsForm.handleChange} value={sparePartsForm.values.warrantyDate} isValid={sparePartsForm.isValid} onBlur={sparePartsForm.handleBlur} isTouched={sparePartsForm.touched.warrantyDate} invalidFeedback={sparePartsForm.errors.warrantyDate} type='date' required />
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='retriedDate' label='Retried Date'>
                                <Input onChange={sparePartsForm.handleChange} value={sparePartsForm.values.retriedDate} isValid={sparePartsForm.isValid} onBlur={sparePartsForm.handleBlur} isTouched={sparePartsForm.touched.retriedDate} invalidFeedback={sparePartsForm.errors.retriedDate} type='date' placeholder="Enter RetriedDate" required />
                            </FormGroup>
                        </div>
                    </div>
                </OffCanvasBody>
                <div className='row m-0'>
                    <div className='col-12 p-3'>
                        <Button color='info' className='w-100' type="submit" isDisable={!sparePartsForm.isValid && !!sparePartsForm.submitCount}>
                            Save
                        </Button>
                    </div>
                </div>
            </OffCanvas>

            <OffCanvas setOpen={setEditVehicleSpareOffCanvas} isOpen={editVehicleSpareOffCanvas} titleId='editVehicleSpareOffCanvas' isBodyScroll placement='end' tag="form" noValidate onSubmit={sparePartsEditForm.handleSubmit}>
                <OffCanvasHeader setOpen={setEditVehicleSpareOffCanvas} onClick={closeAndReset}>
                    <OffCanvasTitle id='editVehicleSpareOffCanvas'>Edit Vehicle Spare Parts</OffCanvasTitle>
                </OffCanvasHeader>
                <OffCanvasBody>
                    <div className="row g-4">
                        <div className='col-12'>
                            <FormGroup id='vehicleDetailsId' label='Vehicle'>
                                <SearchableSelect ariaLabel='Vehicle' placeholder="Select Vehicle" onChange={selectVehicle} onBlur={sparePartsEditForm.handleBlur} value={vehicleDetailsId} isValid={sparePartsEditForm.isValid} isTouched={sparePartsEditForm.touched.vehicleDetailsId} invalidFeedback={sparePartsEditForm.errors.vehicleDetailsId} list={vehicleDetailsData.map((data: any) => ({ value: data.vehicleDetailsId, label: data.vehicleId }))} required disabled />
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='sparePartsTypeId' label='Spare Parts Type'>
                                <SearchableSelect ariaLabel='Spare Parts Type' placeholder="Select Spare Parts Type" onChange={selectSparePartsType} onBlur={sparePartsEditForm.handleBlur} value={sparePartsTypeId} isValid={sparePartsEditForm.isValid} isTouched={sparePartsEditForm.touched.sparePartsTypeId} invalidFeedback={sparePartsEditForm.errors.sparePartsTypeId} list={sparePartsData.map((data: any) => ({ value: data.sparePartsTypeId, label: data.sparePartsName }))} required />
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='date' label='Date'>
                                <Input onChange={sparePartsEditForm.handleChange} value={sparePartsEditForm.values.date} isValid={sparePartsEditForm.isValid} onBlur={sparePartsEditForm.handleBlur} isTouched={sparePartsEditForm.touched.date} invalidFeedback={sparePartsEditForm.errors.date} type='date' placeholder="Enter Date" required />
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='itemNo' label='Item No'>
                                <Input onChange={sparePartsEditForm.handleChange} value={sparePartsEditForm.values.itemNo} isValid={sparePartsEditForm.isValid} onBlur={sparePartsEditForm.handleBlur} isTouched={sparePartsEditForm.touched.itemNo} invalidFeedback={sparePartsEditForm.errors.itemNo} type='text' placeholder="Enter Item No" />
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='vendorName' label='Vendor Name'>
                                <Input onChange={sparePartsEditForm.handleChange} value={sparePartsEditForm.values.vendorName} isValid={sparePartsEditForm.isValid} onBlur={sparePartsEditForm.handleBlur} isTouched={sparePartsEditForm.touched.vendorName} invalidFeedback={sparePartsEditForm.errors.vendorName} type='text' placeholder="Enter Vendor Name" required />
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='brandName' label='Brand Name'>
                                <Input onChange={sparePartsEditForm.handleChange} value={sparePartsEditForm.values.brandName} isValid={sparePartsEditForm.isValid} onBlur={sparePartsEditForm.handleBlur} isTouched={sparePartsEditForm.touched.brandName} invalidFeedback={sparePartsEditForm.errors.brandName} type='text' placeholder="Enter Brand Name" required />
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='mha' label='MHA'>
                                <Input onChange={sparePartsEditForm.handleChange} value={sparePartsEditForm.values.mha} isValid={sparePartsEditForm.isValid} onBlur={sparePartsEditForm.handleBlur} isTouched={sparePartsEditForm.touched.mha} invalidFeedback={sparePartsEditForm.errors.mha} type='text' placeholder="Enter MHA" required />
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='manufacturers' label='Manufacturers'>
                                <Input onChange={sparePartsEditForm.handleChange} value={sparePartsEditForm.values.manufacturers} isValid={sparePartsEditForm.isValid} onBlur={sparePartsEditForm.handleBlur} isTouched={sparePartsEditForm.touched.manufacturers} invalidFeedback={sparePartsEditForm.errors.manufacturers} type='text' placeholder="Enter Manufacturers" required />
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='warrantyDate' label='Warranty Date'>
                                <Input onChange={sparePartsEditForm.handleChange} value={sparePartsEditForm.values.warrantyDate} isValid={sparePartsEditForm.isValid} onBlur={sparePartsEditForm.handleBlur} isTouched={sparePartsEditForm.touched.warrantyDate} invalidFeedback={sparePartsEditForm.errors.warrantyDate} type='date' required />
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='retriedDate' label='Retried Date'>
                                <Input onChange={sparePartsEditForm.handleChange} value={sparePartsEditForm.values.retriedDate} isValid={sparePartsEditForm.isValid} onBlur={sparePartsEditForm.handleBlur} isTouched={sparePartsEditForm.touched.retriedDate} invalidFeedback={sparePartsEditForm.errors.retriedDate} type='date' placeholder="Enter RetriedDate" required />
                            </FormGroup>
                        </div>

                        <div className="d-flex justify-content-center position-absolute top-65 start-50 translate-middle">
                            <DeleteComponents isDeleteOpen={isDeleteOpen} setIsDeleteOpen={setIsDeleteOpen}
                                deleteFunction={deleteSparePartSubmit}
                            />
                        </div>
                    </div>
                </OffCanvasBody>
                <div className='row m-0'>
                    <div className='col-6 p-3'>
                        <Button color='danger' className='w-100' icon="Delete" onClick={() => setIsDeleteOpen(!isDeleteOpen)}>
                            Delete
                        </Button>
                    </div>
                    <div className='col-6 p-3'>
                        <Button color='info' className='w-100' type="submit" isDisable={!sparePartsEditForm.isValid && !!sparePartsEditForm.submitCount}>
                            Update
                        </Button>
                    </div>
                </div>
            </OffCanvas>

            <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />

        </PageWrapper>
    )
}
export default VehicleSpareAndTaxDetails