import { AxiosResponse } from "axios";
import service from "../api/api.services";
import { getAPIURL, getLicenseKey } from "./application.settings";

const api = service()


export const getStudentFeeConcession = (studentDetailsId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentFeeConcessionListByStudentDetailsId/${getLicenseKey}/${studentDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentFeeConcessionListByStudentDetailsId - " + error.message)
            console.log('error caught in service : getStudentFeeConcessionListByStudentDetailsId')
        },
    );

export const getStudentConcessionList = (staffDetailsId: any, concessionTypeId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentConcessionList/${getLicenseKey}/${staffDetailsId}/${concessionTypeId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentConcessionList - " + error.message)
            console.log('error caught in service : getStudentConcessionList')
        },
    );

export const approveStudentFeeConcession = (feeConcessionPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `approveStudentFeeConcession`, feeConcessionPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : approveStudentFeeConcession')
        }
    );

export const rejectStudentFeeConcession = (feeConcessionPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `rejectStudentFeeConcession`, feeConcessionPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : rejectStudentFeeConcession')
        }
    );

export const addStudentManualFeeConcession = (approvedConcessionPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addStudentManualFeeConcession`, approvedConcessionPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addStudentManualFeeConcession')
        }
    );