import { useContext, useEffect, useState } from "react";
import Page from "../../../layout/Page/Page";
import Button from "../../bootstrap/Button";
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from "../../bootstrap/Card";
import FormGroup from "../../bootstrap/forms/FormGroup";
import Input from "../../bootstrap/forms/Input";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import { getAcademicPeriod, getBatchMasterDetails, getSection } from "../../../services/master.service";
import { toasts } from "../../../services/toast.service";
import SearchableSelect from "../../../common/components/SearchableSelect";
import { getStudentDetailsByAcademicPeriodId, getStudentSectionList, getStudentStandardList, sendSMS } from "../../../services/sms.service";
import { getStaffListByUserTypeId } from "../../../services/staff.service";
import AuthContext from "../../../contexts/authContext";
import { getStandardDetails } from "../../../services/common.service";
import { useFormik } from "formik";
import { showLoader } from "../../../services/loader.services";
import { getLicenseKey } from "../../../services/application.settings";
import AlertService from "../../../services/AlertService";


type TTabs = 'Student General Message - Standard' | 'Student General Message - Section' | 'Staff General Message' | 'Holiday General Message';

interface ITabs {
    [key: string]: TTabs;
}

function GeneralMessage() {

    useEffect(() => {
        getBatchMaster();
        getAcademicPeriodList();
        getStandardList();
    }, [])

    const TABS: any = {
        FIRST: 'General Message - Standard',
        SECOND: 'General Message - Section',
        THIRD: 'Staff General Message',
        FOURTH: 'Holiday General Message'
    };

    const { userTypeId,userAccountId } = useContext(AuthContext);
    const [activeTab, setActiveTab] = useState(TABS.FIRST);
    const [batchMasterData, setBatchMasterData] = useState<any>([]);
    const [batchMasterId, setBatchMasterId] = useState<any>('');
    const [academicPeriodId, setAcademicPeriodId] = useState<any>('')
    const [academicPeriodData, setAcademicPeriodData] = useState<any>([])

    const [state, setState] = useState(false)

    const [staffTypeIdValue, setStaffTypeIdValue] = useState<any>('')

    const [studentDetailsByAcademicPeriodData, setStudentDetailsByAcademicPeriodData] = useState<any>([])
    const [studentDetailsByStandardWiseData, setStudentDetailsByStandardWiseData] = useState<any>([])
    const [studentDetailsBySectionWiseData, setStudentDetailsBySectionWiseData] = useState<any>([])
    const [staffListByUserTypeData, setStaffListByUserTypeData] = useState<any>([])
    const [totalCount, setTotalCount] = useState<any>()

    const [standardDetailsId, setStandardDetailsId] = useState<any>('')
    const [standardData, setStandardData] = useState<any>([])
    const [sectionDetailsId, setSectionDetailsId] = useState<any>('')
    const [sectionData, setSectionData] = useState<any>([])

    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);

    const messageForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            variable1: '',
            variable2: '',
        },
        validate: (values) => {
            const errors: {
                variable1?: string;
                variable2?: string;
            } = {};
            if (!values.variable1) {
                errors.variable1 = 'Required';
            }
            if (!values.variable2) {
                errors.variable2 = 'Required';
            }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: () => { messageDataSubmit(); },
    });

    const selectStaffType = (e: any) => {
        setState(false)
        setTotalCount('')
        let staffTypeIdValue = e
        setStaffTypeIdValue(staffTypeIdValue)
    }

    const selectBatch = (e: any) => {
        setState(false)
        setTotalCount('')
        setStandardDetailsId('')
        setSectionDetailsId('')
        let batchMasterId = e
        setBatchMasterId(batchMasterId)
    }

    const selectStandard = (e: any) => {
        setState(false)
        setTotalCount('')
        setSectionDetailsId('')
        let standardDetailsId = e
        setStandardDetailsId(standardDetailsId)
        if (standardDetailsId?.value != undefined) {
            getSectionDetailsList(standardDetailsId?.value)
        }
    }

    const selectSectionDetails = (e: any) => {
        setState(false)
        setTotalCount('')
        let sectionDetailsId = e
        setSectionDetailsId(sectionDetailsId)
    }

    function getBatchMaster() {
        getBatchMasterDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.batchMasterDetails;
                    if (data != undefined) {
                        setBatchMasterData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setBatchMasterData([{ batchMasterId: "", batchName: response.data.message }]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStandardList() {
        getStandardDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.standardDetails;
                    if (data != undefined) {
                        setStandardData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setStandardData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getSectionDetailsList(standardDetailsId: any) {
        getSection(standardDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.sectionDetails;
                    if (data != undefined) {
                        setSectionData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setSectionData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    const selectAcademicPeriod = (e: any) => {
        setState(false)
        setTotalCount('')
        let academicPeriodId = e
        setAcademicPeriodId(academicPeriodId)
    }

    function getAcademicPeriodList() {
        getAcademicPeriod(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.academicPeriod;
                    if (data != undefined) {
                        setAcademicPeriodData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setAcademicPeriodData([{ academicPeriodId: "", period: response.data.message }]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function viewHoliday() {
        getStudentDetailsByAcademicPeriod(academicPeriodId?.value)
    }

    function viewStudentStandardWise() {
        getStudentDetailsByStandardWise(batchMasterId?.value, standardDetailsId?.value)
    }

    function viewStudentSectionWise() {
        getStudentDetailsBySectionWise(batchMasterId?.value, standardDetailsId?.value, sectionDetailsId?.value)
    }

    function viewStaff() {
        getStaffListByUserType(staffTypeIdValue?.value)
    }

    function getStudentDetailsByAcademicPeriod(academicPeriodId: number) {
        getStudentDetailsByAcademicPeriodId(academicPeriodId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentDetailsByAcademicPeriodId;
                    if (data != undefined) {
                        setStudentDetailsByAcademicPeriodData(data);
                        setTotalCount(data.length)
                        setState(true);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setStudentDetailsByAcademicPeriodData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStudentDetailsByStandardWise(batchMasterId: number, standardDetailsId: number) {
        getStudentStandardList(batchMasterId, standardDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentList;
                    if (data != undefined) {
                        setStudentDetailsByStandardWiseData(data);
                        setTotalCount(data.length)
                        setState(true);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setStudentDetailsByStandardWiseData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStudentDetailsBySectionWise(batchMasterId: number, standardDetailsId: any, sectionDetailsId: any) {
        getStudentSectionList(batchMasterId, standardDetailsId, sectionDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentList;
                    if (data != undefined) {
                        setStudentDetailsBySectionWiseData(data);
                        setTotalCount(data.length)
                        setState(true);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setStudentDetailsBySectionWiseData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStaffListByUserType(staffTypeIdValue: number) {
        getStaffListByUserTypeId(userAccountId, staffTypeIdValue,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.staffListByUserTypeId;
                    if (data != undefined) {
                        setStaffListByUserTypeData(data);
                        setTotalCount(data.length)
                        setState(true);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setStaffListByUserTypeData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    const [smsType, setSmsType] = useState<any>('')
    const [students, setStudents] = useState<any>([])

    function setMessageDataDetails() {

        return ({
            licenseKey: String(getLicenseKey),
            userTypeId: '9',
            variable1: messageForm.values.variable1,
            variable2: messageForm.values.variable2,
            smsSendDetails: students,
            smsMessageTypeId: String(smsType),
            staffDetailsId: userAccountId,
        })
    }

    function messageDataSubmit() {
        showLoader(true)
        if (messageForm.isValid) {
            let messageData = setMessageDataDetails();
            sendSMS(messageData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        tabEmpty();
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false)
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (messageForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function tabEmpty() {
        messageForm.resetForm();
        setStudents([]);
        setSmsType('')
        setState(false)
        setBatchMasterId('')
        setSectionDetailsId('')
        setStandardDetailsId('')
        setAcademicPeriodId('')
        setStaffTypeIdValue('')
        setTotalCount('')
    }

    const staffTypeData = [{ staffTypeId: 0, staffType: 'All' }, { staffTypeId: 7, staffType: 'Teaching Staff' }, { staffTypeId: 8, staffType: 'Non-Teaching Staff' }, { staffTypeId: 9, staffType: 'Driver' }]

    return (
        <PageWrapper title="SMS">
            <Page container='fluid'>
                <div className='row h-100'>
                    <div className='col-lg-3'>
                        <Card stretch>
                            <CardHeader>
                                <CardLabel icon='Person' iconColor='info'>
                                    <CardTitle tag='div' className='h5'>
                                        Message Admin
                                    </CardTitle>

                                </CardLabel>
                            </CardHeader>
                            <CardBody>
                                <div className='row g-3'>
                                    <div className='col-12'>
                                        <Button
                                            icon='ContactMail'
                                            color='info'
                                            size={'sm'}
                                            className='w-100 p-3'
                                            isLight={TABS.FIRST !== activeTab}
                                            onClick={() => { setActiveTab(TABS.FIRST); tabEmpty(); setSmsType(1) }}>
                                            {TABS.FIRST}
                                        </Button>
                                    </div>
                                    <div className='col-12'>
                                        <Button
                                            icon='ContactMail'
                                            color='info'
                                            size={'sm'}
                                            className='w-100 p-3'
                                            isLight={TABS.SECOND !== activeTab}
                                            onClick={() => { setActiveTab(TABS.SECOND); tabEmpty(); setSmsType(2) }}>
                                            {TABS.SECOND}
                                        </Button>
                                    </div>
                                    <div className='col-12'>
                                        <Button
                                            icon='Contacts'
                                            color='info'
                                            size={'sm'}
                                            className='w-100 p-3'
                                            isLight={TABS.THIRD !== activeTab}
                                            onClick={() => { setActiveTab(TABS.THIRD); tabEmpty(); setSmsType(3) }}>
                                            {TABS.THIRD}
                                        </Button>
                                    </div>
                                    <div className='col-12'>
                                        <Button
                                            icon='Today'
                                            color='info'
                                            size={'sm'}
                                            className='w-100 p-3'
                                            isLight={TABS.FOURTH !== activeTab}
                                            onClick={() => { setActiveTab(TABS.FOURTH); tabEmpty(); setSmsType(4) }}>
                                            {TABS.FOURTH}
                                        </Button>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </div>

                    <div className='col-lg-9'>
                        {TABS.FIRST === activeTab && (
                            <Card stretch>
                                <CardHeader borderSize={1}>
                                    <CardLabel icon='Message' iconColor='info'>
                                        <CardTitle >
                                            GENERAL MESSAGE DETAILS
                                        </CardTitle>
                                    </CardLabel>
                                </CardHeader>
                                <CardBody >
                                    <form onSubmit={messageForm.handleSubmit}>
                                        <div className="row g-4">
                                            <div className="col-lg-12">
                                                <label>Select Student's Batch and Standard and then Send SMS</label>
                                            </div>
                                            <div className="col-lg-4">
                                                <FormGroup id='batchMasterId' label='Batch' isFloating>
                                                    <SearchableSelect
                                                        isFloating
                                                        ariaLabel='Batch'
                                                        onChange={selectBatch}
                                                        value={batchMasterId}
                                                        list={batchMasterData.map((data: any) => ({
                                                            value: data.batchMasterId,
                                                            label: data.batchName,
                                                        }))}
                                                        placeholder="Select Batch"
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-4">
                                                <FormGroup id='standardDetailsId' label='Standard' isFloating>
                                                    <SearchableSelect isFloating ariaLabel='Standard' placeholder="Standard"
                                                        onChange={selectStandard}
                                                        value={standardDetailsId}
                                                        list={standardData.map((data: any) => (
                                                            { value: data.standardDetailsId, label: data.standardName }
                                                        ))} required
                                                    />
                                                </FormGroup>
                                            </div>
                                            {state ? <>
                                                <div className="col-lg-4 justify-content-center">
                                                    <FormGroup id="noOfStudents" label="Number Of Students" isFloating>
                                                        <Input type="text" value={totalCount} disabled />
                                                    </FormGroup>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div>
                                                        <strong>Message :</strong>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 align-self-center">
                                                    Dear Parents, School Start for
                                                </div>
                                                <div className="col-lg-4 justify-content-center">
                                                    <FormGroup id="variable1" label="" isFloating>
                                                        <Input type="text" placeholder="Enter Details"
                                                            onChange={messageForm.handleChange}
                                                            value={messageForm.values.variable1}
                                                            onBlur={messageForm.handleBlur}
                                                            isValid={messageForm.isValid}
                                                            isTouched={messageForm.touched.variable1}
                                                            invalidFeedback={messageForm.errors.variable1} />
                                                    </FormGroup>
                                                </div>
                                                <div className="col-lg-4 align-self-center">
                                                    Buses, will be available.
                                                </div>
                                                <div className="col-lg-4 align-self-center">
                                                    For Enq : Phone:
                                                </div>
                                                <div className="col-lg-4 justify-content-center">
                                                    <FormGroup id="variable2" label="" isFloating>
                                                        <Input type="text" placeholder="Enter Details"
                                                            onChange={messageForm.handleChange}
                                                            value={messageForm.values.variable2}
                                                            onBlur={messageForm.handleBlur}
                                                            isValid={messageForm.isValid}
                                                            isTouched={messageForm.touched.variable2}
                                                            invalidFeedback={messageForm.errors.variable2} />
                                                    </FormGroup>
                                                </div>
                                                <div className="col-lg-4 align-self-center">
                                                    SUNARTS.
                                                </div>
                                                <div className="col-lg-12 d-flex justify-content-end">
                                                    <FormGroup>
                                                        <Button type="submit" icon='send' color='info' isDisable={!!messageForm.submitCount && !messageForm.isValid} onClick={() => {
                                                            setStudents(studentDetailsByStandardWiseData.map((student: any) => ({
                                                                studentDetailsId: String(student.studentDetailsId),
                                                                smsToBeSentNumber: String(student.smsToBeSentNumber)
                                                            })))
                                                        }}>Send</Button>
                                                    </FormGroup>
                                                </div>
                                            </> :
                                                <div className="col-lg-4 justify-content-center">
                                                    <FormGroup>
                                                        <Button icon='East' color='info' isDisable={batchMasterId?.value == undefined || standardDetailsId?.value == undefined} onClick={() => { viewStudentStandardWise() }}>Next</Button>
                                                    </FormGroup>
                                                </div>}
                                        </div>
                                    </form>
                                </CardBody>

                            </Card>
                        )}
                        {TABS.SECOND === activeTab && (
                            <Card stretch>
                                <CardHeader borderSize={1}>
                                    <CardLabel icon='Message' iconColor='info'>
                                        <CardTitle >
                                            GENERAL MESSAGE DETAILS
                                        </CardTitle>
                                    </CardLabel>
                                </CardHeader>
                                <CardBody >
                                    <form onSubmit={messageForm.handleSubmit}>
                                        <div className="row g-4">
                                            <div className="col-lg-12">
                                                <label>Select Student's Batch, Standard and Section and then Send SMS</label>
                                            </div>
                                            <div className="col-lg-3">
                                                <FormGroup id='batchMasterId' label='Batch' isFloating>
                                                    <SearchableSelect
                                                        isFloating
                                                        ariaLabel='Batch'
                                                        onChange={selectBatch}
                                                        value={batchMasterId}
                                                        list={batchMasterData.map((data: any) => ({
                                                            value: data.batchMasterId,
                                                            label: data.batchName,
                                                        }))}
                                                        placeholder="Select Batch"
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-3">
                                                <FormGroup id='standardDetailsId' label='Standard' isFloating>
                                                    <SearchableSelect isFloating ariaLabel='Standard' placeholder="Standard"
                                                        onChange={selectStandard}
                                                        value={standardDetailsId}
                                                        list={standardData.map((data: any) => (
                                                            { value: data.standardDetailsId, label: data.standardName }
                                                        ))} required
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-3">
                                                <FormGroup id='sectionDetailsId' label='Section' isFloating>
                                                    <SearchableSelect isFloating ariaLabel='Section' placeholder="Section"
                                                        onChange={selectSectionDetails}
                                                        value={sectionDetailsId}
                                                        list={sectionData.map((data: any) => (
                                                            { value: data.sectionDetailsId, label: data.sectionName }
                                                        ))} required
                                                    />
                                                </FormGroup>
                                            </div>
                                            {state ? <>
                                                <div className="col-lg-3 justify-content-center">
                                                    <FormGroup id="noOfStudents" label="Number Of Students" isFloating>
                                                        <Input type="text" value={totalCount} disabled />
                                                    </FormGroup>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div>
                                                        <strong>Message :</strong>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 align-self-center">
                                                    Dear Parents, School Start for
                                                </div>
                                                <div className="col-lg-4 justify-content-center">
                                                    <FormGroup id="variable1" label="" isFloating>
                                                        <Input type="text" placeholder="Enter Details"
                                                            onChange={messageForm.handleChange}
                                                            value={messageForm.values.variable1}
                                                            onBlur={messageForm.handleBlur}
                                                            isValid={messageForm.isValid}
                                                            isTouched={messageForm.touched.variable1}
                                                            invalidFeedback={messageForm.errors.variable1} />
                                                    </FormGroup>
                                                </div>
                                                <div className="col-lg-4 align-self-center">
                                                    Buses, will be available.
                                                </div>
                                                <div className="col-lg-4 align-self-center">
                                                    For Enq : Phone:
                                                </div>
                                                <div className="col-lg-4 justify-content-center">
                                                    <FormGroup id="variable2" label="" isFloating>
                                                        <Input type="text" placeholder="Enter Details"
                                                            onChange={messageForm.handleChange}
                                                            value={messageForm.values.variable2}
                                                            onBlur={messageForm.handleBlur}
                                                            isValid={messageForm.isValid}
                                                            isTouched={messageForm.touched.variable2}
                                                            invalidFeedback={messageForm.errors.variable2} />
                                                    </FormGroup>
                                                </div>
                                                <div className="col-lg-4 align-self-center">
                                                    SUNARTS.
                                                </div>
                                                <div className="col-lg-12 d-flex justify-content-end">
                                                    <FormGroup>
                                                        <Button type="submit" icon='send' color='info' isDisable={!!messageForm.submitCount && !messageForm.isValid} onClick={() => {
                                                            setStudents(studentDetailsBySectionWiseData.map((student: any) => ({
                                                                studentDetailsId: student.studentDetailsId,
                                                                smsToBeSentNumber: student.smsToBeSentNumber
                                                            })));
                                                        }}>Send</Button>
                                                    </FormGroup>
                                                </div>
                                            </> :
                                                <div className="col-lg-3 justify-content-center">
                                                    <FormGroup>
                                                        <Button icon='East' color='info' isDisable={batchMasterId?.value == undefined || standardDetailsId?.value == undefined || sectionDetailsId?.value == undefined} onClick={() => { viewStudentSectionWise() }}>Next</Button>
                                                    </FormGroup>
                                                </div>}
                                        </div>
                                    </form>
                                </CardBody>

                            </Card>
                        )}
                        {TABS.THIRD === activeTab && (
                            <Card stretch>
                                <CardHeader borderSize={1}>
                                    <CardLabel icon='Message' iconColor='info'>
                                        <CardTitle>STAFF GENERAL MESSAGE DETAILS</CardTitle>
                                    </CardLabel>
                                </CardHeader>
                                <CardBody >
                                    <form onSubmit={messageForm.handleSubmit}>
                                        <div className='row g-4'>
                                            <div className="col-lg-12">
                                                <label>Select Staff Designation and Send SMS</label>
                                            </div>
                                            <div className='col-lg-6'>
                                                <FormGroup id="staffTypeIdValue" label="Select Staff Type" isFloating>
                                                    <SearchableSelect placeholder="Select Staff Type" isFloating
                                                        ariaLabel='Staff Type'
                                                        onChange={selectStaffType}
                                                        value={staffTypeIdValue}
                                                        list={staffTypeData.map((data: any) => (
                                                            { value: data.staffTypeId, label: data.staffType }
                                                        ))}
                                                    />
                                                </FormGroup>
                                            </div>
                                            {state ? <>
                                                <div className="col-lg-4 justify-content-center">
                                                    <FormGroup id="noOfStudents" label="Number Of Staff" isFloating>
                                                        <Input type="text" value={totalCount} disabled />
                                                    </FormGroup>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div>
                                                        <strong>Message :</strong>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2 align-self-center">
                                                    Dear Staffs , Due to
                                                </div>
                                                <div className="col-lg-3 justify-content-center">
                                                    <FormGroup id="variable1" label="" isFloating>
                                                        <Input type="text" placeholder="Enter Details"
                                                            onChange={messageForm.handleChange}
                                                            value={messageForm.values.variable1}
                                                            onBlur={messageForm.handleBlur}
                                                            isValid={messageForm.isValid}
                                                            isTouched={messageForm.touched.variable1}
                                                            invalidFeedback={messageForm.errors.variable1} />
                                                    </FormGroup>
                                                </div>
                                                <div className="col-lg-2 align-self-center">
                                                    School
                                                </div>
                                                <div className="col-lg-3 justify-content-center">
                                                    <FormGroup id="variable2" label="" isFloating>
                                                        <Input type="text" placeholder="Enter Details"
                                                            onChange={messageForm.handleChange}
                                                            value={messageForm.values.variable2}
                                                            onBlur={messageForm.handleBlur}
                                                            isValid={messageForm.isValid}
                                                            isTouched={messageForm.touched.variable2}
                                                            invalidFeedback={messageForm.errors.variable2} />
                                                    </FormGroup>
                                                </div>
                                                <div className="col-lg-2 align-self-center">
                                                    From Sun School .
                                                </div>
                                                <div className="col-lg-12 d-flex justify-content-end">
                                                    <FormGroup>
                                                        <Button type="submit" icon='send' color='info' isDisable={!!messageForm.submitCount && !messageForm.isValid} onClick={() => {
                                                            setStudents(staffListByUserTypeData.map((student: any) => ({
                                                                studentDetailsId: String(student.staffDetailsId),
                                                                smsToBeSentNumber: String(student.contactNumber)
                                                            })))
                                                        }}>Send</Button>
                                                    </FormGroup>
                                                </div>
                                            </> :
                                                <div className="col-lg-4 justify-content-center">
                                                    <FormGroup>
                                                        <Button icon='East' color='info' isDisable={staffTypeIdValue?.value == undefined} onClick={() => { viewStaff() }}>Next</Button>
                                                    </FormGroup>
                                                </div>}
                                        </div>
                                    </form>
                                </CardBody>

                            </Card>
                        )}
                        {TABS.FOURTH === activeTab && (
                            <Card stretch>
                                <CardHeader borderSize={1}>
                                    <CardLabel icon="Message" iconColor='info'>
                                        <CardTitle tag='div' className='h5'>
                                            HOLIDAY MESSAGE DETAILS
                                        </CardTitle>
                                    </CardLabel>
                                </CardHeader>
                                <CardBody >
                                    <form onSubmit={messageForm.handleSubmit}>
                                        <div className='row g-4'>
                                            <div className="col-lg-12">
                                                <label>Select Student's Academic and then Send SMS:</label>
                                            </div>
                                            <div className='col-lg-6'>
                                                <FormGroup id='academicPeriodId' label='Academic Period' isFloating>
                                                    <SearchableSelect isFloating ariaLabel='Academic Period' placeholder="Select Academic Period"
                                                        value={academicPeriodId}
                                                        onChange={(e: any) => selectAcademicPeriod(e)}
                                                        list={academicPeriodData.map((data: any) => (
                                                            { value: data.academicPeriodId, label: data.period }
                                                        ))} required />
                                                </FormGroup>
                                            </div>
                                            {state ? <>
                                                <div className="col-lg-4 justify-content-center">
                                                    <FormGroup id="noOfStudents" label="Number Of Students" isFloating>
                                                        <Input type="text" value={totalCount} disabled />
                                                    </FormGroup>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div>
                                                        <strong>Message :</strong>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 align-self-center">
                                                    Dear Parents , School is Declared Holiday on
                                                </div>
                                                <div className="col-lg-3 justify-content-center">
                                                    <FormGroup id="variable1" label="" isFloating>
                                                        <Input type="text" placeholder="Enter Details"
                                                            onChange={messageForm.handleChange}
                                                            value={messageForm.values.variable1}
                                                            onBlur={messageForm.handleBlur}
                                                            isValid={messageForm.isValid}
                                                            isTouched={messageForm.touched.variable1}
                                                            invalidFeedback={messageForm.errors.variable1} />
                                                    </FormGroup>
                                                </div>
                                                <div className="col-lg-2 align-self-center">
                                                    due to
                                                </div>
                                                <div className="col-lg-3 justify-content-center">
                                                    <FormGroup id="variable2" label="" isFloating>
                                                        <Input type="text" placeholder="Enter Details"
                                                            onChange={messageForm.handleChange}
                                                            value={messageForm.values.variable2}
                                                            onBlur={messageForm.handleBlur}
                                                            isValid={messageForm.isValid}
                                                            isTouched={messageForm.touched.variable2}
                                                            invalidFeedback={messageForm.errors.variable2} />
                                                    </FormGroup>
                                                </div>
                                                <div className="col-lg-12 align-self-center">
                                                    For Enq : Phone : 04175-298027 Mobile : 8825567253 . SUNARTS
                                                </div>
                                                <div className="col-lg-12 d-flex justify-content-end">
                                                    <FormGroup>
                                                        <Button type="submit" icon='send' color='info' isDisable={!!messageForm.submitCount && !messageForm.isValid} onClick={() => {
                                                            setStudents(studentDetailsByAcademicPeriodData.map((student: any) => ({
                                                                studentDetailsId: String(student.studentDetailsId),
                                                                smsToBeSentNumber: String(student.smsToBeSentNumber)
                                                            })))
                                                        }}>Send</Button>
                                                    </FormGroup>
                                                </div>
                                            </> :
                                                <div className="col-lg-4 justify-content-center">
                                                    <FormGroup>
                                                        <Button icon='East' color='info' isDisable={academicPeriodId?.value == undefined} onClick={() => {
                                                            viewHoliday()
                                                        }}>Next</Button>
                                                    </FormGroup>
                                                </div>}
                                        </div>
                                    </form>
                                </CardBody>

                            </Card>
                        )}
                    </div>
                </div>
            </Page>

            <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />

        </PageWrapper>
    )
}
export default GeneralMessage