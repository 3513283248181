import { AxiosResponse } from "axios";
import service from "../api/api.services";
import { getAPIURL, getLicenseKey } from "./application.settings";

const api = service()

export const getScholarshipType = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getScholarshipType/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getScholarshipType - " + error.message)
            console.log('error caught in service : getScholarshipType')
        },
    );

export const addStudentScholarshipDetails = (studentScholarshipPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addStudentScholarshipDetails`, studentScholarshipPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addStudentScholarshipDetails')
        }
    );

export const getScholarshipDetailsByStudentDetailsId = (studentDetailsId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getScholarshipDetailsByStudentDetailsId/${getLicenseKey}/${studentDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getScholarshipDetailsByStudentDetailsId - " + error.message)
            console.log('error caught in service : getScholarshipDetailsByStudentDetailsId')
        },
    );