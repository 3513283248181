import { useContext, useEffect, useRef, useState } from "react";
import Page from "../../../layout/Page/Page";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../../bootstrap/Card";
import FormGroup from "../../bootstrap/forms/FormGroup";
import Input from "../../bootstrap/forms/Input";
import Select from "../../bootstrap/forms/Select";
import Collapse from "../../bootstrap/Collapse";
import Icon from "../../icon/Icon";
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from "../../bootstrap/Dropdown";
import Button, { ButtonGroup } from "../../bootstrap/Button";
import Checks, { ChecksGroup } from "../../bootstrap/forms/Checks";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "../../bootstrap/Modal";
import classNames from "classnames";
import { useFormik } from "formik";
import { useReactToPrint } from "react-to-print";
import useDarkMode from "../../../hooks/useDarkMode";
import useSortableData from "../../../hooks/useSortableData";
import { toasts } from "../../../services/toast.service";
import PaginationButtons, { dataPagination } from "../../PaginationButtons";
import { convertDateRangeToEpoch, getColumnsForDataTable, getCurrentDateAndTime, getLedger, onlyAllowNumber, updateColumnsForDataTable, updateFilter } from "../../../services/common.service";
import { getVehicleDetails } from "../../../services/transport.service";
import { convertJsonToPdf, downloadExcel, downloadFile } from "../../../services/ExportService";
import { getLicenseKey } from "../../../services/application.settings";
import AuthContext from "../../../contexts/authContext";
import { deleteFuelConsumption, getFuelConsumptionReport, updateFuelConsumption } from "../../../services/fuel.service";
import AlertService from "../../../services/AlertService";
import OpenCardComponent from "../../../common/components/OpenCardComponent";
import { getStaffListByUserTypeId } from "../../../services/staff.service";
import NoDataMsg from "../../../common/components/NoDataMsg";
import DeleteComponents from "../../../common/components/DeleteComponents";
import { showLoader } from "../../../services/loader.services";
import SearchableSelect from "../../../common/components/SearchableSelect";

function FuelConsumptionReport() {

    useEffect(() => {
        getVehicleDetailsList()
        getLedgerList(0)
        getLedgerListByLedgerDetailsId(38);

    }, [])

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const [updateFuelConsumptionReport, setUpdateFuelConsumptionReport] = useState(false);
    const { themeStatus, darkModeStatus } = useDarkMode();
    const [fuelConsumptionReportData, setFuelConsumptionReportData] = useState([])
    const [dataSuccess, setDataSuccess] = useState(false)
    const [isOpenListCard, setIsOpenListCard] = useState(true)
    const [columnVisibilityData, setColumnVisibilityData] = useState([]);
    const [allColumnsData, setAllColumnsData] = useState([]);
    const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
    const [columnDataById, setColumnDataById] = useState([])
    const componentRef = useRef(null);
    const [isChecked, setIsChecked] = useState(false)

    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(fuelConsumptionReportData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);

    const [vehicleDetailsId, setVehicleDetailsId] = useState<any>('')
    const [staffDetailsId, setStaffDetailsId] = useState<any>('')
    const [staffDetailsData, setStaffDetailsData] = useState<any>([])
    const [ledgerDetailsId, setLedgerDetailsId] = useState<any>('')
    const [vehicleDetailsData, setVehicleDetailsData] = useState<any>([])
    const [ledgerDetailsData, setLedgerDetailsData] = useState<any>([])
    const [ledgerDetailsDataForCanvas, setLedgerDetailsDataForCanvas] = useState<any>([])
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);
    const [ledgerIdForCollapse, setLedgerIdForCollapse] = useState<any>({ value: 0, label: 'Select All' })
    const [vehicleDetailsIdForCollapse, setVehicleDetailsIdForCollapse] = useState<any>({ value: 0, label: 'Select All' })

    const [isLoader, setIsLoader] = useState(false)
    const [noDataMsg, setNoDataMsg] = useState('')

    const [currentReading, setCurrentReading] = useState<any>('')
    const [mileage, setMileage] = useState<any>('')
    const [totalKmRun, setTotalKmRun] = useState<any>('')
    const [quantityFilled, setQuantityFilled] = useState<any>('')
    const [ratePerLiter, setRatePerLiter] = useState<any>(0)
    const [amount, setAmount] = useState<any>('')
    const [averageMileage, setAverageMileage] = useState<any>('')

    const [isDeleteOpen, setIsDeleteOpen] = useState(false);

    const fuelForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            periodFrom: getCurrentDateAndTime('date'),
            periodTo: getCurrentDateAndTime('date'),
        },
        validate: (values) => {
        },
        //validateOnChange: false,
        onSubmit: () => { },
    });

    const updateFuelForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            fuelConsumptionId: '',
            vehicleDetailsId: '',
            fuelType: "",
            staffDetailsId: '',
            previousReading: '',
            currentReading: '',
            totalKmRun: '',
            fillingDate: getCurrentDateAndTime('date'),
            quantityFilled: '',
            mileage: '',
            ratePerLiter: '',
            amount: '',
            ledgerDetailsId: '',
            remarks: '',
            previousFillingDate: '',
            previousQuantityFilled: '',
            previousMileage: 0,
            averageMileage: '',
        },
        validate: (values) => { },

        //validateOnChange: false,
        onSubmit: (values) => { updateFuelConsumptionReportSubmit() },
    })

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },

        //validateOnChange: false,
        onSubmit: () => { },
    });

    // Filter
    const filteredData = updateFilter(onCurrentPageData, columnVisibilityData, columnVisibilityForm);

    function onActivate(i: any) {
        if (i != undefined) {
            let selectedFuel = i
            setUpdateFuelConsumptionReport(true)
            setVehicleDetailsId({ value: i.vehicleDetailsId, label: i.registrationNo })
            setLedgerDetailsId({ value: i.ledgerDetailsId, label: i.ledgerName })
            setStaffDetailsId({ value: i.staffDetailsId, label: i.driverName })
            setRatePerLiter(selectedFuel.ratePerLiter)
            setCurrentReading(selectedFuel.currentReading)
            setTotalKmRun(selectedFuel.totalKmRun)
            setQuantityFilled(selectedFuel.quantityFilled)
            setAmount(selectedFuel.amount)
            setAverageMileage(selectedFuel.averageMileage)
            setMileage(selectedFuel.mileage)
            updateFuelForm.setValues({
                fuelConsumptionId: selectedFuel.fuelConsumptionId,
                vehicleDetailsId: vehicleDetailsId?.value,
                fuelType: selectedFuel.fuelType,
                staffDetailsId: staffDetailsId?.value,
                previousReading: selectedFuel.previousReading,
                currentReading: currentReading,
                totalKmRun: totalKmRun,
                fillingDate: selectedFuel.fillingDate,
                quantityFilled: quantityFilled,
                mileage: mileage,
                ratePerLiter: ratePerLiter,
                averageMileage: averageMileage,
                amount: amount,
                ledgerDetailsId: ledgerDetailsId?.value,
                remarks: selectedFuel.remarks,
                previousFillingDate: selectedFuel.previousFillingDate,
                previousQuantityFilled: selectedFuel.previousQuantityFilled,
                previousMileage: selectedFuel.previousMileage,
            })
            getVehicleDetailsList()
            getStaffListByUserType(9);
        }
    }

    const selectLedger = (e: any) => {
        let ledgerDetailsId = e
        setLedgerDetailsId(ledgerDetailsId)
    }

    const selectStaffDetails = (e: any) => {
        let staffDetailsId = e
        setStaffDetailsId(staffDetailsId)
    }

    function updateAmount(e: any) {
        setRatePerLiter(Number(e))
        const newAmount = Number(e) * Number(quantityFilled)
        setAmount(newAmount)
    }

    function updateMileage(e: any) {
        setQuantityFilled(e)
        const mileage = Number(totalKmRun) / Number(e)
        setMileage(mileage)
        const newAmount = Number(ratePerLiter) * Number(e)
        setAmount(newAmount)
        let averageMileage = mileage / updateFuelForm.values.previousMileage
        if (averageMileage != Infinity) {
            setAverageMileage(averageMileage.toFixed(2))
        }
        else {
            setAverageMileage(0)
        }
    }

    function updateTotalKiloMeterRun(e: any) {
        setTotalKmRun('')
        setQuantityFilled('')
        setRatePerLiter('')
        setAmount('')
        setMileage('')
        setCurrentReading(e)
        const totalKmRun = Number(e) - Number(updateFuelForm.values.previousReading)
        setTotalKmRun(totalKmRun)
    }

    function view() {
        setDataSuccess(false)
        setIsLoader(true);
        let fromDate = fuelForm.values.periodFrom
        let toDate = fuelForm.values.periodTo
        let datesArray = convertDateRangeToEpoch(fromDate, toDate);
        getFuelConsumptionReportList(datesArray[0], datesArray[1], vehicleDetailsIdForCollapse?.value, ledgerIdForCollapse?.value);
        columnVisibilityForm.resetForm;
    }

    function updateFuelConsumptionSubmit() {

        return ({
            licenseKey: getLicenseKey,
            userAccountId: userAccountId,
            fuelConsumptionId: updateFuelForm.values.fuelConsumptionId,
            vehicleDetailsId: vehicleDetailsId?.value != '' ? vehicleDetailsId?.value : null,
            fuelType: updateFuelForm.values.fuelType != "" ? updateFuelForm.values.fuelType : null,
            staffDetailsId: staffDetailsId?.value != "" ? staffDetailsId?.value : null,
            previousReading: updateFuelForm.values.previousReading != "" ? updateFuelForm.values.previousReading : null,
            currentReading: currentReading != "" ? currentReading : null,
            totalKmRun: totalKmRun != "" ? totalKmRun : null,
            fillingDate: updateFuelForm.values.fillingDate != "" ? updateFuelForm.values.fillingDate : null,
            quantityFilled: quantityFilled != "" ? quantityFilled : null,
            mileage: mileage != 0 ? mileage : null,
            ratePerLiter: ratePerLiter != "" ? ratePerLiter : null,
            amount: amount != 0 ? amount : null,
            averageMileage: averageMileage != '' ? averageMileage : null,
            ledgerDetailsId: ledgerDetailsId?.value != '' ? ledgerDetailsId?.value : null,
            remarks: updateFuelForm.values.remarks != "" ? updateFuelForm.values.remarks : null,
        })
    }

    function getVehicleDetailsList() {
        getVehicleDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.vehicleDetails;
                    if (data != undefined) {
                        const vehicleDetailsData = [{ vehicleDetailsId: 0, registrationNo: 'Select All' }];
                        data.forEach((obj: { vehicleDetailsId: number; registrationNo: string }) => {
                            let registrationNo = vehicleDetailsData.push(obj);
                        });
                        setVehicleDetailsData(vehicleDetailsData);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setVehicleDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStaffListByUserType(userTypeId: any) {
        getStaffListByUserTypeId(userAccountId, userTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.staffListByUserTypeId;
                    if (data != undefined) {
                        setStaffDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setStaffDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getLedgerList(ledgerDetailsId: any) {
        getLedger(ledgerDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.ledger;
                    if (data != undefined) {
                        const ledgerDetailsData = [{ ledgerDetailsId: 0, ledgerName: 'Select All' }];
                        data.forEach((obj: { ledgerDetailsId: number; ledgerName: string }) => {
                            let ledgerName = ledgerDetailsData.push(obj);
                        });
                        setLedgerDetailsDataForCanvas(ledgerDetailsData);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setLedgerDetailsDataForCanvas([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getLedgerListByLedgerDetailsId(ledgerDetailsId: any) {
        getLedger(ledgerDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.ledger;
                    if (data != undefined) {
                        setLedgerDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setLedgerDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function updateFuelConsumptionReportSubmit() {
        if (updateFuelForm.isValid) {
            let fuelConsumptionReportPostData = updateFuelConsumptionSubmit()
            updateFuelConsumption(fuelConsumptionReportPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        closeAndReset();
                        view()
                    }
                    else if (data.success == false) {
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (updateFuelForm.isValid == false) {
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function deleteFuelConsumptionSubmit() {

        return ({
            licenseKey: getLicenseKey,
            userAccountId: userAccountId,
            fuelConsumptionId: updateFuelForm.values.fuelConsumptionId,
        })
    }

    function deleteFuelConsumptionReportSubmit() {
        showLoader(true);
        let deleteFuelConsumptionPostData = deleteFuelConsumptionSubmit()
        deleteFuelConsumption(deleteFuelConsumptionPostData,
            (response) => {
                const data = response.data;
                if (data.success == true) {
                    showLoader(false);
                    setAlertStatus({ message: data.message, type: "success" });
                    setIsOpen(true);
                    closeAndReset();
                    view();
                }
                else if (data.success == false) {
                    showLoader(false)
                    setAlertStatus({ message: data.message, type: "error" });
                    setIsOpen(true);
                }
                else {
                    showLoader(false)
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setAlertStatus({ message: errorDescription, type: "error" });
                    setIsOpen(true);
                }
            }
            , (error) => {
                showLoader(false)
                setAlertStatus({ message: error, type: "error" });
                setIsOpen(true);
            }
        )
    }

    function getFuelConsumptionReportList(fromDate: any, toDate: any, vehicleDetailsId: any, ledgerDetailsId: any) {
        getFuelConsumptionReport(fromDate, toDate, vehicleDetailsId, ledgerDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.fuelConsumptionReport;
                    if (data != undefined) {
                        setIsLoader(false);
                        setFuelConsumptionReportData(data);
                        setIsOpenListCard(false);
                        setDataSuccess(true);
                        getColumnsForTable('getFuelConsumptionReport', 'get');
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        setIsLoader(false);
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setIsLoader(false);
                    setIsOpenListCard(false);
                    getColumnsForTable('getFuelConsumptionReport', 'get');
                    setFuelConsumptionReportData([]);
                    setNoDataMsg(response.data.message)
                    setDataSuccess(true)
                } else {
                    setIsLoader(false);
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                setIsLoader(false);
                toasts(error, "Error")
            }
        )
    }

    function getColumnsForTable(apiName: any, type: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setColumnVisibilityData(columnVisibilityData)

                        const allData = allColumnsData.map((item: any) => item.isDisplay);
                        const allColumns = allData.filter((item: any) => item == false);

                        if (type == 'get') {                           
                            setIsChecked(allColumns == '' ? true : false);
                        }
                        else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

                            for (let i = 0; i < nonDefaultValue.length; i++) {

                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: allColumns == '' ? 0 : 1
                                }
                                all.push(obj);
                            }
                            updateColumnsSubmit('', '', all)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )

    }

    function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {

        if (columnVisibilityForm.isValid) {

            setColumnDataById(isDisplay)
            const columnVisibilityPostData = {
                licenseKey: getLicenseKey,
                userAccountId: userAccountId,
                columns: type ? type : [
                    {
                        columnVisibilityId: columnVisibilityId,
                        isDisplay: isDisplay ? 0 : 1
                    }
                ]
            }

            updateColumnsForDataTable(columnVisibilityPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        getColumnsForTable('getFuelConsumptionReport', 'get');
                    }
                    else if (data.success == false) {
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        toasts(errorDescription, "Error")
                    }
                }
                , (error) => {
                    toasts(error, "Error")
                }
            )
        } else if (columnVisibilityForm.isValid == false) {
            toasts("Please fill all the details!", "Error")
        }
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    function closeAndReset() {
        setUpdateFuelConsumptionReport(false)
        setIsDeleteOpen(false);
        setStaffDetailsId('')
        setLedgerDetailsId('')
        setCurrentReading('')
        setMileage('')
        setTotalKmRun('')
        setQuantityFilled('')
        setRatePerLiter('')
        setAmount('')
        setAverageMileage('')
    }

    return (
        <>
            <PageWrapper title="Fuel Consumption Report">
                <Page container='fluid'>
                    <Collapse isOpen={isOpenListCard}>
                        <Card stretch data-tour='list'>
                            <CardHeader borderSize={1}>
                                <CardLabel icon='List' iconColor='info'>
                                    <CardTitle tag='div' className='h5'>Fuel Consumption Report</CardTitle>
                                </CardLabel>
                            </CardHeader>
                            <CardBody>
                                <div className="row">
                                    <div className="col-lg-2">
                                        <FormGroup id="periodFrom" label="From Date" isFloating>
                                            <Input type="date" placeholder="Enter date"
                                                onChange={fuelForm.handleChange}
                                                value={fuelForm.values.periodFrom} />
                                        </FormGroup>
                                    </div>
                                    <div className="col-lg-2">
                                        <FormGroup id="periodTo" label="To Date" isFloating>
                                            <Input type="date" placeholder="Enter date"
                                                onChange={fuelForm.handleChange}
                                                value={fuelForm.values.periodTo} />
                                        </FormGroup>
                                    </div>
                                    <div className="col-lg-3">
                                        <FormGroup id="vehicleDetailsIdForCollapse" label="Vehicle" isFloating>
                                            <SearchableSelect isFloating ariaLabel="" placeholder="Select Vehicle"
                                                onChange={(e: any) => setVehicleDetailsIdForCollapse(e)}
                                                value={vehicleDetailsIdForCollapse}
                                                list={vehicleDetailsData.map((data: any) => (
                                                    { value: data.vehicleDetailsId, label: data.registrationNo }
                                                ))} />
                                        </FormGroup>
                                    </div>
                                    <div className="col-lg-3">
                                        <FormGroup id="ledgerIdForCollapse" label="Ledger" isFloating>
                                            <SearchableSelect isFloating ariaLabel="" placeholder="Select Ledger"
                                                onChange={(e: any) => setLedgerIdForCollapse(e)}
                                                value={ledgerIdForCollapse}
                                                list={ledgerDetailsDataForCanvas.map((data: any) => (
                                                    { value: data.ledgerDetailsId, label: data.ledgerName }
                                                ))} />
                                        </FormGroup>
                                    </div>
                                    <div className="col-lg-2 d-flex align-self-center">
                                        <FormGroup>
                                            <Button icon="ArrowDownward" color="primary" isDisable={ledgerIdForCollapse?.value != undefined && vehicleDetailsIdForCollapse?.value != undefined ? false : true} onClick={view}>View</Button>
                                        </FormGroup>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Collapse>
                    <OpenCardComponent isOpenListCard={isOpenListCard} setIsOpenListCard={setIsOpenListCard} isLoader={isLoader} />
                    {dataSuccess ?
                        <Card stretch data-tour='list' ref={componentRef} id='pdf'>
                            <CardHeader borderSize={1}>
                                <CardLabel icon='List' iconColor='info' className="col-lg-6">
                                    <CardTitle tag='div' className='h5'>Report</CardTitle>
                                </CardLabel>&nbsp;&nbsp;

                                <ButtonGroup className="col-lg-3 justify-content-end d-print-none" color='primary'>
                                    <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                                    <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
                                </ButtonGroup>

                                <CardActions className="d-print-none">
                                    <Dropdown isOpen={columnVisibilityMenu} setIsOpen={setColumnVisibilityMenu} isButtonGroup>
                                        <DropdownToggle>
                                            <Button icon='FilterAlt' color='primary' isLight>Filter</Button>
                                        </DropdownToggle>
                                        <DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
                                            <DropdownItem>
                                                <div className='container py-2'>
                                                    <form className='row g-3'>
                                                        <div className='col-12'>
                                                            <FormGroup>
                                                                <h6>Select All</h6>
                                                                <Checks
                                                                    id='available'
                                                                    type='switch'
                                                                    label='Select All Columns'
                                                                    onChange={() => getColumnsForTable('getFuelConsumptionReport', 'post')}
                                                                    checked={isChecked}
                                                                    ariaLabel='Available status'
                                                                />
                                                            </FormGroup>
                                                        </div>
                                                        <div className='col-12' style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                                                            <FormGroup>
                                                                <h6>Columns</h6>
                                                                <ChecksGroup>
                                                                    {allColumnsData.map((i: any) => (
                                                                        <Checks
                                                                            id={i.columnVisibilityId}
                                                                            label={i.columnName}
                                                                            onChange={() => updateColumnsSubmit(i.columnVisibilityId, i.isDisplay, '')}
                                                                            checked={i.isDisplay} disabled={i.isDefault == true}
                                                                        />
                                                                    ))}
                                                                </ChecksGroup>
                                                            </FormGroup>
                                                        </div>
                                                    </form>
                                                </div>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                    <Dropdown isButtonGroup>
                                        <DropdownToggle>
                                            <Button color='primary' isLight icon='CloudDownload'> Export </Button>
                                        </DropdownToggle>
                                        <DropdownMenu isAlignmentEnd>
                                            <DropdownItem>
                                                <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel("fuelConsumptionReport", fuelConsumptionReportData, columnVisibilityData)}> Excel </Button>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(fuelConsumptionReportData, columnVisibilityData, "fuelConsumptionReport")}> PDF
                                                </Button>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile("fuelConsumptionReport", fuelConsumptionReportData, columnVisibilityData)}> CSV </Button>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <Button color='primary' isLight icon='Print' onClick={handlePrint} > Print </Button>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>

                                </CardActions>
                            </CardHeader>
                            <CardBody className='table-responsive' isScrollable>
                                <table className='table table-modern table-hover text-nowrap'>
                                    <thead>
                                        <tr>
                                            <th scope='col' onClick={() => requestSort('sno')}
                                                className='cursor-pointer text-decoration-underline'>S.No{' '}
                                                <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                            </th>
                                            {columnVisibilityData.map((column: any) => (<>
                                                <th scope='col' onClick={() => requestSort(column.keyName)}
                                                    className='cursor-pointer text-decoration-underline'>
                                                    {column.columnName}
                                                    <Icon size='lg' className={getClassNamesFor(column.keyName)}
                                                        icon='FilterList'
                                                    />
                                                </th>
                                            </>))}
                                            <th scope='col' className='d-print-none'>
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredData != "" ?
                                            <>
                                                {filteredData.map((rowData: any) => (
                                                    <tr key={rowData.fuelConsumptionId}>
                                                        <td>{rowData.sno}</td>
                                                        {columnVisibilityData.map((column: any) => (
                                                            (column.isDisplay && rowData[column.keyName]) ?
                                                                <td key={`${rowData.fuelConsumptionId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />
                                                        ))}
                                                        <td className="d-print-none" >
                                                            <Button color='dark' isLight icon='Edit' aria-label='Edit' onClick={() => { onActivate(rowData) }} /></td>
                                                    </tr>
                                                ))
                                                } </>
                                            :
                                            <NoDataMsg columnsCount={columnVisibilityData.length + 2} msg={noDataMsg} />}
                                    </tbody>
                                </table>
                            </CardBody>
                            <PaginationButtons
                                className='d-print-none'
                                data={items}
                                label='items'
                                setCurrentPage={setCurrentPage}
                                currentPage={currentPage}
                                perPage={perPage}
                                setPerPage={setPerPage}
                            />
                        </Card> : null}
                </Page>

                <Modal
                    isOpen={updateFuelConsumptionReport}
                    setIsOpen={setUpdateFuelConsumptionReport}
                    isStaticBackdrop
                    //isScrollable={true}
                    isCentered
                    size={'xl'}
                    title="updateFuelConsumptionReport"
                >
                    <ModalHeader setIsOpen={setUpdateFuelConsumptionReport} onClick={closeAndReset}>
                        <ModalTitle id="updateFuelConsumptionReport">Update Fuel Consumption Report</ModalTitle>
                    </ModalHeader>
                    <form noValidate onSubmit={updateFuelForm.handleSubmit} >
                        <ModalBody>

                            <div className="row g-4">
                                <div className="col-lg-4">
                                    <FormGroup id="vehicleDetailsId" label="Vehicle No" isFloating>
                                        <SearchableSelect isFloating placeholder='Select Vehicle' ariaLabel={""}
                                            onChange={(e: any) => setVehicleDetailsId(e)}
                                            value={vehicleDetailsId}
                                            list={vehicleDetailsData.map((data: any) => (
                                                { value: data.vehicleDetailsId, label: data.vehicleId }
                                            ))} disabled />
                                    </FormGroup>
                                </div>
                            </div>
                            <div className="row g-4 mt-4">
                                <div className="col-lg-3">
                                    <FormGroup id="fuelType" label="Fuel Type" isFloating>
                                        <Select ariaLabel=""
                                            onChange={updateFuelForm.handleChange}
                                            value={updateFuelForm.values.fuelType} disabled
                                        >
                                            <option>Petrol</option>
                                            <option>Diesel</option>
                                            <option>Gas</option>
                                        </Select>
                                    </FormGroup>
                                </div>
                                <div className="col-lg-3">
                                    <FormGroup id="previousFillingDate" label="Previous Filling date" isFloating>
                                        <Input type='date'
                                            onChange={updateFuelForm.handleChange}
                                            value={updateFuelForm.values.previousFillingDate} disabled
                                        />
                                    </FormGroup>
                                </div>
                                <div className="col-lg-3">
                                    <FormGroup id="previousQuantityFilled" label="Quantity Filled (Liters) " isFloating>
                                        <Input type='text'
                                            onChange={updateFuelForm.handleChange}
                                            value={updateFuelForm.values.previousQuantityFilled} disabled
                                        />
                                    </FormGroup>
                                </div>
                                <div className="col-lg-3">
                                    <FormGroup id="previousMileage" label="Previous Mileage" isFloating>
                                        <Input type='text'
                                            onChange={updateFuelForm.handleChange}
                                            value={updateFuelForm.values.previousMileage} disabled
                                        />
                                    </FormGroup>
                                </div>
                                <div className="col-lg-3">
                                    <FormGroup id="staffDetailsId" label="Driver Responsible" isFloating>
                                        <SearchableSelect isFloating ariaLabel=""
                                            onChange={selectStaffDetails}
                                            value={staffDetailsId}
                                            list={staffDetailsData.map((data: any) => (
                                                { value: data.staffDetailsId, label: data.staffName }
                                            ))}
                                        />
                                    </FormGroup>
                                </div>
                                <div className="col-lg-3">
                                    <FormGroup id="previousReading" label="Previous Reading" isFloating>
                                        <Input type='text'
                                            onChange={updateFuelForm.handleChange}
                                            value={updateFuelForm.values.previousReading} disabled
                                        />
                                    </FormGroup>
                                </div>
                                <div className="col-lg-3">
                                    <FormGroup id="currentReading" label="Current Reading" isFloating>
                                        <Input type='text'
                                            onInput={(e: any) => { updateTotalKiloMeterRun(e.target.value) }}
                                            value={currentReading}
                                            onKeyDown={(e: any) => onlyAllowNumber(e)} />
                                    </FormGroup>
                                </div>
                                <div className="col-lg-3">
                                    <FormGroup id="totalKmRun" label="Total KM Ran From Previous Filling" isFloating>

                                        <Input type='text'
                                            value={totalKmRun} disabled />
                                    </FormGroup>
                                </div>
                                <div className="col-lg-3">
                                    <FormGroup id="fillingDate" label="Current Filling Date" isFloating>
                                        <Input type='date'
                                            onChange={updateFuelForm.handleChange}
                                            value={updateFuelForm.values.fillingDate}
                                        />
                                    </FormGroup>
                                </div>
                                <div className="col-lg-3">
                                    <FormGroup id="quantityFilled" label="Quantity Filled (Liters) " isFloating>
                                        <Input type='text'
                                            onInput={(e: any) => { updateMileage(e.target.value); }}
                                            value={quantityFilled} />
                                    </FormGroup>
                                </div>
                                <div className="col-lg-3">
                                    <FormGroup id="mileage" label="Current Mileage (per ltr)" isFloating>
                                        <Input type='text'
                                            // onChange={updateFuelForm.handleChange}
                                            value={mileage} disabled
                                        />
                                    </FormGroup>
                                </div>
                                <div className="col-lg-3">
                                    <FormGroup id="averageMileage" label="Average Mileage" isFloating>
                                        <Input type='text'
                                            onChange={updateFuelForm.handleChange}
                                            value={averageMileage}
                                            disabled />
                                    </FormGroup>
                                </div>
                                <div className="col-lg-3">
                                    <FormGroup id="ratePerLiter" label="Rate Per Liter" isFloating>
                                        <Input type='text'
                                            onInput={(e: any) => { updateAmount(e.target.value); }}
                                            value={ratePerLiter} />
                                    </FormGroup>
                                </div>
                                <div className="col-lg-3">
                                    <FormGroup id="amount" label="amount" isFloating>
                                        <Input type='text'
                                            value={amount} disabled
                                        />
                                    </FormGroup>
                                </div>
                                <div className="col-lg-3">
                                    <FormGroup id="ledgerDetailsId" label="ledgerDetailsId" isFloating>
                                        <SearchableSelect isFloating ariaLabel=""
                                            onChange={selectLedger}
                                            value={ledgerDetailsId}
                                            list={ledgerDetailsData.map((data: any) => (
                                                { value: data.ledgerDetailsId, label: data.ledgerName }
                                            ))}
                                        />
                                    </FormGroup>
                                </div>
                                <div className="col-lg-3">
                                    <FormGroup id="remarks" label="remarks" isFloating>
                                        <Input type='text'
                                            onChange={updateFuelForm.handleChange}
                                            value={updateFuelForm.values.remarks}
                                        />
                                    </FormGroup>
                                </div>
                            </div>

                        </ModalBody>
                        <ModalFooter>
                            <div className='row'>
                                <div className="d-flex justify-content-center position-absolute top-65 start-80 translate-middle">
                                    <DeleteComponents isDeleteOpen={isDeleteOpen} setIsDeleteOpen={setIsDeleteOpen} deleteFunction={deleteFuelConsumptionReportSubmit} />
                                </div>
                            </div>
                            <Button color='info' isOutline className='border-0' onClick={closeAndReset}>Close</Button>
                            <Button color='danger' icon='Delete' onClick={() => setIsDeleteOpen(!isDeleteOpen)}>Delete</Button>
                            <Button icon="Save" color="info" type="submit" isDisable={!updateFuelForm.isValid && !!updateFuelForm.submitCount} >
                                Update
                            </Button>
                        </ModalFooter>
                    </form>
                </Modal>
                <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
            </PageWrapper>

        </>
    )
}

export default FuelConsumptionReport;
