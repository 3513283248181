import { useFormik } from "formik";
import Page from "../../../layout/Page/Page";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import FormGroup from "../../bootstrap/forms/FormGroup";
import Input from "../../bootstrap/forms/Input";
import Select from "../../bootstrap/forms/Select";
import Icon from "../../icon/Icon";
import { useContext, useEffect, useState } from "react";
import { toasts } from "../../../services/toast.service";
import AlertService from "../../../services/AlertService";
import Card, { CardBody, CardFooter, CardFooterRight, CardHeader, CardLabel, CardSubTitle, CardTitle } from "../../bootstrap/Card";
import Button from "../../bootstrap/Button";
import useDarkMode from "../../../hooks/useDarkMode";
import Textarea from "../../bootstrap/forms/Textarea";
import { getCity, getStates, onlyAllowNumber } from "../../../services/common.service";
import AuthContext from "../../../contexts/authContext";
import { getLicenseKey } from "../../../services/application.settings";
import SearchableSelect from "../../../common/components/SearchableSelect";
import { getSchoolDetails, getTrustList, updateSchoolDetails } from "../../../services/school.service";

function SchoolDetails() {

    useEffect(() => {
        getSchoolList();
        getTrust();
        getStatesList();
    }, [])

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const [updateCollegeDetailsData, setUpdateCollegeDetailsData] = useState([])
    const [trustListData, setTrustListData] = useState<any>([])
    const [statesData, setStatesData] = useState<any>([])
    const [cityData, setCityData] = useState<any>([])
    const [stateId, setStateId] = useState<any>('')
    const [cityId, setCityId] = useState<any>('')
    const [trustCreationId, setTrustCreationId] = useState<any>('')

    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);

    const { themeStatus, darkModeStatus } = useDarkMode();

    const TABS = {
        BASIC_INFO: 'Basic Info',
        ADDRESS: 'Address',
        DETAILS: 'Details',
        OTHER_INFO: 'Others',
    };
    
    const [activeTab, setActiveTab] = useState(TABS.BASIC_INFO);

    const updateCollageForm = useFormik({

        enableReinitialize: true,
        initialValues: {
            schoolDetailsId: '',
            trustCreationId: '',
            schoolName: '',
            schoolLoginName: '',
            profile: '',
            chairman: '',
            director: '',
            founders: '',
            address1: '',
            address2: '',
            cityId: '',
            stateId: '',
            pincode: '',
            phone: '',
            cellNo: '',
            schoolMailId: '',
            website: '',
            status: '',
            typeByGender: '',
            typeByShift: '',
            minority: '',
            minorityStatus: '',
            dateOfEstablishment: '',
            CPE: '',
            dateOfRecognition: '',
            location: '',
            campusArea: '',
            builtUpArea: '',
            IQAC: '',
            govenmentAgency: '',
            nameOfAgency: '',
            dateOfCollegeRecognizedByGovernmentAgency: '',
            sourceOfFund: '',
            university: '',
            dateOfAffiliation: '',
            UGCUnderSection: '',
            UGCDate: '',
            UGCRemarks: '',
            instituteUnderSection: '',
            detailsOfRecognition: '',
            instituteDate: '',
            validity: '',
            instituteRemarks: '',
            autonomyUniversity: '',
            autonomy: '',
            autonomyStatus: '',
            schoolOffersDistanceEducation: '',
            registeredForAnotherUniversity: '',
            distanceEducationCouncil: '',
            Year: '',
            workingDays: '',
            teachingDays: '',
            description: ''
        },
        validate: (values: any) => {
            const errors: {
                schoolName?: string;
                schoolMailId?: string;
                phone?: string;
                cellNo?: string;
                pincode?: string;
            } = {};

            if (!values.schoolName) {
                errors.schoolName = 'Required'
            }
            else if (values.schoolName.length < 3) {
                errors.schoolName = 'Must be 3 characters or more';
            }
            // else if (values.schoolName.length > 20) {
            //     errors.schoolName = 'Must be 20 characters or less';
            // }
            if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.schoolMailId) || values.schoolMailId == '') {
                errors.schoolMailId = 'Invalid email address';
            }
            if (!/^[6-9]\d{9}$/i.test(values.phone) || values.phone == "") {
                errors.phone = 'Must be 10 Numbers';
            }
            if (!/^\d{10}$/.test(values.cellNo) || values.cellNo == '') {
                errors.cellNo = 'Must be 10 Numbers';
            }
            if (!/^\d{6}$/.test(values.pincode)) {
                errors.pincode = 'Must be 6 Numbers';
            }

            return errors;
        },
        validateOnChange: true,
        onSubmit: () => { updateSchoolSubmit() },
    });

    const selectState = (e: any) => {
        let stateId = e
        setStateId(stateId)
        if (stateId?.value != undefined) {
            getCityList(stateId?.value)
        }
    }

    const selectCity = (e: any) => {
        let cityId = e
        setCityId(cityId)
    }

    const selectTrust = (e: any) => {
        let trustCreationId = e
        setTrustCreationId(trustCreationId)
    }

    function getTrust() {
        getTrustList(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.trustList;
                    if (data != undefined) {
                        setTrustListData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setTrustListData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStatesList() {
        getStates(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.statesAndCity;
                    if (data != undefined) {
                        setStatesData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setStatesData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getCityList(stateId: number) {
        getCity(stateId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.statesAndCity[0]['city'];
                    if (data != undefined) {
                        setCityData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setCityData([]);
                } else {

                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function setUpdateCollegeDetails() {

        return ({
            licenseKey: getLicenseKey,
            userAccountId: userAccountId,
            schoolDetailsId: updateCollageForm.values.schoolDetailsId,
            trustCreationId: trustCreationId.value,
            schoolName: updateCollageForm.values.schoolName,
            schoolLoginName: updateCollageForm.values.schoolLoginName,
            chairman: updateCollageForm.values.chairman,
            director: updateCollageForm.values.director,
            founders: updateCollageForm.values.founders,
            profile: updateCollageForm.values.profile,
            address1: updateCollageForm.values.address1,
            address2: updateCollageForm.values.address2,
            cityId: cityId.value,
            stateId: stateId.value,
            pincode: updateCollageForm.values.pincode,
            phone: updateCollageForm.values.phone,
            cellNo: updateCollageForm.values.cellNo,
            schoolMailId: updateCollageForm.values.schoolMailId,
            website: updateCollageForm.values.website,
            status: updateCollageForm.values.status,
            typeByGender: updateCollageForm.values.typeByGender,
            typeByShift: updateCollageForm.values.typeByShift,
            minority: updateCollageForm.values.minority,
            minorityStatus: updateCollageForm.values.minorityStatus,
            dateOfEstablishment: updateCollageForm.values.dateOfEstablishment,
            CPE: updateCollageForm.values.CPE,
            dateOfRecognition: updateCollageForm.values.dateOfRecognition,
            location: updateCollageForm.values.location,
            campusArea: updateCollageForm.values.campusArea,
            builtUpArea: updateCollageForm.values.builtUpArea,
            IQAC: updateCollageForm.values.IQAC,
            govenmentAgency: updateCollageForm.values.govenmentAgency,
            nameOfAgency: updateCollageForm.values.nameOfAgency,
            dateOfCollegeRecognizedByGovernmentAgency: updateCollageForm.values.dateOfCollegeRecognizedByGovernmentAgency,
            sourceOfFund: updateCollageForm.values.sourceOfFund,
            university: updateCollageForm.values.university,
            dateOfAffiliation: updateCollageForm.values.dateOfAffiliation,
            UGCUnderSection: updateCollageForm.values.UGCUnderSection,
            UGCDate: updateCollageForm.values.UGCDate,
            UGCRemarks: updateCollageForm.values.UGCRemarks,
            instituteUnderSection: updateCollageForm.values.instituteUnderSection,
            detailsOfRecognition: updateCollageForm.values.detailsOfRecognition,
            instituteDate: updateCollageForm.values.instituteDate,
            validity: updateCollageForm.values.validity,
            instituteRemarks: updateCollageForm.values.instituteRemarks,
            autonomyUniversity: updateCollageForm.values.autonomyUniversity,
            autonomy: updateCollageForm.values.autonomy,
            autonomyStatus: updateCollageForm.values.autonomyStatus,
            schoolOffersDistanceEducation: updateCollageForm.values.schoolOffersDistanceEducation,
            registeredForAnotherUniversity: updateCollageForm.values.registeredForAnotherUniversity,
            distanceEducationCouncil: updateCollageForm.values.distanceEducationCouncil,
            Year: updateCollageForm.values.Year,
            workingDays: updateCollageForm.values.workingDays,
            teachingDays: updateCollageForm.values.teachingDays,
            description: updateCollageForm.values.description,
        })
    }

    function getSchoolList() {
        getSchoolDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.schoolDetails[0];
                    if (data != undefined) {
                        setUpdateCollegeDetailsData(data);
                        setTrustCreationId({ value: data.trustCreationId, label: data.trustName })
                        setStateId({ value: data.stateId, label: data.stateName });
                        setCityId({ value: data.cityId, label: data.district })
                        getCityList(data.stateId);
                        updateCollageForm.setValues({
                            schoolDetailsId: data.schoolDetailsId ? data.schoolDetailsId : '',
                            trustCreationId: trustCreationId?.value ? trustCreationId?.value : '',
                            schoolName: data.schoolName ? data.schoolName : '',
                            schoolLoginName: data.schoolLoginName ? data.schoolLoginName : '',
                            chairman: data.chairman,
                            director: data.director,
                            founders: data.founders,
                            profile: data.profile,
                            address1: data.address1,
                            address2: data.address2,
                            stateId: stateId?.value,
                            cityId: cityId?.value,
                            pincode: data.pincode,
                            phone: data.phone,
                            cellNo: data.cellNo,
                            schoolMailId: data.schoolMailId,
                            website: data.website,
                            status: data.status,
                            typeByGender: data.typeByGender,
                            typeByShift: data.typeByShift,
                            minority: data.minority,
                            minorityStatus: data.minorityStatus,
                            dateOfEstablishment: data.dateOfEstablishment,
                            CPE: data.CPE,
                            dateOfRecognition: data.dateOfRecognition,
                            location: data.location,
                            campusArea: data.campusArea,
                            builtUpArea: data.builtUpArea,
                            IQAC: data.IQAC,
                            govenmentAgency: data.govenmentAgency,
                            nameOfAgency: data.nameOfAgency,
                            dateOfCollegeRecognizedByGovernmentAgency: data.dateOfCollegeRecognizedByGovernmentAgency,
                            sourceOfFund: data.sourceOfFund,
                            university: data.university,
                            dateOfAffiliation: data.dateOfAffiliation,
                            UGCUnderSection: data.UGCUnderSection,
                            UGCDate: data.UGCDate,
                            UGCRemarks: data.UGCRemarks,
                            instituteUnderSection: data.instituteUnderSection,
                            detailsOfRecognition: data.detailsOfRecognition,
                            instituteDate: data.instituteDate,
                            validity: data.validity,
                            instituteRemarks: data.instituteRemarks,
                            autonomyUniversity: data.autonomyUniversity,
                            autonomy: data.autonomy,
                            autonomyStatus: data.autonomyStatus,
                            schoolOffersDistanceEducation: data.schoolOffersDistanceEducation,
                            registeredForAnotherUniversity: data.registeredForAnotherUniversity,
                            distanceEducationCouncil: data.distanceEducationCouncil,
                            Year: data.Year,
                            workingDays: data.workingDays,
                            teachingDays: data.teachingDays,
                            description: data.description ? data.description : '',

                        });
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function updateSchoolSubmit() {

        if (updateCollageForm.isValid) {
            let updateCollegeDetailsPostData = setUpdateCollegeDetails()
            updateSchoolDetails(updateCollegeDetailsPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        getSchoolList();
                    }
                    else if (data.success == false) {
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (updateCollageForm.isValid == false) {
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    return (
        <>
            <PageWrapper title={`School Details`}>
                <Page container='fluid'>
                    <div className='row h-100'>
                        <div className='col-xl-3 col-lg-4 col-md-6'>
                            <Card stretch>
                                <CardHeader>
                                    <CardLabel icon='AccountBalance' iconColor='info'>
                                        <CardTitle tag='div' className='h5'>
                                            Update School
                                        </CardTitle>
                                        <CardSubTitle tag='div' className='h6'>
                                            Information
                                        </CardSubTitle>
                                    </CardLabel>
                                </CardHeader>
                                <CardBody isScrollable>
                                    <div className='row g-3'>
                                        <div className='col-12'>
                                            <Button
                                                icon='Article'
                                                color='info'
                                                className='w-100 p-3'
                                                isLight={TABS.BASIC_INFO !== activeTab}
                                                onClick={() => setActiveTab(TABS.BASIC_INFO)}>
                                                {TABS.BASIC_INFO}
                                            </Button>
                                        </div>
                                        <div className='col-12'>
                                            <Button
                                                icon='Home'
                                                color='info'
                                                className='w-100 p-3'
                                                isLight={TABS.ADDRESS !== activeTab}
                                                onClick={() => setActiveTab(TABS.ADDRESS)}>
                                                {TABS.ADDRESS}
                                            </Button>
                                        </div>
                                        <div className='col-12 border-bottom' />
                                        {/* <div className='col-12'>
                                            <Button
                                                icon='ListAlt'
                                                color='success'
                                                className='w-100 p-3'
                                                isLight={TABS.DETAILS !== activeTab}
                                                onClick={() => setActiveTab(TABS.DETAILS)}>
                                                {TABS.DETAILS}
                                            </Button>
                                        </div> */}
                                        <div className='col-12'>
                                            <Button
                                                icon='ListAlt'
                                                color='success'
                                                className='w-100 p-3'
                                                isLight={TABS.OTHER_INFO !== activeTab}
                                                onClick={() => setActiveTab(TABS.OTHER_INFO)}>
                                                {TABS.OTHER_INFO}
                                            </Button>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                        <div className='col-xl-9 col-lg-8 col-md-6'>
                            {TABS.BASIC_INFO === activeTab && (
                                <Card stretch tag='form' noValidate onSubmit={updateCollageForm.handleSubmit}>
                                    <CardHeader>
                                        <CardLabel icon='Article' iconColor='info'>
                                            <CardTitle tag='div' className='h5'>
                                                Basic Info
                                            </CardTitle>
                                        </CardLabel>
                                    </CardHeader>
                                    <CardBody isScrollable>

                                        <div className="row g-4">
                                            <div className="col-md-6">
                                                <FormGroup id="schoolName" label="Name" isFloating>
                                                    <Input type='text' onChange={updateCollageForm.handleChange} value={updateCollageForm.values.schoolName}
                                                        onBlur={updateCollageForm.handleBlur}
                                                        isValid={updateCollageForm.isValid}
                                                        isTouched={updateCollageForm.touched.schoolName}
                                                        invalidFeedback={updateCollageForm.errors.schoolName} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-6">
                                                <FormGroup id="trustCreationId" label="Trust" isFloating>
                                                    <SearchableSelect isFloating ariaLabel='Trust' placeholder="Select Trust"
                                                        onChange={(e: any) => selectTrust(e)}
                                                        value={trustCreationId}
                                                        list={trustListData.map((data: any) => (
                                                            { value: data.trustCreationId, label: data.trustName }
                                                        ))} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-6">
                                                <FormGroup id="profile" label="Profile" isFloating>
                                                    <Input type='text' onChange={updateCollageForm.handleChange} value={updateCollageForm.values.profile} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-6">
                                                <FormGroup id="director" label="Director" isFloating>
                                                    <Input type='text' onChange={updateCollageForm.handleChange} value={updateCollageForm.values.director} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-6">
                                                <FormGroup id="chairman" label="Chairman" isFloating>
                                                    <Input type='text' onChange={updateCollageForm.handleChange} value={updateCollageForm.values.chairman} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-6">
                                                <FormGroup id="founders" label="Founder's" isFloating>
                                                    <Input type='text' onChange={updateCollageForm.handleChange} value={updateCollageForm.values.founders} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-6">
                                                <FormGroup id="schoolMailId" label="School Email" isFloating>
                                                    <Input type='text'
                                                        onChange={updateCollageForm.handleChange}
                                                        value={updateCollageForm.values.schoolMailId || ''}
                                                        onBlur={updateCollageForm.handleBlur}
                                                        isValid={updateCollageForm.isValid}
                                                        isTouched={updateCollageForm.touched.schoolMailId}
                                                        invalidFeedback={updateCollageForm.errors.schoolMailId} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-6">
                                                <FormGroup id="website" label="School Website" isFloating>
                                                    <Input type='text' onChange={updateCollageForm.handleChange} value={updateCollageForm.values.website} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-6">
                                                <FormGroup id="phone" label="Mobile No" isFloating>
                                                    <Input type='text' onChange={updateCollageForm.handleChange}
                                                        value={updateCollageForm.values.phone}
                                                        onBlur={updateCollageForm.handleBlur}
                                                        isValid={updateCollageForm.isValid}
                                                        isTouched={updateCollageForm.touched.phone}
                                                        invalidFeedback={updateCollageForm.errors.phone}
                                                        onKeyDown={(e: any) => onlyAllowNumber(e)} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-6">
                                                <FormGroup id="cellNo" label="Cell No" isFloating>
                                                    <Input type='text' onChange={updateCollageForm.handleChange} value={updateCollageForm.values.cellNo} onBlur={updateCollageForm.handleBlur}
                                                        isValid={updateCollageForm.isValid}
                                                        isTouched={updateCollageForm.touched.cellNo}
                                                        invalidFeedback={updateCollageForm.errors.cellNo}
                                                        onKeyDown={(e: any) => onlyAllowNumber(e)} />
                                                </FormGroup>
                                            </div>
                                        </div>
                                    </CardBody>
                                    <CardFooter>
                                        <CardFooterRight>
                                            <Button
                                                type='submit'
                                                icon='Save'
                                                color='info'
                                                isOutline
                                                isDisable={
                                                    !updateCollageForm.isValid &&
                                                    !!updateCollageForm.submitCount
                                                }
                                            >
                                                Save
                                            </Button>
                                        </CardFooterRight>
                                    </CardFooter>
                                </Card>
                            )}
                            {TABS.ADDRESS === activeTab && (
                                <Card stretch tag='form' noValidate onSubmit={updateCollageForm.handleSubmit}>
                                    <CardHeader>
                                        <CardLabel icon='Home' iconColor='info'>
                                            <CardTitle>{TABS.ADDRESS}</CardTitle>
                                        </CardLabel>
                                    </CardHeader>
                                    <CardBody className='pb-0' isScrollable>
                                        <div className="row g-4">
                                            <div className="col-md-12">
                                                <FormGroup id="address1" label="Address 1" isFloating>
                                                    <Input type='text' onChange={updateCollageForm.handleChange} value={updateCollageForm.values.address1} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-12">
                                                <FormGroup id="address2" label="Address 2" isFloating>
                                                    <Input type="text" onChange={updateCollageForm.handleChange} value={updateCollageForm.values.address2} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-6">
                                                <FormGroup id="stateId" label="State" isFloating>
                                                    <SearchableSelect isFloating ariaLabel='State' placeholder="Select State"
                                                        onChange={(e: any) => selectState(e)}
                                                        value={stateId}
                                                        list={statesData.map((data: any) => (
                                                            { value: data.stateId, label: data.stateName }
                                                        ))} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-6">
                                                <FormGroup id="cityId" label="City" isFloating>
                                                    <SearchableSelect isFloating ariaLabel='City' placeholder="Select City"
                                                        onChange={(e: any) => selectCity(e)}
                                                        value={cityId}
                                                        list={cityData.map((data: any) => (
                                                            { value: data.cityId, label: data.cityName }
                                                        ))} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-6">
                                                <FormGroup id="pincode" label="Pincode" isFloating>
                                                    <Input type='text' onChange={updateCollageForm.handleChange} value={updateCollageForm.values.pincode}
                                                        onBlur={updateCollageForm.handleBlur}
                                                        isValid={updateCollageForm.isValid}
                                                        isTouched={updateCollageForm.touched.pincode}
                                                        invalidFeedback={updateCollageForm.errors.pincode}
                                                        onKeyDown={(e: any) => onlyAllowNumber(e)} />
                                                </FormGroup>
                                            </div>
                                        </div>
                                    </CardBody>
                                    <CardFooter>

                                        <CardFooterRight>
                                            <Button
                                                type='submit'
                                                icon='Save'
                                                color='info'
                                                isOutline
                                                isDisable={
                                                    !updateCollageForm.isValid &&
                                                    !!updateCollageForm.submitCount
                                                }
                                            >
                                                Save
                                            </Button>
                                        </CardFooterRight>
                                    </CardFooter>
                                </Card>
                            )}
                            {TABS.OTHER_INFO === activeTab && (
                                <Card stretch tag='form' noValidate onSubmit={updateCollageForm.handleSubmit}>
                                    <CardBody isScrollable>
                                        <div className="row g-4">
                                            <div className="col-md-12">
                                                <p className='lead fw-bold'><Icon icon="List" color="info" size={'2x'} /> Other Information </p>
                                            </div>
                                            <div className="col-md-6">
                                                <FormGroup id="status" label="Status" isFloating>
                                                    <Input type='text' onChange={updateCollageForm.handleChange} value={updateCollageForm.values.status} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-6">
                                                <FormGroup id="typeByGender" label="Type By Gender" isFloating>
                                                    <Input type='text' onChange={updateCollageForm.handleChange} value={updateCollageForm.values.typeByGender} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-6">
                                                <FormGroup id="typeByShift" label="Type By Shift" isFloating>
                                                    <Input type='text' onChange={updateCollageForm.handleChange} value={updateCollageForm.values.typeByShift} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-6">
                                                <FormGroup id="minority" label="Minority" isFloating>
                                                    <Input type='text' onChange={updateCollageForm.handleChange} value={updateCollageForm.values.minority} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-6">
                                                <FormGroup id="minorityStatus" label="Minority Status" isFloating>
                                                    <Input type='text' onChange={updateCollageForm.handleChange} value={updateCollageForm.values.minorityStatus} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-6">
                                                <FormGroup id="dateOfEstablishment" label="Date Of Establishment" isFloating>
                                                    <Input type='date' onChange={updateCollageForm.handleChange} value={updateCollageForm.values.dateOfEstablishment} />
                                                </FormGroup>
                                            </div>

                                            <div className="col-md-12">
                                                <p className='lead fw-bold'><Icon icon="Groups" color="info" size={'2x'} /> Campus Information </p>
                                            </div>
                                            <div className="col-md-6">
                                                <FormGroup id="CPE" label="CPE" isFloating>
                                                    <Input type='text' onChange={updateCollageForm.handleChange} value={updateCollageForm.values.CPE} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-6">
                                                <FormGroup id="dateOfRecognition" label="Date Of Recognition" isFloating>
                                                    <Input type='date' onChange={updateCollageForm.handleChange} value={updateCollageForm.values.dateOfRecognition} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-6">
                                                <FormGroup id="location" label="Location" isFloating>
                                                    <Input type='text' onChange={updateCollageForm.handleChange} value={updateCollageForm.values.location} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-6">
                                                <FormGroup id="campusArea" label="Campus Area" isFloating>
                                                    <Input type='text' onChange={updateCollageForm.handleChange} value={updateCollageForm.values.campusArea} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-6">
                                                <FormGroup id="builtUpArea" label="Built Up Area" isFloating>
                                                    <Input type='text' onChange={updateCollageForm.handleChange} value={updateCollageForm.values.builtUpArea} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-6">
                                                <FormGroup id="IQAC" label="IQAC" isFloating>
                                                    <Input type='text' onChange={updateCollageForm.handleChange} value={updateCollageForm.values.IQAC} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-12">
                                                <p className='lead fw-bold'><Icon icon="Groups" color="info" size={'2x'} /> School Fund</p>
                                            </div>
                                            <div className="col-md-6">
                                                <FormGroup id="sourceOfFund" label="Source Of Fund" isFloating>
                                                    <Input type='text' onChange={updateCollageForm.handleChange} value={updateCollageForm.values.sourceOfFund} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-12">
                                                <p className='lead fw-bold'><Icon icon="List" color="info" size={'2x'} /> School Running Days</p>
                                            </div>
                                            <div className="col-md-6">
                                                <FormGroup id="Year" label="Year" isFloating>
                                                    <Input type='text' onChange={updateCollageForm.handleChange} value={updateCollageForm.values.Year || ''} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-6">
                                                <FormGroup id="workingDays" label="Working Days" isFloating>
                                                    <Input type='text' onChange={updateCollageForm.handleChange} value={updateCollageForm.values.workingDays || ''} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-6">
                                                <FormGroup id="teachingDays" label="Teaching Days" isFloating>
                                                    <Input type='text' onChange={updateCollageForm.handleChange} value={updateCollageForm.values.teachingDays || ''} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-6">
                                                <FormGroup id="description" label="Description" isFloating>
                                                    <Textarea placeholder='Enter Description' onChange={updateCollageForm.handleChange} value={updateCollageForm.values.description || ''} />
                                                </FormGroup>
                                            </div>

                                        </div>
                                    </CardBody>
                                    <CardFooter>

                                        <CardFooterRight>
                                            <Button
                                                type='submit'
                                                icon='Save'
                                                color='info'
                                                isOutline
                                                isDisable={
                                                    !updateCollageForm.isValid &&
                                                    !!updateCollageForm.submitCount
                                                }
                                            >
                                                Save
                                            </Button>
                                        </CardFooterRight>
                                    </CardFooter>
                                </Card>
                            )}
                            {TABS.DETAILS === activeTab && (
                                <Card stretch tag='form' noValidate onSubmit={updateCollageForm.handleSubmit}>
                                    <CardBody isScrollable>
                                        <div className="row g-4">
                                            <div className="col-md-12">
                                                <p className='lead fw-bold'>
                                                    <Icon icon="Groups" color="info" size={'2x'} /> School Recognized By Government Agency
                                                </p>
                                            </div>
                                            <div className="col-md-6">
                                                <FormGroup id="govenmentAgency" label="Government Agency" isFloating>
                                                    <Input type='text' onChange={updateCollageForm.handleChange} value={updateCollageForm.values.govenmentAgency || ''} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-6">
                                                <FormGroup id="nameOfAgency" label="Name Of Agency" isFloating>
                                                    <Input type='text' onChange={updateCollageForm.handleChange} value={updateCollageForm.values.nameOfAgency || ''} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-6">
                                                <FormGroup id="dateOfRecognition" label="Date Of Recognition" isFloating>
                                                    <Input type='date' onChange={updateCollageForm.handleChange} value={updateCollageForm.values.dateOfRecognition || ''} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-12">
                                                <p className='lead fw-bold'><Icon icon="Groups" color="info" size={'2x'} /> Affliation</p>
                                            </div>
                                            <div className="col-md-6">
                                                <FormGroup id="university" label="University" isFloating>
                                                    <Input type='text' onChange={updateCollageForm.handleChange} value={updateCollageForm.values.university || ''} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-6">
                                                <FormGroup id="dateOfAffiliation" label="Date Of Affiliation" isFloating>
                                                    <Input type='date' onChange={updateCollageForm.handleChange} value={updateCollageForm.values.dateOfAffiliation || ''} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-12">
                                                <p className='lead fw-bold'><Icon icon="Groups" color="info" size={'2x'} /> UGC</p>
                                            </div>
                                            <div className="col-md-6">
                                                <FormGroup id="UGCUnderSection" label="Under Section" isFloating>
                                                    <Input type='text' onChange={updateCollageForm.handleChange} value={updateCollageForm.values.UGCUnderSection || ''} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-6">
                                                <FormGroup id="UGCDate" label="Date" isFloating>
                                                    <Input type='date' onChange={updateCollageForm.handleChange} value={updateCollageForm.values.UGCDate || ''} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-6">
                                                <FormGroup id="UGCRemarks" label="Remarks" isFloating>
                                                    <Textarea placeholder='Enter UGC Remarks' onChange={updateCollageForm.handleChange} value={updateCollageForm.values.UGCRemarks || ''} />
                                                </FormGroup>
                                            </div>
                                        </div>
                                    </CardBody>
                                    <CardFooter>
                                        <CardFooterRight>
                                            <Button type='submit' icon='Save' color='info' isOutline isDisable={
                                                !updateCollageForm.isValid &&
                                                !!updateCollageForm.submitCount
                                            }>
                                                Save
                                            </Button>
                                        </CardFooterRight>
                                    </CardFooter>
                                </Card>
                            )}

                        </div>
                    </div>
                </Page>

                <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
            </PageWrapper>
        </>
    )
}
export default SchoolDetails;