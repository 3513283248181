import React, { useContext, useEffect, useRef, useState } from 'react';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Collapse from '../../bootstrap/Collapse';
import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../bootstrap/Card';
import FormGroup from '../../bootstrap/forms/FormGroup';
import SearchableSelect from '../../../common/components/SearchableSelect';
import Button, { ButtonGroup } from '../../bootstrap/Button';
import OpenCardComponent from '../../../common/components/OpenCardComponent';
import { downloadExcel, convertJsonToPdf, downloadFile } from '../../../services/ExportService';
import Dropdown, { DropdownToggle, DropdownMenu, DropdownItem } from '../../bootstrap/Dropdown';
import Checks, { ChecksGroup } from '../../bootstrap/forms/Checks';
import Icon from '../../icon/Icon';
import { useReactToPrint } from 'react-to-print';
import Input from '../../bootstrap/forms/Input';
import { useFormik } from 'formik';
import { getBatchMasterDetails, getSectionDetails } from '../../../services/master.service';
import { toasts } from '../../../services/toast.service';
import { getFeeType } from '../../../services/fee.service';
import { getStudentDetailsBySectionDetailsId } from '../../../services/student.service';
import { getColumnsForDataTable, getMedium, getStandardDetails, updateColumnsForDataTable, updateFilter } from '../../../services/common.service';
import AuthContext from '../../../contexts/authContext';
import { getLicenseKey } from '../../../services/application.settings';
import NoDataMsg from '../../../common/components/NoDataMsg';
import useSortableData from '../../../hooks/useSortableData';
import { dataPagination } from '../../PaginationButtons';

const BulkFeesCollection = () => {
	useEffect(() => {
		getFeeTypeList();
		getBatchList()
	});
	const { userAccountId, userTypeId } = useContext(AuthContext);
	const [isOpenListCard, setIsOpenListCard] = useState(true);
	const componentRef = useRef(null);

	const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
	const [isChecked, setIsChecked] = useState(false);
	const [isLoader, setIsLoader] = useState(false)
	const [dataSuccess, setDataSuccess] = useState(false)
	const [noDataMsg, setNoDataMsg] = useState('')

	const [mediumId, setMediumId] = useState<any>('');
	const [feeTypeId, setFeeTypeId] = useState<any>('');
	const [standardDetailsId, setStandardId] = useState<any>('');
	const [sectionDetailsId, setSectionDetailsId] = useState<any>(Number);

	const [columnVisibilityData, setColumnVisibilityData] = useState<any>([]);
	const [allColumnsData, setAllColumnsData] = useState([]);
	const [columnDataById, setColumnDataById] = useState([])
	const [feeTypeData, setFeeTypeData] = useState<any>([]);
	const [mediumData, setMediumData] = useState<any>([]);
	const [standardData, setStandardData] = useState<any>([]);
	const [sectionDetailsData, setSectionDetailsData] = useState([]);
	const [studentDetailsData, setStudentDetailsData] = useState<any>([])

	const [filteredDataToRefresh, setFilteredDataToRefresh] = useState<any>([])
	const [studentDetails, setStudentDetails] = useState<any>([])
	const [feesConfirmationArray, setFeesConfirmationArray] = useState<any>([])

	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const { items, requestSort, getClassNamesFor } = useSortableData(studentDetailsData);
	const onCurrentPageData = dataPagination(items, currentPage, perPage);

	const columnVisibilityForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			searchInput: '',
			available: false,
		},
		validate: (values) => { },
		//validateOnChange: false,
		onSubmit: () => { },
	});

	const filteredData = updateFilter(studentDetailsData, columnVisibilityData, columnVisibilityForm);

	const filteredData1 = onCurrentPageData.filter(
		(i) =>
			// Name
			i.admissionNo !== null && i.admissionNo.toString().toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase()) ||
			i.studentName !== null && i.studentName.toString().toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase()) ||
			i.medium !== null && i.medium.toString().toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase()) ||
			i.standardName !== null && i.standardName.toString().toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase())

	);

	const selectFeeType = (e: any) => {
		setMediumId('');
		setStandardId('');
		setSectionDetailsId('');
		let feeTypeId = e;
		setFeeTypeId(feeTypeId);
		console.log(feeTypeId);

	};

	const [batchMasterId, setBatchMasterId] = useState<any>('')
	const [batchMasterData, setBatchMasterData] = useState<any>([])

	const selectBatch = (e: any) => {
		setStandardId('')
		setSectionDetailsId('')
		setMediumId('')
		let batchMasterId = e
		setBatchMasterId(batchMasterId)
		getMediumList();
	}

	const selectMedium = (e: any) => {
		setStandardId('');
		setSectionDetailsId('');
		let mediumId = e;
		setMediumId(mediumId);
		if (mediumId?.value != undefined) {
			getStandardList();
		}
	};

	const selectStandard = (e: any) => {
		setSectionDetailsId('');
		let standardDetailsId = e;
		setStandardId(standardDetailsId);
		if (mediumId?.value != undefined && standardDetailsId?.value != undefined) {
			getSectionList(mediumId?.value, standardDetailsId?.value);
		}
	};

	const selectSection = (e: any) => {
		setSectionDetailsId('');
		let sectionDetailsId = e;
		setSectionDetailsId(sectionDetailsId);
	};

	function viewFeeList() {
		setDataSuccess(false)
		setIsLoader(true);
		setFilteredDataToRefresh([]);
		setStudentDetailsData([]);
		setStudentDetails([])
		setFeesConfirmationArray([]);
		getStudentList(batchMasterId?.value, sectionDetailsId?.value);
	}

	function getFeeTypeList() {
		getFeeType(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.feeType;
					if (data != undefined) {
						setFeeTypeData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setFeeTypeData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getBatchList() {
		getBatchMasterDetails(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.batchMasterDetails;
					if (data != undefined) {
						setBatchMasterData(data);
					} else {
						toasts("Undefined Data", "Error")
					}
				}
				else if (response.data.success === false) {
					// toasts(response.data.message, "Error")
					setBatchMasterData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, "Error")
				}
			}, error => {
				toasts(error, "Error")
			}
		)
	}

	function getMediumList() {
		getMedium(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.medium;
					if (data != undefined) {
						setMediumData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setMediumData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getStandardList() {
		getStandardDetails(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.standardDetails;
					if (data != undefined) {
						setStandardData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setStandardData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getSectionList(mediumId: any, standardDetailsId: any) {
		getSectionDetails(
			mediumId,
			standardDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.sectionDetails;
					if (data != undefined) {
						setSectionDetailsData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, 'Error');
					setSectionDetailsData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getStudentList(batchMasterId: any, sectionDetailsId: any) {
		setIsLoader(true);
		getStudentDetailsBySectionDetailsId(batchMasterId, sectionDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.studentDetailsBySectionDetailsId;
					if (data != undefined) {
						setIsLoader(false);
						setIsOpenListCard(false);
						getColumnsForTable('getStudentDetails', 'get');
						setStudentDetailsData(data);
						setFilteredDataToRefresh(data)
						setDataSuccess(true)
						for (let i = 0; i < data.length; i++) {
							data[i].sno = i + 1;
							data[i].isEditable = data[i].isEditable = true;
							data[i].isSelect = data[i].isSelect = false;
						}
					} else {
						setIsLoader(false);
						toasts("Undefined Data", "Error")
						setDataSuccess(false)
						setIsOpenListCard(true);
					}
				}
				else if (response.data.success === false) {
					setIsLoader(false);
					getColumnsForTable('getStudentDetails', 'get');
					setStudentDetailsData([]);
					setNoDataMsg(response.data.message)
					//toasts(response.data.message, "Error")
					setDataSuccess(true)
					setIsOpenListCard(false);
					viewFeeList()
				} else {
					setIsLoader(false);
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, "Error")
					setIsLoader(false);
					setDataSuccess(false)
					setIsOpenListCard(true);
				}
			}, error => {
				setIsLoader(false);
				toasts(error, "Error")
				setDataSuccess(false)
			}
		)
	}


	const updateStudentDetailsIdValue = (e: any, cell: number, admissionNo: any, uniRegNo: any, studentName: any) => {
		const newData: any = [];
		if (e.target.checked == true) {
			filteredDataToRefresh.forEach((item: any, index: any) => {
				if (item['studentDetailsId'] == cell) {
					item['isSelect'] = true
					item['isEditable'] = false
				}
				newData.push(item)
			});
			setFilteredDataToRefresh(newData)

		} else if (e.target.checked == false) {

			filteredDataToRefresh.forEach((item: any, index: any) => {
				if (item['studentDetailsId'] == cell) {
					item['isSelect'] = false
					item['isEditable'] = true
					item['remarks'] = ''
				}
				newData.push(item)
			});

			setFilteredDataToRefresh(newData)
		}

		if (feesConfirmationArray.length == 0) {
			const obj = {
				studentDetailsId: cell,
				admissionNo: admissionNo,
				uniRegNo: uniRegNo,
				studentName: studentName
			};
			feesConfirmationArray.push(obj);
		} else {
			let selectedItem1: any;
			feesConfirmationArray.forEach((item: any, index: any) => {
				if (item['studentDetailsId'] == cell) {
					selectedItem1 = item
					selectedItem1['studentDetailsId'] = cell
				}
			});
			if (selectedItem1 != undefined) {
				feesConfirmationArray.splice(feesConfirmationArray.indexOf(selectedItem1), 1);
				selectedItem1['studentDetailsId'] = cell
			} else {
				var obj
				obj = {
					studentDetailsId: cell,
					admissionNo: admissionNo,
					uniRegNo: uniRegNo,
					studentName: studentName
				};

				feesConfirmationArray.push(obj);
			}
		}

		if (studentDetails.length == 0) {
			const obj = {
				studentDetailsId: cell,
				absentOrPermission: "Absent",
				remarks: ""
			};
			studentDetails.push(obj);
		} else {
			let selectedItem: any;
			studentDetails.forEach((item: any, index: any) => {
				if (item['studentDetailsId'] == cell) {
					selectedItem = item
					selectedItem['studentDetailsId'] = cell
				}
			});
			if (selectedItem != undefined) {
				studentDetails.splice(studentDetails.indexOf(selectedItem), 1);
				selectedItem['studentDetailsId'] = cell
			} else {
				var obj
				obj = {
					studentDetailsId: cell,
					absentOrPermission: "Absent",
					remarks: ""
				};

				studentDetails.push(obj);
			}
		}
	}

	const updateRemarksValue = (amount: any, cell: number) => {
		const newData: any = [];

		filteredDataToRefresh.forEach((item: any, index: any) => {
			if (item['studentDetailsId'] == cell) {
				item['amount'] = amount
			}
			newData.push(item)
		});

		setFilteredDataToRefresh(newData)

		if (studentDetails.length == 0) {
			const obj = {
				studentDetailsId: cell,
				absentOrPermission: "",
				amount: amount,
			};
			studentDetails.push(obj);
		} else {
			let selectedItem: any;
			studentDetails.forEach((item: any, index: any) => {
				if (item['studentDetailsId'] == cell) {
					selectedItem = item
					selectedItem['amount'] = amount
				}
			});
			if (selectedItem != undefined) {
				selectedItem['amount'] = amount
			} else {
				var obj
				obj = {
					studentDetailsId: cell,
					absentOrPermission: "",
					amount: amount,
				};

				studentDetails.push(obj);
			}
		}

		console.log(studentDetails);

	}

	function getColumnsForTable(apiName: any, type: any) {
		getColumnsForDataTable(userTypeId, apiName,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.columnsForDataTable;
					if (data != undefined) {
						let allColumnsData = data;
						setAllColumnsData(allColumnsData)
						let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
						setColumnVisibilityData(columnVisibilityData)

						const allData = allColumnsData.map((item: any) => item.isDisplay);
						const allColumns = allData.filter((item: any) => item == false);

						if (type == 'get') {
							setIsChecked(allColumns == '' ? true : false);
						}
						else if (type == 'post') {
							const all = [];
							let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

							for (let i = 0; i < nonDefaultValue.length; i++) {

								const obj = {
									columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
									isDisplay: allColumns == '' ? 0 : 1
								}
								all.push(obj);
							}
							updateColumnsSubmit('', '', all)
						}
					} else {
						toasts("Undefined Data", "Error")
					}
				}
				else if (response.data.success === false) {
					toasts(response.data.message, "Error")
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, "Error")
				}
			}, error => {
				toasts(error, "Error")
			}
		)

	}

	function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {

		if (columnVisibilityForm.isValid) {

			setColumnDataById(isDisplay)
			const columnVisibilityPostData = {
				licenseKey: getLicenseKey,
				userAccountId: userAccountId,
				columns: type ? type : [
					{
						columnVisibilityId: columnVisibilityId,
						isDisplay: isDisplay ? 0 : 1
					}
				]
			}

			updateColumnsForDataTable(columnVisibilityPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						getColumnsForTable('getStudentDetails', 'get');
					}
					else if (data.success == false) {
						// toasts(data.success, "Error")
					}
					else {
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						toasts(errorDescription, "Error")
					}
				}
				, (error) => {
					toasts(error, "Error")
				}
			)
		} else if (columnVisibilityForm.isValid == false) {
			toasts("Please fill all the details!", "Error")
		}
	}

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	return (
		<PageWrapper title='Bulk Fees Collection'>
			<Page container='fluid'>
				<Collapse isOpen={isOpenListCard}>
					<Card>
						<CardHeader borderSize={1}>
							<CardLabel icon='List' iconColor='info' className='col-lg-9'>
								<CardTitle tag='div' className='h5'>
									Bulk Fees Collection
								</CardTitle>
							</CardLabel>
						</CardHeader>
						<CardBody>
							<div className='row'>
								<div className='col-2'>
									<FormGroup id='feeTypeId' label='Fees Type' isFloating>
										<SearchableSelect
											isFloating
											ariaLabel='Fees Type'
											onChange={selectFeeType}
											value={feeTypeId}
											list={feeTypeData.map((data: any) => ({
												value: data.feeTypeId,
												label: data.feeType,
											}))} />
									</FormGroup>
								</div>

								{feeTypeId?.value != undefined ? (
									<div className='col-md-2'>
										<FormGroup id='batchMasterId' label='Batch' isFloating>
											<SearchableSelect isFloating ariaLabel='Batch' placeholder="Batch"
												onChange={selectBatch}
												value={batchMasterId}
												list={batchMasterData.map((data: any) => ({ value: data.batchMasterId, label: data.batchName }))} required />
										</FormGroup>
									</div>) : null
								}

								{batchMasterId?.value != undefined ? (
									<div className='col-2'>
										<FormGroup id='mediumId' label='Medium' isFloating>
											<SearchableSelect
												isFloating
												ariaLabel='Medium'
												onChange={selectMedium}
												value={mediumId}
												list={mediumData.map((data: any) => ({
													value: data.mediumId,
													label: data.medium,
												}))}
											/>
										</FormGroup>
									</div>
								) : null}

								{mediumId?.value != undefined ? (
									<div className='col-2'>
										<FormGroup id='standardDetailsId' label='Standard' isFloating>
											<SearchableSelect
												isFloating
												ariaLabel='Standard'
												onChange={selectStandard}
												value={standardDetailsId}
												list={standardData.map((data: any) => ({
													value: data.standardDetailsId,
													label: data.standardName,
												}))}
											/>
										</FormGroup>
									</div>
								) : null}

								{standardDetailsId?.value != undefined ? (
									<div className='col-2'>
										<FormGroup id='sectionId' label='Section' isFloating>
											<SearchableSelect
												isFloating
												ariaLabel='Section'
												onChange={selectSection}
												value={sectionDetailsId}
												list={sectionDetailsData.map((data: any) => ({
													value: data.sectionDetailsId,
													label: data.sectionName,
												}))}
											/>
										</FormGroup>
									</div>
								) : null}

								{sectionDetailsId?.value != undefined ? (
									<div className='col-3 mt-2'>
										<Button icon='ArrowDownward' color='primary' onClick={viewFeeList}>
											View
										</Button>
									</div>
								) : null}
							</div>
						</CardBody>
					</Card>
				</Collapse>
				<OpenCardComponent
					isOpenListCard={isOpenListCard}
					setIsOpenListCard={setIsOpenListCard}
				/>

				{dataSuccess ?
					<Card stretch data-tour='list' ref={componentRef}>
						<CardHeader borderSize={1}>
							<CardLabel icon='List' iconColor='info' className='col-lg-5'>
								<CardTitle tag='div' className='h5'>
									Fees List
								</CardTitle>
							</CardLabel>
							&nbsp;&nbsp;
							<ButtonGroup className='col-lg-3 d-print-none' color='primary'>
								<Icon className='mt-1' icon='Search' size='2x' color='primary' />
								<Input
									id='searchInput'
									type='search'
									placeholder='Search...'
									onChange={columnVisibilityForm.handleChange}
									value={columnVisibilityForm.values.searchInput}
								/>
							</ButtonGroup>
							{/* <CardActions className='d-print-none'>
							<Dropdown
								isOpen={columnVisibilityMenu}
								setIsOpen={setColumnVisibilityMenu}
								isButtonGroup>
								<DropdownToggle>
									<Button icon='FilterAlt' color='primary' isLight>
										Filter
									</Button>
								</DropdownToggle>
								<DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
									<DropdownItem>
										<div className='container py-2'>
											<form className='row g-3'>
												<div className='col-12'>
													<FormGroup>
														<h6>Select All</h6>
														<Checks
															id='available'
															type='switch'
															label='Select All Columns'
															checked={isChecked}
															ariaLabel='Available status'
														/>
													</FormGroup>
												</div>
												<div
													className='col-12'
													style={{
														maxHeight: '200px',
														overflowY: 'scroll',
													}}>
													<FormGroup>
														<h6>Columns</h6>
													</FormGroup>
												</div>
											</form>
										</div>
									</DropdownItem>
								</DropdownMenu>
							</Dropdown>
							<Dropdown isButtonGroup>
								<DropdownToggle>
									<Button color='primary' isLight icon='CloudDownload'>
										{' '}
										Export{' '}
									</Button>
								</DropdownToggle>
								<DropdownMenu isAlignmentEnd>
									<DropdownItem>
										<Button color='primary' isLight icon='CloudDownload'>
											{' '}
											Excel{' '}
										</Button>
									</DropdownItem>
									<DropdownItem>
										<Button color='primary' isLight icon='PictureAsPdf'>
											{' '}
											PDF
										</Button>
									</DropdownItem>
									<DropdownItem>
										<Button color='primary' isLight icon='CloudDownload'>
											{' '}
											CSV{' '}
										</Button>
									</DropdownItem>
									<DropdownItem>
										<Button
											color='primary'
											isLight
											icon='Print'
											onClick={handlePrint}>
											{' '}
											Print{' '}
										</Button>
									</DropdownItem>
								</DropdownMenu>
							</Dropdown>
						</CardActions> */}
						</CardHeader>
						<CardBody className='table-responsive' isScrollable>
							<table className='table table-modern table-hover text-nowrap'>
								<thead>
									<tr>
										<td />
										<th>S.No</th>
										<th>Admission No</th>
										<th>Student Name</th>
										<th>Medium</th>
										<th>Standard</th>
										<th>Amount</th>
									</tr>
								</thead>
								<tbody>
									{studentDetailsData != '' ? <>
										{filteredData1.map((i: any) => (
											<tr key={i.studentDetailsId}>

												<td>
													<Checks id={i.studentDetailsId} name='selectedList' value={i.studentDetailsId} checked={i.isSelect} onChange={(e: any) => updateStudentDetailsIdValue(e, i.studentDetailsId, i.admissionNo, i.uniRegNo, i.studentName)} />
												</td>
												<td>{i.sno}</td>
												<td>{i.admissionNo}</td>
												<td>{i.studentName}</td>
												<td>{i.medium}</td>
												<td>{i.standardName}</td>
												<td scope='col'>
													<div className="col-12">
														<FormGroup id='amount'>
															<Input className="w-200" placeholder="" value={i.amount} disabled={i.isEditable} onInput={(e: any) => updateRemarksValue(e.target.value, i.studentDetailsId)} />
														</FormGroup>
													</div>
												</td>
											</tr>
										))}</> : <NoDataMsg columnsCount={6} msg={noDataMsg} />}
								</tbody>
							</table>
						</CardBody>
					</Card>
					: null}
			</Page>
		</PageWrapper>
	);
};

export default BulkFeesCollection;
