import React, { useContext, useEffect, useRef, useState } from 'react'
import useSortableData from '../../../hooks/useSortableData';
import PaginationButtons, { dataPagination } from '../../PaginationButtons';
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from '../../bootstrap/Card';
import { useFormik } from 'formik';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Button, { ButtonGroup } from '../../bootstrap/Button';
import Icon from '../../icon/Icon';
import Page from '../../../layout/Page/Page';
import Collapse from '../../bootstrap/Collapse';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../../bootstrap/Dropdown';
import { downloadExcel, convertJsonToPdf, downloadFile } from '../../../services/ExportService';
import Checks, { ChecksGroup } from '../../bootstrap/forms/Checks';
import { useReactToPrint } from 'react-to-print';
import { toasts } from '../../../services/toast.service';
import useDarkMode from '../../../hooks/useDarkMode';
import { useNavigate } from 'react-router-dom';
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from '../../bootstrap/OffCanvas';
import AlertService from '../../../services/AlertService';
import Textarea from '../../bootstrap/forms/Textarea';
import { deleteStaffDetails, getProfileForStaff, getStaffDesignation, getStaffListByUserTypeId, updateStaffResigningDetails, updateStaffRole } from '../../../services/staff.service';
import { getColumnsForDataTable, getMedium, getStandardDetails, getUserType, pictNotLoading, profilePic, updateColumnsForDataTable, updateFilter } from '../../../services/common.service';
import { getLicenseKey } from '../../../services/application.settings';
import AuthContext from '../../../contexts/authContext';
import OpenCardComponent from '../../../common/components/OpenCardComponent';
import NoDataMsg from '../../../common/components/NoDataMsg';
import SearchableSelect from '../../../common/components/SearchableSelect';
import DeleteComponents from '../../../common/components/DeleteComponents';
import { showLoader } from '../../../services/loader.services';
import { getSectionDetails } from '../../../services/master.service';

const StaffList = () => {

    useEffect(() => {
        getUserTypeList()
        getStandardList()
        getMediumList()
    }, [])

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const navigate = useNavigate();
    const [staffDetailsId, setStaffDetailsId] = useState('')
    const [staffTypeId, setStaffTypeId] = useState<any>({ value: 0, label: 'Select All' })
    const [userTypeData, setUserTypeData] = useState<any>([])
    const [staffDetailsData, setStaffDetailsData] = useState([])
    const [staffProfileData, setStaffProfileData] = useState<any>([])
    const [dataSuccess, setDataSuccess] = useState(false)

    // ForModal
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(staffDetailsData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);
    const [isOpenListCard, setIsOpenListCard] = useState(true)
    const [columnVisibilityData, setColumnVisibilityData] = useState([]);
    const [allColumnsData, setAllColumnsData] = useState([]);
    const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
    const [columnDataById, setColumnDataById] = useState([])
    const componentRef = useRef(null);
    const [isChecked, setIsChecked] = useState(false)
    const { themeStatus, darkModeStatus } = useDarkMode();
    const [isLoader, setIsLoader] = useState(false)

    //Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);
    const [noDataMsg, setNoDataMsg] = useState('')

    const [updateRoleCanvas, setUpdateRoleCanvas] = useState(false)
    const [deleteStaffListCanvas, setDeleteStaffListCanvas] = useState(false)
    const [exitOrResignCanvas, setExitOrResignCanvas] = useState(false);

    const [relievingTypeId, setRelievingTypeId] = useState<any>('')
    const [relievingTypeIdValue, setRelievingTypeIdValue] = useState<any>('')
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);

    const [staffDesignationId, setStaffDesignationId] = useState<any>('')
    const [staffDesignationData, setStaffDesignationData] = useState<any>([])
    const [sectionDetailsId, setSectionDetailsId] = useState<any>('')
    const [designationShow, setDesignationShow] = useState(false)

    const [mediumId, setMediumId] = useState<any>('');
    const [standardDetailsId, setStandardDetailsId] = useState<any>('');
    const [mediumData, setMediumData] = useState<any>([]);
    const [standardData, setStandardData] = useState<any>([]);

    const deleteStaffListForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            remarks: '',
        },
        validate: (values) => { },

        //validateOnChange: false,
        onSubmit: () => { },
    })

    const updateRoleForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            staffTypeId: '',
            staffDesignationId: '',
        },
        validate: () => { },

        //validateOnChange: false,
        onSubmit: () => { addStaffRoleSubmit() },
    })

    const exitOrResignForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            resignedDate: '',
            remarks: '',
            terminationDate: '',
            terminationReason: '',
        },
        validate: (values) => {
            const errors: {
                resignedDate?: string;
            } = {};
            if (!values.resignedDate) {
                errors.resignedDate = 'Required'
            }
            return errors;
        },

        //validateOnChange: false,
        onSubmit: () => { exitOrResignSubmit() },
    })

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },

        //validateOnChange: false,
        onSubmit: () => { },
    });

    const selectRelievingType = (e: any) => {
        exitOrResignForm.resetForm();
        setRelievingTypeId('')
        setRelievingTypeIdValue('')
        let relievingTypeId = e
        setRelievingTypeId(relievingTypeId?.value)
        setRelievingTypeIdValue(relievingTypeId)
    }

    // Filter
    const filteredData = updateFilter(onCurrentPageData, columnVisibilityData, columnVisibilityForm);

    function viewStaffList() {
        setDataSuccess(false)
        setIsLoader(true);
        columnVisibilityForm.resetForm();
        getStaffListByUserType(staffTypeId?.value)
    }

    const selectUserType = (e: any) => {
        setDataSuccess(false)
        setIsOpenListCard(true)
        let staffTypeId = e
        setStaffTypeId(staffTypeId)
    }

    function onActivate(i: any) {
        // setStaffDesignationId('')
        if (i != undefined) {
            let selectedStaff = i
            setStaffDetailsId(selectedStaff.staffDetailsId)
            getStaffProfile(selectedStaff.staffDetailsId)
        }
    }

    function getStaffListByUserType(staffTypeId: any) {
        getStaffListByUserTypeId(userAccountId, staffTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.staffListByUserTypeId;
                    if (data != undefined) {
                        getColumnsForTable('getStaffListByUserTypeId', 'get');
                        setStaffDetailsData(data);
                        setIsLoader(false);
                        setIsOpenListCard(false);
                        setDataSuccess(true);
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }

                    } else {
                        setDataSuccess(false);
                        setIsOpenListCard(true);
                        setIsLoader(false);
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setDataSuccess(true);
                    getColumnsForTable('getStaffListByUserTypeId', 'get');
                    setStaffDetailsData([]);
                    setIsOpenListCard(false);
                    setIsLoader(false);
                    setNoDataMsg(response.data.message)
                    //toasts(response.data.message, "Error")
                } else {
                    setDataSuccess(false);
                    setIsOpenListCard(true);
                    setIsLoader(false);
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                setIsLoader(false);
                toasts(error, "Error")
            }
        )
    }

    function getUserTypeList() {
        getUserType(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.userType;
                    if (data != undefined) {
                        const userTypeData = [{ userTypeId: 0, userType: 'All Staff' }];
                        data.forEach((obj: { userTypeId: number; userType: string }) => {
                            let userType = userTypeData.push(obj);
                        });
                        setUserTypeData(userTypeData);

                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setUserTypeData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    const [roleTypeId, setRoleTypeId] = useState<any>('')
    const [fromStandardId, setFromStandardId] = useState<any>('')
    const [toStandardId, setToStandardId] = useState<any>('')

    const selectRoleChange = (e: any) => {
        setDesignationShow(false);
        setStaffDesignationId('')
        let roleTypeId = e
        setRoleTypeId(roleTypeId)
        if (roleTypeId?.value != undefined) {
            getStaffDesignationDetails(roleTypeId?.value)
        }
    }

    const selectStaffDesignation = (e: any) => {
        setMediumId('')
        setStandardDetailsId('')
        setSectionDetailsId('')
        setFromStandardId('')
        setToStandardId('')
        let staffDesignationId = e;
        setStaffDesignationId(staffDesignationId);
        if (staffDesignationId?.value == 4) {
            setFromStandardId({ value: 1, label: 'I' })
            setToStandardId({ value: 12, label: 'XII' })
        }
    }

    const selectMedium = (e: any) => {
        setStandardDetailsId('')
        setSectionDetailsId('')
        let mediumId = e;
        setMediumId(mediumId);
    };

    const selectStandard = (e: any) => {
        setSectionDetailsId('')
        let standardDetailsId = e;
        setStandardDetailsId(standardDetailsId);
        if (standardDetailsId?.value != undefined) {
            getSectionList(mediumId?.value, standardDetailsId?.value)
        }
    };

    const selectSection = (e: any) => {
        let standardDetailsId = e;
        setSectionDetailsId(standardDetailsId);
    };

    const selectFromStandard = (e: any) => {
        setToStandardId('')
        let fromStandardId = e;
        setFromStandardId(fromStandardId);
    };

    const selectToStandard = (e: any) => {
        let toStandardId = e;
        setToStandardId(toStandardId);
    };

    function getStaffProfile(staffDetailsId: number) {
        getProfileForStaff(staffDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.profileForStaff[0];
                    let profileData = response.data.data.profileForStaff;
                    if (data != undefined) {
                        setStaffProfileData(profileData)
                        setRoleTypeId({ value: data.userTypeId, label: data.userType })
                        setStaffDesignationId({ value: data.staffDesignationId, label: data.designationName })
                        setSectionDetailsId(data.sectionDetailsId > 0 ? { value: data.sectionDetailsId, label: data.sectionName } : null)
                        setMediumId(data.mediumId > 0 ? { value: data.mediumId, label: data.medium } : null)
                        setStandardDetailsId(data.fromStandardDetailsId > 0 ? { value: data.fromStandardDetailsId, label: data.fromStandardName } : null)
                        if (data.staffDesignationId != 0) {
                            getStaffDesignationDetails(data.userTypeId)
                        }
                        updateRoleForm.setValues({
                            staffTypeId: data['userTypeId'],
                            staffDesignationId: data['staffDesignationId'],
                        })
                        if (data['staffDesignationId'] == 4) {
                            setFromStandardId({ value: 1, label: 'I' })
                            setToStandardId({ value: 12, label: 'XII' })
                        } else {
                            setFromStandardId(data.fromStandardDetailsId > 0 ? { value: data.fromStandardDetailsId, label: data.fromStandardName } : null)
                            setToStandardId(data.toStandardDetailsId > 0 ? { value: data.toStandardDetailsId, label: data.toStandardName } : null)
                        }

                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStaffDesignationDetails(staffTypeId: any) {
        getStaffDesignation(staffTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.staffDesignation;
                    if (data != undefined) {
                        setStaffDesignationData(data);
                        setDesignationShow(true);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setStaffDesignationData([]);
                    setDesignationShow(false);
                } else {

                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStandardList() {
        getStandardDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.standardDetails;
                    if (data != undefined) {
                        setStandardData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setStandardData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getMediumList() {
        getMedium(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.medium;
                    if (data != undefined) {
                        setMediumData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setMediumData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    const [sectionDetailsData, setSectionDetailsData] = useState([])

    function getSectionList(mediumId: any, standardDetailsId: any) {
        getSectionDetails(mediumId, standardDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.sectionDetails;
                    if (data != undefined) {
                        setSectionDetailsData(data);

                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setSectionDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function setStaffRole() {
        return {
            staffDetailsId: staffDetailsId,
            userTypeId: roleTypeId?.value != undefined ? roleTypeId?.value : 0,
            staffDesignationId: staffDesignationId?.value != undefined ? staffDesignationId?.value : 0,
            fromStandardDetailsId: staffDesignationId?.value == 6 ? standardDetailsId?.value : fromStandardId?.value != undefined ? fromStandardId?.value : 0,
            toStandardDetailsId: staffDesignationId?.value == 6 ? standardDetailsId?.value : toStandardId?.value != undefined ? toStandardId?.value : 0,
            sectionDetailsId: sectionDetailsId?.value != undefined ? sectionDetailsId?.value : 0,
            userAccountId: userAccountId,
            licenseKey: getLicenseKey
        }
    }

    function addStaffRoleSubmit() {
        showLoader(true)
        if (updateRoleForm.isValid) {
            let addStaffRolePostData = setStaffRole();
            updateStaffRole(addStaffRolePostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        getStaffListByUserType(staffTypeId?.value)
                        closeAndReset();
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        showLoader(false)
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (updateRoleForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function setStaffResigningDetails() {
        return {
            staffDetailsId: staffDetailsId,
            resignedDate: exitOrResignForm.values.resignedDate ? exitOrResignForm.values.resignedDate : null,
            remarks: exitOrResignForm.values.remarks ? exitOrResignForm.values.remarks : null,
            terminationDate: exitOrResignForm.values.terminationDate ? exitOrResignForm.values.terminationDate : null,
            terminationReason: exitOrResignForm.values.terminationReason ? exitOrResignForm.values.terminationReason : null,
            userAccountId: userAccountId,
            licenseKey: getLicenseKey
        }
    }

    function exitOrResignSubmit() {
        showLoader(true)
        if (exitOrResignForm.isValid) {
            let exitOrResignPostData = setStaffResigningDetails();
            updateStaffResigningDetails(exitOrResignPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        getStaffListByUserType(0)
                        closeAndReset();
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        showLoader(false)
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (exitOrResignForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function setStaffDelete() {
        return {
            staffDetailsId: staffDetailsId,
            remarks: deleteStaffListForm.values.remarks,
            userAccountId: userAccountId,
            licenseKey: getLicenseKey
        }
    }

    function staffDeleteSubmit() {
        showLoader(true)
        if (deleteStaffListForm.isValid) {
            let staffDeletePostData = setStaffDelete();
            deleteStaffDetails(staffDeletePostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        setIsOpenListCard(true);
                        setDataSuccess(false)
                        closeAndReset();
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        showLoader(false)
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (deleteStaffListForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function getColumnsForTable(apiName: any, type: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setColumnVisibilityData(columnVisibilityData)

                        const allData = allColumnsData.map((item: any) => item.isDisplay);
                        const allColumns = allData.filter((item: any) => item == false);

                        if (type == 'get') {                           
                            setIsChecked(allColumns == '' ? true : false);
                        }
                        else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

                            for (let i = 0; i < nonDefaultValue.length; i++) {

                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: allColumns == '' ? 0 : 1
                                }
                                all.push(obj);
                            }
                            updateColumnsSubmit('', '', all)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )

    }

    function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {

        if (columnVisibilityForm.isValid) {

            setColumnDataById(isDisplay)
            const columnVisibilityPostData = {
                licenseKey: getLicenseKey,
                userAccountId: userAccountId,
                columns: type ? type : [
                    {
                        columnVisibilityId: columnVisibilityId,
                        isDisplay: isDisplay ? 0 : 1
                    }
                ]
            }

            updateColumnsForDataTable(columnVisibilityPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        getColumnsForTable('getStaffListByUserTypeId', 'get');
                    }
                    else if (data.success == false) {
                        // toasts(data.success, "Error")
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        toasts(errorDescription, "Error")
                    }
                }
                , (error) => {
                    toasts(error, "Error")
                }
            )
        } else if (columnVisibilityForm.isValid == false) {
            toasts("Please fill all the details!", "Error")
        }
    }

    // Export Configuration
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    function closeAndReset() {
        setUpdateRoleCanvas(false)
        setExitOrResignCanvas(false)
        setDeleteStaffListCanvas(false)
        exitOrResignForm.resetForm();
        updateRoleForm.resetForm();
        deleteStaffListForm.resetForm();
        setRoleTypeId('')
        setRelievingTypeId('')
        setRelievingTypeIdValue('')
        setIsDeleteOpen(false);
        setStaffDesignationData([]);
        setDesignationShow(false)
        setStaffDesignationId('')
        setRoleTypeId('')
    }

    function resetForNew() {
        exitOrResignForm.resetForm();
        setRelievingTypeId('')
        setRelievingTypeIdValue('')
        setStaffDesignationData([]);
        setDesignationShow(false)
        setStaffDesignationId('')
        setRoleTypeId('')
        setFromStandardId('')
        setToStandardId('')
    }

    const relievingTypeData = [{ relievingTypeId: 1, relievingType: 'Resign' }, { relievingTypeId: 2, relievingType: 'Termination' }]

    return (

        <PageWrapper title='Staff List'>
            <Page container='fluid'>
                <Collapse isOpen={isOpenListCard}>
                    <Card stretch data-tour='list' className='d-print-none'>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info'>
                                <CardTitle>
                                    Staff List
                                </CardTitle>
                            </CardLabel>
                            <CardActions className='float-end'>
                                {userAccountId == 2 || userAccountId == 3 || userAccountId == 4 || userAccountId == 5 ?
                                    null :
                                    <Button
                                        icon='Add'
                                        color='primary'
                                        isLight
                                        tag='a'
                                        to={`../academic/addStaff`}>
                                        Add New
                                    </Button>
                                }
                            </CardActions>
                        </CardHeader>
                        <CardBody>
                            <div className='row g-4'>
                                <div className='col-md-3'>
                                    <FormGroup id='staffTypeId' label='User Type' isFloating>
                                        <SearchableSelect isFloating
                                            ariaLabel='Type'
                                            onChange={selectUserType}
                                            value={staffTypeId}
                                            list={userTypeData.map((data: any) => (
                                                { value: data.userTypeId, label: data.userType }
                                            ))}
                                        />
                                    </FormGroup>
                                </div>
                                <div className='col-md-3'>
                                    <Button className='mt-2' icon='ArrowDownward' isDisable={staffTypeId?.value == undefined} color='primary' onClick={viewStaffList}>View</Button>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Collapse>

                <OpenCardComponent isOpenListCard={isOpenListCard} setIsOpenListCard={setIsOpenListCard} isLoader={isLoader} />

                {dataSuccess ?
                    <Card stretch data-tour='list' ref={componentRef}>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info' className="col-lg-6">
                                <CardTitle tag='div' className='h5'>Staff List</CardTitle>
                            </CardLabel>&nbsp;&nbsp;

                            <ButtonGroup className="col-lg-3 d-print-none" color='primary'>
                                <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                                <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
                            </ButtonGroup>

                            <CardActions className="d-print-none">
                                <Dropdown isOpen={columnVisibilityMenu} setIsOpen={setColumnVisibilityMenu} isButtonGroup>
                                    <DropdownToggle>
                                        <Button icon='FilterAlt' color='primary' isLight>Filter</Button>
                                    </DropdownToggle>
                                    <DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
                                        <DropdownItem>
                                            <div className='container py-2'>
                                                <form className='row g-3'>
                                                    <div className='col-12'>
                                                        <FormGroup>
                                                            <h6>Select All</h6>
                                                            <Checks id='available' type='switch' label='Select All Columns' onChange={() => getColumnsForTable('getStaffListByUserTypeId', 'post')} checked={isChecked} ariaLabel='Available status' />
                                                        </FormGroup>
                                                    </div>
                                                    <div className='col-12' style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                                                        <FormGroup>
                                                            <h6>Columns</h6>
                                                            <ChecksGroup>
                                                                {allColumnsData.map((i: any) => (
                                                                    <Checks key={i.columnVisibilityId} id='{i.columnVisibilityId}' label={i.columnName} onChange={() => updateColumnsSubmit(i.columnVisibilityId, i.isDisplay, '')} checked={i.isDisplay} disabled={i.isDefault == true} />
                                                                ))}
                                                            </ChecksGroup>
                                                        </FormGroup>
                                                    </div>
                                                </form>
                                            </div>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                <Dropdown isButtonGroup>
                                    <DropdownToggle>
                                        <Button color='primary' isLight icon='CloudDownload'> Export </Button>
                                    </DropdownToggle>
                                    <DropdownMenu isAlignmentEnd>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel("Staff List", staffDetailsData, columnVisibilityData)}> Excel </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(staffDetailsData, columnVisibilityData, "Staff List")}> PDF
                                            </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile("Staff List", staffDetailsData, columnVisibilityData)}> CSV </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='Print' onClick={handlePrint}> Print </Button>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>

                            </CardActions>
                        </CardHeader>

                        <CardBody className='table-responsive' isScrollable>
                            <table className='table table-modern table-hover text-nowrap'>
                                <thead>
                                    <tr>
                                        <th scope='col' onClick={() => requestSort('sno')}
                                            className='cursor-pointer text-decoration-underline'>S.No{''}
                                            <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                        </th>
                                        {columnVisibilityData.map((column: any) => (
                                            <th key={column.keyName} scope='col' onClick={() => requestSort(column.keyName)}
                                                className='cursor-pointer text-decoration-underline'>
                                                {column.columnName}
                                                <Icon size='lg' className={getClassNamesFor(column.keyName)} icon='FilterList' />
                                            </th>
                                        ))}
                                        <td className="d-print-none" />
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredData != "" ? <>{filteredData.map((rowData: any) => (<tr key={rowData.staffDetailsId}><td>{rowData.sno}</td>{columnVisibilityData.map((column: any) => ((column.isDisplay && rowData[column.keyName]) ? <td className='text-nowrap' key={`${rowData.staffDetailsId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />))}<td className='d-print-none' onClick={() => { onActivate(rowData) }} >
                                        {userAccountId == 2 || userAccountId == 3 || userAccountId == 4 || userAccountId == 5 ?
                                            <Button icon="PersonPin" isLight color="info" onClick={() => navigate(`../academic/staffProfile/${rowData.staffDetailsId}`)}> Profile</Button> :
                                            <Dropdown isButtonGroup><DropdownToggle><Button className='text-nowrap' color='dark' isLight icon='MoreHoriz' aria-label='MoreHoriz'>More</Button></DropdownToggle><DropdownMenu isAlignmentEnd className='card-body-scrollable' size='lg'><DropdownItem><Button icon="PersonPin" isLight color="info" onClick={() => navigate(`../academic/staffProfile/${staffDetailsId}`)}>Staff Profile</Button></DropdownItem><DropdownItem><Button icon="Receipt" isLight color="success" onClick={() => navigate(`../academic/updateStaff/${staffDetailsId}`)}>Update staff Details</Button></DropdownItem><DropdownItem><Button icon="Save" isLight color="primary" onClick={() => { setUpdateRoleCanvas(true); resetForNew(); }}>Update Role</Button></DropdownItem><DropdownItem><Button icon="Save" isLight color="primary" onClick={() => { setExitOrResignCanvas(true); resetForNew(); }}>Exit or Resign</Button></DropdownItem><DropdownItem><Button icon="Delete" isLight color="primary" onClick={() => { setDeleteStaffListCanvas(true) }}>Delete</Button></DropdownItem></DropdownMenu></Dropdown>}</td></tr>))}</> : <NoDataMsg columnsCount={columnVisibilityData.length + 2} msg={noDataMsg} />}</tbody></table>

                            {/* <DropdownItem><Button icon="EditLocation" isLight color="primary" onClick={() => navigate(`../academic/staffCertificateTracker/${staffDetailsId}`)}>Certificate Tracker</Button></DropdownItem> */}
                        </CardBody>

                        <PaginationButtons
                            className='d-print-none'
                            data={items}
                            label='items'
                            setCurrentPage={setCurrentPage}
                            currentPage={currentPage}
                            perPage={perPage}
                            setPerPage={setPerPage}
                        />
                    </Card>
                    : null}
            </Page>

            <OffCanvas
                setOpen={setUpdateRoleCanvas}
                isOpen={updateRoleCanvas}
                titleId='updateRoleCanvas'
                isBodyScroll
                placement='end' tag='form' noValidate onSubmit={updateRoleForm.handleSubmit}>
                <OffCanvasHeader setOpen={setUpdateRoleCanvas} onClick={closeAndReset}>
                    <OffCanvasTitle id="updateRoleCanvas">Update Role</OffCanvasTitle>
                </OffCanvasHeader>
                <OffCanvasBody>
                    <div className="row g-4">
                        <div className="col-lg-12">
                            <FormGroup id="staffTypeId" label="Role">
                                <SearchableSelect ariaLabel="" placeholder="Select Role Type"
                                    onChange={selectRoleChange}
                                    onBlur={updateRoleForm.handleBlur}
                                    value={roleTypeId}
                                    list={userTypeData.map((data: any) => (
                                        { value: data.userTypeId, label: data.userType }
                                    ))} />
                            </FormGroup>
                        </div>
                        {designationShow == true ?
                            <div className="col-lg-12">
                                <FormGroup id="staffDesignationId" label="Designation">
                                    <SearchableSelect ariaLabel="" placeholder="Select Designation"
                                        onChange={selectStaffDesignation}
                                        onBlur={updateRoleForm.handleBlur}
                                        value={staffDesignationId}
                                        list={staffDesignationData.map((data: any) => (
                                            { value: data.staffDesignationId, label: data.designationName }
                                        ))}
                                        isValid={updateRoleForm.isValid}
                                        isTouched={updateRoleForm.touched.staffDesignationId}
                                        invalidFeedback={updateRoleForm.errors.staffDesignationId} />
                                </FormGroup>
                            </div> : null}

                        {staffDesignationId?.value == 6 ?
                            <>
                                <div className='col-lg-12'>
                                    <FormGroup id='mediumId' label='Medium'>
                                        <SearchableSelect
                                            ariaLabel='Medium'
                                            onChange={selectMedium}
                                            value={mediumId}
                                            list={mediumData.map((data: any) => ({
                                                value: data.mediumId,
                                                label: data.medium,
                                            }))}
                                        />
                                    </FormGroup>
                                </div>

                                {mediumId ?
                                    <div className='col-lg-12'>
                                        <FormGroup
                                            id='standardDetailsId'
                                            label='Standard'
                                        >
                                            <SearchableSelect
                                                ariaLabel='Standard'
                                                onChange={selectStandard}
                                                value={standardDetailsId}
                                                list={standardData.map((data: any) => ({
                                                    value: data.standardDetailsId,
                                                    label: data.standardName,
                                                }))}
                                            />
                                        </FormGroup>
                                    </div> : null
                                }

                                {standardDetailsId ?
                                    <div className='col-lg-12'>
                                        <FormGroup
                                            id='sectionDetailsId'
                                            label='Section'
                                        >
                                            <SearchableSelect
                                                ariaLabel='Section'
                                                onChange={selectSection}
                                                value={sectionDetailsId}
                                                list={sectionDetailsData.map((data: any) => ({
                                                    value: data.sectionDetailsId,
                                                    label: data.sectionName,
                                                }))}
                                            />
                                        </FormGroup>
                                    </div> : null
                                }
                            </> : null}

                        {staffDesignationId?.value == 4 || staffDesignationId?.value == 5 || staffDesignationId?.value == 7 ?
                            <>
                                <div className='col-lg-12'>
                                    <FormGroup
                                        id='fromStandardId'
                                        label='Standard From'
                                    >
                                        <SearchableSelect
                                            ariaLabel='Standard From'
                                            onChange={selectFromStandard}
                                            value={fromStandardId}
                                            disabled={staffDesignationId?.value == 4 ? true : false}
                                            list={standardData.map((data: any) => ({
                                                value: data.standardDetailsId,
                                                label: data.standardName,
                                            }))} />
                                    </FormGroup>
                                </div>
                                <div className='col-lg-12'>
                                    <FormGroup
                                        id='toStandardId'
                                        label='Standard To'
                                    >
                                        <SearchableSelect
                                            ariaLabel='Standard To'
                                            onChange={selectToStandard}
                                            value={toStandardId}
                                            disabled={staffDesignationId?.value == 4 ? true : false}
                                            list={standardData.map((data: any) => ({
                                                value: data.standardDetailsId,
                                                label: data.standardName,
                                            }))}
                                        />
                                    </FormGroup>
                                </div>
                            </> : null
                        }
                    </div>

                </OffCanvasBody>
                <div className='row m-0'>
                    <div className='col-12 p-3 position-absolute top-95 start-50 translate-middle'>
                        <Button
                            color='info'
                            className='w-100' type="submit">
                            Save
                        </Button>
                    </div>
                </div>
            </OffCanvas>

            <OffCanvas
                setOpen={setExitOrResignCanvas}
                isOpen={exitOrResignCanvas}
                titleId='exitOrResignCanvas'
                isBodyScroll
                placement='end' tag='form' noValidate onSubmit={exitOrResignForm.handleSubmit}>
                <OffCanvasHeader setOpen={setExitOrResignCanvas} onClick={closeAndReset}>
                    <OffCanvasTitle id="exitOrResignCanvas">Exit / Resign</OffCanvasTitle>
                </OffCanvasHeader>
                <OffCanvasBody>
                    <div className='row g-4'>
                        {staffProfileData.map((staff: any) => (
                            <div className='col-12' key={staff.staffUserAccountId}>
                                <Card className={`bg-l${darkModeStatus ? 'o25' : '25'
                                    }-info bg-l${darkModeStatus ? 'o50' : '10'
                                    }-info-hover transition-base rounded-2 mb-4`}
                                    shadow='sm'>
                                    <CardBody>
                                        <div className='d-flex align-items-center pb-3'>
                                            <div className='flex-shrink-0 ms-2'>
                                                <img style={{ borderRadius: '50%' }} src={staff.profilePath != null ? staff.profilePath : profilePic(staff.genderId)} height={65} width={65} onError={(e: any) => pictNotLoading(e, staff.genderId)} />
                                            </div>
                                            <div className='flex-grow-1 ms-3'>
                                                <div className='fw-bold fs-4 text-dark mb-0'>
                                                    {staff.fullname}
                                                </div>
                                                <div className='text-muted'>
                                                    ( {staff.empNumber} )
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        ))}
                        <div className='col-lg-12'>
                            <FormGroup id="relievingTypeId" label="Select Relieving Type" >
                                <SearchableSelect placeholder="Select Relieving Type"
                                    ariaLabel='Relieving Type'
                                    onChange={selectRelievingType}
                                    value={relievingTypeIdValue}
                                    list={relievingTypeData.map((data: any) => (
                                        { value: data.relievingTypeId, label: data.relievingType }
                                    ))}
                                />
                            </FormGroup>
                        </div>
                    </div>
                    <div className="row g-4 mt-2">
                        {relievingTypeId == '1' ?
                            <>
                                <div className="col-lg-12">
                                    <FormGroup id='resignedDate' label='Resigned Date'>
                                        <Input type='date'
                                            onChange={exitOrResignForm.handleChange}
                                            value={exitOrResignForm.values.resignedDate}
                                            onBlur={exitOrResignForm.handleBlur}
                                            isValid={exitOrResignForm.isValid}
                                            isTouched={exitOrResignForm.touched.resignedDate}
                                            invalidFeedback={exitOrResignForm.errors.resignedDate} />
                                    </FormGroup>
                                </div>
                                <div className="col-lg-12">
                                    <FormGroup id='remarks' label='Remarks'>
                                        <Textarea
                                            placeholder="Enter Remarks"
                                            onChange={exitOrResignForm.handleChange}
                                            value={exitOrResignForm.values.remarks} />
                                    </FormGroup>
                                </div>
                            </> : null}
                        {relievingTypeId == '2' ?
                            <>
                                <div className="col-lg-12">
                                    <FormGroup id='terminationDate' label='Termination Date'>
                                        <Input type='date'
                                            onChange={exitOrResignForm.handleChange}
                                            value={exitOrResignForm.values.terminationDate} />
                                    </FormGroup>
                                </div>
                                <div className="col-lg-12">
                                    <FormGroup id='terminationReason' label='Termination Reason'>
                                        <Textarea
                                            placeholder="Enter Termination Reason"
                                            onChange={exitOrResignForm.handleChange}
                                            value={exitOrResignForm.values.terminationReason} />
                                    </FormGroup>
                                </div>
                            </> : null}
                    </div>

                </OffCanvasBody>
                <div className='row m-0'>
                    <div className='col-12 p-3 position-absolute top-95 start-50 translate-middle'>
                        <Button
                            color='info'
                            className='w-100' type="submit" isDisable={!exitOrResignForm.isValid && !!exitOrResignForm.submitCount}>
                            Save
                        </Button>
                    </div>
                </div>
            </OffCanvas>

            <OffCanvas
                setOpen={setDeleteStaffListCanvas}
                isOpen={deleteStaffListCanvas}
                titleId='deleteStaffListCanvas'
                isBodyScroll
                placement='end'
                tag="form" noValidate onSubmit={deleteStaffListForm.handleSubmit}>
                <OffCanvasHeader setOpen={setDeleteStaffListCanvas} onClick={closeAndReset}>
                    <OffCanvasTitle id="deleteStaffListCanvas">Delete Staff</OffCanvasTitle>
                </OffCanvasHeader>
                <OffCanvasBody>
                    <div className="row">
                        {staffProfileData.map((staff: any) => (
                            <div className='col-12' key={staff.staffUserAccountId}>
                                <Card className={`bg-l${darkModeStatus ? 'o25' : '25'
                                    }-info bg-l${darkModeStatus ? 'o50' : '10'
                                    }-info-hover transition-base rounded-2 mb-4`}
                                    shadow='sm'>
                                    <CardBody>
                                        <div className='d-flex align-items-center pb-3'>
                                            <div className='flex-shrink-0 ms-2'>
                                                <img style={{ borderRadius: '50%' }} src={staff.profilePath != null ? staff.profilePath : profilePic(staff.genderId)} height={65} width={65} onError={(e: any) => pictNotLoading(e, staff.genderId)} />
                                            </div>
                                            <div className='flex-grow-1 ms-3'>
                                                <div className='fw-bold fs-4 text-dark mb-0'>
                                                    {staff.fullname}
                                                </div>
                                                <div className='text-muted'>
                                                    ( {staff.empNumber} )
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        ))}
                        <div className="col-12 mt-3">
                            <FormGroup id='remarks' label="Remarks">
                                <Textarea
                                    placeholder="Enter Remarks"
                                    onChange={deleteStaffListForm.handleChange}
                                    value={deleteStaffListForm.values.remarks} />
                            </FormGroup>
                        </div>

                        <div className="d-flex justify-content-center position-absolute top-65 start-50 translate-middle">
                            <DeleteComponents isDeleteOpen={isDeleteOpen} setIsDeleteOpen={setIsDeleteOpen} deleteFunction={staffDeleteSubmit} />
                        </div>
                    </div>
                </OffCanvasBody>
                <div className='row m-0'>
                    <div className='col-12 p-3 position-absolute top-95 start-50 translate-middle'>
                        <Button
                            color='danger'
                            icon='Delete'
                            className='w-100' type="submit" onClick={() => setIsDeleteOpen(!isDeleteOpen)}>
                            Delete
                        </Button>
                    </div>
                </div>
            </OffCanvas>
            <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />

        </PageWrapper>

    );
}

export default StaffList