import { useFormik } from "formik";
import { useEffect, useContext, useState, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import NoDataMsg from "../../../common/components/NoDataMsg";
import AuthContext from "../../../contexts/authContext";
import useSortableData from "../../../hooks/useSortableData";
import Page from "../../../layout/Page/Page";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import { getLicenseKey } from "../../../services/application.settings";
import { updateFilter, getColumnsForDataTable, updateColumnsForDataTable } from "../../../services/common.service";
import { downloadExcel, convertJsonToPdf, downloadFile } from "../../../services/ExportService";
import { TableLoader } from "../../../services/loader.services";
import { getStudentFeeDetailsByFeeTypeId } from "../../../services/report.service";
import { toasts } from "../../../services/toast.service";
import Button, { ButtonGroup } from "../../bootstrap/Button";
import Card, { CardHeader, CardLabel, CardTitle, CardActions, CardBody } from "../../bootstrap/Card";
import Dropdown, { DropdownToggle, DropdownMenu, DropdownItem } from "../../bootstrap/Dropdown";
import Checks, { ChecksGroup } from "../../bootstrap/forms/Checks";
import FormGroup from "../../bootstrap/forms/FormGroup";
import Icon from "../../icon/Icon";
import PaginationButtons, { dataPagination } from "../../PaginationButtons";
import Input from "../../bootstrap/forms/Input";

function LabCoatFeeCollected() {

  useEffect(() => {
    getLabCoatFeeCollected();
  }, [])

  const { userAccountId, userTypeId } = useContext(AuthContext);
  const [labCoatFeeCollectedData, setLabCoatFeeCollectedData] = useState([])
  const [columnVisibilityData, setColumnVisibilityData] = useState([]);
  const [allColumnsData, setAllColumnsData] = useState([]);
  const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
  const [columnDataById, setColumnDataById] = useState([])
  const componentRef = useRef(null);
  const [isChecked, setIsChecked] = useState(false)

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const { items, requestSort, getClassNamesFor } = useSortableData(labCoatFeeCollectedData);
  const onCurrentPageData = dataPagination(items, currentPage, perPage);
  const [dataStatus, setDataStatus] = useState(false)

  const [noDataMsg, setNoDataMsg] = useState('')

  const columnVisibilityForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      searchInput: '',
      available: false,
    },
    validate: (values) => { },

    //validateOnChange: false,
    onSubmit: () => { },
  });

  // Filter
  const filteredData = updateFilter(onCurrentPageData, columnVisibilityData, columnVisibilityForm);

  function getLabCoatFeeCollected() {
    setDataStatus(false)
    getStudentFeeDetailsByFeeTypeId(
      (response) => {
        if (response.data.success) {
          let data = response.data.data.studentFeeDetailsByFeeTypeId;
          if (data != undefined) {
            setDataStatus(true)
            setLabCoatFeeCollectedData(data);
            getColumnsForTable('getStudentFeeDetailsByFeeTypeId', 'get');
            for (let i = 0; i < data.length; i++) {
              data[i].sno = i + 1;
            }
          } else {
            setDataStatus(true)
            toasts("Undefined Data", "Error")
          }
        }
        else if (response.data.success === false) {
          //toasts(response.data.message, "Error")
          getColumnsForTable('getStudentFeeDetailsByFeeTypeId', 'get');
          setLabCoatFeeCollectedData([]);
          setNoDataMsg(response.data.message)
          setDataStatus(true)
        } else {
          let errorCode = response.data.error[0].error_code;
          let errorDescription = response.data.error[0].error_description;
          setDataStatus(true)
          toasts(errorDescription, "Error")
        }
      }, error => {
        setDataStatus(true)
        toasts(error, "Error")
      }
    )
  }

  function getColumnsForTable(apiName: any, type: any) {
    getColumnsForDataTable(userTypeId, apiName,
      (response) => {
        if (response.data.success) {
          let data = response.data.data.columnsForDataTable;
          if (data != undefined) {
            let allColumnsData = data;
            setAllColumnsData(allColumnsData)
            let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
            setColumnVisibilityData(columnVisibilityData)

            const allData = allColumnsData.map((item: any) => item.isDisplay);
            const allColumns = allData.filter((item: any) => item == false);

            if (type == 'get') {
              setIsChecked(allColumns == '' ? true : false);
            }
            else if (type == 'post') {
              const all = [];
              let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

              for (let i = 0; i < nonDefaultValue.length; i++) {

                const obj = {
                  columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                  isDisplay: allColumns == '' ? 0 : 1
                }
                all.push(obj);
              }
              updateColumnsSubmit('', '', all)
            }
          } else {
            toasts("Undefined Data", "Error")
          }
        }
        else if (response.data.success === false) {
          toasts(response.data.message, "Error")
        } else {
          let errorCode = response.data.error[0].error_code;
          let errorDescription = response.data.error[0].error_description;
          toasts(errorDescription, "Error")
        }
      }, error => {
        toasts(error, "Error")
      }
    )

  }

  function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {

    if (columnVisibilityForm.isValid) {

      setColumnDataById(isDisplay)
      const columnVisibilityPostData = {
        licenseKey: getLicenseKey,
        userAccountId: userAccountId,
        columns: type ? type : [
          {
            columnVisibilityId: columnVisibilityId,
            isDisplay: isDisplay ? 0 : 1
          }
        ]
      }

      updateColumnsForDataTable(columnVisibilityPostData,
        (response) => {
          const data = response.data;
          if (data.success == true) {
            getColumnsForTable('getStudentFeeDetailsByFeeTypeId', 'get');
          }
          else if (data.success == false) {
            // toasts(data.success, "Error")
          }
          else {
            let errorCode = response.data.error[0].error_code;
            let errorDescription = response.data.error[0].error_description;
            toasts(errorDescription, "Error")
          }
        }
        , (error) => {
          toasts(error, "Error")
        }
      )
    } else if (columnVisibilityForm.isValid == false) {
      toasts("Please fill all the details!", "Error")
    }
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <PageWrapper title="Lab Coat Fee Collected" >
      <Page container='fluid'>
        <Card stretch data-tour='list' ref={componentRef}>
          <CardHeader borderSize={1}>
            <CardLabel icon='List' iconColor='info' className="col-lg-6">
              <CardTitle tag='div' className='h5'>Lab Coat Fee Collected Student List</CardTitle>
            </CardLabel>&nbsp;&nbsp;

            <ButtonGroup className="col-lg-3 justify-content-end d-print-none" color='primary'>
              <Icon className="mt-1" icon='Search' size='2x' color='primary' />
              <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
            </ButtonGroup>

            <CardActions className="d-print-none">
              <Dropdown isOpen={columnVisibilityMenu} setIsOpen={setColumnVisibilityMenu} isButtonGroup>
                <DropdownToggle>
                  <Button icon='FilterAlt' color='primary' isLight>Filter</Button>
                </DropdownToggle>
                <DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
                  <DropdownItem>
                    <div className='container py-2'>
                      <form className='row g-3'>
                        <div className='col-12'>
                          <FormGroup>
                            <h6>Select All</h6>
                            <Checks
                              id='available'
                              type='switch'
                              label='Select All Columns'
                              onChange={() => getColumnsForTable('getStudentFeeDetailsByFeeTypeId', 'post')}
                              checked={isChecked}
                              ariaLabel='Available status'
                            />
                          </FormGroup>
                        </div>
                        <div className='col-12' style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                          <FormGroup>
                            <h6>Columns</h6>
                            <ChecksGroup>
                              {allColumnsData.map((i: any) => (
                                <Checks key={i.columnVisibilityId} id='{i.columnVisibilityId}' label={i.columnName} onChange={() => updateColumnsSubmit(i.columnVisibilityId, i.isDisplay, '')} checked={i.isDisplay} disabled={i.isDefault == true} />
                              ))}
                            </ChecksGroup>
                          </FormGroup>
                        </div>
                      </form>
                    </div>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
              <Dropdown isButtonGroup>
                <DropdownToggle>
                  <Button color='primary' isLight icon='CloudDownload'> Export </Button>
                </DropdownToggle>
                <DropdownMenu isAlignmentEnd>
                  <DropdownItem>
                    <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel("LabCoatFeeCollectedReport", labCoatFeeCollectedData, columnVisibilityData)}> Excel </Button>
                  </DropdownItem>
                  <DropdownItem>
                    <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(labCoatFeeCollectedData, columnVisibilityData, "LabCoatFeeCollectedReport")} > PDF
                    </Button>
                  </DropdownItem>
                  <DropdownItem>
                    <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile("LabCoatFeeCollectedReport", labCoatFeeCollectedData, columnVisibilityData)}> CSV </Button>
                  </DropdownItem>
                  <DropdownItem>
                    <Button color='primary' isLight icon='Print' onClick={handlePrint} > Print </Button>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>

            </CardActions>
          </CardHeader>
          <CardBody className='table-responsive' isScrollable>
            {!dataStatus ?
              <TableLoader /> :
              <table className='table table-modern table-hover text-nowrap'>
                <thead>
                  <tr>
                    <th scope='col' onClick={() => requestSort('sno')}
                      className='cursor-pointer text-decoration-underline'>S.No{' '}
                      <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                    </th>
                    {columnVisibilityData.map((column: any) => (
                      <th key={column.keyName} scope='col' onClick={() => requestSort(column.keyName)}
                        className='cursor-pointer text-decoration-underline'>
                        {column.columnName}
                        <Icon size='lg' className={getClassNamesFor(column.keyName)} icon='FilterList' />
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {filteredData != "" ?
                    <>
                      {filteredData.map((rowData: any) => (
                        <tr key={rowData.studentFeeCollectionId}>
                          <td>{rowData.sno}</td>
                          {columnVisibilityData.map((column: any) => (
                            (column.isDisplay && rowData[column.keyName]) ?
                              <td key={`${rowData.studentFeeCollectionId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />
                          ))}
                        </tr>
                      ))
                      } </>
                    :
                    <NoDataMsg columnsCount={columnVisibilityData.length + 1} msg={noDataMsg} />}
                </tbody>
              </table>
            }
          </CardBody>
          <PaginationButtons
            className='d-print-none'
            data={items}
            label='items'
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            perPage={perPage}
            setPerPage={setPerPage}
          />
        </Card>
      </Page>
    </PageWrapper>
  )
}
export default LabCoatFeeCollected;