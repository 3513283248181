import React, { useContext, useEffect, useState } from 'react'
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper'
import Page from '../../../../layout/Page/Page'
import Collapse from '../../../bootstrap/Collapse'
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../../../bootstrap/Card'
import FormGroup from '../../../bootstrap/forms/FormGroup'
import Select from '../../../bootstrap/forms/Select'
import Button from '../../../bootstrap/Button'
import Icon from '../../../icon/Icon'
import { toasts } from '../../../../services/toast.service'
import Input from '../../../bootstrap/forms/Input'
import { useFormik } from 'formik'
import AlertService from '../../../../services/AlertService'
import { addVehicleTyreLink, getVehicleTyreDetails, getVehicleTyreLink } from '../../../../services/tyre.service'
import { getVehicleDetails } from '../../../../services/transport.service'
import { getLicenseKey } from '../../../../services/application.settings'
import AuthContext from '../../../../contexts/authContext'
import { showLoader } from '../../../../services/loader.services'
import OpenCardComponent from '../../../../common/components/OpenCardComponent'
import SearchableSelect from '../../../../common/components/SearchableSelect'

const VehicleTyreManagement = () => {
    useEffect(() => {
        getVehicleDetailsList();
        getVehicleTyreDetailsList()
    }, [])

    const [vehicleDetailsData, setVehicleDetailsData] = useState<any>([])

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const [vehicleDetailsId, setVehicleDetailsId] = useState<any>('')
    const [vehicleTyreDetailsId_FR, setVehicleTyreDetailsId_FR] = useState<any>('')
    const [vehicleTyreDetailsId_FL, setVehicleTyreDetailsId_FL] = useState<any>('')
    const [vehicleTyreDetailsId_BFR, setVehicleTyreDetailsId_BFR] = useState<any>('')
    const [vehicleTyreDetailsId_BBL, setVehicleTyreDetailsId_BBL] = useState<any>('')
    const [vehicleTyreDetailsId_BBR, setVehicleTyreDetailsId_BBR] = useState<any>('')
    const [vehicleTyreDetailsId_BFL, setVehicleTyreDetailsId_BFL] = useState<any>('')
    const [vehicleTyreDetailsData, setVehicleTyreDetailsData] = useState<any>([]);
    const [vehicleTyreLinkData, setVehicleTyreLinkData] = useState([]);

    const [isOpenListCard, setIsOpenListCard] = useState(true)
    const [dataSuccess, setDataSuccess] = useState(false)

    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);

    const [isLoader, setIsLoader] = useState(false)

    const vehicleTyreManagementForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            vehicleDetailsId: "",
            vehicleTyreDetailsId_FR: '',
            vehicleTyreDetailsId_FL: '',
            vehicleTyreDetailsId_BFL: '',
            vehicleTyreDetailsId_BFR: '',
            vehicleTyreDetailsId_BBL: '',
            vehicleTyreDetailsId_BBR: '',
            fixedDate_FL: "",
            fixedDate_FR: "",
            fixedDate_BFL: "",
            fixedDate_BFR: "",
            fixedDate_BBL: "",
            fixedDate_BBR: "",
            remarks: "",
        },
        validate: (values) => {
            const errors: {
                vehicleDetailsId?: string;
                vehicleTyreDetailsId_FR?: string;
                vehicleTyreDetailsId_FL?: string;
                vehicleTyreDetailsId_BFL?: string;
                vehicleTyreDetailsId_BFR?: string;
                vehicleTyreDetailsId_BBL?: string;
                vehicleTyreDetailsId_BBR?: string;
                fixedDate_FL?: string;
                fixedDate_FR?: string;
                fixedDate_BFL?: string;
                fixedDate_BFR?: string;
                fixedDate_BBL?: string;
                fixedDate_BBR?: string;
                remarks?: string;
            } = {};
            if (!vehicleDetailsId) {
                errors.vehicleDetailsId = 'Required'
            }
            if (!vehicleTyreDetailsId_FR) {
                errors.vehicleTyreDetailsId_FR = 'Required'
            }
            if (!vehicleTyreDetailsId_FL) {
                errors.vehicleTyreDetailsId_FL = 'Required'
            }
            if (!vehicleTyreDetailsId_BFL) {
                errors.vehicleTyreDetailsId_BFL = 'Required'
            }
            if (!vehicleTyreDetailsId_BFR) {
                errors.vehicleTyreDetailsId_BFR = 'Required'
            }
            if (!vehicleTyreDetailsId_BBL) {
                errors.vehicleTyreDetailsId_BBL = 'Required'
            }
            if (!vehicleTyreDetailsId_BBR) {
                errors.vehicleTyreDetailsId_BBR = 'Required'
            }
            if (!values.fixedDate_FL) {
                errors.fixedDate_FL = 'Required'
            }
            if (!values.fixedDate_FR) {
                errors.fixedDate_FR = 'Required'
            }
            if (!values.fixedDate_BFL) {
                errors.fixedDate_BFL = 'Required'
            }
            if (!values.fixedDate_BFR) {
                errors.fixedDate_BFR = 'Required'
            }
            if (!values.fixedDate_BBL) {
                errors.fixedDate_BBL = 'Required'
            }
            if (!values.fixedDate_BBR) {
                errors.fixedDate_BBR = 'Required'
            }
            if (!values.remarks) {
                errors.remarks = 'Required'
            }
            return errors;

        },

        //validateOnChange: false,
        onSubmit: () => { vehicleTyreManagementSubmit(); },
    })

    const selectVehicle = (e: any) => {
        let vehicleDetailsId = e
        setVehicleDetailsId(vehicleDetailsId)
    }

    const selectVehicleTyreDetailsId_FR = (e: any) => {
        let vehicleTyreDetailsId_FR = e
        setVehicleTyreDetailsId_FR(vehicleTyreDetailsId_FR)
    }

    const selectVehicleTyreDetailsId_FL = (e: any) => {
        let vehicleTyreDetailsId_FL = e
        setVehicleTyreDetailsId_FL(vehicleTyreDetailsId_FL)
    }

    const selectVehicleTyreDetailsId_BFL = (e: any) => {
        let vehicleTyreDetailsId_BFL = e
        setVehicleTyreDetailsId_BFL(vehicleTyreDetailsId_BFL)
    }

    const selectVehicleTyreDetailsId_BBR = (e: any) => {
        let vehicleTyreDetailsId_BBR = e
        setVehicleTyreDetailsId_BBR(vehicleTyreDetailsId_BBR)
    }

    const selectVehicleTyreDetailsId_BBL = (e: any) => {
        let vehicleTyreDetailsId_BBL = e
        setVehicleTyreDetailsId_BBL(vehicleTyreDetailsId_BBL)
    }

    const selectVehicleTyreDetailsId_BFR = (e: any) => {
        let vehicleTyreDetailsId_BFR = e
        setVehicleTyreDetailsId_BFR(vehicleTyreDetailsId_BFR)
    }

    function viewTyreManagementList() {
        setDataSuccess(false)
        setIsLoader(true);
        getVehicleTyreLinkList(vehicleDetailsId?.value);
    }

    function getVehicleDetailsList() {
        getVehicleDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.vehicleDetails;
                    if (data != undefined) {
                        setVehicleDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setVehicleDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getVehicleTyreDetailsList() {
        getVehicleTyreDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.vehicleTyreDetails;
                    if (data != undefined) {
                        setVehicleTyreDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setVehicleTyreDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function setTyreLinkDetails() {
        return ({
            vehicleDetailsId: vehicleDetailsId?.value,
            vehicleTyreDetailsId_FR: vehicleTyreDetailsId_FR?.value,
            vehicleTyreDetailsId_FL: vehicleTyreDetailsId_FL?.value,
            vehicleTyreDetailsId_BFL: vehicleTyreDetailsId_BFL?.value,
            vehicleTyreDetailsId_BFR: vehicleTyreDetailsId_BFR?.value,
            vehicleTyreDetailsId_BBL: vehicleTyreDetailsId_BBL?.value,
            vehicleTyreDetailsId_BBR: vehicleTyreDetailsId_BBR?.value,
            fixedDate_FL: vehicleTyreManagementForm.values.fixedDate_FL,
            fixedDate_FR: vehicleTyreManagementForm.values.fixedDate_FR,
            fixedDate_BFL: vehicleTyreManagementForm.values.fixedDate_BFL,
            fixedDate_BFR: vehicleTyreManagementForm.values.fixedDate_BFR,
            fixedDate_BBL: vehicleTyreManagementForm.values.fixedDate_BBL,
            fixedDate_BBR: vehicleTyreManagementForm.values.fixedDate_BBR,
            remarks: vehicleTyreManagementForm.values.remarks,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId
        })
    }

    function vehicleTyreManagementSubmit() {
        showLoader(true)
        if (vehicleTyreManagementForm.isValid) {
            let addVehicleTyreLinkPost = setTyreLinkDetails()
            addVehicleTyreLink(addVehicleTyreLinkPost,
                (response) => {
                    showLoader(false)
                    const data = response.data;
                    if (data.success == true) {
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        vehicleTyreManagementForm.resetForm();
                        setDataSuccess(false);
                        setIsOpenListCard(true);
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        showLoader(false)
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (vehicleTyreManagementForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function getVehicleTyreLinkList(vehicleDetailsId: any) {
        setIsLoader(true);
        getVehicleTyreLink(vehicleDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.vehicleTyreLink[0];
                    if (data != undefined) {
                        setIsLoader(false);
                        setVehicleTyreLinkData(data);
                        setDataSuccess(true);
                        setIsOpenListCard(false);
                        setVehicleDetailsId({ value: data.vehicleDetailsId, label: data.vehicleId })
                        setVehicleTyreDetailsId_FR({ value: data.vehicleTyreDetailsId, label: data.vehicleTyreNo_FR })
                        setVehicleTyreDetailsId_FL({ value: data.vehicleTyreDetailsId, label: data.vehicleTyreNo_FL })
                        setVehicleTyreDetailsId_BFR({ value: data.vehicleTyreDetailsId, label: data.vehicleTyreNo_BFR })
                        setVehicleTyreDetailsId_BFL({ value: data.vehicleTyreDetailsId, label: data.vehicleTyreNo_BFL })
                        setVehicleTyreDetailsId_BBL({ value: data.vehicleTyreDetailsId, label: data.vehicleTyreNo_BBL })
                        setVehicleTyreDetailsId_BBR({ value: data.vehicleTyreDetailsId, label: data.vehicleTyreNo_BBR })
                        vehicleTyreManagementForm.setValues({
                            vehicleDetailsId: vehicleDetailsId?.value,
                            vehicleTyreDetailsId_FR: vehicleTyreDetailsId_FR?.value,
                            vehicleTyreDetailsId_FL: vehicleTyreDetailsId_FL?.value,
                            vehicleTyreDetailsId_BFL: vehicleTyreDetailsId_BFL?.value,
                            vehicleTyreDetailsId_BFR: vehicleTyreDetailsId_BFR?.value,
                            vehicleTyreDetailsId_BBL: vehicleTyreDetailsId_BBL?.value,
                            vehicleTyreDetailsId_BBR: vehicleTyreDetailsId_BBR?.value,
                            fixedDate_FL: data.fixedDate_FL,
                            fixedDate_FR: data.fixedDate_FR,
                            fixedDate_BFL: data.fixedDate_BFL,
                            fixedDate_BFR: data.fixedDate_BFR,
                            fixedDate_BBL: data.fixedDate_BBL,
                            fixedDate_BBR: data.fixedDate_BBR,
                            remarks: data.remarks,
                        });
                    } else {
                        setIsLoader(false);
                        //toasts("Undefined Data", "Error")
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        setDataSuccess(false);
                        setIsOpenListCard(true);
                    }
                }
                else if (response.data.success === false) {
                    setIsLoader(false);
                    // toasts(response.data.message, "Error")
                    setAlertStatus({ message: 'Vehicle Tyre Link Not Created, Please Create', type: "error" });
                    setIsOpen(true);
                    setDataSuccess(true);
                    setIsOpenListCard(false);
                    //setVehicleDetailsId({value: data.vehicleDetailsId, label: data.vehicleId })
                    setVehicleTyreDetailsId_FR('')
                    setVehicleTyreDetailsId_FL('')
                    setVehicleTyreDetailsId_BFR('')
                    setVehicleTyreDetailsId_BFL('')
                    setVehicleTyreDetailsId_BBL('')
                    setVehicleTyreDetailsId_BBR('')

                    vehicleTyreManagementForm.setValues({
                        vehicleDetailsId: vehicleDetailsId?.value,
                        vehicleTyreDetailsId_FR: '',
                        vehicleTyreDetailsId_FL: '',
                        vehicleTyreDetailsId_BFL: '',
                        vehicleTyreDetailsId_BFR: '',
                        vehicleTyreDetailsId_BBL: '',
                        vehicleTyreDetailsId_BBR: '',
                        fixedDate_FL: '',
                        fixedDate_FR: '',
                        fixedDate_BFL: '',
                        fixedDate_BFR: '',
                        fixedDate_BBL: '',
                        fixedDate_BBR: '',
                        remarks: '',
                    });
                } else {
                    setIsLoader(false);
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    //toasts(errorDescription, "Error")
                    setDataSuccess(false);
                    setIsOpenListCard(true);
                    setAlertStatus({ message: 'Vehicle Tyre Link Not Created, Please Create', type: "error" });
                    setIsOpen(true);
                }
            }, error => {
                setIsLoader(false);
                toasts(error, "Error")
            }
        )
    }

    return (
        <>
            <PageWrapper title='Vehicle Tyre Management'>
                <Page container='fluid'>
                    <Collapse isOpen={isOpenListCard}>
                        <Card>
                            <CardHeader borderSize={1}>
                                <CardLabel icon='list' iconColor='primary'>
                                    <CardTitle>
                                        Vehicle Tyre Link
                                    </CardTitle>
                                </CardLabel>
                            </CardHeader>
                            <CardBody>
                                <div className='row'>
                                    <div className='col-4'>
                                        <FormGroup id='vehicleDetailsId' label='Vehicle' isFloating >
                                            <SearchableSelect isFloating placeholder="Select Vehicle" ariaLabel={''}
                                                onChange={selectVehicle}
                                                value={vehicleDetailsId}
                                                list={vehicleDetailsData.map((data: any) => (
                                                    { value: data.vehicleDetailsId, label: data.vehicleId }
                                                ))}
                                                onBlur={vehicleTyreManagementForm.handleBlur}
                                                isValid={vehicleTyreManagementForm.isValid}
                                                isTouched={vehicleTyreManagementForm.touched.vehicleDetailsId}
                                                invalidFeedback={vehicleTyreManagementForm.errors.vehicleDetailsId} />
                                        </FormGroup>
                                    </div>
                                    <div className='col-3 mt-2'>
                                        <Button icon='ArrowDownward' color='primary' onClick={viewTyreManagementList}>View</Button>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Collapse>

                    <OpenCardComponent isOpenListCard={isOpenListCard} setIsOpenListCard={setIsOpenListCard} isLoader={isLoader} />

                    {dataSuccess ?
                        <Card tag='form' noValidate onSubmit={vehicleTyreManagementForm.handleSubmit}>
                            <CardBody>
                                <div className="row mt-4">
                                    <div className="col-lg-1"></div>
                                    <div className="col-lg-10">
                                        <table className='table table-modern table-hover text-nowrap'>
                                            <thead className='table-info'>
                                                <tr>
                                                    <th scope='col'>TYRE PLACEMENT</th>
                                                    <th scope='col'>TYRE</th>
                                                    <th scope='col'>FIXED DATE</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td scope='row'>Front Right</td>
                                                    <td><SearchableSelect id='vehicleTyreDetailsId_FR' ariaLabel='' placeholder='Select Tyre Link'
                                                        onChange={selectVehicleTyreDetailsId_FR}
                                                        value={vehicleTyreDetailsId_FR}
                                                        list={vehicleTyreDetailsData.map((data: any) => (
                                                            { value: data.vehicleTyreDetailsId, label: data.tyreId }
                                                        ))}
                                                        onBlur={vehicleTyreManagementForm.handleBlur}
                                                        isValid={vehicleTyreManagementForm.isValid}
                                                        isTouched={vehicleTyreManagementForm.touched.vehicleTyreDetailsId_FR}
                                                        invalidFeedback={vehicleTyreManagementForm.errors.vehicleTyreDetailsId_FR} /></td>
                                                    <td><Input id='fixedDate_FR' type='date'
                                                        onChange={vehicleTyreManagementForm.handleChange}
                                                        value={vehicleTyreManagementForm.values.fixedDate_FR}
                                                        onBlur={vehicleTyreManagementForm.handleBlur}
                                                        isValid={vehicleTyreManagementForm.isValid}
                                                        isTouched={vehicleTyreManagementForm.touched.fixedDate_FR}
                                                        invalidFeedback={vehicleTyreManagementForm.errors.fixedDate_FR} /></td>
                                                </tr>
                                                <tr>
                                                    <td scope='row'>Front Left</td>
                                                    <td><SearchableSelect id='vehicleTyreDetailsId_FL' ariaLabel='' placeholder='Select Tyre Link'
                                                        onChange={selectVehicleTyreDetailsId_FL}
                                                        value={vehicleTyreDetailsId_FL}
                                                        list={vehicleTyreDetailsData.map((data: any) => (
                                                            { value: data.vehicleTyreDetailsId, label: data.tyreId }
                                                        ))}
                                                        onBlur={vehicleTyreManagementForm.handleBlur}
                                                        isValid={vehicleTyreManagementForm.isValid}
                                                        isTouched={vehicleTyreManagementForm.touched.vehicleTyreDetailsId_FL}
                                                        invalidFeedback={vehicleTyreManagementForm.errors.vehicleTyreDetailsId_FL} /></td>
                                                    <td><Input id='fixedDate_FL' type='date'
                                                        onChange={vehicleTyreManagementForm.handleChange}
                                                        value={vehicleTyreManagementForm.values.fixedDate_FL}
                                                        onBlur={vehicleTyreManagementForm.handleBlur}
                                                        isValid={vehicleTyreManagementForm.isValid}
                                                        isTouched={vehicleTyreManagementForm.touched.fixedDate_FL}
                                                        invalidFeedback={vehicleTyreManagementForm.errors.fixedDate_FL} /></td>
                                                </tr>
                                                <tr>
                                                    <td scope='row'>Back Front Right</td>
                                                    <td><SearchableSelect id='vehicleTyreDetailsId_BFL' ariaLabel='' placeholder='Select Tyre Link'
                                                        onChange={selectVehicleTyreDetailsId_BFL}
                                                        value={vehicleTyreDetailsId_BFL}
                                                        list={vehicleTyreDetailsData.map((data: any) => (
                                                            { value: data.vehicleTyreDetailsId, label: data.tyreId }
                                                        ))}
                                                        onBlur={vehicleTyreManagementForm.handleBlur}
                                                        isValid={vehicleTyreManagementForm.isValid}
                                                        isTouched={vehicleTyreManagementForm.touched.vehicleTyreDetailsId_BFL}
                                                        invalidFeedback={vehicleTyreManagementForm.errors.vehicleTyreDetailsId_BFL} /></td>
                                                    <td><Input id='fixedDate_BFL' type='date'
                                                        onChange={vehicleTyreManagementForm.handleChange}
                                                        value={vehicleTyreManagementForm.values.fixedDate_BFL}
                                                        onBlur={vehicleTyreManagementForm.handleBlur}
                                                        isValid={vehicleTyreManagementForm.isValid}
                                                        isTouched={vehicleTyreManagementForm.touched.fixedDate_BFL}
                                                        invalidFeedback={vehicleTyreManagementForm.errors.fixedDate_BFL} /></td>
                                                </tr>
                                                <tr>
                                                    <td scope='row'>Back Front Left</td>
                                                    <td><SearchableSelect id='vehicleTyreDetailsId_BFR' ariaLabel='' placeholder='Select Tyre Link'
                                                        onChange={selectVehicleTyreDetailsId_BFR}
                                                        value={vehicleTyreDetailsId_BFR}
                                                        list={vehicleTyreDetailsData.map((data: any) => (
                                                            { value: data.vehicleTyreDetailsId, label: data.tyreId }
                                                        ))}
                                                        onBlur={vehicleTyreManagementForm.handleBlur}
                                                        isValid={vehicleTyreManagementForm.isValid}
                                                        isTouched={vehicleTyreManagementForm.touched.vehicleTyreDetailsId_BFR}
                                                        invalidFeedback={vehicleTyreManagementForm.errors.vehicleTyreDetailsId_BFR} /></td>
                                                    <td><Input id='fixedDate_BFR' type='date'
                                                        onChange={vehicleTyreManagementForm.handleChange}
                                                        value={vehicleTyreManagementForm.values.fixedDate_BFR}
                                                        onBlur={vehicleTyreManagementForm.handleBlur}
                                                        isValid={vehicleTyreManagementForm.isValid}
                                                        isTouched={vehicleTyreManagementForm.touched.fixedDate_BFR}
                                                        invalidFeedback={vehicleTyreManagementForm.errors.fixedDate_BFR} /></td>
                                                </tr>
                                                <tr>
                                                    <td scope='row'>Back Rear Right</td>
                                                    <td><SearchableSelect id='vehicleTyreDetailsId_BBL' ariaLabel='' placeholder='Select Tyre Link'
                                                        onChange={selectVehicleTyreDetailsId_BBL}
                                                        value={vehicleTyreDetailsId_BBL}
                                                        list={vehicleTyreDetailsData.map((data: any) => (
                                                            { value: data.vehicleTyreDetailsId, label: data.tyreId }
                                                        ))}
                                                        onBlur={vehicleTyreManagementForm.handleBlur}
                                                        isValid={vehicleTyreManagementForm.isValid}
                                                        isTouched={vehicleTyreManagementForm.touched.vehicleTyreDetailsId_BBL}
                                                        invalidFeedback={vehicleTyreManagementForm.errors.vehicleTyreDetailsId_BBL} /></td>
                                                    <td><Input id='fixedDate_BBL' type='date'
                                                        onChange={vehicleTyreManagementForm.handleChange}
                                                        value={vehicleTyreManagementForm.values.fixedDate_BBL}
                                                        onBlur={vehicleTyreManagementForm.handleBlur}
                                                        isValid={vehicleTyreManagementForm.isValid}
                                                        isTouched={vehicleTyreManagementForm.touched.fixedDate_BBL}
                                                        invalidFeedback={vehicleTyreManagementForm.errors.fixedDate_BBL} /></td>
                                                </tr>
                                                <tr>
                                                    <td scope='row'>Back Rear Left</td>
                                                    <td><SearchableSelect id='vehicleTyreDetailsId_BBR' ariaLabel='' placeholder='Select Tyre Link'
                                                        onChange={selectVehicleTyreDetailsId_BBR}
                                                        value={vehicleTyreDetailsId_BBR}
                                                        list={vehicleTyreDetailsData.map((data: any) => (
                                                            { value: data.vehicleTyreDetailsId, label: data.tyreId }
                                                        ))}
                                                        onBlur={vehicleTyreManagementForm.handleBlur}
                                                        isValid={vehicleTyreManagementForm.isValid}
                                                        isTouched={vehicleTyreManagementForm.touched.vehicleTyreDetailsId_BBR}
                                                        invalidFeedback={vehicleTyreManagementForm.errors.vehicleTyreDetailsId_BBR} /></td>
                                                    <td><Input id='fixedDate_BBR' type='date'
                                                        onChange={vehicleTyreManagementForm.handleChange}
                                                        value={vehicleTyreManagementForm.values.fixedDate_BBR}
                                                        onBlur={vehicleTyreManagementForm.handleBlur}
                                                        isValid={vehicleTyreManagementForm.isValid}
                                                        isTouched={vehicleTyreManagementForm.touched.fixedDate_BBR}
                                                        invalidFeedback={vehicleTyreManagementForm.errors.fixedDate_BBR} /></td>
                                                </tr>
                                                <tr>
                                                    <td scope='row'>Remarks</td>
                                                    <td colSpan={2}><Input id='remarks' type='text'
                                                        onChange={vehicleTyreManagementForm.handleChange}
                                                        value={vehicleTyreManagementForm.values.remarks}
                                                        onBlur={vehicleTyreManagementForm.handleBlur}
                                                        isValid={vehicleTyreManagementForm.isValid}
                                                        isTouched={vehicleTyreManagementForm.touched.remarks}
                                                        invalidFeedback={vehicleTyreManagementForm.errors.remarks} /></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                                <div className='row justify-content-center'>
                                    <div className='col-lg-3 justify-content-evenly'>
                                        <FormGroup>
                                            <Button icon='Save' color='info' type='submit'>Save</Button>
                                        </FormGroup>
                                    </div>
                                </div>
                            </CardBody>
                        </Card> : null
                    }
                </Page>
                <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
            </PageWrapper>
        </>
    )
}
export default VehicleTyreManagement