import React, { useContext, useEffect, useRef, useState } from 'react'
import Button from '../../bootstrap/Button';
import Page from '../../../layout/Page/Page';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../../bootstrap/Card';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Select from '../../bootstrap/forms/Select';
import { toasts } from '../../../services/toast.service';
import Checks from '../../bootstrap/forms/Checks';
import Icon from '../../icon/Icon';
import Collapse from '../../bootstrap/Collapse';
import AlertService from '../../../services/AlertService';
import { assignScreenForStaff, getAllScreens, getAssignedScreenByUserAccountId } from '../../../services/screen.service';
import { getUserType } from '../../../services/common.service';
import OpenCardComponent from '../../../common/components/OpenCardComponent';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import AuthContext from '../../../contexts/authContext';
import { getLicenseKey } from '../../../services/application.settings';
import { getStaffListByUserTypeId } from '../../../services/staff.service';
import { showLoader } from '../../../services/loader.services';
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from '../../../layout/SubHeader/SubHeader';
import SearchableSelect from '../../../common/components/SearchableSelect';

const AssignScreensForUser = () => {

    useEffect(() => {
        getUserTypeList()
    }, [])

    const { userAccountId } = useContext(AuthContext);
    const [userTypeId, setUserTypeId] = useState<any>('')
    const [staffDetailsId, setStaffDetailsId] = useState<any>('')

    const [userTypeData, setUserTypeData] = useState<any>([])
    const [staffData, setStaffData] = useState<any>([])
    const [allScreensData, setAllScreensData] = useState<any>([])
    const [assignScreenData, setAssignScreenData] = useState([])
    const [checkArray, setCheckArray] = useState<any>([])
    const [data, setData] = useState<any>([])
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);

    const componentRef = useRef(null);
    const [isOpenListCard, setIsOpenListCard] = useState(true);
    const [dataStatus, setDataStatus] = useState(false);
    const [isLoader, setIsLoader] = useState(false)

    const selectUserType = (e: any) => {
        setStaffDetailsId('')
        let userTypeId = e
        setUserTypeId(userTypeId)
        if(userTypeId?.value != undefined){
        getStaffListByUserType(userTypeId?.value)
        }
    }

    const selectStaff = (e: any) => {
        let staffDetailsId = e
        setStaffDetailsId(staffDetailsId)
    }

    const viewScreenList = () => {
        setIsLoader(true);
        setDataStatus(false)
        getAllScreen()
        setCheckArray([])
    }

    function onSelect(event: any, screenId: any) {

        assignScreenData.forEach((screenGroupMain: any) => {

            let screenGroupMainData = screenGroupMain.screenGroup

            screenGroupMainData.forEach((screenGroup: any) => {

                let screenGroupData = screenGroup.screens

                const newData: any = [];

                screenGroupData.forEach((item: any) => {

                    if (item.screenId == screenId) {
                        item.isChecked = event.target.checked
                    }
                    newData.push(item)
                })
                setData(newData)
            })
            generateMenu(assignScreenData)
        });

        if (event.target.checked == true) {
            checkArray.push(screenId);
        }
        else if (event.target.checked == false) {
            let unCheckArrayId = checkArray.indexOf(Number(screenId))
            checkArray.splice(unCheckArrayId, 1);
        }
    }

    function selectAllScreenGroupMain(allScreens: any, e: any) {
        if (e == true) {
            let screenGroupMainData = allScreens.screenGroup

            screenGroupMainData.forEach((screenGroup: any) => {

                let screenGroupData = screenGroup.screens

                const newData: any = [];

                screenGroupData.forEach((item: any) => {

                    if (item.screenId == item.screenId) {
                        item.isChecked = true
                        screenGroup.isScreenGroupChecked = true
                        allScreens.isScreenMainGroupChecked = true
                    }
                    newData.push(item.screenId)
                    checkArray.push(item.screenId);
                })
                setData(newData)
            })


        } else {
            let screenGroupMainData = allScreens.screenGroup

            screenGroupMainData.forEach((screenGroup: any) => {

                let screenGroupData = screenGroup.screens

                const newData: any = [];

                screenGroupData.forEach((item: any) => {

                    if (item.screenId == item.screenId) {
                        item.isChecked = false
                        screenGroup.isScreenGroupChecked = false
                        allScreens.isScreenMainGroupChecked = false
                    }
                    newData.push(item.screenId)
                    let unCheckArrayId = checkArray.indexOf(Number(item.screenId))
                    checkArray.splice(unCheckArrayId, 1);
                })
                setData(newData)
            })
        }
    }

    function selectAllScreenGroup(screenGroup: any, e: any) {

        if (e == true) {
            let screenGroupData = screenGroup.screens

            const newData: any = [];

            screenGroupData.forEach((item: any) => {

                if (item.screenId == item.screenId) {
                    item.isChecked = true
                    screenGroup.isScreenGroupChecked = true
                }
                newData.push(item)
                checkArray.push(item.screenId);
            })

            setData(newData)
            generateMenu(assignScreenData)

        } else {
            let screenGroupData = screenGroup.screens

            const newData: any = [];

            screenGroupData.forEach((item: any) => {

                if (item.screenId == item.screenId) {
                    item.isChecked = false
                    screenGroup.isScreenGroupChecked = false
                }
                newData.push(item)
                let unCheckArrayId = checkArray.indexOf(Number(item.screenId))
                checkArray.splice(unCheckArrayId, 1);
            })
            setData(newData)
            generateMenu(assignScreenData)
        }
    }

    function setScreenData() {
        return {
            userTypeId: userTypeId?.value,
            userAccountId: userAccountId,
            staffDetailsId: staffDetailsId?.value,
            screenId: checkArray,
            licenseKey: getLicenseKey
        }
    }

    function assignScreenForStaffSubmit() {
        showLoader(true)
        if (checkArray.length > 0) {
            let screenPostData = setScreenData();

            assignScreenForStaff(screenPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        closeAndReset();
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        showLoader(false)
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (checkArray.length == 0) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function getUserTypeList() {
        getUserType(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.userType;
                    if (data != undefined) {
                        setUserTypeData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setUserTypeData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStaffListByUserType(staffTypeId: any) {
        getStaffListByUserTypeId(userAccountId, staffTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.staffListByUserTypeId;
                    if (data != undefined) {
                        setStaffData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setStaffData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getAllScreen() {
        getAllScreens(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.allScreens;
                    if (data != undefined) {
                        setAllScreensData(data);
                        setIsOpenListCard(false);
                        getScreenListByUserAccountId(staffDetailsId?.value)
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getScreenListByUserAccountId(staffDetailsId: any) {
        getAssignedScreenByUserAccountId(staffDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.assignedScreenByUserAccountId;
                    if (data != undefined) {
                        setIsLoader(false);
                        setAssignScreenData(data);
                        setDataStatus(true)
                        setIsOpenListCard(false)

                        generateMenu(data)

                        // Getting Assigned screensId
                        for (let i = 0; i < data.length; i++) {
                            let screenGroupData = data[i].screenGroup;
                            for (let j = 0; j < screenGroupData.length; j++) {
                                let screensData = screenGroupData[j].screens;
                                for (let k = 0; k < screensData.length; k++) {
                                    if (screensData[k].isChecked == true) {
                                        checkArray.push(screensData[k].screenId)
                                    }
                                }
                            }
                        }
                        setCheckArray(checkArray)

                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function generateMenu(screenData: any) {
        for (let i = 0; i < screenData.length; i++) {

            let screenGroupMain = screenData[i]
            let screenGroupMainData = screenData


            for (let j = 0; j < screenGroupMain.screenGroup.length; j++) {
                let screenGroup = screenGroupMain.screenGroup[j]
                let screenGroupData = screenGroupMain.screenGroup

                for (let k = 0; k < screenGroup.screens.length; k++) {

                    let screen = screenGroup.screens
                    let checkedArray = screen.filter((item: any) => item.isChecked).length
                    let allScreensCount = screenGroup.screens.length

                    if (checkedArray == allScreensCount) {
                        const newData: any = [];
                        screenGroupData.forEach((item: any) => {
                            if (item.screenGroupId == screenGroup.screenGroupId) {
                                item.isScreenGroupChecked = true
                            }
                            newData.push(item)
                        })
                        setData(newData)

                    } else {
                        const newData: any = [];
                        screenGroupData.forEach((item: any) => {
                            if (item.screenGroupId == screenGroup.screenGroupId) {
                                item.isScreenGroupChecked = false
                            }
                            newData.push(item)
                        })
                        setData(newData)
                    }

                    let checkedArrayForScreenGroup = screenGroupData.filter((item: any) => item.isScreenGroupChecked).length
                    let allScreensCounForScreenGroup = screenGroupMain.screenGroup.length

                    if (checkedArrayForScreenGroup == allScreensCounForScreenGroup) {
                        const screenData: any = [];
                        screenGroupMainData.forEach((item: any) => {
                            if (item.screenGroupMainId == screenGroupMain.screenGroupMainId) {
                                item.isScreenMainGroupChecked = true
                            }
                            screenData.push(item)
                        })
                        setData(screenData)
                    } else {
                        const screenData: any = [];
                        screenGroupMainData.forEach((item: any) => {
                            if (item.screenGroupMainId == screenGroupMain.screenGroupMainId) {
                                item.isScreenMainGroupChecked = false
                            }
                            screenData.push(item)
                        })
                        setData(screenData)
                    }
                }
            }
        }
    }

    function closeAndReset() {
        setIsOpen(true);
        setIsOpenListCard(true);
        setDataStatus(false);
        setUserTypeId('');
        setStaffDetailsId('');
        setCheckArray([]);
    }

    return (
        <PageWrapper title='Assign Screen'>
            <Page container='fluid'>
                <Collapse isOpen={isOpenListCard}>
                    <Card>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info'>
                                <CardTitle tag='div' className='h5'>
                                    Screen Assign For Staff
                                </CardTitle>
                            </CardLabel>
                        </CardHeader>
                        <CardBody>
                            <div className='row'>
                                <div className='col-3'>
                                    <FormGroup id='academicPeriodId' label='Staff Type' isFloating>
                                        <SearchableSelect isFloating
                                            ariaLabel='Staff Type'
                                            onChange={selectUserType}
                                            value={userTypeId}
                                            list={userTypeData.map((data: any) => (
                                                { value: data.userTypeId, label: data.userType }
                                            ))}
                                        />
                                    </FormGroup>
                                </div>
                                {userTypeId ?
                                    <div className='col-3'>
                                        <FormGroup id='semesterTypeId' label='Staff' isFloating>
                                            <SearchableSelect isFloating
                                                ariaLabel='Staff'
                                                onChange={selectStaff}
                                                value={staffDetailsId}
                                                list={staffData.map((data: any) => (
                                                    { value: data.staffDetailsId, label: data.staffName }
                                                ))}
                                            />
                                        </FormGroup>
                                    </div> : null
                                }
                                {staffDetailsId ?
                                    <div className='col-3 mt-2'>
                                        <Button
                                            icon='ArrowDownward'
                                            color='primary'
                                            onClick={viewScreenList}
                                        >
                                            View
                                        </Button>
                                    </div> : null
                                }
                            </div>
                        </CardBody>
                    </Card>
                </Collapse>

                <OpenCardComponent isOpenListCard={isOpenListCard} setIsOpenListCard={setIsOpenListCard} isLoader={isLoader} />

                {dataStatus ?
                    <>
                        <Card ref={componentRef}>
                            <CardHeader borderSize={1}>
                                <div className='d-flex col-5 justify-content-start'>
                                    <CardLabel icon='List' iconColor='info'>
                                        <CardTitle tag='div' className='h5'>Assign Screens For <span className='text-primary'>{staffDetailsId?.label}</span></CardTitle>
                                    </CardLabel>
                                </div>
                            </CardHeader>

                            <CardBody>
                                <div className='row'>
                                    {assignScreenData?.map((allScreens: any) => (

                                        <Card stretch shadow='none' key={allScreens.screenGroupMainId}>
                                            <CardHeader borderSize={1}>
                                                <CardLabel className="col-lg-5">
                                                    <CardTitle tag='div' className='h5 text-danger'>
                                                        <Checks className='border border-danger mt-1'
                                                            label={allScreens.screenGroupMainDisplayName}
                                                            onChange={(e: any) => selectAllScreenGroupMain(allScreens, e.target.checked)}
                                                            checked={allScreens.isScreenMainGroupChecked} />
                                                    </CardTitle>
                                                </CardLabel>
                                            </CardHeader>
                                            <CardBody>

                                                {allScreens?.screenGroup.map((screensGroup: any) => {
                                                    return (

                                                        <div className='col-12 mt-2' key={screensGroup.screenGroupId}>
                                                            <Card stretch>
                                                                <CardHeader borderSize={1}>
                                                                    <CardLabel className="col-lg-5">
                                                                        <CardTitle tag='div' className='h5 text-info'>
                                                                            <Checks className='border border-info mt-1' label={screensGroup.screenGroupDisplayName}
                                                                                onChange={(e: any) => selectAllScreenGroup(screensGroup, e.target.checked)}
                                                                                checked={screensGroup.isScreenGroupChecked}
                                                                            />
                                                                        </CardTitle>
                                                                    </CardLabel>
                                                                </CardHeader>
                                                                <CardBody>

                                                                    <div className="row">

                                                                        {
                                                                            screensGroup?.screens.map((screens: any) => {

                                                                                return (

                                                                                    <div className="col-3" key={screens.screenId}>
                                                                                        <Checks
                                                                                            label={screens.displayName}
                                                                                            onChange={(e) => onSelect(e, screens.screenId)}
                                                                                            checked={screens.isChecked}
                                                                                        />
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </CardBody>
                                                            </Card>
                                                        </div>

                                                    )
                                                })}
                                            </CardBody>
                                        </Card>
                                    ))}
                                </div>
                            </CardBody>
                        </Card>
                        <SubHeader>
                            <SubHeaderLeft><></></SubHeaderLeft>
                            <SubHeaderRight>
                                <SubheaderSeparator />
                                <Button icon='Save' color='primary' isDisable={checkArray.length == 0} onClick={assignScreenForStaffSubmit}>Submit</Button>
                            </SubHeaderRight>
                        </SubHeader>
                    </> : null
                }
                <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
            </Page>
        </PageWrapper >
    );
}

export default AssignScreensForUser