import { useFormik } from 'formik'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import NoDataMsg from '../../../../../common/components/NoDataMsg';
import OpenCardComponent from '../../../../../common/components/OpenCardComponent';
import SearchableSelect from '../../../../../common/components/SearchableSelect';
import AuthContext from '../../../../../contexts/authContext';
import useSortableData from '../../../../../hooks/useSortableData';
import Page from '../../../../../layout/Page/Page';
import PageWrapper from '../../../../../layout/PageWrapper/PageWrapper';
import AlertService from '../../../../../services/AlertService';
import { getLicenseKey } from '../../../../../services/application.settings';
import { getStudentManualTc, updateStudentManualTc } from '../../../../../services/certificate.service';
import { updateFilter, convertDateToWords, uploadImageForManualTc, getGraduationType, getColumnsForDataTable, updateColumnsForDataTable, profilePic, pictNotLoading, getStandardDetails } from '../../../../../services/common.service';
import { downloadExcel, convertJsonToPdf, downloadFile } from '../../../../../services/ExportService';
import { showLoader } from '../../../../../services/loader.services';
import { getAcademicPeriod } from '../../../../../services/master.service';
import { toasts } from '../../../../../services/toast.service';
import Button, { ButtonGroup } from '../../../../bootstrap/Button';
import Card, { CardHeader, CardLabel, CardTitle, CardBody, CardActions } from '../../../../bootstrap/Card';
import Collapse from '../../../../bootstrap/Collapse';
import Dropdown, { DropdownToggle, DropdownMenu, DropdownItem } from '../../../../bootstrap/Dropdown';
import Checks, { ChecksGroup } from '../../../../bootstrap/forms/Checks';
import FormGroup from '../../../../bootstrap/forms/FormGroup';
import Modal, { ModalHeader, ModalTitle, ModalBody, ModalFooter } from '../../../../bootstrap/Modal';
import PaginationButtons, { dataPagination } from '../../../../PaginationButtons';
import Icon from '../../../../icon/Icon';
import Input from '../../../../bootstrap/forms/Input';

function ManualTcList() {

    const navigate = useNavigate();

    useEffect(() => {
        getAcademicPeriodList()
        getStandardList();
    }, [])

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const [academicPeriodId, setAcademicPeriodId] = useState<any>({ value: 0, label: 'Select All' })

    const [academicPeriodData, setAcademicPeriodData] = useState<any>([])
    const [studentManualTcData, setStudentManualTcData] = useState<any>([])

    const [isOpenListCard, setIsOpenListCard] = useState(true)
    const [dataSuccess, setDataSuccess] = useState(false)
    const [columnVisibilityData, setColumnVisibilityData] = useState([]);
    const [allColumnsData, setAllColumnsData] = useState([]);
    const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
    const [columnDataById, setColumnDataById] = useState([])
    const [isChecked, setIsChecked] = useState(false)

    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(studentManualTcData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);

    const [updateManualTcModal, setUpdateManualTcModal] = useState(false);
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);

    const [dateOfBirthInWord, setDateOfBirthInWord] = useState<string>('');
    const [dateOfBirth, setDateOfBirth] = useState<string>('');

    const [standardDetailsData, setStandardDetailsData] = useState<any>([])
    const [standardDetailsId, setStandardDetailsId] = useState<any>('')
    const componentRef = useRef(null);
    const [isLoader, setIsLoader] = useState(false)
    const [noDataMsg, setNoDataMsg] = useState('')

    const [selectedFile, setSelectedFile] = useState<any>()
    const [profilePath, setProfilePath] = useState(null);
    const [studentManualTcId, setStudentManualTcId] = useState<any>()

    const updateManualTcEntryForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            studentManualTcId: '',
            profilePath: '',
            serialNo: '',
            admissionNo: "",
            studentName: "",
            fatherOrMotherName: "",
            religion: "",
            nationality: "",
            caste: "",
            community: "",
            gender: "",
            dateOfBirth: "",
            dateOfBirthInWord: "",
            admissionDate: "",
            degreeAndCourse: "",
            timeOfLeaving: "",
            leavingDate: "",
            leavingReason: '',
            qualifiedForHigherStudy: "",
            transferCertificateDate: "",
            conductAndCharacter: "",
            medium: "",
            standardDetailsId: '',
        },
        validate: (values) => {
            const errors: {
                admissionNo?: string;
                studentName?: string;
                fatherOrMotherName?: string;
                gender?: string;
                standardDetailsId?: string
            } = {};
            if (!values.admissionNo) {
                errors.admissionNo = 'Required';
            }
            if (!values.studentName) {
                errors.studentName = 'Required';
            }
            if (!values.fatherOrMotherName) {
                errors.fatherOrMotherName = 'Required';
            }
            if (!standardDetailsId?.value) {
                errors.standardDetailsId = 'Required';
            }
            return errors;

        },
        //validateOnChange: false,
        onSubmit: (values) => { updateManualTcSubmit() },
    })

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },

        //validateOnChange: false,
        onSubmit: () => { },
    });

    // Filter
    const filteredData = updateFilter(onCurrentPageData, columnVisibilityData, columnVisibilityForm);

    function onActivate(i: any) {
        if (i != undefined) {
            setSelectedFile('')
            let selectedStudentTransferCertificate = i
            setProfilePath(selectedStudentTransferCertificate.profilePath);
            setDateOfBirth(selectedStudentTransferCertificate.dateOfBirth)
            setDateOfBirthInWord(convertDateToWords(selectedStudentTransferCertificate.dateOfBirth))
            setUpdateManualTcModal(true);
            setStandardDetailsId({ value: selectedStudentTransferCertificate.standardDetailsId, label: selectedStudentTransferCertificate.standardName })
            setStudentManualTcId(selectedStudentTransferCertificate.studentManualTcId)
            updateManualTcEntryForm.setValues({
                studentManualTcId: selectedStudentTransferCertificate.studentManualTcId,
                profilePath: selectedStudentTransferCertificate.profilePath,
                serialNo: selectedStudentTransferCertificate.serialNo,
                admissionNo: selectedStudentTransferCertificate.admissionNo,
                studentName: selectedStudentTransferCertificate.studentName,
                fatherOrMotherName: selectedStudentTransferCertificate.fatherOrMotherName,
                religion: selectedStudentTransferCertificate.religion,
                nationality: selectedStudentTransferCertificate.nationality,
                caste: selectedStudentTransferCertificate.caste,
                community: selectedStudentTransferCertificate.community,
                gender: selectedStudentTransferCertificate.gender,
                dateOfBirth: selectedStudentTransferCertificate.dateOfBirth,
                dateOfBirthInWord: dateOfBirthInWord,
                admissionDate: selectedStudentTransferCertificate.admissionDate,
                degreeAndCourse: selectedStudentTransferCertificate.degreeAndCourse,
                timeOfLeaving: selectedStudentTransferCertificate.timeOfLeaving,
                leavingDate: selectedStudentTransferCertificate.leavingDate,
                leavingReason: selectedStudentTransferCertificate.leavingReason,
                qualifiedForHigherStudy: selectedStudentTransferCertificate.qualifiedForHigherStudy,
                transferCertificateDate: selectedStudentTransferCertificate.transferCertificateDate,
                conductAndCharacter: selectedStudentTransferCertificate.conductAndCharacter,
                medium: selectedStudentTransferCertificate.medium,
                standardDetailsId: standardDetailsId?.value,
            });
        }
    }

    function onPrint(i: any) {
        if (i != undefined) {
            let selectedStudentTransferCertificate = i
            window.open(`../academic/commonTcForManual/${selectedStudentTransferCertificate.studentManualTcId}`)
        }
    }

    function onUpload(studentManualTcId: any) {
        const filedata = new FormData();
        filedata.append('profilePic', selectedFile, selectedFile.name)
        filedata.append('licenseKey', getLicenseKey.toString())
        filedata.append('userAccountId', userAccountId)
        filedata.append('studentManualTcId', studentManualTcId)
        uploadImageForManualTc(filedata,
            (response) => {
                console.log('Profile pic upload');
            }
            , (error) => {
                console.log('Profile pic not upload');

            }
        )
    }

    const convertDateOfBirth = (e: any) => {

        let dateOfBirth = e.target.value
        setDateOfBirth(dateOfBirth)
        setDateOfBirthInWord(convertDateToWords(dateOfBirth))
    };

    const selectStandard = (e: any) => {
        let standardDetailsId = e
        setStandardDetailsId(standardDetailsId)
    }

    function updateManualTcEntry() {
        return ({
            studentManualTcId: updateManualTcEntryForm.values.studentManualTcId,
            admissionNo: updateManualTcEntryForm.values.admissionNo,
            studentName: updateManualTcEntryForm.values.studentName,
            fatherOrMotherName: updateManualTcEntryForm.values.fatherOrMotherName,
            religion: updateManualTcEntryForm.values.religion,
            nationality: updateManualTcEntryForm.values.nationality,
            caste: updateManualTcEntryForm.values.caste,
            community: updateManualTcEntryForm.values.community,
            gender: updateManualTcEntryForm.values.gender,
            dateOfBirth: dateOfBirth,
            dateOfBirthInWord: dateOfBirthInWord,
            admissionDate: updateManualTcEntryForm.values.admissionDate,
            degreeAndCourse: updateManualTcEntryForm.values.degreeAndCourse,
            timeOfLeaving: updateManualTcEntryForm.values.timeOfLeaving,
            leavingDate: updateManualTcEntryForm.values.leavingDate,
            leavingReason: updateManualTcEntryForm.values.leavingReason,
            qualifiedForHigherStudy: updateManualTcEntryForm.values.qualifiedForHigherStudy,
            transferCertificateDate: updateManualTcEntryForm.values.transferCertificateDate,
            conductAndCharacter: updateManualTcEntryForm.values.conductAndCharacter,
            medium: updateManualTcEntryForm.values.medium,
            standardDetailsId: standardDetailsId?.value,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId
        })
    }

    const selectAcademicPeriod = (e: any) => {
        let academicPeriodId = e
        setAcademicPeriodId(academicPeriodId)
    }

    const selectStudentList = () => {
        columnVisibilityForm.resetForm()
        setDataSuccess(false)
        setIsLoader(true);
        getStudentManualTcList(academicPeriodId?.value)
    }

    function getAcademicPeriodList() {
        getAcademicPeriod(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.academicPeriod;
                    if (data != undefined) {
                        const academicPeriodData = [{ academicPeriodId: 0, period: 'Select All' }];
                        data.forEach((obj: { academicPeriodId: number; period: string }) => {
                            let period = academicPeriodData.push(obj);
                        });
                        setAcademicPeriodData(academicPeriodData);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setAcademicPeriodData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStandardList() {
        getStandardDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.standardDetails;
                    if (data != undefined) {
                        setStandardDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setStandardDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStudentManualTcList(academicPeriodId: any) {
        setIsLoader(true);
        getStudentManualTc(academicPeriodId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentManualTc;
                    if (data != undefined) {
                        setIsLoader(false);
                        getColumnsForTable('getStudentManualTc', 'get');
                        setStudentManualTcData(data);
                        setDataSuccess(true);
                        setIsOpenListCard(false);
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        setIsLoader(false);
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    getColumnsForTable('getStudentManualTc', 'get');
                    setStudentManualTcData([]);
                    setNoDataMsg(response.data.message)
                    setIsLoader(false);
                    setDataSuccess(true);
                    setIsOpenListCard(false);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setIsLoader(false);
                    toasts(errorDescription, "Error")
                }
            }, error => {
                setIsLoader(false);
                toasts(error, "Error")
            }
        )
    }

    function updateManualTcSubmit() {
        showLoader(true);
        if (updateManualTcEntryForm.isValid) {
            let updateManualTcEntryPost = updateManualTcEntry()
            updateStudentManualTc(updateManualTcEntryPost,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        //let studentManualTcId = data.data.studentManualTcId
                        if (selectedFile != '') {
                            onUpload(studentManualTcId);
                        }
                        showLoader(false);
                        setUpdateManualTcModal(false);
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        updateManualTcEntryForm.resetForm();
                        getStudentManualTcList(academicPeriodId?.value);
                    }
                    else if (data.success == false) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false);
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }, (error) => {
                    showLoader(false);
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (updateManualTcEntryForm.isValid == false) {
            showLoader(false);
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function getColumnsForTable(apiName: any, type: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setColumnVisibilityData(columnVisibilityData)

                        if (type == 'get') {
                            const allData = allColumnsData.map((item: any) => item.isDisplay);
                            const allColumns = allData.filter((item: any) => item == false);
                            setIsChecked(allColumns == '' ? true : false);
                        }
                        else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

                            for (let i = 0; i < nonDefaultValue.length; i++) {

                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0
                                }
                                all.push(obj);
                            }
                            updateColumnsSubmit('', '', all)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )

    }

    function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {

        if (columnVisibilityForm.isValid) {

            setColumnDataById(isDisplay)
            const columnVisibilityPostData = {
                licenseKey: getLicenseKey,
                userAccountId: userAccountId,
                columns: type ? type : [
                    {
                        columnVisibilityId: columnVisibilityId,
                        isDisplay: isDisplay ? 0 : 1
                    }
                ]
            }

            updateColumnsForDataTable(columnVisibilityPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        getColumnsForTable('getStudentManualTc', 'get');
                    }
                    else if (data.success == false) {
                        // toasts(data.success, "Error")
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        toasts(errorDescription, "Error")
                    }
                }
                , (error) => {
                    toasts(error, "Error")
                }
            )
        } else if (columnVisibilityForm.isValid == false) {
            toasts("Please fill all the details!", "Error")
        }
    }

    function closeModal() {
        setUpdateManualTcModal(false)
    }

    // Excel Configuration
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const handleFileChange = (e: any) => {
        if (e.target.files && e.target.files[0]) {
            setSelectedFile(e.target.files[0]);
        }
    };

    const handleDeleteAvatar = () => {
        setSelectedFile(null);
        const fileInput = document.getElementById('fileInput') as HTMLInputElement;
        if (fileInput) {
            fileInput.value = '';
        }
    };

    return (
        <PageWrapper title='Manual Tc List'>
            <Page container='fluid'>
                <Collapse isOpen={isOpenListCard}>
                    <Card stretch data-tour='list'>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info'>
                                <CardTitle tag='div' className='h5'>
                                    Manual Tc List
                                </CardTitle>
                            </CardLabel>
                        </CardHeader>
                        <CardBody>
                            <div className='row'>
                                <div className='col-3'>
                                    <FormGroup id='academicPeriodId' label='Academic Period' isFloating>
                                        <SearchableSelect isFloating
                                            ariaLabel='Academic Period'
                                            onChange={selectAcademicPeriod}
                                            value={academicPeriodId}
                                            list={academicPeriodData.map((data: any) => (
                                                { value: data.academicPeriodId, label: data.period }
                                            ))}
                                        />
                                    </FormGroup>
                                </div>

                                <div className="col-3 mt-2" >
                                    <Button icon='ArrowDownward' color='primary' isDisable={academicPeriodId?.value == undefined} onClick={selectStudentList}>View</Button>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Collapse>

                <OpenCardComponent isOpenListCard={isOpenListCard} setIsOpenListCard={setIsOpenListCard} isLoader={isLoader} />

                {dataSuccess ?
                    <>
                        <Card stretch data-tour='list' ref={componentRef}>
                            <CardHeader borderSize={1}>
                                <CardLabel icon='List' iconColor='info' className="col-lg-5">
                                    <CardTitle tag='div' className='h5'>Student Manual Tc List</CardTitle>
                                </CardLabel>&nbsp;&nbsp;

                                <ButtonGroup className="col-lg-3 d-print-none" color='primary'>
                                    <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                                    <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
                                </ButtonGroup>

                                <CardActions className="d-print-none">
                                    <Dropdown isOpen={columnVisibilityMenu} setIsOpen={setColumnVisibilityMenu} isButtonGroup>
                                        <DropdownToggle>
                                            <Button icon='FilterAlt' color='primary' isLight>Filter</Button>
                                        </DropdownToggle>
                                        <DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
                                            <DropdownItem>
                                                <div className='container py-2'>
                                                    <form className='row g-3'>
                                                        <div className='col-12'>
                                                            <FormGroup>
                                                                <h6>Select All</h6>
                                                                <Checks id='available' type='switch' label='Select All Columns' onChange={() => getColumnsForTable('getStudentManualTc', 'post')} checked={isChecked} ariaLabel='Available status' />
                                                            </FormGroup>
                                                        </div>
                                                        <div className='col-12' style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                                                            <FormGroup>
                                                                <h6>Columns</h6>
                                                                <ChecksGroup>
                                                                    {allColumnsData.map((i: any) => (
                                                                        <Checks key={i.columnVisibilityId} id='{i.columnVisibilityId}' label={i.columnName} onChange={() => updateColumnsSubmit(i.columnVisibilityId, i.isDisplay, '')} checked={i.isDisplay} disabled={i.isDefault == true} />
                                                                    ))}
                                                                </ChecksGroup>
                                                            </FormGroup>
                                                        </div>
                                                    </form>
                                                </div>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                    <Dropdown isButtonGroup>
                                        <DropdownToggle>
                                            <Button color='primary' isLight icon='CloudDownload'> Export </Button>
                                        </DropdownToggle>
                                        <DropdownMenu isAlignmentEnd>
                                            <DropdownItem>
                                                <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel("Student Manual Tc", studentManualTcData, columnVisibilityData)}> Excel </Button>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(studentManualTcData, columnVisibilityData, "Student Manual Tc")}> PDF
                                                </Button>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile("Student Manual Tc", studentManualTcData, columnVisibilityData)}> CSV </Button>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <Button color='primary' isLight icon='Print' onClick={handlePrint}> Print </Button>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                    <Button color='primary' isLight icon='Add' onClick={() => navigate(`../academic/manualTcEntry`)}>
                                        Add New
                                    </Button>
                                </CardActions>
                            </CardHeader>
                            <CardBody className='table-responsive' isScrollable >
                                <table className='table table-modern table-hover text-nowrap'>
                                    <thead>
                                        <tr>
                                            <th scope='col' onClick={() => requestSort('sno')}
                                                className='cursor-pointer text-decoration-underline'>S.No{' '}
                                                <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                            </th>
                                            {columnVisibilityData.map((column: any) => (
                                                <th key={column.keyName} scope='col' onClick={() => requestSort(column.keyName)}
                                                    className='cursor-pointer text-decoration-underline'>
                                                    {column.columnName}
                                                    <Icon size='lg' className={getClassNamesFor(column.keyName)} icon='FilterList' />
                                                </th>
                                            ))}
                                            <td className='d-print-none' />
                                            <td className='d-print-none' />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredData != "" ?
                                            <>
                                                {filteredData.map((rowData: any) => (
                                                    <tr key={rowData.studentManualTcId}>
                                                        <td>{rowData.sno}</td>
                                                        {columnVisibilityData.map((column: any) => (
                                                            (column.isDisplay && rowData[column.keyName]) ?
                                                                <td key={`${rowData.studentManualTcId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />
                                                        ))}
                                                        <td className='d-print-none'> <Button onClick={() => { onActivate(rowData) }} style={{ marginRight: '5px' }} isLink icon='Circle' className='text-nowrap' color='success' >Preview</Button>
                                                        </td>
                                                        <td className='d-print-none'>
                                                            <Button onClick={() => { onPrint(rowData) }} style={{ marginRight: '5px' }} isLink icon='Circle' className='text-nowrap' color='primary' >Print
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                ))
                                                } </>
                                            :
                                            <NoDataMsg columnsCount={columnVisibilityData.length + 3} msg={noDataMsg} />}
                                    </tbody>
                                </table>
                            </CardBody>
                            <PaginationButtons
                                className='d-print-none'
                                data={items}
                                label='items'
                                setCurrentPage={setCurrentPage}
                                currentPage={currentPage}
                                perPage={perPage}
                                setPerPage={setPerPage}
                            />
                        </Card>
                    </> : null}
            </Page>

            <Modal
                setIsOpen={setUpdateManualTcModal}
                isOpen={updateManualTcModal}
                id='updateManualTcModal'
                isStaticBackdrop={true}
                //isScrollable={true}
                isCentered
                size='lg'
            >

                <ModalHeader setIsOpen={setUpdateManualTcModal}>
                    <ModalTitle id='updateManualTcModal'>Update Manual Tc List</ModalTitle>
                </ModalHeader>
                <form noValidate onSubmit={updateManualTcEntryForm.handleSubmit}>
                    <ModalBody>
                        <div className='row'>
                            <div className="d-grid gap-4">
                                <div className='row'>
                                    <div className='col-lg-6'></div>
                                    <div className='col-lg-6'>
                                        <div className='col-12'>
                                            <div className='row g-4 align-items-center'>
                                                <div className='col-lg-auto'>
                                                    {selectedFile ?
                                                        <img style={{ borderRadius: '50%' }}
                                                            src={URL.createObjectURL(selectedFile)}
                                                            height='120' width='120' />
                                                        :
                                                        <img style={{ borderRadius: '50%' }}
                                                            src={profilePath != null ? profilePath : profilePic(updateManualTcEntryForm.values.gender == 'Male' ? 1 : 2)}
                                                            height='120' width='120' onError={(e: any) => pictNotLoading(e, updateManualTcEntryForm.values.gender == 'Male' ? 1 : 2)}
                                                        />}
                                                </div>
                                                <div className='col-lg '>
                                                    <div className='row g-4'>
                                                        <div className='col-auto mt-4'>
                                                            <Input
                                                                type='file'
                                                                id="fileInput"
                                                                autoComplete='photo'
                                                                ariaLabel='Upload image file'
                                                                //onChange={(e) => { setSelectedFile(e) }}
                                                                onChange={handleFileChange}
                                                            />
                                                        </div>
                                                        <div className='col-auto mt-4'>
                                                            <Button
                                                                color='dark'
                                                                isLight
                                                                icon='Delete'
                                                                onClick={handleDeleteAvatar}>
                                                                Delete Avatar
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-lg-1'></div>
                                    <div className="d-flex col-lg-5 justify-content-start">
                                        <div className="align-self-center" style={{ marginRight: '10px' }}>
                                            <p className="h6">Serial No :</p>
                                        </div>
                                        <div>
                                            <FormGroup id="serialNo" label="" >
                                                <Input type="text" placeholder="Serial Number"
                                                    onChange={updateManualTcEntryForm.handleChange}
                                                    value={updateManualTcEntryForm.values.serialNo} disabled />
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <div className="d-flex col-lg-6 justify-content-end">
                                        <div className="align-self-center" style={{ marginRight: '10px' }}>
                                            <p className="h6">Admission No :</p>
                                        </div>
                                        <div>
                                            <FormGroup id="admissionNo" label="" >
                                                <Input type="text" placeholder="Admission Number" onChange={updateManualTcEntryForm.handleChange}
                                                    value={updateManualTcEntryForm.values.admissionNo}
                                                    onBlur={updateManualTcEntryForm.handleBlur}
                                                    isValid={updateManualTcEntryForm.isValid}
                                                    isTouched={updateManualTcEntryForm.touched.admissionNo}
                                                    invalidFeedback={updateManualTcEntryForm.errors.admissionNo}
                                                />
                                            </FormGroup>
                                        </div>
                                    </div>
                                </div>
                                <div className='row  '>
                                    <div className='d-grid gap-4'>
                                        <div className="row  justify-content-start">
                                            <div className="col-lg-1 align-self-center">
                                                <p className='h6'>1.</p>
                                            </div>
                                            <div className="col-lg-5 align-self-center">
                                                <p className="h6">Standard</p>
                                            </div>
                                            <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                            <div className="col-lg-5 justify-content-center">
                                                <FormGroup id="standardDetailsId"  >
                                                    <SearchableSelect ariaLabel={""} placeholder="Select Standard"
                                                        onChange={selectStandard}
                                                        value={standardDetailsId}
                                                        list={standardDetailsData.map((data: any) => (
                                                            { value: data.standardDetailsId, label: data.standardName }
                                                        ))}
                                                        onBlur={updateManualTcEntryForm.handleBlur}
                                                        isValid={updateManualTcEntryForm.isValid}
                                                        isTouched={updateManualTcEntryForm.touched.standardDetailsId}
                                                        invalidFeedback={updateManualTcEntryForm.errors.standardDetailsId} />
                                                </FormGroup>
                                            </div>
                                        </div>
                                        <div className="row justify-content-start">
                                            <div className="col-lg-1 align-self-center ">
                                                <p className='h6'>2.</p>
                                            </div>
                                            <div className="col-lg-5 align-self-center">
                                                <p className="h6">Name of the Student (in BLOCK LETTERS) (as entered in +2 or equivalent Certificate)</p>
                                            </div>
                                            <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                            <div className="col-lg-5 justify-content-center">
                                                <FormGroup id="studentName" label="">
                                                    <Input type="text" placeholder="Name Of Student"
                                                        onChange={updateManualTcEntryForm.handleChange}
                                                        value={updateManualTcEntryForm.values.studentName}
                                                        onBlur={updateManualTcEntryForm.handleBlur}
                                                        isValid={updateManualTcEntryForm.isValid}
                                                        isTouched={updateManualTcEntryForm.touched.studentName}
                                                        invalidFeedback={updateManualTcEntryForm.errors.studentName} />
                                                </FormGroup>
                                            </div>
                                        </div>
                                        <div className="row justify-content-center">
                                            <div className="col-lg-1 align-self-center">
                                                <p className='h6'>3.</p>
                                            </div>
                                            <div className="col-lg-5 align-self-center">
                                                <p className="h6">Name of the Father or Mother of the Puplil </p>
                                            </div>
                                            <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                            <div className="col-lg-5 justify-content-center">
                                                <FormGroup id="fatherOrMotherName" label=""  >
                                                    <Input type="text" placeholder="Name Of Father Or Mother"
                                                        onChange={updateManualTcEntryForm.handleChange}
                                                        value={updateManualTcEntryForm.values.fatherOrMotherName}
                                                        onBlur={updateManualTcEntryForm.handleBlur}
                                                        isValid={updateManualTcEntryForm.isValid}
                                                        isTouched={updateManualTcEntryForm.touched.fatherOrMotherName}
                                                        invalidFeedback={updateManualTcEntryForm.errors.fatherOrMotherName} />
                                                </FormGroup>
                                            </div>
                                        </div>
                                        <div className="row justify-content-center">
                                            <div className="col-lg-1 align-self-center">
                                                <p className='h6'>4.</p>
                                            </div>
                                            <div className="col-lg-5 align-self-center"><p className="h6">Nationality</p>
                                            </div>
                                            <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                            <div className="col-lg-5 justify-content-center">
                                                <FormGroup id="nationality" label="">
                                                    <Input type="text" placeholder="Nationality" onChange={updateManualTcEntryForm.handleChange} value={updateManualTcEntryForm.values.nationality} />
                                                </FormGroup>
                                            </div>
                                        </div>
                                        <div className="row justify-content-center">
                                            <div className="col-lg-1 align-self-center">
                                                <p className='h6'>5.</p>
                                            </div>
                                            <div className="col-lg-5 align-self-center"><p className="h6">Religion</p>
                                            </div>
                                            <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                            <div className="col-lg-5 justify-content-center">
                                                <FormGroup id="religion" label="">
                                                    <Input type="text" placeholder="Religion"
                                                        onChange={updateManualTcEntryForm.handleChange}
                                                        value={updateManualTcEntryForm.values.religion} />
                                                </FormGroup>
                                            </div>
                                        </div>
                                        {/* <div className="row justify-content-center">
                                            <div className="col-lg-1 align-self-center ">
                                                <p className='h6'>6.</p>
                                            </div>
                                            <div className="col-lg-5 align-self-center"><p className="h6">Caste</p>
                                            </div>
                                            <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                            <div className="col-lg-5 justify-content-center">
                                                <FormGroup id="caste" label="">
                                                    <Input type="text" placeholder="Caste" onChange={updateManualTcEntryForm.handleChange} value={updateManualTcEntryForm.values.caste} />
                                                </FormGroup>
                                            </div>
                                        </div>
                                        <div className="row justify-content-center">
                                            <div className="col-lg-1 align-self-center ">
                                                <p className='h6'>7.</p>
                                            </div>
                                            <div className="col-lg-5 align-self-center"><p className="h6">Community</p>
                                            </div>
                                            <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                            <div className="col-lg-5 justify-content-center">
                                                <FormGroup id="community" label="">
                                                    <Input type="text" placeholder="Community" onChange={updateManualTcEntryForm.handleChange} value={updateManualTcEntryForm.values.community} />
                                                </FormGroup>
                                            </div>
                                        </div> */}
                                        <div className="row justify-content-center">
                                            <div className="col-lg-1 align-self-center ">
                                                <p className='h6'>6.</p>
                                            </div>
                                            <div className="col-lg-5 align-self-center"><p className="h6">Gender</p>
                                            </div>
                                            <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                            <div className="col-lg-5 justify-content-center">
                                                <FormGroup id="gender" label="">
                                                    <Input type="text" placeholder="Male Or Female"
                                                        onChange={updateManualTcEntryForm.handleChange}
                                                        value={updateManualTcEntryForm.values.gender}
                                                        onBlur={updateManualTcEntryForm.handleBlur}
                                                        isValid={updateManualTcEntryForm.isValid}
                                                        isTouched={updateManualTcEntryForm.touched.gender}
                                                        invalidFeedback={updateManualTcEntryForm.errors.gender} />
                                                </FormGroup>
                                            </div>
                                        </div>
                                        <div className="row justify-content-center">
                                            <div className="col-lg-1 align-self-center ">
                                                <p className='h6'>7.</p>
                                            </div>
                                            <div className="col-lg-5 align-self-center"><p className="h6">Date of Birth as entered in the Admission</p>
                                            </div>
                                            <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                            <div className="col-lg-5 justify-content-center">
                                                <FormGroup id="dateOfBirth" label="">
                                                    <Input type="date" placeholder="Date Of Birth"
                                                        onChange={convertDateOfBirth}
                                                        value={dateOfBirth} />
                                                </FormGroup>
                                            </div>
                                        </div>
                                        <div className="row justify-content-center">
                                            <div className="col-lg-1 align-self-center ">
                                                <p className='h6'>8.</p>
                                            </div>
                                            <div className="col-lg-5 align-self-center"><p className="h6">Date of Birth in Word</p>
                                            </div>
                                            <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                            <div className="col-lg-5 justify-content-center">
                                                <FormGroup id="dateOfBirthInWord" label="">
                                                    <Input type="text" placeholder="Date Of Birth"
                                                        onChange={updateManualTcEntryForm.handleChange}
                                                        value={dateOfBirthInWord} disabled />
                                                </FormGroup>
                                            </div>
                                        </div>
                                        <div className="row justify-content-center">
                                            <div className="col-lg-1 align-self-center ">
                                                <p className='h6'>9.</p>
                                            </div>
                                            <div className="col-lg-5 align-self-center">
                                                <p className="h6">Date of Admission and class in which admitted</p>
                                            </div>
                                            <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                            <div className="col-lg-5 justify-content-center">
                                                <FormGroup id="admissionDate" label="">
                                                    <Input type="date" placeholder="Date of Admission" onChange={updateManualTcEntryForm.handleChange} value={updateManualTcEntryForm.values.admissionDate} />
                                                </FormGroup>
                                            </div>
                                        </div>
                                        <div className="row justify-content-center">
                                            <div className="col-lg-1 align-self-center">
                                                <p className='h6'>10.</p>
                                            </div>
                                            <div className="col-lg-5 align-self-center">
                                                <p className="h6">Degree and Programme to which the student was admitted</p>
                                            </div>
                                            <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                            <div className="col-lg-5 justify-content-center">
                                                <FormGroup id="degreeAndCourse" label="">
                                                    <Input type="text" placeholder="Degree and Programme" onChange={updateManualTcEntryForm.handleChange} value={updateManualTcEntryForm.values.degreeAndCourse} />
                                                </FormGroup>
                                            </div>
                                        </div>
                                        <div className="row justify-content-center">
                                            <div className="col-lg-1 align-self-center ">
                                                <p className='h6'>11.</p>
                                            </div>
                                            <div className="col-lg-5 align-self-center">
                                                <p className="h6">Class in which the pupil was studying at the time of leaving</p>
                                            </div>
                                            <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                            <div className="col-lg-5 justify-content-center">
                                                <FormGroup id="timeOfLeaving" label="">
                                                    <Input type="text" placeholder="Class" onChange={updateManualTcEntryForm.handleChange} value={updateManualTcEntryForm.values.timeOfLeaving} />
                                                </FormGroup>
                                            </div>
                                        </div>
                                        <div className="row justify-content-center">
                                            <div className="col-lg-1 align-self-center ">
                                                <p className='h6'>12.</p>
                                            </div>
                                            <div className="col-lg-5 align-self-center">
                                                <p className="h6">Date on which the student actually left the College</p>
                                            </div>
                                            <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                            <div className="col-lg-5 justify-content-center">
                                                <FormGroup id="leavingDate" label="">
                                                    <Input type="date" placeholder="Date" onChange={updateManualTcEntryForm.handleChange} value={updateManualTcEntryForm.values.leavingDate} />
                                                </FormGroup>
                                            </div>
                                        </div>
                                        <div className="row justify-content-center">
                                            <div className="col-lg-1 align-self-center ">
                                                <p className='h6'>13.</p>
                                            </div>
                                            <div className="col-lg-5 align-self-center">
                                                <p className="h6">Reason for which the student left the College</p>
                                            </div>
                                            <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                            <div className="col-lg-5 justify-content-center">
                                                <FormGroup id="leavingReason" label="">
                                                    <Input type="text" placeholder="Reason" onChange={updateManualTcEntryForm.handleChange} value={updateManualTcEntryForm.values.leavingReason} />
                                                </FormGroup>
                                            </div>
                                        </div>
                                        <div className="row justify-content-center">
                                            <div className="col-lg-1 align-self-center">
                                                <p className='h6'>14.</p>
                                            </div>
                                            <div className="col-lg-5 align-self-center">
                                                <p className="h6">
                                                    Whether qualified for promotion to higher studies
                                                </p>
                                            </div>
                                            <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                            <div className="col-lg-5 justify-content-center">
                                                <FormGroup id="qualifiedForHigherStudy" label="">
                                                    <Input type="text" placeholder="Qualified for promotion" onChange={updateManualTcEntryForm.handleChange} value={updateManualTcEntryForm.values.qualifiedForHigherStudy} />
                                                </FormGroup>
                                            </div>
                                        </div>
                                        <div className="row justify-content-center">
                                            <div className="col-lg-1 align-self-center ">
                                                <p className='h6'>15.</p>
                                            </div>
                                            <div className="col-lg-5 align-self-center">
                                                <p className="h6">Date of the Transfer Certificate</p>
                                            </div>
                                            <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                            <div className="col-lg-5 justify-content-center">
                                                <FormGroup id="transferCertificateDate" label="">
                                                    <Input type="date" placeholder="Date Of Transfer Certificate" onChange={updateManualTcEntryForm.handleChange} value={updateManualTcEntryForm.values.transferCertificateDate} />
                                                </FormGroup>
                                            </div>
                                        </div>
                                        <div className="row justify-content-center">
                                            <div className="col-lg-1 align-self-center ">
                                                <p className='h6'>16.</p>
                                            </div>
                                            <div className="col-lg-5 align-self-center">
                                                <p className="h6">Conduct and Character</p>
                                            </div>
                                            <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                            <div className="col-lg-5 justify-content-center">
                                                <FormGroup id="conductAndCharacter" label="">
                                                    <Input type="text" placeholder="Conduct And Character" onChange={updateManualTcEntryForm.handleChange} value={updateManualTcEntryForm.values.conductAndCharacter} />
                                                </FormGroup>
                                            </div>
                                        </div>
                                        <div className="row justify-content-center">
                                            <div className="col-lg-1 align-self-center ">
                                                <p className='h6'>17.</p>
                                            </div>
                                            <div className="col-lg-5 align-self-center">
                                                <p className="h6">Medium(for M.Phil Only)</p>
                                            </div>
                                            <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                            <div className="col-lg-5 justify-content-center">
                                                <FormGroup id="medium" label="">
                                                    <Input type="text" placeholder="Medium" onChange={updateManualTcEntryForm.handleChange} value={updateManualTcEntryForm.values.medium} />
                                                </FormGroup>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </ModalBody>
                    <ModalFooter>
                        <Button color='info' isOutline className='border-0' onClick={closeModal}>Close</Button>
                        <Button color='info' icon='Save' type="submit" isDisable={!updateManualTcEntryForm.isValid && !!updateManualTcEntryForm.submitCount}>Update </Button>
                    </ModalFooter>
                </form>
            </Modal>
            <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
        </PageWrapper>
    );
}

export default ManualTcList;