import React, { useContext, useEffect, useState } from 'react';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody, CardFooter, CardHeader, CardLabel, CardTitle } from '../../bootstrap/Card';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Checks, { ChecksGroup } from '../../bootstrap/forms/Checks';
import Label from '../../bootstrap/forms/Label';
import Button from '../../bootstrap/Button';
import Textarea from '../../bootstrap/forms/Textarea';
import OpenCardComponent from '../../../common/components/OpenCardComponent';
import SearchableSelect from '../../../common/components/SearchableSelect';
import Collapse from '../../bootstrap/Collapse';
import { toasts } from '../../../services/toast.service';
import { getMedium, getStandardDetails, getTimeDuration } from '../../../services/common.service';
import {
	getAcademicPeriod,
	getBatchMasterDetails,
	getSectionDetails,
} from '../../../services/master.service';
import { useFormik } from 'formik';
import { getLicenseKey } from '../../../services/application.settings';
import AuthContext from '../../../contexts/authContext';
import { showLoader } from '../../../services/loader.services';
import { addQuizDetails, getSubjectListByStandardDetailsId } from '../../../services/exam.service';
import AlertService from '../../../services/AlertService';
import { getSubjectPaperDetails } from '../../../services/subject.service';
import Select from '../../bootstrap/forms/Select';

const AddQuiz = () => {
	const { userAccountId, userTypeId } = useContext(AuthContext);

	useEffect(() => {
		getAcademicPeriodList();
	}, []);
	const [academicPeriodId, setAcademicPeriodId] = useState<any>('');
	const [mediumId, setMediumId] = useState<any>('');
	const [standardDetailsId, setStandardDetailsId] = useState<any>('');
	const [batchMasterId, setBatchMasterId] = useState<any>('');
	const [subjectPaperDetailsId, setSubjectPaperDetailsId] = useState<any>('');
	const [timeDurationId, setTimeDurationId] = useState<any>('');

	const [academicPeriodData, setAcademicPeriodData] = useState<any>([]);
	const [mediumData, setMediumData] = useState<any>([]);
	const [standardData, setStandardData] = useState<any>([]);
	const [sectionDetailsData, setSectionDetailsData] = useState<any>([]);
	const [batchMasterData, setBatchMasterData] = useState<any>([]);
	const [subjectData, setSubjectData] = useState<any>([]);
	const [timeDurationData, setTimeDurationData] = useState<any>([]);
	const [questionCount, setQuestionCount] = useState<any>(10);
	const [addQuestionData, setAddQuestionData] = useState<any>([]);
	const [quizTable, setQuizTable] = useState<any>([]);

	const [isOpenListCard, setIsOpenListCard] = useState(true);
	const [isLoader, setIsLoader] = useState(false);
	const [alertStatus, setAlertStatus] = useState<any>({ message: '', type: '' });
	const [isOpen, setIsOpen] = useState(false);
	const [dataSuccess, setDataSuccess] = useState(false);

	const questionTypeForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			title: '',
			totalNoOfQuestion: '',
			subjectPaperDetailsId: '',
			timeDurationId: '',
		},
		validate: (values) => {
			const errors: {
				title?: string;
				totalNoOfQuestion?: string;
				subjectPaperDetailsId?: string;
				timeDurationId?: string;
			} = {};
			if (!values.title) {
				errors.title = 'Required';
			}
			if (!timeDurationId) {
				errors.timeDurationId = 'Required';
			}
			if (!questionCount) {
				errors.totalNoOfQuestion = 'Required';
			}
			if (!subjectPaperDetailsId) {
				errors.subjectPaperDetailsId = 'Required';
			}

			return errors;
		},
		//validateOnChange: false,
		onSubmit: () => { addQuizSubmit() },
	});

	const selectAcademicPeriod = (e: any) => {
		setSubjectPaperDetailsId('')
		setBatchMasterId('');
		setMediumId('');
		setStandardDetailsId('');
		questionTypeForm.resetForm()
		let academicPeriodId = e;
		setAcademicPeriodId(academicPeriodId);
		getBatchList();
	};

	const selectBatch = (e: any) => {
		setSubjectPaperDetailsId('')
		setMediumId('');
		setStandardDetailsId('');
		questionTypeForm.resetForm()
		let batchMasterId = e;
		setBatchMasterId(batchMasterId);
		getMediumList();
	};
	const selectMedium = (e: any) => {
		setSubjectPaperDetailsId('')
		setStandardDetailsId('');
		questionTypeForm.resetForm()
		let mediumId = e;
		setMediumId(mediumId);
		getStandardList();
	};

	const selectStandard = (e: any) => {
		setSubjectPaperDetailsId('')
		questionTypeForm.resetForm()
		let standardDetailsId = e;
		setStandardDetailsId(standardDetailsId);
		if (standardDetailsId?.value != undefined) {
			getSubjectPaperList(batchMasterId?.value, standardDetailsId?.value);
		}
	};

	const selectSubject = (e: any) => {
		let subjectPaperDetailsId = e;
		setSubjectPaperDetailsId(subjectPaperDetailsId);
		if (subjectPaperDetailsId?.value != undefined) {
			getTimeDurationList()
		}
	};

	const selectDuration = (e: any) => {
		let timeDurationId = e;
		setTimeDurationId(timeDurationId);
	};

	const selectQuestionCount = (questionCount: any) => {
		setQuestionCount(questionCount);
		setQuizTable([]);
	};

	function View() {
		setIsOpenListCard(false);
		setDataSuccess(true);

		let newData: any = [];
		for (let i = 0; i < questionCount; i++) {
			newData.push({
				option1Value: '',
				question: '',
				subjectPaperDetailsIdArray: '',
				displayOrder: i,
			});
		}
		setAddQuestionData(newData);
	}

	function getAcademicPeriodList() {
		getAcademicPeriod(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.academicPeriod;
					if (data != undefined) {
						setAcademicPeriodData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					setAcademicPeriodData([]);
					//toasts(response.data.message, "Error")
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getBatchList() {
		getBatchMasterDetails(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.batchMasterDetails;
					if (data != undefined) {
						setBatchMasterData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					// toasts(response.data.message, "Error")
					setBatchMasterData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getMediumList() {
		getMedium(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.medium;
					if (data != undefined) {
						setMediumData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setMediumData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getStandardList() {
		getStandardDetails(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.standardDetails;
					if (data != undefined) {
						setStandardData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setStandardData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getTimeDurationList() {
		getTimeDuration(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.timeDuration;
					if (data != undefined) {
						setTimeDurationData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setTimeDurationData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getSubjectPaperList(batchMasterId: any, standardDetailsId: any) {
		getSubjectListByStandardDetailsId(
			batchMasterId,
			standardDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.subjectListByStandardDetailsId;
					if (data != undefined) {
						setSubjectData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setSubjectData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}
	const [isDuplicateEntry, setIsDuplicateEntry] = useState<any>([]);
	const [isEmptyData, setIsEmptyData] = useState<any>([]);

	const addQuizQuestion = (question: any, option1Value: any, option2Value: any, option3Value: any, option4Value: any, correctAnswer: any, displayOrder: any) => {

		let existingData = quizTable.filter((data: any) => data.question == question && data.displayOrder != displayOrder)
		setIsDuplicateEntry(existingData)

		const newData: any = [];

		addQuestionData.forEach((item: any, index: any) => {
			if (item['displayOrder'] == displayOrder) {
				item['option1Value'] = option1Value;
				item['question'] = question;
				item['option2Value'] = option2Value;
				item['option3Value'] = option3Value;
				item['option4Value'] = option4Value;
				item['correctAnswer'] = correctAnswer;
				item['displayOrder'] = displayOrder;
				item['existingData'] = existingData != '' ? true : false
			}
			newData.push(item);
		});
		setAddQuestionData(newData);

		if (quizTable.length == 0 && question != '' && option1Value != '' && option3Value != '' && option3Value != '' && option4Value != '') {
			const obj = {
				displayOrder: displayOrder,
				question: question,
				option1Value: option1Value,
				option2Value: option2Value,
				option3Value: option3Value,
				option4Value: option4Value,
				correctAnswer: correctAnswer,
			};
			quizTable.push(obj);
		} else {
			let selectedItem: any;
			quizTable.forEach((item: any, index: any) => {
				if (item['displayOrder'] == displayOrder) {
					selectedItem = item;
					selectedItem['question'] = question,
						selectedItem['option1Value'] = option1Value;
					selectedItem['option2Value'] = option2Value;
					selectedItem['option3Value'] = option3Value;
					selectedItem['option4Value'] = option4Value;
					selectedItem['correctAnswer'] = correctAnswer;
				}
			});
			if (selectedItem != undefined) {
				selectedItem['displayOrder'] = displayOrder,
					selectedItem['question'] = question,
					selectedItem['option1Value'] = option1Value;
				selectedItem['option2Value'] = option2Value;
				selectedItem['option3Value'] = option3Value;
				selectedItem['option4Value'] = option4Value;
				selectedItem['correctAnswer'] = correctAnswer;
			} else if (question != '' && option1Value != '' && option3Value != '' && option3Value != '' && option4Value != '') {
				var obj;
				obj = {
					displayOrder: displayOrder,
					question: question,
					option1Value: option1Value,
					option2Value: option2Value,
					option3Value: option3Value,
					option4Value: option4Value,
					correctAnswer: correctAnswer,
				};
				quizTable.push(obj);
			}
		}

		let emptyData = quizTable.filter((item: any) => item.question == '' || item.option1Value == '' || item.option2Value == '' || item.option3Value == '' || item.option4Value == '' || item.correctAnswer == undefined)
		setIsEmptyData(emptyData)
	};

	function setQuizDetails() {
		return {
			academicPeriodId: academicPeriodId?.value,
			batchMasterId: batchMasterId?.value,
			standardDetailsId: standardDetailsId?.value,
			mediumId: mediumId?.value,
			subjectPaperDetailsId: subjectPaperDetailsId?.value,
			timeDurationId: timeDurationId?.value,
			titleName: questionTypeForm.values.title,
			totalNoOfQuestion: questionCount,
			staffDetailsId: userAccountId,
			questions: quizTable,
			licenseKey: getLicenseKey,
		};
	}

	function addQuizSubmit() {
		showLoader(true);
		if (questionCount == quizTable.length && isEmptyData == '') {
			let quizDetailsPostData = setQuizDetails();
			addQuizDetails(
				quizDetailsPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'success' });
						setIsOpen(true);
						setIsOpenListCard(true)
						closeAndReset();
					} else if (data.success == false) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'error' });
						setIsOpen(true);
					} else {
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						showLoader(false);
						setAlertStatus({ message: errorDescription, type: 'error' });
						setIsOpen(true);
					}
				},
				(error) => {
					showLoader(false);
					setAlertStatus({ message: error, type: 'error' });
					setIsOpen(true);
				},
			);
		} else if (questionCount != quizTable.length || isEmptyData != '') {
			showLoader(false);
			setAlertStatus({ message: 'Please fill all the details!', type: 'error' });
			setIsOpen(true);
		}
	}

	function closeAndReset() {
		setQuizTable([])
		setAddQuestionData([])
		setDataSuccess(false)
		setAcademicPeriodId('')
		setBatchMasterId('')
		setStandardDetailsId('')
		setMediumId('')
		setSubjectPaperDetailsId('')
		setTimeDurationId('')
		questionTypeForm.resetForm()
		setQuestionCount('')
	}

	return (
		<PageWrapper title='Quiz Creation'>
			<Page container='fluid'>
				<Collapse isOpen={isOpenListCard}>
					<Card stretch data-tour='list'>
						<CardHeader borderSize={1}>
							<CardLabel icon='List' iconColor='info'>
								<CardTitle tag='div' className='h5'>
									Quiz Creation
								</CardTitle>
							</CardLabel>
						</CardHeader>
						<CardBody>
							<div className='row'>
								<div className='col-md-2'>
									<FormGroup id='academicPeriodId' label='Academic Period' isFloating>
										<SearchableSelect
											isFloating
											ariaLabel='Academic Period'
											placeholder='Academic Period'
											value={academicPeriodId}
											onChange={selectAcademicPeriod}
											list={academicPeriodData.map((data: any) => ({
												value: data.academicPeriodId, label: data.period
											}))}
											required
										/>
									</FormGroup>
								</div>

								{academicPeriodId?.value != undefined ? (
									<div className='col-2'>
										<FormGroup id='batchMasterId' label='Batch' isFloating>
											<SearchableSelect
												isFloating
												ariaLabel='Batch'
												placeholder='Select Batch'
												onChange={selectBatch}
												value={batchMasterId}
												list={batchMasterData.map((data: any) => ({
													value: data.batchMasterId, label: data.batchName
												}))}
												required
											/>
										</FormGroup>
									</div>
								) : null}

								{batchMasterId?.value != undefined ? (
									<div className='col-2'>
										<FormGroup id='mediumId' label='Medium' isFloating>
											<SearchableSelect
												isFloating
												ariaLabel='Medium'
												onChange={selectMedium}
												value={mediumId}
												list={mediumData.map((data: any) => ({
													value: data.mediumId, label: data.medium
												}))}
											/>
										</FormGroup>
									</div>
								) : null}

								{mediumId?.value != undefined ? (
									<div className='col-2'>
										<FormGroup
											id='standardDetailsId'
											label='Standard'
											isFloating>
											<SearchableSelect
												isFloating
												ariaLabel='Standard'
												onChange={selectStandard}
												value={standardDetailsId}
												list={standardData.map((data: any) => ({
													value: data.standardDetailsId, label: data.standardName
												}))}
											/>
										</FormGroup>
									</div>
								) : null}

								{standardDetailsId?.value != undefined ? (
									<div className='col-4'>
										<FormGroup id='subjectPaperDetailsId' label='Subject' isFloating>
											<SearchableSelect
												isFloating
												ariaLabel='Subject'
												onChange={selectSubject}
												value={subjectPaperDetailsId}
												onBlur={questionTypeForm.handleBlur}
												isValid={questionTypeForm.isValid}
												isTouched={questionTypeForm.touched.subjectPaperDetailsId}
												invalidFeedback={questionTypeForm.errors.subjectPaperDetailsId}
												list={subjectData.map((data: any) => ({
													value: data.subjectPaperDetailsId, label: data.paperName
												}))}
											/>
										</FormGroup>
									</div>
								) : null}

								{subjectPaperDetailsId?.value != undefined ? (<>
									<div className='col-4 mt-3'>
										<FormGroup id='title' label='Title' isFloating>
											<Input
												onChange={questionTypeForm.handleChange}
												value={questionTypeForm.values.title}
												onBlur={questionTypeForm.handleBlur}
												isValid={questionTypeForm.isValid}
												isTouched={questionTypeForm.touched.title}
												invalidFeedback={questionTypeForm.errors.title}
												placeholder='Enter Title'
											/>
										</FormGroup>
									</div>
									<div className='col-2 mt-3'>
										<FormGroup
											id='questionCount'
											label='No Of Questions'
											isFloating>
											<Input
												onChange={(e: any) =>
													selectQuestionCount(e.target.value)
												}
												value={questionCount}
												placeholder='Enter Section'
												required
											/>
										</FormGroup>
									</div>
									<div className='col-2 mt-3'>
										<FormGroup id='timeDurationId' label='Duration' isFloating>
											<SearchableSelect
												isFloating
												ariaLabel='Duration'
												onChange={selectDuration}
												value={timeDurationId}
												onBlur={questionTypeForm.handleBlur}
												isValid={questionTypeForm.isValid}
												isTouched={questionTypeForm.touched.timeDurationId}
												invalidFeedback={questionTypeForm.errors.timeDurationId}
												list={timeDurationData.map((data: any) => ({
													value: data.timeDurationId, label: data.durationRange
												}))}
											/>
										</FormGroup>
									</div>
								</>) : null}

								{timeDurationId ?
									<div className='col-2'>
										<Button className='mt-4' icon='ArrowDownward' color='primary' onClick={View}>
											View
										</Button>
									</div>
									: null
								}
							</div>
						</CardBody>
					</Card>
				</Collapse>

				<OpenCardComponent isOpenListCard={isOpenListCard} setIsOpenListCard={setIsOpenListCard} isLoader={isLoader} />

				{dataSuccess ? (
					<>
						<Card>
							<CardHeader borderSize={1}>
								<CardLabel icon='NoteAdd' iconColor='primary'>
									<CardTitle>Add Questions</CardTitle>
								</CardLabel>
							</CardHeader>
							<CardBody>
								{addQuestionData != '' ? (
									<>
										{addQuestionData.map((i: any, index: any) => (
											<div className='row mt-3' key={index}>
												<div className='col-md-8'>
													<Card stretch shadow={'sm'} className='rounded-1 mb-0 border border-light'>
														<CardHeader borderSize={1}>
															<CardLabel icon='EditNote'>
																<CardTitle>Question {index + 1}</CardTitle>
															</CardLabel>
														</CardHeader>
														<CardBody>
															<div className='row g-4'>
																<div className='col-12'>
																	<FormGroup id='question' label='Question' isFloating>
																		<Textarea className={i.question == '' ? '' : i.existingData ? 'is-invalid' : 'is-valid'}
																			rows={6}
																			onChange={(e: any,) => addQuizQuestion(e.target.value, i.option1Value, i.option2Value, i.option3Value, i.option4Value, i.correctAnswer, i.displayOrder,)}
																			value={i.question}
																			style={{ height: '70px', width: '100%', }}
																			placeholder='Enter Question'
																		/>

																	</FormGroup>
																	{i.existingData &&
																		<div className="mt-1"><small className='text-danger ms-2'>Duplicate Question</small></div>
																	}
																</div>
																<div className='col-6'>
																	<FormGroup id='option1Value' label='Option 1' isFloating>
																		<Input
																			placeholder='Enter Option A'
																			onChange={(e: any) => addQuizQuestion(i.question, e.target.value, i.option2Value, i.option3Value, i.option4Value, i.correctAnswer, i.displayOrder,)}
																			value={i.option1Value}
																		/>
																	</FormGroup>
																</div>
																<div className='col-6'>
																	<FormGroup id='option2Value' label='Option 2' isFloating>
																		<Input
																			placeholder='Enter Option B'
																			onChange={(e: any) => addQuizQuestion(i.question, i.option1Value, e.target.value, i.option3Value, i.option4Value, i.correctAnswer, i.displayOrder,)}
																			value={i.option2Value}
																		/>
																	</FormGroup>
																</div>
																<div className='col-6'>
																	<FormGroup id='option3Value' label=' Option 3' isFloating>
																		<Input
																			placeholder=' Option 3'
																			onChange={(e: any) => addQuizQuestion(i.question, i.option1Value, i.option2Value, e.target.value, i.option4Value, i.correctAnswer, i.displayOrder,)}
																			value={i.option3Value}
																		/>
																	</FormGroup>
																</div>
																<div className='col-6'>
																	<FormGroup id='option4Value' label=' Option 4' isFloating>
																		<Input
																			placeholder='Enter Option D'
																			onChange={(e: any) => addQuizQuestion(i.question, i.option1Value, i.option2Value, i.option3Value, e.target.value, i.correctAnswer, i.displayOrder,)}
																			value={i.option4Value}
																		/>
																	</FormGroup>
																</div>
															</div>
														</CardBody>
													</Card>
												</div>

												<div className='col-md-4'>
													<Card stretch shadow={'sm'} className='rounded-1 mb-0 border border-light'>
														<CardHeader borderSize={1}>
															<CardLabel icon='CheckCircleOutline'>
																<CardTitle>Correct Answer</CardTitle>
															</CardLabel>
														</CardHeader>
														<CardBody>
															<ChecksGroup className='mt-1'>
																<FormGroup id='option1Value'>
																	<Checks
																		type='radio'
																		label='Option 1'
																		value={i.correctAnswer}
																		onChange={(e: any) => addQuizQuestion(i.question, i.option1Value, i.option2Value, i.option3Value, i.option4Value, '1', i.displayOrder,)}
																		checked={i.correctAnswer == 1 ? '1' : '0'}
																	/>
																</FormGroup>
																<br />
																<FormGroup id='option2Value'>
																	<Checks
																		type='radio'
																		label='Option 2'
																		value={i.correctAnswer}
																		onChange={(e: any) => addQuizQuestion(i.question, i.option1Value, i.option2Value, i.option3Value, i.option4Value, '2', i.displayOrder,)}
																		checked={i.correctAnswer == 2 ? '2' : '0'}
																	/>
																</FormGroup>
																<br />
																<FormGroup id='option3Value'>
																	<Checks
																		type='radio'
																		label='Option 3'
																		value={i.correctAnswer}
																		onChange={(e: any) => addQuizQuestion(i.question, i.option1Value, i.option2Value, i.option3Value, i.option4Value, '3', i.displayOrder,)}
																		checked={i.correctAnswer == 3 ? '3' : '0'}
																	/>
																</FormGroup>
																<br />
																<FormGroup id='option4Value'>
																	<Checks
																		type='radio'
																		label='Option 4'
																		value={i.correctAnswer}
																		onChange={(e: any) => addQuizQuestion(i.question, i.option1Value, i.option2Value, i.option3Value, i.option4Value, '4', i.displayOrder,)}
																		checked={i.correctAnswer == 4 ? '4' : '0'}
																	/>
																</FormGroup>
															</ChecksGroup>
														</CardBody>
														<br /><br /><br />
													</Card>
													<br />
												</div>
											</div>
										))}
									</>
								) : null}


							</CardBody>
							<CardFooter borderSize={1}>
								<div className='col-12 d-flex justify-content-end'>
									<Button color='info' isLight onClick={questionTypeForm.handleSubmit} icon='Save' isDisable={isDuplicateEntry != '' ? true : false}>
										Save
									</Button>
								</div>
							</CardFooter>
						</Card>
					</>
				) : null}
			</Page>
			<AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
		</PageWrapper>
	);
};

export default AddQuiz;
