
import React, { FC, useState } from 'react';
import Icon from '../../components/icon/Icon';
import Spinner from '../../components/bootstrap/Spinner';

interface INoDataMsgProps {
    columnsCount: any;
    msg : any;
}

const NoDataMsg: FC<INoDataMsgProps> = ({ columnsCount, msg }) => {
    return (
        <>
            <tr>
                <td colSpan={columnsCount} className="text-center">{msg}</td>
            </tr>
        </>
    )
}
export default NoDataMsg;