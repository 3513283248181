import React, { useEffect, useState } from 'react';
import Chart from '../extras/Chart';
import Card, { CardHeader, CardLabel, CardTitle, CardBody, CardActions } from '../bootstrap/Card';
import Page from '../../layout/Page/Page';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import { toasts } from '../../services/toast.service';
import { getAttendanceReportByStudentDetailsId, getStudentAttendancePercentageForMonth } from '../../services/common.service';
import Button from '../bootstrap/Button';
import Spinner from '../bootstrap/Spinner';

const AttendanceStatus = ({ studentDetailsId, height }: any) => {

    useEffect(() => {
        getAttendanceReportByStudentDetails(studentDetailsId)
    }, [])

    const [attendancePercentageData, setAttendancePercentageData] = useState<any>('');
    const [attendancePercentageForMonthData, setAttendancePercentageForMonthData] = useState<any>('');
    const [show, setShow] = useState<any>('year')


    function getAttendanceReportByStudentDetails(studentDetailsId: any) {
        getAttendanceReportByStudentDetailsId(studentDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.attendanceReportByStudentDetailsId[0];
                    if (data != undefined) {
                        setAttendancePercentageData({
                            series: [data.presentPercentage],
                            options: {
                                chart: {
                                    type: 'radialBar',
                                    offsetY: -20,
                                    sparkline: {
                                        enabled: true,
                                    },
                                },
                                plotOptions: {
                                    radialBar: {
                                        startAngle: -90,
                                        endAngle: 90,
                                        track: {
                                            background: '#e7e7e7',
                                            strokeWidth: '97%',
                                            margin: 5,
                                            dropShadow: {
                                                enabled: true,
                                                top: 2,
                                                left: 0,
                                                color: '#999',
                                                opacity: 1,
                                                blur: 2,
                                            },
                                        },
                                        dataLabels: {
                                            name: {
                                                show: false,
                                            },
                                            value: {
                                                offsetY: -2,
                                                fontSize: '22px',
                                            },
                                        },
                                    },
                                },
                                grid: {
                                    padding: {
                                        top: -10,
                                    },
                                },
                                fill: {
                                    type: 'gradient',
                                    gradient: {
                                        shade: 'light',
                                        shadeIntensity: 0.4,
                                        inverseColors: false,
                                        opacityFrom: 1,
                                        opacityTo: 1,
                                        stops: [0, 50, 53, 91],
                                    },
                                },
                                labels: ['Attendance Results'],
                            },
                        })

                    } else {

                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")					
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, 'Error');
                }
            },
            (error) => {
                toasts(error, 'Error');
            },
        );
    }

    function getStudentAttendancePercentage(studentDetailsId: any) {
        getStudentAttendancePercentageForMonth(studentDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentAttendancePercentageForMonth;
                    if (data != undefined) {

                        let presentPercentage = data.map((item: any) => item.presentPercentage)
                        let month = data.map((item: any) => item.month)

                        setAttendancePercentageForMonthData({
                            series: [
                                {
                                    name: 'Percentage',
                                    data: presentPercentage,
                                },
                            ],
                            options: {
                                chart: {
                                    height: 350,
                                    type: 'bar',
                                },
                                plotOptions: {
                                    bar: {
                                        dataLabels: {
                                            position: 'top', // top, center, bottom
                                        },
                                    },
                                },
                                dataLabels: {
                                    enabled: true,
                                    formatter(val: any) {
                                        return `${val}%`;
                                    },
                                    offsetY: -20,
                                    style: {
                                        fontSize: '12px',
                                        colors: ['#304758'],
                                    },
                                },

                                xaxis: {
                                    categories: month,
                                    position: 'top',
                                    axisBorder: {
                                        show: false,
                                    },
                                    axisTicks: {
                                        show: false,
                                    },
                                    crosshairs: {
                                        fill: {
                                            type: 'gradient',
                                            gradient: {
                                                colorFrom: '#D8E3F0',
                                                colorTo: '#BED1E6',
                                                stops: [0, 100],
                                                opacityFrom: 0.4,
                                                opacityTo: 0.5,
                                            },
                                        },
                                    },
                                    tooltip: {
                                        enabled: true,
                                    },
                                },
                                yaxis: {
                                    axisBorder: {
                                        show: false,
                                    },
                                    axisTicks: {
                                        show: false,
                                    },
                                    labels: {
                                        show: false,
                                        formatter(val: any) {
                                            return `${val}%`;
                                        },
                                    },
                                },
                                title: {
                                    text: 'Month Wise Attendance Status,' + new Date().getFullYear(),
                                    floating: true,
                                    offsetY: height == 197 ? 177 : 277,
                                    align: 'center',
                                    style: { color: '#444' }
                                },
                            },
                        })

                    } else {

                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")					
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, 'Error');
                }
            },
            (error) => {
                toasts(error, 'Error');
            },
        );
    }


    function selectYearOrMonth(type: any) {
        setShow(type)
        if (type == 'year') {
            getAttendanceReportByStudentDetails(studentDetailsId)
        } else if (type == 'month') {
            getStudentAttendancePercentage(studentDetailsId)
        }
    }

    return (
        <>
            {attendancePercentageData != '' ?
                <Card className='rounded-1 border border-light'>
                    <CardHeader borderSize={1}>
                        <CardLabel icon={show === 'year' ? 'PieChartOutline' : 'BarChart'}>
                            <CardTitle tag='div' className='h5'>
                                Attendance
                            </CardTitle>
                        </CardLabel>
                        <CardActions>
                            <Button
                                color='info'
                                onClick={() => selectYearOrMonth('year')}
                                isLink={show !== 'year'}
                                isLight={show === 'year'}>
                                Year
                            </Button>
                            <Button
                                color='info'
                                onClick={() => selectYearOrMonth('month')}
                                isLink={show !== 'month'}
                                isLight={show === 'month'}>
                                Month
                            </Button>
                        </CardActions>
                    </CardHeader>
                    <CardBody>
                        <>
                            {attendancePercentageData != '' && show == 'year' ?
                                <>
                                    {height == 300 ? <br /> : null}
                                    <Chart
                                        series={attendancePercentageData.series}
                                        options={attendancePercentageData.options}
                                        type={attendancePercentageData.options.chart?.type}
                                        height={420}
                                    />
                                    {height == 300 ? <><br /><br /><br /><br /><br /></> : null}
                                </> :
                                attendancePercentageForMonthData != '' && show == 'month' ?
                                    <div className='col-12'>
                                        <Chart
                                            series={attendancePercentageForMonthData.series}
                                            options={attendancePercentageForMonthData.options}
                                            type='bar'
                                            height={height}
                                        />
                                        {height == 300 ? <br /> : null}
                                    </div> :
                                    <div className='row'>
                                        <div className='col-12 d-flex justify-content-center'>
                                            <Spinner color='info' size={'xl'} className='fs-2' />
                                        </div>
                                    </div>
                            }
                        </>
                    </CardBody>
                </Card> : null
            }
        </>
    );
};

export default AttendanceStatus;
