import { AxiosResponse } from "axios";
import service from "../api/api.services";
import { getAPIURL, getLicenseKey } from "./application.settings";

const api = service()

export const getAllScreens = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

api.get(getAPIURL() + `getAllScreens/${getLicenseKey}`,
    response => {
        success(response)
    },
    error => {
        failure("getAllScreens - " + error.message)
        console.log('error caught in service : getAllScreens')
    },
);

export const getAllScreensByUserAccountId = (userAccountId: any, userTypeId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

api.get(getAPIURL() + `getAllScreens/${getLicenseKey}/${userAccountId}/${userTypeId}`,
    response => {
        success(response)
    },
    error => {
        failure("getAllScreens - " + error.message)
        console.log('error caught in service : getAllScreens')
    },
);

export const getAssignedScreenByUserAccountId = (staffDetailsId : number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

api.get(getAPIURL() + `getAssignedScreenByUserAccountId/${getLicenseKey}/${staffDetailsId}`,
    response => {
        success(response)
    },
    error => {
        failure("getAssignedScreenByUserAccountId - " + error.message)
        console.log('error caught in service : getAssignedScreenByUserAccountId')
    },
);

export const getScreenListByUserTypeId = (userTypeId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

api.get(getAPIURL() + `getScreenListByUserTypeId/${getLicenseKey}/${userTypeId}`,
    response => {
        success(response)
    },
    error => {
        failure("getScreenListByUserTypeId - " + error.message)
        console.log('error caught in service : getScreenListByUserTypeId')
    },
);

export const assignScreenForUserType = (screenPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

api.post(getAPIURL() + `assignScreenForUserType`, screenPostData,
    response => {
        success(response)
    },
    error => {
        failure(error.message)
        console.log('error caught in service : assignScreenForUserType')
    }
);

export const assignScreenForStaff = (screenPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

api.post(getAPIURL() + `assignScreenForStaff`, screenPostData,
    response => {
        success(response)
    },
    error => {
        failure(error.message)
        console.log('error caught in service : assignScreenForStaff')
    }
);