import { useContext, useEffect, useRef, useState } from "react"
import Page from "../../../../layout/Page/Page"
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper"
import Button, { ButtonGroup } from "../../../bootstrap/Button"
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../../../bootstrap/Card"
import FormGroup from "../../../bootstrap/forms/FormGroup"
import Input from "../../../bootstrap/forms/Input"
import Select from "../../../bootstrap/forms/Select"
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from "../../../bootstrap/OffCanvas"
import { downloadExcel, convertJsonToPdf, downloadFile } from "../../../../services/ExportService"
import PaginationButtons, { dataPagination } from "../../../PaginationButtons"
import Dropdown, { DropdownToggle, DropdownMenu, DropdownItem } from "../../../bootstrap/Dropdown"
import Checks, { ChecksGroup } from "../../../bootstrap/forms/Checks"
import Icon from "../../../icon/Icon"
import useDarkMode from "../../../../hooks/useDarkMode"
import useSortableData from "../../../../hooks/useSortableData"
import { useFormik } from "formik"
import { toasts } from "../../../../services/toast.service"
import { useReactToPrint } from "react-to-print"
import AlertService from "../../../../services/AlertService"
import { addVehicleTyreService, getVehicleTyreDetails, getVehicleTyreServiceList } from "../../../../services/tyre.service"
import AuthContext from "../../../../contexts/authContext"
import { getColumnsForDataTable, updateColumnsForDataTable, updateFilter } from "../../../../services/common.service"
import { getLicenseKey } from "../../../../services/application.settings"
import { TableLoader, showLoader } from "../../../../services/loader.services"
import NoDataMsg from "../../../../common/components/NoDataMsg"
import SearchableSelect from "../../../../common/components/SearchableSelect"

const TyreService = () => {

    useEffect(() => {
        getVehicleTyreService();
        getVehicleTyreDetailsList();
    }, [])

    const [dataStatus, setDataStatus] = useState(false)
    const { userAccountId, userTypeId } = useContext(AuthContext);
    const [tyreServiceData, setTyreServiceData] = useState([])
    const [tyreServiceOffCanvas, setTyreServiceOffCanvas] = useState(false);
    const [vehicleTyreDetailsData, setVehicleTyreDetailsData] = useState<any>([]);
    const [vehicleTyreDetailsId, setVehicleTyreDetailsId] = useState<any>('');
    const [columnVisibilityData, setColumnVisibilityData] = useState([]);
    const [allColumnsData, setAllColumnsData] = useState([]);
    const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
    const [columnDataById, setColumnDataById] = useState([])
    const componentRef = useRef(null);
    const [isChecked, setIsChecked] = useState(false)
    // ForModal And Tables States
    const { themeStatus, darkModeStatus } = useDarkMode();
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(tyreServiceData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);
    const [isOpen, setIsOpen] = useState(false);
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });

    const [noDataMsg, setNoDataMsg] = useState('')

    const addTyreServiceForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            vehicleTyreDetailsId: '',
            serviceDate: "",
            serviceType: "",
            expectedReturnDate: "",
            actualReturnedDate: "",
            remarks: '',
        },
        validate: (values) => {
            const errors: {
                vehicleTyreDetailsId?: string;
                serviceDate?: string;
                serviceType?: string;
                expectedReturnDate?: string;
            } = {};

            if (!vehicleTyreDetailsId) {
                errors.vehicleTyreDetailsId = 'Required';
            }
            if (!values.serviceDate) {
                errors.serviceDate = 'Required';
            }
            if (!values.serviceType) {
                errors.serviceType = 'Required';
            }
            if (!values.expectedReturnDate) {
                errors.expectedReturnDate = 'Required';
            }
            return errors;
        },

        //validateOnChange: false,
        onSubmit: () => { addVehicleTyreServiceSubmit() },
    })

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },

        //validateOnChange: false,
        onSubmit: () => { },
    });


    const selectVehicleTyreDetails = (e: any) => {
        let vehicleTyreDetailsId = e
        setVehicleTyreDetailsId(vehicleTyreDetailsId)
    }

    // Filter
    const filteredData = updateFilter(onCurrentPageData, columnVisibilityData, columnVisibilityForm);

    function getVehicleTyreService() {
        setDataStatus(false)
        getVehicleTyreServiceList(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.vehicleTyreServiceList;
                    if (data != undefined) {
                        setDataStatus(true)
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                        getColumnsForTable('getVehicleTyreServiceList', 'get');
                        setTyreServiceData(data);

                    } else {
                        setDataStatus(true)
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setDataStatus(true)
                    //toasts(response.data.message, "Error")
                    getColumnsForTable('getVehicleTyreServiceList', 'get');
                    setTyreServiceData([]);
                    setNoDataMsg(response.data.message)
                } else {
                    setDataStatus(true)
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                setDataStatus(true)
                toasts(error, "Error")
            }
        )
    }

    function setAddTyreService() {
        return ({
            licenseKey: getLicenseKey,
            userAccountId: userAccountId,
            vehicleTyreDetailsId: vehicleTyreDetailsId?.value,
            serviceDate: addTyreServiceForm.values.serviceDate,
            serviceType: addTyreServiceForm.values.serviceType,
            expectedReturnDate: addTyreServiceForm.values.expectedReturnDate,
            remarks: addTyreServiceForm.values.remarks,
        })
    }

    function addVehicleTyreServiceSubmit() {
        showLoader(true)
        if (addTyreServiceForm.isValid) {
            let vehicleTyreServicePostData = setAddTyreService()
            addVehicleTyreService(vehicleTyreServicePostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        closeAndReset();
                        getVehicleTyreService();
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        showLoader(false)
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (addTyreServiceForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function getVehicleTyreDetailsList() {
        getVehicleTyreDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.vehicleTyreDetails;
                    if (data != undefined) {
                        setVehicleTyreDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setVehicleTyreDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getColumnsForTable(apiName: any, type: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setColumnVisibilityData(columnVisibilityData)

                        const allData = allColumnsData.map((item: any) => item.isDisplay);
                        const allColumns = allData.filter((item: any) => item == false);

                        if (type == 'get') {
                            setIsChecked(allColumns == '' ? true : false);
                        }
                        else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

                            for (let i = 0; i < nonDefaultValue.length; i++) {

                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: allColumns == '' ? 0 : 1
                                }
                                all.push(obj);
                            }
                            updateColumnsSubmit('', '', all)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {

        if (columnVisibilityForm.isValid) {

            setColumnDataById(isDisplay)
            const columnVisibilityPostData = {
                licenseKey: getLicenseKey,
                userAccountId: userAccountId,
                columns: type ? type : [
                    {
                        columnVisibilityId: columnVisibilityId,
                        isDisplay: isDisplay ? 0 : 1
                    }
                ]
            }

            updateColumnsForDataTable(columnVisibilityPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        getColumnsForTable('getVehicleTyreServiceList', 'get');
                    }
                    else if (data.success == false) {
                        // toasts(data.success, "Error")
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        toasts(errorDescription, "Error")
                    }
                }
                , (error) => {
                    toasts(error, "Error")
                }
            )
        } else if (columnVisibilityForm.isValid == false) {
            toasts("Please fill all the details!", "Error")
        }
    }

    function closeAndReset() {
        setTyreServiceOffCanvas(false)
        addTyreServiceForm.resetForm()
        setVehicleTyreDetailsId('')
    }

    function resetStateAddNew() {
        addTyreServiceForm.resetForm()
        setVehicleTyreDetailsId('')
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <>
            <PageWrapper title="Tyre Service">
                <Page container='fluid'>
                    <Card stretch data-tour='list' ref={componentRef} id='pdf'>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info' className="col-lg-5">
                                <CardTitle tag='div' className='h5'>Tyre Service List</CardTitle>
                            </CardLabel>&nbsp;&nbsp;

                            <ButtonGroup className="col-lg-3 d-print-none" color='primary'>
                                <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                                <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
                            </ButtonGroup>

                            <CardActions className="d-print-none">
                                <Dropdown isOpen={columnVisibilityMenu} setIsOpen={setColumnVisibilityMenu} isButtonGroup>
                                    <DropdownToggle>
                                        <Button icon='FilterAlt' color='primary' isLight>Filter</Button>
                                    </DropdownToggle>
                                    <DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
                                        <DropdownItem>
                                            <div className='container py-2'>
                                                <form className='row g-3'>
                                                    <div className='col-12'>
                                                        <FormGroup>
                                                            <h6>Select All</h6>
                                                            <Checks id='available' type='switch' label='Select All Columns' onChange={() => getColumnsForTable('getVehicleTyreServiceList', 'post')} checked={isChecked} ariaLabel='Available status' />
                                                        </FormGroup>
                                                    </div>
                                                    <div className='col-12' style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                                                        <FormGroup>
                                                            <h6>Columns</h6>
                                                            <ChecksGroup>
                                                                {allColumnsData.map((i: any) => (
                                                                    <Checks key={i.columnVisibilityId} label={i.columnName} onChange={() => updateColumnsSubmit(i.columnVisibilityId, i.isDisplay, '')} checked={i.isDisplay} disabled={i.isDefault == true} />
                                                                ))}
                                                            </ChecksGroup>
                                                        </FormGroup>
                                                    </div>
                                                </form>
                                            </div>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                <Dropdown isButtonGroup>
                                    <DropdownToggle>
                                        <Button color='primary' isLight icon='CloudDownload'> Export </Button>
                                    </DropdownToggle>
                                    <DropdownMenu isAlignmentEnd>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel("TyreServiceDetails", tyreServiceData, columnVisibilityData)}> Excel </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(tyreServiceData, columnVisibilityData, "TyreServiceDetails")}> PDF
                                            </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile("TyreServiceDetails", tyreServiceData, columnVisibilityData)}> CSV </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='Print' onClick={handlePrint}> Print </Button>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>

                                <Button color='primary' isLight icon='Add' onClick={() => { setTyreServiceOffCanvas(true); resetStateAddNew() }}>
                                    Add New
                                </Button>
                            </CardActions>
                        </CardHeader>

                        <CardBody className='table-responsive' isScrollable >
                            {!dataStatus ?
                                <TableLoader /> :
                                <table className='table table-modern table-hover text-nowrap'>
                                    <thead>
                                        <tr>
                                            <th scope='col' onClick={() => requestSort('sno')}
                                                className='cursor-pointer text-decoration-underline'>S.No{' '}
                                                <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                            </th>
                                            {columnVisibilityData.map((column: any) => (
                                                <th key={column.keyName} scope='col' onClick={() => requestSort(column.keyName)}
                                                    className='cursor-pointer text-decoration-underline'>
                                                    {column.columnName}
                                                    <Icon size='lg' className={getClassNamesFor(column.keyName)} icon='FilterList' />
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredData != "" ?
                                            <>
                                                {filteredData.map((rowData: any) => (
                                                    <tr key={rowData.vehicleTyreServiceDetailsId}>
                                                        <td>{rowData.sno}</td>
                                                        {columnVisibilityData.map((column: any) => (
                                                            (column.isDisplay && rowData[column.keyName]) ?
                                                                <td key={`${rowData.vehicleTyreServiceDetailsId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />
                                                        ))}
                                                    </tr>
                                                ))
                                                } </>
                                            :
                                            <NoDataMsg columnsCount={columnVisibilityData.length + 1} msg={noDataMsg} />}
                                    </tbody>
                                </table>
                            }
                        </CardBody>
                        <PaginationButtons
                            className="d-print-none"
                            data={items}
                            label='items'
                            setCurrentPage={setCurrentPage}
                            currentPage={currentPage}
                            perPage={perPage}
                            setPerPage={setPerPage}
                        />
                    </Card>
                </Page>
            </PageWrapper>

            <OffCanvas
                setOpen={setTyreServiceOffCanvas}
                isOpen={tyreServiceOffCanvas}
                titleId='tyreServiceOffCanvas'
                isBodyScroll
                isBackdrop={true}
                placement='end'
                tag="form" noValidate onSubmit={addTyreServiceForm.handleSubmit}>
                <OffCanvasHeader setOpen={setTyreServiceOffCanvas} onClick={closeAndReset}>
                    <OffCanvasTitle id='addCourseOffcanvas'>Tyre Service Creation</OffCanvasTitle>
                </OffCanvasHeader>
                <OffCanvasBody>
                    <div className='row g-4'>
                        <div className='col-lg-12'>
                            <FormGroup id='vehicleTyreDetailsId' label='Tyre' >
                                <SearchableSelect placeholder='SelectTyre' ariaLabel={''}
                                    onChange={selectVehicleTyreDetails}
                                    value={vehicleTyreDetailsId}
                                    list={vehicleTyreDetailsData.map((data: any) => (
                                        { value: data.vehicleTyreDetailsId, label: data.tyreId }
                                    ))}
                                    isValid={addTyreServiceForm.isValid}
                                    onBlur={addTyreServiceForm.handleBlur}
                                    isTouched={addTyreServiceForm.touched.vehicleTyreDetailsId}
                                    invalidFeedback={addTyreServiceForm.errors.vehicleTyreDetailsId}
                                />
                            </FormGroup>
                        </div>
                        <div className='col-lg-12'>
                            <FormGroup id='serviceDate' label='Date' >
                                <Input type='date'
                                    onChange={addTyreServiceForm.handleChange}
                                    value={addTyreServiceForm.values.serviceDate}
                                    isValid={addTyreServiceForm.isValid}
                                    onBlur={addTyreServiceForm.handleBlur}
                                    isTouched={addTyreServiceForm.touched.serviceDate}
                                    invalidFeedback={addTyreServiceForm.errors.serviceDate} />
                            </FormGroup>
                        </div>
                        <div className='col-lg-12'>
                            <FormGroup id='serviceType' label='Type Of Service' >
                                <Select placeholder='SelectType Of Service' ariaLabel={''}
                                    onChange={addTyreServiceForm.handleChange}
                                    value={addTyreServiceForm.values.serviceType}
                                    isValid={addTyreServiceForm.isValid}
                                    onBlur={addTyreServiceForm.handleBlur}
                                    isTouched={addTyreServiceForm.touched.serviceType}
                                    invalidFeedback={addTyreServiceForm.errors.serviceType}>
                                    <option>Puncher</option>
                                    <option>Retreading</option>
                                </Select>
                            </FormGroup>
                        </div>
                        <div className='col-lg-12'>
                            <FormGroup id='expectedReturnDate' label='Expected Return Date' >
                                <Input type='date'
                                    onChange={addTyreServiceForm.handleChange}
                                    value={addTyreServiceForm.values.expectedReturnDate}
                                    isValid={addTyreServiceForm.isValid}
                                    onBlur={addTyreServiceForm.handleBlur}
                                    isTouched={addTyreServiceForm.touched.expectedReturnDate}
                                    invalidFeedback={addTyreServiceForm.errors.expectedReturnDate} />
                            </FormGroup>
                        </div>
                        <div className='col-lg-12 '>
                            <FormGroup id='remarks' label='Remarks' >
                                <Input type="text" placeholder='EnterRemarks'
                                    onChange={addTyreServiceForm.handleChange}
                                    value={addTyreServiceForm.values.remarks} />
                            </FormGroup>
                        </div>
                    </div>
                </OffCanvasBody>
                <div className='row m-0'>
                    <div className='col-12 p-3'>
                        <Button
                            color='info'
                            className='w-100' type="submit" isDisable={!addTyreServiceForm.isValid && !!addTyreServiceForm.submitCount}>
                            Save
                        </Button>
                    </div>
                </div>
            </OffCanvas>
            <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
        </>
    )
}

export default TyreService