import { AxiosResponse } from "axios";
import service from "../api/api.services";
import { getAPIURL, getLicenseKey } from "./application.settings";

const api = service()
export const addFuelConsumption = (fuelConsumptionEntryPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addFuelConsumption`, fuelConsumptionEntryPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addFuelConsumption')
        }
    );

    export const getFuelConsumptionReport = (fromDate: any,toDate: any, vehicleDetailsId: any, ledgerDetailsId: any,success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getFuelConsumptionReport/${getLicenseKey}/${fromDate}/${toDate}/${vehicleDetailsId}/${ledgerDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getFuelConsumptionReport - " + error.message)
            console.log('error caught in service : getFuelConsumptionReport')
        },
    );

    export const updateFuelConsumption = (fuelConsumptionReportPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateFuelConsumption`, fuelConsumptionReportPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateFuelConsumption')
        }
    );

    export const deleteFuelConsumption = (fuelConsumptionReportPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

        api.post(getAPIURL() + `deleteFuelConsumption`, fuelConsumptionReportPostData,
            response => {
                success(response)
            },
            error => {
                failure(error.message)
                console.log('error caught in service : deleteFuelConsumption')
            }
        );