import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import HumansWebp from '../../../assets/img/scene2.webp';
import Humans from '../../../assets/img/scene2.webp';
import AuthContext from '../../../contexts/authContext';
import useDarkMode from '../../../hooks/useDarkMode';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft } from '../../../layout/SubHeader/SubHeader';
import {
	getCurrentDateAndTime,
	convertDateToEpoch,
	getMedium,
	getStandardDetails,
} from '../../../services/common.service';
import {
	getAllocatedTimeTable,
	getScheduleDetailsByDate,
} from '../../../services/timeTableService';
import { toasts } from '../../../services/toast.service';
import Card, { CardLabel, CardTitle, CardBody, CardHeader, CardActions } from '../../bootstrap/Card';
import OpenCardComponent from '../../../common/components/OpenCardComponent';
import Button from '../../bootstrap/Button';
import Collapse from '../../bootstrap/Collapse';
import FormGroup from '../../bootstrap/forms/FormGroup';
import { useFormik } from 'formik';
import Input from '../../bootstrap/forms/Input';
import { getDayOrderType, getSectionDetails } from '../../../services/master.service';
import SearchableSelect from '../../../common/components/SearchableSelect';
import Icon from '../../icon/Icon';

const PeriodScheduleForStaff = () => {

	const { userAccountId, userTypeId } = useContext(AuthContext);

	useEffect(() => {
		if (userTypeId != 1) {
			getScheduleDetailsList();
		}
		getMediumList();
	}, []);

	const { themeStatus, darkModeStatus } = useDarkMode();

	const [mediumId, setMediumId] = useState<any>('');
	const [standardDetailsId, setStandardId] = useState<any>('');
	const [sectionDetailsId, setSectionDetailsId] = useState<any>(Number);
	const [dayOrderTypeId, setDayOrderTypeId] = useState<any>('');
	const [timeTableMasterId, setTimeTableMasterId] = useState<any>('');

	const [scheduleData, setScheduleData] = useState<any>([]);
	const [mediumData, setMediumData] = useState<any>([]);
	const [standardData, setStandardData] = useState<any>([]);
	const [sectionDetailsData, setSectionDetailsData] = useState([]);
	const [dayOrderData, setDayOrderData] = useState<any>([]);
	const [timeTableAllocationData, setTimeTableAllocationData] = useState<any>([]);

	const [isOpenListCard, setIsOpenListCard] = useState(true);
	const [isLoader, setIsLoader] = useState(false);
	const [dataSuccess, setDataSuccess] = useState(false);

	const staffScheduleForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			date: getCurrentDateAndTime('date'),
		},
		validate: (values) => {
			const errors: {
				date?: string;
			} = {};

			if (!values.date) {
				errors.date = 'Required';
			}
			return errors;
		},

		//validateOnChange: false,
		onSubmit: () => { },
	});

	const selectMedium = (e: any) => {
		setStandardId('');
		setSectionDetailsId('');
		setDayOrderTypeId('')
		setDataSuccess(false)
		let mediumId = e;
		setMediumId(mediumId);
		getStandardList();
	};

	const selectStandard = (e: any) => {
		setSectionDetailsId('');
		setDayOrderTypeId('')
		setDataSuccess(false);
		let standardDetailsId = e;
		setStandardId(standardDetailsId);
		if (standardDetailsId?.value != undefined) {
			getSectionList(mediumId?.value, standardDetailsId?.value);
		}
	};

	const selectSection = (e: any) => {
		setDayOrderTypeId('');
		setDataSuccess(false);
		let sectionDetailsId = e;
		setSectionDetailsId(sectionDetailsId);
		getDayOrderTypeList();
	};

	const selectDayOrder = (e: any) => {
		setDayOrder('')
		setDataSuccess(false)
		setDayOrderTypeId('');
		let dayOrderTypeId = e;
		setDayOrderTypeId(dayOrderTypeId);
	};

	function viewForAdminLogin() {
		getAllocatedTimeTableList(sectionDetailsId?.value, dayOrderTypeId?.value);
	}

	function ViewSchedule() {
		setDayOrder('')
		setDayId('')
		setDataSuccess(false);
		setIsLoader(true);
		setScheduleData([]);
		getScheduleDetailsList();
	}

	const [dayOrder, setDayOrder] = useState<any>('')
	const [dayId, setDayId] = useState<any>('')

	function getScheduleDetailsList() {
		let scheduleDate = staffScheduleForm.values.date;
		let dateInMilliSec = convertDateToEpoch(scheduleDate);

		getScheduleDetailsByDate(
			userTypeId,
			userAccountId,
			dateInMilliSec,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.scheduleDetailsByDate[0].period;
					if (data != undefined) {
						setIsLoader(false);
						setScheduleData(data);
						setDayId(response.data.data.scheduleDetailsByDate[0].dayId);
						setDayOrder(response.data.data.scheduleDetailsByDate[0].dayOrderName);
						setDataSuccess(true);
						for (let i = 0; i < data.length; i++) {
							data[i].periodCount = data[i].periodName[data[i].periodName.length - 1];
						}
					} else {
						setIsLoader(false);
						setDataSuccess(false);
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					setIsLoader(false);
					setDataSuccess(true);
					// toasts(response.data.message, "Error")
					setScheduleData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					setIsLoader(false);
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				setIsLoader(false);
				toasts(error, 'Error');
			},
		);
	}

	function getMediumList() {
		getMedium(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.medium;
					if (data != undefined) {
						setMediumData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setMediumData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getStandardList() {
		getStandardDetails(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.standardDetails;
					if (data != undefined) {
						setStandardData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setStandardData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getSectionList(mediumId: any, standardDetailsId: any) {
		getSectionDetails(
			mediumId,
			standardDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.sectionDetails;
					if (data != undefined) {
						setSectionDetailsData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, 'Error');
					setSectionDetailsData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getDayOrderTypeList() {
		getDayOrderType(
			1,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.dayOrderType;
					if (data != undefined) {
						setDayOrderData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setDayOrderData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getAllocatedTimeTableList(sectionDetailsId: any, dayOrderTypeId: any) {
		setIsLoader(true);
		getAllocatedTimeTable(
			sectionDetailsId,
			dayOrderTypeId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.allocatedTimeTable[0].dayOrder[0].period;
					if (data != undefined) {
						setIsLoader(false);
						setScheduleData(data);
						setDayId(response.data.data.allocatedTimeTable[0].dayOrder[0].dayId);
						setDayOrder(response.data.data.allocatedTimeTable[0].dayOrder[0].day);
						setDataSuccess(true);
						for (let i = 0; i < data.length; i++) {
							data[i].periodCount = data[i].periodName[data[i].periodName.length - 1];
						}
					} else {
						setIsLoader(false);
						setDataSuccess(false);
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					setIsLoader(false);
					setDataSuccess(true);
					// toasts(response.data.message, "Error")
					setScheduleData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					setIsLoader(false);
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				setIsLoader(false);
				toasts(error, 'Error');
			},
		);
	}

	return (
		<PageWrapper title='Schedule'>
			<Page container='fluid'>
				<Collapse isOpen={isOpenListCard}>
					<Card
						stretch
						data-tour='list'
						tag='form'
						noValidate
						onSubmit={staffScheduleForm.handleSubmit}>
						<CardHeader borderSize={1}>
							<CardLabel icon='Schedule' iconColor='info'>
								<CardTitle tag='div' className='h5'>
									Schedule
								</CardTitle>
							</CardLabel>
							<CardActions>
								<CardTitle tag='div' className='h5 text-success'>{dayOrder}</CardTitle>
							</CardActions>
						</CardHeader>
						<CardBody>
							{userTypeId == 1 ? (
								<>
									<div className='row'>
										<div className='col-2'>
											<FormGroup id='mediumId' label='Medium' isFloating>
												<SearchableSelect
													isFloating
													ariaLabel='Medium'
													onChange={selectMedium}
													value={mediumId}
													list={mediumData.map((data: any) => ({
														value: data.mediumId,
														label: data.medium,
													}))}
												/>
											</FormGroup>
										</div>

										{mediumId?.value != undefined ? (
											<div className='col-2'>
												<FormGroup
													id='standardDetailsId'
													label='Standard'
													isFloating>
													<SearchableSelect
														isFloating
														ariaLabel='Standard'
														onChange={selectStandard}
														value={standardDetailsId}
														list={standardData.map((data: any) => ({
															value: data.standardDetailsId,
															label: data.standardName,
														}))}
													/>
												</FormGroup>
											</div>
										) : null}

										{standardDetailsId?.value != undefined ? (
											<div className='col-2'>
												<FormGroup
													id='sectionDetailsId'
													label='Section'
													isFloating>
													<SearchableSelect
														isFloating
														ariaLabel='Section'
														onChange={selectSection}
														value={sectionDetailsId}
														list={sectionDetailsData.map(
															(data: any) => ({
																value: data.sectionDetailsId,
																label: data.sectionName,
															}),
														)}
													/>
												</FormGroup>
											</div>
										) : null}

										{sectionDetailsId?.value != undefined ? (
											<div className='col-2'>
												<FormGroup
													id='dayOrderTypeId'
													label='Day Order'
													isFloating>
													<SearchableSelect
														isFloating
														ariaLabel='DayOrder'
														onChange={selectDayOrder}
														value={dayOrderTypeId}
														list={dayOrderData.map((data: any) => ({
															value: data.dayOrderTypeId,
															label: data.dayOrderName,
														}))}
													/>
												</FormGroup>
											</div>
										) : null}

										{dayOrderTypeId ? (
											<div className='col-3 mt-2'>
												<Button
													icon='ArrowDownward'
													color='primary'
													onClick={viewForAdminLogin}>
													View
												</Button>
											</div>
										) : null}
									</div>
								</>
							) : (
								<div className='row g-4'>
									<div className='col-md-2'>
										<FormGroup id='date' label='Date' isFloating>
											<Input
												onChange={staffScheduleForm.handleChange}
												value={staffScheduleForm.values.date}
												onBlur={staffScheduleForm.handleBlur}
												isValid={staffScheduleForm.isValid}
												isTouched={staffScheduleForm.touched.date}
												invalidFeedback={staffScheduleForm.errors.date}
												type='date'
											/>
										</FormGroup>
									</div>

									<div className='col-md-2'>
										<Button
											className='mt-2'
											icon='ArrowDownward'
											color='primary'
											onClick={ViewSchedule}>
											View
										</Button>
									</div>
								</div>
							)}
						</CardBody>
					</Card>
				</Collapse>

				<OpenCardComponent
					isOpenListCard={isOpenListCard}
					setIsOpenListCard={setIsOpenListCard}
					isLoader={isLoader}
				/>
				{dataSuccess ? (
					<>
						{scheduleData != '' && dayId > 0 && dayId != 7 ? (
							<>
								<div className='col-12'>
									<div className='row'>
										{scheduleData.map((period: any) => (
											<div className='col-3' key={period.periodDetailsId}>
												<Card stretch shadow={'md'} className="border border-light">
													<CardBody>
														<div className='row g-3 align-items-center'>
															<div className='col d-flex align-items-center'>
																<div className='flex-shrink-0'>
																	<div
																		className='ratio ratio-1x1'
																		style={{ width: 65 }}>
																		<div
																			className={classNames(
																				'rounded-2',
																				'd-flex align-items-center justify-content-center',
																				{
																					'bg-l10-info':
																						!darkModeStatus,
																					'bg-lo25-info':
																						darkModeStatus,
																				},
																			)}>
																			<span className='fw-bold'>
																				<span className='ms-1 text-info fs-2'>
																					{period.periodCount}
																				</span><br />
																				{userTypeId == 1 ?
																					<small className='text-dark fs-6'>
																						{standardDetailsId?.label} - {sectionDetailsId?.label}
																					</small> : null
																				}
																			</span>
																		</div>
																	</div>
																</div>
																<div className='flex-grow-1 ms-3 d-flex justify-content-between align-items-center'>
																	<div>
																		<small className='fw-bold'>
																			{period.paperName}
																		</small>
																		{userTypeId == 1 ?
																			<div className=' text-info fs-6 mb-0'>
																				{period.staffName}
																			</div> :
																			<div className=' text-success fs-6 mb-0'>
																				{period.standardName} - {period.sectionName}
																			</div>
																		}
																		<div className='text-info'>
																			<small>
																				<span className='text-black-50 fw-bold'>
																					{period.startTime}-{period.endTime}
																				</span>
																			</small>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</CardBody>
												</Card>
											</div>
										))}{' '}
									</div>
								</div>
							</>
						) : (
							<div className='row'>
								<div className='col-12 d-flex justify-content-center'>
									{scheduleData != '' && (dayId == 0 || dayId == 7) ?
										<div className='row'>
											<div className="col-12" style={{ height: '10vh' }}></div>
											<div className='col-12 d-flex justify-content-center'>
												<Icon icon='CalendarToday' size={'10x'} />
											</div>
											<div className='fs-4 fw-bold text-center text-uppercase text-danger'>
												Holiday
											</div>
										</div> :
										<div className='row'>
											<div className='col-12 d-flex justify-content-center'>
												<img
													srcSet={HumansWebp}
													src={Humans}
													alt='Humans'
													style={{ height: '40vh' }}
												/>
											</div>
											<div className='fs-4 fw-bold text-center text-uppercase text-danger'>
												Schedule Not Allocated
											</div>
										</div>
									}
								</div>
							</div>
						)}
					</>
				) : null}
			</Page>
		</PageWrapper>
	);
};

export default PeriodScheduleForStaff;
