import { useContext, useEffect, useRef, useState } from "react";
import Page from "../../../layout/Page/Page";
import Collapse from "../../bootstrap/Collapse";
import { toasts } from "../../../services/toast.service";
import useDarkMode from "../../../hooks/useDarkMode";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../../bootstrap/Card";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import FormGroup from "../../bootstrap/forms/FormGroup";
import Button from "../../bootstrap/Button";
import AlertService from "../../../services/AlertService";
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from "../../bootstrap/OffCanvas";
import { useReactToPrint } from "react-to-print";
import OpenCardComponent from "../../../common/components/OpenCardComponent";
import DeleteComponents from "../../../common/components/DeleteComponents";
import { getBatchMasterDetails, getSectionDetails, getShift } from "../../../services/master.service";
import { getCurrentDateAndTime, getMedium, getStandardDetails } from "../../../services/common.service";
import { getStaffSubjectAllocation } from "../../../services/subject.service";
import { getLicenseKey } from "../../../services/application.settings";
import AuthContext from "../../../contexts/authContext";
import { showLoader } from "../../../services/loader.services";
import SearchableSelect from "../../../common/components/SearchableSelect";
import { addClassTimeTable, deleteTimeTableMasterDetails, getAllocatedTimeTable, getAllocatedTimeTableMaster, getDayOrder, getPeriodListByShiftId, updateTimeTableMasterDetails } from "../../../services/timeTableService";
import HumansWebp from '../../../assets/img/scene2.webp';
import Humans from '../../../assets/img/scene2.png';

function TimeTableAllocation() {

    useEffect(() => {
        getMediumList();
    }, [])

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const componentRef = useRef(null);
    const [shiftId, setShiftId] = useState<any>('')
    const [timeTableMasterId, setTimeTableMasterId] = useState<any>('')
    const [shiftData, setShiftData] = useState<any>([])

    const [staffSubjectAllocationData, setStaffSubjectAllocationData] = useState<any>([])
    const [timeTableAllocationData, setTimeTableAllocationData] = useState<any>([])
    const [periodDetailsData, setPeriodDetailsData] = useState<any>([])
    const [dayOrderTypeData, setDayOrderTypeData] = useState<any>([])
    const [timeTableMasterDetailsData, setTimeTableMasterDetailsData] = useState<any>([])
    const [day, setDay] = useState<any>([])

    const [timeTableDetails, setTimeTableDetails] = useState<any>([])
    const [updateTimeTable, setUpdateTimeTable] = useState<any>([])
    const [staffSubjectAllocationDetailsId, setStaffSubjectAllocationDetailsId] = useState<any>([])

    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);
    const [dataSuccess, setDataSuccess] = useState(false)
    const [isReport, setIsReport] = useState(false)
    const [isOpenListCard, setIsOpenListCard] = useState(true)
    const [isLoader, setIsLoader] = useState(false)
    const [editTimeTableOffcanvas, setEditTimeTableOffcanvas] = useState(false)
    const [isDeleteOpen, setIsDeleteOpen] = useState(false)

    const [standardDetailsId, setStandardDetailsId] = useState<any>('')
    const [mediumId, setMediumId] = useState<any>('')
    const [standardData, setStandardData] = useState<any>([])
    const [mediumData, setMediumData] = useState<any>([])
    const [sectionDetailsId, setSectionDetailsId] = useState<any>('')
    const [sectionData, setSectionData] = useState<any>([])

    const [batchMasterId, setBatchMasterId] = useState<any>('')

    const selectMedium = (e: any) => {
        setStandardDetailsId('')
        setSectionDetailsId('')
        setShiftId('')
        let mediumId = e
        setMediumId(mediumId)
        getBatchMasterList()
    }

    const selectBatch = (e: any) => {
        let batchMasterId = e
        setBatchMasterId(batchMasterId)
        getStandardList();
    }

    const selectStandard = (e: any) => {
        setSectionDetailsId('')
        setShiftId('')
        let standardDetailsId = e
        setStandardDetailsId(standardDetailsId)
        getSectionDetailsList(mediumId?.value, standardDetailsId?.value)
    }

    const selectSectionDetails = (e: any) => {
        setShiftId('')
        let sectionDetailsId = e
        setSectionDetailsId(sectionDetailsId)
        getShiftList()
    }

    const selectShift = (e: any) => {
        let shiftId = e
        setShiftId(shiftId)
    }

    function viewTimeTableAllocationList() {
        setTimeTableDetails([])
        setIsLoader(true);
        setDataSuccess(false);
        getDayOrderTypeByIsDayOrder()
        getStaffSubjectAllocationList(batchMasterId?.value, standardDetailsId?.value, sectionDetailsId?.value, 1)
    }

    function viewTimeList(dayId: any) {
        getStaffSubjectAllocationList1(batchMasterId?.value, standardDetailsId?.value, sectionDetailsId?.value, 1);
        getTimeTableMasterDetails(sectionDetailsId?.value, dayId);
    }

    function setTimeTableDetailsData() {
        return {
            batchMasterId: batchMasterId?.value,
            standardDetailsId: standardDetailsId?.value,
            sectionDetailsId: sectionDetailsId?.value,
            timeTableDetails: timeTableDetails,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId
        }
    }

    function setTimeTableEditDetails() {
        return {
            timeTableDetails: updateTimeTable,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId
        }
    }

    function setDelTimeTableDetails() {
        return {
            timeTableMasterId: timeTableMasterId,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId
        }
    }

    function addTimeTableDetails(dayOrderTypeId: any, shiftId: any, periodDetailsId: any, staffSubjectAllocationDetailsId: any) {

        if (timeTableDetails.length == 0) {
            var obj
            obj = {
                dayId: dayOrderTypeId,
                shiftId: shiftId,
                periodDetailsId: periodDetailsId,
                staffSubjectAllocationDetailsId: staffSubjectAllocationDetailsId != null ? staffSubjectAllocationDetailsId?.value : null
            };
            timeTableDetails.push(obj);
        } else {
            let selectedItem: any
            timeTableDetails.forEach((item: any, index: string) => {
                if (item['dayId'] == dayOrderTypeId && item['shiftId'] == shiftId && item['periodDetailsId'] == periodDetailsId) {
                    selectedItem = item
                }
            });
            if (selectedItem != undefined) {
                selectedItem['staffSubjectAllocationDetailsId'] = staffSubjectAllocationDetailsId != null ? staffSubjectAllocationDetailsId?.value : null
            } else {
                var obj
                obj = {
                    dayId: dayOrderTypeId,
                    shiftId: shiftId,
                    periodDetailsId: periodDetailsId,
                    staffSubjectAllocationDetailsId: staffSubjectAllocationDetailsId != null ? staffSubjectAllocationDetailsId?.value : null
                };
                timeTableDetails.push(obj);
            }
        }
    }

    function updateTimeTableDetails(timeTableMasterDetailsId: number, staffSubjectAllocationDetailsId: any) {

        const newData: any = [];
        timeTableMasterDetailsData.forEach((item: any, index: any) => {
            if (item['timeTableMasterDetailsId'] == timeTableMasterDetailsId) {
                item['staffSubjectAllocationDetailsId'] = staffSubjectAllocationDetailsId
            }
            newData.push(item)
        });

        setTimeTableMasterDetailsData(newData)

        if (updateTimeTable.length == 0) {
            var obj
            obj = {
                timeTableMasterDetailsId: timeTableMasterDetailsId,
                staffSubjectAllocationDetailsId: staffSubjectAllocationDetailsId != null ? staffSubjectAllocationDetailsId?.value : null
            };
            updateTimeTable.push(obj);

        } else {
            let selectedItem: any
            updateTimeTable.forEach((item: any, index: number) => {
                if (item['timeTableMasterDetailsId'] == timeTableMasterDetailsId) {
                    selectedItem = item
                }
            });
            if (selectedItem != undefined) {
                selectedItem['timeTableMasterDetailsId'] = timeTableMasterDetailsId
            } else {
                var obj
                obj = {
                    timeTableMasterDetailsId: timeTableMasterDetailsId,
                    staffSubjectAllocationDetailsId: staffSubjectAllocationDetailsId != null ? staffSubjectAllocationDetailsId?.value : null
                };
                updateTimeTable.push(obj);
            }
        }
    }

    function timeTableAllocationSubmit() {
        showLoader(true)
        if (timeTableDetails.length == 48) {
            let timeTablePostData = setTimeTableDetailsData()
            addClassTimeTable(timeTablePostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        viewTimeTableAllocationList();
                        setTimeTableDetails([])
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        showLoader(false)
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }, (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function updateTimeTableAllocation() {
        showLoader(true)
        if (updateTimeTable.length > 0) {
            let timeTableEditPostData = setTimeTableEditDetails()
            updateTimeTableMasterDetails(timeTableEditPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        getAllocatedTimeTableList(sectionDetailsId?.value)
                        setUpdateTimeTable([])
                        setEditTimeTableOffcanvas(false)
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        showLoader(false)
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }, (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function deleteTimeTableMasterSubmit() {
        setIsDeleteOpen(false)
        showLoader(true)
        if (timeTableMasterId != '') {
            let delTimeTablePostData = setDelTimeTableDetails()
            deleteTimeTableMasterDetails(delTimeTablePostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        viewTimeTableAllocationList();
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        showLoader(false)
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }, (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (timeTableMasterId == '') {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    const [batchMasterData, setBatchMasterData] = useState([])

    function getBatchMasterList() {
        getBatchMasterDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.batchMasterDetails;
                    if (data != undefined) {
                        setBatchMasterData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setBatchMasterData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStandardList() {
        getStandardDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.standardDetails;
                    if (data != undefined) {
                        setStandardData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setStandardData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getMediumList() {
        getMedium(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.medium;
                    if (data != undefined) {
                        setMediumData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setMediumData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getSectionDetailsList(mediumId: any, standardDetailsId: any) {
        getSectionDetails(mediumId, standardDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.sectionDetails;
                    if (data != undefined) {
                        setSectionData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setSectionData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getShiftList() {
        getShift(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.shift;
                    if (data != undefined) {
                        setShiftData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setShiftData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStaffSubjectAllocationList(batchMasterId: any, standardDetailsId: any, sectionDetailsId: any, shift: any) {
        getStaffSubjectAllocation(batchMasterId, standardDetailsId, sectionDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.staffSubjectAllocation;
                    if (data != undefined) {
                        setStaffSubjectAllocationData(data);
                        getAllocatedTimeTableList(sectionDetailsId)
                        getPeriodForTimeTable(shift)
                        // setIsLoader(false);
                        // setIsOpenListCard(false);
                        // setDataSuccess(true);
                    } else {
                        setIsLoader(false);
                        setIsOpenListCard(true);
                        setDataSuccess(false);
                        // toasts("Undefined Data", "Error")
                        setAlertStatus({ message: "Undefined Data", type: "error" });
                        setIsOpen(true);
                    }
                }
                else if (response.data.success === false) {
                    setIsLoader(false);
                    setIsOpenListCard(true);
                    setDataSuccess(false);
                    setAlertStatus({ message: response.data.message, type: "error" });
                    setIsOpen(true);
                    setStaffSubjectAllocationData([]);
                } else {
                    setIsLoader(false);
                    setIsOpenListCard(true);
                    setDataSuccess(false);
                    setIsReport(false)
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                setIsLoader(false);
                setIsOpenListCard(true);
                setDataSuccess(false);
                setIsReport(false)
                toasts(error, "Error")
            }
        )
    }

    function getStaffSubjectAllocationList1(batchMasterId: any, standardDetailsId: any, sectionDetailsId: any, shift: any) {
        getStaffSubjectAllocation(batchMasterId, standardDetailsId, sectionDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.staffSubjectAllocation;
                    if (data != undefined) {
                        setStaffSubjectAllocationData(data);
                        getAllocatedTimeTableList(sectionDetailsId)
                        getPeriodForTimeTable(shift)
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getAllocatedTimeTableList(sectionDetailsId: number,) {
        getAllocatedTimeTable(sectionDetailsId, 0,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.allocatedTimeTable[0].dayOrder;
                    let timeTableMasterId = response.data.data.allocatedTimeTable[0].timeTableMasterId;
                    if (data != undefined) {
                        setTimeTableAllocationData(data);
                        setTimeTableMasterId(timeTableMasterId);
                        setIsReport(true)
                    } else {
                        setDataSuccess(false);
                        setAlertStatus({ message: "Undefined Data", type: "error" });
                        setIsOpen(true);
                    }
                }
                else if (response.data.success === false) {
                    setIsReport(false)
                    // setAlertStatus({ message: response.data.message, type: "error" });
                    // setIsOpen(true);
                    setNoDataMsg(response.data.message)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setDataSuccess(false);
                    setAlertStatus({ message: errorDescription, type: "error" });
                    setIsOpen(true);
                }
            }, error => {
                setDataSuccess(false);
                setAlertStatus({ message: error, type: "error" });
                setIsOpen(true);
            }
        )
    }

    const [noDataMsg, setNoDataMsg] = useState<any>('')

    function getTimeTableMasterDetails(sectionDetailsId: number, dayId: number) {
        getAllocatedTimeTableMaster(sectionDetailsId, dayId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.allocatedTimeTable[0].dayOrder[0].period;
                    let day = response.data.data.allocatedTimeTable[0].dayOrder[0].day;
                    if (data != undefined) {

                        for (let i = 0; i < data.length; i++) {
                            data[i].staffSubjectAllocationDetailsId = { value: data[i].staffSubjectAllocationDetailsId, label: data[i].paperWithStaff };
                        }

                        setDay(day);
                        setEditTimeTableOffcanvas(true)
                        setTimeTableMasterDetailsData(data);

                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getPeriodForTimeTable(shift: number) {
        getPeriodListByShiftId(shift,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.period;
                    if (data != undefined) {
                        setPeriodDetailsData(data);
                        setIsLoader(false);
                        setIsOpenListCard(false);
                        setDataSuccess(true);
                    } else {
                        setIsLoader(false);
                        setIsOpenListCard(true);
                        setDataSuccess(false);
                        setAlertStatus({ message: "Undefined Data", type: "error" });
                        setIsOpen(true);
                    }
                }
                else if (response.data.success === false) {
                    setIsLoader(false);
                    setIsOpenListCard(true);
                    setDataSuccess(false);
                    setAlertStatus({ message: response.data.message, type: "error" });
                    setIsOpen(true);
                } else {
                    setIsLoader(false);
                    setIsOpenListCard(true);
                    setDataSuccess(false);
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                setIsLoader(false);
                setIsOpenListCard(true);
                setDataSuccess(false);
                toasts(error, "Error")
            }
        );
    }

    function getDayOrderTypeByIsDayOrder() {
        getDayOrder(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.dayOrderType;
                    if (data != undefined) {
                        setDayOrderTypeData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    // Export Configuration
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <PageWrapper title={`Class Time Table Allocation`}>
            <Page container='fluid'>
                <Collapse isOpen={isOpenListCard}>
                    <Card stretch data-tour='list'>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info'>
                                <CardTitle tag='div' className='h5'>
                                    Class Time Table Allocation
                                </CardTitle>
                            </CardLabel>
                        </CardHeader>
                        <CardBody>
                            <div className='row g-4'>
                                <div className='col-md-2'>
                                    <FormGroup id='mediumId' label='Medium' isFloating>
                                        <SearchableSelect isFloating ariaLabel='Medium' placeholder="Select Standard"
                                            onChange={(e: any) => selectMedium(e)}
                                            value={mediumId}
                                            list={mediumData.map((data: any) => ({ value: data.mediumId, label: data.medium }))} required />

                                    </FormGroup>
                                </div>

                                {mediumId?.value != undefined ?
                                    <div className='col-md-2'>
                                        <FormGroup id='batchMasterId' label='Batch' isFloating>
                                            <SearchableSelect isFloating ariaLabel='Batch' placeholder="Select Batch"
                                                onChange={(e: any) => selectBatch(e)}
                                                value={batchMasterId}
                                                list={batchMasterData.map((data: any) => (
                                                    { value: data.batchMasterId, label: data.batchName }
                                                ))} required
                                            />
                                        </FormGroup>
                                    </div> : null}

                                {batchMasterId?.value != undefined ?
                                    <div className='col-md-2'>
                                        <FormGroup id='standardDetailsId' label='Standard' isFloating>
                                            <SearchableSelect isFloating ariaLabel='Standard' placeholder="Select Standard"
                                                onChange={(e: any) => selectStandard(e)}
                                                value={standardDetailsId}
                                                list={standardData.map((data: any) => (
                                                    { value: data.standardDetailsId, label: data.standardName }
                                                ))} required
                                            />
                                        </FormGroup>
                                    </div> : null}

                                {standardDetailsId?.value != undefined ?
                                    <div className='col-md-2'>
                                        <FormGroup id='sectionDetailsId' label='Section' isFloating>
                                            <SearchableSelect isFloating ariaLabel='Section' placeholder="Select Section"
                                                onChange={(e: any) => selectSectionDetails(e)}
                                                value={sectionDetailsId}
                                                list={sectionData.map((data: any) => (
                                                    { value: data.sectionDetailsId, label: data.sectionName }
                                                ))} required
                                            />
                                        </FormGroup>
                                    </div> : null}

                                {/* {sectionDetailsId?.value != undefined ?
                                    <div className='col-md-2 '>
                                        <FormGroup id='shift' label='Shift' isFloating>
                                            <SearchableSelect isFloating ariaLabel='Shift' placeholder="Select Shift"
                                                onChange={(e: any) => selectShift(e)}
                                                value={shiftId}
                                                list={shiftData.map((data: any) => (
                                                    { value: data.shiftId, label: data.shift }
                                                ))}
                                            />
                                        </FormGroup>
                                    </div> : null} */}

                                {sectionDetailsId?.value != undefined ? <div className="col-md-2">
                                    <Button className="mt-2" icon='ArrowDownward' color='primary' onClick={viewTimeTableAllocationList}>View</Button>
                                </div> : null}
                            </div>
                        </CardBody>
                    </Card>
                </Collapse>

                <OpenCardComponent isOpenListCard={isOpenListCard} setIsOpenListCard={setIsOpenListCard} isLoader={isLoader} />

                {dataSuccess ?
                    <>
                        {isReport ?
                            <Card stretch data-tour='list' ref={componentRef}>
                                <CardHeader borderSize={1}>
                                    <CardLabel icon='List' iconColor='info' className="col-lg-8">
                                        <CardTitle tag='div' className='h5'>Class Time Table</CardTitle>
                                    </CardLabel>&nbsp;&nbsp;

                                    {userTypeId == 1 ?
                                        <CardActions className="d-print-none">
                                            <Button color='primary' icon='Print' isLight onClick={handlePrint}>
                                                Print
                                            </Button>

                                            <Button color='danger' icon='Delete' isLight onClick={() => setIsDeleteOpen(!isDeleteOpen)}>
                                                Delete
                                            </Button>
                                        </CardActions> : null
                                    }
                                </CardHeader>

                                <div className="d-flex justify-content-center col-12">
                                    <div className="d-flex justify-content-center col-12">
                                        <DeleteComponents isDeleteOpen={isDeleteOpen} setIsDeleteOpen={setIsDeleteOpen} deleteFunction={deleteTimeTableMasterSubmit} />
                                    </div>
                                </div>

                                <CardBody className='table-responsive'>
                                    {/* <table className="table table-modern table-hover">
                                        <thead className="table-info text-center text-uppercase align-middle">
                                            <tr>
                                                <th className="table-info text-uppercase">Day</th>
                                                {periodDetailsData.map((periodDetails: any) => (
                                                    <th key={periodDetails.periodDetailsId} className="table-info text-uppercase">{periodDetails.periodName}<br />{periodDetails.startTime}<br />{periodDetails.endTime}</th>
                                                ))}
                                                <th className="table-info text-uppercase d-print-none">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {timeTableAllocationData.map((dayOrderType: any) => (
                                                <tr key={dayOrderType.dayId} className="text-center text-wrap align-middle">
                                                    <td width="10px table-info"><label><strong>{dayOrderType.day}</strong></label></td>
                                                    <td width="12px">
                                                        <label className="text-primary">{dayOrderType.period[0].paperName}</label>
                                                        <br />
                                                        ({dayOrderType.period[0].staffName})
                                                    </td>
                                                    <td width="12px">
                                                        <label className="text-primary">{dayOrderType.period[1].paperName}</label><br />(
                                                        {dayOrderType.period[1].staffName})
                                                    </td>
                                                    <td width="12px">
                                                        <label className="text-primary">{dayOrderType.period[2].paperName}</label><br />({dayOrderType.period[2].staffName})
                                                    </td>
                                                    <td width="12px">
                                                        <label className="text-primary">{dayOrderType.period[3].paperName}</label><br />({dayOrderType.period[3].staffName})
                                                    </td>                                                    
                                                    <td width="12px">
                                                        <label className="text-primary">{dayOrderType.period[4].paperName}</label><br />({dayOrderType.period[4].staffName})
                                                    </td>
                                                    <td width="12px">
                                                        <label className="text-primary">{dayOrderType.period[5].paperName}</label><br />({dayOrderType.period[5].staffName})
                                                    </td>
                                                    <td width="12px">
                                                        <label className="text-primary">{dayOrderType.period[6].paperName}</label><br />({dayOrderType.period[6].staffName})
                                                    </td>
                                                    <td width="12px">
                                                        <label className="text-primary">{dayOrderType.period[7].paperName}</label><br />({dayOrderType.period[7].staffName})
                                                    </td>
                                                    <td width="12px" className="d-print-none">
                                                        <Button color='dark' isLight icon='Edit' aria-label='Edit' onClick={() => viewTimeList(dayOrderType.dayId)} /></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table> */}

                                    <table className="table table-modern table-hover table-responsive" >
                                        <thead className="table-info text-center text-uppercase align-middle">
                                            <tr>
                                                <th className="table-info text-uppercase">Day</th>
                                                {periodDetailsData.map((periodDetails: any) => (
                                                    <th key={periodDetails.periodDetailsId} className="table-info text-uppercase">{periodDetails.periodName}<br />{periodDetails.startTime}<br />{periodDetails.endTime}</th>
                                                ))}
                                                {userTypeId == 1 ?
                                                    <th className="table-info text-uppercase d-print-none">Action</th> : null
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dayOrderTypeData.map((dayOrderType: any) => (
                                                <tr key={dayOrderType.dayOrderTypeId}>
                                                    <td>
                                                        <div className="mt-2 text-center"><strong>{dayOrderType.dayOrderName}</strong></div>
                                                    </td>
                                                    {periodDetailsData.map((periodDetails: any) => (

                                                        <td key={periodDetails.periodDetailsId}>
                                                            {periodDetails.isBreakPeriod ?
                                                                <div className="mt-2 text-center text-danger">
                                                                    <strong>{periodDetails.periodName}</strong>
                                                                </div> :
                                                                timeTableAllocationData.map((timeTable: any) => (
                                                                    timeTable.period.map((period: any) => (
                                                                        <div className="mt-2 text-center">
                                                                            {(periodDetails.periodDetailsId == period.periodDetailsId) && (dayOrderType.dayOrderTypeId == timeTable.dayId) ?
                                                                                <div>
                                                                                    <label className="text-primary">{period.paperName}</label><br /><label>({period.staffName})</label>
                                                                                </div> : null}
                                                                        </div>
                                                                    ))
                                                                ))}
                                                        </td>
                                                    ))}
                                                    {userTypeId == 1 ?
                                                        <td width="12px" className="d-print-none">
                                                            <Button color='dark' isLight icon='Edit' aria-label='Edit' onClick={() => viewTimeList(dayOrderType.dayOrderTypeId)} /></td> : null
                                                    }
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                    {/* <table className="table table-modern table-hover table-responsive" >
                                        <thead className="table-info text-center text-uppercase align-middle">
                                            <tr>
                                                <th style={{ width: '50px' }} className="table-info text-uppercase">Period</th>
                                                {dayOrderTypeData.map((dayOrderType: any) => (
                                                    <th style={{ width: '100px' }} key={dayOrderType.dayOrderTypeId} className="table-info text-uppercase">{dayOrderType.dayOrderName}</th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {periodDetailsData.map((periodDetails: any) => (
                                                <tr key={periodDetails.periodDetailsId}>
                                                    <td>
                                                        <div className="mt-2 text-center"><strong>{periodDetails.periodName}<br />{periodDetails.startTime}<br />{periodDetails.endTime}</strong></div>
                                                    </td>
                                                    {dayOrderTypeData.map((dayOrderType: any) => (

                                                        <td key={periodDetails.periodDetailsId}>
                                                            {periodDetails.isBreakPeriod ?
                                                                <div className="mt-2 text-center text-danger">
                                                                    <strong>{periodDetails.periodName}</strong>
                                                                </div> :
                                                                timeTableAllocationData.map((timeTable: any) => (
                                                                    timeTable.period.map((period: any) => (
                                                                        <div className="mt-2 text-center">
                                                                            {(periodDetails.periodDetailsId == period.periodDetailsId) && (dayOrderType.dayOrderTypeId == timeTable.dayId) ?
                                                                                <strong>{period.paperName}</strong> : null}
                                                                        </div>
                                                                    ))
                                                                ))}
                                                        </td>
                                                    ))}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table> */}
                                    <h6 className="mg-lg-t-10">Date: {getCurrentDateAndTime('dateTime')}</h6>
                                </CardBody>
                            </Card>
                            : userTypeId == 1 ?
                                <Card stretch ref={componentRef}>
                                    <CardHeader borderSize={1}>
                                        <div className='d-flex col-5 justify-content-start'>
                                            <CardLabel icon='List' iconColor='info'>
                                                <CardTitle tag='div' className='h5'>Time Table</CardTitle>
                                            </CardLabel>
                                        </div>
                                    </CardHeader>

                                    <CardBody>
                                        <table className="table table-modern table-hover table-responsive" >
                                            <thead className="table-info text-center text-uppercase align-middle">
                                                <tr>
                                                    <th style={{ width: '50px' }} className="table-info text-uppercase">Period</th>
                                                    {dayOrderTypeData.map((dayOrderType: any) => (
                                                        <th style={{ width: '100px' }} key={dayOrderType.dayOrderTypeId} className="table-info text-uppercase">{dayOrderType.dayOrderName}</th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {periodDetailsData.map((periodDetails: any) => (
                                                    <tr key={periodDetails.periodDetailsId}>
                                                        <td>
                                                            <div className="mt-2 text-center"><strong>{periodDetails.periodName}<br />{periodDetails.startTime}<br />{periodDetails.endTime}</strong></div>
                                                        </td>
                                                        {dayOrderTypeData.map((dayOrderType: any) => (

                                                            <td key={periodDetails.periodDetailsId}>
                                                                {periodDetails.isBreakPeriod ?
                                                                    <div className="mt-2 text-center text-danger">
                                                                        <strong>{periodDetails.periodName}</strong>
                                                                    </div> :
                                                                    <FormGroup id='staffSubjectAllocationDetailsId'>
                                                                        <SearchableSelect ariaLabel='syllabusPaper' placeholder="Select Subject" onChange={(e: any) => addTimeTableDetails(dayOrderType.dayOrderTypeId, periodDetails.shiftId, periodDetails.periodDetailsId, e)}
                                                                            list={staffSubjectAllocationData.map((data: any) => (
                                                                                { value: data.staffSubjectAllocationDetailsId, label: data.paperWithStaff }
                                                                            ))} />
                                                                    </FormGroup>
                                                                }
                                                            </td>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>

                                        <div className="col-lg-12 d-flex justify-content-end">
                                            <FormGroup>
                                                <Button icon="Save" color="info" type="submit" onClick={timeTableAllocationSubmit}>
                                                    Save
                                                </Button>
                                            </FormGroup>
                                        </div>
                                    </CardBody>

                                    {/* <CardBody>
                                    <table className="table table-modern table-hover table-responsive" >
                                        <thead className="table-info text-center text-uppercase align-middle">
                                            <tr>
                                                <th style={{ width: '50px' }} className="table-info text-uppercase">Day</th>
                                                {periodDetailsData.map((periodDetails: any) => (
                                                    <th style={{ width: '100px' }} key={periodDetails.periodDetailsId} className="table-info text-uppercase">{periodDetails.periodName}<br />{periodDetails.startTime}<br />{periodDetails.endTime}</th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dayOrderTypeData.map((dayOrderType: any) => (
                                                <tr key={dayOrderType.dayOrderTypeId}>
                                                    <td>
                                                        <div className="mt-2 text-center"><strong>{dayOrderType.dayOrderName}</strong></div>
                                                    </td>
                                                    {periodDetailsData.map((periodDetails: any) => (

                                                        <td key={periodDetails.periodDetailsId}>
                                                            {periodDetails.isBreakPeriod ?
                                                                <div className="mt-2 text-center">
                                                                    <strong>{periodDetails.periodName}</strong>
                                                                </div> :
                                                                <FormGroup id='staffSubjectAllocationDetailsId'>
                                                                    <SearchableSelect ariaLabel='syllabusPaper' placeholder="Select Subject" onChange={(e: any) => addTimeTableDetails(dayOrderType.dayOrderTypeId, periodDetails.shiftId, periodDetails.periodDetailsId, e)}
                                                                        list={staffSubjectAllocationData.map((data: any) => (
                                                                            { value: data.staffSubjectAllocationDetailsId, label: data.paperWithStaff }
                                                                        ))} />
                                                                </FormGroup>
                                                            }
                                                        </td>
                                                    ))}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                    <div className="col-lg-12 d-flex justify-content-end">
                                        <FormGroup>
                                            <Button icon="Save" color="info" type="submit" onClick={timeTableAllocationSubmit}>
                                                Save
                                            </Button>
                                        </FormGroup>
                                    </div>
                                </CardBody> */}
                                </Card> :
                                <div className='row'>
                                    <div className='col-12 d-flex justify-content-center'>
                                        <img
                                            srcSet={HumansWebp}
                                            src={Humans}
                                            alt='Humans'
                                            style={{ height: '50vh' }}
                                        />
                                    </div>
                                    <div className='fs-4 fw-bold d-flex justify-content-center text-center text-uppercase text-danger'>Time Table Not Created. Please Contact Admin</div>
                                </div>
                        }
                    </>
                    : null
                }

                <OffCanvas setOpen={setEditTimeTableOffcanvas} isOpen={editTimeTableOffcanvas} titleId='editTimeTableOffcanvas' placement='end' isBodyScroll>
                    <OffCanvasHeader setOpen={setEditTimeTableOffcanvas}>
                        <OffCanvasTitle id='editTimeTableOffcanvas'>Update Time Table</OffCanvasTitle>
                    </OffCanvasHeader>
                    <OffCanvasBody>

                        <table className="table table-bordered">
                            <thead>
                                <tr><th className="bg-primary text-white text-center"><label>{day}</label></th></tr>
                            </thead>
                        </table>
                        <div className="row g-4">
                            {timeTableMasterDetailsData.map((timeTable: any) => (

                                <div className="col-md-12" key={timeTable.periodDetailsId}>
                                    <FormGroup id='staffSubjectAllocationDetailsId' label={timeTable.periodName}>
                                        <SearchableSelect ariaLabel='syllabusPaper' placeholder="Select Paper"
                                            value={timeTable.staffSubjectAllocationDetailsId}
                                            onChange={(e: any) => updateTimeTableDetails(timeTable.timeTableMasterDetailsId, e)}
                                            list={staffSubjectAllocationData.map((data: any) => (
                                                { value: data.staffSubjectAllocationDetailsId, label: data.paperWithStaff }
                                            ))} />
                                    </FormGroup>
                                </div>
                            ))}
                        </div>
                    </OffCanvasBody>
                    <div className='row m-0'>
                        <div className='col-12 p-3'>
                            <Button color='info' className='w-100' type="submit" icon="Save" onClick={updateTimeTableAllocation}>
                                Save
                            </Button>
                        </div>
                    </div>
                </OffCanvas>

                <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />

            </Page>
        </PageWrapper>
    )
}
export default TimeTableAllocation