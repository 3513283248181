import React, { useContext, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import Button, { ButtonGroup } from '../../../components/bootstrap/Button';
import Page from '../../../layout/Page/Page';
import Card, {
    CardActions,
    CardBody,
    CardHeader,
    CardLabel,
    CardTitle,
} from '../../../components/bootstrap/Card';
import Checks, { ChecksGroup } from '../../../components/bootstrap/forms/Checks';
import Input from '../../../components/bootstrap/forms/Input';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import { dataPagination } from '../../../components/PaginationButtons';
import useSortableData from '../../../hooks/useSortableData';
import Icon from '../../../components/icon/Icon';
import useSelectTable from '../../../hooks/useSelectTable';
import { toasts } from '../../../services/toast.service';
import Collapse from '../../bootstrap/Collapse';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import OpenCardComponent from '../../../common/components/OpenCardComponent';
import AlertService from '../../../services/AlertService';
import { getBatchMasterDetails, getSectionDetails } from '../../../services/master.service';
import AuthContext from '../../../contexts/authContext';
import { getLicenseKey } from '../../../services/application.settings';
import { showLoader } from '../../../services/loader.services';
import NoDataMsg from '../../../common/components/NoDataMsg';
import SearchableSelect from '../../../common/components/SearchableSelect';
import { getStudentDetailsBySectionDetailsId, updateStudentStandard } from '../../../services/student.service';
import { getMedium, getStandardDetails } from '../../../services/common.service';

const StudentRollBack = () => {

    useEffect(() => {
        getBatchList()
    }, [])

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const [studentRollbackData, setStudentRollbackData] = useState([])
    const [isOpenListCard, setIsOpenListCard] = useState(true)
    const [dataSuccess, setDataSuccess] = useState(false)
    const [isLoader, setIsLoader] = useState(false)

    // Table States
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10000);
    const { items, requestSort, getClassNamesFor } = useSortableData(studentRollbackData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);
    const { selectTable, SelectAllCheck, selectItemHandleChange } = useSelectTable(onCurrentPageData);

    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);

    const [noDataMsg, setNoDataMsg] = useState('')
    const [standardDetailsId, setStandardDetailsId] = useState<any>('')
    const [mediumId, setMediumId] = useState<any>('')
    const [sectionDetailsId, setSectionDetailsId] = useState<any>('')
    const [nextStandardDetailsId, setNextStandardDetailsId] = useState<any>('')
    const [nextMediumId, setNextMediumId] = useState<any>('')
    const [nextSectionDetailsId, setNextSectionDetailsId] = useState<any>('')
    const [nextBatchMasterId, setNextBatchMasterId] = useState<any>('')

    const [standardData, setStandardData] = useState<any>([])
    const [mediumData, setMediumData] = useState<any>([])
    const [sectionDetailsData, setSectionDetailsData] = useState<any>([])
    const [nextSectionDetailsData, setNextSectionDetailsData] = useState<any>([])
    const [nextBatchMasterData, setNextBatchMasterData] = useState([])

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },

        //validateOnChange: false,
        onSubmit: () => { },
    });

    // Filter
    const filteredData = onCurrentPageData.filter(
        (i) =>
            // Name
            i.admissionNo && i.admissionNo.toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase()) ||
            i.studentName && i.studentName.toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase()) ||
            i.courseName && i.courseName.toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase()) ||
            i.batchName && i.batchName.toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase()) ||
            i.semesterNumber && i.semesterNumber.toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase()),
    );

    const [batchMasterId, setBatchMasterId] = useState<any>('')
    const [batchMasterData, setBatchMasterData] = useState<any>([])

    const selectBatch = (e: any) => {
        setStandardDetailsId('')
        setSectionDetailsId('')
        setMediumId('')
        let batchMasterId = e
        setBatchMasterId(batchMasterId)
        getMediumList();
    }

    const selectMedium = (e: any) => {
        setStandardDetailsId('')
        setSectionDetailsId('')
        let mediumId = e
        setMediumId(mediumId)
        getStandardList()
    }

    const selectStandard = (e: any) => {
        setSectionDetailsId('')
        let standardDetailsId = e
        setStandardDetailsId(standardDetailsId)
        if (mediumId?.value != undefined && standardDetailsId?.value != undefined) {
            getSectionDetailsList(mediumId?.value, standardDetailsId?.value)
        }
    }

    const selectSection = (e: any) => {
        let sectionDetailsId = e
        setSectionDetailsId(sectionDetailsId)
    }

    const selectNextMedium = (e: any) => {
        setNextBatchMasterId('')
        setNextStandardDetailsId('')
        setNextSectionDetailsId('')
        let mediumId = e
        setNextMediumId(mediumId)
        getNextBatchList()
    }

    const selectNextBatch = (e: any) => {
        setNextStandardDetailsId('')
        setNextSectionDetailsId('')
        let mediumId = e
        setNextBatchMasterId(mediumId)
    }

    const selectNextStandard = (e: any) => {
        setNextSectionDetailsId('')
        let standardDetailsId = e
        setNextStandardDetailsId(standardDetailsId)
        if (standardDetailsId?.value != undefined) {
            getNextSectionDetailsList(nextMediumId?.value, standardDetailsId?.value)
        }
    }

    const selectNextSection = (e: any) => {
        let sectionDetailsId = e
        setNextSectionDetailsId(sectionDetailsId)
    }

    function viewStudentList() {
        setDataSuccess(false);
        setIsLoader(true);
        columnVisibilityForm.resetForm();
        setNextBatchMasterId('')
        setNextMediumId('')
        setNextStandardDetailsId('')
        setNextSectionDetailsId('')
        selectTable.values.selectedList = [];

        if(batchMasterId?.value != undefined && sectionDetailsId?.value != undefined){
        getStudentRollbackList(batchMasterId?.value, sectionDetailsId?.value)
        }
    }

    function getStudentRollbackList(batchMasterId: any, sectionDetailsId: any) {
        getStudentDetailsBySectionDetailsId(batchMasterId, sectionDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentDetailsBySectionDetailsId;
                    if (data != undefined) {
                        setIsLoader(false);
                        setStudentRollbackData(data);
                        setIsOpenListCard(false);
                        setDataSuccess(true);
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                            data[i].id = data[i].studentDetailsId.toString();
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setIsLoader(false);
                    setIsOpenListCard(false);
                    setDataSuccess(true);
                    setStudentRollbackData([]);
                    setNoDataMsg(response.data.message)
                } else {
                    setIsLoader(false);
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setIsOpenListCard(false);
                    //setDataSuccess(true);
                    toasts(errorDescription, "Error")
                }
            }, error => {
                setIsLoader(false);
                setIsOpenListCard(true);
                setDataSuccess(false);
                toasts(error, "Error")
            }
        )
    }

    function getBatchList() {
        getBatchMasterDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.batchMasterDetails;
                    if (data != undefined) {
                        setBatchMasterData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setBatchMasterData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }


    function getStandardList() {
        getStandardDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.standardDetails;
                    if (data != undefined) {
                        setStandardData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setStandardData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getMediumList() {
        getMedium(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.medium;
                    if (data != undefined) {
                        setMediumData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setMediumData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getNextBatchList() {
        getBatchMasterDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.batchMasterDetails;
                    if (data != undefined) {
                        setNextBatchMasterData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setNextBatchMasterData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getSectionDetailsList(mediumId: any, standardDetailsId: any) {
        getSectionDetails(mediumId, standardDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.sectionDetails;
                    if (data != undefined) {
                        setSectionDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setSectionDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getNextSectionDetailsList(mediumId: any, standardDetailsId: any) {
        getSectionDetails(mediumId, standardDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.sectionDetails;
                    if (data != undefined) {
                        setNextSectionDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setNextSectionDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function setStudentStandard() {
        const studentDetailsIdArray = selectTable.values.selectedList
        return {
            mediumId: mediumId?.value,
            nextBatchMasterId: nextBatchMasterId?.value,
            standardDetailsId: nextStandardDetailsId?.value,
            sectionDetailsId: nextSectionDetailsId?.value,
            studentDetailsId: studentDetailsIdArray,
            staffDetailsId: userAccountId,
            licenseKey: getLicenseKey
        }
    }

    function updateStudentStandardSubmit() {
        showLoader(true)
        if (selectTable.values.selectedList.length > 0) {
            let updateStudentStandardPostData = setStudentStandard();
            updateStudentStandard(updateStudentStandardPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        resetForm()
                        getStudentRollbackList(batchMasterId?.value, sectionDetailsId?.value)
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        showLoader(false)
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (selectTable.values.selectedList.length == 0) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function resetForm() {
        setIsOpenListCard(true);
        setDataSuccess(false);
        setNextBatchMasterId('')
        setNextMediumId('')
        setNextStandardDetailsId('')
        setNextSectionDetailsId('')
        selectTable.values.selectedList = [];
    }

    return (
        <PageWrapper title={`Student Roll Back`}>
            <Page container='fluid'>
                <Collapse isOpen={isOpenListCard}>
                    <Card stretch data-tour='list'>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info'>
                                <CardTitle tag='div' className='h5'>
                                    Student Roll Back
                                </CardTitle>
                            </CardLabel>
                        </CardHeader>
                        <CardBody>
                            <div className='row g-4'>
                                <div className='col-md-2'>
                                    <FormGroup id='batchMasterId' label='Batch' isFloating>
                                        <SearchableSelect isFloating ariaLabel='Batch' placeholder="Batch"
                                            onChange={selectBatch}
                                            value={batchMasterId}
                                            list={batchMasterData.map((data: any) => ({ value: data.batchMasterId, label: data.batchName }))} required />
                                    </FormGroup>
                                </div>

                                {batchMasterId?.value != undefined ?
                                    <div className='col-md-2'>
                                        <FormGroup id='mediumId' label='Medium' isFloating>
                                            <SearchableSelect isFloating ariaLabel='Medium' placeholder="Select Standard"
                                                onChange={selectMedium}
                                                value={mediumId}
                                                list={mediumData.map((data: any) => ({ value: data.mediumId, label: data.medium }))} required />

                                        </FormGroup>
                                    </div> : null
                                }

                                {mediumId?.value != undefined ? <div className='col-md-2'>
                                    <FormGroup id='standardDetailsId' label='Standard' isFloating>
                                        <SearchableSelect isFloating ariaLabel='Standard' placeholder="Select Standard"
                                            onChange={selectStandard}
                                            value={standardDetailsId}
                                            list={standardData.map((data: any) => ({ value: data.standardDetailsId, label: data.standardName }))} required />

                                    </FormGroup>
                                </div> : null}
                                {standardDetailsId?.value != undefined ? <div className='col-md-2'>
                                    <FormGroup id='sectionDetailsId' label='Section' isFloating>
                                        <SearchableSelect isFloating ariaLabel='Section' onChange={selectSection} value={sectionDetailsId}
                                            list={sectionDetailsData.map((data: any) => (
                                                { value: data.sectionDetailsId, label: data.sectionName }
                                            ))}
                                        />
                                    </FormGroup>
                                </div> : null}
                                {sectionDetailsId ?
                                    <div className='col-md-3'>
                                        <Button className='mt-2' icon='ArrowDownward' color='primary' onClick={viewStudentList}>View</Button>
                                    </div> : null
                                }
                            </div>
                        </CardBody>
                    </Card>
                </Collapse>

                <OpenCardComponent isOpenListCard={isOpenListCard} setIsOpenListCard={setIsOpenListCard} isLoader={isLoader} />

                {dataSuccess ?
                    <Card stretch data-tour='list'>
                        <CardHeader borderSize={1}>
                            <div className='d-flex col-md-5 justify-content-start'>
                                <CardLabel icon='List' iconColor='info'>
                                    <CardTitle tag='div' className='h5'>Student Roll Back List</CardTitle>
                                </CardLabel>
                            </div>
                            <div className='d-flex col-md-7 justify-content-end'>
                                <div className='d-flex col-8 justify-content-end' data-tour='search'>
                                    <ButtonGroup className="col-lg-8 d-print-none" color='primary'>
                                        <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                                        <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
                                    </ButtonGroup>
                                </div>&nbsp;&nbsp;
                                <div className='col-md-2 justify-content-center'>
                                    <Button icon='Save' color='info' isDisable={selectTable.values.selectedList.length == 0 || nextBatchMasterId?.value == undefined || nextSectionDetailsId == undefined ? true : false} onClick={updateStudentStandardSubmit}>Submit</Button>
                                </div>
                            </div>
                        </CardHeader>
                        <CardBody className='table-responsive' isScrollable>
                            <div className='row g-4'>
                                <div className='col-md-3'>
                                    <FormGroup id='mediumId' label='Medium' isFloating>
                                        <SearchableSelect isFloating ariaLabel='Medium' placeholder="Select Standard"
                                            onChange={selectNextMedium}
                                            value={nextMediumId}
                                            list={mediumData.map((data: any) => ({ value: data.mediumId, label: data.medium }))} required />

                                    </FormGroup>
                                </div>
                                <div className='col-md-3'>
                                    <FormGroup id='nextBatchMasterId' label='Batch' isFloating>
                                        <SearchableSelect isFloating ariaLabel='Batch' placeholder="Select Batch"
                                            onChange={selectNextBatch}
                                            value={nextBatchMasterId}
                                            list={nextBatchMasterData.map((data: any) => ({ value: data.nextBatchMasterId, label: data.batchName }))} required />

                                    </FormGroup>
                                </div>
                                <div className='col-md-3'>
                                    <FormGroup id='standardDetailsId' label='Standard' isFloating>
                                        <SearchableSelect isFloating ariaLabel='Standard' placeholder="Select Standard"
                                            onChange={selectNextStandard}
                                            value={nextStandardDetailsId}
                                            list={standardData.map((data: any) => ({ value: data.standardDetailsId, label: data.standardName }))} required />

                                    </FormGroup>
                                </div>
                                <div className='col-md-3'>
                                    <FormGroup id='sectionDetailsId' label='Section' isFloating>
                                        <SearchableSelect isFloating ariaLabel='Section' onChange={selectNextSection} value={nextSectionDetailsId}
                                            list={nextSectionDetailsData.map((data: any) => (
                                                { value: data.sectionDetailsId, label: data.sectionName }
                                            ))}
                                        />
                                    </FormGroup>
                                </div>
                            </div>
                            <table className='table table-modern table-hover text-nowrap mt-4'>
                                <thead>
                                    <tr>
                                        <th scope='col'>{SelectAllCheck}</th>
                                        <th scope='col' onClick={() => requestSort('sno')}
                                            className='cursor-pointer text-decoration-underline'>S.No{' '}
                                            <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                        </th>
                                        <th scope='col' onClick={() => requestSort('admissionNo')}
                                            className='cursor-pointer text-decoration-underline'>Admission Number{' '}
                                            <Icon size='lg' className={getClassNamesFor('admissionNo')} icon='FilterList' />
                                        </th>

                                        <th scope='col' onClick={() => requestSort('studentName')}
                                            className='cursor-pointer text-decoration-underline'>Student Name{' '}
                                            <Icon size='lg' className={getClassNamesFor('studentName')} icon='FilterList' />
                                        </th>
                                        <th scope='col' onClick={() => requestSort('studentName')}
                                            className='cursor-pointer text-decoration-underline'>Medium{' '}
                                            <Icon size='lg' className={getClassNamesFor('studentName')} icon='FilterList' />
                                        </th>
                                        <th>Standard</th>
                                        <th>Section</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredData.length != 0 ?
                                        <>{filteredData.map((i: any) => (<tr key={i.studentDetailsId}><td><Checks
                                            id={i.studentDetailsId.toString()}
                                            name='selectedList'
                                            value={i.studentDetailsId}
                                            onChange={selectTable.handleChange}
                                            checked={selectTable.values.selectedList.includes(
                                                // @ts-ignore
                                                i.studentDetailsId.toString(),
                                            )}
                                        /></td><td>{i.sno}</td><td>{i.admissionNo}</td><td>{i.studentName}</td><td>{i.medium}</td><td>{i.standardName}</td><td>{i.sectionName}</td></tr>))}</>
                                        :
                                        <NoDataMsg columnsCount={7} msg={noDataMsg} />}
                                </tbody>
                            </table>
                        </CardBody>
                        {/* <PaginationButtons
                            className='d-print-none'
                            data={items}
                            label='items'
                            setCurrentPage={setCurrentPage}
                            currentPage={currentPage}
                            perPage={perPage}
                            setPerPage={setPerPage}
                        /> */}
                    </Card> : null
                }
            </Page>
            <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
        </PageWrapper>
    );
};

export default StudentRollBack;
