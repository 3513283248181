import React, { useContext, useEffect, useState } from 'react'
import { toasts } from '../../../services/toast.service';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Collapse from '../../bootstrap/Collapse';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../../bootstrap/Card';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Select from '../../bootstrap/forms/Select';
import Button from '../../bootstrap/Button';
import Input from '../../bootstrap/forms/Input';
import useDarkMode from '../../../hooks/useDarkMode';
import OpenCardComponent from '../../../common/components/OpenCardComponent';
import AlertService from '../../../services/AlertService';
import { addDayOrder, addHoliday, getAcademicPeriod, getDayList, getDayOrderType, getHolidayType, getMonth } from '../../../services/master.service';
import { getLicenseKey } from '../../../services/application.settings';
import AuthContext from '../../../contexts/authContext';
import Icon from '../../icon/Icon';
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from '../../../layout/SubHeader/SubHeader';
import { showLoader } from '../../../services/loader.services';
import { useFormik } from 'formik';
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from '../../bootstrap/OffCanvas';
import SearchableSelect from '../../../common/components/SearchableSelect';

const DayOrder = () => {

    useEffect(() => {
        getAcademicPeriodList();
        getDayOrder();
        getHoliday();
        getMonthList();
    }, [])

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const [academicPeriodId, setAcademicPeriodId] = useState<any>('')
    const [monthId, setMonthId] = useState<any>('')
    const [dayOrderTypeId, setDayOrderTypeId] = useState('')
    const [monthName, setMonthName] = useState('')
    const [dayOrder, setDayOrder] = useState<any>(0)
    const [academicPeriodData, setAcademicPeriodData] = useState<any>([])
    const [monthData, setMonthData] = useState<any>([])
    const [dayOrderTypeData, setDayOrderTypeData] = useState<any>([])
    const [dayListData, setDayListData] = useState([])
    const [dataSuccess, setDataSuccess] = useState(false)
    const [isLoader, setIsLoader] = useState(false)
    const [isOpenListCard, setIsOpenListCard] = useState(true)
    const { darkModeStatus } = useDarkMode();
    const [dayOrderArray, setDayOrderArray] = useState<any>([])

    // For Modal
    const [holidayCanvas, setHolidayCanvas] = useState(false)

    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);

    const [dayOrderTypeIdForHoliday, setDayOrderTypeIdForHoliday] = useState<any>('')
    const [cellForHoliday, setCellForHoliday] = useState<any>('')
    const [dayForHoliday, setDayForHoliday] = useState<any>('')
    const [dayOrderFor, setDayOrderFor] = useState<any>('')
    const [holidayData, setHolidayData] = useState<any>([])
    const [holidayTypeId, setHolidayTypeId] = useState<any>('')

    const addHolidayForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            holidayTypeId: '',
            holidayName: '',
        },
        validate: (values) => {
            const errors: {
                holidayTypeId?: string;
                holidayName?: string;
            } = {};
            if (!holidayTypeId) {
                errors.holidayTypeId = 'Required';
            }
            if (!values.holidayName) {
                errors.holidayName = 'Required';
            }
            return errors;
        },

        //validateOnChange: false,
        onSubmit: () => { addHolidaySubmit() },
    });

    const selectAcademicPeriod = (e: any) => {
        setDataSuccess(false);
        setMonthId('')
        let academicPeriodId = e
        setAcademicPeriodId(academicPeriodId)
    }

    const selectMonth = (e: any) => {
        setDataSuccess(false);
        let monthId = e
        setMonthId(monthId)
    }

    function setAddHolidayDetails() {
        return ({
            fromDate: null,
            toDate: null,
            reason: null,
            courseId: [0],
            holidayTypeId: holidayTypeId?.value,
            holidayName: addHolidayForm.values.holidayName,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId
        })
    }

    function viewDayOrder() {
        setIsLoader(true);
        setDataSuccess(false);
        setDayListData([]);
        getDay( monthId?.value, academicPeriodId?.value)
    }

    function getAcademicPeriodList() {
        getAcademicPeriod(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.academicPeriod;
                    if (data != undefined) {
                        setAcademicPeriodData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setAcademicPeriodData([]);
                    //toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getMonthList() {
        getMonth(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.month;
                    if (data != undefined) {
                        setMonthData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setMonthData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getDay(  monthId: string,academicPeriodId: string) {
        getDayList(monthId,academicPeriodId,  
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.dayList;
                    let dayOrder = response.data.data.dayList[0].dayOrder;
                    let monthName = response.data.data.dayList[0].fullMonth;
                    if (data != undefined) {
                        setDayListData(data)
                        setDayOrder(dayOrder);
                        setMonthName(monthName);
                        setIsLoader(false);
                        setIsOpenListCard(false);
                        setDataSuccess(true);

                        const newData: any = [];

                        if (dayOrder == 1) {
                            let dayOrderArray = 0
                            for (let i = 0; i < data.length; i++) {
                                let dayList = data[i]

                                if (dayList.dayOrderTypeId?.value == 7 || dayList.dayOrderTypeId == 7 || dayList.day == "Sunday") {
                                    dayList['dayOrderTypeId'] = { value: dayList.day == "Sunday" ? 7 : dayList['dayOrderTypeId'], label: dayList.day == "Sunday" ? 'Sunday' : dayList['dayOrderName'] };
                                    dayList['particulars'] = dayList.day == "Sunday" ? 'Sunday' : dayList.particulars;
                                    dayList.isEditable = true;
                                }
                                else if (dayList.dayOrderTypeId != 7 && dayList.dayOrderTypeId?.value != 7 && dayList.day != "Sunday" && dayList.dayOrderName != "Holiday") {
                                    dayOrderArray = dayOrderArray + 1
                                    dayList['dayOrder'] = dayOrderArray
                                    dayList['dayOrderTypeId'] = dayList.dayOrderName != null ? { value: dayList['dayOrderTypeId'], label: dayList['dayOrderName'] } : null;
                                    dayList['particulars'] = dayList.particulars;
                                }
                                else {
                                    dayList['dayOrder'] = <Icon icon='Remove' className='mb-0 text-danger fw-bold h2' />
                                }
                                newData.push(dayList)
                            }
                        }
                        else {
                            let dayOrder1 = dayOrder - 1
                            let dayOrderArray = dayOrder1
                            for (let i = 0; i < data.length; i++) {
                                let dayList = data[i]
                                if (dayList.dayOrderTypeId?.value == 7 || dayList.dayOrderTypeId == 7 || dayList.day == "Sunday") {
                                    dayList['dayOrderTypeId'] = { value: dayList.day == "Sunday" ? 7 : dayList['dayOrderTypeId'], label: dayList.day == "Sunday" ? 'Sunday' : dayList['dayOrderName'] };
                                    dayList['particulars'] = dayList.day == "Sunday" ? 'Sunday' : dayList.particulars;
                                    dayList.isEditable = true;
                                }
                                else if (dayList.dayOrderTypeId != 7 && dayList.dayOrderTypeId?.value != 7 && dayList.day != "Sunday" && dayList.dayOrderName != "Holiday") {
                                    dayOrderArray = dayOrderArray + 1
                                    dayList['dayOrder'] = dayOrderArray
                                    dayList['dayOrderTypeId'] = dayList.dayOrderName != null ? { value: dayList['dayOrderTypeId'], label: dayList['dayOrderName'] } : null;
                                    dayList['particulars'] = dayList.particulars;
                                }
                                else {
                                    dayList['dayOrder'] = <Icon icon='Remove' className='mb-0 text-danger fw-bold h2' />
                                }
                                newData.push(dayList)
                            }
                        }
                        setDayListData(newData)
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                    setIsLoader(false);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                    setIsLoader(false);
                }
            }, error => {
                toasts(error, "Error")
                setIsLoader(false);
            }
        )
    }

    function getDayOrder() {
        getDayOrderType(0,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.dayOrderType;
                    if (data != undefined) {
                        setDayOrderTypeData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setDayOrderTypeData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function updateWorkingDays(workingData: any, ifHoliday: string) {


        const newData: any = [];

        if (dayOrder == 1) {

            let dayOrderArray = 0
            for (let i = 0; i < workingData.length; i++) {
                let dayList = workingData[i]

                if (dayList.day == "Sunday") {
                    dayList['dayOrderTypeId'] = { value: 7, label: 'Holiday' };
                    dayList['particulars'] = "Sunday";
                    dayList.isEditable = true;
                }
                else if (dayList.dayOrderTypeId?.value == 7 && dayList.dayOrderTypeId == 7 && ifHoliday != "") {
                    dayList['dayOrderTypeId'] = { value: 7, label: 'Holiday' };
                    dayList.isEditable = true;
                }
                else if (dayList.dayOrderTypeId?.value != 7 && dayList.dayOrderTypeId != 7) {
                    dayOrderArray = dayOrderArray + 1
                    dayList['dayOrder'] = dayOrderArray
                }
                else {
                    dayList['dayOrder'] = <Icon icon='Remove' className='mb-0 text-danger fw-bold h2' />
                }
                newData.push(dayList)
            }
        }
        else {
            let dayOrder1 = dayOrder - 1
            let dayOrderArray = dayOrder1
            for (let i = 0; i < workingData.length; i++) {
                let dayList = workingData[i]
                if (dayList.day == "Sunday") {
                    dayList['dayOrderTypeId'] = { value: 7, label: 'Holiday' };
                    dayList['particulars'] = "Sunday";
                    dayList.isEditable = true;
                }
                else if (dayList.dayOrderTypeId?.value == 7 && dayList.dayOrderTypeId == 7 && ifHoliday != "") {
                    dayList['dayOrderTypeId'] = { value: 7, label: 'Holiday' };
                    dayList.isEditable = true;
                }
                else if (dayList.dayOrderTypeId?.value != 7 && dayList.dayOrderTypeId != 7) {
                    dayOrderArray = dayOrderArray + 1
                    dayList['dayOrder'] = dayOrderArray
                }
                else {
                    dayList['dayOrder'] = <Icon icon='Remove' className='mb-0 text-danger fw-bold h2' />
                }
                newData.push(dayList)

            }
        }
        setDayListData(newData)
    }

    function updateParticularValue(e: any, dayOrderTypeId: number, cell: String, day: String, dayOrder: any) {

        const newData: any = [];

        dayListData.forEach((item: any, index: any) => {
            if (item['fullDate'] == cell) {
                item['particulars'] = e
            }
            newData.push(item)
        });

        setDayListData(newData)

        if (dayOrderArray.length == 0) {
            var obj
            obj = {
                date: cell,
                day: day,
                particulars: e,
                dayOrderTypeId: dayOrderTypeId,
                dayOrder: dayOrder
            };
            dayOrderArray.push(obj);
        } else {
            let selectedItem: any
            dayOrderArray.forEach((item: any, index: number) => {
                if (item['date'] == cell) {
                    selectedItem = item
                }
            });
            if (selectedItem != undefined) {
                selectedItem['particulars'] = e
            } else {
                var obj
                obj = {
                    date: cell,
                    day: day,
                    particulars: e,
                    dayOrderTypeId: dayOrderTypeId,
                    dayOrder: dayOrder
                };
                dayOrderArray.push(obj);
            }
        }
    }

    function updateDayOrderTypeIdValue(e: any, cell: String, day: String, dayOrder: any) {

        if (e != null) {
            if (e?.value == 7) {
                setHolidayCanvas(true)
                setDayOrderTypeIdForHoliday(e?.value)
                setCellForHoliday(cell)
                setDayForHoliday(day)
                setDayOrderFor(dayOrder)
            }
        }
        const newData: any = [];

        dayListData.forEach((item: any, index: any) => {
            if (item['fullDate'] == cell) {
                item['dayOrderTypeId'] = e != null ? e : null
            }
            newData.push(item)
        });

        setDayListData(newData)

        if (dayOrderArray.length == 0) {
            var obj
            obj = {
                date: cell,
                day: day,
                particulars: "",
                dayOrderTypeId: e != null ? e?.value : null,
                dayOrder: dayOrder
            };
            dayOrderArray.push(obj);
        } else {
            let selectedItem: any
            dayOrderArray.forEach((item: any, index: number) => {
                if (item['date'] == cell) {
                    selectedItem = item
                }
            });
            if (selectedItem != undefined) {
                selectedItem['dayOrderTypeId'] = e != null ? e?.value : null
            } else {
                var obj
                obj = {
                    date: cell,
                    day: day,
                    particulars: "",
                    dayOrderTypeId: e != null ? e?.value : null,
                    dayOrder: dayOrder
                };
                dayOrderArray.push(obj);
            }
        }
        updateWorkingDays(dayListData, "")
    }

    function setDayOrderDetails() {

        return ({
            academicPeriodId: academicPeriodId?.value,
            monthId: monthId?.value,
            dayOrderArray: dayOrderArray,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId
        })
    }

    function dayOrderSubmit() {
        showLoader(true)
        if (dayOrderArray.length > 0) {
            let dayOrderPostData = setDayOrderDetails()
            addDayOrder(dayOrderPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        closeAndReset();
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        showLoader(false)
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }, (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (dayOrderArray.length == 0) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function closeAndReset() {
        setDayOrderArray([]);
        setMonthId('')
        setAcademicPeriodId('')
        setIsOpenListCard(true);
        setDataSuccess(false);
        setHolidayCanvas(false)
    }

    function getHoliday() {
        getHolidayType(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.holidayType;
                    if (data != undefined) {
                        setHolidayData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setHolidayData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function addHolidaySubmit() {
        showLoader(true)
        if (addHolidayForm.isValid) {
            let holidayPostData = setAddHolidayDetails()
            addHoliday(holidayPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        updateParticularValue(addHolidayForm.values.holidayName, dayOrderTypeIdForHoliday, cellForHoliday, dayForHoliday, dayOrderFor)
                        updateWorkingDays(dayListData, "ifHoliday")
                        setDayOrderTypeIdForHoliday([])
                        setCellForHoliday([])
                        setDayForHoliday([])
                        setDayOrderFor([])
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        closeAddHoliday()
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        showLoader(false)
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }, (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (addHolidayForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function closeAddHoliday() {
        addHolidayForm.resetForm();
        setHolidayTypeId('')
        setHolidayCanvas(false)
    }
    return (
        <PageWrapper title={`Day Order`}>
            <Page container='fluid'>
                <Collapse isOpen={isOpenListCard}>
                    <Card stretch data-tour='list'>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info'>
                                <CardTitle tag='div' className='h5'>
                                    Day Order List
                                </CardTitle>
                            </CardLabel>
                        </CardHeader>
                        <CardBody>
                            <div className="row g-4">
                                <div className='col-md-3'>
                                    <FormGroup id='academicPeriodId' label='Academic Period' isFloating>
                                        <SearchableSelect isFloating ariaLabel='Academic Period' placeholder="Select Academic Period"
                                            value={academicPeriodId}
                                            onChange={(e: any) => selectAcademicPeriod(e)}
                                            list={academicPeriodData.map((data: any) => (
                                                { value: data.academicPeriodId, label: data.period }
                                            ))} required />
                                    </FormGroup>
                                </div>

                                {academicPeriodId ? <div className='col-md-3'>
                                    <FormGroup id='monthId' label='Month' isFloating>
                                        <SearchableSelect isFloating
                                            ariaLabel='Month'
                                            onChange={selectMonth}
                                            value={monthId}
                                            list={monthData.map((data: any) => (
                                                { value: data.monthId, label: data.month }
                                            ))}
                                        />
                                    </FormGroup>
                                </div>
                                    : null}

                                {monthId ?
                                    <div className='col-md-3'>
                                        <Button icon='ArrowDownward' color='primary' onClick={viewDayOrder}>View</Button>
                                    </div>
                                    : null}
                            </div>
                        </CardBody>
                    </Card>
                </Collapse>

                <OpenCardComponent isOpenListCard={isOpenListCard} setIsOpenListCard={setIsOpenListCard} isLoader={isLoader} />

                {dataSuccess ?
                    <>
                        <SubHeader>
                            <SubHeaderLeft>
                                <CardLabel>
                                    <CardTitle tag='div' className='h5 justify-content-center'>
                                        Day Order for the Month :   <Icon icon='CalendarToday' size='2x' color='info' />   <span className='fw-bold text-danger fs-5 mb-0'>{monthName}</span>
                                    </CardTitle>
                                </CardLabel>
                            </SubHeaderLeft>
                            <SubHeaderRight>
                                <SubheaderSeparator />
                                <Button color='primary' icon="PublishedWithChanges" className='w-100' onClick={() => window.location.reload()}>Reset</Button>
                            </SubHeaderRight>
                        </SubHeader>

                        <Card stretch data-tour='list' className='mt-3'>
                            <CardBody className='table-responsive'>
                                <table className='table table-modern text-nowrap'>
                                    <thead>
                                        <tr className='text-center'>
                                            <th scope="col">Date</th>
                                            <th scope="col">Day</th>
                                            <th scope="col">Day Order</th>
                                            <th scope="col">Particulars</th>
                                            <th scope="col">Working Days</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dayListData.map((i: any) => (
                                            <tr key={i.date}>
                                                <td className={i.day === 'Sunday' || i.dayOrderTypeId?.value == 7 || i.dayOrderTypeId == 7 ? 'text-center text-danger fw-bold' : 'text-center'}>{i.fullDate}</td>
                                                <td className={i.day === 'Sunday' || i.dayOrderTypeId?.value == 7 || i.dayOrderTypeId == 7 ? 'text-center text-danger fw-bold' : 'text-center'}>{i.day}</td>
                                                <td scope='col'>
                                                    <FormGroup id='dayOrderTypeId'>
                                                        <SearchableSelect placeholder='Select' ariaLabel='Day Order' list={dayOrderTypeData.map((data: any) => ({ value: data.dayOrderTypeId, label: data.dayOrderName }))}
                                                            value={i.dayOrderTypeId != null ? i.dayOrderTypeId : 0} disabled={i.isEditable}
                                                            onChange={(e: any) => updateDayOrderTypeIdValue(e, i.fullDate, i.day, i.dayOrder)}
                                                        />
                                                    </FormGroup>
                                                </td>
                                                <td scope='col'>
                                                    <FormGroup id='particulars'>
                                                        <Input className="w-100" disabled={i.isEditable} placeholder="" value={i.particulars ? i.particulars : ''} onInput={(e: any) => updateParticularValue(e.target.value, i.dayOrderTypeId, i.fullDate, i.day, i.dayOrder)} />
                                                    </FormGroup>
                                                </td>
                                                <td className={i.day === 'Sunday' || i.dayOrderTypeId?.value == 7 || i.dayOrderTypeId == 7 ? 'text-center text-danger fw-bold' : 'text-center'}>{i.day === 'Sunday' || i.dayOrderTypeId?.value == 7 ? <>
                                                    <Icon icon='Remove' className='mb-0 text-danger fw-bold h2' /></> : i.dayOrder}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </CardBody>
                        </Card>
                        <SubHeader>
                            <SubHeaderLeft><></></SubHeaderLeft>
                            <SubHeaderRight>
                                <SubheaderSeparator />
                                <Button color='info' icon="Save" type="submit" className='w-100' isDisable={dayOrderArray.length == 0} onClick={dayOrderSubmit}>Save</Button>
                            </SubHeaderRight>
                        </SubHeader>
                    </>
                    : null}

            </Page>

            <OffCanvas setOpen={setHolidayCanvas} isOpen={holidayCanvas} titleId='holidayCanvas' placement='end'>
                <OffCanvasHeader setOpen={setHolidayCanvas} onClick={closeAddHoliday}>
                    <OffCanvasTitle id='holidayCanvas'>Add Holiday</OffCanvasTitle>
                </OffCanvasHeader>
                <form noValidate onSubmit={addHolidayForm.handleSubmit}>
                    <OffCanvasBody>
                        <div className='row g-4'>
                            <div className="col-lg-12">
                                <FormGroup id='holidayTypeId' label='Holiday Type'>
                                    <SearchableSelect
                                        ariaLabel='Holiday Type'
                                        placeholder='Holiday Type'
                                        onChange={(e: any) => setHolidayTypeId(e)}
                                        value={holidayTypeId}
                                        list={holidayData.map((data: any) => (
                                            { value: data.holidayTypeId, label: data.holidayType }
                                        ))}
                                        onBlur={addHolidayForm.handleBlur}
                                        isValid={addHolidayForm.isValid}
                                        isTouched={addHolidayForm.touched.holidayTypeId}
                                        invalidFeedback={addHolidayForm.errors.holidayTypeId}
                                    />
                                </FormGroup>
                            </div>
                            <div className="col-lg-12">
                                <FormGroup id='holidayName' label='Holiday Name' >
                                    <Input
                                        placeholder='Holiday Name'
                                        onChange={addHolidayForm.handleChange}
                                        value={addHolidayForm.values.holidayName}
                                        onBlur={addHolidayForm.handleBlur}
                                        isValid={addHolidayForm.isValid}
                                        isTouched={addHolidayForm.touched.holidayName}
                                        invalidFeedback={addHolidayForm.errors.holidayName}
                                    />
                                </FormGroup>
                            </div>
                        </div>
                    </OffCanvasBody>
                    <div className='row m-0'>
                        <div className='col-12 p-3 position-absolute top-95 start-50 translate-middle'>
                            <Button className='w-100' type='submit' icon="save" color="info" isDisable={!addHolidayForm.isValid && !!addHolidayForm.submitCount}>Save</Button>
                        </div>
                    </div>
                </form>
            </OffCanvas>
            <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
        </PageWrapper>
    );
}

export default DayOrder