import React, { useEffect, useState } from 'react'
import Card, { CardHeader, CardLabel, CardTitle, CardSubTitle, CardActions, CardBody } from '../../bootstrap/Card'
import Carousel from '../../bootstrap/Carousel'
import PageWrapper from '../../../layout/PageWrapper/PageWrapper'
import Page from '../../../layout/Page/Page'
import { getEventList, getTournamentNameList } from '../../../services/sportsManagement.service'
import { toasts } from '../../../services/toast.service'
import Icon from '../../icon/Icon'
import { getGalleryImages } from '../../../services/complaint.service'
import classNames from 'classnames'
import useDarkMode from '../../../hooks/useDarkMode'
import Modal, { ModalHeader, ModalTitle, ModalBody } from '../../bootstrap/Modal'
import CommonIncome from '../../../pages/_common/CRMDashboard/CommonIncome'
import CommonLatestTransActions from '../../../pages/_common/CRMDashboard/CommonLatestTransActions'
import CommonSalePerformance from '../../../pages/_common/CRMDashboard/CommonSalePerformance'
import CommonTopSales from '../../../pages/_common/CRMDashboard/CommonTopSales'
import USERS from '../../../common/data/userDummyData'
import Popovers from '../../bootstrap/Popovers'
import Avatar from '../../Avatar'
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from '../../../layout/SubHeader/SubHeader'
import Button from '../../bootstrap/Button'
import { useNavigate } from 'react-router-dom'

const GalleryList = () => {

	useEffect(() => {
		getGalleryImagesList()
	}, [])


	const [galleryData, setGalleryData] = useState<any>([])
	const [dataStatus, setDataStatus] = useState<any>(false)
	const [noDataMsg, setNoDataMsg] = useState<any>('')

	const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'dark'];

	function getGalleryImagesList() {
		getGalleryImages(0,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.galleryImages;
					if (data != undefined) {
						setDataStatus(true)

						for (let i = 0; i < data.length; i++) {

							let imageData = data[i].image

							for (let j = 0; j < imageData.length; j++) {
								imageData[j].src = imageData[j].imagePath;
							}
						}

						setGalleryData(data);
					} else {
						setDataStatus(true)
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					setDataStatus(true)
					//toasts(response.data.message, 'Error');                   
					setGalleryData([]);
					setNoDataMsg(response.data.message)
				} else {
					setDataStatus(true)
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				setDataStatus(true)
				toasts(error, 'Error');
			},
		);
	}

	const navigate = useNavigate();

	const [selectedImage, setSelectedImage] = useState<string | undefined>(undefined);
	const [showImage, setShowImage] = useState<any>(false);

	return (
		<PageWrapper title='Event List'>
			<SubHeader>
				<SubHeaderLeft>					
					<CardLabel icon='PhotoAlbum' iconColor='primary'>
						<CardTitle tag='div' className='h5 text-uppercase'>
							Gallery
						</CardTitle>
					</CardLabel>
				</SubHeaderLeft>
				<SubHeaderRight>
				<SubheaderSeparator />
					<Button color='info' isLight icon='Upload' onClick={() => navigate(`../management/gallery`)}>
						Upload
					</Button>
				</SubHeaderRight>
			</SubHeader>
			<Page>
				<div className='row'>
					{galleryData.map((item: any, index: any) => (
						<div className='col-6' key={item.galleryType}>
							<Card>
								<CardHeader>
									<CardLabel icon='Image' iconColor='primary'>
										<CardTitle tag='div' className='h5 text-uppercase'>
											{item.galleryType}
										</CardTitle>
									</CardLabel>
								</CardHeader>
								<CardBody>
									<div className='col-12'>
										<div className='row'>
											{item.image.map((imageData: any, i: any) => (
												<div key={imageData.imagePath} className={`col-2 ${i > 5 ? ' mt-3' : ''}`}>
													<Popovers
														trigger='hover'
														desc={<b>Preview</b>}>
														<Avatar
															onClick={() => { setSelectedImage(imageData.imagePath); setShowImage(true) }}
															srcSet={imageData.imagePath}
															src={imageData.imagePath}
															color={'info'}
															size={80}
															className='cursor-pointer'
														/>
													</Popovers>
												</div>

											))}
										</div>
									</div>
								</CardBody>
							</Card>
						</div>
					))}
				</div>

				<Modal setIsOpen={setSelectedImage} isOpen={!!selectedImage} isCentered>
					<ModalHeader setIsOpen={setSelectedImage}>
						<ModalTitle id='preview'>Preview</ModalTitle>
					</ModalHeader>
					<ModalBody>
						<img
							src={selectedImage}
							alt={selectedImage}
							width='100%'
							height='auto'
							className='object-fit-contain p-4'
						/>
					</ModalBody>
				</Modal>
			</Page>
		</PageWrapper>
	)
}

export default GalleryList