import React, { useContext, useEffect, useState } from 'react';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../bootstrap/Card';
import Collapse from '../../bootstrap/Collapse';
import SearchableSelect from '../../../common/components/SearchableSelect';
import Button from '../../bootstrap/Button';
import FormGroup from '../../bootstrap/forms/FormGroup';
import { toasts } from '../../../services/toast.service';
import {
	getAllStudent,
	getProfileForStudent,
	getStudentDetailsBySectionDetailsId,
} from '../../../services/student.service';
import OpenCardComponent from '../../../common/components/OpenCardComponent';
import useDarkMode from '../../../hooks/useDarkMode';
import Modal, { ModalHeader, ModalTitle, ModalBody } from '../../bootstrap/Modal';
import OffCanvas, {
	OffCanvasBody,
	OffCanvasHeader,
	OffCanvasTitle,
} from '../../bootstrap/OffCanvas';
import { Input } from '../../icon/material-icons';
import classNames from 'classnames';
import Icon from '../../icon/Icon';
import { getAcademicPeriod, getBatchMasterDetails, getSectionDetails } from '../../../services/master.service';
import {
	getColumnsForDataTable,
	getMedium,
	getStandardDetails,
	pictNotLoading,
	profilePic,
	updateColumnsForDataTable,
} from '../../../services/common.service';
import { getExamDetails, getStudentExamMarkDetails } from '../../../services/exam.service';
import { getLicenseKey } from '../../../services/application.settings';
import AuthContext from '../../../contexts/authContext';
import { useFormik } from 'formik';
import { getFirstLetter } from '../../../helpers/helpers';
import { use } from 'i18next';

const StudentMarkSheet = () => {
	const { userAccountId, userTypeId } = useContext(AuthContext);

	useEffect(() => {
		getBatchList();
		if (userTypeId == 9) {
			getStudentProfile(userAccountId);
		}
	}, []);

	const [isOpenListCard, setIsOpenListCard] = useState(true);
	const [studentDetailsId, setStudentDetailsId] = useState<any>(Number);
	const [dataSuccess, setDataSuccess] = useState(false);
	const [mediumId, setMediumId] = useState<any>('');
	const [standardDetailsId, setStandardDetailsId] = useState<any>('');
	const [sectionDetailsId, setSectionDetailsId] = useState<any>(Number);
	const [examDetailsId, setExamDetailsId] = useState<any>('');

	const [mediumData, setMediumData] = useState<any>([]);
	const [standardData, setStandardData] = useState<any>([]);
	const [sectionDetailsData, setSectionDetailsData] = useState([]);
	const [studentMarkDetailsData, setStudentMarkDetailsData] = useState<any>([]);
	const [studentDetailsDataByDropdown, setStudentDetailsDataByDropdown] = useState([]);
	const [studentData, setStudentData] = useState<any>([]);

	const [isLoader, setIsLoader] = useState(false);
	const { darkModeStatus } = useDarkMode();
	const [noDataMsg, setNoDataMsg] = useState('');

	const [viewOffCanvas, setViewOffCanvas] = useState(false);




	const [batchMasterId, setBatchMasterId] = useState<any>('')
	const [batchMasterData, setBatchMasterData] = useState<any>([])

	const selectBatch = (e: any) => {
		setStudentDetailsId('')
		setStandardDetailsId('')
		setSectionDetailsId('')
		setMediumId('')
		let batchMasterId = e
		setBatchMasterId(batchMasterId)
		getMediumList();
	}

	const selectMedium = (e: any) => {
		setStudentMarkDetailsData([]);
		setDataSuccess(false);
		setStandardDetailsId('');
		setSectionDetailsId('');
		setStudentDetailsId('');
		let mediumId = e;
		setMediumId(mediumId);
		getStandardList();
	};

	const selectStandard = (e: any) => {
		setStudentMarkDetailsData([]);
		setDataSuccess(false);
		setSectionDetailsId('');
		setStudentDetailsId('');
		setSectionDetailsId('');
		let standardDetailsId = e;
		setStandardDetailsId(standardDetailsId);
		if (mediumId?.value != undefined && standardDetailsId?.value != undefined) {
			getSectionList(mediumId?.value, standardDetailsId?.value);
		}
	};

	const selectSection = (e: any) => {
		setStudentMarkDetailsData([]);
		setDataSuccess(false);
		setStudentDetailsId('');
		let sectionDetailsId = e;
		setSectionDetailsId(sectionDetailsId);
		if (batchMasterId?.value != undefined && sectionDetailsId?.value != undefined) {
			getStudentListBySectionId(batchMasterId?.value, sectionDetailsId?.value);
		}
	};

	const selectStudent = (e: any) => {
		setStudentMarkDetailsData([]);
		setDataSuccess(false);
		let studentDetailsId = e;
		setStudentDetailsId(studentDetailsId);
	};

	function ViewExam() {
		getStudentProfile(studentDetailsId?.value)
		getStudentExamMarkDetailsList(standardDetailsId?.value, sectionDetailsId?.value, studentDetailsId?.value, 0);
	}

	const [markDetailsData, setMarkDetailsData] = useState<any>([])
	const [examType, setExamType] = useState<any>('')

	function ViewMark(i: any) {
		setViewOffCanvas(true);
		setMarkDetailsData([i])
		setExamType(i.examTypeName)
	}

	const [studentProfileData, setStudentProfileData] = useState<any>([])

	function getStudentProfile(studentDetailsId: number) {
		getProfileForStudent(studentDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.studentProfile;
					if (data != undefined) {
						setStudentData(data);
						setStudentProfileData(data[0]);
						if (userTypeId == 9) {
							setStandardDetailsId(data[0]?.standardDetailsId);
							setSectionDetailsId(data[0]?.sectionDetailsId);
							setStudentDetailsId(data[0]?.studentDetailsId);
							getStudentExamMarkDetailsList(data[0]?.standardDetailsId, data[0]?.sectionDetailsId, data[0]?.studentDetailsId, 0);
						}
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					setStudentData([]);
					setNoDataMsg(response.data.message);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getBatchList() {
		getBatchMasterDetails(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.batchMasterDetails;
					if (data != undefined) {
						setBatchMasterData(data);
					} else {
						toasts("Undefined Data", "Error")
					}
				}
				else if (response.data.success === false) {
					// toasts(response.data.message, "Error")
					setBatchMasterData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, "Error")
				}
			}, error => {
				toasts(error, "Error")
			}
		)
	}

	function getMediumList() {
		getMedium(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.medium;
					if (data != undefined) {
						setMediumData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setMediumData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getStandardList() {
		getStandardDetails(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.standardDetails;
					if (data != undefined) {
						setStandardData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setStandardData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getStudentListBySectionId(batchMasterId: any, sectionDetailsId: any) {
		getStudentDetailsBySectionDetailsId(batchMasterId, sectionDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.studentDetailsBySectionDetailsId;
					if (data != undefined) {
						setStudentDetailsDataByDropdown(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					toasts(response.data.message, 'Error');
					setStudentDetailsDataByDropdown([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getSectionList(mediumId: any, standardDetailsId: any) {
		getSectionDetails(
			mediumId,
			standardDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.sectionDetails;
					if (data != undefined) {
						setSectionDetailsData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setSectionDetailsData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getStudentExamMarkDetailsList(StandardDetailsId: any, SectionDetailsId: any, StudentDetailsId: any, examDetailsId: any) {
		setIsLoader(true)
		getStudentExamMarkDetails(StandardDetailsId, SectionDetailsId, StudentDetailsId, examDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.studentExamMarkDetails;
					if (data != undefined) {
						setIsLoader(false);
						setIsOpenListCard(false);
						setStudentMarkDetailsData(data);
						setDataSuccess(true);
					} else {
						setIsLoader(false);
						toasts('Undefined Data', 'Error');
						setDataSuccess(false);
						setIsOpenListCard(true);
					}
				} else if (response.data.success === false) {
					setIsLoader(false);
					setStudentMarkDetailsData([]);
					setNoDataMsg(response.data.message);
					setDataSuccess(true);
					setIsOpenListCard(false);
				} else {
					setIsLoader(false);
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
					setDataSuccess(false);
					setIsOpenListCard(true);
				}
			},
			(error) => {
				setIsLoader(false);
				toasts(error, 'Error');
				setDataSuccess(false);
			},
		);
	}

	function closeAndReset() {
		setViewOffCanvas(false);
	}
	return (
		<PageWrapper title='StudentMarkDetails'>
			<Page container='fluid'>

				{userTypeId != 9 ? <>
					<Collapse isOpen={isOpenListCard}>
						<Card stretch data-tour='list' tag='form' noValidate>
							<CardHeader borderSize={1}>
								<CardLabel icon='List' iconColor='info'>
									<CardTitle tag='div' className='h5'>
										Student Mark Details
									</CardTitle>
								</CardLabel>
							</CardHeader>
							<CardBody>
								<div className='row'>
									<div className='col-md-3'>
										<FormGroup id='batchMasterId' label='Batch' isFloating>
											<SearchableSelect isFloating ariaLabel='Batch' placeholder="Batch"
												onChange={selectBatch}
												value={batchMasterId}
												list={batchMasterData.map((data: any) => ({ value: data.batchMasterId, label: data.batchName }))}
												required
											/>
										</FormGroup>
									</div>

									{batchMasterId?.value != undefined ? (
										<div className='col-3'>
											<FormGroup id='mediumId' label='Medium' isFloating>
												<SearchableSelect
													isFloating
													ariaLabel='Medium'
													onChange={selectMedium}
													value={mediumId}
													list={mediumData.map((data: any) => ({
														value: data.mediumId,
														label: data.medium,
													}))}
												/>
											</FormGroup>
										</div>
									) : null}

									{mediumId?.value != undefined ? (
										<div className='col-3'>
											<FormGroup
												id='standardDetailsId'
												label='Standard'
												isFloating>
												<SearchableSelect
													isFloating
													ariaLabel='Standard'
													onChange={selectStandard}
													value={standardDetailsId}
													list={standardData.map((data: any) => ({
														value: data.standardDetailsId,
														label: data.standardName,
													}))}
												/>
											</FormGroup>
										</div>
									) : null}

									{standardDetailsId?.value != undefined ? (
										<div className='col-3'>
											<FormGroup id='sectionDetailsId' label='Section' isFloating>
												<SearchableSelect
													isFloating
													ariaLabel='Section'
													onChange={selectSection}
													value={sectionDetailsId}
													list={sectionDetailsData.map((data: any) => ({
														value: data.sectionDetailsId,
														label: data.sectionName,
													}))}
												/>
											</FormGroup>
										</div>
									) : null}

									{sectionDetailsId?.value != undefined ? (
										<div className='col-3 mt-3'>
											<FormGroup id='studentDetailsId' label='Student' isFloating>
												<SearchableSelect
													isFloating
													ariaLabel='Student'
													onChange={selectStudent}
													value={studentDetailsId}
													list={studentDetailsDataByDropdown.map(
														(data: any) => ({
															value: data.studentDetailsId,
															label: data.studentNameAndAdmissionNo,
														}),
													)}
												/>
											</FormGroup>
										</div>
									) : null}

									{studentDetailsId?.value != undefined ? (
										<div className={userTypeId == 9 ? 'col-2 mt-2' : 'col-2 mt-3'}>
											<Button
												className='mt-2'
												icon='ArrowDownward'
												color='primary'
												onClick={ViewExam}>
												View
											</Button>
										</div>
									) : null}
								</div>
							</CardBody>
						</Card>
					</Collapse>

					<OpenCardComponent
						isOpenListCard={isOpenListCard}
						setIsOpenListCard={setIsOpenListCard}
						isLoader={isLoader}
					/> </> : null
				}

				{dataSuccess ? (
					<>
						{studentMarkDetailsData != '' ? (
							<>
								<div className='row'>
									{studentMarkDetailsData.map((i: any, index: any) => (
										<div className='col-3' key={index}>
											<Card stretch shadow={'sm'} className="border border-light">
												<CardHeader className='bg-l25-info' style={{ height: '50px' }}>
													<CardLabel>
														<CardTitle className='text-info text-uppercase'>
															{i.examTypeName}
														</CardTitle>
													</CardLabel>
													<CardActions>
														<Button icon='ArrowForwardIos' aria-label='Read More'
															color={darkModeStatus ? 'info' : undefined} onClick={() => ViewMark(i)}
														/>
													</CardActions>
												</CardHeader>
												<CardBody>
													<div className='col-12'>
														<div className='row'>
															<div className='col d-flex align-items-center'>
																<div className='flex-grow-1 col-5'>
																	<strong>Total Mark </strong>
																</div>
																<div className='col-3 text-center'>
																	<strong> : </strong>
																</div>
																<div className='col-4 text-end'>
																	<strong><span className='text-primary'>{i.totalScored}</span> / {i.totalMark}</strong>
																</div>
															</div>
														</div>
														<div className='row mt-3'>
															<div className='col d-flex align-items-center'>
																<div className='flex-grow-1 col-5'>
																	<strong>Percentage </strong>
																</div>
																<div className='col-3 text-center'>
																	<strong> : </strong>
																</div>
																<div className='col-4 text-end'>
																	<strong>{i.percentage}</strong>
																</div>
															</div>
														</div>
														<div className='row mt-3'>
															<div className='col d-flex align-items-center'>
																<div className='flex-grow-1 col-5'>
																	<strong>Rank </strong>
																</div>
																<div className='col-3 text-center'>
																	<strong> : </strong>
																</div>
																<div className='col-4 text-end'>
																	<strong>{i.classRank}</strong>
																</div>
															</div>
														</div>
														<div className='row mt-3'>
															<div className='col d-flex align-items-center'>
																<div className='flex-grow-1 col-5'>
																	<strong>Result </strong>
																</div>
																<div className='col-3 text-center'>
																	<strong> : </strong>
																</div>
																<div className='col-4 text-end'>
																	<strong className={i.result == 'Fail' ? 'text-danger' : 'text-success'}>
																	 <Icon icon={i.result == 'Fail' ? 'Dangerous' : 'TaskAlt'} className='fs-4'/>	{i.result}
																	</strong>
																</div>
															</div>
														</div>
													</div>
												</CardBody>

											</Card>
										</div>
									))}
								</div>
							</>
						) : (
							<div className='row'>
								<div className='col-12' style={{ height: '20vh' }}></div>
								<div className='col-12 d-flex justify-content-center'>
									<Icon icon='DoNotDisturb' size={'10x'} />
								</div>
								<div className='fs-4 fw-bold text-center text-uppercase text-danger'>
									{noDataMsg}
								</div>
							</div>
						)}{' '}
					</>
				) : null}
			</Page>

			<OffCanvas
				setOpen={setViewOffCanvas}
				isOpen={viewOffCanvas}
				titleId=''
				isBodyScroll
				placement='end'
				tag='form'
				noValidate>
				<OffCanvasHeader setOpen={setViewOffCanvas} onClick={closeAndReset}>
					<OffCanvasTitle id='markList' className='text-uppercase'>{examType} </OffCanvasTitle>
				</OffCanvasHeader>
				<OffCanvasBody>
					{markDetailsData != '' ? (
						<>

							<div className='col-12'>
								<Card className={`bg-l${darkModeStatus ? 'o25' : '25'
									}-info bg-l${darkModeStatus ? 'o50' : '10'
									}-info-hover transition-base rounded-2`}
									shadow='sm' style={{ height: '95px' }}>
									<CardBody>
										<div className='d-flex align-items-center pb-3'>
											<div className='flex-shrink-0 ms-2'>
												<img style={{ borderRadius: '50%' }} src={studentProfileData.profilePath != null ? studentProfileData.profilePath : profilePic(studentProfileData.genderId)} height={60} width={60} onError={(e: any) => pictNotLoading(e, studentProfileData.genderId)} />
											</div>
											<div className='flex-grow-1 ms-3'>
												<div className='fw-bold fs-5 text-dark mb-0'>
													{studentProfileData.studentName}
												</div>
												<div className='text-muted'>
													( {studentProfileData.admissionNo} )
												</div>
											</div>
										</div>
									</CardBody>
								</Card>
							</div>

							<div className='col-12'>
								{markDetailsData.map((i: any) => (
									<div className='row g-4'>
										<div className='col-md-4'>
											<Card className={`bg-l${darkModeStatus ? 'o25' : '25'
												}-success bg-l${darkModeStatus ? 'o50' : '10'
												}-success-hover transition-base rounded-2 mb-0`}
												shadow='sm'>
												<CardBody>
													<div className='d-flex align-items-center pb-1'>
														<div className='flex-grow-1 '>
															<div className='fw-bold fs-3 text-success mb-0  d-flex justify-content-center '>
																{i.totalScored}
															</div>
															<div className='text-muted  d-flex justify-content-center'>
																<b>Total Mark</b>
															</div>
														</div>
													</div>
												</CardBody>
											</Card>
										</div>
										<div className='col-md-4'>
											<Card className={`bg-l${darkModeStatus ? 'o25' : '25'
												}-warning bg-l${darkModeStatus ? 'o50' : '10'
												}-warning-hover transition-base rounded-2 mb-0`}
												shadow='sm'>
												<CardBody>
													<div className='d-flex align-items-center pb-1'>
														<div className='flex-grow-1 '>
															<div className='fw-bold fs-3 text-warning mb-0  d-flex justify-content-center'>
																{i.percentage}
															</div>
															<div className='text-muted  d-flex justify-content-center '>
																<b>Percentage</b>
															</div>
														</div>
													</div>
												</CardBody>
											</Card>
										</div>
										<div className='col-md-4'>
											<Card className={`bg-l${darkModeStatus ? 'o25' : '25'
												}-danger bg-l${darkModeStatus ? 'o50' : '10'
												}-danger-hover transition-base rounded-2 mb-0 `}
												shadow='sm'>
												<CardBody>
													<div className='d-flex align-items-center pb-1'>
														<div className='flex-grow-1 '>
															<div className='fw-bold fs-3 text-danger mb-0  d-flex justify-content-center '>
																{i.classRank}
															</div>
															<div className='text-muted  d-flex justify-content-center '>
																<b> Rank</b>
															</div>
														</div>
													</div>
												</CardBody>
											</Card>
										</div>
									</div>
								))}
							</div>
						</>
					) : null
					}

					<br />

					<table className='table table-modern'>
						<thead>
							<tr>
								<th className='bg-l25-info text-uppercase fs-6'>Subject</th>
								<th className='bg-l25-info text-uppercase fs-6'>Total</th>
								<th className='bg-l25-info text-uppercase fs-6'>Score</th>
							</tr>
						</thead>
						<tbody>
							{markDetailsData.map((item: any) => (
								<>
									{item.markDetails.map((i: any) => (
										<tr key={i.subjectPaperDetailsId}>
											<td>{i.paperName}</td>
											<td>{i.totalMark}</td>
											<td>{i.totalScoredMark}</td>
										</tr>
									))}
									<tr className='table-danger'>
										<td className='text-dark fw-bold'>Total</td>
										<td className='text-dark fw-bold'>{item.totalMark}</td>
										<td className='text-dark fw-bold'>{item.totalScored}</td>
									</tr>
								</>
							))}
						</tbody>
					</table>
				</OffCanvasBody>
			</OffCanvas>
		</PageWrapper>
	);
};

export default StudentMarkSheet;
