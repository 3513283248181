import { useFormik } from "formik";
import { useContext, useEffect, useRef, useState } from "react"
import { useReactToPrint } from "react-to-print";
import NoDataMsg from "../../../common/components/NoDataMsg";
import OpenCardComponent from "../../../common/components/OpenCardComponent";
import SearchableSelect from "../../../common/components/SearchableSelect";
import AuthContext from "../../../contexts/authContext";
import useSortableData from "../../../hooks/useSortableData";
import Page from "../../../layout/Page/Page";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import { getLicenseKey } from "../../../services/application.settings";
import { getCurrentDateAndTime, updateFilter, convertDateRangeToEpoch, getColumnsForDataTable, updateColumnsForDataTable, getStandardDetails } from "../../../services/common.service";
import { downloadExcel, convertJsonToPdf, downloadFile } from "../../../services/ExportService";
import { getMonth } from "../../../services/master.service";
import { getDepartmentWiseLateAttendanceReport, getMonthWiseLateAttendanceReport, getYearWiseLateAttendanceReport } from "../../../services/report.service";
import { toasts } from "../../../services/toast.service";
import Button, { ButtonGroup } from "../../bootstrap/Button";
import Card, { CardHeader, CardLabel, CardTitle, CardBody, CardActions } from "../../bootstrap/Card";
import Collapse from "../../bootstrap/Collapse";
import Dropdown, { DropdownToggle, DropdownMenu, DropdownItem } from "../../bootstrap/Dropdown";
import Checks, { ChecksGroup } from "../../bootstrap/forms/Checks";
import FormGroup from "../../bootstrap/forms/FormGroup";
import Icon from "../../icon/Icon";
import PaginationButtons, { dataPagination } from "../../PaginationButtons";
import Input from "../../bootstrap/forms/Input";

const LateAttendanceReport = () => {

    useEffect(() => {
        getStandardList();
        getMonthList()
    }, [])


    const [standardDetailsData, setStandardDetailsData] = useState<any>([])
    const [semesterTypeData, setSemesterTypeData] = useState<any>([])
    const [monthData, setMonthData] = useState<any>([])
    const [lateAttendanceData, setLateAttendanceData] = useState<any>([])
    const [monthWiseLateAttendanceData, setMonthWiseLateAttendanceData] = useState<any>([])
    const [yearWiseLateAttendanceData, setYearWiseLateAttendanceData] = useState<any>([])
    const [semesterTypeId, setSemesterTypeId] = useState('')
    const [columnVisibilityData, setColumnVisibilityData] = useState([]);
    const [allColumnsData, setAllColumnsData] = useState([]);
    const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
    const [columnDataById, setColumnDataById] = useState([])
    const [isChecked, setIsChecked] = useState(false)
    const componentRef = useRef(null);
    const { userAccountId, userTypeId } = useContext(AuthContext);

    const [isOpenListCard, setIsOpenListCard] = useState(true)
    const [dataSuccess, setDataSuccess] = useState(false)

    const [cardTitle, setCardTitle] = useState('')
    const [exportFileName, setExportFileName] = useState('')

    // ForModal
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(lateAttendanceData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);
    const [isLoader, setIsLoader] = useState(false)

    const [noDataMsg, setNoDataMsg] = useState('')

    const [reportTypeId, setReportTypeId] = useState<any>('')
    const [reportTypeIdValue, setReportTypeIdValue] = useState<any>('')

    const [monthId, setMonthId] = useState<any>('')
    const [standardDetailsId, setStandardDetailsId] = useState<any>('')

    const selectReportType = (e: any) => {
        resetTable()
        setDataSuccess(false)
        setIsLoader(false)
        let reportTypeId = e
        setReportTypeId(reportTypeId?.value)
        setReportTypeIdValue(reportTypeId)
        setIsOpenListCard(true)
    }

    const lateAbsenteesReportForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            standardDetailsId: '',
            fromDate: getCurrentDateAndTime('date'),
            toDate: getCurrentDateAndTime('date'),
            semesterTypeId: '',
            monthId: '',
        },
        validate: (values) => { },

        //validateOnChange: false,
        onSubmit: () => { },
    });

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },
        //validateOnChange: false,
        onSubmit: () => { },
    });

    // Filter
    const filteredData = updateFilter(onCurrentPageData, columnVisibilityData, columnVisibilityForm);

    // Filter
    // const filteredData = onCurrentPageData.filter(
    //     (i) =>
    //         i.dateOfAttendance.toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase()) ||
    //         i.reasonName.toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase()) ||
    //         i.admissionNo.toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase()) ||
    //         i.studentName.toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase()) ||
    //         i.standardName.toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase()) ||
    //         i.sectionName.toString().toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase()),
    // );

    function viewDepartWiseList() {
        setDataSuccess(false)
        setIsLoader(true);
        let fromDate = lateAbsenteesReportForm.values.fromDate
        let toDate = lateAbsenteesReportForm.values.toDate
        let datesArray = convertDateRangeToEpoch(fromDate, toDate);
        getDepartmentWiseLateAttendance(datesArray[0], datesArray[1], standardDetailsId?.value);
        setCardTitle('Standard Wise Late Attendance Report')
        setExportFileName('DepartmentWiseLateAttendanceReport')
    }

    function viewDateWiseList() {
        setDataSuccess(false)
        setIsLoader(true);
        let fromDate = lateAbsenteesReportForm.values.fromDate
        let datesArray = convertDateRangeToEpoch(fromDate, fromDate);
        getDepartmentWiseLateAttendance(datesArray[0], datesArray[1], 0);
        setCardTitle('Daily Wise Late Attendance Report')
        setExportFileName('DailyWiseLateAttendanceReport')
    }

    function viewMonthWiseLateAttendance() {
        setDataSuccess(false)
        setIsLoader(true);
        getMonthWiseLateAttendance(monthId?.value);
        setCardTitle('Monthly Wise Late Attendance Report')
        setExportFileName('MonthlyWiseLateAttendanceReport')
    }

    function viewYearWiseLateAttendance() {
        setDataSuccess(false)
        setIsLoader(true);
        getYearWiseAttendanceReport();
        setCardTitle('Year Wise Late Attendance Report')
        setExportFileName('YearWiseLateAttendanceReport')
    }

    const selectStandard = (e: any) => {
        let standardDetailsId = e
        setStandardDetailsId(standardDetailsId)
    }

    const selectMonth = (e: any) => {
        let monthId = e
        setMonthId(monthId)
    }

    function getStandardList() {
        getStandardDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.standardDetails;
                    if (data != undefined) {

                        const standardDetailsData = [{ standardDetailsId: 0, standardName: 'Select All' }];
                        data.forEach((obj: { standardDetailsId: number; standardName: string }) => {
                            standardDetailsData.push(obj);
                        });
                        setStandardDetailsData(standardDetailsData);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setStandardDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getMonthList() {
        getMonth(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.month;
                    if (data != undefined) {
                        setMonthData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setMonthData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getDepartmentWiseLateAttendance(fromDate: number, toDate: number, standardDetailsId: any) {
        setIsLoader(true);
        getDepartmentWiseLateAttendanceReport(fromDate, toDate, standardDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.lateAttendanceReport;
                    if (data != undefined) {
                        setDataSuccess(true)
                        setIsLoader(false);
                        setLateAttendanceData(data);
                        setIsOpenListCard(false);
                        getColumnsForTable('getLateAttendanceReport', 'get');
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        setIsLoader(false);
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success == false) {
                    setIsLoader(false);
                    //toasts(response.data.message, "Error")
                    setDataSuccess(true)
                    setIsOpenListCard(false);
                    getColumnsForTable('getLateAttendanceReport', 'get');
                    setLateAttendanceData([]);
                    setNoDataMsg(response.data.message)
                } else {
                    setIsLoader(false);
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                setIsLoader(false);
                toasts(error, "Error")
            }
        )
    }

    function getMonthWiseLateAttendance(monthId: any) {
        setIsLoader(true);
        getMonthWiseLateAttendanceReport(monthId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.lateAttendanceReport;
                    if (data != undefined) {
                        setDataSuccess(true)
                        setIsLoader(false);
                        setLateAttendanceData(data);
                        setIsOpenListCard(false)
                        getColumnsForTable('getLateAttendanceReport', 'get');
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        setIsLoader(false);
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success == false) {
                    setIsLoader(false);
                    setDataSuccess(true)
                    setIsOpenListCard(false);
                    //toasts(response.data.message, "Error")
                    getColumnsForTable('getLateAttendanceReport', 'get');
                    setLateAttendanceData([]);
                    setNoDataMsg(response.data.message)
                } else {
                    setIsLoader(false);
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                setIsLoader(false);
                toasts(error, "Error")
            }
        )
    }

    function getYearWiseAttendanceReport() {
        setIsLoader(true)
        getYearWiseLateAttendanceReport(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.lateAttendanceReport;
                    if (data != undefined) {
                        setDataSuccess(true)
                        setIsLoader(false)
                        setIsOpenListCard(false)
                        setLateAttendanceData(data);
                        getColumnsForTable('getLateAttendanceReport', 'get');
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }

                    } else {
                        setIsLoader(false)
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success == false) {
                    setIsLoader(false)
                    setDataSuccess(true)
                    setIsOpenListCard(false);
                    //toasts(response.data.message, "Error")
                    getColumnsForTable('getLateAttendanceReport', 'get');
                    setLateAttendanceData([]);
                    setNoDataMsg(response.data.message)
                } else {
                    setIsLoader(false)
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                setIsLoader(false)
                toasts(error, "Error")
            }
        )
    }

    function getColumnsForTable(apiName: any, type: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setColumnVisibilityData(columnVisibilityData)

                        if (type == 'get') {
                            const allData = allColumnsData.map((item: any) => item.isDisplay);
                            const allColumns = allData.filter((item: any) => item == false);
                            setIsChecked(allColumns == '' ? true : false);
                        }
                        else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

                            for (let i = 0; i < nonDefaultValue.length; i++) {

                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0
                                }
                                all.push(obj);
                            }
                            updateColumnsSubmit('', '', all)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )

    }

    function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {

        if (columnVisibilityForm.isValid) {

            setColumnDataById(isDisplay)
            const columnVisibilityPostData = {
                licenseKey: getLicenseKey,
                userAccountId: userAccountId,
                columns: type ? type : [
                    {
                        columnVisibilityId: columnVisibilityId,
                        isDisplay: isDisplay ? 0 : 1
                    }
                ]
            }
            updateColumnsForDataTable(columnVisibilityPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        getColumnsForTable('getLateAttendanceReport', 'get');
                    }
                    else if (data.success == false) {
                        // toasts(data.success, "Error")
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        toasts(errorDescription, "Error")
                    }
                }
                , (error) => {
                    toasts(error, "Error")
                }
            )
        } else if (columnVisibilityForm.isValid == false) {
            toasts("Please fill all the details!", "Error")
        }
    }

    function resetTable() {
        setDataSuccess(false)
        lateAbsenteesReportForm.resetForm()
        setSemesterTypeId('')
        setStandardDetailsId('')
        setMonthId('')
        setIsOpenListCard(true)
    }

    // Export Configuration
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const reportTypeData = [{ reportTypeId: 1, reportType: 'Standard Wise' }, { reportTypeId: 2, reportType: 'Daily' }, { reportTypeId: 3, reportType: 'Monthly' }, { reportTypeId: 4, reportType: 'Yearly' }]

    return (
        <>
            <PageWrapper title={`Late Attendance`}>
                <Page container='fluid'>
                    <Collapse isOpen={isOpenListCard}>
                        <Card>
                            <CardHeader borderSize={1}>
                                <CardLabel icon='list' iconColor='info'>
                                    <CardTitle tag='div' className='h5'>
                                        Late Attendance Report
                                    </CardTitle>
                                </CardLabel>
                            </CardHeader>
                            <CardBody>
                                <div className="row">
                                    <div className="col-lg-2">
                                        <FormGroup id="reportTypeId" label="Select Report Type" isFloating>
                                            <SearchableSelect placeholder="Select Report Type" isFloating
                                                ariaLabel='Report Type'
                                                onChange={selectReportType}
                                                value={reportTypeIdValue}
                                                list={reportTypeData.map((data: any) => (
                                                    { value: data.reportTypeId, label: data.reportType }
                                                ))}
                                            />
                                        </FormGroup>
                                    </div>

                                    {reportTypeId == '1' ?
                                        <>
                                            <div className="col-lg-2">
                                                <FormGroup id='standardDetailsId' label='Standard' isFloating>
                                                    <SearchableSelect isFloating ariaLabel='Standard' onChange={selectStandard} value={standardDetailsId} list={standardDetailsData.map((data: any) => (
                                                        { value: data.standardDetailsId, label: data.standardName }
                                                    ))}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-3">
                                                <FormGroup id='fromDate' label='Date' isFloating>
                                                    <Input onChange={lateAbsenteesReportForm.handleChange} value={lateAbsenteesReportForm.values.fromDate}
                                                        onBlur={lateAbsenteesReportForm.handleBlur}
                                                        isValid={lateAbsenteesReportForm.isValid}
                                                        isTouched={lateAbsenteesReportForm.touched.fromDate}
                                                        invalidFeedback={lateAbsenteesReportForm.errors.fromDate}
                                                        type='date' />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-3">
                                                <FormGroup id='toDate' label='Date' isFloating>
                                                    <Input onChange={lateAbsenteesReportForm.handleChange} value={lateAbsenteesReportForm.values.toDate}
                                                        onBlur={lateAbsenteesReportForm.handleBlur}
                                                        isValid={lateAbsenteesReportForm.isValid}
                                                        isTouched={lateAbsenteesReportForm.touched.toDate}
                                                        invalidFeedback={lateAbsenteesReportForm.errors.toDate}
                                                        type='date' />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-2 align-self-center mt-2">
                                                <Button icon="ArrowDownward" color="primary" onClick={viewDepartWiseList} isDisable={standardDetailsId?.value == undefined ? true : false}>View</Button>
                                            </div>
                                        </> : null}
                                    {reportTypeId == '2' ?
                                        <>
                                            <div className="col-lg-3">
                                                <FormGroup id='fromDate' label='Date' isFloating>
                                                    <Input onChange={lateAbsenteesReportForm.handleChange} value={lateAbsenteesReportForm.values.fromDate}
                                                        onBlur={lateAbsenteesReportForm.handleBlur}
                                                        isValid={lateAbsenteesReportForm.isValid}
                                                        isTouched={lateAbsenteesReportForm.touched.fromDate}
                                                        invalidFeedback={lateAbsenteesReportForm.errors.fromDate}
                                                        type='date' />
                                                </FormGroup>
                                            </div>

                                            <div className="col-lg-2 align-self-center">
                                                <FormGroup >
                                                    <Button icon="ArrowDownward" color="primary" onClick={viewDateWiseList}>View</Button>
                                                </FormGroup>
                                            </div>
                                        </>
                                        : null}
                                    {reportTypeId == '3' ?
                                        < >                                            
                                            <div className='col-3'>
                                                <FormGroup id='monthId' label='Month' isFloating>
                                                    <SearchableSelect isFloating
                                                        ariaLabel='Month'
                                                        onChange={selectMonth}
                                                        value={monthId}
                                                        list={monthData.map((data: any) => (
                                                            { value: data.monthId, label: data.month }
                                                        ))}
                                                    />
                                                </FormGroup>
                                            </div>

                                            <div className="col-lg-2 align-self-center">
                                                <FormGroup >
                                                    <Button icon="South" color="primary"
                                                        onClick={viewMonthWiseLateAttendance}>View</Button>
                                                </FormGroup>
                                            </div>
                                        </>
                                        : null}
                                    {reportTypeId == '4' ?
                                        <>
                                            <div className="col-lg-2 align-self-center">
                                                <FormGroup >
                                                    <Button icon="South" color="primary"
                                                        onClick={viewYearWiseLateAttendance}>View</Button>
                                                </FormGroup>
                                            </div>
                                        </>
                                        : null}
                                </div>
                            </CardBody>
                        </Card>
                    </Collapse>

                    <OpenCardComponent isOpenListCard={isOpenListCard} setIsOpenListCard={setIsOpenListCard} isLoader={isLoader} />

                    {dataSuccess ? <Card stretch data-tour='list' ref={componentRef}>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info' className="col-lg-6">
                                <CardTitle tag='div' className='h5'>{cardTitle}</CardTitle>
                            </CardLabel>

                            <ButtonGroup className="col-lg-3 d-print-none" color='primary'>
                                <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                                <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
                            </ButtonGroup>

                            <CardActions className="d-print-none">
                                <Dropdown isOpen={columnVisibilityMenu} setIsOpen={setColumnVisibilityMenu} isButtonGroup>
                                    <DropdownToggle>
                                        <Button icon='FilterAlt' color='primary' isLight>Filter</Button>
                                    </DropdownToggle>
                                    <DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
                                        <DropdownItem>
                                            <div className='container py-2'>
                                                <form className='row g-3'>
                                                    <div className='col-12'>
                                                        <FormGroup>
                                                            <h6>Select All</h6>
                                                            <Checks id='available' type='switch' label='Select All Columns' onChange={() => getColumnsForTable('getLateAttendanceReport', 'post')} checked={isChecked} ariaLabel='Available status' />
                                                        </FormGroup>
                                                    </div>
                                                    <div className='col-12' style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                                                        <FormGroup>
                                                            <h6>Columns</h6>
                                                            <ChecksGroup>
                                                                {allColumnsData.map((i: any) => (
                                                                    <Checks key={i.columnVisibilityId} label={i.columnName} onChange={() => updateColumnsSubmit(i.columnVisibilityId, i.isDisplay, '')} checked={i.isDisplay} disabled={i.isDefault == true} />
                                                                ))}
                                                            </ChecksGroup>
                                                        </FormGroup>
                                                    </div>
                                                </form>
                                            </div>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                <Dropdown isButtonGroup>
                                    <DropdownToggle>
                                        <Button color='primary' isLight icon='CloudDownload'> Export </Button>
                                    </DropdownToggle>
                                    <DropdownMenu isAlignmentEnd>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel(exportFileName, lateAttendanceData, columnVisibilityData)}> Excel </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(lateAttendanceData, columnVisibilityData, exportFileName)}> PDF
                                            </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile(exportFileName, lateAttendanceData, columnVisibilityData)}> CSV </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='Print' onClick={handlePrint}> Print </Button>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </CardActions>
                        </CardHeader>

                        <CardBody className='table-responsive' isScrollable>
                            <table className='table table-modern table-hover text-center text-nowrap'>
                                <thead>
                                    <tr className='table-primary'>
                                        <th scope='col' onClick={() => requestSort('sno')}
                                            className='cursor-pointer text-decoration-underline'>S.No{' '}
                                            <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                        </th>
                                        {columnVisibilityData.map((column: any) => (<>
                                            <th key={column.columnVisibilityId} scope='col' onClick={() => requestSort(column.keyName)}
                                                className='cursor-pointer text-decoration-underline'>
                                                {column.columnName}
                                                <Icon size='lg' className={getClassNamesFor(column.keyName)} icon='FilterList' />
                                            </th>
                                        </>))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredData != "" ?
                                        <>
                                            {filteredData.map((i: any) => (
                                                <tr key={i.studentLateAttendanceId}>
                                                    <td>{i.sno}</td>
                                                    {columnVisibilityData.map((column: any) => (<>
                                                        {column.keyName == 'dateOfAttendance' && column.isDisplay ? <td>{i.dateOfAttendance}</td> : null}
                                                    </>))}
                                                    {columnVisibilityData.map((column: any) => (<>
                                                        {column.keyName == 'reasonName' && column.isDisplay ? <td>{i.reasonName}</td> : null}
                                                    </>))}
                                                    {columnVisibilityData.map((column: any) => (<>
                                                        {column.keyName == 'admissionNo' && column.isDisplay ? <td>{i.admissionNo}</td> : null}
                                                    </>))}
                                                    {columnVisibilityData.map((column: any) => (<>
                                                        {column.keyName == 'studentName' && column.isDisplay ? <td>{i.studentName}</td> : null}
                                                    </>))}
                                                    {columnVisibilityData.map((column: any) => (<>
                                                        {column.keyName == 'standardName' && column.isDisplay ? <td>{i.standardName}</td> : null}
                                                    </>))}
                                                    {columnVisibilityData.map((column: any) => (<>
                                                        {column.keyName == 'sectionName' && column.isDisplay ? <td>{i.sectionName}</td> : null}
                                                    </>))}
                                                </tr>
                                            ))
                                            } </>
                                        :
                                        <NoDataMsg columnsCount={7} msg={noDataMsg} />}
                                </tbody>
                            </table>
                        </CardBody>
                        <PaginationButtons
                            data={items}
                            label='items'
                            setCurrentPage={setCurrentPage}
                            currentPage={currentPage}
                            perPage={perPage}
                            setPerPage={setPerPage}
                        />
                    </Card> : null}

                </Page>
            </PageWrapper>
        </>
    )
}
export default LateAttendanceReport;