import { useContext, useEffect, useRef, useState } from "react";
import useDarkMode from "../../../../hooks/useDarkMode";
import useSortableData from "../../../../hooks/useSortableData";
import PaginationButtons, { dataPagination } from "../../../PaginationButtons";
import { useFormik } from "formik";
import { toasts } from "../../../../services/toast.service";
import Page from "../../../../layout/Page/Page";
import Collapse from "../../../bootstrap/Collapse";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTabItem, CardTitle } from "../../../bootstrap/Card";
import FormGroup from "../../../bootstrap/forms/FormGroup";
import Select from "../../../bootstrap/forms/Select";
import Button, { ButtonGroup } from "../../../bootstrap/Button";
import OpenCardComponent from "../../../../common/components/OpenCardComponent";
import Icon from "../../../icon/Icon";
import Input from "../../../bootstrap/forms/Input";
import AlertService from "../../../../services/AlertService";
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from "../../../bootstrap/OffCanvas";
import classNames from "classnames";
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";
import { useNavigate, useParams } from "react-router-dom";
import Textarea from "../../../bootstrap/forms/Textarea";
import DeleteComponents from "../../../../common/components/DeleteComponents";
import { getAllStudent, getProfileForStudent, getStudentDetailsBySectionDetailsId } from "../../../../services/student.service";
import { addCertificateTracker, addStudentCertificateDetails, deleteCertificateTrackingDetails, getCertificateType, getOriginalCertificateListByUserAccountId, getStudentOrStaffCertificateTrackingDetails, updateCertificateTracker } from "../../../../services/certificate.service";
import { getColumnsForDataTable, getCurrentDateAndTime, getMedium, getStandardDetails, pictNotLoading, profilePic, updateColumnsForDataTable, updateFilter } from "../../../../services/common.service";
import AuthContext from "../../../../contexts/authContext";
import { getLicenseKey } from "../../../../services/application.settings";
import SubHeader, { SubHeaderLeft, SubheaderSeparator } from "../../../../layout/SubHeader/SubHeader";
import { TableLoader, showLoader } from "../../../../services/loader.services";
import SearchableSelect from "../../../../common/components/SearchableSelect";
import NoDataMsg from "../../../../common/components/NoDataMsg";
import { getBatchMasterDetails, getSectionDetails } from "../../../../services/master.service";

const StudentCertificateTracker = () => {

    const [studentDetailsId, setStudentDetailsId] = useState<any>(Number)

    const navigate = useNavigate();
    const { navigatedStudentDetailsId } = useParams();
    const [isNavigatedStudent, setIsNavigatedStudent] = useState(false)

    useEffect(() => {
        getAllStudentDetails();
        if (navigatedStudentDetailsId != undefined) {
            setDataSuccess(true);
            setIsLoader(true);
            setIsNavigatedStudent(true);
            getStudentProfile(navigatedStudentDetailsId);
            setStudentDetailsId({ value: navigatedStudentDetailsId, label: '' })
        }
    }, [])

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const [studentProfileData, setStudentProfileData] = useState<any>([])

    const [isOpenListCard, setIsOpenListCard] = useState(true)
    const [isStudentOrClassWise, setIsStudentOrClassWise] = useState(true)
    const { darkModeStatus } = useDarkMode();
    const [mediumId, setMediumId] = useState<any>(Number)
    const [sectionDetailsId, setSectionDetailsId] = useState<any>(Number)
    const [standardDetailsId, setStandardDetailsId] = useState<any>(Number)
    const [studentOrStaffOriginalCertificateId, setStudentOrStaffOriginalCertificateId] = useState<any>(Number)

    const componentRef = useRef(null);
    const [dataSuccess, setDataSuccess] = useState(false)

    const [mediumData, setMediumData] = useState<any>([])
    const [standardDetailsData, setStandardDetailsData] = useState<any>([])
    const [sectionDetailsData, setSectionDetailsData] = useState<any>([])
    const [studentDetailsData, setStudentDetailsData] = useState<any>([])
    const [studentDataArray, setStudentDataArray] = useState<any>([])
    const [allStudentData, setAllStudentData] = useState<any>([])
    const [studentOrStaffCertificateTrackingData, setStudentOrStaffCertificateTrackingData] = useState([])

    const [columnVisibilityData, setColumnVisibilityData] = useState<any>([]);
    const [allColumnsData, setAllColumnsData] = useState([]);
    const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
    const [columnDataById, setColumnDataById] = useState([])
    const [isChecked, setIsChecked] = useState(false)

    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(studentOrStaffCertificateTrackingData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);

    const [certificateTypeData, setCertificateTypeData] = useState<any>([])
    const [certificateTrackerDetailsData, setCertificateTrackerDetailsData] = useState([])
    const [isLoader, setIsLoader] = useState(false)

    //Check
    const [checkArray, setCheckArray] = useState<any>([])
    const [checkArray1, setCheckArray1] = useState<any>([])
    const [certificateTrackerCheckArray, setCertificateTrackerCheckArray] = useState<any>([])
    const [certificateTrackerCheckArray1, setCertificateTrackerCheckArray1] = useState<any>([])

    //OffCanvas
    const [addOriginalCertificateOffCanvas, setAddOriginalCertificateOffCanvas] = useState(false)
    const [addTrackingDetailsOffCanvas, setAddTrackingDetailsOffCanvas] = useState(false)
    const [editCertificateTrackerOffCanvas, setEditCertificateTrackerOffCanvas] = useState(false)
    const [deleteOffCanvas, setDeleteOffCanvas] = useState(false)
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);

    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);
    const [noDataMsg, setNoDataMsg] = useState('')

    const addOriginalCertificateForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            certificateTypeId: '',
            submittedDate: getCurrentDateAndTime('date'),
            status: 'IN',
        },
        validate: (values) => {
            const errors: {
                submittedDate?: string;
                certificateTypeId?: string;
                status?: string;
            } = {};
            if (checkArray.length == 0) {
                errors.certificateTypeId = 'Required';
            }
            if (!values.submittedDate) {
                errors.submittedDate = 'Required';
            }
            if (!values.status) {
                errors.status = 'Required';
            }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: () => { addOriginalCertificateSubmit() },
    });

    const addCertificateTrackerForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            originalCertificateId: '',
            dueDate: getCurrentDateAndTime('date'),
            status: 'DUE',
            returnDate: '',
            remarks: '',
        },
        validate: (values) => {
            const errors: {
                dueDate?: string;
                status?: string;
                originalCertificateId?: string;
            } = {};
            if (!values.dueDate) {
                errors.dueDate = 'Required';
            }
            if (certificateTrackerCheckArray.length == 0) {
                errors.originalCertificateId = 'Required';
            }
            if (!values.status) {
                errors.status = 'Required';
            }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: () => { addCertificateTrackerSubmit() },
    });

    const editCertificateTrackerForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            originalCertificateId: '',
            studentOrStaffCertificateTrackerId: '',
            dueDate: '',
            status: '',
            returnDate: '',
            actualReturnDate: '',
            remarks: '',
        },
        validate: (values) => {
            const errors: {
                returnDate?: string;
                dueDate?: string;
                actualReturnDate?: string;
                status?: string;
                originalCertificateId?: string;
            } = {};
            if (!values.dueDate) {
                errors.dueDate = 'Required';
            }
            if (!values.returnDate) {
                errors.returnDate = 'Required';
            }
            if (!values.actualReturnDate) {
                errors.actualReturnDate = 'Required';
            }
            if (!studentOrStaffOriginalCertificateId?.value) {
                errors.originalCertificateId = 'Required';
            }
            if (!values.status) {
                errors.status = 'Required';
            }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: () => { updateCertificateTrackerSubmit() },
    });

    const deleteCertificateTrackerForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            studentOrStaffCertificateTrackerId: '',
            remarks: '',
            certificateName: '',
            submittedDate: '',
            dueDate: '',
            returnDate: '',
            status: ''
        },
        validate: (values) => { },
        //validateOnChange: false,
        onSubmit: () => { deleteCertificateTrackerSubmit() },
    });

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },

        //validateOnChange: false,
        onSubmit: () => { },
    });

    // Filter
    const filteredData = updateFilter(onCurrentPageData, columnVisibilityData, columnVisibilityForm);

    const [batchMasterId, setBatchMasterId] = useState<any>('')
	const [batchMasterData, setBatchMasterData] = useState<any>([])

	const selectBatch = (e: any) => {
		setStandardDetailsId('')
		setSectionDetailsId('')
		setMediumId('')
		let batchMasterId = e
		setBatchMasterId(batchMasterId)
		getMediumList();
	}

    const selectMedium = (e: any) => {
        setStandardDetailsId('')
        setSectionDetailsId('')
        setStudentDetailsId('')
        let mediumId = e
        setMediumId(mediumId)
        getStandardList()
    }

    const selectStandard = (e: any) => {
        setSectionDetailsId('')
        setStudentDetailsId('')
        let standardDetailsId = e
        setStandardDetailsId(standardDetailsId)

        if (mediumId?.value != undefined && standardDetailsId?.value != undefined) {
            getSectionList(mediumId?.value, standardDetailsId?.value)
        }
    }

    const selectSection = (e: any) => {
        setStudentDetailsId('')
        setStudentDetailsData([])
        let sectionDetailsId = e
        setSectionDetailsId(sectionDetailsId)
        if (batchMasterId?.value != undefined && sectionDetailsId?.value != undefined) {
            getStudentListBySectionId(batchMasterId?.value, sectionDetailsId?.value)
        }
    }

    const selectStudent = (e: any) => {
        setDataSuccess(false)
        setIsOpenListCard(true);
        let studentDetailsId = e
        setStudentDetailsId(studentDetailsId)
    }

    function viewParticularStudent() {
        getCertificateTypeList(9, 1);
        setDataSuccess(false)
        setIsLoader(true);
        columnVisibilityForm.resetForm();
        getStudentProfile(studentDetailsId?.value);

    }

    function getCertificateTrackingDetails(userTypeId: number, studentOrStaffDetailsId: any, studentOrStaffCertificateTrackerId: number) {
        getStudentOrStaffCertificateTrackingDetails(userTypeId, studentOrStaffDetailsId, studentOrStaffCertificateTrackerId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentOrStaffCertificateTrackingDetails;
                    let tableData = response.data.data.studentOrStaffCertificateTrackingDetails[0]['certificateTracker'];
                    if (data != undefined) {
                        setStudentDataArray(data);
                        getColumnsForTable('getStudentOrStaffCertificateTrackingDetails', 'get');
                        setStudentOrStaffCertificateTrackingData(tableData)
                        setDataSuccess(true)
                        setIsOpenListCard(false);
                        setIsLoader(false);
                        for (let i = 0; i < tableData.length; i++) {
                            tableData[i].sno = i + 1;
                        }
                    } else {
                        setIsLoader(false);
                        //toasts("Undefined Data", "Error")
                        setAlertStatus({ message: "Undefined Data", type: "error" });
                        setIsOpen(true);
                        setDataSuccess(false)
                        setIsOpenListCard(false);
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setNoDataMsg(response.data.message)
                    getColumnsForTable('getStudentOrStaffCertificateTrackingDetails', 'get');
                    setStudentOrStaffCertificateTrackingData([])
                    setIsLoader(false);
                    setDataSuccess(true)
                    setIsOpenListCard(false);
                } else {
                    setIsLoader(false);
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    //toasts(errorDescription, "Error")
                    setAlertStatus({ message: errorDescription, type: "error" });
                    setIsOpen(true);
                    setDataSuccess(false)
                }
            }, error => {
                setIsLoader(false);
                //toasts(error, "Error")
                setAlertStatus({ message: error, type: "error" });
                setIsOpen(true);
            }
        )
    }

    function getStudentListBySectionId(batchMasterId: any, sectionDetailsId: any) {
        getStudentDetailsBySectionDetailsId(batchMasterId, sectionDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentDetailsBySectionDetailsId;
                    if (data != undefined) {
                        setStudentDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setStudentDetailsData([]);
                    setDataSuccess(false);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                    setDataSuccess(false);
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getBatchList() {
		getBatchMasterDetails(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.batchMasterDetails;
					if (data != undefined) {
						setBatchMasterData(data);
					} else {
						toasts("Undefined Data", "Error")
					}
				}
				else if (response.data.success === false) {
					// toasts(response.data.message, "Error")
					setBatchMasterData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, "Error")
				}
			}, error => {
				toasts(error, "Error")
			}
		)
	}

    function getMediumList() {
        getMedium(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.medium;
                    if (data != undefined) {
                        setMediumData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setMediumData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStandardList() {
        getStandardDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.standardDetails;
                    if (data != undefined) {
                        setStandardDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setStandardDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getSectionList(mediumId: number, standardDetailsId: number) {
        getSectionDetails(mediumId, standardDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.sectionDetails;
                    if (data != undefined) {
                        setSectionDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setSectionDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getAllStudentDetails() {
        getAllStudent(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentDetails;
                    if (data != undefined) {
                        setAllStudentData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setAllStudentData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getCertificateTypeList(userTypeId: number, certificateTypeId: number) {
        getCertificateType(userTypeId, certificateTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.certificateType;
                    if (data != undefined) {
                        setCertificateTypeData(data);

                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getOriginalCertificateListByUserAccountIdList(userTypeId: number, studentDetailsId: number) {
        getOriginalCertificateListByUserAccountId(userTypeId, studentDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.originalCertificateListByUserAccountId;
                    if (data != undefined) {
                        setCertificateTrackerDetailsData(data);
                    } else {
                        //toasts("Undefined Data", "Error")
                        setAlertStatus({ message: "Undefined Data", type: "error" });
                        setIsOpen(true);
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    // setAlertStatus({ message: response.data.message, type: "error" });
                    // setIsOpen(true);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    //toasts(errorDescription, "Error")
                    setAlertStatus({ message: errorDescription, type: "error" });
                    setIsOpen(true);
                }
            }, error => {
                //toasts(error, "Error")
                setAlertStatus({ message: error, type: "error" });
                setIsOpen(true);
            }
        )
    }

    function selectOriginalCertificateType(e: any) {
        let certificateTypeId = e.map((item: any) => item.value)
        setCheckArray1(e)
        setCheckArray(certificateTypeId)
    }

    function selectCertificateTracker(e: any) {

        let originalCertificateId = e.map((item: any) => item.value)
        setCertificateTrackerCheckArray1(e)
        setCertificateTrackerCheckArray(originalCertificateId)
    }

    function addCertificateTrackerOpen() {
        setAddTrackingDetailsOffCanvas(true)
        getOriginalCertificateListByUserAccountIdList(10, studentDetailsId?.value)
    }


    function setAddStudentCertificateDetails() {
        return {
            studentDetailsId: studentDetailsId?.value,
            certificateTypeId: checkArray,
            submittedDate: addOriginalCertificateForm.values.submittedDate,
            status: addOriginalCertificateForm.values.status,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId
        }
    }

    function addOriginalCertificateSubmit() {
        showLoader(true);
        if (addOriginalCertificateForm.isValid) {
            let addStudentCertificateDetailsPostData = setAddStudentCertificateDetails();
            addStudentCertificateDetails(addStudentCertificateDetailsPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        viewParticularStudent();
                        closeAndReset();
                    }
                    else if (data.success == false) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false);
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false);
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (addOriginalCertificateForm.isValid == false) {
            showLoader(false);
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function setCertificateTracker() {
        return {
            originalCertificateId: certificateTrackerCheckArray,
            studentOrStaffDetailsId: studentDetailsId?.value,
            status: addCertificateTrackerForm.values.status,
            dueDate: addCertificateTrackerForm.values.dueDate,
            returnDate: addCertificateTrackerForm.values.returnDate,
            remarks: addCertificateTrackerForm.values.remarks,
            userTypeId: 10,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId
        }
    }

    function setUpdateCertificateTracker() {
        return {
            studentOrStaffCertificateTrackerId: editCertificateTrackerForm.values.studentOrStaffCertificateTrackerId,
            status: editCertificateTrackerForm.values.status,
            studentOriginalCertificateId: studentOrStaffOriginalCertificateId?.value,
            dueDate: editCertificateTrackerForm.values.dueDate,
            returnDate: editCertificateTrackerForm.values.returnDate,
            actualReturnDate: editCertificateTrackerForm.values.actualReturnDate,
            remarks: editCertificateTrackerForm.values.remarks,
            userTypeId: 10,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId
        }
    }

    function addCertificateTrackerSubmit() {
        showLoader(true);
        if (addCertificateTrackerForm.isValid) {
            let addStudentCertificateTrackerDetailsPostData = setCertificateTracker()
            addCertificateTracker(addStudentCertificateTrackerDetailsPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        viewParticularStudent();
                        closeAndReset();
                    }
                    else if (data.success == false) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false);
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false);
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (addCertificateTrackerForm.isValid == false) {
            showLoader(false);
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function updateCertificateTrackerSubmit() {
        showLoader(true);
        if (addCertificateTrackerForm.isValid) {
            let studentCertificateTrackerDetailsPostData = setUpdateCertificateTracker()
            updateCertificateTracker(studentCertificateTrackerDetailsPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        viewParticularStudent();
                        closeAndReset();
                    }
                    else if (data.success == false) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false);
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false);
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (addCertificateTrackerForm.isValid == false) {
            showLoader(false);
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function setDeleteCertificateTracker() {
        return {
            studentOrStaffCertificateTrackerId: deleteCertificateTrackerForm.values.studentOrStaffCertificateTrackerId,
            userTypeId: 10,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId
        }
    }

    function onActivate(i: any, type: any) {
        if (i != undefined) {

            if (type == 'edit') {
                setEditCertificateTrackerOffCanvas(true)
            } else {
                setDeleteOffCanvas(true)
            }

            let selectedCertificate = i
            getCertificateTrackingDetails(10, studentDetailsId?.value, 0);
            setStudentOrStaffOriginalCertificateId(selectedCertificate.studentOrStaffOriginalCertificateId != null ? { value: selectedCertificate.studentOrStaffOriginalCertificateId, label: selectedCertificate.certificateName } : 0)
            editCertificateTrackerForm.setValues({
                studentOrStaffCertificateTrackerId: selectedCertificate.studentOrStaffCertificateTrackerId,
                originalCertificateId: selectedCertificate.originalCertificateId,
                remarks: selectedCertificate.remarks,
                dueDate: selectedCertificate.dueDate,
                returnDate: selectedCertificate.returnDate,
                actualReturnDate: selectedCertificate.actualReturnDate,
                status: selectedCertificate.status

            });

            deleteCertificateTrackerForm.setValues({
                studentOrStaffCertificateTrackerId: selectedCertificate.studentOrStaffCertificateTrackerId,
                remarks: selectedCertificate.remarks,
                certificateName: selectedCertificate.certificateName,
                submittedDate: selectedCertificate.submittedDate,
                dueDate: selectedCertificate.dueDate,
                returnDate: selectedCertificate.returnDate,
                status: selectedCertificate.status

            });
        }
    }

    function deleteCertificateTrackerSubmit() {
        showLoader(true);
        let deleteStudentCertificateTrackerDetailsPostData = setDeleteCertificateTracker()
        deleteCertificateTrackingDetails(deleteStudentCertificateTrackerDetailsPostData,
            (response) => {
                const data = response.data;
                if (data.success == true) {
                    showLoader(false);
                    setAlertStatus({ message: data.message, type: "success" });
                    setIsOpen(true);
                    viewParticularStudent();
                    closeAndReset();
                }
                else if (data.success == false) {
                    showLoader(false);
                    setAlertStatus({ message: data.message, type: "error" });
                    setIsOpen(true);
                }
                else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    showLoader(false);
                    setAlertStatus({ message: errorDescription, type: "error" });
                    setIsOpen(true);
                }
            }
            , (error) => {
                showLoader(false);
                setAlertStatus({ message: error, type: "error" });
                setIsOpen(true);
            }
        )
    }

    function getColumnsForTable(apiName: any, type: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setColumnVisibilityData(columnVisibilityData)

                        if (type == 'get') {
                            const allData = allColumnsData.map((item: any) => item.isDisplay);
                            const allColumns = allData.filter((item: any) => item == false);
                            setIsChecked(allColumns == '' ? true : false);
                        }
                        else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

                            for (let i = 0; i < nonDefaultValue.length; i++) {

                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0
                                }
                                all.push(obj);
                            }
                            updateColumnsSubmit('', '', all)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )

    }

    function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {

        if (columnVisibilityForm.isValid) {

            setColumnDataById(isDisplay)
            const columnVisibilityPostData = {
                licenseKey: getLicenseKey,
                userAccountId: userAccountId,
                columns: type ? type : [
                    {
                        columnVisibilityId: columnVisibilityId,
                        isDisplay: isDisplay ? 0 : 1
                    }
                ]
            }

            updateColumnsForDataTable(columnVisibilityPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        getColumnsForTable('getStudentOrStaffCertificateTrackingDetails', 'get');
                    }
                    else if (data.success == false) {
                        // toasts(data.success, "Error")
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        toasts(errorDescription, "Error")
                    }
                }
                , (error) => {
                    toasts(error, "Error")
                }
            )
        } else if (columnVisibilityForm.isValid == false) {
            toasts("Please fill all the details!", "Error")
        }
    }

    function getStudentProfile(studentDetailsId: any) {
        getProfileForStudent(studentDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentProfile;
                    if (data != undefined) {
                        setStudentProfileData(data);
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                        getCertificateTrackingDetails(10, studentDetailsId, 0);
                        getOriginalCertificateListByUserAccountIdList(10, studentDetailsId)
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function closeAndReset() {
        setAddOriginalCertificateOffCanvas(false);
        setAddTrackingDetailsOffCanvas(false);
        setDeleteOffCanvas(false);
        addCertificateTrackerForm.resetForm();
        addOriginalCertificateForm.resetForm();
        deleteCertificateTrackerForm.resetForm();
        setIsDeleteOpen(false)
        setCheckArray([])
        setCheckArray1([])
        setCertificateTrackerCheckArray([])
        setCertificateTrackerCheckArray1([])
    }

    function selectStudentCard() {
        if (isStudentOrClassWise) {
            getBatchList()           
        }
        setIsStudentOrClassWise(!isStudentOrClassWise)
        setDataSuccess(false)
        setMediumId('')
        setSectionDetailsId('')
        setStandardDetailsId('')
        setStudentDetailsId('')
        setBatchMasterId('')
    }

    return (
        <>
            <PageWrapper title='Certificate Tracker'>
                {isNavigatedStudent ?
                    <SubHeader>
                        <SubHeaderLeft>
                            <Button color='info' isLink icon='ArrowBack' onClick={() => navigate(`../academic/studentList`)}>
                                Back to List
                            </Button>
                            <SubheaderSeparator />
                            {studentProfileData.map((student: any) => (
                                <div key={student.studentDetailsId}>
                                    <img style={{ borderRadius: '50%' }}
                                        src={student.profilePath != null ? student.profilePath : profilePic(student.genderId)}
                                        height='40' width='40' onError={(e: any) => pictNotLoading(e, student.genderId)}
                                    />
                                    <span>
                                        <strong className='ms-3'>{student.studentName} </strong>
                                    </span>
                                    <span className='border border-success border-2 text-success fw-bold px-3 py-2 rounded'>
                                        {student.admissionNo}
                                    </span>
                                </div>))}
                        </SubHeaderLeft>
                    </SubHeader> : <></>}
                <Page container='fluid'>
                    {!isNavigatedStudent ? <>

                        <Collapse isOpen={isOpenListCard}>
                            <Card>
                                <CardHeader borderSize={1}>
                                    <CardActions>
                                        <Button
                                            icon='PersonSearch'
                                            color='info'
                                            isLight={isStudentOrClassWise ? false : true}
                                            onClick={selectStudentCard}>
                                            Student
                                        </Button>

                                        <Button
                                            icon='PeopleAlt'
                                            color='info'
                                            isLight={isStudentOrClassWise ? true : false}
                                            onClick={selectStudentCard}>
                                            Class Wise
                                        </Button>
                                    </CardActions>
                                </CardHeader>
                                {isStudentOrClassWise ?
                                    <CardBody>
                                        <div className='row'>
                                            <div className="col-4">
                                                <FormGroup id='studentDetailsId' label='Student' isFloating>
                                                    <SearchableSelect isFloating ariaLabel='Student' onChange={selectStudent} value={studentDetailsId}
                                                        list={allStudentData.map((data: any) => (
                                                            { value: data.studentDetailsId, label: data.studentNameAndAdmissionNo }
                                                        ))}
                                                    />
                                                </FormGroup>
                                            </div>

                                            <div className="col-3 mt-2">
                                                <Button icon='ArrowDownward' color='primary' onClick={viewParticularStudent} isDisable={studentDetailsId?.value == undefined}>View</Button>
                                            </div>
                                        </div>
                                    </CardBody> :
                                    <CardBody>
                                        <div className='row'>
                                            <div className='col-md-3'>
                                                <FormGroup id='batchMasterId' label='Batch' isFloating>
                                                    <SearchableSelect isFloating ariaLabel='Batch' placeholder="Batch"
                                                        onChange={selectBatch}
                                                        value={batchMasterId}
                                                        list={batchMasterData.map((data: any) => ({ value: data.batchMasterId, label: data.batchName }))} required />
                                                </FormGroup>
                                            </div>

                                            {batchMasterId?.value != undefined ?
                                                <div className='col-3'>
                                                    <FormGroup id='mediumId' label='Medium' isFloating>
                                                        <SearchableSelect isFloating ariaLabel='Medium' onChange={selectMedium} value={mediumId}
                                                            list={mediumData.map((data: any) => (
                                                                { value: data.mediumId, label: data.medium }
                                                            ))}
                                                        />
                                                    </FormGroup>
                                                </div> : null
                                            }

                                            {mediumId?.value != undefined ? <div className='col-3'>
                                                <FormGroup id='standardDetailsId' label='Standard' isFloating>
                                                    <SearchableSelect isFloating ariaLabel='Standard' onChange={selectStandard}
                                                        value={standardDetailsId} list={standardDetailsData.map((data: any) => (
                                                            { value: data.standardDetailsId, label: data.standardName }
                                                        ))}
                                                    />
                                                </FormGroup>
                                            </div> : null}

                                            {standardDetailsId?.value != undefined ? <div className='col-3'>
                                                <FormGroup id='sectionDetailsId' label='Section' isFloating>
                                                    <SearchableSelect isFloating ariaLabel='Section' onChange={selectSection} value={sectionDetailsId}
                                                        list={sectionDetailsData.map((data: any) => (
                                                            { value: data.sectionDetailsId, label: data.sectionName }
                                                        ))}
                                                    />
                                                </FormGroup>
                                            </div> : null}

                                            {sectionDetailsId?.value != undefined ?
                                                <div className="col-3 mt-3">
                                                    <FormGroup id='studentDetailsId' label='Student' isFloating>
                                                        <SearchableSelect isFloating ariaLabel='Student' onChange={selectStudent} value={studentDetailsId}
                                                            list={studentDetailsData.map((data: any) => (
                                                                { value: data.studentDetailsId, label: data.studentNameAndAdmissionNo }
                                                            ))}
                                                        />
                                                    </FormGroup>
                                                </div>
                                                : null}

                                            {studentDetailsId?.value != undefined ? <div className="col-2">
                                                <Button icon='ArrowDownward' className="mt-4" color='primary' onClick={viewParticularStudent} isDisable={studentDetailsId?.value == undefined}>View</Button>
                                            </div> : null}
                                        </div>
                                    </CardBody>}
                            </Card>
                        </Collapse>

                        <OpenCardComponent isOpenListCard={isOpenListCard} setIsOpenListCard={setIsOpenListCard} isLoader={isLoader} />
                    </>
                        : null}
                    {dataSuccess ?
                        <Card stretch data-tour='list' ref={componentRef} id='pdf'>
                            <CardHeader borderSize={1}>
                                <CardLabel icon='List' iconColor='info' className="col-lg-5">
                                    <CardTitle tag='div' className='h5'>Certificate Tracker</CardTitle>
                                </CardLabel>&nbsp;&nbsp;
                                <CardActions className="d-print-none">
                                    <ButtonGroup className=" d-print-none" color='primary'>
                                        <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                                        <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
                                    </ButtonGroup>

                                    <Button color='primary' icon='Add' isLight onClick={() => setAddOriginalCertificateOffCanvas(true)}>
                                        Add Original Certificate
                                    </Button>
                                    <Button color='primary' icon='Add' isLight onClick={addCertificateTrackerOpen}>
                                        Add New
                                    </Button>
                                </CardActions>
                            </CardHeader>

                            <CardBody className='table-responsive' isScrollable>
                                {isLoader ?
                                    <TableLoader /> :
                                    <table className='table table-modern table-hover text-nowrap'>
                                        <thead>
                                            <tr>
                                                <th scope='col' onClick={() => requestSort('sno')}
                                                    className='cursor-pointer text-decoration-underline'>S.No{' '}
                                                    <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                                </th>
                                                {columnVisibilityData.map((column: any) => (
                                                    <th key={column.keyName} scope='col' onClick={() => requestSort(column.keyName)}
                                                        className='cursor-pointer text-decoration-underline'>
                                                        {column.columnName}
                                                        <Icon size='lg' className={getClassNamesFor(column.keyName)} icon='FilterList' />
                                                    </th>
                                                ))}
                                                <th className="d-print-none" colSpan={2}>Action
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredData != "" ?
                                                <>
                                                    {filteredData.map((rowData: any) => (
                                                        <tr key={rowData.studentOrStaffCertificateTrackerId}>
                                                            <td>{rowData.sno}</td>
                                                            {columnVisibilityData.map((column: any) => (
                                                                (column.isDisplay && rowData[column.keyName]) ?
                                                                    <td key={`${rowData.studentOrStaffCertificateTrackerId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />
                                                            ))}

                                                            {rowData.status == 'DUE' ? <td className="d-print-none"><Button color='dark' isLight aria-label='Edit'
                                                                icon='Edit' onClick={() => { onActivate(rowData, 'edit') }}
                                                            /></td> : <td />}
                                                            <td className="d-print-none" ><Button color='danger' isLight aria-label='Edit'
                                                                icon='Delete' onClick={() => { onActivate(rowData, 'delete') }}
                                                            /></td>
                                                        </tr>
                                                    ))}
                                                </>
                                                :
                                                <NoDataMsg columnsCount={columnVisibilityData.length + 2} msg={noDataMsg} />}
                                        </tbody>
                                    </table>}
                            </CardBody>
                            <PaginationButtons
                                className="d-print-none"
                                data={items}
                                label='items'
                                setCurrentPage={setCurrentPage}
                                currentPage={currentPage}
                                perPage={perPage}
                                setPerPage={setPerPage}
                            />
                        </Card> : null}

                    <OffCanvas setOpen={setAddOriginalCertificateOffCanvas} isOpen={addOriginalCertificateOffCanvas} titleId='addOriginalCertificateOffCanvas' isBodyScroll tag="form" noValidate onSubmit={addOriginalCertificateForm.handleSubmit}>
                        <OffCanvasHeader setOpen={setAddOriginalCertificateOffCanvas} onClick={closeAndReset}>
                            <OffCanvasTitle id='addOriginalCertificateOffCanvas'>Add Original Certificate</OffCanvasTitle>
                        </OffCanvasHeader>
                        <OffCanvasBody>

                            <div className="row g-4">
                                {studentProfileData.map((student: any) => (
                                    <div className='col-12' key={student.studentDetailsId}>
                                        <Card className={`bg-l${darkModeStatus ? 'o25' : '25'
                                            }-info bg-l${darkModeStatus ? 'o50' : '10'
                                            }-info-hover transition-base rounded-2 mb-4`}
                                            shadow='sm'>
                                            <CardBody>
                                                <div className='d-flex align-items-center pb-3'>
                                                    <div className='flex-shrink-0 ms-2'>
                                                        <img style={{ borderRadius: '50%' }} src={student.profilePath != null ? student.profilePath : profilePic(student.genderId)} height={65} width={65} onError={(e: any) => pictNotLoading(e, student.genderId)} />
                                                    </div>
                                                    <div className='flex-grow-1 ms-3'>
                                                        <div className='fw-bold fs-4 text-dark mb-0'>
                                                            {student.studentName}
                                                        </div>
                                                        <div className='text-muted'>
                                                            ( {student.admissionNo} )
                                                        </div>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </div>
                                ))}
                                <div className='col-12'>
                                    <FormGroup id='certificateTypeId' label="Certificate Type" >
                                        <SearchableSelect multiple
                                            ariaLabel='Certificate Type'
                                            placeholder="Select Certificate Type"
                                            onChange={(e: any) => selectOriginalCertificateType(e)}
                                            value={checkArray1}
                                            onBlur={addOriginalCertificateForm.handleBlur}
                                            isValid={addOriginalCertificateForm.isValid}
                                            isTouched={addOriginalCertificateForm.touched.certificateTypeId}
                                            invalidFeedback={addOriginalCertificateForm.errors.certificateTypeId}
                                            list={certificateTypeData.map((data: any) => (
                                                { value: data.certificateTypeId, label: data.certificateName }
                                            ))}
                                        />
                                    </FormGroup>
                                </div>
                                <div className='col-12'>
                                    <FormGroup id="submittedDate" label='Date'>
                                        <Input type="date" placeholder="Enter Date" onChange={addOriginalCertificateForm.handleChange} value={addOriginalCertificateForm.values.submittedDate} onBlur={addOriginalCertificateForm.handleBlur}
                                            isValid={addOriginalCertificateForm.isValid}
                                            isTouched={addOriginalCertificateForm.touched.submittedDate}
                                            invalidFeedback={addOriginalCertificateForm.errors.submittedDate} />
                                    </FormGroup>
                                </div>
                                <div className='col-12'>
                                    <FormGroup id="status" label="Status">
                                        <Select ariaLabel="Select Status" placeholder="Select Status" onChange={addOriginalCertificateForm.handleChange} value={addOriginalCertificateForm.values.status} onBlur={addOriginalCertificateForm.handleBlur}
                                            isValid={addOriginalCertificateForm.isValid}
                                            isTouched={addOriginalCertificateForm.touched.status}
                                            invalidFeedback={addOriginalCertificateForm.errors.status} disabled>
                                            <option value='IN'>IN</option>
                                            <option value='DUE'>DUE</option>
                                        </Select>
                                    </FormGroup>
                                </div>
                            </div>
                        </OffCanvasBody>
                        <div className='row m-0'>
                            <div className='col-12 p-3'>
                                <Button color='info' icon="Save" className='w-100' type="submit" isDisable={checkArray.length == 0 && !addOriginalCertificateForm.isValid && !!addOriginalCertificateForm.submitCount}>
                                    Save
                                </Button>
                            </div>
                        </div>
                    </OffCanvas>

                    <OffCanvas setOpen={setAddTrackingDetailsOffCanvas} isOpen={addTrackingDetailsOffCanvas} titleId='addTrackingDetailsOffCanvas' isBodyScroll tag="form" noValidate onSubmit={addCertificateTrackerForm.handleSubmit}>
                        <OffCanvasHeader setOpen={setAddTrackingDetailsOffCanvas} onClick={closeAndReset}>
                            <OffCanvasTitle id='addTrackingDetailsOffCanvas'>Add Tracking Details</OffCanvasTitle>
                        </OffCanvasHeader>
                        <OffCanvasBody>
                            <div className="row g-4">
                                {studentProfileData.map((student: any) => (
                                    <div className='col-12' key={student.studentDetailsId}>
                                        <Card className={`bg-l${darkModeStatus ? 'o25' : '25'
                                            }-info bg-l${darkModeStatus ? 'o50' : '10'
                                            }-info-hover transition-base rounded-2 mb-4`}
                                            shadow='sm'>
                                            <CardBody>
                                                <div className='d-flex align-items-center pb-3'>
                                                    <div className='flex-shrink-0 ms-2'>
                                                        <img style={{ borderRadius: '50%' }} src={student.profilePath != null ? student.profilePath : profilePic(student.genderId)} height={60} width={60} onError={(e: any) => pictNotLoading(e, student.genderId)} />
                                                    </div>
                                                    <div className='flex-grow-1 ms-3'>
                                                        <div className='fw-bold fs-4 text-dark mb-0'>
                                                            {student.studentName}
                                                        </div>
                                                        <div className='text-muted'>
                                                            ( {student.admissionNo} )
                                                        </div>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </div>
                                ))}
                                <div className='col-12'>
                                    <FormGroup id='originalCertificateId' label="Original Certificate Type" >
                                        <SearchableSelect multiple
                                            ariaLabel='Original Certificate Type'
                                            placeholder="Select OriginalCertificate Type"
                                            onChange={(e: any) => selectCertificateTracker(e)}
                                            value={certificateTrackerCheckArray1}
                                            onBlur={addCertificateTrackerForm.handleBlur}
                                            isValid={addCertificateTrackerForm.isValid}
                                            isTouched={addCertificateTrackerForm.touched.originalCertificateId}
                                            invalidFeedback={addCertificateTrackerForm.errors.originalCertificateId}
                                            list={certificateTrackerDetailsData.map((data: any) => (
                                                { value: data.originalCertificateId, label: data.certificateName }
                                            ))}
                                        />
                                    </FormGroup>
                                </div>
                                <div className='col-12'>
                                    <FormGroup id="dueDate" label='Date Of Issue'>
                                        <Input type="date" placeholder="Enter Date" onChange={addCertificateTrackerForm.handleChange} value={addCertificateTrackerForm.values.dueDate}
                                            onBlur={addCertificateTrackerForm.handleBlur}
                                            isValid={addCertificateTrackerForm.isValid}
                                            isTouched={addCertificateTrackerForm.touched.dueDate}
                                            invalidFeedback={addCertificateTrackerForm.errors.dueDate} />
                                    </FormGroup>
                                </div>
                                <div className='col-12'>
                                    <FormGroup id="returnDate" label='Return Date'>
                                        <Input type="date" placeholder="Enter Date" onChange={addCertificateTrackerForm.handleChange} value={addCertificateTrackerForm.values.returnDate} />
                                    </FormGroup>
                                </div>
                                <div className='col-12'>
                                    <FormGroup id="status" label="Status">
                                        <Select ariaLabel="Select Status" placeholder="Select Status" onChange={addCertificateTrackerForm.handleChange} value={addCertificateTrackerForm.values.status} onBlur={addCertificateTrackerForm.handleBlur}
                                            isValid={addCertificateTrackerForm.isValid}
                                            isTouched={addCertificateTrackerForm.touched.status}
                                            invalidFeedback={addCertificateTrackerForm.errors.status} disabled>
                                            <option value='IN'>IN</option>
                                            <option value='DUE'>DUE</option>
                                        </Select>
                                    </FormGroup>
                                </div>
                                <div className='col-12'>
                                    <FormGroup id="remarks" label="Remarks">
                                        <Textarea placeholder="Enter Remarks" onChange={addCertificateTrackerForm.handleChange} value={addCertificateTrackerForm.values.remarks} />
                                    </FormGroup>
                                </div>
                            </div>
                        </OffCanvasBody>
                        <div className='row m-0'>
                            <div className='col-12 p-3'>
                                <Button color='info' icon="Save" className='w-100' type="submit" isDisable={certificateTrackerCheckArray.length == 0 && !addCertificateTrackerForm.isValid && !!addCertificateTrackerForm.submitCount}>
                                    Save
                                </Button>
                            </div>
                        </div>
                    </OffCanvas>

                    <OffCanvas setOpen={setEditCertificateTrackerOffCanvas} isOpen={editCertificateTrackerOffCanvas} titleId='editCertificateTrackerOffCanvas' isBodyScroll tag="form" noValidate onSubmit={editCertificateTrackerForm.handleSubmit}>
                        <OffCanvasHeader setOpen={setEditCertificateTrackerOffCanvas} onClick={closeAndReset}>
                            <OffCanvasTitle id='editCertificateTrackerOffCanvas'>Edit Tracking Details</OffCanvasTitle>
                        </OffCanvasHeader>
                        <OffCanvasBody>
                            <div className="row g-4">
                                {studentProfileData.map((student: any) => (
                                    <div className='col-12' key={student.studentDetailsId}>
                                        <Card className={`bg-l${darkModeStatus ? 'o25' : '25'
                                            }-info bg-l${darkModeStatus ? 'o50' : '10'
                                            }-info-hover transition-base rounded-2 mb-4`}
                                            shadow='sm'>
                                            <CardBody>
                                                <div className='d-flex align-items-center pb-3'>
                                                    <div className='flex-shrink-0 ms-2'>
                                                        <img style={{ borderRadius: '50%' }} src={student.profilePath != null ? student.profilePath : profilePic(student.genderId)} height={60} width={60} onError={(e: any) => pictNotLoading(e, student.genderId)} />
                                                    </div>
                                                    <div className='flex-grow-1 ms-3'>
                                                        <div className='fw-bold fs-4 text-dark mb-0'>
                                                            {student.studentName}
                                                        </div>
                                                        <div className='text-muted'>
                                                            ( {student.admissionNo} )
                                                        </div>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </div>
                                ))}
                                <div className='col-12'>
                                    <FormGroup id='originalCertificateId' label="Original Certificate Type" >
                                        <SearchableSelect
                                            ariaLabel='Original Certificate Type'
                                            placeholder="Select OriginalCertificate Type"
                                            onChange={(e: any) => setStudentOrStaffOriginalCertificateId(e)}
                                            value={studentOrStaffOriginalCertificateId}
                                            onBlur={editCertificateTrackerForm.handleBlur}
                                            isValid={editCertificateTrackerForm.isValid}
                                            isTouched={editCertificateTrackerForm.touched.originalCertificateId}
                                            invalidFeedback={editCertificateTrackerForm.errors.originalCertificateId}
                                            list={certificateTrackerDetailsData.map((data: any) => (
                                                { value: data.originalCertificateId, label: data.certificateName }
                                            ))}
                                        />
                                    </FormGroup>
                                </div>
                                <div className='col-12'>
                                    <FormGroup id="dueDate" label='Date Of Issue'>
                                        <Input type="date" placeholder="Enter Date" onChange={editCertificateTrackerForm.handleChange} value={editCertificateTrackerForm.values.dueDate}
                                            onBlur={editCertificateTrackerForm.handleBlur}
                                            isValid={editCertificateTrackerForm.isValid}
                                            isTouched={editCertificateTrackerForm.touched.dueDate}
                                            invalidFeedback={editCertificateTrackerForm.errors.dueDate} />
                                    </FormGroup>
                                </div>
                                <div className='col-12'>
                                    <FormGroup id="returnDate" label='Return Date'>
                                        <Input type="date" placeholder="Enter Date" onChange={editCertificateTrackerForm.handleChange} value={editCertificateTrackerForm.values.returnDate}
                                            onBlur={editCertificateTrackerForm.handleBlur}
                                            isValid={editCertificateTrackerForm.isValid}
                                            isTouched={editCertificateTrackerForm.touched.returnDate}
                                            invalidFeedback={editCertificateTrackerForm.errors.returnDate} />
                                    </FormGroup>
                                </div>
                                <div className='col-12'>
                                    <FormGroup id="actualReturnDate" label='Actual Return Date'>
                                        <Input type="date" placeholder="Enter Date" onChange={editCertificateTrackerForm.handleChange} value={editCertificateTrackerForm.values.actualReturnDate}
                                            onBlur={editCertificateTrackerForm.handleBlur}
                                            isValid={editCertificateTrackerForm.isValid}
                                            isTouched={editCertificateTrackerForm.touched.actualReturnDate}
                                            invalidFeedback={editCertificateTrackerForm.errors.actualReturnDate} />
                                    </FormGroup>
                                </div>
                                <div className='col-12'>
                                    <FormGroup id="status" label="Status">
                                        <Select ariaLabel="Select Status" placeholder="Select Status" onChange={editCertificateTrackerForm.handleChange} value={editCertificateTrackerForm.values.status} onBlur={editCertificateTrackerForm.handleBlur}
                                            isValid={editCertificateTrackerForm.isValid}
                                            isTouched={editCertificateTrackerForm.touched.status}
                                            invalidFeedback={editCertificateTrackerForm.errors.status}>
                                            <option value='IN'>IN</option>
                                            <option value='DUE'>DUE</option>
                                        </Select>
                                    </FormGroup>
                                </div>
                                <div className='col-12'>
                                    <FormGroup id="remarks" label="Remarks">
                                        <Textarea placeholder="Enter Remarks" onChange={editCertificateTrackerForm.handleChange} value={editCertificateTrackerForm.values.remarks} />
                                    </FormGroup>
                                </div>
                            </div>
                        </OffCanvasBody>
                        <div className='row m-0'>
                            <div className='col-12 p-3'>
                                <Button color='info' icon="Save" className='w-100' type="submit" isDisable={!editCertificateTrackerForm.isValid && !!editCertificateTrackerForm.submitCount}>
                                    Save
                                </Button>
                            </div>
                        </div>
                    </OffCanvas>

                    <OffCanvas setOpen={setDeleteOffCanvas} isOpen={deleteOffCanvas} titleId='deleteOffCanvas' isBodyScroll
                        tag="form" noValidate onSubmit={deleteCertificateTrackerForm.handleSubmit}>
                        <OffCanvasHeader setOpen={setDeleteOffCanvas} onClick={closeAndReset}>
                            <OffCanvasTitle id='deleteOffCanvas'>Certificate Details</OffCanvasTitle>
                        </OffCanvasHeader>
                        <OffCanvasBody>
                            <div className="row g-4">
                                <div className="col-12">
                                    <FormGroup id="certificateName" label='Certificate Name'>
                                        <Input type="text" onChange={deleteCertificateTrackerForm.handleChange} value={deleteCertificateTrackerForm.values.certificateName != "" ? deleteCertificateTrackerForm.values.certificateName : ""} disabled />
                                    </FormGroup>
                                </div>

                                <div className="col-12">
                                    <FormGroup id="status" label='Status'>
                                        <Input type="text" onChange={deleteCertificateTrackerForm.handleChange} value={deleteCertificateTrackerForm.values.status != "" ? deleteCertificateTrackerForm.values.status : ""} disabled />
                                    </FormGroup>
                                </div>

                                <div className="col-12">
                                    <FormGroup id="submittedDate" label='Submitted Date'>
                                        <Input type="text" onChange={deleteCertificateTrackerForm.handleChange}
                                            value={deleteCertificateTrackerForm.values.submittedDate != "" ? deleteCertificateTrackerForm.values.submittedDate : ""} disabled />
                                    </FormGroup>
                                </div>

                                <div className="col-12">
                                    <FormGroup id="dueDate" label='Date Of Issue'>
                                        <Input type="text" onChange={deleteCertificateTrackerForm.handleChange}
                                            value={deleteCertificateTrackerForm.values.dueDate != "" ? deleteCertificateTrackerForm.values.dueDate : ""} disabled />
                                    </FormGroup>
                                </div>

                                <div className="col-12">
                                    <FormGroup id="returnDate" label='Return Date'>
                                        <Input type="text" onChange={deleteCertificateTrackerForm.handleChange} value={deleteCertificateTrackerForm.values.returnDate != "" ? deleteCertificateTrackerForm.values.returnDate : ""} disabled />
                                    </FormGroup>
                                </div>

                                <div className="col-12">
                                    <FormGroup id="remarks" label='Remarks'>
                                        <Textarea onChange={deleteCertificateTrackerForm.handleChange} value={deleteCertificateTrackerForm.values.remarks != "" ? deleteCertificateTrackerForm.values.remarks : ""} disabled />
                                    </FormGroup>
                                </div>
                                <div className="d-flex justify-content-center position-absolute top-65 start-50 translate-middle">
                                    <DeleteComponents isDeleteOpen={isDeleteOpen} setIsDeleteOpen={setIsDeleteOpen} deleteFunction={deleteCertificateTrackerSubmit} />
                                </div>
                            </div>
                        </OffCanvasBody>
                        <div className='row m-0'>
                            <div className='col-12 p-3'>
                                <Button color='danger' className='w-100' icon="Delete" onClick={() => setIsDeleteOpen(!isDeleteOpen)}>
                                    Delete
                                </Button>
                            </div>
                        </div>
                    </OffCanvas>
                </Page>
                <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
            </PageWrapper>
        </>
    );
}

export default StudentCertificateTracker;