import { AxiosResponse } from "axios";
import service from "../api/api.services";
import { getAPIURL, getLicenseKey } from "./application.settings";

const api = service()

export const getComplaintType = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,
) => api.get(getAPIURL() + `getComplaintType/${getLicenseKey}`,
	(response) => {
		success(response);
	},
	(error) => {
		failure('getComplaintType - ' + error.message);
		console.log('error caught in service : getComplaintType');
	},
);

export const addComplaintDetails = (complaintData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

	api.post(getAPIURL() + `addComplaintDetails`, complaintData,
		response => {
			success(response)
		},
		error => {
			failure(error.message)
			console.log('error caught in service : addComplaintDetails')
		}
	);

export const uploadGalleryPic = (galleryData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

	api.post(getAPIURL() + `uploadGalleryPic`, galleryData,
		response => {
			success(response)
		},
		error => {
			failure(error.message)
			console.log('error caught in service : uploadGalleryPic')
		}
	);

export const getGalleryType = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>
	api.get(getAPIURL() + `getGalleryType/${getLicenseKey}`,
		(response) => {
			success(response);
		},
		(error) => {
			failure('getGalleryType - ' + error.message);
			console.log('error caught in service : getGalleryType');
		},
	);

export const addComplaintType = (complaintTypePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

	api.post(getAPIURL() + `addComplaintType`, complaintTypePostData,
		response => {
			success(response)
		},
		error => {
			failure(error.message)
			console.log('error caught in service : addComplaintType')
		}
	);

export const updateComplaintType = (updateComplaintPost: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

	api.post(getAPIURL() + `updateComplaintType`, updateComplaintPost,
		response => {
			success(response)
		},
		error => {
			failure(error.message)
			console.log('error caught in service : updateComplaintType')
		}
	);

export const deleteComplaintType = (updateComplaintPost: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

	api.post(getAPIURL() + `deleteComplaintType`, updateComplaintPost,
		response => {
			success(response)
		},
		error => {
			failure(error.message)
			console.log('error caught in service : deleteComplaintType')
		}
	);

export const getGalleryImages = (galleryTypeId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>
	api.get(getAPIURL() + `getGalleryImages/${getLicenseKey}/${galleryTypeId}`,
		(response) => {
			success(response);
		},
		(error) => {
			failure('getGalleryImages - ' + error.message);
			console.log('error caught in service : getGalleryImages');
		},
	);