import { useFormik } from "formik";
import { useEffect, useState, useRef, useContext } from "react";
import { useReactToPrint } from "react-to-print";
import useDarkMode from "../../../../hooks/useDarkMode";
import useSortableData from "../../../../hooks/useSortableData";
import Page from "../../../../layout/Page/Page";
import { downloadExcel, convertJsonToPdf, downloadFile } from "../../../../services/ExportService";
import { toasts } from "../../../../services/toast.service";
import PaginationButtons, { dataPagination } from "../../../PaginationButtons";
import Button, { ButtonGroup } from "../../../bootstrap/Button";
import Card, { CardHeader, CardLabel, CardTitle, CardActions, CardBody } from "../../../bootstrap/Card";
import Dropdown, { DropdownToggle, DropdownMenu, DropdownItem } from "../../../bootstrap/Dropdown";
import Checks, { ChecksGroup } from "../../../bootstrap/forms/Checks";
import FormGroup from "../../../bootstrap/forms/FormGroup";
import Icon from "../../../icon/Icon";
import Input from "../../../bootstrap/forms/Input";
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from "../../../bootstrap/OffCanvas";
import Select from "../../../bootstrap/forms/Select";
import Collapse from "../../../bootstrap/Collapse";
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";
import DeleteComponents from "../../../../common/components/DeleteComponents";
import AlertService from "../../../../services/AlertService";
import OpenCardComponent from "../../../../common/components/OpenCardComponent";
import { getColumnsForDataTable, getMedium, getStandardDetails, onlyAllowNumber, profilePic, updateColumnsForDataTable, updateFilter } from "../../../../services/common.service";
import { addStaffSubjectAllocationDetails, deleteStaffSubjectAllocationDetails, getSubjectPaperDetails, getUnit, updateStaffSubjectAllocationDetails } from "../../../../services/subject.service";
import { getLicenseKey } from "../../../../services/application.settings";
import AuthContext from "../../../../contexts/authContext";
import { addAssignmentDetails, getStaffListByUserTypeId } from "../../../../services/staff.service";
import { showLoader } from "../../../../services/loader.services";
import NoDataMsg from "../../../../common/components/NoDataMsg";
import SearchableSelect from "../../../../common/components/SearchableSelect";
import { getAssignmentDetails, getBatchMasterDetails, getSectionDetails } from "../../../../services/master.service";
import { getSubjectListByStandardDetailsId } from "../../../../services/exam.service";
import Textarea from "../../../bootstrap/forms/Textarea";
import { getStudentDetailsBySectionDetailsId } from "../../../../services/student.service";
import classNames from "classnames";
import Avatar from "../../../Avatar";
import Modal, { ModalHeader, ModalTitle, ModalBody } from "../../../bootstrap/Modal";

function AssignmentCreation() {

    useEffect(() => {
        getBatchList()
    }, [])

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const [mediumId, setMediumId] = useState<any>([])
    const [standardDetailsId, setStandardDetailsId] = useState<any>([])
    const [sectionDetailsId, setSectionDetailsId] = useState<any>([])
    const [mediumData, setMediumData] = useState<any>([])
    const [standardData, setStandardData] = useState<any>([])
    const [sectionDetailsData, setSectionDetailsData] = useState<any>([])
    const [studentDetailsId, setStudentDetailsId] = useState<any>('');
    const [columnVisibilityData, setColumnVisibilityData] = useState([]);
    const [allColumnsData, setAllColumnsData] = useState([]);
    const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
    const [columnDataById, setColumnDataById] = useState([])
    const componentRef = useRef(null);
    const [isChecked, setIsChecked] = useState(false)
    const [assignmentDetailsData, setAssignmentDetailsData] = useState<any>([])
    const [staffDetailsData, setStaffDetailsData] = useState<any>([])
    const [staffDetailsId, setStaffDetailsId] = useState<any>('')
    const [subjectPaperDetailsId, setSubjectPaperDetailsId] = useState<any>('')
    const [dataSuccess, setDataSuccess] = useState(false)
    const [dataStatus, setDataStatus] = useState(false)
    const [isLoader, setIsLoader] = useState(false)
    const [selectedOption, setSelectedOption] = useState<any>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(assignmentDetailsData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);
    const [isOpenListCard, setIsOpenListCard] = useState(true)
    const [addAssignmentDetailsOffCanvas, setAssignmentDetailsOffCanvas] = useState(false)
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [allStudentData, setAllStudentData] = useState<any>([])
    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);
    const [noDataMsg, setNoDataMsg] = useState('')
    const [batchMasterData, setBatchMasterData] = useState<any>([])
    const [batchMasterId, setBatchMasterId] = useState<any>('')
    const [subjectData, setSubjectData] = useState<any>([]);
    const { themeStatus, darkModeStatus } = useDarkMode();
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [studentDetails, setStudentDetails] = useState<any>([]);

    const assignmentTypeForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            academicPeriodId: '',
            batchMasterId: '',
            mediumId: '',
            standardDetailsId: '',
            sectionDetailsId: '',
            title: '',
            subjectPaperDetailsId: '',
            studentDetailsId: '',
            dueDate: '',
        },
        validate: (values) => {
            const errors: {
                academicPeriodId?: string;
                batchMasterId?: string;
                mediumId?: string;
                standardDetailsId?: string;
                sectionDetailsId?: string;
                title?: string;
                subjectPaperDetailsId?: string;
                studentDetailsId?: string;
                dueDate?: string;
            } = {};
            if (!batchMasterId?.value) {
                errors.batchMasterId = 'Required';
            }
            if (!mediumId?.value) {
                errors.mediumId = 'Required';
            }
            if (!standardDetailsId?.value) {
                errors.standardDetailsId = 'Required';
            }
            if (!sectionDetailsId?.value) {
                errors.sectionDetailsId = 'Required';
            }
            if (!studentDetailsId) {
                errors.studentDetailsId = 'Required';
            }
            if (!values.title) {
                errors.title = 'Required';
            }
            if (!values.dueDate) {
                errors.dueDate = 'Required';
            }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: () => { addAssignmentSubmit() },
    });

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },

        //validateOnChange: false,
        onSubmit: () => { },
    });

    // Filter
    const filteredData = updateFilter(onCurrentPageData, columnVisibilityData, columnVisibilityForm);

    function onActivate(item: any) {
        if (item != undefined) {
            setIsModalOpen(true)

            for (let i = 0; i < item.assignmentStatus.length; i++) {
                item.assignmentStatus[i].sno = i + 1;
            }
            setStudentDetails(item.assignmentStatus)
        }
    }

    const selectBatch = (e: any) => {
        setSubjectPaperDetailsId('')
        setStandardDetailsId('')
        setSectionDetailsId('')
        setMediumId('')
        let batchMasterId = e
        setBatchMasterId(batchMasterId)
        if (batchMasterId?.value != undefined) {
            getMediumList()
        }
    }

    const selectMedium = (e: any) => {
        setSubjectPaperDetailsId('')
        setStandardDetailsId('')
        setSectionDetailsId('')
        let mediumId = e
        setMediumId(mediumId)
        if (mediumId?.value != undefined) {
            getStandardList()
        }
    }

    const selectStandard = (e: any) => {
        setSubjectPaperDetailsId('')
        setSectionDetailsId('')
        let standardDetailsId = e
        setStandardDetailsId(standardDetailsId)
        if (standardDetailsId?.value != undefined) {
            getSection(mediumId?.value, standardDetailsId?.value)
            getSubjectPaperList(batchMasterId?.value, standardDetailsId?.value);
        }
    }

    const selectSection = (e: any) => {
        setSubjectPaperDetailsId('')
        let sectionDetailsId = e
        setSectionDetailsId(sectionDetailsId)
        if (batchMasterId?.value != undefined && sectionDetailsId?.value != undefined) {
            getStudentListBySectionId(batchMasterId?.value, sectionDetailsId?.value);
        }
    }

    const selectStaff = (e: any) => {
        let staffDetailsId = e
        setStaffDetailsId(staffDetailsId)
    }

    function selectedStudent(e: any) {
        let studentDetailsId = e;
        setSelectedOption(studentDetailsId.value)
        const studentArrayId = studentDetailsId.map((e: any) => e.value);
        setStudentDetailsId(studentArrayId)
    }

    const selectSubject = (e: any) => {
        let subjectPaperDetailsId = e;
        setSubjectPaperDetailsId(subjectPaperDetailsId);
        if (subjectPaperDetailsId?.value != undefined) {
            // getTimeDurationList()
        }
    };

    function viewAssignmentList() {
        getStaffListByUserType(6);
        setDataSuccess(false)
        setIsLoader(true);
        setAssignmentDetailsData([]);
        columnVisibilityForm.resetForm()
        getAssignmentDetailsId(batchMasterId?.value, standardDetailsId?.value, sectionDetailsId?.value, subjectPaperDetailsId?.value)
    }

    function getMediumList() {
        getMedium(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.medium;
                    if (data != undefined) {
                        setMediumData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setMediumData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStandardList() {
        getStandardDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.standardDetails;
                    if (data != undefined) {
                        setStandardData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setStandardData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getSection(mediumId: any, standardDetailsId: any) {
        getSectionDetails(mediumId, standardDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.sectionDetails;
                    if (data != undefined) {
                        setSectionDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setSectionDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getSubjectPaperList(batchMasterId: any, standardDetailsId: any) {
        getSubjectListByStandardDetailsId(
            batchMasterId,
            standardDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.subjectListByStandardDetailsId;
                    if (data != undefined) {
                        setSubjectData(data);
                    } else {
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setSubjectData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, 'Error');
                }
            },
            (error) => {
                toasts(error, 'Error');
            },
        );
    }

    function getAssignmentDetailsId(batchMasterId: any, standardDetailsId: any, sectionDetailsId: any, subjectPaperDetailsId: any,) {
        getAssignmentDetails(batchMasterId, standardDetailsId, sectionDetailsId, subjectPaperDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.assignmentDetails;
                    if (data != undefined) {
                        setIsLoader(false);
                        getColumnsForTable('getAssignmentDetails', 'get');
                        setIsOpenListCard(false);
                        setDataSuccess(true);
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                        setAssignmentDetailsData(data);
                        //setDataStatus(true)
                    } else {
                        setIsLoader(false);
                        // toasts("Undefined Data", "Error")
                        setAlertStatus({ message: "Undefined Data", type: "error" });
                        setIsOpen(true);
                    }
                }
                else if (response.data.success === false) {
                    setIsLoader(false);
                    setIsOpenListCard(false);
                    getColumnsForTable('getAssignmentDetails', 'get');
                    setAssignmentDetailsData([]);
                    setNoDataMsg(response.data.message)
                    setDataSuccess(true);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setIsLoader(false);
                    // toasts(errorDescription, "Error")
                    setAlertStatus({ message: errorDescription, type: "error" });
                    setIsOpen(true);
                }
            }, error => {
                setIsLoader(false);
                // toasts(error, "Error")
                setAlertStatus({ message: error, type: "error" });
                setIsOpen(true);
            }
        )
    }

    function getStaffListByUserType(userTypeId: any) {
        getStaffListByUserTypeId(userAccountId, userTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.staffListByUserTypeId;
                    if (data != undefined) {
                        setStaffDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setStaffDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getBatchList() {
        getBatchMasterDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.batchMasterDetails;
                    if (data != undefined) {
                        setBatchMasterData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                    setBatchMasterData([])
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function setAssignmentDetails() {
        return {
            batchMasterId: batchMasterId?.value,
            standardDetailsId: standardDetailsId?.value,
            sectionDetailsId: sectionDetailsId?.value,
            subjectPaperDetailsId: subjectPaperDetailsId?.value,
            studentDetailsId: studentDetailsId,
            title: assignmentTypeForm.values.title,
            staffDetailsId: userAccountId,
            licenseKey: getLicenseKey,
            dueDate: assignmentTypeForm.values.dueDate,
        };
    }


    function getStudentListBySectionId(batchMasterId: any, sectionDetailsId: any) {
        getStudentDetailsBySectionDetailsId(batchMasterId, sectionDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentDetailsBySectionDetailsId;
                    if (data != undefined) {
                        setAllStudentData(data);
                    } else {
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    toasts(response.data.message, 'Error');
                    setAllStudentData([])
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, 'Error');
                }
            },
            (error) => {
                toasts(error, 'Error');
            },
        );
    }

    function addAssignmentSubmit() {
        showLoader(true);
        if (assignmentTypeForm.isValid) {
            let addStaffPostData = setAssignmentDetails();
            addAssignmentDetails(
                addStaffPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: 'success' });
                        setIsOpen(true);
                        setIsOpenListCard(true)
                        closeAndReset();
                        getAssignmentDetailsId(batchMasterId?.value, standardDetailsId?.value, sectionDetailsId?.value, subjectPaperDetailsId?.value)
                    } else if (data.success == false) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: 'error' });
                        setIsOpen(true);
                    } else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false);
                        setAlertStatus({ message: errorDescription, type: 'error' });
                        setIsOpen(true);
                    }
                },
                (error) => {
                    showLoader(false);
                    setAlertStatus({ message: error, type: 'error' });
                    setIsOpen(true);
                },
            );
        } else if (assignmentTypeForm.isValid) {
            showLoader(false);
            setAlertStatus({ message: 'Please fill all the details!', type: 'error' });
            setIsOpen(true);
        }
    }


    function getColumnsForTable(apiName: any, type: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setColumnVisibilityData(columnVisibilityData)

                        const allData = allColumnsData.map((item: any) => item.isDisplay);
                        const allColumns = allData.filter((item: any) => item == false);

                        if (type == 'get') {
                            setIsChecked(allColumns == '' ? true : false);
                        }
                        else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

                            for (let i = 0; i < nonDefaultValue.length; i++) {

                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: allColumns == '' ? 0 : 1
                                }
                                all.push(obj);
                            }
                            updateColumnsSubmit('', '', all)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )

    }

    function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {

        if (columnVisibilityForm.isValid) {

            setColumnDataById(isDisplay)
            const columnVisibilityPostData = {
                licenseKey: getLicenseKey,
                userAccountId: userAccountId,
                columns: type ? type : [
                    {
                        columnVisibilityId: columnVisibilityId,
                        isDisplay: isDisplay ? 0 : 1
                    }
                ]
            }

            updateColumnsForDataTable(columnVisibilityPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        getColumnsForTable('getAssignmentDetails', 'get');
                    }
                    else if (data.success == false) {
                        // toasts(data.success, "Error")
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        toasts(errorDescription, "Error")
                    }
                }
                , (error) => {
                    toasts(error, "Error")
                }
            )
        } else if (columnVisibilityForm.isValid == false) {
            toasts("Please fill all the details!", "Error")
        }
    }

    function closeAndReset() {
        setIsDeleteOpen(false);
        setAssignmentDetailsOffCanvas(false);
        setStaffDetailsId('')
        assignmentTypeForm.resetForm();
    }

    // Excel Configuration
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <PageWrapper title="Assignment Creation">
            <Page container='fluid'>
                <Collapse isOpen={isOpenListCard}>
                    <Card stretch data-tour='list'>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info'>
                                <CardTitle tag='div' className='h5'>
                                    Assignment Creation
                                </CardTitle>
                            </CardLabel>
                        </CardHeader>
                        <CardBody>
                            <div className="row g-4">
                                <div className='col-md-3'>
                                    <FormGroup id='batchMasterId' label='Batch' isFloating>
                                        <SearchableSelect isFloating
                                            ariaLabel='Batch'
                                            onChange={selectBatch}
                                            value={batchMasterId}
                                            list={batchMasterData.map((data: any) => (
                                                { value: data.batchMasterId, label: data.batchName }
                                            ))}
                                        />
                                    </FormGroup>
                                </div>
                                {batchMasterId?.value != undefined ?
                                    <div className='col-md-3'>
                                        <FormGroup id='mediumId' label='Medium' isFloating>
                                            <SearchableSelect isFloating
                                                ariaLabel='Medium'
                                                onChange={selectMedium}
                                                value={mediumId}
                                                list={mediumData.map((data: any) => (
                                                    { value: data.mediumId, label: data.medium }
                                                ))}
                                            />
                                        </FormGroup>
                                    </div> : null
                                }
                                {mediumId?.value != undefined ? <div className='col-md-3'>
                                    <FormGroup id='standardDetailsId' label='Standard' isFloating>
                                        <SearchableSelect isFloating
                                            ariaLabel='Standard'
                                            onChange={selectStandard}
                                            value={standardDetailsId}
                                            list={standardData.map((data: any) => (
                                                { value: data.standardDetailsId, label: data.standardName }
                                            ))}
                                        />
                                    </FormGroup>
                                </div> : null}
                                {standardDetailsId?.value != undefined ? <div className='col-md-3'>
                                    <FormGroup id='sectionDetailsId' label='Section' isFloating>
                                        <SearchableSelect isFloating
                                            ariaLabel='Section'
                                            onChange={selectSection}
                                            value={sectionDetailsId}
                                            list={sectionDetailsData.map((data: any) => (
                                                { value: data.sectionDetailsId, label: data.sectionName }
                                            ))}
                                        />
                                    </FormGroup>
                                </div> : null}
                                {sectionDetailsId?.value != undefined ?
                                    <div className='col-md-3 mt-3'>
                                        <FormGroup id='subjectPaperDetailsId' label='Subject' isFloating>
                                            <SearchableSelect
                                                isFloating
                                                ariaLabel='Subject'
                                                onChange={selectSubject}
                                                value={subjectPaperDetailsId}
                                                onBlur={assignmentTypeForm.handleBlur}
                                                isValid={assignmentTypeForm.isValid}
                                                isTouched={assignmentTypeForm.touched.subjectPaperDetailsId}
                                                invalidFeedback={assignmentTypeForm.errors.subjectPaperDetailsId}
                                                list={subjectData.map((data: any) => ({
                                                    value: data.subjectPaperDetailsId, label: data.paperName
                                                }))}
                                            />
                                        </FormGroup>
                                    </div>
                                    : null}
                                {subjectPaperDetailsId?.value != undefined ? <div className='col-md-3'>
                                    <Button className="mt-1" icon='ArrowDownward' color='primary' onClick={viewAssignmentList}>View</Button>
                                </div> : null}
                            </div>
                        </CardBody>
                    </Card>
                </Collapse>

                <OpenCardComponent isOpenListCard={isOpenListCard} setIsOpenListCard={setIsOpenListCard} isLoader={isLoader} />

                {dataSuccess ? <>
                    <Card stretch data-tour='list' ref={componentRef}>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info' className="col-lg-5">
                                <CardTitle tag='div' className='h5'>Assignment Creation</CardTitle>
                            </CardLabel>&nbsp;&nbsp;
                            <ButtonGroup className="col-lg-3 d-print-none" color='primary'>
                                <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                                <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
                            </ButtonGroup>

                            <CardActions className="d-print-none">
                                <Dropdown isOpen={columnVisibilityMenu} setIsOpen={setColumnVisibilityMenu} isButtonGroup>
                                    <DropdownToggle>
                                        <Button icon='FilterAlt' color='primary' isLight>Filter</Button>
                                    </DropdownToggle>
                                    <DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
                                        <DropdownItem>
                                            <div className='container py-2'>
                                                <form className='row g-3'>
                                                    <div className='col-12'>
                                                        <FormGroup>
                                                            <h6>Select All</h6>
                                                            <Checks id='available' type='switch' label='Select All Columns' onChange={() => getColumnsForTable('getAssignmentDetails', 'post')} checked={isChecked} ariaLabel='Available status' />
                                                        </FormGroup>
                                                    </div>
                                                    <div className='col-12' style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                                                        <FormGroup>
                                                            <h6>Columns</h6>
                                                            <ChecksGroup>
                                                                {allColumnsData.map((i: any) => (
                                                                    <Checks key={i.columnVisibilityId} id='{i.columnVisibilityId}' label={i.columnName} onChange={() => updateColumnsSubmit(i.columnVisibilityId, i.isDisplay, '')} checked={i.isDisplay} disabled={i.isDefault == true} />
                                                                ))}
                                                            </ChecksGroup>
                                                        </FormGroup>
                                                    </div>
                                                </form>
                                            </div>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                <Dropdown isButtonGroup>
                                    <DropdownToggle>
                                        <Button color='primary' isLight icon='CloudDownload'> Export </Button>
                                    </DropdownToggle>
                                    <DropdownMenu isAlignmentEnd>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel("AssignmentList", assignmentDetailsData, columnVisibilityData)}> Excel </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(assignmentDetailsData, columnVisibilityData, "AssignmentList")}> PDF
                                            </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile("AssignmentList", assignmentDetailsData, columnVisibilityData)}> CSV </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='Print' onClick={handlePrint}> Print </Button>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                <Button color='primary' icon='Add' isLight onClick={() => { setAssignmentDetailsOffCanvas(true); }}>
                                    Add New
                                </Button>
                            </CardActions>
                        </CardHeader>
                        <CardBody className='table-responsive' isScrollable>
                            <table className='table table-modern table-hover text-nowrap'>
                                <thead>
                                    <tr>
                                        <th scope='col' onClick={() => requestSort('sno')}
                                            className='cursor-pointer text-decoration-underline'>S.No{' '}
                                            <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                        </th>
                                        {columnVisibilityData.map((column: any) => (
                                            <th key={column.keyName} scope='col' onClick={() => requestSort(column.keyName)}
                                                className='cursor-pointer text-decoration-underline'>
                                                {column.columnName}
                                                <Icon size='lg' className={getClassNamesFor(column.keyName)} icon='FilterList' />
                                            </th>
                                        ))}
                                        <th scope='col' className='d-print-none'>
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredData != "" ?
                                        <>
                                            {filteredData.map((rowData: any) => (
                                                <tr key={rowData.assignmentDetailsId}>
                                                    <td>{rowData.sno}</td>
                                                    {columnVisibilityData.map((column: any) => (
                                                        (column.isDisplay && rowData[column.keyName]) ?
                                                            <td key={`${rowData.assignmentDetailsId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />
                                                    ))}
                                                    <td>
                                                        <Button icon='Person' size={'sm'} isLight color='primary' onClick={() => onActivate(rowData)}>
                                                            View
                                                        </Button>
                                                    </td>
                                                </tr>
                                            ))
                                            }</>
                                        :
                                        <NoDataMsg columnsCount={columnVisibilityData.length + 2} msg={noDataMsg} />}
                                </tbody>
                            </table>
                        </CardBody>
                        <PaginationButtons
                            className="d-print-none"
                            data={items}
                            label='items'
                            setCurrentPage={setCurrentPage}
                            currentPage={currentPage}
                            perPage={perPage}
                            setPerPage={setPerPage}
                        />
                    </Card> </> : null
                }
                <OffCanvas
                    setOpen={setAssignmentDetailsOffCanvas}
                    isOpen={addAssignmentDetailsOffCanvas}
                    titleId='addAssignmentDetailsOffCanvas'
                    isBodyScroll
                    tag="form" noValidate
                    onSubmit={assignmentTypeForm.handleSubmit}
                    isNotClose>
                    <OffCanvasHeader setOpen={setAssignmentDetailsOffCanvas} onClick={() => { closeAndReset; setAssignmentDetailsOffCanvas(false) }}>
                        <OffCanvasTitle id='addAssignmentDetailsOffCanvas'>Add Assignment Creation </OffCanvasTitle>
                    </OffCanvasHeader>
                    <OffCanvasBody>
                        <div className="row g-3">
                            <div className='col-12'>
                                <FormGroup id='title' label='Title' >
                                    <Input
                                        onChange={assignmentTypeForm.handleChange}
                                        value={assignmentTypeForm.values.title}
                                        onBlur={assignmentTypeForm.handleBlur}
                                        isValid={assignmentTypeForm.isValid}
                                        isTouched={assignmentTypeForm.touched.title}
                                        invalidFeedback={assignmentTypeForm.errors.title}
                                        placeholder='Enter Title'
                                    />
                                </FormGroup>
                            </div>
                            <div className='col-12'>
                                <FormGroup id='studentDetailsId' label='Student'  >
                                    <SearchableSelect ariaLabel='Student' onChange={selectedStudent} value={selectedOption} multiple
                                        list={allStudentData.map((data: any) => (
                                            { value: data.studentDetailsId, label: data.studentNameAndAdmissionNo }
                                        ))}
                                        placeholder="Select Student"
                                        isValid={assignmentTypeForm.isValid}
                                        onBlur={assignmentTypeForm.handleBlur}
                                        isTouched={assignmentTypeForm.touched.studentDetailsId}
                                        invalidFeedback={assignmentTypeForm.errors.studentDetailsId}
                                    />
                                </FormGroup>
                            </div>
                            <div className="col-12">
                                <FormGroup id='dueDate' label='Date' >
                                    <Input onChange={assignmentTypeForm.handleChange} value={assignmentTypeForm.values.dueDate}
                                        onBlur={assignmentTypeForm.handleBlur}
                                        isValid={assignmentTypeForm.isValid}
                                        isTouched={assignmentTypeForm.touched.dueDate}
                                        invalidFeedback={assignmentTypeForm.errors.dueDate}
                                        type='date' />
                                </FormGroup>
                            </div>
                        </div>

                    </OffCanvasBody>
                    <div className='row m-0'>
                        <div className='col-12 p-3 position-absolute top-95 start-50 translate-middle'>
                            <Button
                                color='info' icon="Save"
                                className='w-100' type="submit" isDisable={!assignmentTypeForm.isValid && !!assignmentTypeForm.submitCount}>
                                Save
                            </Button>
                        </div>
                    </div>
                </OffCanvas>
                <Modal
                    setIsOpen={setIsModalOpen}
                    isOpen={isModalOpen}
                    titleId='attendanceNotSubmittedDepartments'
                    isStaticBackdrop
                    isScrollable
                    size='lg'>
                    <ModalHeader
                        className="'modal-header '"
                        setIsOpen={() => {
                            setIsModalOpen(false);
                        }}
                        onClick={() => setIsModalOpen(false)}>
                        <ModalTitle id='attendanceNotSubmittedDepartments'>
                            Student List
                        </ModalTitle>
                    </ModalHeader>
                    <ModalBody>
                        <div className='col-lg-5'></div>
                        <div className='col-lg-12'>
                            <table className='table table-modern table-hover '>
                                <thead >
                                    <tr>
                                        <th className={classNames('bg-l25-info fs-6', { 'bg-l10-info': !darkModeStatus, 'bg-lo25-info': darkModeStatus })}>
                                            S.NO
                                        </th>
                                        <th className={classNames('bg-l25-info fs-6', { 'bg-l10-info': !darkModeStatus, 'bg-lo25-info': darkModeStatus })}>
                                            ADMISSION NO
                                        </th>
                                        <th className={classNames('bg-l25-info fs-6', { 'bg-l10-info': !darkModeStatus, 'bg-lo25-info': darkModeStatus })}>
                                            ROLL NO
                                        </th>
                                        <th className={classNames('bg-l25-info fs-6', { 'bg-l10-info': !darkModeStatus, 'bg-lo25-info': darkModeStatus })}>
                                            STUDENT NAME
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {studentDetails != '' ?
                                        <>
                                            {studentDetails.map((i: any, index: any) => (
                                                <tr key={i.studentDetailsId}>
                                                    <td><div className="ms-4">{i.sno}</div></td>
                                                    <td>{i.admissionNo}</td>
                                                    <td>{i.studentRollNo}</td>
                                                    <td> {i.studentName}</td>
                                                </tr>
                                            ))}
                                        </> : <NoDataMsg columnsCount={3} msg={noDataMsg} />}
                                </tbody>
                            </table>
                        </div>
                    </ModalBody>
                </Modal>
            </Page>

            <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />

        </PageWrapper>
    )
} export default AssignmentCreation;
