import { useFormik } from "formik";
import { useEffect, useState, useRef, useContext } from "react";
import { useReactToPrint } from "react-to-print";
import useDarkMode from "../../../../hooks/useDarkMode";
import useSortableData from "../../../../hooks/useSortableData";
import Page from "../../../../layout/Page/Page";
import { downloadExcel, convertJsonToPdf, downloadFile } from "../../../../services/ExportService";
import { toasts } from "../../../../services/toast.service";
import PaginationButtons, { dataPagination } from "../../../PaginationButtons";
import Button, { ButtonGroup } from "../../../bootstrap/Button";
import Card, { CardHeader, CardLabel, CardTitle, CardActions, CardBody } from "../../../bootstrap/Card";
import Dropdown, { DropdownToggle, DropdownMenu, DropdownItem } from "../../../bootstrap/Dropdown";
import Checks, { ChecksGroup } from "../../../bootstrap/forms/Checks";
import FormGroup from "../../../bootstrap/forms/FormGroup";
import Icon from "../../../icon/Icon";
import Input from "../../../bootstrap/forms/Input";
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from "../../../bootstrap/OffCanvas";
import Select from "../../../bootstrap/forms/Select";
import Collapse from "../../../bootstrap/Collapse";
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";
import DeleteComponents from "../../../../common/components/DeleteComponents";
import AlertService from "../../../../services/AlertService";
import OpenCardComponent from "../../../../common/components/OpenCardComponent";
import { getColumnsForDataTable, getMedium, getStandardDetails, onlyAllowNumber, updateColumnsForDataTable, updateFilter } from "../../../../services/common.service";
import { addStaffSubjectAllocationDetails, deleteStaffSubjectAllocationDetails, getStaffSubjectAllocation, getSubjectPaperDetails, getUnit, updateStaffSubjectAllocationDetails } from "../../../../services/subject.service";
import { getLicenseKey } from "../../../../services/application.settings";
import AuthContext from "../../../../contexts/authContext";
import { getStaffListByUserTypeId } from "../../../../services/staff.service";
import { showLoader } from "../../../../services/loader.services";
import NoDataMsg from "../../../../common/components/NoDataMsg";
import SearchableSelect from "../../../../common/components/SearchableSelect";
import { getBatchMasterDetails, getSectionDetails } from "../../../../services/master.service";


function StaffSubjectAllocation() {

    useEffect(() => {
        getBatchList()
    }, [])

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const [mediumId, setMediumId] = useState<any>([])
    const [standardDetailsId, setstandardDetailsId] = useState<any>([])
    const [sectionDetailsId, setSectionDetailsId] = useState<any>([])

    const [mediumData, setMediumData] = useState<any>([])
    const [standardData, setStandardData] = useState<any>([])
    const [sectionDetailsData, setSectionDetailsData] = useState<any>([])

    const [columnVisibilityData, setColumnVisibilityData] = useState([]);
    const [allColumnsData, setAllColumnsData] = useState([]);
    const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
    const [columnDataById, setColumnDataById] = useState([])
    const componentRef = useRef(null);
    const [isChecked, setIsChecked] = useState(false)
    const [staffSubjectAllocationData, setStaffSubjectAllocationData] = useState<any>([])
    const [staffDetailsData, setStaffDetailsData] = useState<any>([])
    const [subjectPaperDetailsData, setSubjectPaperDetailsData] = useState<any>([])
    const [unitData, setUnitData] = useState([])
    const [staffDetailsId, setStaffDetailsId] = useState<any>('')
    const [subjectPaperDetailsId, setSubjectPaperDetailsId] = useState<any>('')

    const [dataSuccess, setDataSuccess] = useState(false)
    const [dataStatus, setDataStatus] = useState(false)
    const [isLoader, setIsLoader] = useState(false)

    const { themeStatus, darkModeStatus } = useDarkMode();
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(staffSubjectAllocationData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);
    const [isOpenListCard, setIsOpenListCard] = useState(true)
    const [addStaffSubjectAllocationOffCanvas, setAddStaffSubjectAllocationOffCanvas] = useState(false)
    const [editStaffSubjectAllocationOffCanvas, setEditStaffSubjectAllocationOffCanvas] = useState(false)
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);

    const [filteredDataToRefresh, setFilteredDataToRefresh] = useState([])
    const [unitArray, setUnitArray] = useState<any>([])
    const [unitArray1, setUnitArray1] = useState<any>([])
    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);
    const [noDataMsg, setNoDataMsg] = useState('')

    const [batchMasterData, setBatchMasterData] = useState<any>([])
    const [batchMasterId, setBatchMasterId] = useState<any>('')

    const staffSubjectAllocationForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            subjectPaperDetailsId: '',
            staffDetailsId: '',
            noOfPeriod: '',
            unit: '',
        },
        validate: (values) => {
            const errors: {
                subjectPaperDetailsId?: string;
                staffDetailsId?: string;
                noOfPeriod?: string;
                unit?: string;

            } = {};
            if (!subjectPaperDetailsId?.value) {
                errors.subjectPaperDetailsId = 'Required';
            }
            if (!staffDetailsId?.value) {
                errors.staffDetailsId = 'Required';
            }
            if (!values.noOfPeriod) {
                errors.noOfPeriod = 'Required';
            }
            if (unitArray.length == 0) {
                errors.unit = 'Required';
            }
            return errors;
        },

        //validateOnChange: false,
        onSubmit: () => { addStaffSubjectAllocation() },
    });

    const staffSubjectAllocationEditForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            staffSubjectAllocationDetailsId: '',
            subjectPaperDetailsId: '',
            unit: '',
            staffDetailsId: '',
            noOfPeriod: ''
        },
        validate: (values) => {
            const errors: {
                staffSubjectAllocationDetailsId?: string;
                subjectPaperDetailsId?: string;
                unit?: string;
                staffDetailsId?: string
                noOfPeriod?: string
            } = {};
            if (!values.staffSubjectAllocationDetailsId) {
                errors.staffSubjectAllocationDetailsId = 'Required';
            }
            if (!values.subjectPaperDetailsId) {
                errors.subjectPaperDetailsId = 'Required';
            }
            if (!values.unit) {
                errors.unit = 'Required';
            }
            if (!staffDetailsId?.value) {
                errors.staffDetailsId = 'Required';
            }
            if (!values.noOfPeriod) {
                errors.noOfPeriod = 'Required';
            }
            return errors;
        },

        //validateOnChange: false,
        onSubmit: () => { updateStaffSubjectAllocation() },
    });

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },

        //validateOnChange: false,
        onSubmit: () => { },
    });

    // Filter
    const filteredData = updateFilter(onCurrentPageData, columnVisibilityData, columnVisibilityForm);

    function onActivate(i: any) {
        if (i != undefined) {

            let selectedSubject = i
            setEditStaffSubjectAllocationOffCanvas(true)
            setStaffDetailsId({ value: i.staffDetailsId, label: i.empAndStaffName })

            staffSubjectAllocationEditForm.setValues({
                staffSubjectAllocationDetailsId: selectedSubject.staffSubjectAllocationDetailsId,
                subjectPaperDetailsId: selectedSubject.subjectPaperDetailsId,
                unit: selectedSubject.unit,
                staffDetailsId: staffDetailsId?.value,
                noOfPeriod: selectedSubject.noOfPeriod,
            });
        }
    }

    const selectBatch = (e: any) => {
        setstandardDetailsId('')
        setSectionDetailsId('')
        setMediumId('')
        let batchMasterId = e
        setBatchMasterId(batchMasterId)
        if (batchMasterId?.value != undefined) {
            getMediumList()
        }
    }

    const selectMedium = (e: any) => {
        setstandardDetailsId('')
        setSectionDetailsId('')
        let mediumId = e
        setMediumId(mediumId)
        if (mediumId?.value != undefined) {
            getStandardList()
        }
    }

    const selectStandard = (e: any) => {
        setSectionDetailsId('')
        let standardDetailsId = e
        setstandardDetailsId(standardDetailsId)
        if (standardDetailsId?.value != undefined) {
            getSection(mediumId?.value, standardDetailsId?.value)
        }
    }

    const selectSection = (e: any) => {
        let sectionDetailsId = e
        setSectionDetailsId(sectionDetailsId)
    }

    const selectStaff = (e: any) => {
        let staffDetailsId = e
        setStaffDetailsId(staffDetailsId)
    }

    const selectPaper = (e: any) => {
        let subjectPaperDetailsId = e
        setSubjectPaperDetailsId(subjectPaperDetailsId)
    }

    function viewStaffSubjectAllocationList() {
        getStaffListByUserType(6);
        getSubjectPaper();
        getUnitList();
        setDataSuccess(false)
        setIsLoader(true);
        setStaffSubjectAllocationData([]);
        columnVisibilityForm.resetForm()
        getStaffSubjectAllocationList(batchMasterId?.value, standardDetailsId?.value, sectionDetailsId?.value)
    }

    function getMediumList() {
        getMedium(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.medium;
                    if (data != undefined) {
                        setMediumData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setMediumData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStandardList() {
        getStandardDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.standardDetails;
                    if (data != undefined) {
                        setStandardData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setStandardData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getSection(mediumId: any, standardDetailsId: any) {
        getSectionDetails(mediumId, standardDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.sectionDetails;
                    if (data != undefined) {
                        setSectionDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setSectionDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStaffSubjectAllocationList(batchMasterId: any, standardDetailsId: any, sectionDetailsId: string) {
        getStaffSubjectAllocation(batchMasterId, standardDetailsId, sectionDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.staffSubjectAllocation;
                    if (data != undefined) {
                        setIsLoader(false);
                        getColumnsForTable('getStaffSubjectAllocation', 'get');
                        setStaffSubjectAllocationData(data);
                        setIsOpenListCard(false);
                        setDataSuccess(true);
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                        //setDataStatus(true)
                    } else {
                        setIsLoader(false);
                        // toasts("Undefined Data", "Error")
                        setAlertStatus({ message: "Undefined Data", type: "error" });
                        setIsOpen(true);
                    }
                }
                else if (response.data.success === false) {
                    setIsLoader(false);
                    setIsOpenListCard(false);
                    getColumnsForTable('getStaffSubjectAllocation', 'get');
                    setStaffSubjectAllocationData([]);
                    setNoDataMsg(response.data.message)
                    setDataSuccess(true);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setIsLoader(false);
                    // toasts(errorDescription, "Error")
                    setAlertStatus({ message: errorDescription, type: "error" });
                    setIsOpen(true);
                }
            }, error => {
                setIsLoader(false);
                // toasts(error, "Error")
                setAlertStatus({ message: error, type: "error" });
                setIsOpen(true);
            }
        )
    }

    function getStaffListByUserType(userTypeId: any) {
        getStaffListByUserTypeId(userAccountId, userTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.staffListByUserTypeId;
                    if (data != undefined) {
                        setStaffDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setStaffDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getSubjectPaper() {
        getSubjectPaperDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.subjectPaperDetails;
                    if (data != undefined) {
                        setSubjectPaperDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setSubjectPaperDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getUnitList() {
        getUnit(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.unit;
                    if (data != undefined) {
                        setUnitData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getBatchList() {
        getBatchMasterDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.batchMasterDetails;
                    if (data != undefined) {
                        setBatchMasterData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                    setBatchMasterData([])
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function setStaffSubjectAllocation() {

        return {
            batchMasterId: batchMasterId?.value,
            standardDetailsId: standardDetailsId?.value,
            sectionDetailsId: sectionDetailsId?.value,
            subjectPaperDetailsId: subjectPaperDetailsId?.value,
            unit: unitArray,
            staffDetailsId: staffDetailsId?.value,
            noOfPeriod: staffSubjectAllocationForm.values.noOfPeriod,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId
        }
    }

    function setEditStaffSubjectAllocation() {

        return {
            staffSubjectAllocationDetailsId: staffSubjectAllocationEditForm.values.staffSubjectAllocationDetailsId,
            subjectPaperDetailsId: staffSubjectAllocationEditForm.values.subjectPaperDetailsId,
            unit: staffSubjectAllocationEditForm.values.unit,
            staffDetailsId: staffDetailsId?.value,
            noOfPeriod: staffSubjectAllocationEditForm.values.noOfPeriod,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId
        }
    }

    function selectUnit(e: any) {
        let unit = e.map((item: any) => item.value)
        setUnitArray1(e)
        setUnitArray(unit)
    }

    function addStaffSubjectAllocation() {
        showLoader(true)
        if (staffSubjectAllocationForm.isValid) {
            let addAllocationPostData = setStaffSubjectAllocation()
            addStaffSubjectAllocationDetails(addAllocationPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        closeAndReset();
                        viewStaffSubjectAllocationList();
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false)
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (staffSubjectAllocationForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function updateStaffSubjectAllocation() {
        showLoader(true)
        if (staffSubjectAllocationEditForm.isValid) {
            let updateAllocationPostData = setEditStaffSubjectAllocation()
            updateStaffSubjectAllocationDetails(updateAllocationPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        closeAndReset();
                        viewStaffSubjectAllocationList();
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false)
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (staffSubjectAllocationEditForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function deleteStaffSubjectAllocation() {
        showLoader(true)
        // if (staffSubjectAllocationEditForm.isValid) {
        let deleteAllocationPostData = setEditStaffSubjectAllocation()
        deleteStaffSubjectAllocationDetails(deleteAllocationPostData,
            (response) => {
                const data = response.data;
                if (data.success == true) {
                    showLoader(false)
                    setAlertStatus({ message: data.message, type: "success" });
                    setIsOpen(true);
                    closeAndReset();
                    viewStaffSubjectAllocationList();
                }
                else if (data.success == false) {
                    showLoader(false)
                    setAlertStatus({ message: data.message, type: "error" });
                    setIsOpen(true);
                }
                else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    showLoader(false)
                    setAlertStatus({ message: errorDescription, type: "error" });
                    setIsOpen(true);
                }
            }
            , (error) => {
                showLoader(false)
                setAlertStatus({ message: error, type: "error" });
                setIsOpen(true);
            }
        )
        // } else if (staffSubjectAllocationEditForm.isValid == false) {
        //     setIsLoader(false);
        //     setAlertStatus({ message: 'Please fill all the details!', type: "error" });
        //     setIsOpen(true);
        // }
    }

    function getColumnsForTable(apiName: any, type: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setColumnVisibilityData(columnVisibilityData)

                        const allData = allColumnsData.map((item: any) => item.isDisplay);
                        const allColumns = allData.filter((item: any) => item == false);

                        if (type == 'get') {
                            setIsChecked(allColumns == '' ? true : false);
                        }
                        else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

                            for (let i = 0; i < nonDefaultValue.length; i++) {

                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: allColumns == '' ? 0 : 1
                                }
                                all.push(obj);
                            }
                            updateColumnsSubmit('', '', all)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )

    }

    function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {

        if (columnVisibilityForm.isValid) {

            setColumnDataById(isDisplay)
            const columnVisibilityPostData = {
                licenseKey: getLicenseKey,
                userAccountId: userAccountId,
                columns: type ? type : [
                    {
                        columnVisibilityId: columnVisibilityId,
                        isDisplay: isDisplay ? 0 : 1
                    }
                ]
            }

            updateColumnsForDataTable(columnVisibilityPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        getColumnsForTable('getStaffSubjectAllocation', 'get');
                    }
                    else if (data.success == false) {
                        // toasts(data.success, "Error")
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        toasts(errorDescription, "Error")
                    }
                }
                , (error) => {
                    toasts(error, "Error")
                }
            )
        } else if (columnVisibilityForm.isValid == false) {
            toasts("Please fill all the details!", "Error")
        }
    }

    function closeAndReset() {
        setIsDeleteOpen(false);
        // setAddStaffSubjectAllocationOffCanvas(false);
        setEditStaffSubjectAllocationOffCanvas(false);
        staffSubjectAllocationForm.resetForm();
        staffSubjectAllocationEditForm.resetForm();
        setUnitArray([])
        setUnitArray1([])
        setStaffDetailsId('')
        setSubjectPaperDetailsId('')
    }

    function resetStateAddNew() {
        staffSubjectAllocationForm.resetForm();
        staffSubjectAllocationEditForm.resetForm();
        setStaffDetailsId('')
        setSubjectPaperDetailsId('')
    }

    // Excel Configuration
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <PageWrapper title="Staff Subject Allocation">
            <Page container='fluid'>
                <Collapse isOpen={isOpenListCard}>
                    <Card stretch data-tour='list'>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info'>
                                <CardTitle tag='div' className='h5'>
                                    Staff Subject Allocation
                                </CardTitle>
                            </CardLabel>
                        </CardHeader>
                        <CardBody>
                            <div className="row g-4">
                                <div className='col-md-2'>
                                    <FormGroup id='batchMasterId' label='Batch' isFloating>
                                        <SearchableSelect isFloating
                                            ariaLabel='Batch'
                                            onChange={selectBatch}
                                            value={batchMasterId}
                                            list={batchMasterData.map((data: any) => (
                                                { value: data.batchMasterId, label: data.batchName }
                                            ))}
                                        />
                                    </FormGroup>
                                </div>
                                {batchMasterId?.value != undefined ?
                                    <div className='col-md-2'>
                                        <FormGroup id='mediumId' label='Medium' isFloating>
                                            <SearchableSelect isFloating
                                                ariaLabel='Medium'
                                                onChange={selectMedium}
                                                value={mediumId}
                                                list={mediumData.map((data: any) => (
                                                    { value: data.mediumId, label: data.medium }
                                                ))}
                                            />
                                        </FormGroup>
                                    </div> : null
                                }
                                {mediumId?.value != undefined ? <div className='col-md-2'>
                                    <FormGroup id='standardDetailsId' label='Standard' isFloating>
                                        <SearchableSelect isFloating
                                            ariaLabel='Standard'
                                            onChange={selectStandard}
                                            value={standardDetailsId}
                                            list={standardData.map((data: any) => (
                                                { value: data.standardDetailsId, label: data.standardName }
                                            ))}
                                        />
                                    </FormGroup>
                                </div> : null}

                                {standardDetailsId?.value != undefined ? <div className='col-md-2'>
                                    <FormGroup id='sectionDetailsId' label='Section' isFloating>
                                        <SearchableSelect isFloating
                                            ariaLabel='Section'
                                            onChange={selectSection}
                                            value={sectionDetailsId}
                                            list={sectionDetailsData.map((data: any) => (
                                                { value: data.sectionDetailsId, label: data.sectionName }
                                            ))}
                                        />
                                    </FormGroup>
                                </div> : null}

                                {standardDetailsId?.value != undefined && sectionDetailsId?.value != undefined ? <div className='col-md-3'>
                                    <Button className="mt-2" icon='ArrowDownward' color='primary' onClick={viewStaffSubjectAllocationList}>View</Button>
                                </div> : null}
                            </div>
                        </CardBody>
                    </Card>
                </Collapse>

                <OpenCardComponent isOpenListCard={isOpenListCard} setIsOpenListCard={setIsOpenListCard} isLoader={isLoader} />

                {dataSuccess ? <>

                    <Card stretch data-tour='list' ref={componentRef}>
                        <CardHeader borderSize={1}>

                            <CardLabel icon='List' iconColor='info' className="col-lg-5">
                                <CardTitle tag='div' className='h5'>Staff Subject Allocation Details</CardTitle>
                            </CardLabel>&nbsp;&nbsp;
                            <ButtonGroup className="col-lg-3 d-print-none" color='primary'>
                                <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                                <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
                            </ButtonGroup>

                            <CardActions className="d-print-none">
                                <Dropdown isOpen={columnVisibilityMenu} setIsOpen={setColumnVisibilityMenu} isButtonGroup>
                                    <DropdownToggle>
                                        <Button icon='FilterAlt' color='primary' isLight>Filter</Button>
                                    </DropdownToggle>
                                    <DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
                                        <DropdownItem>
                                            <div className='container py-2'>
                                                <form className='row g-3'>
                                                    <div className='col-12'>
                                                        <FormGroup>
                                                            <h6>Select All</h6>
                                                            <Checks id='available' type='switch' label='Select All Columns' onChange={() => getColumnsForTable('getStaffSubjectAllocation', 'post')} checked={isChecked} ariaLabel='Available status' />
                                                        </FormGroup>
                                                    </div>
                                                    <div className='col-12' style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                                                        <FormGroup>
                                                            <h6>Columns</h6>
                                                            <ChecksGroup>
                                                                {allColumnsData.map((i: any) => (
                                                                    <Checks key={i.columnVisibilityId} id='{i.columnVisibilityId}' label={i.columnName} onChange={() => updateColumnsSubmit(i.columnVisibilityId, i.isDisplay, '')} checked={i.isDisplay} disabled={i.isDefault == true} />
                                                                ))}
                                                            </ChecksGroup>
                                                        </FormGroup>
                                                    </div>
                                                </form>
                                            </div>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                <Dropdown isButtonGroup>
                                    <DropdownToggle>
                                        <Button color='primary' isLight icon='CloudDownload'> Export </Button>
                                    </DropdownToggle>
                                    <DropdownMenu isAlignmentEnd>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel("Staff Subject Allocation List", staffSubjectAllocationData, columnVisibilityData)}> Excel </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(staffSubjectAllocationData, columnVisibilityData, "Staff Subject Allocation List")}> PDF
                                            </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile("Staff Subject Allocation List", staffSubjectAllocationData, columnVisibilityData)}> CSV </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='Print' onClick={handlePrint}> Print </Button>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                <Button color='primary' icon='Add' isLight onClick={() => { setAddStaffSubjectAllocationOffCanvas(true); resetStateAddNew() }}>
                                    Add New
                                </Button>
                            </CardActions>
                        </CardHeader>
                        <CardBody className='table-responsive' isScrollable>
                            <table className='table table-modern table-hover text-nowrap'>
                                <thead>
                                    <tr>
                                        <th scope='col' onClick={() => requestSort('sno')}
                                            className='cursor-pointer text-decoration-underline'>S.No{' '}
                                            <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                        </th>
                                        {columnVisibilityData.map((column: any) => (
                                            <th key={column.keyName} scope='col' onClick={() => requestSort(column.keyName)}
                                                className='cursor-pointer text-decoration-underline'>
                                                {column.columnName}
                                                <Icon size='lg' className={getClassNamesFor(column.keyName)} icon='FilterList' />
                                            </th>
                                        ))}
                                        <th scope='col' className='d-print-none'>
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredData != "" ?
                                        <>{filteredData.map((rowData: any) => (
                                            <tr key={rowData.staffSubjectAllocationDetailsId}>
                                                <td>{rowData.sno}</td>{columnVisibilityData.map((column: any) => (
                                                    (column.isDisplay && rowData[column.keyName]) ?
                                                        <td key={`${rowData.staffSubjectAllocationDetailsId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />))}<td className="d-print-none" >
                                                    <Button color='dark' isLight icon='Edit' aria-label='Edit' onClick={() => { onActivate(rowData) }} /></td></tr>))}</>
                                        :
                                        <NoDataMsg columnsCount={columnVisibilityData.length + 2} msg={noDataMsg} />}
                                </tbody>
                            </table>
                        </CardBody>
                        <PaginationButtons
                            className="d-print-none"
                            data={items}
                            label='items'
                            setCurrentPage={setCurrentPage}
                            currentPage={currentPage}
                            perPage={perPage}
                            setPerPage={setPerPage}
                        />
                    </Card> </> : null
                }

                <OffCanvas
                    setOpen={setAddStaffSubjectAllocationOffCanvas}
                    isOpen={addStaffSubjectAllocationOffCanvas}
                    titleId='addStaffSubjectAllocationOffCanvas'
                    isBodyScroll
                    tag="form" noValidate
                    onSubmit={staffSubjectAllocationForm.handleSubmit}
                    isNotClose>
                    <OffCanvasHeader setOpen={setAddStaffSubjectAllocationOffCanvas} onClick={() => { closeAndReset; setAddStaffSubjectAllocationOffCanvas(false) }}>
                        <OffCanvasTitle id='addStaffSubjectAllocationOffCanvas'>Add Staff Subject Allocation</OffCanvasTitle>
                    </OffCanvasHeader>
                    <OffCanvasBody>
                        <div className="row g-4">
                            <div className="col-lg-12">
                                <FormGroup id='staffDetailsId' label='Select Staff' >
                                    <SearchableSelect
                                        ariaLabel='Staff Name' placeholder="Select Staff"
                                        onChange={selectStaff}
                                        value={staffDetailsId}
                                        list={staffDetailsData.map((data: any) => (
                                            { value: data.staffDetailsId, label: data.empAndStaffName }
                                        ))}
                                        onBlur={staffSubjectAllocationForm.handleBlur} isValid={staffSubjectAllocationForm.isValid} isTouched={staffSubjectAllocationForm.touched.staffDetailsId} invalidFeedback={staffSubjectAllocationForm.errors.staffDetailsId} required
                                    />
                                </FormGroup>
                            </div>
                            <div className="col-lg-12">
                                <FormGroup id='subjectPaperDetailsId' label='Select Paper Name' >
                                    <SearchableSelect
                                        ariaLabel='Paper Name' placeholder="Select Paper Name"
                                        onChange={selectPaper}
                                        value={subjectPaperDetailsId}
                                        list={subjectPaperDetailsData.map((data: any) => (
                                            { value: data.subjectPaperDetailsId, label: data.paperCodeAndName }
                                        ))}
                                        onBlur={staffSubjectAllocationForm.handleBlur} isValid={staffSubjectAllocationForm.isValid} isTouched={staffSubjectAllocationForm.touched.subjectPaperDetailsId} invalidFeedback={staffSubjectAllocationForm.errors.subjectPaperDetailsId} required
                                    />
                                </FormGroup>
                            </div>
                            <div className="col-lg-12">
                                <FormGroup id='unit' label='Select Unit' >
                                    <SearchableSelect multiple
                                        ariaLabel='Unit' placeholder="Unit"
                                        onChange={(e: any) => selectUnit(e)}
                                        value={unitArray1}
                                        list={unitData.map((data: any) => (
                                            { value: data.unit, label: data.unit }
                                        ))}
                                        onBlur={staffSubjectAllocationForm.handleBlur} isValid={staffSubjectAllocationForm.isValid} isTouched={staffSubjectAllocationForm.touched.unit} invalidFeedback={staffSubjectAllocationForm.errors.unit} required
                                    />
                                </FormGroup>
                            </div>
                            <div className='col-12'>
                                <FormGroup id='noOfPeriod' label='Period'>
                                    <Input placeholder="Enter Period" onChange={staffSubjectAllocationForm.handleChange} value={staffSubjectAllocationForm.values.noOfPeriod} type='text' onBlur={staffSubjectAllocationForm.handleBlur} isValid={staffSubjectAllocationForm.isValid} isTouched={staffSubjectAllocationForm.touched.noOfPeriod} invalidFeedback={staffSubjectAllocationForm.errors.noOfPeriod} onKeyDown={(e: any) => onlyAllowNumber(e)} required />
                                </FormGroup>
                            </div>
                        </div>

                    </OffCanvasBody>
                    <div className='row m-0'>
                        <div className='col-12 p-3 position-absolute top-95 start-50 translate-middle'>
                            <Button
                                color='info' icon="Save"
                                className='w-100' type="submit" isDisable={!staffSubjectAllocationForm.isValid && !!staffSubjectAllocationForm.submitCount}>
                                Save
                            </Button>
                        </div>
                    </div>
                </OffCanvas>

                <OffCanvas
                    setOpen={setEditStaffSubjectAllocationOffCanvas}
                    isOpen={editStaffSubjectAllocationOffCanvas}
                    titleId='editStaffSubjectAllocationOffCanvas'
                    isBodyScroll
                    tag="form" noValidate
                    onSubmit={staffSubjectAllocationEditForm.handleSubmit}
                >
                    <OffCanvasHeader setOpen={setEditStaffSubjectAllocationOffCanvas} onClick={closeAndReset}>
                        <OffCanvasTitle id='editStaffSubjectAllocationOffCanvas'>Update Staff Subject Allocation</OffCanvasTitle>
                    </OffCanvasHeader>
                    <OffCanvasBody>
                        <div className="row g-4">
                            <div className="col-lg-12">
                                <FormGroup id='subjectPaperDetailsId' label='Select Paper Name'>
                                    <Select
                                        ariaLabel='Paper Name'
                                        onChange={staffSubjectAllocationEditForm.handleChange}
                                        value={staffSubjectAllocationEditForm.values.subjectPaperDetailsId}
                                        disabled
                                        list={subjectPaperDetailsData.map((data: any) => (
                                            { value: data.subjectPaperDetailsId, text: data.paperCodeAndName }
                                        ))}
                                    />
                                </FormGroup>
                            </div>
                            <div className="col-lg-12">
                                <FormGroup id='staffDetailsId' label='Select Staff'>
                                    <SearchableSelect
                                        ariaLabel='Staff'
                                        onChange={selectStaff}
                                        value={staffDetailsId}
                                        list={staffDetailsData.map((data: any) => (
                                            { value: data.staffDetailsId, label: data.empAndStaffName }
                                        ))}
                                    />
                                </FormGroup>
                            </div>
                            <div className='col-12'>
                                <FormGroup id='unit' label='Unit'>
                                    <Input placeholder="Enter Unit" onChange={staffSubjectAllocationEditForm.handleChange} value={staffSubjectAllocationEditForm.values.unit} type='text' onBlur={staffSubjectAllocationEditForm.handleBlur} isValid={staffSubjectAllocationEditForm.isValid} isTouched={staffSubjectAllocationEditForm.touched.unit} invalidFeedback={staffSubjectAllocationEditForm.errors.unit} disabled />
                                </FormGroup>
                            </div>
                            <div className='col-12'>
                                <FormGroup id='noOfPeriod' label='No Of Period'>
                                    <Input placeholder="Enter No Of Period" onChange={staffSubjectAllocationEditForm.handleChange} value={staffSubjectAllocationEditForm.values.noOfPeriod} type='text' onBlur={staffSubjectAllocationEditForm.handleBlur} isValid={staffSubjectAllocationEditForm.isValid} isTouched={staffSubjectAllocationEditForm.touched.noOfPeriod} invalidFeedback={staffSubjectAllocationEditForm.errors.noOfPeriod} onKeyDown={(e: any) => onlyAllowNumber(e)} />
                                </FormGroup>
                            </div>
                        </div>
                    </OffCanvasBody>

                    <div className='row'>
                        <div className="d-flex justify-content-center position-absolute top-65 start-50 translate-middle">
                            <DeleteComponents isDeleteOpen={isDeleteOpen} setIsDeleteOpen={setIsDeleteOpen} deleteFunction={deleteStaffSubjectAllocation} />
                        </div>
                        <div className='col-6 position-absolute bottom-0 start-0 p-3'>
                            <Button color='danger' className='w-100' icon="Delete" onClick={() => setIsDeleteOpen(!isDeleteOpen)}>
                                Delete
                            </Button>
                        </div>
                        <div className='col-6 position-absolute bottom-0 end-0 p-3'>
                            <Button color='info' icon="Save" className='w-100' type="submit" isDisable={!staffSubjectAllocationEditForm.isValid && !!staffSubjectAllocationEditForm.submitCount}>Update</Button>
                        </div>
                    </div>
                </OffCanvas>
            </Page>

            <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />

        </PageWrapper>
    )
} export default StaffSubjectAllocation;