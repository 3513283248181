import classNames from 'classnames';
import React, { useContext, useEffect, useRef, useState } from 'react'
import Page from '../../../layout/Page/Page';
import Card, { CardHeader, CardLabel, CardTitle, CardActions, CardBody, CardSubTitle } from '../../bootstrap/Card';
import useDarkMode from '../../../hooks/useDarkMode';
import Button, { ButtonGroup } from '../../bootstrap/Button';
import PaginationButtons, { dataPagination } from '../../PaginationButtons';
import useSortableData from '../../../hooks/useSortableData';
import { getColumnsForDataTable, updateFilter } from '../../../services/common.service';
import { toasts } from '../../../services/toast.service';
import AuthContext from '../../../contexts/authContext';
import { TableLoader } from '../../../services/loader.services';
import Icon from '../../icon/Icon';
import NoDataMsg from '../../../common/components/NoDataMsg';
import { useFormik } from 'formik';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../../bootstrap/Dropdown';
import { downloadExcel, convertJsonToPdf, downloadFile } from '../../../services/ExportService';
import { useReactToPrint } from 'react-to-print';
import { getGenderDetails, getGenderWiseStaffReport } from '../../../services/master.service';
import { getFirstLetter } from '../../../helpers/helpers';


const GenderWiseStaffList = () => {

    useEffect(() => {
        setGenderId(0)
        getGenderWiseStaff(0)
        getGender()
    }, [])

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const { darkModeStatus } = useDarkMode();
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [genderData, setGenderData] = useState([]);
    const [casteData, setCasteData] = useState([]);
    const { items, requestSort, getClassNamesFor } = useSortableData(casteData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);
    const [allColumnsData, setAllColumnsData] = useState([]);
    const [columnVisibilityData, setColumnVisibilityData] = useState([]);
    const [isChecked, setIsChecked] = useState(false)
    const [noDataMsg, setNoDataMsg] = useState('')
    const [dataStatus, setDataStatus] = useState(false)
    const componentRef = useRef(null);


    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },

        //validateOnChange: false,
        onSubmit: () => { },
    });

    const filteredData = updateFilter(onCurrentPageData, columnVisibilityData, columnVisibilityForm);

    const [genderCount, setGenderCount] = useState<any>([])

    function getGenderWiseStaff(genderId: any) {
        setDataStatus(false)
        getGenderWiseStaffReport(genderId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.genderWiseStaffReport;
                    if (data != undefined) {
                        getColumnsForTable('getGenderWiseStaffReport', 'get');
                        setCasteData(data[0].staffDetails);
                        setGenderCount(data[0]);
                        for (let i = 0; i < data[0].staffDetails.length; i++) {
                            data[0].staffDetails[i].sno = i + 1;
                        }
                        setDataStatus(true)
                    } else {
                        setDataStatus(true)
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setDataStatus(true)
                    //toasts(response.data.message, "Error")
                    getColumnsForTable('getGenderWiseStaffReport', 'get');
                    setCasteData([]);
                    setNoDataMsg(response.data.message)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setDataStatus(true)
                    toasts(errorDescription, "Error")
                }
            }, error => {
                setDataStatus(true)
                toasts(error, "Error")
            }
        )
    }

    function getGender() {
        getGenderDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.genderDetails;
                    if (data != undefined) {
                        setGenderData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setGenderData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }


    function getColumnsForTable(apiName: any, type: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setColumnVisibilityData(columnVisibilityData)

                        const allData = allColumnsData.map((item: any) => item.isDisplay);
                        const allColumns = allData.filter((item: any) => item == false);

                        if (type == 'get') {
                            setIsChecked(allColumns == '' ? true : false);
                        }
                        else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

                            for (let i = 0; i < nonDefaultValue.length; i++) {

                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: allColumns == '' ? 0 : 1
                                }
                                all.push(obj);
                            }
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const [genderId, setGenderId] = useState<any>(0)

    function selectStaffList(genderId: any) {
        getGenderWiseStaff(genderId)
        setGenderId(genderId)
    }

    const { themeStatus } = useDarkMode();

    return (
        <Page>
            <Card stretch ref={componentRef}>
                <CardHeader borderSize={1}>
                    <CardLabel icon='list'>
                        <CardTitle tag='div' className='h5'>
                            Gender Wise Staff List
                        </CardTitle>
                    </CardLabel>
                    <CardActions className="d-print-none">
                        <Dropdown isButtonGroup>
                            <DropdownToggle>
                                <Button color='primary' isLight icon='CloudDownload'> Export </Button>
                            </DropdownToggle>
                            <DropdownMenu isAlignmentEnd>
                                <DropdownItem>
                                    <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel("GenderWiseStaffList", casteData, columnVisibilityData)}> Excel </Button>
                                </DropdownItem>
                                <DropdownItem>
                                    <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(casteData, columnVisibilityData, "GenderWiseStaffList")}> PDF
                                    </Button>
                                </DropdownItem>
                                <DropdownItem>
                                    <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile("GenderWiseStaffList", casteData, columnVisibilityData)}> CSV </Button>
                                </DropdownItem>
                                <DropdownItem>
                                    <Button color='primary' isLight icon='Print' onClick={handlePrint}> Print </Button>
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </CardActions>
                </CardHeader>
                <CardBody>
                    <div className='row'>
                        <div className='col-3'>
                            {/* <div className='col-md-12 mt-4'>
                                <Card
                                    onClick={() => selectStaffList(0)}
                                    className={`${genderId == 0 ? 'border border-warning border-2' : ''} bg-l${darkModeStatus ? 'o25' : '25'
                                        }-warning bg-l${darkModeStatus ? 'o50' : '10'
                                        }-warning-hover transition-base rounded-2 mb-4`}
                                    shadow='sm'>
                                    <CardBody>
                                        <div className='d-flex align-items-center pb-3 mt-3' style={{ height: '22px' }}>
                                            <div
                                                className='ratio ratio-1x1 me-3'
                                                style={{ width: 40 }}>
                                                <div
                                                    className={classNames(
                                                        'rounded-5',
                                                        'd-flex align-items-center justify-content-center',
                                                        'bg-warning',
                                                    )}>
                                                    <span className='avatar-1 fs-3 text-white fw-bold'>
                                                        T
                                                    </span>
                                                </div>
                                            </div>
                                            <div className='flex-grow-1 ms-3'>
                                                <div className='fw-bold fs-4 text-warning mb-0'>
                                                    {genderCount.totalCount}
                                                </div>
                                                <div className='text-muted'>
                                                    <b>TOTAL</b>
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                            {genderData.map((item: any) => (
                                <div className='col-md-12 mt-3'>
                                    <Card
                                        onClick={() => selectStaffList(item.genderId)}
                                        className={`${genderId == item.genderId && item.genderId == 1 ? 'border border-primary border-2' : genderId == item.genderId && item.genderId == 2 ? 'border border-success border-2' : genderId == item.genderId && item.genderId == 3 ? 'border border-danger border-2' : ''} ${item.genderId == 1 ? `bg-l${darkModeStatus ? 'o25' : '25'
                                            }-primary bg-l${darkModeStatus ? 'o50' : '10'
                                            }-primary-hover transition-base rounded-2 mb-4` : item.genderId == 2 ? `bg-l${darkModeStatus ? 'o25' : '25'
                                                }-success bg-l${darkModeStatus ? 'o50' : '10'
                                                }-success-hover transition-base rounded-2 mb-4` : item.genderId == 3 ? `bg-l${darkModeStatus ? 'o25' : '25'
                                                    }-danger bg-l${darkModeStatus ? 'o50' : '10'
                                                    }-danger-hover transition-base rounded-2 mb-4` : ''
                                            }`}
                                        shadow='sm'>
                                        <CardBody>
                                            <div className='d-flex align-items-center pb-3 mt-3' style={{ height: '22px' }}>
                                                <div
                                                    className='ratio ratio-1x1 me-3'
                                                    style={{ width: 40 }}>
                                                    <div
                                                        className={classNames(
                                                            'rounded-5',
                                                            'd-flex align-items-center justify-content-center',
                                                            `${item.genderId == 1 ? 'bg-primary' : item.genderId == 2 ? 'bg-success' : item.genderId == 3 ? 'bg-danger' : ''}`
                                                        )}>
                                                        <span className='avatar-1 fs-3 text-white fw-bold text-uppercase'>
                                                            {getFirstLetter(item.gender)}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className='flex-grow-1 ms-3'>
                                                    <div className={`fw-bold fs-4 mb-0 ${item.genderId == 1 ? 'text-primary' : item.genderId == 2 ? 'text-success' : item.genderId == 3 ? 'text-danger' : ''}`}>
                                                        {item.genderId == 1 ? genderCount.maleCount : item.genderId == 2 ? genderCount.femaleCount : item.genderId == 3 ? genderCount.othersCount : null}
                                                    </div>
                                                    <div className='text-muted text-uppercase'>
                                                        <b>{item.gender}</b>
                                                    </div>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>
                            ))} */}

                            <div className='row g-3'>
                                <div className='col-xl-12 col-lg-6 col-sm-12'>
                                    <Button
                                        isLight={genderId !== 0}
                                        onClick={() => selectStaffList(0)}
                                        color={themeStatus}
                                        className={`w-100 py-4 text-warning`}
                                        shadow='sm'
                                        hoverShadow='none'>
                                        Total - {genderCount.totalCount}
                                    </Button>
                                </div>
                                {genderData.map((item: any) => (
                                    <div key={item.genderId} className='col-xl-12 col-lg-6 col-sm-12'>
                                        <Button
                                            isLight={genderId !== item.genderId}
                                            onClick={() => selectStaffList(item.genderId)}
                                            color={themeStatus}
                                            className={`w-100 py-4 ${item.genderId == 1 ? 'text-primary' : item.genderId == 2 ? 'text-success' : item.genderId == 3 ? 'text-danger' : ''}`}
                                            shadow='sm'
                                            hoverShadow='none'>
                                            {item.gender} - {item.genderId == 1 ? genderCount.maleCount : item.genderId == 2 ? genderCount.femaleCount : item.genderId == 3 ? genderCount.othersCount : null}
                                        </Button>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className='col-9'>

                            {!dataStatus ?
                                <TableLoader /> :
                                <table className='table table-modern table-hover text-nowrap'>
                                    <thead>
                                        <tr>
                                            <th scope='col' onClick={() => requestSort('sno')}
                                                className='cursor-pointer text-decoration-underline'>S.No{' '}
                                                <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                            </th>
                                            {columnVisibilityData.map((column: any) => (
                                                <th key={column.keyName} scope='col' onClick={() => requestSort(column.keyName)}
                                                    className='cursor-pointer text-decoration-underline'>
                                                    {column.columnName}
                                                    <Icon size='lg' className={getClassNamesFor(column.keyName)} icon='FilterList' />
                                                </th>
                                            ))}

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredData != "" ?
                                            <>
                                                {filteredData.map((rowData: any) => (
                                                    <tr key={rowData.staffDetailsId}>
                                                        <td>{rowData.sno}</td>
                                                        {columnVisibilityData.map((column: any) => (
                                                            (column.isDisplay && rowData[column.keyName]) ?
                                                                <td key={`${rowData.staffDetailsId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />
                                                        ))}
                                                    </tr>
                                                ))}</>
                                            :
                                            <NoDataMsg columnsCount={columnVisibilityData.length + 2} msg={noDataMsg} />}
                                    </tbody>
                                </table>
                            }
                        </div>
                    </div>
                </CardBody>
                <PaginationButtons
                    className="d-print-none"
                    data={items}
                    label='items'
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                    perPage={perPage}
                    setPerPage={setPerPage}
                />
            </Card>
        </Page>
    );
}

export default GenderWiseStaffList