import React, { useEffect, useState } from 'react';
import ApexCharts from 'apexcharts';
import { getStudentExamMarkDetails } from '../../services/exam.service';
import { toasts } from '../../services/toast.service';
import Card, { CardHeader, CardLabel, CardTitle, CardBody } from '../bootstrap/Card';
import Chart from '../extras/Chart';
import '../../pages/documentation/charts/chart-columns/custom.scss';

const ExamMarkStatus = ({ data }: any) => {

    useEffect(() => {
        if (data != '') {
            getStudentExamMarkDetailsList(data?.standardDetailsId, data?.sectionDetailsId, data?.studentDetailsId, 0)
        }
    }, [])

    const [examData, setExamData] = useState<any>('')

    const dynamicDataOptions = {
        chart: {
            toolbar: {
                show: false,
            },
        },
        tooltip: {
            shared: false,
        },
        legend: {
            show: false,
        },
    };

    const colors = [
        process.env.REACT_APP_INFO_COLOR,
        process.env.REACT_APP_SUCCESS_COLOR,
        process.env.REACT_APP_WARNING_COLOR,
        '#FF4560',
        process.env.REACT_APP_PRIMARY_COLOR,
        process.env.REACT_APP_SECONDARY_COLOR,
        process.env.REACT_APP_DANGER_COLOR,
    ];

    const updateQuarterChart = (
        sourceChart: { w: { globals: { selectedDataPoints: any[] }; config: { series: any[] } } },
        destChartIDToUpdate: string,
    ) => {
        let series = [];
        const seriesIndex = 0;
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const colors = [];

        if (sourceChart.w.globals.selectedDataPoints[0]) {
            const selectedPoints = sourceChart.w.globals.selectedDataPoints;
            for (let i = 0; i < selectedPoints[seriesIndex].length; i += 1) {
                const selectedIndex = selectedPoints[seriesIndex][i];
                const yearSeries = sourceChart.w.config.series[seriesIndex];
                series.push({
                    name: yearSeries.data[selectedIndex].x,
                    data: yearSeries.data[selectedIndex].quarters,
                });
                colors.push(yearSeries.data[selectedIndex].color);
            }

            if (series.length === 0)
                series = [
                    {
                        data: [],
                    },
                ];

            return ApexCharts.exec(destChartIDToUpdate, 'updateOptions', {
                series,
                colors,
                fill: {
                    colors,
                },
            });
        }
        return null;
    };

    function getStudentExamMarkDetailsList(standardDetailsId: any, sectionDetailsId: any, studentDetailsId: any, examDetailsId: any) {

        getStudentExamMarkDetails(standardDetailsId, sectionDetailsId, studentDetailsId, examDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentExamMarkDetails;
                    if (data != undefined) {

                        for (let i = 0; i < data.length; i++) {
                            data[i].x = data[i].examTypeName + ' - ' + data[i].percentage;
                            data[i].y = data[i].totalScored;
                            data[i].color = colors[i];
                            data[i].quarters = data[i].markDetails

                            let markDetails = data[i].markDetails

                            for (let j = 0; j < markDetails.length; j++) {
                                markDetails[j].x = markDetails[j].paperName.substring(0, 3);
                                markDetails[j].y = markDetails[j].totalScoredMark
                            }
                        }

                        setExamData(
                            {
                                series: [
                                    {
                                        data: data,
                                    },
                                ],
                                options: {
                                    ...dynamicDataOptions,
                                    chart: {
                                        id: 'MarkPort',
                                        height: 400,
                                        width: '100%',
                                        type: 'bar',
                                        events: {
                                            dataPointSelection(e: any, chart: any, opts: any) {
                                                const quarterChartEl = document.querySelector('#chart-quarter');
                                                const yearChartEl = document.querySelector('#chart-year');

                                                if (opts.selectedDataPoints[0].length === 1) {
                                                    if (quarterChartEl?.classList.contains('active')) {
                                                        updateQuarterChart(chart, 'markList');
                                                    } else {
                                                        yearChartEl?.classList.add('chart-quarter-activated');
                                                        quarterChartEl?.classList.add('active');
                                                        updateQuarterChart(chart, 'markList');
                                                    }
                                                } else {
                                                    updateQuarterChart(chart, 'markList');
                                                }

                                                if (opts.selectedDataPoints[0].length === 0) {
                                                    yearChartEl?.classList.remove('chart-quarter-activated');
                                                    quarterChartEl?.classList.remove('active');
                                                }
                                            },
                                            updated(chart: any) {
                                                updateQuarterChart(chart, 'markList');
                                            },
                                        },
                                    },
                                    plotOptions: {
                                        bar: {
                                            distributed: true,
                                            horizontal: true,
                                            barHeight: '75%',
                                            dataLabels: {
                                                position: 'bottom',
                                            },
                                        },
                                    },
                                    dataLabels: {
                                        enabled: true,
                                        textAnchor: 'start',
                                        style: {
                                            colors: ['#fff'],
                                        },
                                        formatter(val: any, opt: any) {
                                            return opt.w.globals.labels[opt.dataPointIndex];
                                        },
                                        offsetX: 0,
                                        dropShadow: {
                                            enabled: true,
                                        },
                                    },

                                    colors,

                                    states: {
                                        normal: {
                                            filter: {
                                                type: 'desaturate',
                                            },
                                        },
                                        active: {
                                            allowMultipleDataPointsSelection: true,
                                            filter: {
                                                type: 'darken',
                                                value: 1,
                                            },
                                        },
                                    },
                                    tooltip: {
                                        x: {
                                            show: false,
                                        },
                                        // @ts-ignore
                                        y: {
                                            title: {
                                                formatter(
                                                    val: any,
                                                    opts: {
                                                        w: { globals: { labels: { [x: string]: any } } };
                                                        dataPointIndex: string | number;
                                                    },
                                                ) {
                                                    return opts.w.globals.labels[opts.dataPointIndex];
                                                },
                                            },
                                        },
                                    },
                                    title: {
                                        text: 'Yearly Results',
                                        offsetX: 15,
                                    },
                                    subtitle: {
                                        text: '(Click on bar to see details)',
                                        offsetX: 15,
                                    },
                                    yaxis: {
                                        labels: {
                                            show: false,
                                        },
                                    },
                                },

                                seriesQuarter: [
                                    {
                                        data: [],
                                    },
                                ],
                                optionsQuarter: {
                                    ...dynamicDataOptions,
                                    chart: {
                                        id: 'markList',
                                        height: 400,
                                        width: '100%',
                                        type: 'bar',
                                        stacked: true,
                                    },
                                    plotOptions: {
                                        bar: {
                                            columnWidth: '50%',
                                            horizontal: false,
                                        },
                                    },
                                    legend: {
                                        show: false,
                                    },
                                    grid: {
                                        yaxis: {
                                            lines: {
                                                show: false,
                                            },
                                        },
                                        xaxis: {
                                            lines: {
                                                show: true,
                                            },
                                        },
                                    },
                                    yaxis: {
                                        labels: {
                                            show: false,
                                        },
                                    },
                                    title: {
                                        text: 'Scored Marks',
                                        offsetX: 10,
                                    },
                                    tooltip: {
                                        x: {
                                            formatter(
                                                val: any,
                                                opts: {
                                                    w: { globals: { seriesNames: { [x: string]: any } } };
                                                    seriesIndex: string | number;
                                                },
                                            ) {
                                                return opts.w.globals.seriesNames[opts.seriesIndex];
                                            },
                                        },
                                        y: {
                                            title: {
                                                formatter(
                                                    val: any,
                                                    opts: {
                                                        w: { globals: { labels: { [x: string]: any } } };
                                                        dataPointIndex: string | number;
                                                    },
                                                ) {
                                                    return opts.w.globals.labels[opts.dataPointIndex];
                                                },
                                            },
                                        },
                                    },
                                },
                            }
                        )
                    } else {
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    console.log(response.data.success);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, 'Error');
                }
            },
            (error) => {
                toasts(error, 'Error');
            },
        );
    }

    return (
        <>
            {examData != '' ?
                <div className="col-12">
                    <Card stretch className='rounded-1 border border-light'>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='BarChart'>
                                <CardTitle>
                                    Exam Marks
                                </CardTitle>
                            </CardLabel>
                        </CardHeader>
                        <CardBody style={{ position: 'relative' }}>
                            {examData != '' ? <>
                                <Chart
                                    id='chart-year'
                                    series={examData.series}
                                    options={examData.options}
                                    type='bar'
                                    height={300}
                                />
                                <Chart
                                    id='chart-quarter'
                                    series={examData.seriesQuarter}
                                    options={examData.optionsQuarter}
                                    type='bar'
                                    height={300}
                                /> </> : null
                            }
                        </CardBody>
                    </Card>
                </div> : null
            }
        </>
    );
};

export default ExamMarkStatus;
