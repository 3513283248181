import React, { useContext, useEffect, useRef, useState } from 'react'
import useSortableData from '../../../hooks/useSortableData'
import { dataPagination } from '../../PaginationButtons'
import { useFormik } from 'formik'
import { toasts } from '../../../services/toast.service'
import Page from '../../../layout/Page/Page'
import PageWrapper from '../../../layout/PageWrapper/PageWrapper'
import Collapse from '../../bootstrap/Collapse'
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from '../../bootstrap/Card'
import FormGroup from '../../bootstrap/forms/FormGroup'
import Button, { ButtonGroup } from '../../bootstrap/Button'
import Icon from '../../icon/Icon'
import Input from '../../bootstrap/forms/Input'
import classNames from 'classnames';
import useDarkMode from '../../../hooks/useDarkMode';
import OpenCardComponent from '../../../common/components/OpenCardComponent'
import { useReactToPrint } from 'react-to-print'
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../../bootstrap/Dropdown'
import { convertJsonToPdf, downloadExcel, downloadFile } from '../../../services/ExportService'
import { getRouteList, getRouteTravellerCountByRouteMasterId, getRouteWiseTravellerList } from '../../../services/transport.service'
import AuthContext from '../../../contexts/authContext'
import { getColumnsForDataTable, pictNotLoading, profilePic, updateColumnsForDataTable } from '../../../services/common.service'
import { getLicenseKey } from '../../../services/application.settings'
import NoDataMsg from '../../../common/components/NoDataMsg'
import SearchableSelect from '../../../common/components/SearchableSelect'
import '../../../common/components/toPrint.css'
import noImage from '../../../assets/img/noImage.png';

const RouteWistStudentList = () => {

    useEffect(() => {
        getRoute()
    }, [])

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const [routeMasterId, setRouteMasterId] = useState<any>('')
    const [routeListData, setRouteListData] = useState<any>([])
    const [routeTravellerCountByRouteMasterData, setRouteTravellerCountByRouteMasterData] = useState([])
    const [routeWiseTravellerListData, setRouteWiseTravellerListData] = useState<any>([])

    const [dataSuccess, setDataSuccess] = useState(false)
    const [isOpenListCard, setIsOpenListCard] = useState(true)
    const { darkModeStatus } = useDarkMode();
    const [columnVisibilityData, setColumnVisibilityData] = useState([]);
    const [allColumnsData, setAllColumnsData] = useState([]);
    const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
    const [columnDataById, setColumnDataById] = useState([])
    const componentRef = useRef(null);
    const [isChecked, setIsChecked] = useState(false)

    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(100);
    const { items, requestSort, getClassNamesFor } = useSortableData(routeWiseTravellerListData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);

    const [routeNumber, setRouteNumber] = useState([])
    const [routeName, setRouteName] = useState([])
    const [totalCount, setTotalCount] = useState([])
    const [studentCount, setStudentCount] = useState([])
    const [staffCount, setStaffCount] = useState([])
    const [maleCount, setMaleCount] = useState([])
    const [femaleCount, setFemaleCount] = useState([])
    const [isLoader, setIsLoader] = useState(false)

    const [noDataMsg, setNoDataMsg] = useState('')

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },

        //validateOnChange: false,
        onSubmit: () => { },
    });


    // // Filter
    // const filteredData = updateFilter(items,columnVisibilityData, columnVisibilityForm);

    const filteredData = items.filter(
        (i) =>
            i.userType && i.userType.toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase()) ||
            i.travellerId && i.travellerId.toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase()) ||
            i.areaName && i.areaName.toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase()) ||
            i.gender && i.gender.toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase()) ||
            i.address && i.address.toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase()) ||
            i.contactNo && i.contactNo.toString().includes(columnVisibilityForm.values.searchInput.toLowerCase()) ||
            i.studentOrStaffName && i.studentOrStaffName.toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase())
    );

    const selectRouteMaster = (e: any) => {
        let routeMasterId = e
        setRouteMasterId(routeMasterId)
    }

    const viewTravellerList = () => {
        setDataSuccess(false)
        setIsLoader(true);
        setRouteWiseTravellerListData([]);
        setRouteNumber([])
        setRouteName([])
        setTotalCount([])
        setStudentCount([])
        setStaffCount([])
        setMaleCount([])
        setFemaleCount([])
        getRouteWiseTraveller(routeMasterId?.value)
        columnVisibilityForm.resetForm();
    }

    function getRoute() {
        getRouteList(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.routeList;
                    if (data != undefined) {
                        setRouteListData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setRouteListData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getRouteWiseTraveller(routeMasterId: string) {
        setIsLoader(true);
        getRouteWiseTravellerList(routeMasterId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.routeWiseTravellerList;
                    if (data != undefined) {
                        setIsLoader(false);
                        setRouteWiseTravellerListData(data);
                        setDataSuccess(true);
                        setIsOpenListCard(false);
                        getRouteTravellerCount(routeMasterId);
                        getColumnsForTable('getRouteWiseTravellerList', 'get');
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        setIsLoader(false);
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setIsLoader(false);
                    //toasts(response.data.message, "Error")
                    setDataSuccess(true);
                    setIsOpenListCard(false);
                    getColumnsForTable('getRouteWiseTravellerList', 'get');
                    setRouteWiseTravellerListData([]);
                    setNoDataMsg(response.data.message)
                } else {
                    setIsLoader(false);
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                setIsLoader(false);
                toasts(error, "Error")
            }
        )
    }

    function getRouteTravellerCount(routeMasterId: any) {
        getRouteTravellerCountByRouteMasterId(routeMasterId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.routeTravellerCountByRouteMasterId[0];
                    if (data != undefined) {
                        setRouteNumber(data.routeNumber)
                        setRouteName(data.routeName)
                        setTotalCount(data.totalCount)
                        setStudentCount(data.studentCount)
                        setStaffCount(data.staffCount)
                        setMaleCount(data.maleCount)
                        setFemaleCount(data.femaleCount)
                        setRouteTravellerCountByRouteMasterData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getColumnsForTable(apiName: any, type: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setColumnVisibilityData(columnVisibilityData)

                        const allData = allColumnsData.map((item: any) => item.isDisplay);
                        const allColumns = allData.filter((item: any) => item == false);

                        if (type == 'get') {                          
                            setIsChecked(allColumns == '' ? true : false);
                        }
                        else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

                            for (let i = 0; i < nonDefaultValue.length; i++) {

                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: allColumns == '' ? 0 : 1
                                }
                                all.push(obj);
                            }
                            updateColumnsSubmit('', '', all)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )

    }

    function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {

        if (columnVisibilityForm.isValid) {

            setColumnDataById(isDisplay)
            const columnVisibilityPostData = {
                licenseKey: getLicenseKey,
                userAccountId: userAccountId,
                columns: type ? type : [
                    {
                        columnVisibilityId: columnVisibilityId,
                        isDisplay: isDisplay ? 0 : 1
                    }
                ]
            }

            updateColumnsForDataTable(columnVisibilityPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        getColumnsForTable('getRouteWiseTravellerList', 'get');
                    }
                    else if (data.success == false) {
                        // toasts(data.success, "Error")
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        toasts(errorDescription, "Error")
                    }
                }
                , (error) => {
                    toasts(error, "Error")
                }
            )
        } else if (columnVisibilityForm.isValid == false) {
            toasts("Please fill all the details!", "Error")
        }
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <>
            <PageWrapper title='Route Wise Student List' ref={componentRef} >
                <Page container='fluid'>
                    <Collapse isOpen={isOpenListCard}>
                        <Card stretch data-tour='list'>
                            <CardHeader borderSize={1}>
                                <CardLabel icon='List' iconColor='info'>
                                    <CardTitle tag='div' className='h5'>
                                        Route Wise List
                                    </CardTitle>
                                </CardLabel>
                            </CardHeader>
                            <CardBody className='to-print-none'>
                                <div className='row'>
                                    <div className='col-4'>
                                        <FormGroup id='routeMasterId' label='Route' isFloating>
                                            <SearchableSelect isFloating
                                                ariaLabel='Route'
                                                onChange={selectRouteMaster}
                                                value={routeMasterId}
                                                list={routeListData.map((data: any) => (
                                                    { value: data.routeMasterId, label: data.routeNumberAndRouteName }
                                                ))}
                                            />
                                        </FormGroup>
                                    </div>

                                    <div className="col-3 mt-2">
                                        <Button icon='ArrowDownward' color='primary' isDisable={routeMasterId?.value == undefined} onClick={viewTravellerList}>View</Button>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Collapse>

                    <OpenCardComponent isOpenListCard={isOpenListCard} setIsOpenListCard={setIsOpenListCard} isLoader={isLoader} />

                    {dataSuccess ?
                        <>
                            <Card stretch data-tour='list' ref={componentRef}>
                                <CardHeader borderSize={1}>
                                    <CardLabel icon='List' iconColor='info' className="col-lg-6">
                                        <CardTitle tag='div' className='h5'>Route Wise Traveller List</CardTitle>
                                    </CardLabel>&nbsp;&nbsp;

                                    <ButtonGroup className="col-lg-3 justify-content-end d-print-none" color='primary'>
                                        <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                                        <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
                                    </ButtonGroup>

                                    <CardActions className="d-print-none">
                                        {/* <Dropdown isOpen={columnVisibilityMenu} setIsOpen={setColumnVisibilityMenu} isButtonGroup>
                                            <DropdownToggle>
                                                <Button icon='FilterAlt' color='primary' isLight>Filter</Button>
                                            </DropdownToggle>
                                            <DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
                                                <DropdownItem>
                                                    <div className='container py-2'>
                                                        <form className='row g-3'>
                                                            <div className='col-12'>
                                                                <FormGroup>
                                                                    <h6>Select All</h6>
                                                                    <Checks
                                                                        id='available'
                                                                        type='switch'
                                                                        label='Select All Columns'
                                                                        onChange={() => getColumnsForTable('getRouteWiseTravellerList', 'post')}
                                                                        checked={isChecked}
                                                                        ariaLabel='Available status'
                                                                    />
                                                                </FormGroup>
                                                            </div>
                                                            <div className='col-12' style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                                                                <FormGroup>
                                                                    <h6>Columns</h6>
                                                                    <ChecksGroup>
                                                                        {allColumnsData.map((i: any) => (
                                                                            <Checks key={i.columnVisibilityId} id='{i.columnVisibilityId}' label={i.columnName} onChange={() => updateColumnsSubmit(i.columnVisibilityId, i.isDisplay, '')} checked={i.isDisplay} disabled={i.isDefault == true} />
                                                                        ))}
                                                                    </ChecksGroup>
                                                                </FormGroup>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown> */}
                                        <Dropdown isButtonGroup>
                                            <DropdownToggle>
                                                <Button color='primary' isLight icon='CloudDownload'> Export </Button>
                                            </DropdownToggle>
                                            <DropdownMenu isAlignmentEnd>
                                                <DropdownItem>
                                                    <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel("Route Wise Traveller List", routeWiseTravellerListData, columnVisibilityData)}> Excel </Button>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(routeWiseTravellerListData, columnVisibilityData, "Route Wise Traveller List")}> PDF
                                                    </Button>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile("Route Wise Traveller List", routeWiseTravellerListData, columnVisibilityData)}> CSV </Button>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <Button color='primary' isLight icon='Print' onClick={handlePrint} > Print </Button>
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>

                                    </CardActions>
                                </CardHeader>
                                <CardBody className='table-responsive'>
                                    <div className='row g-4 p-3'>
                                        <div className='col-3'>
                                            <div
                                                className={classNames(
                                                    'd-flex align-items-center rounded-2 p-1',
                                                    {
                                                        'bg-l10-info': !darkModeStatus,
                                                        'bg-lo25-info': darkModeStatus,
                                                    },
                                                )}>
                                                <div className='flex-shrink-0'>
                                                    <Icon
                                                        icon='AddRoad'
                                                        size='2x'
                                                        color='info'
                                                    />
                                                </div>
                                                <div className='flex-grow-1 ms-1'>
                                                    <div className='fw-bold fs-7 mb-0 text-nowrap'>{routeNumber} - {routeName}</div>
                                                    <div className='text-muted mt-n2'>Route</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-1'>
                                            <div
                                                className={classNames(
                                                    'd-flex align-items-center rounded-2 p-1',
                                                    {
                                                        'bg-l10-info': !darkModeStatus,
                                                        'bg-lo25-info': darkModeStatus,
                                                    },
                                                )}>
                                                <div className='flex-shrink-0'>
                                                    <Icon icon='Person' size='2x' color='info' />
                                                </div>
                                                <div className='flex-grow-1 ms-1'>
                                                    <div className='fw-bold fs-6 mb-0'>{totalCount}</div>
                                                    <div className='text-muted mt-n1'>Total</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-2'>
                                            <div
                                                className={classNames(
                                                    'd-flex align-items-center rounded-2 p-1',
                                                    {
                                                        'bg-l10-info': !darkModeStatus,
                                                        'bg-lo25-info': darkModeStatus,
                                                    },
                                                )}>
                                                <div className='flex-shrink-0'>
                                                    <Icon icon='Person' size='2x' color='info' />
                                                </div>
                                                <div className='flex-grow-1 ms-2'>
                                                    <div className='fw-bold fs-6 mb-0'>{studentCount}</div>
                                                    <div className='text-muted mt-n2'>Student</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-2'>
                                            <div
                                                className={classNames(
                                                    'd-flex align-items-center rounded-2 p-1',
                                                    {
                                                        'bg-l10-info': !darkModeStatus,
                                                        'bg-lo25-info': darkModeStatus,
                                                    },
                                                )}>
                                                <div className='flex-shrink-0'>
                                                    <Icon icon='Person' size='2x' color='info' />
                                                </div>
                                                <div className='flex-grow-1 ms-2'>
                                                    <div className='fw-bold fs-6 mb-0'>{staffCount}</div>
                                                    <div className='text-muted mt-n2'>Staff</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-2'>
                                            <div
                                                className={classNames(
                                                    'd-flex align-items-center rounded-2 p-1',
                                                    {
                                                        'bg-l10-success': !darkModeStatus,
                                                        'bg-lo25-success': darkModeStatus,
                                                    },
                                                )}>
                                                <div className='flex-shrink-0'>
                                                    <Icon icon='Male' size='2x' color='success' />
                                                </div>
                                                <div className='flex-grow-1 ms-2'>
                                                    <div className='fw-bold fs-6 mb-0'>{maleCount}</div>
                                                    <div className='text-muted mt-n2'>Male </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-2'>
                                            <div
                                                className={classNames(
                                                    'd-flex align-items-center rounded-2 p-1',
                                                    {
                                                        'bg-l10-danger': !darkModeStatus,
                                                        'bg-lo25-danger': darkModeStatus,
                                                    },
                                                )}>
                                                <div className='flex-shrink-0'>
                                                    <Icon icon='Female' size='2x' color='danger' />
                                                </div>
                                                <div className='flex-grow-1 ms-2'>
                                                    <div className='fw-bold fs-6 mb-0'>{femaleCount}</div>
                                                    <div className='text-muted mt-n2'>Female</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className='g-4'>
                                        <table className="table text-nowrap text-md-nowrap table-bordered mg-b-0">
                                            <thead  >
                                                <tr>
                                                    <th className="text-center bg-primary text-white" style={{ width: "0%" }} >Route : {routeNumber} - {routeName}</th>
                                                    <th className="text-center bg-primary text-white" style={{ width: "2%" }} >Total : {totalCount}</th>
                                                    <th className="text-center bg-primary text-white" style={{ width: "2%" }} >Student : {studentCount}</th>
                                                    <th className="text-center bg-primary text-white" style={{ width: "2%" }} >Staff : {staffCount}</th>
                                                    <th className="text-center bg-primary text-white" style={{ width: "2%" }} >Male : {maleCount}</th>
                                                    <th className="text-center bg-primary text-white" style={{ width: "2%" }} >Female : {femaleCount}</th>
                                                </tr>
                                            </thead>
                                        </table>
                                    </div> */}
                                    <table className="table table-modern table-hover  text-wrap mg-b-0" >
                                        <thead>
                                            <tr className='table-primary'>
                                                <th scope='col' style={{ width: '2rem' }}
                                                    className='cursor-pointer text-decoration-underline text-center'>S.No{' '}
                                                </th>
                                                <th scope='col' style={{ width: '3rem' }}
                                                    className='cursor-pointer text-decoration-underline text-center'>TYPE{' '}
                                                </th>
                                                <th scope='col' style={{ width: '3rem' }}
                                                    className='cursor-pointer text-decoration-underline text-center'>ID{' '}
                                                </th>
                                                <th scope='col' style={{ width: '3rem' }}
                                                    className='cursor-pointer text-decoration-underline text-center'>NAME{' '}
                                                </th>
                                                <th scope='col' style={{ width: '3rem' }}
                                                    className='cursor-pointer text-decoration-underline text-center'>AREA{' '}
                                                </th>
                                                <th scope='col' style={{ width: '3rem' }}
                                                    className='cursor-pointer text-decoration-underline text-center'>MOBILE{' '}
                                                </th>
                                                <th scope='col'
                                                    className='cursor-pointer text-decoration-underline text-center'>ADDRESS{' '}
                                                </th>
                                                {/* {columnVisibilityData.map((column: any) => (
                                                    <th key={column.keyName} scope='col'
                                                        className='cursor-pointer text-decoration-underline text-center'>
                                                        {column.columnName}
                                                    </th>
                                                ))} */}
                                                <th scope='col' style={{ width: '6rem' }}
                                                    className='cursor-pointer text-decoration-underline text-center '>Photo{' '}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {routeWiseTravellerListData != "" ?
                                                <>
                                                    {filteredData.map((rowData: any) => (
                                                        <tr key={rowData.studentOrStaffDetailsId}>
                                                            <td  style={{ width: '2rem' }}>{rowData.sno}</td>
                                                            <td style={{ width: '3rem' }}>{rowData.userType}</td>
                                                            <td  style={{ width: '3rem' }}>{rowData.travellerId}</td>
                                                            <td  style={{ width: '3rem' }}>{rowData.studentOrStaffName}<br />({rowData.gender})</td>
                                                            <td  style={{ width: '3rem' }}>{rowData.areaName}</td>
                                                            <td  style={{ width: '3rem' }}>{rowData.contactNo}</td>
                                                            <td className='text-center' >{rowData.address}</td>
                                                            {/* {columnVisibilityData.map((column: any) => (
                                                        (column.isDisplay && rowData[column.keyName]) ?
                                                            <td className=' text-wrap' style={{ width: '6rem' }} key={`${rowData.studentOrStaffDetailsId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />
                                                    ))} */}
                                                            <td className='text-center'  style={{ width: '6rem' }}>
                                                                {rowData.profilePath ?
                                                                    <img src={rowData.profilePath} className=' rounded-0' height={50} width={50} onError={(e: any) => pictNotLoading(e, rowData.genderId)} />
                                                                    :
                                                                    <img src={profilePic(rowData.genderId)} className=' rounded-0' height={50} width={50} />
                                                                }
                                                            </td>
                                                        </tr>
                                                    ))
                                                    } </>
                                                :
                                                <NoDataMsg columnsCount={columnVisibilityData.length + 2} msg={noDataMsg} />}
                                        </tbody>
                                    </table>
                                </CardBody>
                            </Card>
                        </> : null}
                </Page>
            </PageWrapper>
        </>

    )
}

export default RouteWistStudentList
