import { AxiosResponse } from "axios";
import service from "../api/api.services";
import { getAPIURL, getLicenseKey } from "./application.settings";

const api = service()

export const getTrustList = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getTrustList/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getTrustList - " + error.message)
            console.log('error caught in service : getTrustList')
        },
    );
    
    export const getSchoolDetails = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>
    
    api.get(getAPIURL() + `getSchoolDetails/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getSchoolDetails - " + error.message)
            console.log('error caught in service : getSchoolDetails')
        },
    );
    
    export const updateSchoolDetails = (updateSchoolDetailsPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>
    
    api.post(getAPIURL() + `updateSchoolDetails`, updateSchoolDetailsPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateSchoolDetails')
        }
    );