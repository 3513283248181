import { AxiosResponse } from "axios";
import service from "../api/api.services";
import { getAPIURL, getLicenseKey } from "./application.settings";

const api = service()
export const getVehicleTyreDetails = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getVehicleTyreDetails/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getVehicleTyreDetails - " + error.message)
            console.log('error caught in service : getVehicleTyreDetails')
        },
    );
    export const addVehicleTyreDetails = (addTyerPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addVehicleTyreDetails`, addTyerPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addVehicleTyreDetails')
        }
    );
    export const updateVehicleTyreDetails = (updateTyrePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateVehicleTyreDetails`, updateTyrePostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateVehicleTyreDetails')
        }
    );
    export const getVehicleTyreServiceList = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getVehicleTyreServiceList/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getVehicleTyreServiceList - " + error.message)
            console.log('error caught in service : getVehicleTyreServiceList')
        },
    );
    export const addVehicleTyreService = (vehicleTyreServicePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addVehicleTyreService`, vehicleTyreServicePostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addVehicleTyreService')
        }
    );
    export const getVehicleTyreServiceLists = (fromDate: number, toDate: number,success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getVehicleTyreServiceList/${getLicenseKey}/${fromDate}/${toDate}/0`,
        response => {
            success(response)
        },
        error => {
            failure("getVehicleTyreServiceList - " + error.message)
            console.log('error caught in service : getVehicleTyreServiceList')
        },
    );
    export const updateVehicleTyreService = (updateTyreServiceListPost: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateVehicleTyreService`, updateTyreServiceListPost,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateVehicleTyreService')
        }
    );

    export const addVehicleTyreLink = (addVehicleTyreLinkPost: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addVehicleTyreLink`, addVehicleTyreLinkPost,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addVehicleTyreLink')
        }
    );
    export const getVehicleTyreLink = (vehicleDetailsId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getVehicleTyreLink/${getLicenseKey}/${vehicleDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getVehicleTyreLink - " + error.message)
            console.log('error caught in service : getVehicleTyreLink')
        },
    );