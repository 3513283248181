import { useContext, useEffect, useRef, useState } from "react";
import useDarkMode from "../../../hooks/useDarkMode";
import useSortableData from "../../../hooks/useSortableData";
import PaginationButtons, { dataPagination } from "../../PaginationButtons";
import { useFormik } from "formik";
import Button, { ButtonGroup } from "../../bootstrap/Button";
import { toasts } from "../../../services/toast.service";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import Page from "../../../layout/Page/Page";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../../bootstrap/Card";
import Icon from "../../icon/Icon";
import Input from "../../bootstrap/forms/Input";
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from "../../bootstrap/Dropdown";
import FormGroup from "../../bootstrap/forms/FormGroup";
import Checks, { ChecksGroup } from "../../bootstrap/forms/Checks";
import { convertJsonToPdf, downloadExcel, downloadFile } from "../../../services/ExportService";
import { addRouteArea, deleteRouteArea, getRouteList, getTransportAreaMaster, updateRouteMaster } from "../../../services/transport.service";
import AuthContext from "../../../contexts/authContext";
import { getCity, getColumnsForDataTable, getStates, updateColumnsForDataTable, updateFilter } from "../../../services/common.service";
import { getLicenseKey } from "../../../services/application.settings";
import { useReactToPrint } from "react-to-print";
import AlertService from "../../../services/AlertService";
import { TableLoader, showLoader } from "../../../services/loader.services";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "../../bootstrap/Modal";
import Select from "../../bootstrap/forms/Select";
import NoDataMsg from "../../../common/components/NoDataMsg";
import { StackedBarChart } from "../../icon/material-icons";
import SearchableSelect from "../../../common/components/SearchableSelect";

const RouteList = () => {

    useEffect(() => {
        getRoute()
        getStatesList()
        getTransportArea()
    }, [])

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const [routeListData, setRouteListData] = useState([])
    const [columnVisibilityData, setColumnVisibilityData] = useState([]);
    const [allColumnsData, setAllColumnsData] = useState([]);
    const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
    const [columnDataById, setColumnDataById] = useState([])
    const componentRef = useRef(null);
    const [isChecked, setIsChecked] = useState(false)
    const [dataStatus, setDataStatus] = useState(false)
    const [stateId, setStateId] = useState<any>('')
    const [cityData, setCityData] = useState<any>([])
    const [cityId, setCityId] = useState<any>('')
    const [statesData, setStatesData] = useState<any>([])
    const [transportAreaData, setTransportAreaData] = useState<any>([]);
    const [routeArea, setRouteArea] = useState<any>([])
    const [routeAreaData, setRouteAreaData] = useState<any>([])
    const [addRouteAreaArray, setAddRouteAreaArray] = useState<any>([])

    // ForModal And Tables States
    const { themeStatus, darkModeStatus } = useDarkMode();
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(routeListData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);

    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);

    const [editRouteMasterModal, setEditRouteMasterModal] = useState(false)
    const [deleteRouteMasterModal, setDeleteRouteMasterModal] = useState(false)
    const [refreshData, setRefreshData] = useState<any>([])
    const [routeAreaId, setRouteAreaId] = useState<any>('')
    const [showAddRouteArea, setShowAddRouteArea] = useState<any>(false)

    const [noDataMsg, setNoDataMsg] = useState('')
    const [newTransportAreaMasterId, setNewTransportAreaMasterId] = useState<any>('')

    const routeMasterForm = useFormik({

        enableReinitialize: true,
        initialValues: {
            routeMasterId: '',
            pointNumber: "",
            routeNumber: "",
            routeName: '',
            stateId: '',
            cityId: '',
        },
        validate: (values) => {
            const errors: {
                routeNumber?: string;
                routeName?: string;
                stateId?: string;
                cityId?: string;
            } = {};
            if (!values.routeNumber) {
                errors.routeNumber = 'Required';
            }
            if (!values.routeName) {
                errors.routeName = 'Required';
            }
            if (!stateId) {
                errors.stateId = 'Required';
            }
            if (!cityId) {
                errors.cityId = 'Required';
            }
            return errors;
        },
        validateOnChange: true,
        onSubmit: () => { updateRouteMasterSubmit() },
    });

    const addRouteAreaForm = useFormik({

        enableReinitialize: true,
        initialValues: {
            pointNumber: "",
            transportAreaMasterId: "",
        },
        validate: (values) => { },
        validateOnChange: true,
        onSubmit: () => { updateRouteMasterSubmit() },
    });

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },

        //validateOnChange: false,
        onSubmit: () => { },
    });

    // Filter
    const filteredData = updateFilter(onCurrentPageData, columnVisibilityData, columnVisibilityForm);

    const selectState = (e: any) => {
        let stateId = e
        setStateId(stateId)
        if (stateId?.value != undefined) {
            getCityList(stateId?.value)
        }
    }

    const selectCity = (e: any) => {
        let cityId = e
        setCityId(cityId)
    }

    function onActivate(i: any) {
        if (i != undefined) {
            let selectedRouteMaster = i
            setRefreshData(selectedRouteMaster.routeArea)
            setStateId({ value: selectedRouteMaster.stateId, label: selectedRouteMaster.stateName })
            setCityId({ value: selectedRouteMaster.cityId, label: selectedRouteMaster.cityName })
            setRouteAreaData(selectedRouteMaster.routeArea)


            setEditRouteMasterModal(true)
            routeMasterForm.setValues({
                routeMasterId: selectedRouteMaster.routeMasterId,
                pointNumber: selectedRouteMaster.pointNumber,
                routeNumber: selectedRouteMaster.routeNumber,
                routeName: selectedRouteMaster.routeName,
                stateId: stateId?.value,
                cityId: cityId?.value,
            })
            getCityList(selectedRouteMaster.stateId)
        }
    }

    function onActiveForDeleteRouteMaster(i: any) {
        let selectedRouteArea = i
        setRouteAreaId(selectedRouteArea.routeAreaId)
        setDeleteRouteMasterModal(true)

    }

    function getStatesList() {
        getStates(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.statesAndCity;
                    if (data != undefined) {
                        setStatesData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setStatesData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getCityList(stateId: number) {
        getCity(stateId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.statesAndCity[0]['city'];
                    if (data != undefined) {
                        setCityData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setCityData([]);
                } else {

                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getRoute() {
        setDataStatus(false)
        getRouteList(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.routeList;
                    if (data != undefined) {
                        setDataStatus(true)
                        setRouteListData(data);
                        getColumnsForTable('getRouteList', 'get');
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                            for (let j = 0; j < data[i].routeArea.length; j++) {
                                data[i].routeArea[j].transportAreaMasterId = { value: data[i].routeArea[j].transportAreaMasterId, label: data[i].routeArea[j].areaName }
                            }
                        }

                    } else {
                        setDataStatus(true)
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setDataStatus(true)
                    //toasts(response.data.message, "Error")
                    getColumnsForTable('getRouteList', 'get');
                    setRouteListData([]);
                    setNoDataMsg(response.data.message)
                } else {
                    setDataStatus(true)
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                setDataStatus(true)
                toasts(error, "Error")
            }
        )
    }

    function getTransportArea() {
        getTransportAreaMaster(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.transportAreaMaster;
                    if (data != undefined) {
                        setTransportAreaData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setTransportAreaData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    const updateRouteAreaPointNumber = (e: any, routeAreaId: number, transportAreaMasterId: number) => {
        const newData: any = [];

        refreshData.forEach((item: any, index: any) => {
            if (item['routeAreaId'] == routeAreaId) {
                item['pointNumber'] = e.target.value
            }
            newData.push(item)
        });

        setRefreshData(newData)

        if (routeArea.length == 0) {
            const obj = {
                routeAreaId: routeAreaId,
                pointNumber: e.target.value,
                transportAreaMasterId: transportAreaMasterId
            };
            routeArea.push(obj);
        } else {
            let selectedItem: any;
            routeArea.forEach((item: any, index: any) => {
                if (item['routeAreaId'] == routeAreaId) {
                    selectedItem = item
                    selectedItem['pointNumber'] = e.target.value
                }
            });
            if (selectedItem != undefined) {
                selectedItem['pointNumber'] = e.target.value
            } else {
                var obj
                obj = {
                    routeAreaId: routeAreaId,
                    pointNumber: e.target.value,
                    transportAreaMasterId: transportAreaMasterId
                };

                routeArea.push(obj);
            }
        }
    }

    const updateRouteArea = (transportAreaMasterId: any, pointNumber: number, routeAreaId: number) => {
        const newData: any = [];

        refreshData.forEach((item: any, index: any) => {
            if (item['routeAreaId'] == routeAreaId) {
                item['transportAreaMasterId'] = transportAreaMasterId
            }
            newData.push(item)
        });

        setRefreshData(newData)

        if (routeArea.length == 0) {
            const obj = {
                routeAreaId: routeAreaId,
                pointNumber: pointNumber,
                transportAreaMasterId: transportAreaMasterId?.value
            };
            routeArea.push(obj);
        } else {
            let selectedItem: any;
            routeArea.forEach((item: any, index: any) => {
                if (item['routeAreaId'] == routeAreaId) {
                    selectedItem = item
                    selectedItem['transportAreaMasterId'] = transportAreaMasterId?.value
                }
            });
            if (selectedItem != undefined) {
                selectedItem['pointNumber'] = pointNumber
            } else {
                var obj

                obj = {
                    routeAreaId: routeAreaId,
                    pointNumber: pointNumber,
                    transportAreaMasterId: transportAreaMasterId?.value
                };

                routeArea.push(obj);
            }
        }
    }

    function setRouteMasterEditDetails() {
        return {
            routeMasterId: routeMasterForm.values.routeMasterId,
            routeNumber: routeMasterForm.values.routeNumber,
            routeName: routeMasterForm.values.routeName,
            stateId: stateId?.value,
            cityId: cityId?.value,
            routeArea: routeArea,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId,
        }
    }

    function setDeleteRouteArea() {
        return {
            routeAreaId: routeAreaId,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId,
        }
    }

    function updateRouteMasterSubmit() {
        // showLoader(true)
        if (routeMasterForm.isValid) {

            let routeMasterPostData = setRouteMasterEditDetails()
            updateRouteMaster(routeMasterPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {

                        if (addRouteAreaArray.length > 0) {
                            addRouteAreaSubmit()
                        } else {
                            showLoader(false)
                            setAlertStatus({ message: data.message, type: "success" });
                            setIsOpen(true);
                            closeAndReset()
                            getRoute();
                        }

                    }
                    else if (data.success == false) {

                        if (addRouteAreaArray.length > 0) {
                            addRouteAreaSubmit()
                        } else {
                            showLoader(false)
                            setAlertStatus({ message: data.message, type: "error" });
                            setIsOpen(true);
                        }
                    }
                    else {
                        showLoader(false)
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (routeMasterForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function deleteRouteMasterSubmit() {
        showLoader(true)
        // if (routeMasterForm.isValid) {
        let routeMasterPostData = setDeleteRouteArea()
        deleteRouteArea(routeMasterPostData,
            (response) => {
                const data = response.data;
                if (data.success == true) {
                    showLoader(false)
                    setAlertStatus({ message: data.message, type: "success" });
                    setIsOpen(true);
                    getRoute();
                    closeAndReset()
                }
                else if (data.success == false) {
                    showLoader(false)
                    setAlertStatus({ message: data.message, type: "error" });
                    setIsOpen(true);
                }
                else {
                    showLoader(false)
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setAlertStatus({ message: errorDescription, type: "error" });
                    setIsOpen(true);
                }
            }
            , (error) => {
                showLoader(false)
                setAlertStatus({ message: error, type: "error" });
                setIsOpen(true);
            }
        )

    }

    function getColumnsForTable(apiName: any, type: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setColumnVisibilityData(columnVisibilityData)

                        const allData = allColumnsData.map((item: any) => item.isDisplay);
                        const allColumns = allData.filter((item: any) => item == false);

                        if (type == 'get') {                           
                            setIsChecked(allColumns == '' ? true : false);
                        }
                        else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

                            for (let i = 0; i < nonDefaultValue.length; i++) {

                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: allColumns == '' ? 0 : 1
                                }
                                all.push(obj);
                            }
                            updateColumnsSubmit('', '', all)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {

        if (columnVisibilityForm.isValid) {

            setColumnDataById(isDisplay)
            const columnVisibilityPostData = {
                licenseKey: getLicenseKey,
                userAccountId: userAccountId,
                columns: type ? type : [
                    {
                        columnVisibilityId: columnVisibilityId,
                        isDisplay: isDisplay ? 0 : 1
                    }
                ]
            }

            updateColumnsForDataTable(columnVisibilityPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        getColumnsForTable('getRouteList', 'get');
                    }
                    else if (data.success == false) {
                        // toasts(data.success, "Error")
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        toasts(errorDescription, "Error")
                    }
                }
                , (error) => {
                    toasts(error, "Error")
                }
            )
        } else if (columnVisibilityForm.isValid == false) {
            toasts("Please fill all the details!", "Error")
        }
    }

    function closeAndReset() {
        routeMasterForm.resetForm();
        setEditRouteMasterModal(false);
        setDeleteRouteMasterModal(false);
        setRouteArea([]);
        handleRemove();
    }

    // print
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    function addRouteAreaPointNumberAndAreaMasterId() {
        if (addRouteAreaArray.length == 0) {
            var obj
            obj = {
                pointNumber: addRouteAreaForm.values.pointNumber,
                transportAreaMasterId: newTransportAreaMasterId?.value,
            };
            addRouteAreaArray.push(obj);
        } else {
            let selectedItem: any
            addRouteAreaArray.forEach((item: any, index: number) => {
                if (item['pointNumber'] == addRouteAreaForm.values.pointNumber) {
                    selectedItem = item
                }
            });
            if (selectedItem != undefined) {
                selectedItem['pointNumber'] = addRouteAreaForm.values.pointNumber
                selectedItem['transportAreaMasterId'] = newTransportAreaMasterId?.value
            } else {
                var obj
                obj = {
                    pointNumber: addRouteAreaForm.values.pointNumber,
                    transportAreaMasterId: newTransportAreaMasterId?.value,
                };
                addRouteAreaArray.push(obj);
            }
        }
    }

    function setAddRouteAreaDetails() {
        return {
            routeMasterId: routeMasterForm.values.routeMasterId,
            routeArea: addRouteAreaArray,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId,
        }
    }

    function addRouteAreaSubmit() {
        showLoader(true)
        if (addRouteAreaArray.length > 0) {
            let routeAreaPostData = setAddRouteAreaDetails()
            addRouteArea(routeAreaPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {

                        if (routeArea.length > 0) {
                            showLoader(false)
                            setAlertStatus({ message: 'Route Area Updated And Route Area Added', type: "success" });
                            setIsOpen(true);
                            getRoute();
                            handleRemove();
                            setEditRouteMasterModal(false);
                        } else {
                            showLoader(false)
                            setAlertStatus({ message: data.message, type: "success" });
                            setIsOpen(true);
                            getRoute();
                            handleRemove();
                            setEditRouteMasterModal(false);
                        }

                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        showLoader(false)
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (addRouteAreaArray.length == 0) {
            showLoader(false)
        }
    }

    function addNewRoute() {
        setShowAddRouteArea(true)
    }

    function handleRemove() {
        setShowAddRouteArea(false)
        setAddRouteAreaArray([])
        addRouteAreaForm.resetForm()
        setNewTransportAreaMasterId('')
    }

    return (

        <PageWrapper title={`Route List`}>
            <Page container='fluid'>
                <Card stretch data-tour='list' ref={componentRef} id='pdf'>
                    <CardHeader borderSize={1}>
                        <CardLabel icon='List' iconColor='info' className="col-lg-5">
                            <CardTitle tag='div' className='h5'> Route List</CardTitle>
                        </CardLabel>

                        <ButtonGroup className="col-lg-3 d-print-none" color='primary'>
                            <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                            <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
                        </ButtonGroup>

                        <CardActions className="d-print-none">
                            <Dropdown isOpen={columnVisibilityMenu} setIsOpen={setColumnVisibilityMenu} isButtonGroup>
                                <DropdownToggle>
                                    <Button icon='FilterAlt' color='primary' isLight>Filter</Button>
                                </DropdownToggle>
                                <DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
                                    <DropdownItem>
                                        <div className='container py-2'>
                                            <form className='row g-3'>
                                                <div className='col-12'>
                                                    <FormGroup>
                                                        <h6>Select All</h6>
                                                        <Checks id='available' type='switch' label='Select All Columns' onChange={() => getColumnsForTable('getRouteList', 'post')} checked={isChecked} ariaLabel='Available status' />
                                                    </FormGroup>
                                                </div>
                                                <div className='col-12' style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                                                    <FormGroup>
                                                        <h6>Columns</h6>
                                                        <ChecksGroup>
                                                            {allColumnsData.map((i: any) => (
                                                                <Checks key={i.columnVisibilityId} label={i.columnName} onChange={() => updateColumnsSubmit(i.columnVisibilityId, i.isDisplay, '')} checked={i.isDisplay} disabled={i.isDefault == true} />
                                                            ))}
                                                        </ChecksGroup>
                                                    </FormGroup>
                                                </div>
                                            </form>
                                        </div>
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                            <Dropdown isButtonGroup>
                                <DropdownToggle>
                                    <Button color='primary' isLight icon='CloudDownload'> Export </Button>
                                </DropdownToggle>
                                <DropdownMenu isAlignmentEnd>
                                    <DropdownItem>
                                        <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel("Route List", routeListData, columnVisibilityData)}> Excel </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(routeListData, columnVisibilityData, "Route List")}> PDF
                                        </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile("Route List", routeListData, columnVisibilityData)}> CSV </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <Button color='primary' isLight icon='Print' onClick={handlePrint}> Print </Button>
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                            <Button color='primary' isLight icon='Add' tag='a'
                                to={`../management/routeMaster`} >
                                Add New
                            </Button>
                        </CardActions>
                    </CardHeader>

                    <CardBody className='table-responsive' isScrollable>

                        {!dataStatus ?
                            <TableLoader /> :
                            <table className='table table-modern table-hover text-nowrap'>
                                <thead>
                                    <tr>
                                        <th scope='col' onClick={() => requestSort(`sno`)}
                                            className='cursor-pointer text-decoration-underline'>S.No
                                            <Icon size='lg' className={getClassNamesFor(`sno`)} icon='FilterList' />
                                        </th>
                                        {columnVisibilityData.map((column: any) => (
                                            <th key={column.keyName} scope='col' onClick={() => requestSort(column.keyName)}
                                                className='cursor-pointer text-decoration-underline'>
                                                {column.columnName}
                                                <Icon size='lg' className={getClassNamesFor(column.keyName)} icon='FilterList' />
                                            </th>
                                        ))}
                                        <th scope='col' className='d-print-none'>
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredData != "" ? <>{filteredData.map((rowData: any) => (<tr key={rowData.routeMasterId}><td>{rowData.sno}</td>{columnVisibilityData.map((column: any) => ((column.isDisplay && rowData[column.keyName]) ? <td key={`${rowData.routeMasterId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />))}<td className="d-print-none"><Button color='dark' isLight icon='Edit' aria-label='Edit' onClick={() => onActivate(rowData)} /></td></tr>))}</> :
                                        <NoDataMsg columnsCount={columnVisibilityData.length + 2} msg={noDataMsg} />}
                                </tbody>
                            </table>
                        }
                    </CardBody>
                    <PaginationButtons className='d-print-none' data={items} label='items' setCurrentPage={setCurrentPage} currentPage={currentPage} perPage={perPage} setPerPage={setPerPage} />
                </Card>
            </Page>

            <Modal
                setIsOpen={setEditRouteMasterModal}
                isOpen={editRouteMasterModal}
                titleId='editRouteMasterModal'
                isCentered
                isStaticBackdrop
                size='lg'>

                <ModalHeader className="'modal-header'"
                    setIsOpen={() => { setEditRouteMasterModal(false) }} onClick={closeAndReset}>
                    <ModalTitle id='addTempStudent'>Update Route Master</ModalTitle>
                </ModalHeader>
                <form noValidate onSubmit={routeMasterForm.handleSubmit}>
                    <ModalBody className='row px-4'>
                        <div className='col-md-4'>
                            <Card stretch>
                                <CardHeader borderSize={1}>
                                    <CardLabel icon='list' iconColor='primary'>
                                        <CardTitle>
                                            Route Information
                                        </CardTitle>
                                    </CardLabel>
                                </CardHeader>
                                <CardBody>
                                    <div className='row g-4'>
                                        <div className='col-12'>
                                            <FormGroup id='routeNumber' label='Route Number' isFloating>
                                                <Input
                                                    placeholder='Route Number'
                                                    onBlur={routeMasterForm.handleBlur}
                                                    onChange={routeMasterForm.handleChange}
                                                    value={routeMasterForm.values.routeNumber}
                                                    isValid={routeMasterForm.isValid}
                                                    isTouched={routeMasterForm.touched.routeNumber}
                                                    invalidFeedback={routeMasterForm.errors.routeNumber}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className='col-12'>
                                            <FormGroup id='routeName' label='Route Name' isFloating >
                                                <Input
                                                    placeholder='Route Name'
                                                    onBlur={routeMasterForm.handleBlur}
                                                    onChange={routeMasterForm.handleChange}
                                                    value={routeMasterForm.values.routeName}
                                                    isValid={routeMasterForm.isValid}
                                                    isTouched={routeMasterForm.touched.routeName}
                                                    invalidFeedback={routeMasterForm.errors.routeName}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className='col-12'>
                                            <FormGroup id='stateId' label='State' isFloating>
                                                <SearchableSelect isFloating
                                                    ariaLabel='State'
                                                    onChange={selectState}
                                                    onBlur={routeMasterForm.handleBlur}
                                                    value={stateId}
                                                    list={statesData.map((data: any) => (
                                                        { value: data.stateId, label: data.stateName }
                                                    ))}
                                                    required />
                                            </FormGroup>
                                        </div>

                                        <div className='col-12'>
                                            <FormGroup id='cityId' label='City' isFloating>
                                                <SearchableSelect isFloating
                                                    ariaLabel='City'
                                                    onChange={selectCity}
                                                    onBlur={routeMasterForm.handleBlur}
                                                    value={cityId}
                                                    list={cityData.map((data: any) => (
                                                        { value: data.cityId, label: data.cityName }
                                                    ))}
                                                    required />
                                            </FormGroup>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                        <div className='col-md-8'>
                            <Card stretch>
                                <CardHeader borderSize={1}>
                                    <CardLabel icon='AddLocationAlt' iconColor='primary'>
                                        <CardTitle>
                                            Area Location
                                        </CardTitle>
                                    </CardLabel>
                                </CardHeader>
                                <CardBody>
                                    <div className="row">
                                        <div className="col-lg-12">

                                            <table className="table table-modern table-hover text-nowrap text-md-nowrap mg-b-0">
                                                <thead>
                                                    <tr className='text-center table-primary fs-6'>
                                                        <th>Point Number</th>
                                                        <th>Area</th>
                                                        <th>Delete</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {routeAreaData.map((rowData: any) => (
                                                        <tr key={rowData.routeAreaId}>
                                                            <td width="5px" >
                                                                <Input className="text-center" value={rowData.pointNumber} onInput={(e: any) => { updateRouteAreaPointNumber(e, rowData.routeAreaId, rowData.transportAreaMasterId) }} />
                                                            </td>
                                                            <td>
                                                                <div className='col-12'>
                                                                    <FormGroup id="transportAreaMasterId">
                                                                        <SearchableSelect
                                                                            ariaLabel='Select Area'
                                                                            placeholder="Select Area"
                                                                            onChange={(e: any) => updateRouteArea(e, rowData.pointNumber, rowData.routeAreaId)}
                                                                            value={rowData.transportAreaMasterId}
                                                                            list={transportAreaData.map((data: any) => (
                                                                                { value: data.transportAreaMasterId, label: data.areaName }
                                                                            ))}
                                                                        />
                                                                    </FormGroup>
                                                                </div>
                                                            </td>
                                                            <td className='text-center align-middle'>
                                                                <Button color='danger' isLight icon='Delete' onClick={() => { onActiveForDeleteRouteMaster(rowData) }} />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                                {showAddRouteArea ?
                                                    <tbody>
                                                        <tr>
                                                            <td width="5px" className='text-center'>
                                                                <FormGroup id="pointNumber">
                                                                    <Input className="text-center"
                                                                        onBlur={addRouteAreaForm.handleBlur}
                                                                        onChange={addRouteAreaForm.handleChange}
                                                                        value={addRouteAreaForm.values.pointNumber}
                                                                        isValid={addRouteAreaForm.isValid}
                                                                        isTouched={addRouteAreaForm.touched.pointNumber}
                                                                        invalidFeedback={addRouteAreaForm.errors.pointNumber} />
                                                                </FormGroup>
                                                            </td>
                                                            <td>
                                                                <div className='col-12'>
                                                                    <FormGroup id="transportAreaMasterId">
                                                                        <SearchableSelect
                                                                            ariaLabel=''
                                                                            placeholder="Select"
                                                                            onChange={(e: any) => setNewTransportAreaMasterId(e)}
                                                                            onBlur={() => addRouteAreaPointNumberAndAreaMasterId()}
                                                                            value={newTransportAreaMasterId}
                                                                            list={transportAreaData.map((data: any) => (
                                                                                { value: data.transportAreaMasterId, label: data.areaName }
                                                                            ))}
                                                                        />
                                                                    </FormGroup>
                                                                </div>
                                                            </td>
                                                            <td className='text-center align-middle'>
                                                                <Button color='danger' isLight icon='Close' onClick={() => handleRemove()} />

                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                    : null}
                                            </table>
                                        </div>
                                        {!showAddRouteArea ?
                                            <div className='d-flex justify-content-end'>
                                                <Button color='primary' isLight icon='Add' onClick={addNewRoute}>
                                                    Add Route Area
                                                </Button>
                                            </div> : null}
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                        {/* <div className='row'>
                            <div className="d-flex justify-content-center position-absolute top-65 start-50 translate-middle">
                                <DeleteComponents isDeleteOpen={deleteRouteMasterModal} setIsDeleteOpen={setDeleteRouteMasterModal} deleteFunction={deleteRouteMasterSubmit} />
                            </div>
                        </div> */}
                    </ModalBody>
                    <ModalFooter>
                        <Button color='info' isOutline className='border-0' onClick={closeAndReset}>Close</Button>
                        <Button color='info' icon='Save' type="submit" isDisable={!routeMasterForm.isValid && !!routeMasterForm.submitCount}>Update </Button>
                    </ModalFooter>
                </form>
            </Modal>

            <Modal
                setIsOpen={setDeleteRouteMasterModal}
                isOpen={deleteRouteMasterModal}
                titleId='deleteRouteMasterModal'
                isCentered
                isStaticBackdrop
                size='sm'>
                <ModalHeader className="'modal-header'"
                    setIsOpen={() => { setDeleteRouteMasterModal(false) }}>
                    <ModalTitle id='deleteRouteMasterModal'><></></ModalTitle>
                </ModalHeader>
                <ModalBody >
                    <div className="d-flex justify-content-center">
                        <p className="d-flex justify-content-center fw-bold fs-6">
                            Are you sure want to delete ?
                        </p>
                    </div>
                    <div className="d-flex justify-content-center">
                        <Button className="align-self-center" icon="delete" color="danger" onClick={() => { deleteRouteMasterSubmit() }}>Delete</Button>
                    </div>
                </ModalBody>
            </Modal>

            <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />

        </PageWrapper>


    )
}

export default RouteList
