import { AxiosResponse } from "axios";
import service from "../api/api.services";
import { getAPIURL, getLicenseKey } from "./application.settings";

const api = service()

export const getTournamentDetailsList = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getTournamentDetailsList/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getTournamentDetailsList - " + error.message)
            console.log('error caught in service : getTournamentDetailsList')
        },
    );

export const addTournamentName = (tournamentPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addTournamentName`, tournamentPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addTournamentName')
        }
    );

export const updateTournamentName = (tournamentPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateTournamentName`, tournamentPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateTournamentName')
        }
    );

export const deleteTournamentName = (tournamentPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `deleteTournamentName`, tournamentPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : deleteTournamentName')
        }
    );

export const getEventType = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getEventType/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getEventType - " + error.message)
            console.log('error caught in service : getEventType')
        },
    );

export const getTournamentNameList = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getTournamentNameList/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getTournamentNameList - " + error.message)
            console.log('error caught in service : getTournamentNameList')
        },
    );

export const addTournamentDetails = (tournamentPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addTournamentDetails`, tournamentPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addTournamentDetails')
        }
    );

export const updateTournamentDetails = (tournamentPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateTournamentDetails`, tournamentPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateTournamentDetails')
        }
    );

export const deleteTournamentDetails = (deletePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `deleteTournamentDetails`, deletePostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : deleteTournamentDetails')
        }
    );


export const addTournamentParticipants = (tournamentPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addTournamentParticipants`, tournamentPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addTournamentParticipants')
        }
    );

export const getTournamentDetailsListByTournamentDetailsId = (tournamentDetailsId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getTournamentDetailsList/${getLicenseKey}/${tournamentDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getTournamentDetailsList - " + error.message)
            console.log('error caught in service : getTournamentDetailsList')
        },
    );

export const addEventDetails = (tournamentPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addEventDetails`, tournamentPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addEventDetails')
        }
    );

export const getEventList = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getEventList/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getEventList - " + error.message)
            console.log('error caught in service : getEventList')
        },
    );