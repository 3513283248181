import dayjs from 'dayjs';
import { useFormik } from 'formik';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import DeleteComponents from '../../../common/components/DeleteComponents';
import NoDataMsg from '../../../common/components/NoDataMsg';
import OpenCardComponent from '../../../common/components/OpenCardComponent';
import SearchableSelect from '../../../common/components/SearchableSelect';
import AuthContext from '../../../contexts/authContext';
import useDarkMode from '../../../hooks/useDarkMode';
import useSortableData from '../../../hooks/useSortableData';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import AlertService from '../../../services/AlertService';
import { downloadExcel, convertJsonToPdf, downloadFile } from '../../../services/ExportService';
import { getLicenseKey } from '../../../services/application.settings';
import { updateFilter, convertDateToEpoch, getUserType, getColumnsForDataTable, updateColumnsForDataTable } from '../../../services/common.service';
import { showLoader } from '../../../services/loader.services';
import { addStaffLeave, deleteStaffLeave, getLeaveType, getStaffLeaveDetails, getStaffLeaveDetailsRequestHistory, getStaffListByUserTypeId, getStatus, updateStaffLeave } from '../../../services/staff.service';
import { toasts } from '../../../services/toast.service';
import PaginationButtons, { dataPagination } from '../../PaginationButtons';
import Button, { ButtonGroup } from '../../bootstrap/Button';
import Card, { CardHeader, CardLabel, CardTitle, CardActions, CardBody } from '../../bootstrap/Card';
import Collapse from '../../bootstrap/Collapse';
import Dropdown, { DropdownToggle, DropdownMenu, DropdownItem } from '../../bootstrap/Dropdown';
import Modal, { ModalHeader, ModalTitle, ModalBody } from '../../bootstrap/Modal';
import OffCanvas, { OffCanvasHeader, OffCanvasTitle, OffCanvasBody } from '../../bootstrap/OffCanvas';
import Checks, { ChecksGroup } from '../../bootstrap/forms/Checks';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Textarea from '../../bootstrap/forms/Textarea';
import Icon from '../../icon/Icon';
import Input from '../../bootstrap/forms/Input';
import { useParams } from 'react-router-dom';


function AddStaffLeave() {

    const { userType, staffDetails } = useParams();

    useEffect(() => {
        getUserTypeList();
        if (userTypeId != 1) {
            getStaffLeave(userTypeId, userAccountId);
        }
        if (userType != undefined && staffDetails != undefined) {
            getUserTypeList()
            getStaffListByUserType(userType)
            getStaffLeave(userType, staffDetails)
        }
    }, []);

    const addStaffLeaveForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            staffDetailsId: '',
            leaveTypeId: '',
            fromDate: '',
            toDate: '',
            reason: '',
            approvalStaff: '',
        },
        validate: (values) => {
            const errors: {
                staffDetailsId?: string;
                leaveTypeId?: string;
                fromDate?: string;
                toDate?: string;
                reason?: string;
                approvalStaff?: string;
            } = {};
            if (userTypeId == 1) {
                if (!staffDetailsIdForAdd) {
                    errors.staffDetailsId = 'Required';
                }
            }
            if (!leaveTypeId) {
                errors.leaveTypeId = 'Required';
            }
            if (!fromDate) {
                errors.fromDate = 'Required';
            }
            if (!toDate) {
                errors.toDate = 'Required';
            }
            if (!values.reason) {
                errors.reason = 'Required';
            }
            if (!approvalStaff) {
                errors.approvalStaff = 'Required';
            }
            return errors;
        },
        // validateOnChange: true,
        onSubmit: () => {
            addStaffLeaveSubmit();
        },
    });

    const editLeaveForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            staffDetailsId: '',
            fromDate: '',
            toDate: '',
            totalDays: '',
            reason: '',
            approvalStaff: '',
            remarks: '',
            statusId: '',
            leaveTypeId: '',
            staffLeaveDetailsId: '',
        },
        validate: (values) => {
            const errors: {
                staffDetailsId?: string;
                fromDate?: string;
                reason?: string;
                toDate?: string;
                totalDays?: string;
                approvalStaff?: string;
                remarks?: string;
                statusId?: string;
                leaveTypeId?: string;
            } = {};

            if (userTypeId != 1) {
                if (!staffDetailsIdForAdd) {
                    errors.staffDetailsId = 'Required';
                }
            }
            if (!fromDate) {
                errors.fromDate = 'Required';
            }
            if (!toDate) {
                errors.toDate = 'Required';
            }
            if (!values.totalDays) {
                errors.totalDays = '';
            }
            if (!values.reason) {
                errors.reason = 'Required';
            }
            if (statusId?.value != 1) {
                if (!values.remarks) {
                    errors.remarks = 'Required';
                }
            }
            if (!approvalStaff) {
                errors.approvalStaff = 'Required';
            }
            if (!statusId) {
                errors.statusId = 'Required';
            }
            if (!leaveTypeId) {
                errors.leaveTypeId = 'Required';
            }
            return errors;
        },
        // validateOnChange: true,
        onSubmit: () => {
            updateStaffLeaveSubmit();
        },
    });

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },
        validateOnChange: false,
        onSubmit: () => { },
    });

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const { themeStatus, darkModeStatus } = useDarkMode();
    const [isLoader, setIsLoader] = useState(false);
    const [isOpenListCard, setIsOpenListCard] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [addLeaveCanvas, setAddLeaveCanvas] = useState(false);
    const [editLeaveCanvas, setEditLeaveCanvas] = useState(false);
    const [statusModal, setStatusModal] = useState(false);
    const [userTypeData, setUserTypeData] = useState<any>([]);
    const [fromDate, setFromDate] = useState<any>('');
    const [toDate, setToDate] = useState<any>('');
    const [staffDetailsData, setStaffDetailsData] = useState<any>([]);
    const [staffDetailsId, setStaffDetailsId] = useState<any>('');
    const [allStaffData, setAllStaffData] = useState<any>([]);
    const [leaveData, setLeaveData] = useState<any>([]);
    const [statusData, setStatusData] = useState<any>([]);
    const [noDataMsg, setNoDataMsg] = useState('');
    const [staffDetailsIdForAdd, setStaffDetailsIdForAdd] = useState<any>('');
    const [approvalStaff, setApprovalStaff] = useState<any>('');
    const [statusId, setStatusId] = useState<any>('');
    const [duration, setDuration] = useState<any>();
    const [approvalStaffListData, setApprovalStaffListData] = useState<any>([]);
    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: '', type: '' });
    const [isOpen, setIsOpen] = useState(false);
    const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
    const componentRef = useRef(null);
    const { items, requestSort, getClassNamesFor } = useSortableData(leaveData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);
    const [allColumnsData, setAllColumnsData] = useState([]);
    const [columnVisibilityData, setColumnVisibilityData] = useState([]);
    const [isChecked, setIsChecked] = useState(false);
    const [columnDataById, setColumnDataById] = useState([]);
    const [staffRequestHistoryData, setStaffRequestHistoryData] = useState([]);
    const [dataSuccess, setDataSuccess] = useState(false);
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [staffTypeId, setStaffTypeId] = useState<any>({ value: 0, label: 'Select All' });
    const [leaveTypeData, setLeaveTypeData] = useState<any>([]);
    const [leaveTypeId, setLeaveTypeId] = useState<any>(Number);
    // Filter

    const filteredData = updateFilter(
        onCurrentPageData,
        columnVisibilityData,
        columnVisibilityForm,
    );

    function openAddLeaveOffCanvas() {
        getAllStaff(0);
        getStatusList();
        getLeaveTypeList();
        setAddLeaveCanvas(true);
    }

    const selectUserType = (e: any) => {
        setStaffDetailsId('')
        setDataSuccess(false)
        setIsOpenListCard(true)
        let userTypeId = e;
        setStaffTypeId(userTypeId);
        if (userTypeId?.value != undefined) {
            getStaffListByUserType(userTypeId?.value);
        }
    };

    const selectStaffDetailsId = (e: any) => {
        let staffDetailsId = e;
        setStaffDetailsId(staffDetailsId);
    };

    function selectLeaveList() {
        setDataSuccess(false);
        setIsLoader(true);
        getStaffLeave(
            staffTypeId?.value,
            staffDetailsId?.value != undefined ? staffDetailsId?.value : 0,
        );
    };

    const handleFromDateChange = (e: any) => {
        setFromDate(e.target.value);
        if (toDate != 0) {
            const timeDifference = convertDateToEpoch(toDate) - convertDateToEpoch(e.target.value);
            const dayDifference = timeDifference / (1000 * 3600 * 24);
            setDuration(dayDifference + 1);
        }
    };

    const handleToDateChange = (e: any) => {
        setToDate(e.target.value);
        if (fromDate != 0) {
            const timeDifference =
                convertDateToEpoch(e.target.value) - convertDateToEpoch(fromDate);
            const dayDifference = timeDifference / (1000 * 3600 * 24);
            setDuration(dayDifference + 1);
        }
    };

    function onActivate(i: any) {
        resetForm();
        getAllStaff(0);
        getStatusList();
        getLeaveTypeList();

        if (i != undefined) {
            let selectedLeave = i;
            setEditLeaveCanvas(true);

            setStaffDetailsIdForAdd({
                value: selectedLeave.staffDetailsId,
                label: selectedLeave.staffName,
            });
            setLeaveTypeId({
                value: selectedLeave.leaveTypeId,
                label: selectedLeave.leaveTypeName,
            });
            setStatusId({
                value: selectedLeave.statusId,
                label: selectedLeave.statusName,
            });
            setApprovalStaff({
                value: selectedLeave.assignedBy,
                label: selectedLeave.assignedStaff,
            });
            setFromDate(selectedLeave.fromDate);
            setToDate(selectedLeave.toDate);
            setDuration(selectedLeave.totalDays);
            editLeaveForm.setValues({
                staffDetailsId: selectedLeave.staffDetailsId,
                fromDate: selectedLeave.fromDate,
                toDate: selectedLeave.toDate,
                totalDays: selectedLeave.totalDays,
                reason: selectedLeave.reason,
                approvalStaff: selectedLeave.approvalStaff,
                statusId: selectedLeave.statusId,
                staffLeaveDetailsId: selectedLeave.staffLeaveDetailsId,
                leaveTypeId: selectedLeave.leaveTypeId,
                remarks: '',
            });
        }
    }

    function getStaffLeave(userTypeId: any, staffDetailsId: any) {
        setIsLoader(true);
        getStaffLeaveDetails(
            userTypeId,
            staffDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.staffLeaveDetails;
                    if (data != undefined) {
                        getColumnsForTable('getStaffLeaveDetails', 'get');
                        setLeaveData(data);
                        setIsLoader(false);
                        setDataSuccess(true);
                        setIsOpenListCard(false);
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        setIsLoader(false);
                        setDataSuccess(true);
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    setLeaveData([]);
                    getColumnsForTable('getStaffLeaveDetails', 'get');
                    setIsLoader(false);
                    setDataSuccess(true);
                    setIsOpenListCard(false);
                    setNoDataMsg(response.data.message);
                }
            },
            (error) => {
                setIsLoader(false);
                setDataSuccess(true);
                toasts(error, 'Error');
            },
        );
    }

    function setStaffLeaveDetails() {
        return {
            staffDetailsId: userTypeId != 1 ? userAccountId : staffDetailsIdForAdd?.value,
            leaveTypeId: leaveTypeId?.value,
            assignedBy: approvalStaff?.value,
            fromDate: fromDate,
            toDate: toDate,
            reason: addStaffLeaveForm.values.reason,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId,
        };
    }

    function addStaffLeaveSubmit() {
        showLoader(true);
        console.log(addStaffLeaveForm);
        if (addStaffLeaveForm.isValid) {
            let leavePostData = setStaffLeaveDetails();
            addStaffLeave(
                leavePostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: 'success' });
                        setIsOpen(true);
                        getStaffLeave(
                            staffTypeId?.value != undefined ? staffTypeId?.value : 0,
                            staffDetailsId?.value != undefined ? staffDetailsId?.value : 0,
                        );

                        if (userTypeId == 1) {
                            selectLeaveList()
                        } else {
                            getStaffLeave(staffTypeId?.value == undefined ? 0 : staffTypeId?.value, staffDetailsId?.value == undefined ? 0 : staffDetailsId?.value);
                        }
                        closeAndResetForm();
                    } else if (data.success == false) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: 'warning' });
                        setIsOpen(true);
                    } else {
                        showLoader(false);
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: 'error' });
                        setIsOpen(true);
                    }
                },
                (error) => {
                    setAlertStatus({ message: error, type: 'error' });
                    setIsOpen(true);
                },
            );
        } else if (addStaffLeaveForm.isValid == false) {
            showLoader(false);
            setAlertStatus({ message: 'Please fill all the details!', type: 'warning' });
            setIsOpen(true);
        }
    }

    function setUpdateStaffLeaveDetails() {
        return {
            staffDetailsId: userTypeId != 1 ? userAccountId : staffDetailsIdForAdd?.value,
            leaveTypeId: leaveTypeId?.value,
            assignedBy: approvalStaff?.value,
            statusId: statusId?.value,
            fromDate: fromDate,
            toDate: toDate,
            reason: editLeaveForm.values.reason,
            remarks: editLeaveForm.values.remarks,
            staffLeaveDetailsId: editLeaveForm.values.staffLeaveDetailsId,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId,
        };
    }

    function setDeleteStaffLeaveDetails() {
        return {
            staffLeaveDetailsId: editLeaveForm.values.staffLeaveDetailsId,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId,
        };
    }

    function updateStaffLeaveSubmit() {
        showLoader(true);
        if (editLeaveForm.isValid) {
            let leavePostData = setUpdateStaffLeaveDetails();
            updateStaffLeave(
                leavePostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: 'success' });
                        setIsOpen(true);
                        if (userTypeId == 1) {
                            selectLeaveList()
                        } else {
                            getStaffLeave(staffTypeId?.value == undefined ? 0 : staffTypeId?.value, staffDetailsId?.value == undefined ? 0 : staffDetailsId?.value);
                        }
                        closeAndResetForm();
                    } else if (data.success == false) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: 'warning' });
                        setIsOpen(true);
                    } else {
                        showLoader(false);
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: 'error' });
                        setIsOpen(true);
                    }
                },
                (error) => {
                    setAlertStatus({ message: error, type: 'error' });
                    setIsOpen(true);
                },
            );
        } else if (editLeaveForm.isValid == false) {
            showLoader(false);
            setAlertStatus({ message: 'Please fill all the details!', type: 'warning' });
            setIsOpen(true);
        }
    }

    function deleteStaffLeaveSubmit() {
        showLoader(true);
        let leavePostData = setDeleteStaffLeaveDetails();
        deleteStaffLeave(
            leavePostData,
            (response) => {
                const data = response.data;
                if (data.success == true) {
                    showLoader(false);
                    setAlertStatus({ message: data.message, type: 'success' });
                    setIsOpen(true);
                    if (userTypeId == 1) {
                        selectLeaveList()
                    } else {
                        getStaffLeave(staffTypeId?.value == undefined ? 0 : staffTypeId?.value, staffDetailsId?.value == undefined ? 0 : staffDetailsId?.value);
                    }
                    closeAndResetForm();
                } else if (data.success == false) {
                    showLoader(false);
                    setAlertStatus({ message: data.message, type: 'warning' });
                    setIsOpen(true);
                } else {
                    showLoader(false);
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setAlertStatus({ message: errorDescription, type: 'error' });
                    setIsOpen(true);
                }
            },
            (error) => {
                setAlertStatus({ message: error, type: 'error' });
                setIsOpen(true);
            },
        );
    }

    function getUserTypeList() {
        getUserType(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.userType;
                    if (data != undefined) {
                        const userTypeData = [{ userTypeId: 0, userType: 'Select All' }];
                        data.forEach((obj: { userTypeId: number; userType: string }) => {
                            userTypeData.push(obj);
                        });
                        setUserTypeData(userTypeData);
                        if (userType != undefined) {
                            let user = userTypeData.filter((item: any) => item.userTypeId == userType)
                            setStaffTypeId({ value: userType, label: user[0]?.userType })
                        }
                    } else {
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    setUserTypeData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, 'Error');
                }
            },
            (error) => {
                toasts(error, 'Error');
            },
        );
    }

    function getStatusList() {
        getStatus(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.status;
                    if (data != undefined) {
                        setStatusData(data);
                    } else {
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    setStatusData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, 'Error');
                }
            },
            (error) => {
                toasts(error, 'Error');
            },
        );
    }

    function getStaffLeaveRequestHistory(requestTableId: any) {
        getStaffLeaveDetailsRequestHistory(
            requestTableId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.staffRequestHistory;
                    if (data != undefined) {
                        setStaffRequestHistoryData(data);
                        setStatusModal(true);
                    } else {
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    setStaffRequestHistoryData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, 'Error');
                }
            },
            (error) => {
                toasts(error, 'Error');
            },
        );
    }

    function getStaffListByUserType(userTypeId: any) {
        getStaffListByUserTypeId(userAccountId,
            userTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.staffListByUserTypeId;
                    if (data != undefined) {
                        const staffDetailsData: any = [{ staffDetailsId: 0, empAndStaffName: 'Select All' }];
                        data.forEach((obj: { staffDetailsId: number; empAndStaffName: string }) => {
                            staffDetailsData.push(obj);
                        });
                        setStaffDetailsData(staffDetailsData);

                        if (userType != undefined) {
                            let user = staffDetailsData.filter((item: any) => item.staffDetailsId == staffDetails)
                            setStaffDetailsId({ value: staffDetails, label: user[0]?.empAndStaffName })
                        }
                        else {
                            setStaffDetailsId({ value: 0, label: 'All Staff' });
                        }
                    } else {
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    setStaffDetailsData([]);
                    setStaffDetailsId([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, 'Error');
                }
            },
            (error) => {
                toasts(error, 'Error');
            },
        );
    }

    function getAllStaff(userTypeId: any) {
        getStaffListByUserTypeId(userAccountId,
            userTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.staffListByUserTypeId;
                    if (data != undefined) {
                        setAllStaffData(data);
                        setApprovalStaffListData(data.filter((item: any) => item.userTypeId != 7 && item.userTypeId != 9 && item.userTypeId != 10 && item.userTypeId != 11));
                    } else {
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    setAllStaffData([]);
                    setApprovalStaffListData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, 'Error');
                }
            },
            (error) => {
                toasts(error, 'Error');
            },
        );
    }

    function getLeaveTypeList() {
        getLeaveType(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.leaveType;
                    if (data != undefined) {
                        setLeaveTypeData(data);
                    } else {
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    toasts(response.data.message, 'warning');
                }
            },
            (error) => {
                toasts(error, 'Error');
            },
        );
    }

    function getColumnsForTable(apiName: any, type: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setColumnVisibilityData(columnVisibilityData)

                        if (type == 'get') {
                            const allData = allColumnsData.map((item: any) => item.isDisplay);
                            const allColumns = allData.filter((item: any) => item == false);
                            setIsChecked(allColumns == '' ? true : false);
                        }
                        else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

                            for (let i = 0; i < nonDefaultValue.length; i++) {

                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0
                                }
                                all.push(obj);
                            }
                            updateColumnsSubmit('', '', all)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )

    }

    function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {

        if (columnVisibilityForm.isValid) {

            setColumnDataById(isDisplay)
            const columnVisibilityPostData = {
                licenseKey: getLicenseKey,
                userAccountId: userAccountId,
                columns: type ? type : [
                    {
                        columnVisibilityId: columnVisibilityId,
                        isDisplay: isDisplay ? 0 : 1
                    }
                ]
            }

            updateColumnsForDataTable(columnVisibilityPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        getColumnsForTable('getStaffLeaveDetails', 'get');
                    }
                    else if (data.success == false) {
                        // toasts(data.success, "Error")
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        toasts(errorDescription, "Error")
                    }
                }
                , (error) => {
                    toasts(error, "Error")
                }
            )
        } else if (columnVisibilityForm.isValid == false) {
            toasts("Please fill all the details!", "Error")
        }
    }

    function closeAndResetForm() {
        setStatusModal(false);
        setAddLeaveCanvas(false);
        setEditLeaveCanvas(false);
        resetForm();
    }

    function resetForm() {
        setStaffDetailsIdForAdd('');
        setApprovalStaff('');
        setLeaveTypeId('');
        setFromDate('');
        setToDate('');
        setStatusId('');
        setDuration('')
        editLeaveForm.resetForm();
        addStaffLeaveForm.resetForm();
    }

    // Export Configuration
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <PageWrapper title='Leave Details'>
            <Page container='fluid'>
                {userTypeId == 1 ? (
                    <>
                        <Collapse isOpen={isOpenListCard}>
                            <Card stretch data-tour='list'>
                                <CardHeader borderSize={1}>
                                    <CardLabel icon='List' iconColor='info'>
                                        <CardTitle tag='div' className='h5'>
                                            Leave List
                                        </CardTitle>
                                    </CardLabel>
                                    <CardActions className='d-print-none'>
                                        <Button
                                            color='primary'
                                            icon='Add'
                                            isLight
                                            onClick={openAddLeaveOffCanvas}>
                                            Add New
                                        </Button>
                                    </CardActions>
                                </CardHeader>
                                <CardBody>
                                    <div className='row'>
                                        <div className='col-3'>
                                            <FormGroup
                                                id='userTypeId'
                                                label='Staff Type'
                                                isFloating>
                                                <SearchableSelect
                                                    isFloating
                                                    ariaLabel='Staff Type'
                                                    placeholder='Select Staff Type'
                                                    onChange={selectUserType}
                                                    value={staffTypeId}
                                                    list={userTypeData.map((data: any) => ({
                                                        value: data.userTypeId,
                                                        label: data.userType,
                                                    }))}
                                                />
                                            </FormGroup>
                                        </div>

                                        {staffTypeId?.value != 0 ? (
                                            <div className='col-4'>
                                                <FormGroup
                                                    id='staffDetailsId'
                                                    label='Staff'
                                                    isFloating>
                                                    <SearchableSelect
                                                        isFloating
                                                        ariaLabel='Staff'
                                                        placeholder='Select Staff'
                                                        onChange={(e: any) =>
                                                            selectStaffDetailsId(e)
                                                        }
                                                        value={staffDetailsId}
                                                        list={staffDetailsData.map((data: any) => ({
                                                            value: data.staffDetailsId,
                                                            label: data.empAndStaffName,
                                                        }))}
                                                    />
                                                </FormGroup>
                                            </div>
                                        ) : null}

                                        {staffTypeId?.value != undefined ? (
                                            <div className='col-3'>
                                                <Button
                                                    className='mt-2'
                                                    icon='ArrowDownward'
                                                    color='primary'
                                                    onClick={selectLeaveList}>
                                                    View
                                                </Button>
                                            </div>
                                        ) : null}
                                    </div>
                                </CardBody>
                            </Card>
                        </Collapse>

                        <OpenCardComponent
                            isOpenListCard={isOpenListCard}
                            setIsOpenListCard={setIsOpenListCard}
                            isLoader={isLoader}
                        />
                    </>
                ) : (
                    <></>
                )}
                {dataSuccess ? (
                    <>
                        <Card stretch data-tour='list' ref={componentRef} id='pdf'>
                            <CardHeader borderSize={1}>
                                <CardLabel>
                                    <CardTitle>
                                        <Icon icon='PlaylistAdd' size='2x' color='primary' />
                                        &nbsp; Leave List
                                    </CardTitle>
                                </CardLabel>
                                <CardActions className='d-print-none'>
                                    <ButtonGroup color='primary'>
                                        <Icon
                                            className='mt-1'
                                            icon='Search'
                                            size='2x'
                                            color='primary'
                                        />
                                        <Input
                                            id='searchInput'
                                            type='search'
                                            placeholder='Search...'
                                            onChange={columnVisibilityForm.handleChange}
                                            value={columnVisibilityForm.values.searchInput}
                                        />
                                    </ButtonGroup>
                                    <Dropdown isOpen={columnVisibilityMenu} setIsOpen={setColumnVisibilityMenu} isButtonGroup>
                                        <DropdownToggle>
                                            <Button icon='FilterAlt' color='primary' isLight>Filter</Button>
                                        </DropdownToggle>
                                        <DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
                                            <DropdownItem>
                                                <div className='container py-2'>
                                                    <form className='row g-3'>
                                                        <div className='col-12'>
                                                            <FormGroup>
                                                                <h6>Select All</h6>
                                                                <Checks id='available' type='switch' label='Select All Columns' onChange={() => getColumnsForTable('getStaffLeaveDetails', 'post')} checked={isChecked} ariaLabel='Available status' />
                                                            </FormGroup>
                                                        </div>
                                                        <div className='col-12' style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                                                            <FormGroup>
                                                                <h6>Columns</h6>
                                                                <ChecksGroup>
                                                                    {allColumnsData.map((i: any) => (
                                                                        <Checks key={i.columnVisibilityId} id='{i.columnVisibilityId}' label={i.columnName} onChange={() => updateColumnsSubmit(i.columnVisibilityId, i.isDisplay, '')} checked={i.isDisplay} disabled={i.isDefault == true} />
                                                                    ))}
                                                                </ChecksGroup>
                                                            </FormGroup>
                                                        </div>
                                                    </form>
                                                </div>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                    <Dropdown isButtonGroup>
                                        <DropdownToggle>
                                            <Button color='primary' isLight icon='CloudDownload'>
                                                Export
                                            </Button>
                                        </DropdownToggle>
                                        <DropdownMenu isAlignmentEnd>
                                            <DropdownItem>
                                                <Button
                                                    color='primary'
                                                    isLight
                                                    icon='CloudDownload'
                                                    onClick={() =>
                                                        downloadExcel(
                                                            'StaffLeaveDetails',
                                                            leaveData,
                                                            columnVisibilityData,
                                                        )
                                                    }>
                                                    Excel
                                                </Button>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <Button
                                                    color='primary'
                                                    isLight
                                                    icon='PictureAsPdf'
                                                    onClick={() =>
                                                        convertJsonToPdf(
                                                            leaveData,
                                                            columnVisibilityData,
                                                            'StaffLeaveDetails',
                                                        )
                                                    }>
                                                    PDF
                                                </Button>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <Button
                                                    color='primary'
                                                    isLight
                                                    icon='CloudDownload'
                                                    onClick={() =>
                                                        downloadFile(
                                                            'StaffLeaveDetails',
                                                            leaveData,
                                                            columnVisibilityData,
                                                        )
                                                    }>
                                                    CSV
                                                </Button>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <Button
                                                    color='primary'
                                                    isLight
                                                    icon='Print'
                                                    onClick={() => handlePrint()}>
                                                    Print
                                                </Button>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                    {userTypeId != 1 ? (
                                        <Button
                                            color='primary'
                                            icon='Add'
                                            isLight
                                            onClick={openAddLeaveOffCanvas}>
                                            Add New
                                        </Button>
                                    ) : null}
                                </CardActions>
                            </CardHeader>
                            <CardBody className='table-responsive' isScrollable>
                                <table className='table table-modern table-hover text-nowrap'>
                                    <thead>
                                        <tr>
                                            <th
                                                scope='col'
                                                onClick={() => requestSort('sno')}
                                                className='cursor-pointer text-decoration-underline'>
                                                S.No{' '}
                                                <Icon
                                                    size='lg'
                                                    className={getClassNamesFor('sno')}
                                                    icon='FilterList'
                                                />
                                            </th>
                                            {columnVisibilityData.map((column: any) => (
                                                <th
                                                    key={column.keyName}
                                                    scope='col'
                                                    onClick={() => requestSort(column.keyName)}
                                                    className='cursor-pointer text-decoration-underline'>
                                                    {column.columnName}
                                                    <Icon
                                                        size='lg'
                                                        className={getClassNamesFor(column.keyName)}
                                                        icon='FilterList'
                                                    />
                                                </th>
                                            ))}
                                            <th scope='col' className='d-print-none'>
                                                Status
                                            </th>
                                            <th scope='col' className='d-print-none'>
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredData != '' ? (
                                            <>
                                                {filteredData.map((rowData: any) => (
                                                    <tr key={rowData.staffLeaveDetailsId}>
                                                        <td width={'1%'}>{rowData.sno}</td>
                                                        {columnVisibilityData.map((column: any) =>
                                                            column.isDisplay &&
                                                                rowData[column.keyName] ? (
                                                                <td
                                                                    key={`${rowData.staffLeaveDetailsId}-${column.keyName}`}>
                                                                    {rowData[column.keyName]}
                                                                </td>
                                                            ) : (
                                                                <td
                                                                    key={`empty-${column.keyName}`}
                                                                />
                                                            ),
                                                        )}
                                                        <td className='d-print-none'>
                                                            <Button
                                                                isLink
                                                                icon='Circle'
                                                                className='text-nowrap'
                                                                color={
                                                                    rowData.statusId == 1
                                                                        ? 'info'
                                                                        : rowData.statusId == 2
                                                                            ? 'success'
                                                                            : 'danger'
                                                                }
                                                                onClick={() =>
                                                                    getStaffLeaveRequestHistory(
                                                                        rowData.staffLeaveDetailsId,
                                                                    )
                                                                }>
                                                                {rowData.statusName}
                                                            </Button>
                                                        </td>
                                                        <td className='d-print-none'>
                                                            <Button
                                                                color='dark'
                                                                isLight
                                                                icon='Edit'
                                                                aria-label='Edit'
                                                                onClick={() => {
                                                                    onActivate(rowData);
                                                                }}
                                                            />
                                                        </td>
                                                    </tr>
                                                ))}
                                            </>
                                        ) : (
                                            <NoDataMsg
                                                columnsCount={columnVisibilityData.length + 3}
                                                msg={noDataMsg}
                                            />
                                        )}
                                    </tbody>
                                </table>
                            </CardBody>
                            <PaginationButtons
                                className='d-print-none'
                                data={items}
                                label='items'
                                setCurrentPage={setCurrentPage}
                                currentPage={currentPage}
                                perPage={perPage}
                                setPerPage={setPerPage}
                            />
                        </Card>
                    </>
                ) : null}
            </Page>

            <OffCanvas
                setOpen={setAddLeaveCanvas}
                isOpen={addLeaveCanvas}
                titleId='addLeaveCanvas'
                placement='end'
                isBodyScroll
                tag='form'
                noValidate
                onSubmit={addStaffLeaveForm.handleSubmit}>
                <OffCanvasHeader setOpen={setAddLeaveCanvas} onClick={closeAndResetForm}>
                    <OffCanvasTitle id='addAdvanceSalaryOffCanvas'>
                        Add Leave Request
                    </OffCanvasTitle>
                </OffCanvasHeader>
                <OffCanvasBody>
                    <div className='row g-4'>
                        {userTypeId == 1 ? (
                            <div className='col-12'>
                                <FormGroup id='staffDetailsIdForAdd' label='Staff'>
                                    <SearchableSelect
                                        ariaLabel='Staff'
                                        placeholder='Select Staff'
                                        onChange={(e: any) => setStaffDetailsIdForAdd(e)}
                                        value={staffDetailsIdForAdd}
                                        list={allStaffData.map((data: any) => ({
                                            value: data.staffDetailsId,
                                            label: data.empAndStaffName,
                                        }))}
                                        isValid={addStaffLeaveForm.isValid}
                                        isTouched={addStaffLeaveForm.touched.staffDetailsId}
                                        invalidFeedback={addStaffLeaveForm.errors.staffDetailsId}
                                    />
                                </FormGroup>
                            </div>
                        ) : (
                            <></>
                        )}
                        <div className='col-12'>
                            <FormGroup id='leaveTypeId' label='Leave Type'>
                                <SearchableSelect
                                    ariaLabel='select Leave Type'
                                    onChange={(e: any) => setLeaveTypeId(e)}
                                    onBlur={addStaffLeaveForm.handleBlur}
                                    value={leaveTypeId}
                                    isValid={addStaffLeaveForm.isValid}
                                    isTouched={addStaffLeaveForm.touched.leaveTypeId}
                                    invalidFeedback={addStaffLeaveForm.errors.leaveTypeId}
                                    list={leaveTypeData.map((data: any) => ({
                                        value: data.leaveTypeId,
                                        label: data.leaveTypeName,
                                    }))}
                                />
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='fromDate' label='From Date'>
                                <Input
                                    aria-placeholder=''
                                    onChange={handleFromDateChange}
                                    onBlur={addStaffLeaveForm.handleBlur}
                                    value={fromDate}
                                    isValid={addStaffLeaveForm.isValid}
                                    isTouched={addStaffLeaveForm.touched.fromDate}
                                    invalidFeedback={addStaffLeaveForm.errors.fromDate}
                                    type='date'
                                />
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='toDate' label='To Date'>
                                <Input
                                    aria-placeholder=''
                                    onChange={handleToDateChange}
                                    onBlur={addStaffLeaveForm.handleBlur}
                                    value={toDate}
                                    isValid={addStaffLeaveForm.isValid}
                                    isTouched={addStaffLeaveForm.touched.toDate}
                                    invalidFeedback={addStaffLeaveForm.errors.toDate}
                                    type='date'
                                />
                            </FormGroup>
                        </div>

                        <div className='col-12'>
                            <FormGroup label='Duration'>
                                <Input disabled aria-placeholder='' value={duration} />
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='reason' label='Reason'>
                                <Textarea
                                    autoComplete=''
                                    onChange={addStaffLeaveForm.handleChange}
                                    onBlur={addStaffLeaveForm.handleBlur}
                                    value={addStaffLeaveForm.values.reason}
                                    isValid={addStaffLeaveForm.isValid}
                                    isTouched={addStaffLeaveForm.touched.reason}
                                    invalidFeedback={addStaffLeaveForm.errors.reason}
                                />
                            </FormGroup>
                        </div>

                        <div className='col-12'>
                            <FormGroup id='approvalStaff' label='Pending With'>
                                <SearchableSelect
                                    ariaLabel='Staff'
                                    placeholder='Select Staff'
                                    onChange={(e: any) => setApprovalStaff(e)}
                                    onBlur={addStaffLeaveForm.handleBlur}
                                    value={approvalStaff}
                                    list={approvalStaffListData.map((data: any) => ({
                                        value: data.staffDetailsId,
                                        label: data.empAndStaffName,
                                    }))}
                                    isValid={addStaffLeaveForm.isValid}
                                    isTouched={addStaffLeaveForm.touched.approvalStaff}
                                    invalidFeedback={addStaffLeaveForm.errors.approvalStaff}
                                />
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <br />
                            <br />
                            <br />
                        </div>
                    </div>
                </OffCanvasBody>
                <div className='col-12 p-3 position-absolute top-95 start-50 translate-middle'>
                    <div className='row m-0'>
                        <Button
                            color='info'
                            icon='Save'
                            className='w-100'
                            type='submit'
                            isDisable={
                                !addStaffLeaveForm.isValid && !!addStaffLeaveForm.submitCount
                            }>
                            Save
                        </Button>
                    </div>
                </div>
            </OffCanvas>

            <OffCanvas
                setOpen={setEditLeaveCanvas}
                isOpen={editLeaveCanvas}
                titleId='editLeaveCanvas'
                placement='end'
                isBodyScroll
                tag='form'
                noValidate
                onSubmit={editLeaveForm.handleSubmit}>
                <OffCanvasHeader setOpen={setEditLeaveCanvas} onClick={closeAndResetForm}>
                    <OffCanvasTitle id='editAdvanceSalaryOffCanvas'>Edit Leave</OffCanvasTitle>
                </OffCanvasHeader>
                <OffCanvasBody>
                    <div className='row g-4'>
                        {userTypeId == 1 ? (
                            <div className='col-12'>
                                <FormGroup id='staffDetailsIdForAdd' label='Staff'>
                                    <SearchableSelect
                                        ariaLabel='Staff'
                                        placeholder='Select Staff'
                                        onChange={(e: any) => setStaffDetailsIdForAdd(e)}
                                        value={staffDetailsIdForAdd}
                                        list={allStaffData.map((data: any) => ({
                                            value: data.staffDetailsId,
                                            label: data.empAndStaffName,
                                        }))}
                                        isValid={editLeaveForm.isValid}
                                        isTouched={editLeaveForm.touched.staffDetailsId}
                                        invalidFeedback={editLeaveForm.errors.staffDetailsId}
                                    />
                                </FormGroup>
                            </div>
                        ) : (
                            <></>
                        )}
                        <div className='col-12'>
                            <FormGroup id='leaveTypeId' label='Leave Type'>
                                <SearchableSelect
                                    ariaLabel='select Leave Type'
                                    onChange={(e: any) => setLeaveTypeId(e)}
                                    onBlur={editLeaveForm.handleBlur}
                                    value={leaveTypeId}
                                    isValid={editLeaveForm.isValid}
                                    isTouched={editLeaveForm.touched.leaveTypeId}
                                    invalidFeedback={editLeaveForm.errors.leaveTypeId}
                                    list={leaveTypeData.map((data: any) => ({
                                        value: data.leaveTypeId,
                                        label: data.leaveTypeName,
                                    }))}
                                />
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='fromDate' label='From Date'>
                                <Input
                                    aria-placeholder=''
                                    onChange={handleFromDateChange}
                                    onBlur={editLeaveForm.handleBlur}
                                    value={fromDate}
                                    isValid={editLeaveForm.isValid}
                                    isTouched={editLeaveForm.touched.fromDate}
                                    invalidFeedback={editLeaveForm.errors.fromDate}
                                    type='date'
                                />
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='toDate' label='To Date'>
                                <Input
                                    aria-placeholder=''
                                    onChange={handleToDateChange}
                                    onBlur={editLeaveForm.handleBlur}
                                    value={toDate}
                                    isValid={editLeaveForm.isValid}
                                    isTouched={editLeaveForm.touched.toDate}
                                    invalidFeedback={editLeaveForm.errors.toDate}
                                    type='date'
                                />
                            </FormGroup>
                        </div>

                        <div className='col-12'>
                            <FormGroup label='Duration'>
                                <Input disabled aria-placeholder='' value={duration} />
                            </FormGroup>
                        </div>

                        <div className='col-12'>
                            <FormGroup id='reason' label='Reason'>
                                <Textarea
                                    autoComplete=''
                                    onChange={editLeaveForm.handleChange}
                                    onBlur={editLeaveForm.handleBlur}
                                    value={editLeaveForm.values.reason}
                                    isValid={editLeaveForm.isValid}
                                    isTouched={editLeaveForm.touched.reason}
                                    invalidFeedback={editLeaveForm.errors.reason}
                                />
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='approvalStaff' label='Pending With'>
                                <SearchableSelect
                                    ariaLabel='Staff'
                                    placeholder='Select Staff'
                                    onChange={(e: any) => setApprovalStaff(e)}
                                    onBlur={editLeaveForm.handleBlur}
                                    value={approvalStaff}
                                    list={approvalStaffListData.map((data: any) => ({
                                        value: data.staffDetailsId,
                                        label: data.empAndStaffName,
                                    }))}
                                    isValid={editLeaveForm.isValid}
                                    isTouched={editLeaveForm.touched.approvalStaff}
                                    invalidFeedback={editLeaveForm.errors.approvalStaff}
                                />
                            </FormGroup>
                        </div>

                        <div className='col-12'>
                            <FormGroup id='statusId' label='Status'>
                                <SearchableSelect
                                    ariaLabel='Staff'
                                    placeholder='Select Status'
                                    onChange={(e: any) => setStatusId(e)}
                                    value={statusId}
                                    list={statusData.map((data: any) => ({
                                        value: data.statusId,
                                        label: data.statusName,
                                    }))}
                                    isValid={editLeaveForm.isValid}
                                    isTouched={editLeaveForm.touched.statusId}
                                    invalidFeedback={editLeaveForm.errors.statusId}
                                />
                            </FormGroup>
                        </div>
                        {statusId?.value != 1 ? (
                            <div className='col-12'>
                                <FormGroup id='remarks' label='Remarks'>
                                    <Textarea
                                        autoComplete=''
                                        onChange={editLeaveForm.handleChange}
                                        onBlur={editLeaveForm.handleBlur}
                                        value={editLeaveForm.values.remarks}
                                        isValid={editLeaveForm.isValid}
                                        isTouched={editLeaveForm.touched.remarks}
                                        invalidFeedback={editLeaveForm.errors.remarks}
                                    />
                                </FormGroup>
                            </div>
                        ) : null}

                        <div className='d-flex justify-content-center position-absolute top-60 start-50 translate-middle'>
                            <DeleteComponents
                                isDeleteOpen={isDeleteOpen}
                                setIsDeleteOpen={setIsDeleteOpen}
                                deleteFunction={deleteStaffLeaveSubmit}
                            />
                        </div>

                        <div className='col-12'>
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                        </div>
                    </div>
                </OffCanvasBody>

                <div className='col-12 p-3 position-absolute top-95 start-50 translate-middle'>
                    <div className='row m-0'>
                        <div className='col-6 p-3'>
                            <Button
                                color='danger'
                                className='w-100'
                                icon='Delete'
                                onClick={() => setIsDeleteOpen(!isDeleteOpen)}>
                                Delete
                            </Button>
                        </div>
                        <div className='col-6 p-3'>
                            <Button
                                color='info'
                                icon='Save'
                                type='submit'
                                className='w-100'
                                isDisable={!editLeaveForm.isValid && !!editLeaveForm.submitCount}>
                                {' '}
                                Update
                            </Button>
                        </div>
                    </div>
                </div>
            </OffCanvas>

            <Modal
                setIsOpen={setStatusModal}
                isOpen={statusModal}
                titleId='statusModal'
                isStaticBackdrop
                size='lg'>
                <ModalHeader
                    className="'modal-header'"
                    setIsOpen={() => {
                        setStatusModal(false);
                    }}
                    onClick={closeAndResetForm}>
                    <ModalTitle id='statusModal'> Status</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <div className='row'>
                        <div className='col-12'>
                            <table className='table table-modern table-hover'>
                                <thead>
                                    <tr className='table-primary'>
                                        <td
                                            style={{ width: '30px' }}
                                            className='text-decoration-underline'>
                                            <strong>Action By</strong>
                                        </td>
                                        <td
                                            style={{ width: '25px' }}
                                            className='text-decoration-underline'>
                                            <strong>Action</strong>
                                        </td>
                                        <td
                                            style={{ width: '10px' }}
                                            className='text-decoration-underline'>
                                            <strong>Status</strong>
                                        </td>
                                        <td
                                            style={{ width: '10px' }}
                                            className='text-decoration-underline'>
                                            <strong>Date & Time</strong>
                                        </td>
                                        <td
                                            style={{ width: '25px' }}
                                            className='text-decoration-underline'>
                                            <strong>Remarks</strong>
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {staffRequestHistoryData.map((status: any) => (
                                        <tr key={status.staffRequestHistoryId}>
                                            <td>{status.staffName}</td>
                                            <td>{status.action}</td>
                                            <td
                                                className={
                                                    status.statusId == 1
                                                        ? 'text-info'
                                                        : status.statusId == 2
                                                            ? 'text-success'
                                                            : 'text-danger'
                                                }>
                                                {' '}
                                                <Icon
                                                    icon='Circle'
                                                    color={
                                                        status.statusId == 1
                                                            ? 'info'
                                                            : status.statusId == 2
                                                                ? 'success'
                                                                : 'danger'
                                                    }
                                                />{' '}
                                                {status.statusName}
                                            </td>
                                            <td>
                                                {dayjs(status.statusUpdatedOn).format(
                                                    'D MMMM, YYYY h:mm A',
                                                )}
                                            </td>
                                            <td>{status.remarks}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </ModalBody>
            </Modal>

            <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
        </PageWrapper>
    );
}

export default AddStaffLeave;
