import { useContext, useEffect, useRef, useState } from "react";
import Button, { ButtonGroup } from "../../bootstrap/Button";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../../bootstrap/Card";
import Page from "../../../layout/Page/Page";
import FormGroup from "../../bootstrap/forms/FormGroup";
import Input from "../../bootstrap/forms/Input";
import PaginationButtons, { dataPagination } from "../../PaginationButtons";
import useSortableData from "../../../hooks/useSortableData";
import { toasts } from "../../../services/toast.service";
import { useFormik } from "formik";
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from "../../bootstrap/Dropdown";
import { convertJsonToPdf, downloadExcel, downloadFile, printPage } from "../../../services/ExportService";
import useDarkMode from "../../../hooks/useDarkMode";
import Checks, { ChecksGroup } from "../../bootstrap/forms/Checks";
import { useReactToPrint } from "react-to-print";
import Icon from "../../icon/Icon";
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from "../../bootstrap/OffCanvas";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import AlertService from "../../../services/AlertService";
import DeleteComponents from "../../../common/components/DeleteComponents";
import { getColumnsForDataTable, getMedium, getStandardDetails, updateColumnsForDataTable, updateFilter } from "../../../services/common.service";
import AuthContext from "../../../contexts/authContext";
import { addSectionDetails, deleteSectionDetails, getSectionDetails, updateSectionDetails } from "../../../services/master.service";
import { getLicenseKey } from "../../../services/application.settings";
import { TableLoader, showLoader } from "../../../services/loader.services";
import NoDataMsg from "../../../common/components/NoDataMsg";
import SearchableSelect from "../../../common/components/SearchableSelect";

const SectionCreation = () => {

    useEffect(() => {
        getSection()
        getStandardList()
        getMediumList()
    }, [])

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const { themeStatus, darkModeStatus } = useDarkMode();
    const [sectionData, setSectionData] = useState<any>([]);
    const [columnVisibilityData, setColumnVisibilityData] = useState([]);
    const [allColumnsData, setAllColumnsData] = useState([]);
    const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
    const [columnDataById, setColumnDataById] = useState([])
    const componentRef = useRef(null);
    const [isChecked, setIsChecked] = useState(false)
    const [dataStatus, setDataStatus] = useState(false)

    // For Table and offCanvas
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(sectionData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);
    const [addStandardCreationOffCanvas, setAddStandardCreationOffCanvas] = useState(false)
    const [editStandardCreationOffCanvas, setEditStandardCreationOffCanvas] = useState(false)
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);

    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);
    const [noDataMsg, setNoDataMsg] = useState('')

    const [standardDetailsId, setStandardDetailsId] = useState<any>('')
    const [mediumId, setMediumId] = useState<any>('')
    const [standardData, setStandardData] = useState<any>([])
    const [mediumData, setMediumData] = useState<any>([])

    const addStandardForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            standardDetailsId: '',
            sectionName: '',
            mediumId: '',
        },
        validate: (values) => {
            const errors: {
                standardDetailsId?: string;
                sectionName?: string;
                mediumId?: string;
            } = {};
            if (!standardDetailsId) {
                errors.standardDetailsId = 'Required';
            }
            if (!values.sectionName) {
                errors.sectionName = 'Required';
            }
            if (!mediumId) {
                errors.mediumId = 'Required';
            }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: () => { addSectionDetailsSubmit() },
    });

    const editStandardForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            standardDetailsId: '',
            sectionName: '',
            sectionDetailsId: '',
            mediumId: '',
        },
        validate: (values) => {
            const errors: {
                standardDetailsId?: string;
                sectionName?: string;
                sectionDetailsId?: string;
                mediumId?: string;
            } = {};
            if (!standardDetailsId) {
                errors.standardDetailsId = 'Required';
            }
            if (!values.sectionName) {
                errors.sectionName = 'Required';
            }
            if (!values.sectionDetailsId) {
                errors.sectionDetailsId = 'Required';
            }
            if (!mediumId) {
                errors.mediumId = 'Required';
            }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: () => { updateSectionDetailsSubmit() },
    });

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },

        //validateOnChange: false,
        onSubmit: () => { },
    });

    // Filter
    const filteredData = updateFilter(onCurrentPageData, columnVisibilityData, columnVisibilityForm);

    const selectStandard = (e: any) => {
        let standardDetailsId = e
        setStandardDetailsId(standardDetailsId)
    }

    const selectMedium = (e: any) => {
        let mediumId = e
        setMediumId(mediumId)
    }

    function getSection() {
        setDataStatus(false)
        getSectionDetails(0, 0,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.sectionDetails;
                    if (data != undefined) {
                        getColumnsForTable('getSectionDetails', 'get');
                        setSectionData(data);
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                        setDataStatus(true)
                    } else {
                        setDataStatus(true)
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setDataStatus(true)
                    getColumnsForTable('getSectionDetails', 'get');
                    setSectionData([]);
                    setNoDataMsg(response.data.message)
                } else {
                    setDataStatus(true)
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                setDataStatus(true)
                toasts(error, "Error")
            }
        )
    }

    function getStandardList() {
        getStandardDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.standardDetails;
                    if (data != undefined) {
                        setStandardData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setStandardData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getMediumList() {
        getMedium(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.medium;
                    if (data != undefined) {
                        setMediumData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setMediumData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function onActivate(i: any) {
        if (i != undefined) {

            let selectedStandard = i
            setEditStandardCreationOffCanvas(true)

            setStandardDetailsId({ value: selectedStandard.standardDetailsId, label: selectedStandard.standardName })
            setMediumId({ value: selectedStandard.mediumId, label: selectedStandard.medium })
            editStandardForm.setValues({
                sectionDetailsId: selectedStandard.sectionDetailsId,
                standardDetailsId: standardDetailsId,
                sectionName: selectedStandard.sectionName,
                mediumId: mediumId
            });
        }
    }

    function setStandardDetails() {
        return ({
            licenseKey: getLicenseKey,
            userAccountId: userAccountId,
            standardDetailsId: standardDetailsId?.value,
            sectionName: addStandardForm.values.sectionName,
            mediumId: mediumId?.value
        })
    }

    function setEditStandardDetails() {
        return ({
            standardDetailsId: standardDetailsId?.value,
            sectionName: editStandardForm.values.sectionName,
            sectionDetailsId: editStandardForm.values.sectionDetailsId,
            mediumId: mediumId?.value,
            licenseKey: getLicenseKey,
            isActive: 1,
            userAccountId: userAccountId
        })
    }

    function addSectionDetailsSubmit() {
        showLoader(true);
        if (addStandardForm.isValid) {
            let standardPostData = setStandardDetails()
            addSectionDetails(standardPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        getSection();
                        closeAndFormReset();
                    }
                    else if (data.success == false) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        showLoader(false);
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false);
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (addStandardForm.isValid == false) {
            showLoader(false);
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function updateSectionDetailsSubmit() {
        showLoader(true);
        if (editStandardForm.isValid) {
            let standardPostData = setEditStandardDetails()
            updateSectionDetails(standardPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        getSection();
                        closeAndFormReset();
                    }
                    else if (data.success == false) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        showLoader(false);
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false);
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (editStandardForm.isValid == false) {
            showLoader(false);
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function deleteSectionDetailsSubmit() {
        showLoader(true);
        let standardPostData = setEditStandardDetails()
        deleteSectionDetails(standardPostData,
            (response) => {
                const data = response.data;
                if (data.success == true) {
                    showLoader(false);
                    setAlertStatus({ message: data.message, type: "success" });
                    setIsOpen(true);
                    getSection();
                    closeAndFormReset();
                }
                else if (data.success == false) {
                    showLoader(false);
                    setAlertStatus({ message: data.message, type: "error" });
                    setIsOpen(true);
                }
                else {
                    showLoader(false);
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setAlertStatus({ message: errorDescription, type: "error" });
                    setIsOpen(true);
                }
            }
            , (error) => {
                showLoader(false);
                setAlertStatus({ message: error, type: "error" });
                setIsOpen(true);
            }
        )
    }

    function getColumnsForTable(apiName: any, type: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setColumnVisibilityData(columnVisibilityData)

                        const allData = allColumnsData.map((item: any) => item.isDisplay);
                        const allColumns = allData.filter((item: any) => item == false);

                        if (type == 'get') {
                            setIsChecked(allColumns == '' ? true : false);
                        }
                        else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

                            for (let i = 0; i < nonDefaultValue.length; i++) {

                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: allColumns == '' ? 0 : 1
                                }
                                all.push(obj);
                            }
                            updateColumnsSubmit('', '', all)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )

    }

    function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {

        if (columnVisibilityForm.isValid) {

            setColumnDataById(isDisplay)
            const columnVisibilityPostData = {
                licenseKey: getLicenseKey,
                userAccountId: userAccountId,
                columns: type ? type : [
                    {
                        columnVisibilityId: columnVisibilityId,
                        isDisplay: isDisplay ? 0 : 1
                    }
                ]
            }

            updateColumnsForDataTable(columnVisibilityPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        getColumnsForTable('getSectionDetails', 'get');
                    }
                    else if (data.success == false) {
                        // toasts(data.success, "Error")
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        toasts(errorDescription, "Error")
                    }
                }
                , (error) => {
                    toasts(error, "Error")
                }
            )
        } else if (columnVisibilityForm.isValid == false) {
            toasts("Please fill all the details!", "Error")
        }
    }

    function closeAndFormReset() {
        setAddStandardCreationOffCanvas(false);
        setEditStandardCreationOffCanvas(false);
        setIsDeleteOpen(false);
        addStandardForm.resetForm();
        editStandardForm.resetForm();
    }

    function resetAddNew() {
        setMediumId('')
        setStandardDetailsId('')
        addStandardForm.resetForm();
        editStandardForm.resetForm();
    }

    // Export Configuration
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <>
            <PageWrapper title={`Class Creation`}>
                <Page container='fluid'>
                    <Card stretch data-tour='list' ref={componentRef} id='pdf'>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info' className="col-lg-5">
                                <CardTitle tag='div' className='h5'> Class List</CardTitle>
                            </CardLabel>

                            <ButtonGroup className="col-lg-3 d-print-none" color='primary'>
                                <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                                <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
                            </ButtonGroup>

                            <CardActions className="d-print-none">
                                <Dropdown isOpen={columnVisibilityMenu} setIsOpen={setColumnVisibilityMenu} isButtonGroup>
                                    <DropdownToggle>
                                        <Button icon='FilterAlt' color='primary' isLight>Filter</Button>
                                    </DropdownToggle>
                                    <DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
                                        <DropdownItem>
                                            <div className='container py-2'>
                                                <form className='row g-3'>
                                                    <div className='col-12'>
                                                        <FormGroup>
                                                            <h6>Select All</h6>
                                                            <Checks id='available' type='switch' label='Select All Columns' onChange={() => getColumnsForTable('getSectionDetails', 'post')} checked={isChecked} ariaLabel='Available status' />
                                                        </FormGroup>
                                                    </div>
                                                    <div className='col-12' style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                                                        <FormGroup>
                                                            <h6>Columns</h6>
                                                            <ChecksGroup>
                                                                {allColumnsData.map((i: any) => (
                                                                    <Checks key={i.columnVisibilityId} id='{i.columnVisibilityId}' label={i.columnName} onChange={() => updateColumnsSubmit(i.columnVisibilityId, i.isDisplay, '')} checked={i.isDisplay} disabled={i.isDefault == true} />
                                                                ))}
                                                            </ChecksGroup>
                                                        </FormGroup>
                                                    </div>
                                                </form>
                                            </div>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                <Dropdown isButtonGroup>
                                    <DropdownToggle>
                                        <Button color='primary' isLight icon='CloudDownload'> Export </Button>
                                    </DropdownToggle>
                                    <DropdownMenu isAlignmentEnd>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel("Section List", sectionData, columnVisibilityData)}> Excel </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(sectionData, columnVisibilityData, "Section List")}> PDF
                                            </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile("Section List", sectionData, columnVisibilityData)}> CSV </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='Print' onClick={handlePrint}> Print </Button>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                <Button color='primary' icon='Add' isLight onClick={() => { setAddStandardCreationOffCanvas(true); resetAddNew() }}>
                                    Add New
                                </Button>
                            </CardActions>
                        </CardHeader>

                        <CardBody className='table-responsive' isScrollable>

                            {!dataStatus ?
                                <TableLoader /> :

                                <table className='table table-modern table-hover text-nowrap'>
                                    <thead>
                                        <tr>
                                            <th scope='col' onClick={() => requestSort(`sno`)}
                                                className='cursor-pointer text-decoration-underline'>S.No
                                                <Icon size='lg' className={getClassNamesFor(`sno`)} icon='FilterList' />
                                            </th>
                                            {columnVisibilityData.map((column: any) => (
                                                <th key={column.keyName} scope='col' onClick={() => requestSort(column.keyName)}
                                                    className='cursor-pointer text-decoration-underline'>
                                                    {column.columnName}
                                                    <Icon size='lg' className={getClassNamesFor(column.keyName)} icon='FilterList' />
                                                </th>
                                            ))}
                                            <th scope='col' className='d-print-none'>
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredData != "" ?
                                            <>
                                                {filteredData.map((rowData: any) => (
                                                    <tr key={rowData.departmentId}>
                                                        <td>{rowData.sno}</td>
                                                        {columnVisibilityData.map((column: any) => (
                                                            (column.isDisplay && rowData[column.keyName]) ?
                                                                <td key={`${rowData.departmentId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />
                                                        ))}
                                                        <td className="d-print-none">
                                                            <Button color='dark' isLight icon='Edit' aria-label='Edit' onClick={() => { onActivate(rowData) }} />
                                                        </td>
                                                    </tr>
                                                ))
                                                }</>
                                            :
                                            <NoDataMsg columnsCount={columnVisibilityData.length + 2} msg={noDataMsg} />}
                                    </tbody>
                                </table>
                            }
                        </CardBody>
                        <PaginationButtons className='d-print-none' data={items} label='items' setCurrentPage={setCurrentPage} currentPage={currentPage} perPage={perPage} setPerPage={setPerPage} />
                    </Card>
                </Page>

                <OffCanvas setOpen={setAddStandardCreationOffCanvas} isOpen={addStandardCreationOffCanvas} titleId='addStandardCreationOffCanvas' placement='end' tag="form" noValidate onSubmit={addStandardForm.handleSubmit}>
                    <OffCanvasHeader setOpen={setAddStandardCreationOffCanvas} onClick={closeAndFormReset}>
                        <OffCanvasTitle id="addStandardCreationOffCanvas">Class Creation</OffCanvasTitle>
                    </OffCanvasHeader>
                    <OffCanvasBody>
                        <div className="row g-4">
                            <div className='col-12'>
                                <FormGroup id='mediumId' label='Medium' onBlur={addStandardForm.handleBlur}>
                                    <SearchableSelect ariaLabel='Select Medium' placeholder="Select Medium"
                                        onChange={(e: any) => selectMedium(e)}
                                        onBlur={addStandardForm.handleBlur}
                                        value={mediumId}
                                        isValid={addStandardForm.isValid}
                                        isTouched={addStandardForm.touched.mediumId} invalidFeedback={addStandardForm.errors.mediumId}
                                        list={mediumData.map((data: any) => ({ value: data.mediumId, label: data.medium }))} required />

                                </FormGroup>
                            </div>
                            <div className='col-12'>
                                <FormGroup id='standardDetailsId' label='Standard' onBlur={addStandardForm.handleBlur}>
                                    <SearchableSelect ariaLabel='Select Standard' placeholder="Select Standard"
                                        onChange={(e: any) => selectStandard(e)}
                                        onBlur={addStandardForm.handleBlur}
                                        value={standardDetailsId}
                                        isValid={addStandardForm.isValid}
                                        isTouched={addStandardForm.touched.standardDetailsId} invalidFeedback={addStandardForm.errors.standardDetailsId}
                                        list={standardData.map((data: any) => ({ value: data.standardDetailsId, label: data.standardName }))} required />

                                </FormGroup>
                            </div>

                            <div className='col-12'>
                                <FormGroup id='sectionName' label='Section'>
                                    <Input onChange={addStandardForm.handleChange} value={addStandardForm.values.sectionName} isValid={addStandardForm.isValid} onBlur={addStandardForm.handleBlur} isTouched={addStandardForm.touched.sectionName} invalidFeedback={addStandardForm.errors.sectionName} placeholder="Enter Section" required />
                                </FormGroup>
                            </div>
                        </div>
                    </OffCanvasBody>
                    <div className='row m-0'>
                        <div className='col-12 p-3 position-absolute top-95 start-50 translate-middle'>
                            <Button color='info' icon="Save" className='w-100' type="submit" isDisable={!addStandardForm.isValid && !!addStandardForm.submitCount}>
                                Save
                            </Button>
                        </div>
                    </div>
                </OffCanvas>

                <OffCanvas setOpen={setEditStandardCreationOffCanvas} isOpen={editStandardCreationOffCanvas} titleId='editStandardCreationOffCanvas' placement='end' tag="form" noValidate onSubmit={editStandardForm.handleSubmit}>
                    <OffCanvasHeader setOpen={setEditStandardCreationOffCanvas} onClick={closeAndFormReset}>
                        <OffCanvasTitle id="editStandardCreationOffCanvas">Update Class</OffCanvasTitle>
                    </OffCanvasHeader>
                    <OffCanvasBody>
                        <div className="row g-4">
                            <div className='col-12'>
                                <FormGroup id='mediumId' label='Medium' onBlur={editStandardForm.handleBlur}>
                                    <SearchableSelect ariaLabel='Select Medium' placeholder="Select Medium"
                                        onChange={(e: any) => selectMedium(e)}
                                        onBlur={editStandardForm.handleBlur}
                                        value={mediumId}
                                        isValid={editStandardForm.isValid}
                                        isTouched={editStandardForm.touched.mediumId} invalidFeedback={editStandardForm.errors.mediumId}
                                        list={mediumData.map((data: any) => ({ value: data.mediumId, label: data.medium }))} required />

                                </FormGroup>
                            </div>
                            <div className='col-12'>
                                <FormGroup id='standardDetailsId' label='Standard' onBlur={editStandardForm.handleBlur}>
                                    <SearchableSelect ariaLabel='Select Standard' placeholder="Select Standard"
                                        onChange={(e: any) => selectStandard(e)}
                                        onBlur={editStandardForm.handleBlur}
                                        value={standardDetailsId}
                                        isValid={editStandardForm.isValid}
                                        isTouched={editStandardForm.touched.standardDetailsId} invalidFeedback={editStandardForm.errors.standardDetailsId}
                                        list={standardData.map((data: any) => ({ value: data.standardDetailsId, label: data.standardName }))} required />

                                </FormGroup>
                            </div>

                            <div className='col-12'>
                                <FormGroup id='sectionName' label='Section'>
                                    <Input onChange={editStandardForm.handleChange} value={editStandardForm.values.sectionName} isValid={editStandardForm.isValid} onBlur={editStandardForm.handleBlur} isTouched={editStandardForm.touched.sectionName} invalidFeedback={editStandardForm.errors.sectionName} placeholder="Enter Section" required />
                                </FormGroup>
                            </div>
                            <div className="d-flex justify-content-center position-absolute top-65 start-50 translate-middle">
                                <DeleteComponents isDeleteOpen={isDeleteOpen} setIsDeleteOpen={setIsDeleteOpen} deleteFunction={deleteSectionDetailsSubmit} />
                            </div>
                        </div>
                        <div className='row top-90 m-0'>
                            <div className='col-6 position-absolute bottom-0 start-0 p-3'>
                                <Button color='danger' className='w-100' icon="Delete" onClick={() => setIsDeleteOpen(!isDeleteOpen)}>
                                    Delete
                                </Button>
                            </div>
                            <div className='col-6 position-absolute bottom-0 end-0 p-3'>
                                <Button color='info' icon="Save" className='w-100' type="submit" isDisable={!editStandardForm.isValid && !!editStandardForm.submitCount}>Update</Button>
                            </div>
                        </div>
                    </OffCanvasBody>
                </OffCanvas>

                <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />

            </PageWrapper>
        </>
    );
}
export default SectionCreation;