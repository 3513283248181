import { useFormik } from "formik";
import { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import SearchableSelect from "../../../../../common/components/SearchableSelect";
import AuthContext from "../../../../../contexts/authContext";
import Page from "../../../../../layout/Page/Page";
import PageWrapper from "../../../../../layout/PageWrapper/PageWrapper";
import AlertService from "../../../../../services/AlertService";
import { getLicenseKey } from "../../../../../services/application.settings";
import { addStudentManualTc } from "../../../../../services/certificate.service";
import { uploadImageForManualTc, convertDateToWords, getStandardDetails, profilePic } from "../../../../../services/common.service";
import { showLoader } from "../../../../../services/loader.services";
import { toasts } from "../../../../../services/toast.service";
import Button from "../../../../bootstrap/Button";
import Card, { CardHeader, CardLabel, CardTitle, CardBody } from "../../../../bootstrap/Card";
import FormGroup from "../../../../bootstrap/forms/FormGroup";
import Input from "../../../../bootstrap/forms/Input";

function ManualTcEntry() {

    useEffect(() => {
        getStandardList();
    }, [])

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const [standardDetailsData, setStandardDetailsData] = useState<any>([])
    const [dateOfBirthInWord, setDateOfBirthInWord] = useState<string>('');
    const [dateOfBirth, setDateOfBirth] = useState<string>('');
    const [selectedFile, setSelectedFile] = useState<any>()
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);
    const [isNavigate, setIsNavigate] = useState<any>(null);
    const navigate = useNavigate();

    const [standardDetailsId, setStandardDetailsId] = useState<any>('')

    const manualTcEntryForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            admissionNo: "",
            studentName: "",
            fatherOrMotherName: "",
            religion: "",
            nationality: "",
            caste: "",
            community: "",
            gender: "",
            dateOfBirth: "",
            dateOfBirthInWord: "",
            admissionDate: "",
            degreeAndCourse: "",
            timeOfLeaving: "",
            leavingDate: "",
            leavingReason: '',
            qualifiedForHigherStudy: "",
            transferCertificateDate: "",
            conductAndCharacter: "",
            medium: "",
            standardDetailsId: '',
        },
        validate: (values) => {
            const errors: {
                admissionNo?: string;
                studentName?: string;
                fatherOrMotherName?: string;
                gender?: string;
                standardDetailsId?: string;
                dateOfBirth?: string | null
            } = {};
            if (!values.admissionNo) {
                errors.admissionNo = 'Required';
            }
            if (!values.studentName) {
                errors.studentName = 'Required';
            }
            if (!values.fatherOrMotherName) {
                errors.fatherOrMotherName = 'Required';
            }
            if (!values.gender) {
                errors.gender = 'Required';
            }
            if (!standardDetailsId?.value) {
                errors.standardDetailsId = 'Required';
            }
            if (!dateOfBirth) {
                errors.dateOfBirth = 'Required';
            }
            return errors;

        },
        //validateOnChange: false,
        onSubmit: (values) => { manualTcSubmit() },
    })

    function onUpload(studentManualTcId: any) {
        const filedata = new FormData();
        filedata.append('profilePic', selectedFile, selectedFile.name)
        filedata.append('licenseKey', getLicenseKey.toString())
        filedata.append('userAccountId', userAccountId)
        filedata.append('studentManualTcId', studentManualTcId.toString())
        uploadImageForManualTc(filedata,
            (response) => {
                console.log('Profile pic upload');
            }
            , (error) => {
                console.log('Profile pic not upload');

            }
        )
    }

    const selectStandard = (e: any) => {
        let standardDetailsId = e
        setStandardDetailsId(standardDetailsId)
    }

    const convertDateOfBirth = (e: any) => {

        let dateOfBirth = e.target.value
        setDateOfBirth(dateOfBirth)
        setDateOfBirthInWord(convertDateToWords(dateOfBirth))
    };

    function setManualTcEntry() {
        return ({
            admissionNo: manualTcEntryForm.values.admissionNo,
            studentName: manualTcEntryForm.values.studentName,
            fatherOrMotherName: manualTcEntryForm.values.fatherOrMotherName,
            religion: manualTcEntryForm.values.religion,
            nationality: manualTcEntryForm.values.nationality,
            caste: manualTcEntryForm.values.caste,
            community: manualTcEntryForm.values.community,
            gender: manualTcEntryForm.values.gender,
            dateOfBirth: dateOfBirth ? dateOfBirth : null,
            dateOfBirthInWord: dateOfBirthInWord,
            admissionDate: manualTcEntryForm.values.admissionDate ? manualTcEntryForm.values.admissionDate : null,
            degreeAndCourse: manualTcEntryForm.values.degreeAndCourse,
            timeOfLeaving: manualTcEntryForm.values.timeOfLeaving ? manualTcEntryForm.values.timeOfLeaving : null,
            leavingDate: manualTcEntryForm.values.leavingDate ? manualTcEntryForm.values.leavingDate : null,
            leavingReason: manualTcEntryForm.values.leavingReason,
            qualifiedForHigherStudy: manualTcEntryForm.values.qualifiedForHigherStudy,
            transferCertificateDate: manualTcEntryForm.values.transferCertificateDate ? manualTcEntryForm.values.transferCertificateDate : null,
            conductAndCharacter: manualTcEntryForm.values.conductAndCharacter,
            medium: manualTcEntryForm.values.medium,
            standardDetailsId: standardDetailsId?.value,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId
        })
    }

    function manualTcSubmit() {
        showLoader(true)
        if (manualTcEntryForm.isValid) {
            let manualTcEntryPost = setManualTcEntry()
            addStudentManualTc(manualTcEntryPost,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {

                        let studentManualTcId = data.data.studentManualTcId
                        //onUpload(studentManualTcId);
                        if (selectedFile != undefined) {
                            onUpload(studentManualTcId);
                        }
                        showLoader(false)
                        setIsNavigate(`../academic/manualTcList`)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        //manualTcEntryForm.resetForm();
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                        setIsNavigate(null);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false)
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                        setIsNavigate(null);
                    }
                }, (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                    setIsNavigate(null);
                }
            )
        } else if (manualTcEntryForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
            setIsNavigate(null);
        }
    }

    function getStandardList() {
        getStandardDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.standardDetails;
                    if (data != undefined) {
                        setStandardDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setStandardDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    const handleFileChange = (e: any) => {
        if (e.target.files && e.target.files[0]) {
            setSelectedFile(e.target.files[0]);
        }
    };

    const handleDeleteAvatar = () => {
        setSelectedFile(null);
        const fileInput = document.getElementById('fileInput') as HTMLInputElement;
        if (fileInput) {
            fileInput.value = '';
        }
    };

    return (
        <PageWrapper title="Manual Tc Entry">
            <Page container='fluid'>
                <Card stretch data-tour='list'>
                    <CardHeader borderSize={1}>
                        <CardLabel icon='List' iconColor='info' className="col-lg-5">
                            <CardTitle tag='div' className='h5'>Manual Tc Entry</CardTitle>
                        </CardLabel>
                    </CardHeader>
                    <CardBody >
                        <form noValidate onSubmit={manualTcEntryForm.handleSubmit}>
                            <div className='row'>
                                <div className="d-grid gap-4">
                                    <div className="row">
                                        <div className="col-lg-6 d-flex align-items-center">
                                            <div className='col d-flex justify-content-end align-self-center'>
                                                <div className="d-flex justify-content-end">
                                                    <div className="d-flex justify-content-end align-self-center" style={{ marginRight: '10px' }}>
                                                        <p className="h6">Admission No :</p>
                                                    </div>
                                                    <div>
                                                        <FormGroup id="admissionNo" label="" >
                                                            <Input type="text" style={{ width: '400px' }} placeholder="Admission Number" onChange={manualTcEntryForm.handleChange} value={manualTcEntryForm.values.admissionNo} onBlur={manualTcEntryForm.handleBlur} isValid={manualTcEntryForm.isValid} isTouched={manualTcEntryForm.touched.admissionNo} invalidFeedback={manualTcEntryForm.errors.admissionNo} />
                                                        </FormGroup>
                                                    </div></div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="row">
                                                <div className="col-lg-1 align-self-center h6"></div>
                                                <div className='col-xl-auto'>
                                                    {selectedFile ?
                                                        <img style={{ borderRadius: '50%' }}
                                                            src={URL.createObjectURL(selectedFile)}
                                                            height='120' width='120' />
                                                        :
                                                        <img style={{ borderRadius: '50%' }}
                                                            src={profilePic(1)}
                                                            height='120' width='120'
                                                        />}
                                                </div>
                                                <div className="col-xl-auto">
                                                    <Input
                                                        type='file'
                                                        id="fileInput"
                                                        className="mt-3"
                                                        autoComplete='photo'
                                                        ariaLabel='Upload image file'
                                                        //onChange={(e) => { onFileSelected(e, setSelectedFile) }}
                                                        onChange={handleFileChange}
                                                    />
                                                    <Button
                                                        className="mt-4"
                                                        color='dark'
                                                        isLight
                                                        icon='Delete'
                                                        //onClick={() => setSelectedFile('')}
                                                        onClick={handleDeleteAvatar}
                                                    >
                                                        Delete Avatar
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row  '>
                                        <div className='col-lg-12 d-grid gap-4'>
                                            <div className="row  justify-content-start">
                                                <div className="col-lg-1 align-self-center h6">
                                                    <p>1.</p>
                                                </div>
                                                <div className="col-lg-5 align-self-center">
                                                    <p className="h6">Standard :</p>
                                                </div>
                                                <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                                <div className="col-lg-5 justify-content-center">
                                                    <FormGroup id="standardDetailsId"  >
                                                        <SearchableSelect ariaLabel={""} placeholder="Select Standard"
                                                            onChange={selectStandard}
                                                            value={standardDetailsId}
                                                            list={standardDetailsData.map((data: any) => (
                                                                { value: data.standardDetailsId, label: data.standardName }
                                                            ))}
                                                            onBlur={manualTcEntryForm.handleBlur}
                                                            isValid={manualTcEntryForm.isValid}
                                                            isTouched={manualTcEntryForm.touched.standardDetailsId}
                                                            invalidFeedback={manualTcEntryForm.errors.standardDetailsId} />
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div className="row justify-content-start">
                                                <div className="col-lg-1 align-self-center h6">
                                                    <p>2.</p>
                                                </div>
                                                <div className="col-lg-5 align-self-center">
                                                    <p className="h6">Name of the Student ( in block letters ) ( as entered in +2 or equivalent Certificate )</p>
                                                </div>
                                                <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                                <div className="col-lg-5 justify-content-center">
                                                    <FormGroup id="studentName" label="">
                                                        <Input type="text" placeholder="Name Of Student" onChange={manualTcEntryForm.handleChange} value={manualTcEntryForm.values.studentName} onBlur={manualTcEntryForm.handleBlur} isValid={manualTcEntryForm.isValid} isTouched={manualTcEntryForm.touched.studentName} invalidFeedback={manualTcEntryForm.errors.studentName} />
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-lg-1 align-self-center h6">
                                                    <p>3.</p>
                                                </div>
                                                <div className="col-lg-5 align-self-center">
                                                    <p className="h6">Name of the Father or Mother of the Puplil </p>
                                                </div>
                                                <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                                <div className="col-lg-5 justify-content-center">
                                                    <FormGroup id="fatherOrMotherName" label=""  >
                                                        <Input type="text" placeholder="Name Of Father Or Mother" onChange={manualTcEntryForm.handleChange} value={manualTcEntryForm.values.fatherOrMotherName} onBlur={manualTcEntryForm.handleBlur} isValid={manualTcEntryForm.isValid} isTouched={manualTcEntryForm.touched.fatherOrMotherName} invalidFeedback={manualTcEntryForm.errors.fatherOrMotherName} />
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-lg-1 align-self-center h6">
                                                    <p>4.</p>
                                                </div>
                                                <div className="col-lg-5 align-self-center"><p className="h6">Nationality</p>
                                                </div>
                                                <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                                <div className="col-lg-5 justify-content-center">
                                                    <FormGroup id="nationality" label="">
                                                        <Input type="text" placeholder="Nationality" onChange={manualTcEntryForm.handleChange} value={manualTcEntryForm.values.nationality} />
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-lg-1 align-self-center h6">
                                                    <p>5.</p>
                                                </div>
                                                <div className="col-lg-5 align-self-center"><p className="h6">Religion</p>
                                                </div>
                                                <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                                <div className="col-lg-5 justify-content-center">
                                                    <FormGroup id="religion" label="">
                                                        <Input type="text" placeholder="Religion"
                                                            onChange={manualTcEntryForm.handleChange}
                                                            value={manualTcEntryForm.values.religion} />
                                                    </FormGroup>
                                                </div>
                                            </div>
                                           <div className="row justify-content-center">
                                                <div className="col-lg-1 align-self-center h6">
                                                    <p>6.</p>
                                                </div>
                                                <div className="col-lg-5 align-self-center"><p className="h6">Gender</p>
                                                </div>
                                                <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                                <div className="col-lg-5 justify-content-center">
                                                    <FormGroup id="gender" label="">
                                                        <Input type="text" placeholder="Male Or Female"
                                                            onChange={manualTcEntryForm.handleChange}
                                                            value={manualTcEntryForm.values.gender}
                                                            onBlur={manualTcEntryForm.handleBlur}
                                                            isValid={manualTcEntryForm.isValid}
                                                            isTouched={manualTcEntryForm.touched.gender}
                                                            invalidFeedback={manualTcEntryForm.errors.gender} />
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-lg-1 align-self-center h6">
                                                    <p>7.</p>
                                                </div>
                                                <div className="col-lg-5 align-self-center"><p className="h6">Date of Birth as entered in the Admission</p>
                                                </div>
                                                <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                                <div className="col-lg-5 justify-content-center">
                                                    <FormGroup id="dateOfBirth" label="">
                                                        <Input type="date" placeholder="Date Of Birth"

                                                            onChange={convertDateOfBirth}
                                                            value={dateOfBirth}
                                                            onBlur={manualTcEntryForm.handleBlur}
                                                            isValid={manualTcEntryForm.isValid}
                                                            isTouched={manualTcEntryForm.touched.dateOfBirth}
                                                            invalidFeedback={manualTcEntryForm.errors.dateOfBirth} />
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-lg-1 align-self-center h6">
                                                    <p>8.</p>
                                                </div>
                                                <div className="col-lg-5 align-self-center"><p className="h6">Date of Birth in Word</p>
                                                </div>
                                                <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                                <div className="col-lg-5 justify-content-center">
                                                    <FormGroup id="dateOfBirthInWord" label="">
                                                        <Input type="text" placeholder="Date Of Birth" value={dateOfBirthInWord} disabled />
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-lg-1 align-self-center h6">
                                                    <p>9.</p>
                                                </div>
                                                <div className="col-lg-5 align-self-center">
                                                    <p className="h6">Date of Admission and class in which admitted</p>
                                                </div>
                                                <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                                <div className="col-lg-5 justify-content-center">
                                                    <FormGroup id="admissionDate" label="">
                                                        <Input type="date" placeholder="Date of Admission"
                                                            onChange={manualTcEntryForm.handleChange}
                                                            value={manualTcEntryForm.values.admissionDate} />
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-lg-1 align-self-center h6">
                                                    <p>10.</p>
                                                </div>
                                                <div className="col-lg-5 align-self-center">
                                                    <p className="h6">Degree and Programme to which the student was admitted</p>
                                                </div>
                                                <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                                <div className="col-lg-5 justify-content-center">
                                                    <FormGroup id="degreeAndCourse" label="">
                                                        <Input type="text" placeholder="Degree and Programme" onChange={manualTcEntryForm.handleChange} value={manualTcEntryForm.values.degreeAndCourse} />
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-lg-1 align-self-center h6">
                                                    <p>11.</p>
                                                </div>
                                                <div className="col-lg-5 align-self-center">
                                                    <p className="h6">Class in which the pupil was studying at the time of leaving</p>
                                                </div>
                                                <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                                <div className="col-lg-5 justify-content-center">
                                                    <FormGroup id="timeOfLeaving" label="">
                                                        <Input type="text" placeholder="Class" onChange={manualTcEntryForm.handleChange} value={manualTcEntryForm.values.timeOfLeaving} />
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-lg-1 align-self-center h6">
                                                    <p>12.</p>
                                                </div>
                                                <div className="col-lg-5 align-self-center">
                                                    <p className="h6">Date on which the student actually left the College</p>
                                                </div>
                                                <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                                <div className="col-lg-5 justify-content-center">
                                                    <FormGroup id="leavingDate" label="">
                                                        <Input type="date" placeholder="Date" onChange={manualTcEntryForm.handleChange} value={manualTcEntryForm.values.leavingDate} />
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-lg-1 align-self-center h6">
                                                    <p>13.</p>
                                                </div>
                                                <div className="col-lg-5 align-self-center">
                                                    <p className="h6">Reason for which the student left the College</p>
                                                </div>
                                                <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                                <div className="col-lg-5 justify-content-center">
                                                    <FormGroup id="leavingReason" label="">
                                                        <Input type="text" placeholder="Reason" onChange={manualTcEntryForm.handleChange} value={manualTcEntryForm.values.leavingReason} />
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-lg-1 align-self-center h6">
                                                    <p>14.</p>
                                                </div>
                                                <div className="col-lg-5 align-self-center">
                                                    <p className="h6">
                                                        Whether qualified for promotion to higher studies
                                                    </p>
                                                </div>
                                                <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                                <div className="col-lg-5 justify-content-center">
                                                    <FormGroup id="qualifiedForHigherStudy" label="">
                                                        <Input type="text" placeholder="Qualified for promotion" onChange={manualTcEntryForm.handleChange} value={manualTcEntryForm.values.qualifiedForHigherStudy} />
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-lg-1 align-self-center h6">
                                                    <p>15.</p>
                                                </div>
                                                <div className="col-lg-5 align-self-center">
                                                    <p className="h6">Date of the Transfer Certificate</p>
                                                </div>
                                                <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                                <div className="col-lg-5 justify-content-center">
                                                    <FormGroup id="transferCertificateDate" label="">
                                                        <Input type="date" placeholder="Date Of Transfer Certificate" onChange={manualTcEntryForm.handleChange} value={manualTcEntryForm.values.transferCertificateDate} />
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-lg-1 align-self-center h6">
                                                    <p>16.</p>
                                                </div>
                                                <div className="col-lg-5 align-self-center">
                                                    <p className="h6">Conduct and Character</p>
                                                </div>
                                                <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                                <div className="col-lg-5 justify-content-center">
                                                    <FormGroup id="conductAndCharacter" label="">
                                                        <Input type="text" placeholder="Conduct And Character" onChange={manualTcEntryForm.handleChange} value={manualTcEntryForm.values.conductAndCharacter} />
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-lg-1 align-self-center h6">
                                                    <p>17.</p>
                                                </div>
                                                <div className="col-lg-5 align-self-center">
                                                    <p className="h6">Medium(for M.Phil Only)</p>
                                                </div>
                                                <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                                <div className="col-lg-5 justify-content-center">
                                                    <FormGroup id="medium" label="">
                                                        <Input type="text" placeholder="Medium" onChange={manualTcEntryForm.handleChange} value={manualTcEntryForm.values.medium} />
                                                    </FormGroup>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-6 d-flex justify-content-start'>

                                        </div>
                                        <div className='col-lg-6 d-flex justify-content-end'>
                                            <FormGroup>
                                                <Button icon='Save' color='info' type='submit'>Save</Button>
                                            </FormGroup>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </CardBody>
                </Card>
            </Page>

            <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} isNavigate={isNavigate} />

        </PageWrapper>
    )
}
export default ManualTcEntry;