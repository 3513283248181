import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { webUrl } from '../services/application.settings';
// import CollegeLogo from '../assets/logos/logo.jpg';

interface ILogoProps {
	width?: number;
	height?: number;
}
const Logo: FC<ILogoProps> = ({ width, height }) => {
	return (
		<img src={`${webUrl}assets/logos/logo.jpg`}
			width={width}
			height={height}
		/>
	);
};
Logo.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
};
Logo.defaultProps = {
	width: 2155,
	height: 854,
};

export default Logo;
