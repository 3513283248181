import { useEffect, useState } from "react";
import OpenCardComponent from "../../../../common/components/OpenCardComponent";
import SearchableSelect from "../../../../common/components/SearchableSelect";
import Page from "../../../../layout/Page/Page";
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";
import { toasts } from "../../../../services/toast.service";
import { getVehicleDetails, getVehicleDetailsByVehicleDetailsId, getVehicleScannedDocumentDetails, vehicleDocumentUpload } from "../../../../services/transport.service";
import Button from "../../../bootstrap/Button";
import Card, { CardHeader, CardLabel, CardTitle, CardBody, CardSubTitle } from "../../../bootstrap/Card";
import Collapse from "../../../bootstrap/Collapse";
import FormGroup from "../../../bootstrap/forms/FormGroup";
import Input from "../../../bootstrap/forms/Input";
import { showLoader } from "../../../../services/loader.services";
import AlertService from "../../../../services/AlertService";
import { getLicenseKey } from "../../../../services/application.settings";
import { any } from "prop-types";
import classNames from "classnames";
import useDarkMode from "../../../../hooks/useDarkMode";
import Icon from "../../../icon/Icon";
import Popovers from "../../../bootstrap/Popovers";
import Tooltips from "../../../bootstrap/Tooltips";

function VehicleScannedDocument() {

    useEffect(() => {
        getVehicleDetailsList();
    }, [])

    const [isOpenListCard, setIsOpenListCard] = useState(true)
    const [vehicleDetailsId, setVehicleDetailsId] = useState<any>([])
    const [vehicleDetailsData, setVehicleDetailsData] = useState<any>([])
    const [vehicleDetails, setVehicleDetails] = useState<any>([])
    const [dataSuccess, setDataSuccess] = useState(false)
    const [isLoader, setIsLoader] = useState(false)

    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);

    const { themeStatus, darkModeStatus } = useDarkMode();

    const [inputList, setInputList] = useState<any>([{ documentName: "", document: any, displayOrder: 1 }])
    const [fileUploadArray, setFileUploadArray] = useState<any>([])
    const [count, setCount] = useState(2)

    const selectVehicleDetails = (e: any) => {
        setDataSuccess(false)
        setFileUploadArray([])
        setVehicleScannedDocumentDetails([])
        setInputList([{ documentName: "", document: "", displayOrder: 1 }])
        let vehicleDetailsId = e
        setVehicleDetailsId(vehicleDetailsId)
    }

    function onUpload() {

        showLoader(true)

        let checkArrayData = fileUploadArray.filter((item: any) => item.document == '')

        if (checkArrayData == '') {

            let lastEle = fileUploadArray[fileUploadArray.length - 1];

            for (let i = 0; i < fileUploadArray.length; i++) {

                const filedata = new FormData();
                filedata.append('document', fileUploadArray[i].document, fileUploadArray[i].document.name)
                filedata.append('licenseKey', getLicenseKey.toString())
                filedata.append('vehicleDetailsId', vehicleDetailsId?.value)
                filedata.append('documentName', fileUploadArray[i].documentName)

                vehicleDocumentUpload(filedata,
                    (response) => {
                        if (response.data.success == true) {
                            if (lastEle.displayOrder == fileUploadArray[i].displayOrder) {
                                showLoader(false)
                                setAlertStatus({ message: response.data.message, type: "success" });
                                setIsOpen(true);
                                closeAndReset()
                                view()
                                getVehicleScannedDocumentList(vehicleDetailsId?.value)
                            }
                        }
                        else if (response.data.success == false) {
                            if (lastEle.displayOrder == fileUploadArray[i].displayOrder) {
                                showLoader(false)
                                setAlertStatus({ message: response.data.message, type: "error" });
                                setIsOpen(true);
                            }
                        }
                    }
                    , (error) => {
                        showLoader(false)
                        setAlertStatus({ message: error, type: 'error' });
                        setIsOpen(true);
                    }
                )
            }
        }
        else {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all details', type: 'error' });
            setIsOpen(true);
        }
    }

    function view() {
        setIsLoader(true)
        setDataSuccess(false)
        getVehicleDetailsByVehicleDetailsList(vehicleDetailsId?.value)
        getVehicleScannedDocumentList(vehicleDetailsId?.value)
    }

    function getVehicleDetailsList() {
        getVehicleDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.vehicleDetails;
                    if (data != undefined) {
                        setVehicleDetails(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setVehicleDetails([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getVehicleDetailsByVehicleDetailsList(vehicleDetailsId: any) {
        getVehicleDetailsByVehicleDetailsId(vehicleDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.vehicleDetails[0];
                    if (data != undefined) {
                        setVehicleDetailsData(data);
                        setIsOpenListCard(false);
                        setDataSuccess(true)
                        setIsLoader(false)
                    } else {
                        toasts("Undefined Data", "Error")
                        setIsLoader(false)
                        setIsOpenListCard(true);
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                    setIsLoader(false)
                    setIsOpenListCard(true);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                    setIsLoader(false)
                    setIsOpenListCard(true);
                }
            }, error => {
                toasts(error, "Error")
                setIsLoader(false)
            }
        )
    }

    const [vehicleScannedDocumentData, setVehicleScannedDocumentDetails] = useState<any>([])

    function getVehicleScannedDocumentList(vehicleDetailsId: any) {
        getVehicleScannedDocumentDetails(vehicleDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.vehicleScannedDocumentDetails;
                    if (data != undefined) {
                        setVehicleScannedDocumentDetails(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setVehicleScannedDocumentDetails([])
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                    setIsLoader(false)
                    setIsOpenListCard(true);
                }
            }, error => {
                toasts(error, "Error")
                setIsLoader(false)
            }
        )
    }

    // function sparePartsSubmit() {
    //     showLoader(true)
    //     if (sparePartsForm.isValid) {
    //         let sparePartsPostData = setSparePartsDetails()
    //         addVehicleScannedDocument(sparePartsPostData,
    //             (response) => {
    //                 const data = response.data;
    //                 if (data.success == true) {
    //                     showLoader(false)
    //                     setAlertStatus({ message: data.message, type: "success" });
    //                     setIsOpen(true);
    //                     closeAndReset();
    //                     selectVehicleSpareDetails();
    //                 }
    //                 else if (data.success == false) {
    //                     showLoader(false)
    //                     setAlertStatus({ message: data.message, type: "error" });
    //                     setIsOpen(true);
    //                 }
    //                 else {
    //                     let errorCode = response.data.error[0].error_code;
    //                     let errorDescription = response.data.error[0].error_description;
    //                     showLoader(false)
    //                     setAlertStatus({ message: errorDescription, type: "error" });
    //                     setIsOpen(true);
    //                 }
    //             }
    //             , (error) => {
    //                 showLoader(false)
    //                 setAlertStatus({ message: error, type: "error" });
    //                 setIsOpen(true);
    //             }
    //         )
    //     } else if (sparePartsForm.isValid == false) {
    //         showLoader(false)
    //         setAlertStatus({ message: 'Please fill all the details!', type: "error" });
    //         setIsOpen(true);
    //     }
    // }

    function add() {
        for (let i = 0; i < count; i++) {
            setCount(count + 1)
        }
        setInputList([...inputList, { documentName: '', document: '', displayOrder: count }])
    }

    function handleInputChange(documentName: any, document: any, index: any) {

        const newData: any = [];
        const list = [...inputList];

        setInputList(list);

        inputList.forEach((item: any) => {
            if (item['displayOrder'] == index) {
                item['documentName'] = documentName
                item['document'] = document
            }
            newData.push(item)

        });
        setInputList(newData)

        if (fileUploadArray.length == 0 && document != '') {
            var obj
            obj = {
                displayOrder: index,
                documentName: documentName,
                document: document,
                vehicleDetailsId: vehicleDetailsId?.value,
                licenseKey: getLicenseKey.toString()
            };
            fileUploadArray.push(obj);
        }
        else {
            let selectedItem: any
            fileUploadArray.forEach((item: any) => {
                if (item['displayOrder'] == index) {
                    selectedItem = item
                }
            });
            if (selectedItem != undefined) {
                selectedItem['documentName'] = documentName
                selectedItem['document'] = document
            } else {
                var obj
                obj = {
                    displayOrder: index,
                    documentName: documentName,
                    document: document,
                    vehicleDetailsId: vehicleDetailsId?.value,
                    licenseKey: getLicenseKey.toString()
                };
                fileUploadArray.push(obj);
            }
        }
    }

    function handleFileChange(file: any, index: any) {

        const newData: any = [];
        const list = [...inputList];

        let e = file.target.files[0]

        console.log(e);
        

        inputList.forEach((item: any, index: any) => {
            if (item['displayOrder'] == index) {
                item['document'] = e
            }
            newData.push(item)
        });
        setInputList(newData)

        if (fileUploadArray.length == 0) {
            var obj
            obj = {
                displayOrder: index,
                document: e
            };
            fileUploadArray.push(obj);
        }
        else {
            let selectedItem: any
            fileUploadArray.forEach((item: any) => {
                if (item['displayOrder'] == index) {
                    selectedItem = item
                }
            });
            if (selectedItem != undefined) {
                selectedItem['document'] = e
            } else {
                var obj
                obj = {
                    displayOrder: index,
                    document: e,
                };
                fileUploadArray.push(obj);
            }
        }
    }

    function handleRemove(index: any) {

        const list = [...inputList];
        list.splice(index, 1)
        setInputList(list)
        let selectedItem
        fileUploadArray.forEach((item: any) => {
            if (item['displayOrder'] == index) {
                selectedItem = item
                fileUploadArray.splice(fileUploadArray.indexOf(selectedItem), 1);
            }
        });
    }

    function onDownload(i: any) {
        if (i != undefined) {
            let selectedData: any = i
            window.open(selectedData.certificatePath, '_blank');
        }
    }

    function closeAndReset() {
        setFileUploadArray([])
        setVehicleScannedDocumentDetails([])
        setInputList([{ documentName: "", document: "", displayOrder: 1 }])
    }

    let sno = 1;

    return (
        <>
            <PageWrapper title="Vehicle Scanned Document">
                <Page container='fluid'>
                    <Collapse isOpen={isOpenListCard}>
                        <Card stretch data-tour='list'>
                            <CardHeader borderSize={1}>
                                <CardLabel icon='List' iconColor='info'>
                                    <CardTitle tag='div' className='h5'>
                                        Vehicle Scanned Document
                                    </CardTitle>
                                </CardLabel>
                            </CardHeader>
                            <CardBody>
                                <div className='row'>
                                    <div className='col-4'>
                                        <FormGroup id='vehicleDetailsData' label='Vehicle' isFloating>
                                            <SearchableSelect isFloating ariaLabel=""
                                                onChange={selectVehicleDetails}
                                                value={vehicleDetailsId}
                                                list={vehicleDetails.map((data: any) => (
                                                    { value: data.vehicleDetailsId, label: data.vehicleId }
                                                ))} />
                                        </FormGroup>
                                    </div>

                                    <div className="col-3 mt-2">
                                        <Button icon='ArrowDownward' color='primary' isDisable={vehicleDetailsId?.value == undefined} onClick={view}>View</Button>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Collapse>

                    <OpenCardComponent isOpenListCard={isOpenListCard} setIsOpenListCard={setIsOpenListCard} isLoader={isLoader} />

                    {dataSuccess ?
                        <Card>
                            <CardHeader borderSize={1}>
                                <CardLabel icon='DirectionsBus' iconColor='info'>
                                    <CardTitle tag='div' className='h5'>
                                        {vehicleDetailsId?.label}
                                    </CardTitle>
                                    <CardSubTitle className="text-primary">
                                        Vehicle File Upload
                                    </CardSubTitle>
                                </CardLabel>
                            </CardHeader>
                            <CardBody>
                                <table className='table table-modern table-hover text-nowrap'>
                                    <thead className='table-primary text-center'>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Document</th>
                                            <th>File</th>
                                            {inputList.length > 1 &&
                                                <th>Remove</th>
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {inputList.map((row: any, i: any) => {
                                            return (
                                                <tr className='text-center' key={i}>
                                                    <td>{sno++}</td>
                                                    <td >
                                                        <FormGroup id="documentName">
                                                            <Input type='text' placeholder="Enter Document Name" value={row.documentName} onInput={(e: any) => handleInputChange(e.target.value, row.document, row.displayOrder)} />
                                                        </FormGroup>
                                                    </td>
                                                    <td>
                                                        <Input type='file' autoComplete='photo' ariaLabel='Upload image file' onChange={(e) => handleFileChange(e, row.displayOrder)} />
                                                    </td>
                                                    {inputList.length > 1 &&
                                                        <td>
                                                            <Button color='danger' isLight rounded={1} icon='Delete' onClick={() => handleRemove(i)} />
                                                        </td>
                                                    }
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>

                                <div className="col-lg-12 mt-2 d-flex justify-content-end">
                                    <Button color='info' isLight rounded={1} icon='Add' onClick={add}>Add</Button> &nbsp;&nbsp;
                                    <Button color='info' isLight rounded={1} icon='CloudUpload' onClick={onUpload} isDisable={fileUploadArray.length == 0 ? true : false}>Upload</Button>
                                </div>
                            </CardBody>
                        </Card> : null
                    }

                    {vehicleScannedDocumentData != '' ?
                        <Card stretch data-tour='list'>
                            <CardHeader borderSize={1}>
                                <CardLabel icon='List' iconColor='info'>
                                    <CardTitle tag='div' className='h5'>
                                        Document List
                                    </CardTitle>
                                </CardLabel>
                            </CardHeader>
                            <CardBody>
                                <div className='col-12'>
                                    <div className='row'>
                                        {vehicleScannedDocumentData.map((data: any) => (
                                            <div className='col-3' key={data.vehicleScannedDocumentId}>
                                                <Card>
                                                    <CardBody>
                                                        <div className='row g-3 align-items-center'>
                                                            <div className='col d-flex align-items-center'>
                                                                <div className='flex-grow-1 ms-2 d-flex justify-content-between align-items-center'>
                                                                    <div>
                                                                        <div className='fw-bold text-primary fs-6 mb-0'>
                                                                            {data.registrationNo}
                                                                        </div>
                                                                        <div className='text-success'>
                                                                            <span className='fw-bold'>
                                                                                {data.documentName}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <Tooltips title='click to download' placement='top'>
                                                                    <div className='flex-shrink-0'>
                                                                        <div className='ratio ratio-1x1' style={{ width: 62 }}>
                                                                            <div onClick={() => onDownload(data)}
                                                                                className={classNames('rounded-2', 'd-flex align-items-center justify-content-center', { 'bg-l10-info': !darkModeStatus, 'bg-lo25-info': darkModeStatus, })}>
                                                                                <span className='text-info fs-1 fw-bold'>
                                                                                    <Icon className='cursor-pointer ' icon="FileDownload" />
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Tooltips>
                                                            </div>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </CardBody>
                        </Card> : null
                    }
                </Page>
                <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
            </PageWrapper>

        </>
    )
}
export default VehicleScannedDocument;