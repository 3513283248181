import { useFormik } from "formik"
import Page from "../../../../layout/Page/Page"
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper"
import Button, { ButtonGroup } from "../../../bootstrap/Button"
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../../../bootstrap/Card"
import FormGroup from "../../../bootstrap/forms/FormGroup"
import Input from "../../../bootstrap/forms/Input"
import Select from "../../../bootstrap/forms/Select"
import { toasts } from "../../../../services/toast.service"
import { useContext, useEffect, useRef, useState } from "react"
import Option from "../../../bootstrap/Option"
import { convertJsonToPdf, downloadExcel, downloadFile, printPage } from "../../../../services/ExportService";
import { useReactToPrint } from "react-to-print";
import Icon from "../../../icon/Icon"
import useSortableData from "../../../../hooks/useSortableData"
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from "../../../bootstrap/Dropdown"
import Checks, { ChecksGroup } from "../../../bootstrap/forms/Checks"
import PaginationButtons, { dataPagination } from "../../../PaginationButtons"
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from "../../../bootstrap/OffCanvas"
import AlertService from "../../../../services/AlertService"
import useDarkMode from "../../../../hooks/useDarkMode"
import classNames from "classnames"
import { addVehicleTyreDetails, getVehicleTyreDetails, updateVehicleTyreDetails } from "../../../../services/tyre.service"
import { getColumnsForDataTable, updateColumnsForDataTable, updateFilter } from "../../../../services/common.service"
import AuthContext from "../../../../contexts/authContext"
import { getLicenseKey } from "../../../../services/application.settings"
import { TableLoader, showLoader } from "../../../../services/loader.services"
import NoDataMsg from "../../../../common/components/NoDataMsg"


const TyreCreation = () => {

    useEffect(() => {
        getVehicleTyreDetailsList()
    }, [])

    const [dataStatus, setDataStatus] = useState(false)
    const { userAccountId, userTypeId } = useContext(AuthContext);
    const componentRef = useRef(null);
    const { themeStatus, darkModeStatus } = useDarkMode();
    const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
    const [columnVisibilityData, setColumnVisibilityData] = useState([]);
    const [tyreDetailsData, setTyreDetailsData] = useState([]);
    const [isChecked, setIsChecked] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [allColumnsData, setAllColumnsData] = useState([]);
    const [columnDataById, setColumnDataById] = useState([])
    const { items, requestSort, getClassNamesFor } = useSortableData(tyreDetailsData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);
    const [isOpen, setIsOpen] = useState(false);
    const [addTyreCreationOffCanvas, setAddTyreCreationOffcanvas] = useState(false);
    const [addTyreEditOffCanvas, setTyreEditOffCanvas] = useState(false);
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });

    const [noDataMsg, setNoDataMsg] = useState('')

    const addTyreForm = useFormik({

        enableReinitialize: true,
        initialValues: {
            licenseKey: "",
            userAccountId: 1,
            tyreId: "",
            tyreNo: "",
            retreading: '',
            purchaseDate: "",
            amount: "",
            tyreBrand: "",
            vendorName: "",
            model: "",
            sizeOfTyre: ""

        },
        validate: (values) => {
            const errors: {
                tyreId?: string;
                tyreNo?: string;
                retreading?: string;
                purchaseDate?: string;
                amount?: string;
                tyreBrand?: string;
                vendorName?: string;
                model?: string;
                sizeOfTyre?: string;


            } = {};

            if (!values.tyreNo) {
                errors.tyreNo = 'Required';
            }
            if (!values.retreading) {
                errors.retreading = 'Required';
            }
            if (!values.purchaseDate) {
                errors.purchaseDate = 'Required';
            }
            if (!values.amount) {
                errors.amount = 'Required';
            }
            if (!values.tyreBrand) {
                errors.tyreBrand = 'Required';
            }
            if (!values.vendorName) {
                errors.vendorName = 'Required';
            }
            if (!values.model) {
                errors.model = 'Required';
            }
            if (!values.sizeOfTyre) {
                errors.sizeOfTyre = 'Required';
            }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: () => { TyreCreationSubmit() },
    });

    const editTyreForm = useFormik({

        enableReinitialize: true,
        initialValues: {
            vehicleTyreDetailsId: '',
            tyreId: "",
            tyreNo: "",
            retreading: '',
            purchaseDate: "",
            amount: "",
            tyreBrand: "",
            vendorName: "",
            model: "",
            sizeOfTyre: ""

        },
        validate: (values) => {
            const errors: {
                tyreId?: string;
                tyreNo?: string;
                retreading?: string;
                purchaseDate?: string;
                amount?: string;
                tyreBrand?: string;
                vendorName?: string;
                model?: string;
                sizeOfTyre?: string;


            } = {};

            if (!values.tyreNo) {
                errors.tyreNo = 'Required';
            }
            if (!values.retreading) {
                errors.retreading = 'Required';
            }
            if (!values.purchaseDate) {
                errors.purchaseDate = 'Required';
            }
            if (!values.amount) {
                errors.amount = 'Required';
            }
            if (!values.tyreBrand) {
                errors.tyreBrand = 'Required';
            }
            if (!values.vendorName) {
                errors.vendorName = 'Required';
            }
            if (!values.model) {
                errors.model = 'Required';
            }
            if (!values.sizeOfTyre) {
                errors.sizeOfTyre = 'Required';
            }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: () => { updateTyreCreation() },
    });

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },

        //validateOnChange: false,
        onSubmit: () => { },
    });
    // Filter
    // const filteredData = onCurrentPageData.filter(
    //     (i) =>
    //         i.tyreId.toString().toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase()) ||
    //         i.tyreNo.toString().toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase()) ||
    //         i.retreading.toString().toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase()) ||
    //         i.purchaseDate.toString().toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase()) ||
    //         i.amount.toString().toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase()) ||
    //         i.tyreBrand.toString().toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase()) ||
    //         i.vendorName.toString().toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase()) ||
    //         i.model.toString().toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase()) ||
    //         i.sizeOfTyre.toString().toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase())
    // );

    const filteredData = updateFilter(onCurrentPageData, columnVisibilityData, columnVisibilityForm);

    function onActivate(i: any) {
        if (i != undefined) {

            let selectedTyreCreation = i
            setTyreEditOffCanvas(true)

            editTyreForm.setValues({
                vehicleTyreDetailsId: selectedTyreCreation.vehicleTyreDetailsId,
                tyreId: selectedTyreCreation.tyreId,
                tyreNo: selectedTyreCreation.tyreNo,
                retreading: selectedTyreCreation.retreading,
                purchaseDate: selectedTyreCreation.purchaseDate,
                amount: selectedTyreCreation.amount,
                tyreBrand: selectedTyreCreation.tyreBrand,
                vendorName: selectedTyreCreation.vendorName,
                model: selectedTyreCreation.model,
                sizeOfTyre: selectedTyreCreation.sizeOfTyre
            });
        }
    }

    function setTyreDetails() {

        return ({
            licenseKey: getLicenseKey,
            userAccountId: userAccountId,
            tyreId: addTyreForm.values.tyreId,
            tyreNo: addTyreForm.values.tyreNo,
            retreading: addTyreForm.values.retreading,
            purchaseDate: addTyreForm.values.purchaseDate,
            amount: addTyreForm.values.amount,
            tyreBrand: addTyreForm.values.tyreBrand,
            vendorName: addTyreForm.values.vendorName,
            model: addTyreForm.values.model,
            sizeOfTyre: addTyreForm.values.sizeOfTyre,
            description: addTyreForm.values.tyreId
        })
    }

    function updateTyreDetails() {
        return ({
            vehicleTyreDetailsId: editTyreForm.values.vehicleTyreDetailsId,
            tyreId: editTyreForm.values.tyreId,
            tyreNo: editTyreForm.values.tyreNo,
            retreading: editTyreForm.values.retreading,
            purchaseDate: editTyreForm.values.purchaseDate,
            amount: editTyreForm.values.amount,
            tyreBrand: editTyreForm.values.tyreBrand,
            vendorName: editTyreForm.values.vendorName,
            model: editTyreForm.values.model,
            sizeOfTyre: editTyreForm.values.sizeOfTyre,
            description: editTyreForm.values.tyreId,
            isActive: '1',
            licenseKey: getLicenseKey,
            userAccountId: userAccountId
        })
    }
    function TyreCreationSubmit() {
        showLoader(true)
        if (addTyreForm.isValid) {
            let addTyerPostData = setTyreDetails()
            addVehicleTyreDetails(addTyerPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        closeAndReset();
                        getVehicleTyreDetailsList()
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        showLoader(false)
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (addTyreForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }
    function updateTyreCreation() {
        showLoader(true)
        if (editTyreForm.isValid) {
            let updateTyrePostData = updateTyreDetails()
            updateVehicleTyreDetails(updateTyrePostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        closeAndReset();
                        getVehicleTyreDetailsList();
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        showLoader(false)
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (editTyreForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }
    function getVehicleTyreDetailsList() {
        setDataStatus(false)
        getVehicleTyreDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.vehicleTyreDetails;
                    if (data != undefined) {
                        setDataStatus(true)
                        setTyreDetailsData(data);
                        getColumnsForTable('getVehicleTyreDetails', 'get');
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        setDataStatus(true)
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setDataStatus(true)
                    //toasts(response.data.message, "Error")
                    getColumnsForTable('getVehicleTyreDetails', 'get');
                    setTyreDetailsData([]);
                    setNoDataMsg(response.data.message)
                } else {
                    setDataStatus(true)
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                setDataStatus(true)
                toasts(error, "Error")
            }
        )
    }

    function getColumnsForTable(apiName: any, type: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setColumnVisibilityData(columnVisibilityData)

                        const allData = allColumnsData.map((item: any) => item.isDisplay);
                        const allColumns = allData.filter((item: any) => item == false);

                        if (type == 'get') {
                            setIsChecked(allColumns == '' ? true : false);
                        }
                        else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

                            for (let i = 0; i < nonDefaultValue.length; i++) {

                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: allColumns == '' ? 0 : 1
                                }
                                all.push(obj);
                            }
                            updateColumnsSubmit('', '', all)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )

    }
    function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {

        if (columnVisibilityForm.isValid) {

            setColumnDataById(isDisplay)
            const columnVisibilityPostData = {
                licenseKey: getLicenseKey,
                userAccountId: userAccountId,
                columns: type ? type : [
                    {
                        columnVisibilityId: columnVisibilityId,
                        isDisplay: isDisplay ? 0 : 1
                    }
                ]
            }

            updateColumnsForDataTable(columnVisibilityPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        getColumnsForTable('getVehicleTyreDetails', 'get');
                    }
                    else if (data.success == false) {
                        // toasts(data.success, "Error")
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        toasts(errorDescription, "Error")
                    }
                }
                , (error) => {
                    toasts(error, "Error")
                }
            )
        } else if (columnVisibilityForm.isValid == false) {
            toasts("Please fill all the details!", "Error")
        }
    }
    function closeAndReset() {
        setAddTyreCreationOffcanvas(false)
        setTyreEditOffCanvas(false)
        addTyreForm.resetForm()
        editTyreForm.resetForm()
    }
    // Export Configuration
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <>
            <PageWrapper title="Tyre Creation">
                <Page container='fluid'>
                    <Card stretch data-tour='list' ref={componentRef} id='pdf'>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info' className="col-lg-5">
                                <CardTitle tag='div' className='h5'>Vehicle Tyre List</CardTitle>
                            </CardLabel>&nbsp;&nbsp;

                            <ButtonGroup className="col-lg-3 d-print-none" color='primary'>
                                <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                                <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
                            </ButtonGroup>

                            <CardActions className="d-print-none">
                                <Dropdown isOpen={columnVisibilityMenu} setIsOpen={setColumnVisibilityMenu} isButtonGroup>
                                    <DropdownToggle>
                                        <Button icon='FilterAlt' color='primary' isLight>Filter</Button>
                                    </DropdownToggle>
                                    <DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
                                        <DropdownItem>
                                            <div className='container py-2'>
                                                <form className='row g-3'>
                                                    <div className='col-12'>
                                                        <FormGroup>
                                                            <h6>Select All</h6>
                                                            <Checks id='available' type='switch' label='Select All Columns' onChange={() => getColumnsForTable('getVehicleTyreDetails', 'post')} checked={isChecked} ariaLabel='Available status' />
                                                        </FormGroup>
                                                    </div>
                                                    <div className='col-12' style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                                                        <FormGroup>
                                                            <h6>Columns</h6>
                                                            <ChecksGroup>
                                                                {allColumnsData.map((i: any) => (
                                                                    <Checks key={i.columnVisibilityId} label={i.columnName} onChange={() => updateColumnsSubmit(i.columnVisibilityId, i.isDisplay, '')} checked={i.isDisplay} disabled={i.isDefault == true} />
                                                                ))}
                                                            </ChecksGroup>
                                                        </FormGroup>
                                                    </div>
                                                </form>
                                            </div>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                <Dropdown isButtonGroup>
                                    <DropdownToggle>
                                        <Button color='primary' isLight icon='CloudDownload'> Export </Button>
                                    </DropdownToggle>
                                    <DropdownMenu isAlignmentEnd>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel("Tyre Details Data", tyreDetailsData, columnVisibilityData)}> Excel </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(tyreDetailsData, columnVisibilityData, "Tyre Details Data")}> PDF
                                            </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile("Tyre Details Data", tyreDetailsData, columnVisibilityData)}> CSV </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='Print' onClick={handlePrint}> Print </Button>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                <Button color='primary' isLight icon='Add' onClick={() => setAddTyreCreationOffcanvas(true)} >
                                    Add New
                                </Button>
                            </CardActions>
                        </CardHeader>
                        <CardBody className='table-responsive' isScrollable>
                            {!dataStatus ?
                                <TableLoader /> :
                                <table className='table table-modern table-hover text-nowrap'>
                                    <thead>
                                        <tr>
                                            <th scope='col' onClick={() => requestSort('sno')}
                                                className='cursor-pointer text-decoration-underline'>S.No{' '}
                                                <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                            </th>
                                            {columnVisibilityData.map((column: any) => (
                                                <th key={column.keyName} scope='col' onClick={() => requestSort(column.keyName)}
                                                    className='cursor-pointer text-decoration-underline'>
                                                    {column.columnName}
                                                    <Icon size='lg' className={getClassNamesFor(column.keyName)} icon='FilterList' />
                                                </th>
                                            ))}
                                            <th scope='col' className='d-print-none'>
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredData != "" ?
                                            <>
                                                {filteredData.map((rowData: any) => (
                                                    <tr key={rowData.vehicleTyreDetailsId}>
                                                        <td>{rowData.sno}</td>
                                                        {columnVisibilityData.map((column: any) => (
                                                            (column.isDisplay && rowData[column.keyName]) ?
                                                                <td key={`${rowData.vehicleTyreDetailsId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />
                                                        ))}
                                                        <td className="d-print-none">
                                                            <Button color='dark' isLight icon='Edit' aria-label='Edit' onClick={() => { onActivate(rowData) }} />{rowData.action}</td>
                                                    </tr>
                                                ))
                                                } </>
                                            :
                                            <NoDataMsg columnsCount={columnVisibilityData.length + 2} msg={noDataMsg} />}
                                    </tbody>
                                </table>
                            }
                        </CardBody>
                        <PaginationButtons
                            className="d-print-none"
                            data={items}
                            label='items'
                            setCurrentPage={setCurrentPage}
                            currentPage={currentPage}
                            perPage={perPage}
                            setPerPage={setPerPage}
                        />
                    </Card>
                </Page>

                <OffCanvas
                    setOpen={setAddTyreCreationOffcanvas}
                    isOpen={addTyreCreationOffCanvas}
                    titleId='upcomingEdit'
                    isBodyScroll
                    placement='end'
                    tag="form" noValidate onSubmit={addTyreForm.handleSubmit}>
                    <OffCanvasHeader setOpen={setAddTyreCreationOffcanvas} onClick={closeAndReset}>
                        <OffCanvasTitle id='upcomingEdit'>Tyre Creation</OffCanvasTitle>
                    </OffCanvasHeader>
                    <OffCanvasBody>
                        <div className='row g-4'>
                            <div className='col-12'>
                                <FormGroup id='tyreNo' label='Tyre No'>
                                    <Input onChange={addTyreForm.handleChange} value={addTyreForm.values.tyreNo}
                                        isValid={addTyreForm.isValid}
                                        onBlur={addTyreForm.handleBlur}
                                        isTouched={addTyreForm.touched.tyreNo}
                                        invalidFeedback={addTyreForm.errors.tyreNo}
                                        placeholder="Enter Tyre No" />
                                </FormGroup>
                            </div>

                            <div className='col-12'>
                                <FormGroup id='retreading' label='Type of Retreading' >
                                    <Select ariaLabel="" placeholder="Select Retreading"
                                        onChange={addTyreForm.handleChange}
                                        value={addTyreForm.values.retreading}
                                        onBlur={addTyreForm.handleBlur}
                                        isValid={addTyreForm.isValid}
                                        isTouched={addTyreForm.touched.retreading}
                                        invalidFeedback={addTyreForm.errors.retreading}>
                                        <Option value='Owner'>Owner</Option>
                                        <Option value='Contracter'>Contracter</Option>
                                    </Select>
                                </FormGroup>
                            </div>

                            <div className='col-12'>
                                <FormGroup id='purchaseDate' label='Purchase Date' >
                                    <Input onChange={addTyreForm.handleChange} value={addTyreForm.values.purchaseDate}
                                        isValid={addTyreForm.isValid}
                                        onBlur={addTyreForm.handleBlur}
                                        isTouched={addTyreForm.touched.purchaseDate}
                                        invalidFeedback={addTyreForm.errors.purchaseDate}
                                        placeholder="Enter Purchase Date" type="date" />
                                </FormGroup>
                            </div>

                            <div className='col-12'>
                                <FormGroup id='tyreBrand' label='Tyre Brand' >
                                    <Input onChange={addTyreForm.handleChange} value={addTyreForm.values.tyreBrand}
                                        isValid={addTyreForm.isValid}
                                        onBlur={addTyreForm.handleBlur}
                                        isTouched={addTyreForm.touched.tyreBrand}
                                        invalidFeedback={addTyreForm.errors.tyreBrand}
                                        placeholder="Enter Tyre Brand" />
                                </FormGroup>
                            </div>

                            <div className='col-12'>
                                <FormGroup id='vendorName' label='Vendor Name' >
                                    <Input onChange={addTyreForm.handleChange} value={addTyreForm.values.vendorName}
                                        isValid={addTyreForm.isValid}
                                        onBlur={addTyreForm.handleBlur}
                                        isTouched={addTyreForm.touched.vendorName}
                                        invalidFeedback={addTyreForm.errors.vendorName}
                                        placeholder="Enter VendorName" />
                                </FormGroup>
                            </div>

                            <div className='col-12'>
                                <FormGroup id='model' label='Model' >
                                    <Input onChange={addTyreForm.handleChange} value={addTyreForm.values.model}
                                        isValid={addTyreForm.isValid}
                                        onBlur={addTyreForm.handleBlur}
                                        isTouched={addTyreForm.touched.model}
                                        invalidFeedback={addTyreForm.errors.model}
                                        placeholder="Enter Model" />
                                </FormGroup>
                            </div>

                            <div className='col-12'>
                                <FormGroup id='amount' label='Amount'  >
                                    <Input onChange={addTyreForm.handleChange} value={addTyreForm.values.amount}
                                        isValid={addTyreForm.isValid}
                                        onBlur={addTyreForm.handleBlur}
                                        isTouched={addTyreForm.touched.amount}
                                        invalidFeedback={addTyreForm.errors.amount}
                                        placeholder="Enter Amount" />
                                </FormGroup>
                            </div>

                            <div className='col-12'>
                                <FormGroup id='sizeOfTyre' label='Size of Tyre' >
                                    <Input onChange={addTyreForm.handleChange} value={addTyreForm.values.sizeOfTyre}
                                        isValid={addTyreForm.isValid}
                                        onBlur={addTyreForm.handleBlur}
                                        isTouched={addTyreForm.touched.sizeOfTyre}
                                        invalidFeedback={addTyreForm.errors.sizeOfTyre}
                                        placeholder="Enter Size Of Tyre" />
                                </FormGroup>
                            </div>
                        </div>
                    </OffCanvasBody>
                    <div className='row m-0'>
                        <div className='col-12 p-3'>
                            <Button
                                color='info'
                                className='w-100' type="submit" isDisable={!addTyreForm.isValid && !!addTyreForm.submitCount}>
                                Save
                            </Button>
                        </div>
                    </div>
                </OffCanvas>

                <OffCanvas
                    setOpen={setTyreEditOffCanvas}
                    isOpen={addTyreEditOffCanvas}
                    titleId='upcomingEdit'
                    isBodyScroll
                    placement='end'
                    tag="form" noValidate onSubmit={editTyreForm.handleSubmit}>
                    <OffCanvasHeader setOpen={setTyreEditOffCanvas} onClick={closeAndReset}>
                        <OffCanvasTitle id='upcomingEdit'>Edit Tyre Creation</OffCanvasTitle>
                    </OffCanvasHeader>
                    <OffCanvasBody>
                        <div className='row g-4'>
                            <div className='col-12'>
                                <FormGroup id='tyreId' label='Tyre Id'>
                                    <Input onChange={editTyreForm.handleChange} value={editTyreForm.values.tyreId}
                                        isValid={editTyreForm.isValid}
                                        onBlur={editTyreForm.handleBlur}
                                        isTouched={editTyreForm.touched.tyreId}
                                        invalidFeedback={editTyreForm.errors.tyreId}
                                        placeholder="Enter Tyre No" disabled />
                                </FormGroup>
                            </div>
                            <div className='col-12'>
                                <FormGroup id='tyreNo' label='Tyre No'>
                                    <Input onChange={editTyreForm.handleChange} value={editTyreForm.values.tyreNo}
                                        isValid={editTyreForm.isValid}
                                        onBlur={editTyreForm.handleBlur}
                                        isTouched={editTyreForm.touched.tyreNo}
                                        invalidFeedback={editTyreForm.errors.tyreNo}
                                        placeholder="Enter Tyre No" />
                                </FormGroup>
                            </div>

                            <div className='col-12'>
                                <FormGroup id='retreading' label='Type of Retreading' >
                                    <Select ariaLabel="" placeholder="Select Retreading"
                                        onChange={editTyreForm.handleChange}
                                        value={editTyreForm.values.retreading}
                                        onBlur={editTyreForm.handleBlur}
                                        isValid={editTyreForm.isValid}
                                        isTouched={editTyreForm.touched.retreading}
                                        invalidFeedback={editTyreForm.errors.retreading}>
                                        <Option value='Owner'>Owner</Option>
                                        <Option value='Contracter'>Contracter</Option>
                                    </Select>
                                </FormGroup>
                            </div>

                            <div className='col-12'>
                                <FormGroup id='purchaseDate' label='Purchase Date' >
                                    <Input onChange={editTyreForm.handleChange} value={editTyreForm.values.purchaseDate}
                                        isValid={editTyreForm.isValid}
                                        onBlur={editTyreForm.handleBlur}
                                        isTouched={editTyreForm.touched.purchaseDate}
                                        invalidFeedback={editTyreForm.errors.purchaseDate}
                                        placeholder="Enter Purchase Date" type="date" />
                                </FormGroup>
                            </div>

                            <div className='col-12'>
                                <FormGroup id='tyreBrand' label='Tyre Brand' >
                                    <Input onChange={editTyreForm.handleChange} value={editTyreForm.values.tyreBrand}
                                        isValid={editTyreForm.isValid}
                                        onBlur={editTyreForm.handleBlur}
                                        isTouched={editTyreForm.touched.tyreBrand}
                                        invalidFeedback={editTyreForm.errors.tyreBrand}
                                        placeholder="Enter Tyre Brand" />
                                </FormGroup>
                            </div>

                            <div className='col-12'>
                                <FormGroup id='vendorName' label='Vendor Name' >
                                    <Input onChange={editTyreForm.handleChange} value={editTyreForm.values.vendorName}
                                        isValid={editTyreForm.isValid}
                                        onBlur={editTyreForm.handleBlur}
                                        isTouched={editTyreForm.touched.vendorName}
                                        invalidFeedback={editTyreForm.errors.vendorName}
                                        placeholder="Enter VendorName" />
                                </FormGroup>
                            </div>

                            <div className='col-12'>
                                <FormGroup id='model' label='Model' >
                                    <Input onChange={editTyreForm.handleChange} value={editTyreForm.values.model}
                                        isValid={editTyreForm.isValid}
                                        onBlur={editTyreForm.handleBlur}
                                        isTouched={editTyreForm.touched.model}
                                        invalidFeedback={editTyreForm.errors.model}
                                        placeholder="Enter Model" />
                                </FormGroup>
                            </div>

                            <div className='col-12'>
                                <FormGroup id='amount' label='Amount'  >
                                    <Input onChange={editTyreForm.handleChange} value={editTyreForm.values.amount}
                                        isValid={editTyreForm.isValid}
                                        onBlur={editTyreForm.handleBlur}
                                        isTouched={editTyreForm.touched.amount}
                                        invalidFeedback={editTyreForm.errors.amount}
                                        placeholder="Enter Amount" />
                                </FormGroup>
                            </div>

                            <div className='col-12'>
                                <FormGroup id='sizeOfTyre' label='Size of Tyre' >
                                    <Input onChange={editTyreForm.handleChange} value={editTyreForm.values.sizeOfTyre}
                                        isValid={editTyreForm.isValid}
                                        onBlur={editTyreForm.handleBlur}
                                        isTouched={editTyreForm.touched.sizeOfTyre}
                                        invalidFeedback={editTyreForm.errors.sizeOfTyre}
                                        placeholder="Enter Size Of Tyre" />
                                </FormGroup>
                            </div>
                        </div>
                    </OffCanvasBody>
                    <div className='row m-0'>
                        <div className='col-12 p-3'>
                            <Button color='info' icon="Save" className='w-100' type="submit" isDisable={!editTyreForm.isValid && !!editTyreForm.submitCount}>Update</Button>
                        </div>
                    </div>
                </OffCanvas>

                <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
            </PageWrapper>
        </>
    )
}
export default TyreCreation