import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Brand from '../../../layout/Brand/Brand';
import Navigation, { NavigationLine } from '../../../layout/Navigation/Navigation';
import User from '../../../layout/User/User';
import ThemeContext from '../../../contexts/themeContext';
import useDarkMode from '../../../hooks/useDarkMode';
import Aside, { AsideBody, AsideFoot, AsideHead } from '../../../layout/Aside/Aside';
import { toasts } from '../../../services/toast.service';
import AuthContext from '../../../contexts/authContext';
import { useNavigate } from 'react-router-dom';
import { getAllScreensByUserAccountId } from '../../../services/screen.service';
import { checkDataAvailableOrNot } from '../../../services/common.service';

const DefaultAside = () => {
	const { asideStatus, setAsideStatus } = useContext(ThemeContext);

	const [doc, setDoc] = useState(
		localStorage.getItem('facit_asideDocStatus') === 'true' || false,
	);

	const { t } = useTranslation(['translation', 'menu']);
	const { darkModeStatus } = useDarkMode();
	const { userAccountId, userTypeId } = useContext(AuthContext);
	const navigate = useNavigate();
	const [getMenu, setGetMenu] = useState<any>({});
	const [tableDataCount, setTableDataCount] = useState<any>('');


	useEffect(() => {
		if (userAccountId == '') {
			navigate(`../login`);
			setGetMenu({})
		}
		else {
			if (userTypeId != '9') {
				getScreensByUserAccountId(userAccountId, userTypeId);
			}
			else {
				let alumini = localStorage.getItem('alumini')
				if (alumini != 'alumini') {
					getScreensByUserAccountId(userAccountId, userTypeId);
				}
			}
		}
	}, []);


	function checkDataAvailable() {
		checkDataAvailableOrNot(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.checkDataAvailableOrNot[0];
					if (data != undefined) {
						setTableDataCount(data.tableDataCount)
						if (data.tableDataCount == false) {
							navigate(`/followingStepsToHave`);
						}
					} else {
						toasts("Undefined Data", "Error")
					}
				}
				else if (response.data.success === false) {
					toasts(response.data.message, "Error")
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, "Error")
				}
			}, error => {
				toasts(error, "Error")
			}
		)
	}

	function getScreensByUserAccountId(userAccountId: number, userTypeId: number) {
		getAllScreensByUserAccountId(userAccountId, userTypeId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.allScreens;
					if (data != undefined) {
						if(userTypeId == 9){
							generateMenu2(data)
						}
						else{
							generateMenu(data);
						}						
					} else {
						toasts("Undefined Data", "Error")
					}
				}
				else if (response.data.success === false) {
					toasts(response.data.message, "Error")
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, "Error")
				}
			}, error => {
				toasts(error, "Error")
			}
		)
	}

	function generateMenu(screenData: any) {
		let menu: any = {};
		for (let i = 0; i < screenData.length; i++) {

			let screenGroupMain = screenData[i]
			menu[screenGroupMain.screenGroupMainDisplayName] = {
				id: screenGroupMain.screenGroupMainDisplayName,
				text: screenGroupMain.screenGroupMainDisplayName,
				icon: '',
			};
			for (let j = 0; j < screenGroupMain.screenGroup.length; j++) {
				let screenGroup = screenGroupMain.screenGroup[j]
				let screenGroupSub = screenGroup
				let submenu: any = {}
				menu[screenGroupSub.screenGroupDisplayName] = {
					id: screenGroupSub.screenGroupDisplayName,
					text: screenGroupSub.screenGroupDisplayName,
					path: screenGroupSub.screenGroupDisplayName,
					icon: screenGroupSub.screenGroupIcon,
				}
				for (let k = 0; k < screenGroup.screens.length; k++) {
					let screen = screenGroup.screens[k]

					submenu[screen.displayName] = {
						id: screen.displayName,
						text: screen.displayName,
						path: screen.screenPath,
						icon: screen.screenIcon,
					}
				}
				menu[screenGroupSub.screenGroupDisplayName]['subMenu'] = submenu
			}
		}
		setGetMenu(menu)
	}

	function generateMenu2(screenData: any) {

		let menu: any = {};
		for (let i = 0; i < screenData.length; i++) {

			let screenGroupMain = screenData[i]
			menu[screenGroupMain.displayName] = {
				id: screenGroupMain.displayName,
				text: screenGroupMain.displayName,
				path: screenGroupMain.screenPath,
				icon: screenGroupMain.screenIcon,
			}
		}
		setGetMenu(menu)
	}

	return (
		<>
			<Aside>
				<AsideHead>
					<Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
				</AsideHead>
				<AsideBody>
					{!doc && (
						<>
							<span onClick={() => { checkDataAvailable() }}>
								<Navigation menu={getMenu} id='aside-menu' />
								{/* <NavigationLine /> */}
							</span>
						</>
					)}
				</AsideBody>
				<AsideFoot>
					<User />
				</AsideFoot>
			</Aside>
		</>

	);
};

export default DefaultAside;
