import { useFormik } from "formik";
import { useEffect, useState, useRef, useContext } from "react";
import { useReactToPrint } from "react-to-print";
import useDarkMode from "../../../hooks/useDarkMode";
import useSortableData from "../../../hooks/useSortableData";
import PaginationButtons, { dataPagination } from "../../PaginationButtons";
import { toasts } from "../../../services/toast.service";
import Page from "../../../layout/Page/Page";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../../bootstrap/Card";
import Icon from "../../icon/Icon";
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from "../../bootstrap/Dropdown";
import Button, { ButtonGroup } from "../../bootstrap/Button";
import FormGroup from "../../bootstrap/forms/FormGroup";
import Checks, { ChecksGroup } from "../../bootstrap/forms/Checks";
import { convertJsonToPdf, downloadExcel, downloadFile } from "../../../services/ExportService";
import Input from "../../bootstrap/forms/Input";
import AlertService from "../../../services/AlertService";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import { getColumnsForDataTable, getCurrentDateAndTime, pictNotLoading, profilePic, updateColumnsForDataTable, updateFilter } from "../../../services/common.service";
import { getLicenseKey } from "../../../services/application.settings";
import AuthContext from "../../../contexts/authContext";
import { useNavigate } from "react-router-dom";
import { showLoader, TableLoader } from "../../../services/loader.services";
import NoDataMsg from "../../../common/components/NoDataMsg";
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from "../../bootstrap/OffCanvas";
import { getDeletedStaffList, getProfileForStaff, getStaffListByUserTypeId, rejoinStaffDetails } from "../../../services/staff.service";
import Textarea from "../../bootstrap/forms/Textarea";

function DeletedStaffList() {


    useEffect(() => {
        getDeletedStaff();
    }, [])

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const { themeStatus, darkModeStatus } = useDarkMode();
    const [deletedStaffData, setDeletedStaffData] = useState([]);
    const [columnVisibilityData, setColumnVisibilityData] = useState([]);
    const [allColumnsData, setAllColumnsData] = useState([]);
    const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
    const [columnDataById, setColumnDataById] = useState([])
    const componentRef = useRef(null);
    const [isChecked, setIsChecked] = useState(false)
    const [dataStatus, setDataStatus] = useState(false)
    const [staffProfileData, setStaffProfileData] = useState<any>([])
    // ForModal
    const [deleteStaffListCanvas, setDeleteStaffListCanvas] = useState(false)

    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(deletedStaffData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);

    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);
    const [noDataMsg, setNoDataMsg] = useState('')

    const rejoinForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            staffDetailsId: '',
            dateOfRejoin: "",
            remarks: '',
        },
        validate: (values) => {
            const errors: {
                dateOfRejoin?: string;
                remarks?: string;
            } = {};
            if (!values.dateOfRejoin) {
                errors.dateOfRejoin = 'Required';
            }
            // if (!values.remarks) {
            //     errors.remarks = 'Required';
            // }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: () => { rejoinStaffDetailsSubmit() },
    });

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },

        //validateOnChange: false,
        onSubmit: () => { },
    });

    function onActivate(i: any) {
        if (i != undefined) {
            let selectedStaff = i
            getStaffProfile(selectedStaff.staffDetailsId)
            setDeleteStaffListCanvas(true)

            rejoinForm.setValues({
                staffDetailsId: selectedStaff.staffDetailsId,
                dateOfRejoin: getCurrentDateAndTime('date'),
                remarks: '',
            });
        }
    }

    // Filter
    const filteredData = updateFilter(onCurrentPageData, columnVisibilityData, columnVisibilityForm);

    function getDeletedStaff() {
        setDataStatus(false)
        getDeletedStaffList(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.deletedStaffList;
                    if (data != undefined) {
                        getColumnsForTable('getStaffListByUserTypeId', 'get');
                        setDeletedStaffData(data);
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                        setDataStatus(true)
                    } else {
                        setDataStatus(true)
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setDataStatus(true)
                    //toasts(response.data.message, "Error")
                    getColumnsForTable('getStaffListByUserTypeId', 'get');
                    setDeletedStaffData([]);
                    setNoDataMsg(response.data.message)
                } else {
                    setDataStatus(true)
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                setDataStatus(true)
                toasts(error, "Error")
            }
        )
    }

    function getStaffProfile(staffDetailsId: number) {
        getProfileForStaff(staffDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.profileForStaff[0];
                    let profileData = response.data.data.profileForStaff;
                    if (data != undefined) {
                        //setRoleTypeData(data);
                        setStaffProfileData(profileData)
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function setRejoinStaffDetails() {
        return ({
            licenseKey: getLicenseKey,
            userAccountId: userAccountId,
            staffDetailsId: rejoinForm.values.staffDetailsId,
            remarks: rejoinForm.values.remarks,
            dateOfRejoin: rejoinForm.values.dateOfRejoin,
        })
    }

    function rejoinStaffDetailsSubmit() {
        showLoader(true)
        if (rejoinForm.isValid) {
            let staffRejoinPostData = setRejoinStaffDetails()
            rejoinStaffDetails(staffRejoinPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        getDeletedStaff();
                        closeAndReset();
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false)
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (rejoinForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function getColumnsForTable(apiName: any, type: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setColumnVisibilityData(columnVisibilityData)

                        const allData = allColumnsData.map((item: any) => item.isDisplay);
                        const allColumns = allData.filter((item: any) => item == false);

                        if (type == 'get') {
                            setIsChecked(allColumns == '' ? true : false);
                        }
                        else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

                            for (let i = 0; i < nonDefaultValue.length; i++) {

                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: allColumns == '' ? 0 : 1
                                }
                                all.push(obj);
                            }
                            updateColumnsSubmit('', '', all)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )

    }

    function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {

        if (columnVisibilityForm.isValid) {

            setColumnDataById(isDisplay)
            const columnVisibilityPostData = {
                licenseKey: getLicenseKey,
                userAccountId: userAccountId,
                columns: type ? type : [
                    {
                        columnVisibilityId: columnVisibilityId,
                        isDisplay: isDisplay ? 0 : 1
                    }
                ]
            }

            updateColumnsForDataTable(columnVisibilityPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        getColumnsForTable('getStaffListByUserTypeId', 'get');
                    }
                    else if (data.success == false) {
                        // toasts(data.success, "Error")
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        toasts(errorDescription, "Error")
                    }
                }
                , (error) => {
                    toasts(error, "Error")
                }
            )
        } else if (columnVisibilityForm.isValid == false) {
            toasts("Please fill all the details!", "Error")
        }
    }

    function closeAndReset() {
        rejoinForm.resetForm();
        setDeleteStaffListCanvas(false)
    }

    // Print
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <>
            <PageWrapper title="Discontinued Student List">
                <Page container='fluid'>
                    <Card stretch data-tour='list' ref={componentRef}>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info' className="col-lg-6">
                                <CardTitle tag='div' className='h5'>Deleted Staff List</CardTitle>
                            </CardLabel>&nbsp;&nbsp;

                            <ButtonGroup className="col-lg-3 justify-content-end d-print-none" color='primary'>
                                <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                                <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
                            </ButtonGroup>

                            <CardActions className="d-print-none">
                                <Dropdown isOpen={columnVisibilityMenu} setIsOpen={setColumnVisibilityMenu} isButtonGroup>
                                    <DropdownToggle>
                                        <Button icon='FilterAlt' color='primary' isLight>Filter</Button>
                                    </DropdownToggle>
                                    <DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
                                        <DropdownItem>
                                            <div className='container py-2'>
                                                <form className='row g-3'>
                                                    <div className='col-12'>
                                                        <FormGroup>
                                                            <h6>Select All</h6>
                                                            <Checks id='available' type='switch' label='Select All Columns' onChange={() => getColumnsForTable('getStaffListByUserTypeId', 'post')} checked={isChecked} ariaLabel='Available status' />
                                                        </FormGroup>
                                                    </div>
                                                    <div className='col-12' style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                                                        <FormGroup>
                                                            <h6>Columns</h6>
                                                            <ChecksGroup>
                                                                {allColumnsData.map((i: any) => (
                                                                    <Checks key={i.columnVisibilityId} id='{i.columnVisibilityId}' label={i.columnName} onChange={() => updateColumnsSubmit(i.columnVisibilityId, i.isDisplay, '')} checked={i.isDisplay} disabled={i.isDefault == true} />
                                                                ))}
                                                            </ChecksGroup>
                                                        </FormGroup>
                                                    </div>
                                                </form>
                                            </div>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                <Dropdown isButtonGroup>
                                    <DropdownToggle>
                                        <Button color='primary' isLight icon='CloudDownload'> Export </Button>
                                    </DropdownToggle>
                                    <DropdownMenu isAlignmentEnd>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel("DeletedStaffData", deletedStaffData, columnVisibilityData)}> Excel </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(deletedStaffData, columnVisibilityData, "DeletedStaffData")}> PDF
                                            </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile("DeletedStaffData", deletedStaffData, columnVisibilityData)}> CSV </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='Print' onClick={handlePrint}> Print </Button>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </CardActions>
                        </CardHeader>

                        <CardBody className='table-responsive' isScrollable>
                            {!dataStatus ?
                                <TableLoader /> :
                                <table className='table table-modern table-hover text-nowrap'>
                                    <thead>
                                        <tr>
                                            <th scope='col' onClick={() => requestSort('sno')}
                                                className='cursor-pointer text-decoration-underline'>S.No{' '}
                                                <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                            </th>
                                            {columnVisibilityData.map((column: any) => (
                                                <th key={column.keyName} scope='col' onClick={() => requestSort(column.keyName)}
                                                    className='cursor-pointer text-decoration-underline'>
                                                    {column.columnName}
                                                    <Icon size='lg' className={getClassNamesFor(column.keyName)} icon='FilterList' />
                                                </th>
                                            ))}
                                            {userTypeId == 2 || userTypeId == 3 || userTypeId == 4 || userTypeId == 5 ?
                                                null :
                                                <th className="d-print-none">Action</th>
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredData != "" ?
                                            <>{filteredData.map((rowData: any) => (
                                                <tr key={rowData.staffDetailsId}>
                                                    <td>{rowData.sno}</td>
                                                    {columnVisibilityData.map((column: any) => (
                                                        (column.isDisplay && rowData[column.keyName]) ?
                                                            <td key={`${rowData.studentDetailsId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />))}
                                                    {userTypeId == 2 || userTypeId == 3 || userTypeId == 4 || userTypeId == 5 ?
                                                        null :
                                                        <td className='d-print-none' >
                                                            <Button icon="Refresh" isLight color="primary" onClick={() => onActivate(rowData)} >Rejoin</Button>
                                                        </td>
                                                    }
                                                </tr>))}
                                            </> :
                                            <NoDataMsg columnsCount={columnVisibilityData.length + 2} msg={noDataMsg} />
                                        }
                                    </tbody>
                                </table>
                            }
                        </CardBody>
                        <PaginationButtons
                            className='d-print-none'
                            data={items}
                            label='items'
                            setCurrentPage={setCurrentPage}
                            currentPage={currentPage}
                            perPage={perPage}
                            setPerPage={setPerPage}
                        />
                    </Card>
                </Page>

                <OffCanvas
                    setOpen={setDeleteStaffListCanvas}
                    isOpen={deleteStaffListCanvas}
                    titleId='deleteStaffListCanvas'
                    isBodyScroll
                    placement='end'
                    tag="form" noValidate onSubmit={rejoinForm.handleSubmit}>
                    <OffCanvasHeader setOpen={setDeleteStaffListCanvas} >
                        <OffCanvasTitle id="deleteStaffListCanvas">Rejoin Staff</OffCanvasTitle>
                    </OffCanvasHeader>
                    <OffCanvasBody>
                        <div className="row">
                            {staffProfileData.map((staff: any) => (
                                <div className='col-12' key={staff.staffUserAccountId}>
                                    <Card className={`bg-l${darkModeStatus ? 'o25' : '25'
                                        }-info bg-l${darkModeStatus ? 'o50' : '10'
                                        }-info-hover transition-base rounded-2 mb-4`}
                                        shadow='sm'>
                                        <CardBody>
                                            <div className='d-flex align-items-center pb-3'>
                                                <div className='flex-shrink-0 ms-2'>
                                                    <img style={{ borderRadius: '50%' }} src={staff.profilePath != null ? staff.profilePath : profilePic(staff.genderId)} height={65} width={65} onError={(e: any) => pictNotLoading(e, staff.genderId)} />
                                                </div>
                                                <div className='flex-grow-1 ms-3'>
                                                    <div className='fw-bold fs-4 text-dark mb-0'>
                                                        {staff.fullname}
                                                    </div>
                                                    <div className='text-muted'>
                                                        ( {staff.empNumber} )
                                                    </div>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>
                            ))}
                            <div className='col-12 mt-3'>
                                <FormGroup id='dateOfRejoin' label='Date Of Rejoin'>
                                    <Input onChange={rejoinForm.handleChange} value={rejoinForm.values.dateOfRejoin} onBlur={rejoinForm.handleBlur} isValid={rejoinForm.isValid} isTouched={rejoinForm.touched.dateOfRejoin} invalidFeedback={rejoinForm.errors.dateOfRejoin} type='date' />
                                </FormGroup>
                            </div>

                            <div className='col-12 mt-3'>
                                <FormGroup id='remarks' label='Remarks'>
                                    <Textarea
                                        onChange={rejoinForm.handleChange}
                                        value={rejoinForm.values.remarks}
                                        onBlur={rejoinForm.handleBlur}
                                        // isValid={rejoinForm.isValid}
                                        // isTouched={rejoinForm.touched.remarks}
                                        // invalidFeedback={rejoinForm.errors.remarks}
                                    />
                                </FormGroup>
                            </div>
                        </div>
                    </OffCanvasBody>
                    <div className='row m-0'>
                        <div className='col-12 p-3 position-absolute top-95 start-50 translate-middle'>
                            <Button
                                color='primary'
                                icon='Refresh'
                                className='w-100' type="submit"
                                isDisable={!rejoinForm.isValid && !!rejoinForm.submitCount}>
                                Rejoin
                            </Button>
                        </div>
                    </div>
                </OffCanvas>

                <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />

            </PageWrapper>
        </>
    )
}
export default DeletedStaffList;