import { useContext, useEffect, useRef, useState } from "react";
import Button, { ButtonGroup } from "../../bootstrap/Button";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../../bootstrap/Card";
import Page from "../../../layout/Page/Page";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import FormGroup from "../../bootstrap/forms/FormGroup";
import Input from "../../bootstrap/forms/Input";
import PaginationButtons, { dataPagination } from "../../PaginationButtons";
import useSortableData from "../../../hooks/useSortableData";
import { toasts } from "../../../services/toast.service";
import { useFormik } from "formik";
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from "../../bootstrap/Dropdown";
import { convertJsonToPdf, downloadExcel, downloadFile } from "../../../services/ExportService";
import useDarkMode from "../../../hooks/useDarkMode";
import Checks, { ChecksGroup } from "../../bootstrap/forms/Checks";
import { useReactToPrint } from "react-to-print";
import Icon from "../../icon/Icon";
import Select from "../../bootstrap/forms/Select";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import DeleteComponents from "../../../common/components/DeleteComponents";
import AlertService from "../../../services/AlertService";
import AuthContext from "../../../contexts/authContext";
import { convertDateRangeToEpoch, getColumnsForDataTable, getGraduationType, updateColumnsForDataTable, updateFilter } from "../../../services/common.service";
import { getLicenseKey } from "../../../services/application.settings";
import { addBatchMasterDetails, deleteBatchMaster, getAcademicPeriod, getBatchMasterDetails, updateBatchMaster } from "../../../services/master.service";
import { TableLoader, showLoader } from "../../../services/loader.services";
import NoDataMsg from "../../../common/components/NoDataMsg";
import dayjs from "dayjs";
import SearchableSelect from "../../../common/components/SearchableSelect";

function BatchMaster() {

    useEffect(() => {
        getBatchMaster();
        getAcademicPeriodList()
    }, [])

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const [addBatchMasterModal, setAddBatchMasterModal] = useState(false)
    const [editBatchMasterModal, setEditBatchMasterModal] = useState(false)
    const [batchMasterData, setBatchMasterData] = useState([]);
    const [termDetailsData, setTermDetailsData] = useState<any>([]);
    const [termData, setTermData] = useState<any>([])
    const [termDetails, setTermDetails] = useState<any>([])
    const [columnVisibilityData, setColumnVisibilityData] = useState([]);
    const [allColumnsData, setAllColumnsData] = useState([]);
    const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
    const [columnDataById, setColumnDataById] = useState([])
    const [isChecked, setIsChecked] = useState(false)
    const componentRef = useRef(null);

    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);

    // ForModal
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(batchMasterData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);
    const [updateTermDetails, setUpdateTermDetails] = useState<any>([])
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [dataStatus, setDataStatus] = useState(false)

    const [noDataMsg, setNoDataMsg] = useState('')
    const [noDataMsgForSemester, setNoDataMsgSemester] = useState('')

    const [academicPeriodId, setAcademicPeriodId] = useState<any>('');
    const [academicPeriodData, setAcademicPeriodData] = useState<any>([]);
    const [noOfTerm, setNoOfTerm] = useState<any>('')
    const [termsData, setTermsData] = useState<any>([])
    const [view, setView] = useState(false)

    const batchMasterForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            academicPeriodId: '',
        },
        validate: (values) => {
            const errors: {
                fromDate?: string;
                toDate?: string;
            } = {};
            if (!academicPeriodId?.value) {
                errors.fromDate = 'Required';
            }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: () => { batchMasterSubmit() },
    });

    const batchAndTermsForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            academicPeriodId: '',
            batchMasterId: '',
        },
        //validateOnChange: false,
        onSubmit: () => { },
    });

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },

        //validateOnChange: false,
        onSubmit: () => { },
    });

    // Filter
    const filteredData = updateFilter(onCurrentPageData, columnVisibilityData, columnVisibilityForm);

    function onActivate(i: any) {
        if (i != undefined) {

            let selectedBatchMaster = i
            setEditBatchMasterModal(true)
            setAcademicPeriodId({ value: selectedBatchMaster.academicPeriodId, label: selectedBatchMaster.period })
            batchAndTermsForm.setValues({
                academicPeriodId: selectedBatchMaster.academicPeriodId,
                batchMasterId: selectedBatchMaster.batchMasterId,
            });
            setTermDetailsData(selectedBatchMaster.termDetails)
        }
    }

    function setBatchMasterDetails() {
        return ({
            licenseKey: getLicenseKey,
            userAccountId: userAccountId,
            academicPeriodId: academicPeriodId?.value,
            termDetails: termDetails,
        })
    }

    function setBatchAndSemesterEditDetails() {
        return {
            batchMasterId: batchAndTermsForm.values.batchMasterId,
            academicPeriodId: academicPeriodId?.value,
            termDetails: updateTermDetails,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId,
        }
    }

    function batchMasterSubmit() {

        showLoader(true);
        if (noOfTerm?.value == termDetails.length) {
            let batchMasterPostData = setBatchMasterDetails()
            addBatchMasterDetails(batchMasterPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        closeModal()
                        getBatchMaster()
                    }
                    else if (data.success == false) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        showLoader(false);
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false);
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (noOfTerm?.value != termDetails.length) {
            showLoader(false);
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function updateFromDateValue(event: any, cell: string) {

        const newData: any = [];

        termsData.forEach((item: any, index: any) => {
            if (item['term'] == cell) {
                item['fromDate'] = event.target.value
            }
            newData.push(item)
        });
        setTermData(newData)

        var fromDateArray = event.target.value

        if (termDetails.length == 0) {
            var obj
            obj = {
                term: cell,
                fromDate: fromDateArray,
                toDate: ""
            };
            termDetails.push(obj);
        } else {
            let selectedItem: any
            termDetails.forEach((item: any, index: string) => {
                if (item['term'] == cell) {
                    selectedItem = item
                }
            });
            if (selectedItem != undefined) {
                selectedItem['fromDate'] = fromDateArray
            } else {
                var obj
                obj = {
                    term: cell,
                    fromDate: fromDateArray,
                    toDate: ""
                };
                termDetails.push(obj);
            }
        }
    }

    function updateToDateValue(event: any, cell: string) {

        const newData: any = [];

        termsData.forEach((item: any, index: any) => {
            if (item['term'] == cell) {
                item['toDate'] = event.target.value
            }
            newData.push(item)
        });
        setTermData(newData)

        var toDateArray = event.target.value

        if (termDetails.length == 0) {
            var obj
            obj = {
                term: cell,
                fromDate: "",
                toDate: toDateArray,
            };
            termDetails.push(obj);
        } else {
            let selectedItem: any
            termDetails.forEach((item: any, index: string) => {
                if (item['term'] == cell) {
                    selectedItem = item
                    let selectedSemester = selectedItem.term;
                }
            });
            if (selectedItem != undefined) {
                selectedItem['toDate'] = toDateArray
            } else {
                var obj
                obj = {
                    term: cell,
                    fromDate: "",
                    toDate: toDateArray,
                };
                termDetails.push(obj);
            }
        }
    }

    function editFromDateValue(event: any, cell: number, dataDate: any) {

        var fromDateArray = event.target.value

        const newData: any = [];

        termDetailsData.forEach((item: any, index: any) => {
            if (item['termDetailsId'] == cell) {
                item['fromDate'] = fromDateArray
            }
            newData.push(item)
        });
        setTermDetailsData(newData)

        if (updateTermDetails.length == 0) {
            var obj
            obj = {
                termDetailsId: cell,
                fromDate: fromDateArray,
                toDate: dataDate
            };
            updateTermDetails.push(obj);
        } else {
            let selectedItem: any
            updateTermDetails.forEach((item: any, index: string) => {
                if (item['termDetailsId'] == cell) {
                    selectedItem = item
                }
            });
            if (selectedItem != undefined) {
                selectedItem['fromDate'] = fromDateArray
            } else {
                var obj
                obj = {
                    termDetailsId: cell,
                    fromDate: fromDateArray,
                    toDate: dataDate
                };
                updateTermDetails.push(obj)
            }
        }
    }

    function editToDateValue(event: any, cell: any, dataDate: any) {

        var toDateArray = event.target.value

        const newData: any = [];

        termDetailsData.forEach((item: any, index: any) => {
            if (item['termDetailsId'] == cell) {
                item['toDate'] = toDateArray
            }
            newData.push(item)
        });
        setTermDetailsData(newData)

        if (updateTermDetails.length == 0) {
            var obj
            obj = {
                termDetailsId: cell,
                fromDate: dataDate,
                toDate: toDateArray
            };
            updateTermDetails.push(obj);
        } else {
            let selectedItem: any
            updateTermDetails.forEach((item: any, index: string) => {
                if (item['termDetailsId'] == cell) {
                    selectedItem = item
                }
            });
            if (selectedItem != undefined) {
                selectedItem['toDate'] = toDateArray
            } else {
                var obj
                obj = {
                    termDetailsId: cell,
                    fromDate: dataDate,
                    toDate: toDateArray
                };
                updateTermDetails.push(obj);
            }
        }
    }

    const updateBatchMasterSubmit = () => {
        showLoader(true);
        if (batchAndTermsForm.isValid) {
            let batchAndSemesterPostData = setBatchAndSemesterEditDetails()
            updateBatchMaster(batchAndSemesterPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        getBatchMaster()
                        closeModal();
                    }
                    else if (data.success == false) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        showLoader(false);
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false);
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (batchAndTermsForm.isValid == false) {
            showLoader(false);
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
            closeModal();
        }
    }

    const deleteBatchMasterSubmit = () => {
        showLoader(true);
        if (batchAndTermsForm.isValid) {
            let batchAndSemesterPostData = setBatchAndSemesterEditDetails()
            deleteBatchMaster(batchAndSemesterPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        setIsDeleteOpen(false);
                        closeModal()
                        getBatchMaster()
                    }
                    else if (data.success == false) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        showLoader(false);
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false);
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (batchAndTermsForm.isValid == false) {
            showLoader(false);
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function getBatchMaster() {
        setDataStatus(false)
        getBatchMasterDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.batchMasterDetails;
                    if (data != undefined) {
                        getColumnsForTable('getBatchMasterDetails', 'get');
                        setBatchMasterData(data);
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                        setDataStatus(true)
                    } else {
                        toasts("Undefined Data", "Error")
                        setDataStatus(true)
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")                  
                    getColumnsForTable('getBatchMasterDetails', 'get');
                    setBatchMasterData([]);
                    setNoDataMsg(response.data.message)
                    setDataStatus(true)

                } else {
                    setDataStatus(true)
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                setDataStatus(true)
                toasts(error, "Error")
            }
        )
    }

    function getColumnsForTable(apiName: any, type: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setColumnVisibilityData(columnVisibilityData)

                        const allData = allColumnsData.map((item: any) => item.isDisplay);
                        const allColumns = allData.filter((item: any) => item == false);

                        if (type == 'get') {
                            setIsChecked(allColumns == '' ? true : false);
                        }
                        else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

                            for (let i = 0; i < nonDefaultValue.length; i++) {

                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: allColumns == '' ? 0 : 1
                                }
                                all.push(obj);
                            }
                            updateColumnsSubmit('', '', all)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )

    }

    function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {

        if (columnVisibilityForm.isValid) {

            setColumnDataById(isDisplay)
            const columnVisibilityPostData = {
                licenseKey: getLicenseKey,
                userAccountId: userAccountId,
                columns: type ? type : [
                    {
                        columnVisibilityId: columnVisibilityId,
                        isDisplay: isDisplay ? 0 : 1
                    }
                ]
            }

            updateColumnsForDataTable(columnVisibilityPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        getColumnsForTable('getBatchMasterDetails', 'get');
                    }
                    else if (data.success == false) {
                        // toasts(data.success, "Error")
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        toasts(errorDescription, "Error")
                    }
                }
                , (error) => {
                    toasts(error, "Error")
                }
            )
        } else if (columnVisibilityForm.isValid == false) {
            toasts("Please fill all the details!", "Error")
        }
    }

    function closeModal() {
        setEditBatchMasterModal(false)
        setAddBatchMasterModal(false)
        setIsDeleteOpen(false)
        batchMasterForm.resetForm()
        batchAndTermsForm.resetForm()
        setUpdateTermDetails([])
        setTermData([]);
        setTermDetails([]);
        setTermDetailsData([]);
        setView(false)
        setAcademicPeriodId([])
        setNoOfTerm([]);
    }

    // Export Configuration
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    function getAcademicPeriodList() {
        getAcademicPeriod(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.academicPeriod;
                    if (data != undefined) {
                        setAcademicPeriodData(data);
                    } else {
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setAcademicPeriodData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, 'Error');
                }
            },
            (error) => {
                toasts(error, 'Error');
            },
        );
    }

    const selectAcademicPeriod = (e: any) => {
        let academicPeriodId = e;
        setAcademicPeriodId(academicPeriodId);
        setTermsData([])
        setNoOfTerm([]);
        setView(false)
    };

    const noOfTermData = [
        { id: 1, noOfTerm: 1, termName: '1 Terms' },
        { id: 2, noOfTerm: 2, termName: '2 Terms' },
        { id: 3, noOfTerm: 3, termName: '3 Terms' },
        { id: 4, noOfTerm: 4, termName: '4 Terms' },
        { id: 5, noOfTerm: 5, termName: '5 Terms' },
    ]

    const selectNoOfTerms = (e: any) => {
        let noOfTerm = e;
        setNoOfTerm(noOfTerm);
        setTermsData([])
        setView(false)
    };

    const onView = () => {
        if (academicPeriodId?.value != undefined && noOfTerm?.value != undefined) {
            setView(true)
            let newData: any = []
            for (let i = 0; i < noOfTerm?.value; i++) {

                let termName = i == 0 ? 'Term 1' : 'Term ' + Number(i + 1)

                newData.push({ term: termName, fromDate: '', toDate: '' })
            }
            setTermsData(newData)
        }
    }


    return (
        <>
            <PageWrapper title={`Term Creation`}>
                <Page container='fluid'>
                    <Card stretch data-tour='list' ref={componentRef} id='pdf'>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info' className="col-lg-5">
                                <CardTitle tag='div' className='h5'>Batch & Term List</CardTitle>
                            </CardLabel>&nbsp;&nbsp;

                            <ButtonGroup className="col-lg-3 d-print-none" color='primary'>
                                <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                                <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
                            </ButtonGroup>

                            <CardActions className="d-print-none">
                                <Dropdown isOpen={columnVisibilityMenu} setIsOpen={setColumnVisibilityMenu} isButtonGroup>
                                    <DropdownToggle>
                                        <Button icon='FilterAlt' color='primary' isLight>Filter</Button>
                                    </DropdownToggle>
                                    <DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
                                        <DropdownItem>
                                            <div className='container py-2'>
                                                <form className='row g-3'>
                                                    <div className='col-12'>
                                                        <FormGroup>
                                                            <h6>Select All</h6>
                                                            <Checks id='available' type='switch' label='Select All Columns' onChange={() => getColumnsForTable('getBatchMasterDetails', 'post')} checked={isChecked} ariaLabel='Available status' />
                                                        </FormGroup>
                                                    </div>
                                                    <div className='col-12' style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                                                        <FormGroup>
                                                            <h6>Columns</h6>
                                                            <ChecksGroup>
                                                                {allColumnsData.map((i: any) => (
                                                                    <Checks key={i.columnVisibilityId} id='{i.columnVisibilityId}' label={i.columnName} onChange={() => updateColumnsSubmit(i.columnVisibilityId, i.isDisplay, '')} checked={i.isDisplay} disabled={i.isDefault == true} />
                                                                ))}
                                                            </ChecksGroup>
                                                        </FormGroup>
                                                    </div>
                                                </form>
                                            </div>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                <Dropdown isButtonGroup>
                                    <DropdownToggle>
                                        <Button color='primary' isLight icon='CloudDownload'> Export </Button>
                                    </DropdownToggle>
                                    <DropdownMenu isAlignmentEnd>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel("Batch Master List", batchMasterData, columnVisibilityData)}> Excel </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(batchMasterData, columnVisibilityData, "Batch Master List")}> PDF
                                            </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile("Batch Master List", batchMasterData, columnVisibilityData)}> CSV </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='Print' onClick={handlePrint}> Print </Button>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                <Button color='primary' icon='Add' isLight onClick={() => setAddBatchMasterModal(true)}>
                                    Add New
                                </Button>
                            </CardActions>
                        </CardHeader>


                        <CardBody className='table-responsive' isScrollable>
                            {!dataStatus ?
                                <TableLoader /> :
                                <table className='table table-modern table-hover text-nowrap'>
                                    <thead>
                                        <tr>
                                            <th scope='col' onClick={() => requestSort('sno')}
                                                className='cursor-pointer text-decoration-underline'>S.No{' '}
                                                <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                            </th>
                                            {columnVisibilityData.map((column: any) => (
                                                <th key={column.keyName} scope='col' onClick={() => requestSort(column.keyName)}
                                                    className='cursor-pointer text-decoration-underline'>
                                                    {column.columnName}
                                                    <Icon size='lg' className={getClassNamesFor(column.keyName)} icon='FilterList' />
                                                </th>
                                            ))}
                                            <th scope='col' className='d-print-none'>
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredData != "" ?
                                            <>
                                                {filteredData.map((rowData: any) => (
                                                    <tr key={rowData.batchMasterId}>
                                                        <td>{rowData.sno}</td>{columnVisibilityData.map((column: any) => ((column.isDisplay && rowData[column.keyName]) ?
                                                            <td key={`${rowData.batchMasterId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />))}<td className="d-print-none"><Button color='dark' isLight icon='Edit' aria-label='Edit' onClick={() => { onActivate(rowData) }} /></td></tr>))}
                                            </>
                                            :
                                            <NoDataMsg columnsCount={columnVisibilityData.length + 2} msg={noDataMsg} />}
                                    </tbody>
                                </table>
                            }
                        </CardBody>
                        <PaginationButtons
                            className="d-print-none"
                            data={items}
                            label='items'
                            setCurrentPage={setCurrentPage}
                            currentPage={currentPage}
                            perPage={perPage}
                            setPerPage={setPerPage}
                        />
                    </Card>

                </Page>

                <Modal
                    setIsOpen={setAddBatchMasterModal}
                    isOpen={addBatchMasterModal}
                    titleId='addBatchMaster'
                    isStaticBackdrop
                    size='lg'>
                    <ModalHeader className="'modal-header'"
                        setIsOpen={() => { setAddBatchMasterModal(false) }} onClick={closeModal}>
                        <ModalTitle id='addBatchMaster'>Batch & Term Creation</ModalTitle>
                    </ModalHeader>
                    <form noValidate onSubmit={batchMasterForm.handleSubmit}>
                        <ModalBody>
                            <div className="row g-4">
                                <div className="col-md-1"></div>
                                <div className="col-md-4">
                                    <FormGroup
                                        id='academicPeriodId'
                                        label='Academic Period'
                                        isFloating>
                                        <SearchableSelect
                                            isFloating
                                            ariaLabel='Academic Period'
                                            onChange={selectAcademicPeriod}
                                            value={academicPeriodId}
                                            list={academicPeriodData.map((data: any) => ({
                                                value: data.academicPeriodId,
                                                label: data.period,
                                            }))}
                                        />
                                    </FormGroup>
                                </div>

                                <div className="col-md-4">
                                    <FormGroup
                                        id='noOfTerms'
                                        label='No Of Terms'
                                        isFloating>
                                        <SearchableSelect
                                            isFloating
                                            ariaLabel='No Of Terms'
                                            onChange={selectNoOfTerms}
                                            value={noOfTerm}
                                            list={noOfTermData.map((data: any) => ({
                                                value: data.noOfTerm,
                                                label: data.termName,
                                            }))}
                                        />
                                    </FormGroup>

                                </div>
                                <div className="col-md-2">
                                    <Button icon="ArrowDownward" color="info" onClick={onView} isDisable={academicPeriodId?.value == undefined || noOfTerm?.value == undefined ? true : false} className="mt-2">Submit
                                    </Button>
                                </div>
                            </div>

                            {view && academicPeriodId ?
                                <div className="row mt-4">
                                    <div className="col-lg-1"></div>
                                    <div className="col-lg-10">
                                        <table className="table table-modern table-hover text-center text-nowrap mg-b-0">
                                            <thead className="text-center">
                                                <tr className="table-primary">
                                                    <th>Terms</th>
                                                    <th>From Date</th>
                                                    <th>To Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {termsData.map((data: any) => (
                                                    <tr className="text-center" key={data.term}>
                                                        <td>{data.term}</td>
                                                        <td>
                                                            <FormGroup id="fromDate" label="From Date" isFloating>
                                                                <Input
                                                                    type='date'
                                                                    ariaLabel='FromDate'
                                                                    onChange={(event) => updateFromDateValue(event, data.term)}
                                                                    value={data.fromDate}
                                                                />
                                                            </FormGroup>
                                                        </td>
                                                        <td>
                                                            <FormGroup id="toDate" label="To Date" isFloating>
                                                                <Input
                                                                    type='date'
                                                                    ariaLabel='ToDate'
                                                                    onChange={(event) => updateToDateValue(event, data.term)}
                                                                    value={data.toDate}

                                                                />
                                                            </FormGroup>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div> : null
                            }
                        </ModalBody>
                        <ModalFooter>
                            {view ? <>
                                <Button color='info' isOutline className='border-0' onClick={closeModal}>Close</Button>
                                <Button icon="Save" color="info" type="submit" isDisable={!batchMasterForm.isValid && !batchMasterForm.submitCount}>
                                    Save
                                </Button> </> : <></>
                            }
                        </ModalFooter>
                    </form>
                </Modal>

                <Modal
                    setIsOpen={setEditBatchMasterModal}
                    isOpen={editBatchMasterModal}
                    titleId='updateBatchMaster'
                    isCentered
                    isStaticBackdrop
                    size='lg'>

                    <ModalHeader className="'modal-header'"
                        setIsOpen={closeModal}>
                        <ModalTitle id='updateBatchMaster'>Update Batch & Terms</ModalTitle>
                    </ModalHeader>
                    <ModalBody>
                        <div className="row g-4">
                            <div className="col-md-4"></div>
                            <div className="col-md-4">
                                <FormGroup
                                    id='academicPeriodId'
                                    label='Academic Period'
                                    isFloating>
                                    <SearchableSelect
                                        isFloating
                                        ariaLabel='Academic Period'
                                        onChange={selectAcademicPeriod}
                                        value={academicPeriodId}
                                        list={academicPeriodData.map((data: any) => ({
                                            value: data.academicPeriodId,
                                            label: data.period,
                                        }))}
                                    />
                                </FormGroup>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-md-1"></div>
                            <div className="col-md-10">
                                <table className="table table-modern table-hover text-center text-nowrap mg-b-0">
                                    <thead className="text-center">
                                        <tr className="table-primary">
                                            <th>Terms</th>
                                            <th>From Date</th>
                                            <th>To Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {termDetailsData != '' ?
                                            <>
                                                {termDetailsData.map((data: any) => (
                                                    <tr className="text-center" key={data.term}>
                                                        <td><div className="mt-3">{data.term}</div></td>
                                                        <td>
                                                            <FormGroup id="fromDate" label="From Date" isFloating>
                                                                <Input
                                                                    type='date'
                                                                    ariaLabel='FromDate'
                                                                    onChange={(event) => editFromDateValue(event, data.termDetailsId, data.toDate)}
                                                                    value={data.fromDate}
                                                                />
                                                            </FormGroup>
                                                        </td>
                                                        <td>
                                                            <FormGroup id="toDate" label="To Date" isFloating>
                                                                <Input
                                                                    type='date'
                                                                    ariaLabel='ToDate'
                                                                    onChange={(event) => editToDateValue(event, data.termDetailsId, data.fromDate)}
                                                                    value={data.toDate}
                                                                />
                                                            </FormGroup>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </> : <NoDataMsg columnsCount={3} msg={noDataMsgForSemester} />}
                                    </tbody>
                                </table>
                            </div>
                            {/* <div className="d-flex justify-content-center position-absolute top-60 start-50 translate-middle">
                                <DeleteComponents isDeleteOpen={isDeleteOpen} setIsDeleteOpen={setIsDeleteOpen} deleteFunction={deleteBatchMasterSubmit} />
                            </div> */}

                            <ModalFooter>
                                <Button color='info' isOutline className='border-0' onClick={closeModal}>Close</Button>
                                <Button color='danger' icon='Delete' type="submit" onClick={() => setIsDeleteOpen(!isDeleteOpen)}>Delete All</Button>
                                <Button color='info' icon='Save' type="submit" onClick={updateBatchMasterSubmit}>Update </Button>
                            </ModalFooter>

                        </div>
                    </ModalBody>
                </Modal>

                <Modal
                    setIsOpen={setIsDeleteOpen}
                    isOpen={isDeleteOpen}
                    titleId='deleteBatchMaster'
                    isStaticBackdrop
                    size='sm'>
                    <ModalHeader><></></ModalHeader>
                    <ModalBody>
                        <div className="row g-2">
                            <div className="col-12 d-flex justify-content-center">
                                <h5><strong>Are you sure want to delete ?</strong></h5>
                            </div>
                            <div className='col-10'>
                                <div className='d-flex gap-2 justify-content-end'>
                                    <Button color='danger' size={'sm'} onClick={deleteBatchMasterSubmit}>
                                        Yes
                                    </Button>

                                    <Button color='info' size={'sm'} onClick={() => setIsDeleteOpen(!isDeleteOpen)}>
                                        No
                                    </Button>
                                </div>
                            </div>
                            <div className='col-10'></div>
                        </div>
                    </ModalBody>
                </Modal>

                <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
            </PageWrapper>
        </>
    );
}
export default BatchMaster;