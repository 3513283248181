import { AxiosResponse } from "axios";
import service from "../api/api.services";
import { getAPIURL, getLicenseKey } from "./application.settings";

const api = service()

export const getPeriod = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getPeriod/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getPeriod - " + error.message)
            console.log('error caught in service : getPeriod')
        },
    );

export const addPeriod = (periodFormPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addPeriod`, periodFormPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addPeriod')
        }
    );

export const updatePeriod = (periodFormPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updatePeriod`, periodFormPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updatePeriod')
        }
    );

export const deletePeriod = (periodFormPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `deletePeriod`, periodFormPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : deletePeriod')
        }
    );

export const getAllPeriod = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getAllPeriod/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getAllPeriod - " + error.message)
            console.log('error caught in service : getAllPeriod')
        },
    );

export const getAllocatedTimeTable = (sectionDetailsId: any, dayId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getAllocatedTimeTable/${getLicenseKey}/${sectionDetailsId}/${dayId}`,
        response => {
            success(response)
        },
        error => {
            failure("getAllocatedTimeTable - " + error.message)
            console.log('error caught in service : getAllocatedTimeTable')
        },
    );

export const getAllocatedTimeTableMaster = (sectionDetailsId: number, dayOrderTypeId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getAllocatedTimeTable/${getLicenseKey}/${sectionDetailsId}/${dayOrderTypeId}`,
        response => {
            success(response)
        },
        error => {
            failure("getAllocatedTimeTable - " + error.message)
            console.log('error caught in service : getAllocatedTimeTable')
        },
    );

export const getPeriodListByShiftId = (shiftId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getPeriod/${getLicenseKey}/${shiftId}/0`,
        response => {
            success(response)
        },
        error => {
            failure("getPeriod - " + error.message)
            console.log('error caught in service : getPeriod')
        },
    );

export const getDayOrder = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getDayOrderType/${getLicenseKey}/1`,
        response => {
            success(response)
        },
        error => {
            failure("getDayOrderType - " + error.message)
            console.log('error caught in service : getDayOrderType')
        },
    );

export const addClassTimeTable = (timeTablePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addClassTimeTable`, timeTablePostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addClassTimeTable')
        }
    );

export const updateTimeTableMasterDetails = (timeTableEditPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateTimeTableMasterDetails`, timeTableEditPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateTimeTableMasterDetails')
        }
    );
export const deleteTimeTableMasterDetails = (delTimeTablePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `deleteTimeTableMasterDetails`, delTimeTablePostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : deleteTimeTableMasterDetails')
        }
    );

    export const getScheduleDetailsByDate = (userTypeId: number, userAccountId: number, date: any,success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

        api.get(getAPIURL() + `getScheduleDetailsByDate/${getLicenseKey}/${userTypeId}/${userAccountId}/${date}`,
            response => {
                success(response)
            },
            error => {
                failure("getScheduleDetailsByDate - " + error.message)
                console.log('error caught in service : getScheduleDetailsByDate')
            },
        );

        export const addPeriodName = (periodFormPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

            api.post(getAPIURL() + `addPeriodName`, periodFormPostData,
                response => {
                    success(response)
                },
                error => {
                    failure(error.message)
                    console.log('error caught in service : addPeriodName')
                }
            );
        
        export const updatePeriodName = (periodFormPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>
        
            api.post(getAPIURL() + `updatePeriodName`, periodFormPostData,
                response => {
                    success(response)
                },
                error => {
                    failure(error.message)
                    console.log('error caught in service : updatePeriodName')
                }
            );
        
        export const deletePeriodName = (periodFormPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>
        
            api.post(getAPIURL() + `deletePeriodName`, periodFormPostData,
                response => {
                    success(response)
                },
                error => {
                    failure(error.message)
                    console.log('error caught in service : deletePeriodName')
                }
            );