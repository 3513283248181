import React, { useContext, useEffect, useRef, useState } from 'react';
import {
	getQuizDetails,
	getStudentListForQuizLeaderboard,
	getSubjectListByStandardDetailsId,
} from '../../../services/exam.service';
import { toasts } from '../../../services/toast.service';
import AuthContext from '../../../contexts/authContext';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../../bootstrap/Card';
import { getBatchMasterDetails, getSectionDetails } from '../../../services/master.service';
import OpenCardComponent from '../../../common/components/OpenCardComponent';
import SearchableSelect from '../../../common/components/SearchableSelect';
import Button, { ButtonGroup } from '../../bootstrap/Button';
import Collapse from '../../bootstrap/Collapse';
import FormGroup from '../../bootstrap/forms/FormGroup';
import {
	getColumnsForDataTable,
	getStandardDetails,
	profilePic,
	updateColumnsForDataTable,
} from '../../../services/common.service';
import useDarkMode from '../../../hooks/useDarkMode';
import Icon from '../../icon/Icon';
import Avatar from '../../Avatar';
import { getProfileForStudent } from '../../../services/student.service';
import NoDataMsg from '../../../common/components/NoDataMsg';
import Input from '../../bootstrap/forms/Input';
import { useFormik } from 'formik';
import useSortableData from '../../../hooks/useSortableData';
import { dataPagination } from '../../PaginationButtons';
import { downloadExcel, convertJsonToPdf, downloadFile } from '../../../services/ExportService';
import Dropdown, { DropdownToggle, DropdownMenu, DropdownItem } from '../../bootstrap/Dropdown';
import { useReactToPrint } from 'react-to-print';
import { getLicenseKey } from '../../../services/application.settings';


const LeaderBoard = () => {
	const { userAccountId, userTypeId } = useContext(AuthContext);
	

	useEffect(() => {
		getStudentProfile(userAccountId);
	}, []);

	const [batchMasterId, setBatchMasterId] = useState<any>('');
	const [standardDetailsId, setStandardDetailsId] = useState<any>('');
	const [quizDetailsId, setQuizDetailsId] = useState<any>('');
	const [mediumId, setMediumId] = useState<any>('');
	const [sectionDetailsId, setSectionDetailsId] = useState<any>('');

	const [studentDetailsData, setStudentDetailsData] = useState<any>([]);
	const [leaderData, setLeaderData] = useState<any>([]);
	const [quizDetailsData, setQuizDetailsData] = useState<any>([]);
	const [isOpenListCard, setIsOpenListCard] = useState(true);
	const [isLoader, setIsLoader] = useState(false);

	const [dataSuccess, setDataSuccess] = useState(false);
	const [noDataMsg, setNoDataMsg] = useState('');

	const [columnVisibilityData, setColumnVisibilityData] = useState([]);
	const [allColumnsData, setAllColumnsData] = useState([]);
	const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
	const [columnDataById, setColumnDataById] = useState([]);
	const [isChecked, setIsChecked] = useState(false);

	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const { items, requestSort, getClassNamesFor } = useSortableData(leaderData);
	const onCurrentPageData = dataPagination(items, currentPage, perPage);
	const componentRef = useRef(null);

	const columnVisibilityForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			searchInput: '',
			available: false,
		},
		validate: (values) => {},
		//validateOnChange: false,
		onSubmit: () => {},
	});

	const selectQuizType = (e: any) => {
		let quizDetailsId = e;
		setQuizDetailsId(quizDetailsId);
	};

	function View() {
		setLeaderData('');
		setDataSuccess(false);
		setIsLoader(true);
		getLeaderBoardList(
			batchMasterId,
			standardDetailsId,
			quizDetailsId?.value,
			sectionDetailsId,
		);
	}

	const filteredData = onCurrentPageData.filter(
		(i) =>
			(i.studentName !== null &&
				i.studentName
					.toString()
					.toLowerCase()
					.includes(columnVisibilityForm.values.searchInput.toLowerCase())) ||
			(i.admissionNo !== null &&
				i.admissionNo
					.toString()
					.toLowerCase()
					.includes(columnVisibilityForm.values.searchInput.toLowerCase())) ||
			(i.totalMark !== null &&
				i.totalMark
					.toString()
					.toLowerCase()
					.includes(columnVisibilityForm.values.searchInput.toLowerCase())),
	);

	function getStudentProfile(studentDetailsId: any) {
		getProfileForStudent(
			studentDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.studentProfile[0];
					if (data != undefined) {
						setStudentDetailsData(data);

						getQuizDetailsList(
							data.academicPeriodId,
							data.batchMasterId,
							data.mediumId,
							data.standardDetailsId,
						);

						setBatchMasterId(data.batchMasterId);
						setStandardDetailsId(data.standardDetailsId);
						setSectionDetailsId(data.sectionDetailsId);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					// toasts(response.data.message, "Error")
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getQuizDetailsList(academicPeriodId: any,batchMasterId: any,mediumId: any,standardDetailsId: any) {
		getQuizDetails(academicPeriodId,batchMasterId,mediumId,standardDetailsId,0,0,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.quizDetails;
					if (data != undefined) {
						setQuizDetailsData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setQuizDetailsData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getLeaderBoardList(
		batchMasterId: any,
		standardDetailsId: any,
		quizDetailsId: any,
		sectionDetailsId: any,
	) {
		getStudentListForQuizLeaderboard(
			batchMasterId,
			standardDetailsId,
			quizDetailsId,
			sectionDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.studentListForQuizLeaderboard;
					if (data != undefined) {
						setIsLoader(false);
						getColumnsForTable('getStudentListForQuizLeaderboard', 'get');
						for (let i = 0; i < data.length; i++) {
							data[i].sno = i + 1;
						}
						setLeaderData(data);
						setDataSuccess(true);
						setIsOpenListCard(false);
					} else {
						toasts('Undefined Data', 'Error');
						setDataSuccess(true);
					}
				} else if (response.data.success === false) {
					toasts(response.data.message, 'Error');
					setDataSuccess(true);
					getColumnsForTable('getStudentListForQuizLeaderboard', 'get');
					setNoDataMsg(response.data.message);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
					setDataSuccess(true);
				}
			},
			(error) => {
				toasts(error, 'Error');
				setDataSuccess(true);
			},
		);
	}

	function getColumnsForTable(apiName: any, type: any) {
		getColumnsForDataTable(
			userTypeId,
			apiName,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.columnsForDataTable;
					if (data != undefined) {
						let allColumnsData = data;
						setAllColumnsData(allColumnsData);
						let columnVisibilityData = allColumnsData.filter(
							(item: any) => item.isDisplay == 1,
						);
						setColumnVisibilityData(columnVisibilityData);

						const allData = allColumnsData.map((item: any) => item.isDisplay);
						const allColumns = allData.filter((item: any) => item == false);

						if (type == 'get') {
							setIsChecked(allColumns == '' ? true : false);
						} else if (type == 'post') {
							const all = [];
							let nonDefaultValue = allColumnsData.filter(
								(item: any) => item.isDefault == false,
							);

							for (let i = 0; i < nonDefaultValue.length; i++) {
								const obj = {
									columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
									isDisplay: allColumns == '' ? 0 : 1,
								};
								all.push(obj);
							}
							updateColumnsSubmit('', '', all);
						}
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					// toasts(response.data.message, "Error")
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {
		if (columnVisibilityForm.isValid) {
			setColumnDataById(isDisplay);
			const columnVisibilityPostData = {
				licenseKey: getLicenseKey,
				userAccountId: userAccountId,
				columns: type
					? type
					: [
							{
								columnVisibilityId: columnVisibilityId,
								isDisplay: isDisplay ? 0 : 1,
							},
						],
			};

			updateColumnsForDataTable(
				columnVisibilityPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						getColumnsForTable('getStudentListForQuizLeaderboard', 'get');
					} else if (data.success == false) {
						// toasts(data.success, "Error")
					} else {
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						toasts(errorDescription, 'Error');
					}
				},
				(error) => {
					toasts(error, 'Error');
				},
			);
		} else if (columnVisibilityForm.isValid == false) {
			toasts('Please fill all the details!', 'Error');
		}
	}

	// Export Configuration
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	return (
		<PageWrapper title='Leader Board'>
			<Page container='fluid'>
				<Collapse isOpen={isOpenListCard}>
					<Card stretch data-tour='list'>
						<CardHeader borderSize={1}>
							<CardLabel icon='List' iconColor='info'>
								<CardTitle tag='div' className='h5'>
									Leader Board
								</CardTitle>
							</CardLabel>
						</CardHeader>
						<CardBody>
							<div className='row'>
								<div className='col-3'>
									<FormGroup id='quizDetailsId' label='Quiz' isFloating>
										<SearchableSelect
											isFloating
											ariaLabel='Quiz'
											onChange={selectQuizType}
											value={quizDetailsId}
											list={quizDetailsData.map((data: any) => ({
												value: data.quizDetailsId,
												label: data.titleName + ' - ' + data.paperName,
											}))}
										/>
									</FormGroup>
								</div>

								{quizDetailsId?.value != undefined ? (
									<div className='col-2 mt-2'>
										<Button icon='ArrowDownward' color='primary' onClick={View}>
											View
										</Button>
									</div>
								) : null}
							</div>
						</CardBody>
					</Card>
				</Collapse>
				<OpenCardComponent
					isOpenListCard={isOpenListCard}
					setIsOpenListCard={setIsOpenListCard}
					isLoader={isLoader}
				/>
				{dataSuccess == true ? (
					<div className='col-12'>
						<Card stretch ref={componentRef}>
							<CardHeader borderSize={1}>
								<CardLabel icon='Task' iconColor='primary' className="col-lg-7">
									<CardTitle tag='div' className='h5'>
										Leader Board
									</CardTitle>
								</CardLabel>
								<ButtonGroup
									className='col-lg-3 ms-4 d-print-none d-flex content-justify-end'
									color='primary'>
									<Icon
										className='mt-1'
										icon='Search'
										size='2x'
										color='primary'
									/>
									<Input
										id='searchInput'
										type='search'
										placeholder='Search...'
										onChange={columnVisibilityForm.handleChange}
										value={columnVisibilityForm.values.searchInput}
									/>
								</ButtonGroup>
								<CardActions className='d-print-none'>
									<Dropdown isButtonGroup>
										<DropdownToggle>
											<Button color='primary' isLight icon='CloudDownload'>
												{' '}
												Export{' '}
											</Button>
										</DropdownToggle>
										<DropdownMenu isAlignmentEnd>
											<DropdownItem>
												<Button
													color='primary'
													isLight
													icon='CloudDownload'
													onClick={() =>
														downloadExcel(
															'LeaderBoard',
															leaderData,
															columnVisibilityData,
														)
													}>
													{' '}
													Excel{' '}
												</Button>
											</DropdownItem>
											<DropdownItem>
												<Button
													color='primary'
													isLight
													icon='PictureAsPdf'
													onClick={() =>
														convertJsonToPdf(
															leaderData,
															columnVisibilityData,
															'LeaderBoard',
														)
													}>
													{' '}
													PDF
												</Button>
											</DropdownItem>
											<DropdownItem>
												<Button
													color='primary'
													isLight
													icon='CloudDownload'
													onClick={() =>
														downloadFile(
															'LeaderBoard',
															leaderData,
															columnVisibilityData,
														)
													}>
													{' '}
													CSV{' '}
												</Button>
											</DropdownItem>
											<DropdownItem>
												<Button
													color='primary'
													isLight
													icon='Print'
													onClick={handlePrint}>
													{' '}
													Print{' '}
												</Button>
											</DropdownItem>
										</DropdownMenu>
									</Dropdown>
								</CardActions>
							</CardHeader>
							<CardBody className='table-responsive'>
								<table className='table table-modern table-hover text-nowrap'>
									<thead>
										<tr>
											{/* <td style={{ width: 20 }} /> */}
											<th>S.no</th>
											<th>Student Name</th>
											<th>Admission No</th>
											<th>Total</th>
											<th>Scored Mark</th>
										</tr>
									</thead>

									<tbody className='col-12'>
										{leaderData != '' ? (
											<>
												{filteredData.map((i: any) => (
													<tr key={i.studentDetailsId}>
														<td>{i.sno}</td>
														<td>
															<div className='d-flex'>
																<div className='flex-shrink-0'>
																	{i.profilePath != null ? (
																		<Avatar
																			src={i.profilePath}
																			size={36}
																		/>
																	) : (
																		<img
																			src={profilePic(
																				i.genderId,
																			)}
																			width='36'
																			height='36'
																		/>
																	)}
																</div>
																<div className='flex-grow-1 ms-3 d-flex align-items-center text-nowrap'>
																	{i.studentName}
																</div>
															</div>
														</td>
														<td>{i.admissionNo}</td>
														<td>{i.totalMark}</td>
														<td>{i.scoredMark}</td>
													</tr>
												))}
											</>
										) : (
											<NoDataMsg
												columnsCount={leaderData.length + 2}
												msg={noDataMsg}
											/>
										)}
									</tbody>
								</table>
							</CardBody>
						</Card>
					</div>
				) : null}
			</Page>
		</PageWrapper>
	);
};

export default LeaderBoard;
