import React, { useContext, useEffect, useState } from 'react';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody, CardFooter, CardHeader, CardLabel, CardTitle } from '../../bootstrap/Card';
import SearchableSelect from '../../../common/components/SearchableSelect';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Collapse from '../../bootstrap/Collapse';
import OpenCardComponent from '../../../common/components/OpenCardComponent';
import { profilePic } from '../../../services/common.service';
import Button from '../../bootstrap/Button';
import Input from '../../bootstrap/forms/Input';
import { showLoader } from '../../../services/loader.services';
import AuthContext from '../../../contexts/authContext';
import { getLicenseKey } from '../../../services/application.settings';
import { useFormik } from 'formik';
import { getGalleryImages, getGalleryType, uploadGalleryPic } from '../../../services/complaint.service';
import { toasts } from '../../../services/toast.service';
import AlertService from '../../../services/AlertService';
import InputGroup from '../../bootstrap/forms/InputGroup';
import { useNavigate } from 'react-router-dom';

const Gallery = () => {

	const navigate = useNavigate();

	const { userAccountId } = useContext(AuthContext);

	useEffect(() => {
		getGalleryTypeList();
	}, []);

	const [galleryType, setGalleryType] = useState<any>('');
	const [selectedFile1, setSelectedFile1] = useState<any>('');
	const [selectedFile2, setSelectedFile2] = useState<any>('');
	const [selectedFile3, setSelectedFile3] = useState<any>('');
	const [selectedFile4, setSelectedFile4] = useState<any>('');

	const [galleryData, setGalleryData] = useState<any>([]);

	const [alertStatus, setAlertStatus] = useState<any>({ message: '', type: '' });
	const [isOpen, setIsOpen] = useState(false);
	const [isNavigate, setIsNavigate] = useState<any>(null);

	const [noOfImage, setNoOfImage] = useState<any>(0);

	const [isOpenListCard, setIsOpenListCard] = useState(true);
	const [isLoader, setIsLoader] = useState(false);
	const [dataSuccess, setDataSuccess] = useState(false);

	const selectGalleryType = (e: any) => {
		setDataSuccess(false);
		let galleryType = e;
		setGalleryType(galleryType);
		if (isVisibleOnGalleryType) {
			getGalleryImagesList(galleryType?.value)
		}
		else {
			getGalleryImagesList(galleryType)
		}

	};

	const handleFileChange1 = (e: any) => {
		if (e.target.files && e.target.files[0]) {
			setNoOfImage(noOfImage + 1);
			setSelectedFile1(e.target.files[0]);
		}
	};

	const handleFileChange2 = (e: any) => {
		if (e.target.files && e.target.files[0]) {
			setNoOfImage(noOfImage + 1);
			setSelectedFile2(e.target.files[0]);
		}
	};

	const handleFileChange3 = (e: any) => {
		if (e.target.files && e.target.files[0]) {
			setNoOfImage(noOfImage + 1);
			setSelectedFile3(e.target.files[0]);
		}
	};

	const handleFileChange4 = (e: any) => {
		if (e.target.files && e.target.files[0]) {
			setNoOfImage(noOfImage + 1);
			setSelectedFile4(e.target.files[0]);
		}
	};

	const handleDeleteAvatar1 = () => {
		setNoOfImage(noOfImage - 1);
		setSelectedFile1(null);
		const fileInput = document.getElementById('fileInput1') as HTMLInputElement;
		if (fileInput) {
			fileInput.value = '';
		}
	};

	const handleDeleteAvatar2 = () => {
		setNoOfImage(noOfImage - 1);
		setSelectedFile2(null);
		const fileInput = document.getElementById('fileInput2') as HTMLInputElement;
		if (fileInput) {
			fileInput.value = '';
		}
	};

	const handleDeleteAvatar3 = () => {
		setNoOfImage(noOfImage - 1);
		setSelectedFile3(null);
		const fileInput = document.getElementById('fileInput3') as HTMLInputElement;
		if (fileInput) {
			fileInput.value = '';
		}
	};

	const handleDeleteAvatar4 = () => {
		setSelectedFile4(null);
		setNoOfImage(noOfImage - 1);
		const fileInput = document.getElementById('fileInput4') as HTMLInputElement;
		if (fileInput) {
			fileInput.value = '';
		}
	};

	function View() {
		setIsOpenListCard(false);
		setDataSuccess(true);
	}

	function getGalleryTypeList() {
		getGalleryType(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.galleryType;
					if (data != undefined) {
						setGalleryData(data);
						setIsVisibleOnGalleryType(true);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					setGalleryData([]);
					setIsVisibleOnGalleryType(false);
					//toasts(response.data.message, "Error")
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	const [galleryImages, setGalleryImages] = useState<any>([])

	function getGalleryImagesList(galleryType: any) {
		getGalleryImages(0,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.galleryImages;
					if (data != undefined) {
						let images = data.filter((item: any) => item.galleryType == galleryType)
						setGalleryImages(images[0]?.image);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					setGalleryImages([]);
					//toasts(response.data.message, "Error")
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function onUpload() {
		showLoader(true);

		if (selectedFile1 != '' || selectedFile2 != '' || selectedFile3 != '' || selectedFile4 != '') {
			const galleryData = new FormData();
			galleryData.append('licenseKey', getLicenseKey.toString());
			galleryData.append('staffDetailsId', userAccountId);
			galleryData.append('galleryType', isVisibleOnGalleryType ? galleryType?.value : galleryType);
			galleryData.append('noOfImage', noOfImage);
			galleryData.append('dataCount', galleryImages != undefined ? galleryImages.length : '0');
			selectedFile1 != '' && galleryData.append('imagePath1', selectedFile1, selectedFile1.name);
			selectedFile2 != '' && galleryData.append('imagePath2', selectedFile2, selectedFile2.name);
			selectedFile3 != '' && galleryData.append('imagePath3', selectedFile3, selectedFile3.name);
			selectedFile4 != '' && galleryData.append('imagePath4', selectedFile4, selectedFile4.name);
			uploadGalleryPic(
				galleryData,
				(response) => {
					if (response.data.success == true) {
						showLoader(false);
						setAlertStatus({ message: response.data.message, type: 'success' });
						setIsOpen(true);
						setIsNavigate(`../management/galleryImages`)
						closeAndReset();
					} else {
						showLoader(false);
						setAlertStatus({ message: response.data.message, type: 'warning' });
						setIsOpen(true);
						setIsNavigate(null);
					}
				},
				(error) => {
					showLoader(false);
					setAlertStatus({ message: 'Image not upload', type: 'error' });
					setIsOpen(true);
					setIsNavigate(null);
				},
			);
		} else {
			showLoader(false);
			setAlertStatus({ message: 'Please select image', type: 'error' });
			setIsOpen(true);
			setIsNavigate(null);
		}
	}

	function closeAndReset() {
		setSelectedFile1('');
		setSelectedFile2('');
		setSelectedFile3('');
		setSelectedFile4('');
		const fileInput1 = document.getElementById('fileInput1') as HTMLInputElement;
		const fileInput2 = document.getElementById('fileInput2') as HTMLInputElement;
		const fileInput3 = document.getElementById('fileInput3') as HTMLInputElement;
		const fileInput4 = document.getElementById('fileInput4') as HTMLInputElement;
		if (fileInput1) {
			fileInput1.value = '';
		}
		if (fileInput2) {
			fileInput2.value = '';
		}
		if (fileInput3) {
			fileInput3.value = '';
		}
		if (fileInput4) {
			fileInput4.value = '';
		}
	}

	const [isVisibleOnGalleryType, setIsVisibleOnGalleryType] = useState(true);

	const toggleVisibilityGalleryType = () => {
		setIsVisibleOnGalleryType(!isVisibleOnGalleryType);
		setGalleryType('')
	};

	return (
		<PageWrapper title='Gallery'>
			<Page container='fluid'>
				<Collapse isOpen={isOpenListCard}>
					<Card>
						<CardHeader borderSize={1}>
							<CardLabel icon='list' iconColor='info'>
								<CardTitle>Gallery</CardTitle>
							</CardLabel>
						</CardHeader>
						<CardBody>
							<div className='row'>
								<div className='col-md-3'>
									<InputGroup>
										{isVisibleOnGalleryType ? (
											<FormGroup
												id='galleryType'
												label='Gallery Type'
												isFloating>
												<SearchableSelect
													isFloating
													ariaLabel='Gallery Type'
													onChange={selectGalleryType}
													value={galleryType}
													list={galleryData.map((data: any) => ({
														value: data.galleryType,
														label: data.galleryType,
													}))}
												/>
											</FormGroup>
										) : (
											<FormGroup
												id='galleryType'
												label='Gallery Type'
												isFloating>
												<Input
													onInput={(e: any) =>
														selectGalleryType(e.target.value)
													}
													value={galleryType}
													placeholder='Enter Gallery Type'
												/>
											</FormGroup>
										)}
										<Button
											isOutline
											color='primary'
											icon='Add'
											style={{ height: '46px' }}
											onClick={toggleVisibilityGalleryType}></Button>
									</InputGroup>
								</div>
								{galleryType?.value != undefined || isVisibleOnGalleryType == false ? (
									<div className='col-md-3'>
										<Button className='mt-2' icon='ArrowDownward' color='primary' onClick={View} isDisable={!isVisibleOnGalleryType && galleryType != '' || galleryType?.value != undefined ? false : true}>
											View
										</Button>
									</div>
								) : null}
							</div>
						</CardBody>
					</Card>
				</Collapse>
				<OpenCardComponent
					isOpenListCard={isOpenListCard}
					setIsOpenListCard={setIsOpenListCard}
					isLoader={isLoader}
				/>

				{dataSuccess ? (
					<Card>
						<CardHeader borderSize={1}>
							<CardLabel icon='List' iconColor='info' className='col-lg-5'>
								<CardTitle tag='div' className='h5'>
									Gallery Details
								</CardTitle>
							</CardLabel>
						</CardHeader>
						<CardBody className='table-responsive'>
							<div className='row'>
								<div className='col-3'>
									<Card stretch shadow={'md'} className='border border-light'>
										<CardBody>
											{selectedFile1 ? (
												<img
													src={URL.createObjectURL(selectedFile1)}
													alt=''
													width={138}
													height={138}
													className='mx-auto d-block img-fluid mb-3 rounded'
												/>
											) : (
												<img
													src={profilePic(0)}
													alt=''
													width={140}
													height={140}
													className='mx-auto d-block img-fluid rounded'
												/>
											)}
										</CardBody>
										<CardFooter borderSize={1}>
											<div className='col-12'>
												<Input
													className='ms-1'
													id='fileInput1'
													type='file'
													autoComplete='photo'
													ariaLabel='Upload image file'
													onChange={handleFileChange1}
												/>

												<Button
													style={{ marginLeft: '65px' }}
													className='mt-3'
													color='dark'
													isLight
													icon='Delete'
													onClick={handleDeleteAvatar1}>
													Delete
												</Button>
											</div>
										</CardFooter>
									</Card>
								</div>

								<div className='col-3'>
									<Card stretch shadow={'md'} className='border border-light'>
										<CardBody>
											{selectedFile2 ? (
												<img
													src={URL.createObjectURL(selectedFile2)}
													alt=''
													width={128}
													height={128}
													className='mx-auto d-block img-fluid mb-3 rounded'
												/>
											) : (
												<img
													src={profilePic(0)}
													alt=''
													width={140}
													height={140}
													className='mx-auto d-block img-fluid rounded'
												/>
											)}
										</CardBody>
										<CardFooter borderSize={1}>
											<div className='col-12'>
												<Input
													className='ms-1'
													id='fileInput2'
													type='file'
													autoComplete='photo'
													ariaLabel='Upload image file'
													onChange={handleFileChange2}
													disabled={selectedFile1 != '' ? false : true}
												/>

												<Button
													style={{ marginLeft: '65px' }}
													className='mt-3'
													color='dark'
													isLight
													icon='Delete'
													onClick={handleDeleteAvatar2}>
													Delete
												</Button>
											</div>
										</CardFooter>
									</Card>
								</div>

								<div className='col-3'>
									<Card stretch shadow={'md'} className='border border-light'>
										<CardBody>
											{selectedFile3 ? (
												<img
													src={URL.createObjectURL(selectedFile3)}
													alt=''
													width={128}
													height={128}
													className='mx-auto d-block img-fluid mb-3 rounded'
												/>
											) : (
												<img
													src={profilePic(0)}
													alt=''
													width={140}
													height={140}
													className='mx-auto d-block img-fluid rounded'
												/>
											)}
										</CardBody>
										<CardFooter borderSize={1}>
											<div className='col-12'>
												<Input
													className='ms-1'
													id='fileInput3'
													type='file'
													autoComplete='photo'
													ariaLabel='Upload image file'
													onChange={handleFileChange3}
													disabled={selectedFile2 != '' ? false : true}
												/>

												<Button
													style={{ marginLeft: '65px' }}
													className='mt-3'
													color='dark'
													isLight
													icon='Delete'
													onClick={handleDeleteAvatar3}>
													Delete
												</Button>
											</div>
										</CardFooter>
									</Card>
								</div>

								<div className='col-3'>
									<Card stretch shadow={'md'} className='border border-light'>
										<CardBody>
											{selectedFile4 ? (
												<img
													src={URL.createObjectURL(selectedFile4)}
													alt=''
													width={128}
													height={128}
													className='mx-auto d-block img-fluid mb-3 rounded'
												/>
											) : (
												<img
													src={profilePic(0)}
													alt=''
													width={140}
													height={140}
													className='mx-auto d-block img-fluid rounded'
												/>
											)}
										</CardBody>
										<CardFooter borderSize={1}>
											<div className='col-12'>
												<Input
													className='ms-1'
													id='fileInput4'
													type='file'
													autoComplete='photo'
													ariaLabel='Upload image file'
													onChange={handleFileChange4}
													disabled={selectedFile3 != '' ? false : true}
												/>
												<Button
													style={{ marginLeft: '65px' }}
													className='mt-3'
													color='dark'
													isLight
													icon='Delete'
													onClick={handleDeleteAvatar4}>
													Delete
												</Button>
											</div>
										</CardFooter>
									</Card>
								</div>
								<br />
							</div>
							<br />
							<div className='col-12 d-flex justify-content-end'>
								<Button icon='Save' color='info' type='submit' onClick={onUpload}>
									Submit
								</Button>
							</div>
						</CardBody>
					</Card>
				) : null}
			</Page>
			<AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} isNavigate={isNavigate} />
		</PageWrapper>
	);
};

export default Gallery;
