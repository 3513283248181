import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AuthContext from "../../../contexts/authContext";
import useDarkMode from "../../../hooks/useDarkMode";
import useSortableData from "../../../hooks/useSortableData";
import PaginationButtons, { dataPagination } from "../../PaginationButtons";
import { useFormik } from "formik";
import { getColumnsForDataTable, pictNotLoading, profilePic, updateColumnsForDataTable, updateFilter } from "../../../services/common.service";
import Button, { ButtonGroup } from "../../bootstrap/Button";
import classNames from "classnames";
import { getLicenseKey } from "../../../services/application.settings";
import Page from "../../../layout/Page/Page";
import SubHeader, { SubHeaderLeft, SubheaderSeparator } from "../../../layout/SubHeader/SubHeader";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../../bootstrap/Card";
import Icon from "../../icon/Icon";
import Input from "../../bootstrap/forms/Input";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import AlertService from "../../../services/AlertService";
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from "../../bootstrap/OffCanvas";
import DeleteComponents from "../../../common/components/DeleteComponents";
import FormGroup from "../../bootstrap/forms/FormGroup";
import Textarea from "../../bootstrap/forms/Textarea";
import Select from "../../bootstrap/forms/Select";
import Checks, { ChecksGroup } from "../../bootstrap/forms/Checks";
import Avatar from "../../Avatar";
import USERS from "../../../common/data/userDummyData";
import { getProfileForStaff } from "../../../services/staff.service";
import { toasts } from "../../../services/toast.service";
import { addCertificateTracker, addStudentCertificateDetails, deleteCertificateTrackingDetails, getCertificateType, getOriginalCertificateListByUserAccountId, getStudentOrStaffCertificateTrackingDetails } from "../../../services/certificate.service";
import { showLoader } from "../../../services/loader.services";
import NoDataMsg from "../../../common/components/NoDataMsg";


const StaffCertificateTracker = () => {

    //const [staffDetailsId, setStaffDetailsId] = useState<any>(Number)

    const navigate = useNavigate();
    const { staffDetailsId } = useParams();
    useEffect(() => {
        getCertificateTypeList(7, 1);
        getCertificateTrackingDetails(7, staffDetailsId, 0);
        getStaffProfile(staffDetailsId);
        getOriginalCertificateListByUserAccountIdList(7, staffDetailsId)
    }, [])

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const [profileForStaffData, setProfileForStaffData] = useState<any>([])

    const [isOpenListCard, setIsOpenListCard] = useState(true)
    const { darkModeStatus } = useDarkMode();

    const componentRef = useRef(null);
    const [dataSuccess, setDataSuccess] = useState(false)
    const [dataSuccess1, setDataSuccess1] = useState(false)

    const [studentDataArray, setStudentDataArray] = useState<any>([])
    const [studentOrStaffCertificateTrackingData, setStudentOrStaffCertificateTrackingData] = useState([])

    const [columnVisibilityData, setColumnVisibilityData] = useState<any>([]);
    const [allColumnsData, setAllColumnsData] = useState([]);
    const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
    const [columnDataById, setColumnDataById] = useState([])
    const [isChecked, setIsChecked] = useState(false)

    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(studentOrStaffCertificateTrackingData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);

    const [certificateTypeData, setCertificateTypeData] = useState<any>([])
    const [certificateTrackerDetailsData, setCertificateTrackerDetailsData] = useState([])
    const [isLoader, setIsLoader] = useState(false)

    //Check
    const [checkArray, setCheckArray] = useState<any>([])
    const [certificateTrackerCheckArray, setCertificateTrackerCheckArray] = useState<any>([])

    //OffCanvas
    const [addOriginalCertificateOffCanvas, setAddOriginalCertificateOffCanvas] = useState(false)
    const [addTrackingDetailsOffCanvas, setAddTrackingDetailsOffCanvas] = useState(false)
    const [deleteOffCanvas, setDeleteOffCanvas] = useState(false)
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);

    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);

    const [noDataMsg, setNoDataMsg] = useState('')

    const addOriginalCertificateForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            certificateTypeId: '',
            submittedDate: '',
            status: '',
        },
        validate: (values) => {
            const errors: {
                submittedDate?: string;
                status?: string;
            } = {};
            if (!values.submittedDate) {
                errors.submittedDate = 'Required';
            }
            if (!values.status) {
                errors.status = 'Required';
            }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: () => { addOriginalCertificateSubmit() },
    });

    const addCertificateTrackerForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            originalCertificateId: '',
            dueDate: '',
            status: '',
            returnDate: '',
            remarks: '',
        },
        validate: (values) => {
            const errors: {
                dueDate?: string;
                status?: string;
            } = {};
            if (!values.dueDate) {
                errors.dueDate = 'Required';
            }
            if (!values.status) {
                errors.status = 'Required';
            }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: () => { addCertificateTrackerSubmit() },
    });

    const deleteCertificateTrackerForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            studentOrStaffCertificateTrackerId: '',
            remarks: '',
            certificateName: '',
            submittedDate: '',
            dueDate: '',
            returnDate: '',
            status: ''
        },
        validate: (values) => { },
        //validateOnChange: false,
        onSubmit: () => { deleteCertificateTrackerSubmit() },
    });

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },

        //validateOnChange: false,
        onSubmit: () => { },
    });

    // Filter
    const filteredData = updateFilter(onCurrentPageData, columnVisibilityData, columnVisibilityForm);

    function viewPerticularStudent() {
        setDataSuccess(false)
        setIsLoader(true);
        getCertificateTrackingDetails(7, staffDetailsId, 0);
        getOriginalCertificateListByUserAccountIdList(7, staffDetailsId)
    }

    function getCertificateTrackingDetails(userTypeId: number, studentOrStaffDetailsId: any, studentOrStaffCertificateTrackerId: number) {
        getStudentOrStaffCertificateTrackingDetails(userTypeId, studentOrStaffDetailsId, studentOrStaffCertificateTrackerId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentOrStaffCertificateTrackingDetails;
                    let tableData = response.data.data.studentOrStaffCertificateTrackingDetails[0]['certificateTracker'];
                    if (data != undefined) {
                        // setDataSuccess1(false)
                        setIsLoader(false);
                        setStudentDataArray(data);
                        getColumnsForTable('getStudentOrStaffCertificateTrackingDetails', 'get');
                        setStudentOrStaffCertificateTrackingData(tableData)
                        setDataSuccess(true)
                        setIsOpenListCard(false);
                        for (let i = 0; i < tableData.length; i++) {
                            tableData[i].sno = i + 1;
                            tableData[i].action = <div>
                                <Button
                                    isOutline={!darkModeStatus}
                                    color='danger'
                                    isLight={darkModeStatus}
                                    className={classNames('text-nowrap', {
                                        'border-light': !darkModeStatus,
                                    })}
                                    icon='Delete'>Delete
                                </Button>
                            </div>
                        }
                    } else {
                        setIsLoader(false);
                        //toasts("Undefined Data", "Error")
                        setAlertStatus({ message: "Undefined Data", type: "error" });
                        setIsOpen(true);
                        setDataSuccess(false)
                        setIsOpenListCard(false);
                    }
                }
                else if (response.data.success === false) {
                    setIsLoader(false);
                    //toasts(response.data.message, "Error")
                    getColumnsForTable('getStudentOrStaffCertificateTrackingDetails', 'get');
                    setStudentOrStaffCertificateTrackingData([])
                    setNoDataMsg(response.data.message)
                    // setAlertStatus({ message: response.data.message, type: "error" });
                    // setIsOpen(true);
                    setDataSuccess(true)
                    //setDataSuccess1(true)
                    setIsOpenListCard(false);
                } else {
                    setIsLoader(false);
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    //toasts(errorDescription, "Error")
                    setAlertStatus({ message: errorDescription, type: "error" });
                    setIsOpen(true);
                    setDataSuccess(false)
                }
            }, error => {
                setIsLoader(false);
                //toasts(error, "Error")
                setAlertStatus({ message: error, type: "error" });
                setIsOpen(true);
            }
        )
    }

    function getCertificateTypeList(userTypeId: number, certificateTypeId: number) {
        getCertificateType(userTypeId, certificateTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.certificateType;
                    if (data != undefined) {
                        setCertificateTypeData(data);

                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getOriginalCertificateListByUserAccountIdList(userTypeId: number, staffDetailsId: any) {
        getOriginalCertificateListByUserAccountId(userTypeId, staffDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.originalCertificateListByUserAccountId;
                    if (data != undefined) {
                        setCertificateTrackerDetailsData(data);
                    } else {
                        //toasts("Undefined Data", "Error")
                        setAlertStatus({ message: "Undefined Data", type: "error" });
                        setIsOpen(true);
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    // setAlertStatus({ message: response.data.message, type: "error" });
                    // setIsOpen(true);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    //toasts(errorDescription, "Error")
                    setAlertStatus({ message: errorDescription, type: "error" });
                    setIsOpen(true);
                }
            }, error => {
                //toasts(error, "Error")
                setAlertStatus({ message: error, type: "error" });
                setIsOpen(true);
            }
        )
    }

    function selectOriginalCertificateType(e: any, certificateTypeId: any) {
        const newData: any = []

        certificateTypeData.forEach((item: any, index: any) => {
            if (item['certificateTypeId'] == certificateTypeId) {
                item['isChecked'] = e.target.checked
            }
            newData.push(item)
        });
        setCertificateTypeData(newData)
        if (e.target.checked == true) {
            checkArray.push(certificateTypeId);
        }
        else if (e.target.checked == false) {
            let unCheckArrayId = checkArray.indexOf(Number(certificateTypeId))
            checkArray.splice(unCheckArrayId, 1);
        }
    }

    function selectCertificateTracker(e: any, originalCertificateId: any) {
        const newData: any = []

        certificateTrackerDetailsData.forEach((item: any, index: any) => {
            if (item['originalCertificateId'] == originalCertificateId) {
                item['isChecked'] = e.target.checked
            }
            newData.push(item)
        });
        setCertificateTrackerDetailsData(newData)
        if (e.target.checked == true) {
            certificateTrackerCheckArray.push(originalCertificateId);
        }
        else if (e.target.checked == false) {
            let unCheckArrayId = certificateTrackerCheckArray.indexOf(Number(originalCertificateId))
            certificateTrackerCheckArray.splice(unCheckArrayId, 1);
        }
    }

    function addCertificateTrackerOpen() {
        setAddTrackingDetailsOffCanvas(true)
        getOriginalCertificateListByUserAccountIdList(7, staffDetailsId)
    }


    function setAddStudentCertificateDetails() {
        return {
            studentDetailsId: staffDetailsId,
            certificateTypeId: checkArray,
            submittedDate: addOriginalCertificateForm.values.submittedDate,
            status: addOriginalCertificateForm.values.status,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId
        }
    }

    function addOriginalCertificateSubmit() {
        showLoader(true);
        if (addOriginalCertificateForm.isValid) {
            let addStudentCertificateDetailsPostData = setAddStudentCertificateDetails();
            addStudentCertificateDetails(addStudentCertificateDetailsPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        viewPerticularStudent();
                        closeAndReset();
                    }
                    else if (data.success == false) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false);
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false);
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (addOriginalCertificateForm.isValid == false) {
            showLoader(false);
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function setCertificateTracker() {
        return {
            originalCertificateId: certificateTrackerCheckArray,
            studentOrStaffDetailsId: staffDetailsId,
            status: addCertificateTrackerForm.values.status,
            dueDate: addCertificateTrackerForm.values.dueDate,
            returnDate: addCertificateTrackerForm.values.returnDate,
            remarks: addCertificateTrackerForm.values.remarks,
            userTypeId: 7,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId
        }
    }

    function addCertificateTrackerSubmit() {
        showLoader(true);
        if (addCertificateTrackerForm.isValid) {
            let addStudentCertificateTrackerDetailsPostData = setCertificateTracker()
            addCertificateTracker(addStudentCertificateTrackerDetailsPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        viewPerticularStudent();
                        closeAndReset();
                    }
                    else if (data.success == false) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false);
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false);
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (addCertificateTrackerForm.isValid == false) {
            showLoader(false);
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function setDeleteCertificateTracker() {
        return {
            studentOrStaffCertificateTrackerId: deleteCertificateTrackerForm.values.studentOrStaffCertificateTrackerId,
            userTypeId: 7,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId
        }
    }

    function onActivate(i: any) {
        {
            if (i != undefined) {

                let selectedCertificate = i
                setDeleteOffCanvas(true)
                getCertificateTrackingDetails(7, staffDetailsId, 0);
                deleteCertificateTrackerForm.setValues({
                    studentOrStaffCertificateTrackerId: selectedCertificate.studentOrStaffCertificateTrackerId,
                    remarks: selectedCertificate.remarks,
                    certificateName: selectedCertificate.certificateName,
                    submittedDate: selectedCertificate.submittedDate,
                    dueDate: selectedCertificate.dueDate,
                    returnDate: selectedCertificate.returnDate,
                    status: selectedCertificate.status

                });
            }
        }
    }

    function deleteCertificateTrackerSubmit() {
        showLoader(true);
        let deleteStudentCertificateTrackerDetailsPostData = setDeleteCertificateTracker()
        deleteCertificateTrackingDetails(deleteStudentCertificateTrackerDetailsPostData,
            (response) => {
                const data = response.data;
                if (data.success == true) {
                    showLoader(false);
                    setAlertStatus({ message: data.message, type: "success" });
                    setIsOpen(true);
                    viewPerticularStudent();
                    closeAndReset();
                }
                else if (data.success == false) {
                    showLoader(false);
                    setAlertStatus({ message: data.message, type: "error" });
                    setIsOpen(true);
                }
                else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    showLoader(false);
                    setAlertStatus({ message: errorDescription, type: "error" });
                    setIsOpen(true);
                }
            }
            , (error) => {
                showLoader(false);
                setAlertStatus({ message: error, type: "error" });
                setIsOpen(true);
            }
        )
    }

    function getColumnsForTable(apiName: any, type: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setColumnVisibilityData(columnVisibilityData)

                        const allData = allColumnsData.map((item: any) => item.isDisplay);
                        const allColumns = allData.filter((item: any) => item == false);

                        if (type == 'get') {                           
                            setIsChecked(allColumns == '' ? true : false);
                        }
                        else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

                            for (let i = 0; i < nonDefaultValue.length; i++) {

                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: allColumns == '' ? 0 : 1
                                }
                                all.push(obj);
                            }
                            updateColumnsSubmit('', '', all)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )

    }

    function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {

        if (columnVisibilityForm.isValid) {

            setColumnDataById(isDisplay)
            const columnVisibilityPostData = {
                licenseKey: getLicenseKey,
                userAccountId: userAccountId,
                columns: type ? type : [
                    {
                        columnVisibilityId: columnVisibilityId,
                        isDisplay: isDisplay ? 0 : 1
                    }
                ]
            }

            updateColumnsForDataTable(columnVisibilityPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        getColumnsForTable('getStudentOrStaffCertificateTrackingDetails', 'get');
                    }
                    else if (data.success == false) {
                        // toasts(data.success, "Error")
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        toasts(errorDescription, "Error")
                    }
                }
                , (error) => {
                    toasts(error, "Error")
                }
            )
        } else if (columnVisibilityForm.isValid == false) {
            toasts("Please fill all the details!", "Error")
        }
    }

    function getStaffProfile(staffDetailsId: any) {
        getProfileForStaff(staffDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.profileForStaff;
                    if (data != undefined) {
                        setProfileForStaffData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function closeAndReset() {
        setAddOriginalCertificateOffCanvas(false);
        setAddTrackingDetailsOffCanvas(false);
        setDeleteOffCanvas(false);
        addCertificateTrackerForm.resetForm();
        addOriginalCertificateForm.resetForm();
        deleteCertificateTrackerForm.resetForm();
        setIsDeleteOpen(false)
        setCheckArray([])
        setCertificateTrackerCheckArray([])

        const newData: any = []
        certificateTypeData.forEach((item: any, index: any) => {
            item['isChecked'] = false;
            newData.push(item)
        });
        setCertificateTypeData(newData)
    }


    return (
        <>
            <PageWrapper title='Certificate Tracker'>
                <SubHeader>
                    <SubHeaderLeft>
                        <Button color='info' isLink icon='ArrowBack' onClick={() => navigate(`../academic/staffList`)}>
                            Back to List
                        </Button>
                        <SubheaderSeparator />
                        {profileForStaffData.map((staff: any) => (
                            <div key={staff.staffUserAccountId}>
                                <img style={{ borderRadius: '50%' }}
                                    src={staff.profilePath != null ? staff.profilePath : profilePic(staff.genderId)}
                                    height='40' width='40' onError={(e : any) => pictNotLoading(e, staff.genderId)}
                                />
                                <span>
                                    <strong className='ms-3'>{staff.fullname} </strong>
                                </span>
                                <span className='border border-success border-2 text-success fw-bold px-3 py-2 rounded'>
                                    {staff.empNumber}
                                </span>
                            </div>))}
                    </SubHeaderLeft>
                </SubHeader>
                <Page container='fluid'>
                    {dataSuccess ?
                        <Card stretch data-tour='list' ref={componentRef} id='pdf'>
                            <CardHeader borderSize={1}>
                                <CardLabel icon='List' iconColor='info' className="col-lg-5">
                                    <CardTitle tag='div' className='h5'>Certificate Tracker</CardTitle>
                                </CardLabel>&nbsp;&nbsp;
                                <CardActions className="d-print-none">
                                    <ButtonGroup className=" d-print-none" color='primary'>
                                        <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                                        <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
                                    </ButtonGroup>

                                    <Button color='primary' icon='Add' isLight onClick={() => setAddOriginalCertificateOffCanvas(true)}>
                                        Add Original Certificate
                                    </Button>
                                    <Button color='primary' icon='Add' isLight onClick={addCertificateTrackerOpen}>
                                        Add New
                                    </Button>
                                </CardActions>
                            </CardHeader>

                            <CardBody className='table-responsive' isScrollable>
                                <table className='table table-modern table-hover text-nowrap'>
                                    <thead>
                                        <tr>
                                            <th scope='col' onClick={() => requestSort('sno')}
                                                className='cursor-pointer text-decoration-underline'>S.No{' '}
                                                <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                            </th>
                                            {columnVisibilityData.map((column: any) => (
                                                <th key={column.keyName} scope='col' onClick={() => requestSort(column.keyName)}
                                                    className='cursor-pointer text-decoration-underline'>
                                                    {column.columnName}
                                                    <Icon size='lg' className={getClassNamesFor(column.keyName)} icon='FilterList' />
                                                </th>
                                            ))}
                                            <td className="d-print-none" />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredData != "" ?
                                            <>
                                                {filteredData.map((rowData: any) => (
                                                    <tr key={rowData.studentOrStaffCertificateTrackerId}>
                                                        <td>{rowData.sno}</td>
                                                        {columnVisibilityData.map((column: any) => (
                                                            (column.isDisplay && rowData[column.keyName]) ?
                                                                <td key={`${rowData.studentOrStaffCertificateTrackerId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />
                                                        ))}
                                                        <td className="d-print-none" onClick={() => { onActivate(rowData) }}>{rowData.action}</td>
                                                    </tr>
                                                ))
                                                } </>
                                            :
                                            <NoDataMsg columnsCount={columnVisibilityData.length + 2} msg={noDataMsg} />}
                                    </tbody>
                                </table>
                            </CardBody>
                            <PaginationButtons
                                className="d-print-none"
                                data={items}
                                label='items'
                                setCurrentPage={setCurrentPage}
                                currentPage={currentPage}
                                perPage={perPage}
                                setPerPage={setPerPage}
                            />
                        </Card> : null}
                    {/* {dataSuccess1 ?
                        <Card stretch data-tour='list' ref={componentRef} id='pdf'>
                            <CardHeader borderSize={1}>
                                <CardLabel icon='List' iconColor='info' className="col-lg-5">
                                    <CardTitle tag='div' className='h5'>Certificate Tracker</CardTitle>
                                </CardLabel>&nbsp;&nbsp;
                                <CardActions className="d-print-none">
                                    <ButtonGroup className=" d-print-none" color='primary'>
                                        <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                                        <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
                                    </ButtonGroup>

                                    <Button color='primary' icon='Add' isLight onClick={() => setAddOriginalCertificateOffCanvas(true)}>
                                        Add Original Certificate
                                    </Button>
                                    <Button color='primary' icon='Add' isLight onClick={addCertificateTrackerOpen}>
                                        Add New
                                    </Button>
                                </CardActions>
                            </CardHeader>
                        </Card> : null} */}

                    <OffCanvas setOpen={setAddOriginalCertificateOffCanvas} isOpen={addOriginalCertificateOffCanvas} titleId='addOriginalCertificateOffCanvas' isBodyScroll tag="form" noValidate onSubmit={addOriginalCertificateForm.handleSubmit}>
                        <OffCanvasHeader setOpen={setAddOriginalCertificateOffCanvas} onClick={closeAndReset}>
                            <OffCanvasTitle id='addOriginalCertificateOffCanvas'>Add Original Certificate</OffCanvasTitle>
                        </OffCanvasHeader>
                        <OffCanvasBody>

                            <div className="row g-4">
                                {profileForStaffData.map((staff: any) => (
                                    <div className='col-12' key={staff.staffDetailsId}>
                                        <Card className={`bg-l${darkModeStatus ? 'o25' : '25'
                                            }-info bg-l${darkModeStatus ? 'o50' : '10'
                                            }-info-hover transition-base rounded-2 mb-4`}
                                            shadow='sm'>
                                            <CardBody>
                                                <div className='d-flex align-items-center pb-3'>
                                                    <div className='flex-shrink-0 ms-2'>
                                                        <Icon icon='Person' size='4x' color='info' />
                                                    </div>
                                                    <div className='flex-grow-1 ms-3'>
                                                        <div className='fw-bold fs-4 text-dark mb-0'>
                                                            {staff.fullname}
                                                        </div>
                                                        <div className='text-muted'>
                                                            ( {staff.empNumber} )
                                                        </div>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </div>
                                ))}
                                <div className='col-12'>
                                    <ChecksGroup className="fs-5" id="certificateTypeId">
                                        {certificateTypeData.map((i: any) => (
                                            <Checks className="col-12 fs-5 mt-2" key={i.certificateTypeId} label={i.certificateName} checked={i.isChecked} onChange={(e) => selectOriginalCertificateType(e, i.certificateTypeId)}
                                            />
                                        ))}
                                    </ChecksGroup>
                                </div>
                                <div className='col-12'>
                                    <FormGroup id="submittedDate" label='Date'>
                                        <Input type="date" placeholder="Enter Date" onChange={addOriginalCertificateForm.handleChange} value={addOriginalCertificateForm.values.submittedDate} onBlur={addOriginalCertificateForm.handleBlur}
                                            isValid={addOriginalCertificateForm.isValid}
                                            isTouched={addOriginalCertificateForm.touched.submittedDate}
                                            invalidFeedback={addOriginalCertificateForm.errors.submittedDate} />
                                    </FormGroup>
                                </div>
                                <div className='col-12'>
                                    <FormGroup id="status" label="Status">
                                        <Select ariaLabel="Select Status" placeholder="Select Status" onChange={addOriginalCertificateForm.handleChange} value={addOriginalCertificateForm.values.status} onBlur={addOriginalCertificateForm.handleBlur}
                                            isValid={addOriginalCertificateForm.isValid}
                                            isTouched={addOriginalCertificateForm.touched.status}
                                            invalidFeedback={addOriginalCertificateForm.errors.status}>
                                            <option value='IN'>IN</option>
                                            <option value='DUE'>DUE</option>
                                        </Select>
                                    </FormGroup>
                                </div>
                            </div>
                        </OffCanvasBody>
                        <div className='row m-0'>
                            <div className='col-12 p-3'>
                                <Button color='info' icon="Save" className='w-100' type="submit" isDisable={checkArray.length == 0 && !addOriginalCertificateForm.isValid && !!addOriginalCertificateForm.submitCount}>
                                    Save
                                </Button>
                            </div>
                        </div>
                    </OffCanvas>

                    <OffCanvas setOpen={setAddTrackingDetailsOffCanvas} isOpen={addTrackingDetailsOffCanvas} titleId='addTrackingDetailsOffCanvas' isBodyScroll tag="form" noValidate onSubmit={addCertificateTrackerForm.handleSubmit}>
                        <OffCanvasHeader setOpen={setAddTrackingDetailsOffCanvas} onClick={closeAndReset}>
                            <OffCanvasTitle id='addTrackingDetailsOffCanvas'>Add Tracking Details</OffCanvasTitle>
                        </OffCanvasHeader>
                        <OffCanvasBody>
                            <div className="row g-4">
                                {profileForStaffData.map((staff: any) => (
                                    <div className='col-12' key={staff.staffDetailsId}>
                                        <Card className={`bg-l${darkModeStatus ? 'o25' : '25'
                                            }-info bg-l${darkModeStatus ? 'o50' : '10'
                                            }-info-hover transition-base rounded-2 mb-4`}
                                            shadow='sm'>
                                            <CardBody>
                                                <div className='d-flex align-items-center pb-3'>
                                                    <div className='flex-shrink-0 ms-2'>
                                                        <Icon icon='Person' size='4x' color='info' />
                                                    </div>
                                                    <div className='flex-grow-1 ms-3'>
                                                        <div className='fw-bold fs-4 text-dark mb-0'>
                                                            {staff.fullname}
                                                        </div>
                                                        <div className='text-muted'>
                                                            ( {staff.empNumber} )
                                                        </div>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </div>
                                ))}
                                <div className='col-12'>
                                    <ChecksGroup className="fs-5" id="originalCertificateId">
                                        {certificateTrackerDetailsData.map((i: any) => (
                                            <Checks className="col-12 fs-5 mt-2" key={i.originalCertificateId} label={i.certificateName} checked={i.isChecked} onChange={(e) => selectCertificateTracker(e, i.originalCertificateId)} />
                                        ))}
                                    </ChecksGroup>
                                </div>
                                <div className='col-12'>
                                    <FormGroup id="dueDate" label='Due Date'>
                                        <Input type="date" placeholder="Enter Date" onChange={addCertificateTrackerForm.handleChange} value={addCertificateTrackerForm.values.dueDate}
                                            onBlur={addCertificateTrackerForm.handleBlur}
                                            isValid={addCertificateTrackerForm.isValid}
                                            isTouched={addCertificateTrackerForm.touched.dueDate}
                                            invalidFeedback={addCertificateTrackerForm.errors.dueDate} />
                                    </FormGroup>
                                </div>
                                <div className='col-12'>
                                    <FormGroup id="returnDate" label='Return Date'>
                                        <Input type="date" placeholder="Enter Date" onChange={addCertificateTrackerForm.handleChange} value={addCertificateTrackerForm.values.returnDate} />
                                    </FormGroup>
                                </div>
                                <div className='col-12'>
                                    <FormGroup id="status" label="Status">
                                        <Select ariaLabel="Select Status" placeholder="Select Status" onChange={addCertificateTrackerForm.handleChange} value={addCertificateTrackerForm.values.status} onBlur={addCertificateTrackerForm.handleBlur}
                                            isValid={addCertificateTrackerForm.isValid}
                                            isTouched={addCertificateTrackerForm.touched.status}
                                            invalidFeedback={addCertificateTrackerForm.errors.status}>
                                            <option value='IN'>IN</option>
                                            <option value='DUE'>DUE</option>
                                        </Select>
                                    </FormGroup>
                                </div>
                                <div className='col-12'>
                                    <FormGroup id="remarks" label="Remarks">
                                        <Textarea placeholder="Enter Remarks" onChange={addCertificateTrackerForm.handleChange} value={addCertificateTrackerForm.values.remarks} />
                                    </FormGroup>
                                </div>
                            </div>
                        </OffCanvasBody>
                        <div className='row m-0'>
                            <div className='col-12 p-3'>
                                <Button color='info' icon="Save" className='w-100' type="submit" isDisable={certificateTrackerCheckArray.length == 0 && !addCertificateTrackerForm.isValid && !!addCertificateTrackerForm.submitCount}>
                                    Save
                                </Button>
                            </div>
                        </div>
                    </OffCanvas>

                    <OffCanvas setOpen={setDeleteOffCanvas} isOpen={deleteOffCanvas} titleId='deleteOffCanvas' isBodyScroll
                        tag="form" noValidate onSubmit={deleteCertificateTrackerForm.handleSubmit}>
                        <OffCanvasHeader setOpen={setDeleteOffCanvas} onClick={closeAndReset}>
                            <OffCanvasTitle id='deleteOffCanvas'>Certificate Details</OffCanvasTitle>
                        </OffCanvasHeader>
                        <OffCanvasBody>
                            <div className="row g-4">
                                <div className="col-12">
                                    <FormGroup id="certificateName" label='Certificate Name'>
                                        <Input type="text" onChange={deleteCertificateTrackerForm.handleChange} value={deleteCertificateTrackerForm.values.certificateName != "" ? deleteCertificateTrackerForm.values.certificateName : ""} disabled />
                                    </FormGroup>
                                </div>

                                <div className="col-12">
                                    <FormGroup id="status" label='Status'>
                                        <Input type="text" onChange={deleteCertificateTrackerForm.handleChange} value={deleteCertificateTrackerForm.values.status != "" ? deleteCertificateTrackerForm.values.status : ""} disabled />
                                    </FormGroup>
                                </div>

                                <div className="col-12">
                                    <FormGroup id="submittedDate" label='Submitted Date'>
                                        <Input type="text" onChange={deleteCertificateTrackerForm.handleChange}
                                            value={deleteCertificateTrackerForm.values.submittedDate != "" ? deleteCertificateTrackerForm.values.submittedDate : ""} disabled />
                                    </FormGroup>
                                </div>

                                <div className="col-12">
                                    <FormGroup id="dueDate" label='Due Date'>
                                        <Input type="text" onChange={deleteCertificateTrackerForm.handleChange}
                                            value={deleteCertificateTrackerForm.values.dueDate != "" ? deleteCertificateTrackerForm.values.dueDate : ""} disabled />
                                    </FormGroup>
                                </div>

                                <div className="col-12">
                                    <FormGroup id="returnDate" label='Return Date'>
                                        <Input type="text" onChange={deleteCertificateTrackerForm.handleChange} value={deleteCertificateTrackerForm.values.returnDate != "" ? deleteCertificateTrackerForm.values.returnDate : ""} disabled />
                                    </FormGroup>
                                </div>

                                <div className="col-12">
                                    <FormGroup id="remarks" label='Remarks'>
                                        <Textarea onChange={deleteCertificateTrackerForm.handleChange} value={deleteCertificateTrackerForm.values.remarks != "" ? deleteCertificateTrackerForm.values.remarks : ""} disabled />
                                    </FormGroup>
                                </div>
                                <div className="d-flex justify-content-center position-absolute top-65 start-50 translate-middle">
                                    <DeleteComponents isDeleteOpen={isDeleteOpen} setIsDeleteOpen={setIsDeleteOpen} deleteFunction={deleteCertificateTrackerSubmit} />
                                </div>
                            </div>
                        </OffCanvasBody>
                        <div className='row m-0'>
                            <div className='col-12 p-3'>
                                <Button color='danger' className='w-100' icon="Delete" onClick={() => setIsDeleteOpen(!isDeleteOpen)}>
                                    Delete
                                </Button>
                            </div>
                        </div>
                    </OffCanvas>
                </Page>
                <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
            </PageWrapper>
        </>
    );
}

export default StaffCertificateTracker;