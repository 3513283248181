import React, { useEffect, useState } from 'react'
import CommonDesc from '../../common/other/CommonDesc'
import CommonHowToUse from '../../common/other/CommonHowToUse'
import CommonStoryBtn from '../../common/other/CommonStoryBtn'
import Card, { CardHeader, CardLabel, CardTitle, CardSubTitle, CardActions, CardBody } from '../bootstrap/Card'
import Carousel from '../bootstrap/Carousel'
import PageWrapper from '../../layout/PageWrapper/PageWrapper'
import Page from '../../layout/Page/Page'
import { getEventList, getTournamentNameList } from '../../services/sportsManagement.service'
import { toasts } from '../../services/toast.service'
import Icon from '../icon/Icon'
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from '../../layout/SubHeader/SubHeader'
import Button from '../bootstrap/Button'
import { useNavigate } from 'react-router-dom'

const EventList = () => {

    useEffect(() => {
        getEventDetails()
    }, [])


    const [eventData, setEventData] = useState<any>([])
    const [dataStatus, setDataStatus] = useState<any>(false)
    const [noDataMsg, setNoDataMsg] = useState<any>('')

    function getEventDetails() {
        setDataStatus(false)
        getEventList(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.eventList;
                    if (data != undefined) {
                        setDataStatus(true)

                        for (let i = 0; i < data.length; i++) {

                            let imageData = data[i].image

                            for (let j = 0; j < imageData.length; j++) {
                                imageData[j].src = imageData[j].imagePath;
                            }
                        }

                        setEventData(data);

                    } else {
                        setDataStatus(true)
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    setDataStatus(true)
                    //toasts(response.data.message, 'Error');                   
                    setEventData([]);
                    setNoDataMsg(response.data.message)
                } else {
                    setDataStatus(true)
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, 'Error');
                }
            },
            (error) => {
                setDataStatus(true)
                toasts(error, 'Error');
            },
        );
    }

    const navigate = useNavigate();

    return (
        <PageWrapper title='Event List'>
            <SubHeader>
                <SubHeaderLeft>
                    <CardLabel icon='Image' iconColor='primary'>
                        <CardTitle tag='div' className='h5 text-uppercase'>
                            Event
                        </CardTitle>
                    </CardLabel>
                </SubHeaderLeft>
                <SubHeaderRight>
                    <SubheaderSeparator />
                    <Button color='info' isLight icon='Add' onClick={() => navigate(`../management/addEventDetails`)}>
                        Add
                    </Button>
                </SubHeaderRight>
            </SubHeader>
            <Page>
                {dataStatus ? <>
                    {eventData != '' ?
                        <div className="row">
                            {eventData.map((item: any) => (
                                <div className='col-4'>
                                    <Card stretch className='border border-light' shadow={'md'} >
                                        <CardHeader>
                                            <CardLabel icon='EventNote' iconColor='info'>
                                                <CardTitle>{item.eventName}</CardTitle>
                                                <CardSubTitle>{item.dateOfEventForView}</CardSubTitle>
                                            </CardLabel>
                                        </CardHeader>
                                        <CardBody>
                                            <Carousel
                                                isManual
                                                width={200}
                                                height={200}
                                                items={item.image}
                                                rounded={1}
                                                isRide={true}
                                            />
                                        </CardBody>
                                    </Card>
                                </div>
                            ))}
                        </div> :
                        <div className="row">
                            <div className='col-12' style={{ height: '20vh' }}></div>
                            <div className='col-12 d-flex justify-content-center'>
                                <Icon icon='DoNotDisturb' size={'10x'} />
                            </div>
                            <div className='fs-4 fw-bold text-center text-uppercase text-danger'>
                                {noDataMsg}
                            </div>
                        </div>
                    } </> : null
                }
            </Page>
        </PageWrapper>
    )
}

export default EventList