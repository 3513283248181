import { useFormik } from "formik";
import Page from "../../../layout/Page/Page";
import Card, { CardBody, CardHeader, CardLabel, CardSubTitle, CardTitle } from "../../bootstrap/Card";
import FormGroup from "../../bootstrap/forms/FormGroup";
import Select from "../../bootstrap/forms/Select";
import Input from "../../bootstrap/forms/Input";
import Icon from "../../icon/Icon";
import Button from "../../bootstrap/Button";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import { useContext, useEffect, useState } from "react";
import { toasts } from "../../../services/toast.service";
import { getVehicleDetails, getVehicleDetailsByVehicleDetailsId } from "../../../services/transport.service";
import { getCurrentDateAndTime, getLedger, onlyAllowNumber } from "../../../services/common.service";
import { getLicenseKey } from "../../../services/application.settings";
import AlertService from "../../../services/AlertService";
import { getStaffListByDesignationId, getStaffListByUserTypeId } from "../../../services/staff.service";
import AuthContext from "../../../contexts/authContext";
import Collapse from "../../bootstrap/Collapse";
import OpenCardComponent from "../../../common/components/OpenCardComponent";
import { showLoader } from "../../../services/loader.services";
import SearchableSelect from "../../../common/components/SearchableSelect";
import { addFuelConsumption } from "../../../services/fuel.service";

const FuelConsumptionEntry = () => {

    useEffect(() => {
        getVehicleDetailsList();
        getStaffListByDesignation(12);
        getLedgerList(38);
    }, [])

    const { userAccountId } = useContext(AuthContext);
    const [vehicleData, setVehicleData] = useState<any>([])
    const [vehicleDetailsId, setVehicleDetailsId] = useState<any>('')
    const [staffDetailsId, setStaffDetailsId] = useState<any>('')
    const [ledgerDetailsId, setLedgerDetailsId] = useState<any>('')
    const [vehicleNo, setVehicleNo] = useState('')
    const [vehicleDetailsData, setVehicleDetailsData] = useState([])
    const [staffDetailsData, setStaffDetailsData] = useState<any>([])
    const [ledgerDetailsData, setLedgerDetailsData] = useState<any>([])
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenListCard, setIsOpenListCard] = useState(true)
    const [isLoader, setIsLoader] = useState(false)
    const [dataSuccess, setDataSuccess] = useState(false)

    const [currentReading, setCurrentReading] = useState<any>('')
    const [mileage, setMileage] = useState<any>('')
    const [totalKmRun, setTotalKmRun] = useState<any>('')
    const [quantityFilled, setQuantityFilled] = useState<any>('')
    const [ratePerLitre, setRatePerLiter] = useState<any>(0)
    const [amount, setAmount] = useState<any>('')
    const [averageMileage, setAverageMileage] = useState<any>('')

    const [isNavigate, setIsNavigate] = useState<any>(null);

    const fuelForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            vehicleDetailsId: '',
            fuelType: "",
            staffDetailsId: '',
            previousReading: '',
            currentReading: '',
            totalKmRun: '',
            fillingDate: getCurrentDateAndTime('date'),
            quantityFilled: '',
            mileage: 0,
            ratePerLitre: '',
            amount: 0,
            averageMileage: '',
            ledgerDetailsId: '',
            remarks: '',
            previousFillingDate: '',
            previousQuantityFilled: '',
            previousMileage: 0,
        },
        validate: (values) => {
            const errors: {
                staffDetailsId?: string;
                currentReading?: string;
                fillingDate?: string;
                ratePerLitre?: string;
                ledgerDetailsId?: string;
                quantityFilled?: string;
            } = {};
            if (!staffDetailsId) {
                errors.staffDetailsId = 'Required';
            }
            if (!currentReading) {
                errors.currentReading = 'Required';
            }
            if (!values.fillingDate) {
                errors.fillingDate = 'Required';
            }
            if (!ratePerLitre) {
                errors.ratePerLitre = 'Required';
            }
            if (!ledgerDetailsId) {
                errors.ledgerDetailsId = 'Required';
            }
            if (!quantityFilled) {
                errors.quantityFilled = "Required";
            }
            return errors;
        },

        //validateOnChange: false,
        onSubmit: (values) => { fuelConsumptionEntrySubmit() },
    })

    const selectVehicle = (e: any) => {

        let vehicleDetailsId = e
        setVehicleDetailsId(vehicleDetailsId)
        if (vehicleDetailsId?.value != undefined) {
            setVehicleNo(vehicleDetailsId?.label)
        }

    }

    const selectDriverResponsible = (e: any) => {
        let staffDetailsId = e
        setStaffDetailsId(staffDetailsId)
    }

    const selectLedgerDetails = (e: any) => {
        let ledgerDetailsId = e
        setLedgerDetailsId(ledgerDetailsId)
    }

    function updateAmount(e: any) {
        setRatePerLiter(Number(e))
        const newAmount = Number(e) * Number(quantityFilled)
        setAmount(newAmount)
    }

    function updateMileage(e: any) {
        setQuantityFilled(e)
        const mileage = Number(totalKmRun) / Number(e)
        setMileage(mileage)
        const newAmount = Number(ratePerLitre) * Number(e)
        setAmount(newAmount)
        let averageMileage = mileage / fuelForm.values.previousMileage
        if (averageMileage != Infinity) {
            setAverageMileage(averageMileage.toFixed(2))
        }
        else {
            setAverageMileage(0)
        }
    }

    function updateTotalKiloMeterRun(e: any) {
        setTotalKmRun('')
        setQuantityFilled('')
        setRatePerLiter('')
        setAmount('')
        setMileage('')
        setCurrentReading(e)
        const totalKmRun = Number(e) - Number(fuelForm.values.previousReading)
        setTotalKmRun(totalKmRun)
    }

    function view() {
        closeAndReset()
        setIsLoader(true)
        setDataSuccess(false)
        getVehicleDetailsByVehicleDetailsList(vehicleDetailsId?.value)
    }

    function getVehicleDetailsByVehicleDetailsList(vehicleDetailsId: any) {
        getVehicleDetailsByVehicleDetailsId(vehicleDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.vehicleDetails[0];
                    if (data != undefined) {
                        setVehicleDetailsData(data);
                        fuelForm.setValues({
                            vehicleDetailsId: vehicleDetailsId?.value,
                            fuelType: data['fuelType'] ? data['fuelType'] : '',
                            staffDetailsId: staffDetailsId ? staffDetailsId : '',
                            previousReading: data['currentReading'] ? data['currentReading'] : '',
                            currentReading: '',
                            totalKmRun: data['totalKmRun'] ? data['totalKmRun'] : '',
                            fillingDate: data['fillingDate'] ? data['fillingDate'] : getCurrentDateAndTime('date'),
                            quantityFilled: data['quantityFilled'] ? data['quantityFilled'] : '',
                            mileage: data['mileage'] ? data['mileage'] : '',
                            ratePerLitre: data['ratePerLitre'] ? data['ratePerLitre'] : '',
                            amount: data['amount'] ? data['amount'] : '',
                            averageMileage: data['averageMileage'] ? data['averageMileage'] : '',
                            ledgerDetailsId: ledgerDetailsId ? ledgerDetailsId : '',
                            remarks: data['remarks'] ? data['remarks'] : '',
                            previousFillingDate: data['lastFuelFillingDate'] ? data['lastFuelFillingDate'] : '',
                            previousQuantityFilled: data['quantityFilled'] ? data['quantityFilled'] : '',
                            previousMileage: data['lastFuelFillingMileage'] ? data['lastFuelFillingMileage'] : '',
                        })
                        setIsOpenListCard(false);
                        setDataSuccess(true)
                        setIsLoader(false)
                    } else {
                        toasts("Undefined Data", "Error")
                        setIsLoader(false)
                        setIsOpenListCard(true);
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                    setIsLoader(false)
                    setIsOpenListCard(true);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                    setIsLoader(false)
                    setIsOpenListCard(true);
                }
            }, error => {
                toasts(error, "Error")
                setIsLoader(false)
            }
        )
    }

    function getVehicleDetailsList() {
        getVehicleDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.vehicleDetails;
                    if (data != undefined) {
                        setVehicleData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setVehicleData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStaffListByDesignation(userTypeId: any) {
        getStaffListByDesignationId(userAccountId, userTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.staffListByDesignationId;
                    if (data != undefined) {
                        setStaffDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setStaffDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getLedgerList(ledgerDetailsId: any) {
        getLedger(ledgerDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.ledger;
                    if (data != undefined) {
                        setLedgerDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setLedgerDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function setFuelConsumptionEntry() {

        return ({
            vehicleDetailsId: vehicleDetailsId?.value ? vehicleDetailsId?.value : null,
            fuelType: fuelForm.values.fuelType != "" ? fuelForm.values.fuelType : null,
            staffDetailsId: staffDetailsId?.value ? staffDetailsId?.value : null,
            previousReading: fuelForm.values.previousReading != "" ? fuelForm.values.previousReading : null,
            currentReading: currentReading != "" ? currentReading : null,
            totalKmRun: totalKmRun != "" ? totalKmRun : null,
            fillingDate: fuelForm.values.fillingDate != "" ? fuelForm.values.fillingDate : null,
            quantityFilled: quantityFilled != "" ? quantityFilled : null,
            mileage: mileage != 0 ? mileage : null,
            ratePerLitre: ratePerLitre != "" ? ratePerLitre : null,
            amount: amount != 0 ? amount : null,
            averageMileage: averageMileage != '' ? averageMileage : null,
            ledgerDetailsId: ledgerDetailsId?.value ? ledgerDetailsId?.value : null,
            remarks: fuelForm.values.remarks != "" ? fuelForm.values.remarks : null,
            licenseKey: getLicenseKey,
            createdBy: userAccountId,
            userAccountId: userAccountId,

        })
    }

    function fuelConsumptionEntrySubmit() {
        showLoader(true)
        if (fuelForm.isValid) {
            let fuelConsumptionEntryPostData = setFuelConsumptionEntry()
            addFuelConsumption(fuelConsumptionEntryPostData,
                (response) => {
                    const data = response.data;

                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        setIsNavigate(`../management/fuelConsumptionReport`)
                        closeAndReset()
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                        setIsNavigate(null);
                    }
                    else {
                        showLoader(false)
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                        setIsNavigate(null);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                    setIsNavigate(null);
                }
            )
        } else if (fuelForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
            setIsNavigate(null);
        }
    }

    function closeAndReset() {
        fuelForm.resetForm();
        setCurrentReading('')
        setMileage('')
        setTotalKmRun('')
        setQuantityFilled('')
        setRatePerLiter('')
        setAmount('')
        setStaffDetailsId('')
        setLedgerDetailsId('')
    }

    return (
        <>
            <PageWrapper title="Fuel Consumption Entry">
                <Page container='fluid'>
                    <Collapse isOpen={isOpenListCard}>
                        <Card>
                            <CardHeader borderSize={1}>
                                <CardLabel icon='list' iconColor='primary'>
                                    <CardTitle>
                                        Fuel Consumption
                                    </CardTitle>
                                </CardLabel>
                            </CardHeader>
                            <CardBody>
                                <div className='row'>
                                    <div className='col-4'>
                                        <FormGroup id="vehicleDetailsId" label="Vehicle No" isFloating>
                                            <SearchableSelect isFloating placeholder='Select Vehicle' ariaLabel={""}
                                                onChange={selectVehicle}
                                                value={vehicleDetailsId}
                                                list={vehicleData.map((data: any) => (
                                                    { value: data.vehicleDetailsId, label: data.vehicleId }
                                                ))} />
                                        </FormGroup>
                                    </div>
                                    <div className='col-3 mt-2'>
                                        <Button icon='ArrowDownward' color='primary' isDisable={vehicleDetailsId?.value == undefined} onClick={view}>View</Button>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Collapse>

                    <OpenCardComponent isOpenListCard={isOpenListCard} setIsOpenListCard={setIsOpenListCard} isLoader={isLoader} />

                    {dataSuccess ?
                        <Card stretch data-tour='list' tag='form' noValidate onSubmit={fuelForm.handleSubmit}>
                            <CardHeader borderSize={1}>
                                <CardLabel icon='DirectionsBus' iconColor='info'>
                                    <CardTitle tag='div' className='h5'>
                                        {vehicleNo}
                                    </CardTitle>
                                    <CardSubTitle>
                                        Vehicle No
                                    </CardSubTitle>
                                </CardLabel>
                            </CardHeader>
                            <CardBody>
                                <div className="row g-4">
                                    <div className="col-lg-3">
                                        <FormGroup id="fuelType" label="Fuel Type" isFloating>
                                            <Input onChange={fuelForm.handleChange}
                                                value={fuelForm.values.fuelType} disabled />
                                        </FormGroup>
                                    </div>
                                    <div className="col-lg-3">
                                        <FormGroup id="previousFillingDate" label="Previous Filling date" isFloating>
                                            <Input type='date'
                                                onChange={fuelForm.handleChange}
                                                value={fuelForm.values.previousFillingDate} disabled />
                                        </FormGroup>
                                    </div>
                                    <div className="col-lg-3">
                                        <FormGroup id="previousQuantityFilled" label="Quantity Filled (Liters) " isFloating>
                                            <Input type='text'
                                                onChange={fuelForm.handleChange}
                                                value={fuelForm.values.previousQuantityFilled} disabled />
                                        </FormGroup>
                                    </div>
                                    <div className="col-lg-3">
                                        <FormGroup id="previousMileage" label="Previous Mileage" isFloating>
                                            <Input type='text'
                                                onChange={fuelForm.handleChange}
                                                value={fuelForm.values.previousMileage} disabled />
                                        </FormGroup>
                                    </div>
                                    <div className="col-lg-3">
                                        <FormGroup id="staffDetailsId" label="Driver Responsible" isFloating>
                                            <SearchableSelect isFloating ariaLabel="Driver Responsible" placeholder="Driver Responsible"
                                                onChange={selectDriverResponsible}
                                                value={staffDetailsId}
                                                list={staffDetailsData.map((data: any) => (
                                                    { value: data.staffDetailsId, label: data.empAndStaffName }
                                                ))}
                                                onBlur={fuelForm.handleBlur}
                                                isValid={fuelForm.isValid}
                                                isTouched={fuelForm.touched.staffDetailsId}
                                                invalidFeedback={fuelForm.errors.staffDetailsId} />
                                        </FormGroup>
                                    </div>
                                    <div className="col-lg-3">
                                        <FormGroup id="previousReading" label="Previous Reading" isFloating>
                                            <Input type='text'
                                                onChange={fuelForm.handleChange}
                                                value={fuelForm.values.previousReading} disabled />
                                        </FormGroup>
                                    </div>
                                    <div className="col-lg-3">
                                        <FormGroup id="currentReading" label="Current Reading" isFloating>
                                            <Input type='text'
                                                onInput={(e: any) => { updateTotalKiloMeterRun(e.target.value) }}
                                                value={currentReading}
                                                onBlur={fuelForm.handleBlur}
                                                isValid={fuelForm.isValid}
                                                isTouched={fuelForm.touched.currentReading}
                                                invalidFeedback={fuelForm.errors.currentReading}
                                                onKeyDown={(e: any) => onlyAllowNumber(e)} />
                                        </FormGroup>
                                    </div>
                                    <div className="col-lg-3">
                                        <FormGroup id="totalKmRun" label="Total KM Ran" isFloating>
                                            <Input type='text'
                                                value={totalKmRun} disabled />
                                        </FormGroup>
                                    </div>
                                    <div className="col-lg-3">
                                        <FormGroup id="fillingDate" label="Current Filling Date" isFloating>
                                            <Input type='date'
                                                onChange={fuelForm.handleChange}
                                                value={fuelForm.values.fillingDate}
                                                onBlur={fuelForm.handleBlur}
                                                isValid={fuelForm.isValid}
                                                isTouched={fuelForm.touched.fillingDate}
                                                invalidFeedback={fuelForm.errors.fillingDate} />
                                        </FormGroup>
                                    </div>
                                    <div className="col-lg-3">
                                        <FormGroup id="quantityFilled" label="Quantity Filled (Liters) " isFloating>
                                            <Input type='text'
                                                onInput={(e: any) => { updateMileage(e.target.value); }}
                                                value={quantityFilled}
                                                onBlur={fuelForm.handleBlur}
                                                isValid={fuelForm.isValid}
                                                isTouched={fuelForm.touched.quantityFilled}
                                                invalidFeedback={fuelForm.errors.quantityFilled} />
                                        </FormGroup>
                                    </div>
                                    <div className="col-lg-3">
                                        <FormGroup id="mileage" label="Current Mileage (per ltr)" isFloating>
                                            <Input type='text'
                                                value={mileage} disabled />
                                        </FormGroup>
                                    </div>
                                    <div className="col-lg-3">
                                        <FormGroup id="averageMileage" label="Average Mileage" isFloating>
                                            <Input type='text'
                                                onChange={fuelForm.handleChange}
                                                value={averageMileage} disabled />
                                        </FormGroup>
                                    </div>
                                    <div className="col-lg-3">
                                        <FormGroup id="ratePerLitre" label="Rate Per Liter" isFloating>
                                            <Input type='text'
                                                onInput={(e: any) => { updateAmount(e.target.value); }}
                                                value={ratePerLitre}
                                                onBlur={fuelForm.handleBlur}
                                                isValid={fuelForm.isValid}
                                                isTouched={fuelForm.touched.ratePerLitre}
                                                invalidFeedback={fuelForm.errors.ratePerLitre} />
                                        </FormGroup>
                                    </div>
                                    <div className="col-lg-3">
                                        <FormGroup id="amount" label="Amount" isFloating>
                                            <Input type='text'
                                                value={amount} disabled />
                                        </FormGroup>
                                    </div>

                                    <div className="col-lg-3">
                                        <FormGroup id="ledgerDetailsId" label="Ledger Details" isFloating>
                                            <SearchableSelect isFloating ariaLabel="Ledger Details" placeholder="Ledger Details"
                                                onChange={selectLedgerDetails}
                                                value={ledgerDetailsId}
                                                list={ledgerDetailsData.map((data: any) => (
                                                    { value: data.ledgerDetailsId, label: data.ledgerName }
                                                ))}
                                                onBlur={fuelForm.handleBlur}
                                                isValid={fuelForm.isValid}
                                                isTouched={fuelForm.touched.ledgerDetailsId}
                                                invalidFeedback={fuelForm.errors.ledgerDetailsId} />
                                        </FormGroup>
                                    </div>
                                    <div className="col-lg-3">
                                        <FormGroup id="remarks" label="remarks" isFloating>
                                            <Input type='text'
                                                onChange={fuelForm.handleChange}
                                                value={fuelForm.values.remarks} />
                                        </FormGroup>
                                    </div>

                                    <div className="d-flex justify-content-end">
                                        <FormGroup>
                                            <Button icon="Save" color="info" type="submit" isDisable={!fuelForm.isValid && !!fuelForm.submitCount}>
                                                Save
                                            </Button>
                                        </FormGroup>
                                    </div>
                                </div>
                            </CardBody>
                        </Card> : null
                    }
                </Page>
                <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} isNavigate={isNavigate} />

            </PageWrapper>
        </>
    )
}
export default FuelConsumptionEntry