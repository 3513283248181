import React, { FC, useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import useDarkMode from '../../../../hooks/useDarkMode';
import Page from '../../../../layout/Page/Page';
import FormGroup from '../../../bootstrap/forms/FormGroup';
import Input from '../../../bootstrap/forms/Input';
import { toasts } from '../../../../services/toast.service';
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from '../../../bootstrap/Card';
import Button from '../../../bootstrap/Button';
import Textarea from '../../../bootstrap/forms/Textarea';
import { useFormik } from 'formik';
import AlertService from '../../../../services/AlertService';
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from '../../../bootstrap/OffCanvas';
import Checks, { ChecksGroup } from '../../../bootstrap/forms/Checks';
import Select from '../../../bootstrap/forms/Select';
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from '../../../../layout/SubHeader/SubHeader';
import Alert from '../../../bootstrap/Alert';
import Icon from '../../../icon/Icon';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import UserImage2Webp from '../../../../assets/img/wanna/wanna1.webp';
import UserImage2 from '../../../../assets/img/wanna/wanna1.png';
import Avatar from '../../../Avatar';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../../bootstrap/Modal';
import { getCurrentDateAndTime, onlyAllowNumber, pictNotLoading, profilePic } from '../../../../services/common.service';
import { getProfileForStudent } from '../../../../services/student.service';
import AuthContext from '../../../../contexts/authContext';
import { getLicenseKey } from '../../../../services/application.settings';
import { TableLoader, showLoader } from '../../../../services/loader.services';
import Label from '../../../bootstrap/forms/Label';
import SearchableSelect from '../../../../common/components/SearchableSelect';
import { addExtraFeeDetails, addFineFeeType, addStudentAdvanceFee, addStudentFeeCollection, addStudentFineFee, getExtraFeeType, getFeeType, getFineFeeType, getNotAssignedTermFees, getStudentFeeDetails, getStudentFineFeeList, getStudentTotalFeeCollection } from '../../../../services/fee.service';

interface IStudentDetailsIdProps {
    studentDetailsId: any;
    setShowPayFees(...args: unknown[]): unknown;
    refreshAfterFeeCollection(...args: unknown[]): unknown;
    showPayFees: any;
}

const PayFees: FC<IStudentDetailsIdProps> = ({ studentDetailsId, setShowPayFees, showPayFees, refreshAfterFeeCollection }) => {

    const { userAccountId, userTypeId } = useContext(AuthContext);

    useEffect(() => {
        getStudentFeeList(userTypeId, studentDetailsId);
        getStudentFineFee(studentDetailsId);
        getStudentProfile(studentDetailsId);
        getStudentTotalFeeCollectionList(studentDetailsId);
        getFineFeeTypeList();
        getIsFineFee()
    }, []);

    function getIsFineFee() {
        { showPayFees == 'payFees' ? setIsFineFees(0) : setIsFineFees(1) }
    }

    const TABS = {
        CASH_PAYMENTS: 'Cash',
        CHEQUE: 'Cheque',
        NEFT_UPI: 'NEFT / UPI',
        CARD_PAYMENT: 'Card',
    };

    const [activeTab, setActiveTab] = useState(TABS.CASH_PAYMENTS);

    const { darkModeStatus } = useDarkMode();
    const [studentFeeDetails, setStudentFeeDetails] = useState<any>([])
    const [studentFeesCollectionDetails, setStudentFeesCollectionDetails] = useState<any>([])
    const [filteredDataToRefresh, setFilteredDataToRefresh] = useState<any>([])
    const [termFeesForTutionFeesData, setTermFeesForTutionFeesData] = useState<any>([])
    const [termFeesForTransportFeesData, setTermFeesForTransportFeesData] = useState<any>([])
    const [studentAdvanceFee, setStudentAdvanceFee] = useState<any>([])
    const [studentFineFeeByFineTypeIdData, setStudentFineFeeByFineTypeIdData] = useState<any>([])

    const [addAdvanceFeeOffCanvas, setAddAdvanceFeeOffCanvas] = useState(false)
    const [advanceTutionFeesNoData, setAdvanceTutionFeesNoData] = useState("")
    const [advanceTransportFeesNoData, setAdvanceTransportFeesNoData] = useState("")
    const [fineFeeReport, setFineFeeReport] = useState(false)

    const [addExtraFeeOffCanvas, setAddExtraFeeOffCanvas] = useState(false)
    const [feeTypeId, setFeeTypeId] = useState<any>("")
    const [extraFeeTypeId, setExtraFeeTypeId] = useState<any>("")
    const [feesTypeData, setFeesTypeData] = useState<any>([])
    const [extraFeeTypeData, setExtraFeeTypeData] = useState<any>([])
    const [fineTypeData, setFineTypeData] = useState<any>([])
    const [studentFineFeeListData, setStudentFineFeeListData] = useState<any>([])
    const [studentTotalFeeCollectionData, setStudentTotalFeeCollectionData] = useState<any>([])
    const [finalBalanceAmount, setFinalBalanceAmount] = useState<any>()

    const [paymentTypeId, setPaymentTypeId] = useState(1)
    const [fivehunderd_total, setFivehunderd_total] = useState<any>("")
    const [twoHundred_total, setTwoHundred_total] = useState<any>("")
    const [hundred_total, setHundred_total] = useState<any>("")
    const [fifty_total, setFifty_total] = useState<any>("")
    const [twenty_total, setTwenty_total] = useState<any>("")
    const [ten_total, setTen_total] = useState<any>("")
    const [five_total, setFive_total] = useState<any>("")
    const [totalAmount, setTotalAmount] = useState<any>("")
    const [balCashAmount, setBalCashAmount] = useState<any>("")
    const [totalFeesAmount, setTotalFeesAmount] = useState<any>(0)
    const [isFineFees, setIsFineFees] = useState<any>(Number)
    const [studentProfileData, setStudentProfileData] = useState<any>([])

    const [fineTypeId, setFineTypeId] = useState<any>('')

    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);

    const [hideLoader, setHideLoader] = useState(false);
    const [noDataMsgForStudentFeeDetails, setNoDataMsgForStudentFeeDetails] = useState<any>('')

    const addExtraFeesForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            feeTypeId: '',
            amount: '',
            extraFeeTypeId: '',

        },
        validate: (values) => {
            const errors: {
                feeTypeId?: string;
                amount?: string;
                extraFeeTypeId?: string;
            } = {};
            if (!feeTypeId?.value) {
                errors.feeTypeId = 'Required';
            }
            if (!values.amount) {
                errors.amount = 'Required';
            }
            if (feeTypeId?.value == 14) {
                if (!extraFeeTypeId) {
                    errors.extraFeeTypeId = 'Required';
                }
            }

            return errors;
        },
        //validateOnChange: false,
        onSubmit: (values) => { addExtraFeeSubmit() },
    });

    const payFineFeesForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            fineTypeId: '',
            amount: '',

        },
        validate: (values) => {
            const errors: {
                fineTypeId?: string;
                amount?: string;
            } = {};
            if (!fineTypeId?.values) {
                errors.fineTypeId = 'Required';
            }
            if (!values.amount) {
                errors.amount = 'Required';
            }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: (values) => { },
    });

    const payFeesDenominationForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            receiptDate: getCurrentDateAndTime('date'),
            paymentTypeId: '',
            twoThousand_total: '',
            twoThousand_count: '',
            thousand_total: '',
            thousand_count: '',
            fivehunderd_total: '',
            fivehunderd_count: '',
            twoHundred_total: '',
            twoHundred_count: '',
            hundred_total: '',
            hundred_count: '',
            fifty_total: '',
            fifty_count: '',
            twenty_total: '',
            twenty_count: '',
            ten_total: '',
            ten_count: '',
            five_total: '',
            five_count: '',
            totalAmount: '',
            chequeNumber: '',
            chequeDate: getCurrentDateAndTime('date'),
            chequeAmount: '',
            chequeBankName: '',
            neftRefNumber: '',
            neftTranDate: getCurrentDateAndTime('date'),
            neftAmount: '',
            neftBankName: '',
            cardNumber: '',
            cardDate: getCurrentDateAndTime('date'),
            cardAmount: '',
            cardBankName: '',
            balCashAmount: '',
            narration: ''
        },
        // validate: (values) => {
        //     const errors: {
        //         examMonthName?: string;
        //     } = {};
        //     if (!values.examMonthName) {
        //         errors.examMonthName = 'Required';
        //     }
        //     return errors;
        // },
        //validateOnChange: false,
        onSubmit: () => { },
    });

    const fineFeeTypeForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            fineType: '',
        },
        validate: (values) => { },
        validateOnChange: false,
        onSubmit: () => { },
    })


    function calculateFeesData(feesData: any) {
        setFinalBalanceAmount(feesData[0].total_netPayable);
    }

    function getStudentProfile(studentDetailsId: number) {
        getProfileForStudent(studentDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentProfile;
                    if (data != undefined) {
                        setStudentProfileData(data);
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStudentFeeList(userTypeId: number, studentDetailsId: number) {
        setHideLoader(true)
        getStudentFeeDetails(userTypeId, studentDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentFeeDetails[0]['feeType'];
                    if (data != undefined) {
                        setStudentFeeDetails(data);
                        setHideLoader(false)
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setNoDataMsgForStudentFeeDetails(response.data.message)
                    setHideLoader(false)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                    setHideLoader(false)
                }
            }, error => {
                toasts(error, "Error")
                setHideLoader(false)
            }
        )
    }

    function getStudentTotalFeeCollectionList(studentDetailsId: number) {
        getStudentTotalFeeCollection(studentDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentTotalFeeCollection;
                    if (data != undefined) {
                        setStudentTotalFeeCollectionData(data);
                        calculateFeesData(data)
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getTermFeesForTutionFees(studentDetailsId: number, feeTypeId: number) {
        getNotAssignedTermFees(studentDetailsId, feeTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.notAssignedTermFees;
                    if (data != undefined) {
                        setTermFeesForTutionFeesData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setTermFeesForTutionFeesData([]);
                    setAdvanceTutionFeesNoData(response.data.message)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getTermFeesForTransportFees(studentDetailsId: number, feeTypeId: number) {
        getNotAssignedTermFees(studentDetailsId, feeTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.notAssignedTermFees;
                    if (data != undefined) {
                        setTermFeesForTransportFeesData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setTermFeesForTransportFeesData([]);
                    setAdvanceTransportFeesNoData(response.data.message)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    const updateFeesReceivedValue = (cell: number, amount: any) => {

        studentFeeDetails.forEach((feeType: any) => {

            let feesData = feeType.studentFees
            const newData: any = [];

            feesData.forEach((item: any) => {

                if (item.studentFeeDetailsId == cell) {
                    item.amount = amount
                }
                newData.push(item)
                setFilteredDataToRefresh(newData)
            })
        });

        if (studentFeesCollectionDetails.length == 0) {
            const obj = {
                studentFeeDetailsId: cell,
                amount: amount,
            };
            studentFeesCollectionDetails.push(obj);
        } else {
            let selectedItem: any;
            studentFeesCollectionDetails.forEach((item: any, index: any) => {
                if (item['studentFeeDetailsId'] == cell) {
                    selectedItem = item
                    selectedItem['amount'] = amount
                }
            });
            if (selectedItem != undefined) {
                selectedItem['amount'] = amount
            } else {
                var obj
                obj = {
                    studentFeeDetailsId: cell,
                    amount: amount,
                };
                studentFeesCollectionDetails.push(obj);
            }
        }
        totalPayFees()
    }

    function totalPayFees() {
        let totalFeesAmount1 = 0;
        for (let data of studentFeesCollectionDetails) {
            let feeAmount = Number(data.amount)
            totalFeesAmount1 += feeAmount;
        }
        setTotalFeesAmount(totalFeesAmount1);
        balanceAmountCal1(fivehunderd_total, twoHundred_total, hundred_total, fifty_total, twenty_total, ten_total, five_total, totalFeesAmount1);

    }

    function setPayFeesDetails() {
        return ({
            studentDetailsId: studentDetailsId,
            receiptDate: payFeesDenominationForm.values.receiptDate != '' ? payFeesDenominationForm.values.receiptDate : null,
            studentFeesCollectionDetails: studentFeesCollectionDetails,
            paymentTypeId: paymentTypeId,
            ledgerRefId: null,
            twoThousand_total: Number(payFeesDenominationForm.values.twoThousand_total) != 0 ? Number(payFeesDenominationForm.values.twoThousand_total) : null,
            twoThousand_count: Number(payFeesDenominationForm.values.twoThousand_count) != 0 ? Number(payFeesDenominationForm.values.twoThousand_count) : null,
            thousand_total: Number(payFeesDenominationForm.values.thousand_total) != 0 ? Number(payFeesDenominationForm.values.thousand_total) : null,
            thousand_count: Number(payFeesDenominationForm.values.thousand_count) != 0 ? Number(payFeesDenominationForm.values.thousand_count) : null,
            fivehunderd_total: Number(fivehunderd_total) != 0 ? Number(fivehunderd_total) : null,
            fivehunderd_count: Number(payFeesDenominationForm.values.fivehunderd_count) != 0 ? Number(payFeesDenominationForm.values.fivehunderd_count) : null,
            twoHundred_total: Number(twoHundred_total) != 0 ? Number(twoHundred_total) : null,
            twoHundred_count: Number(payFeesDenominationForm.values.twoHundred_count) != 0 ? Number(payFeesDenominationForm.values.twoHundred_count) : null,
            hundred_total: Number(hundred_total) != 0 ? Number(hundred_total) : null,
            hundred_count: Number(payFeesDenominationForm.values.hundred_count) != 0 ? Number(payFeesDenominationForm.values.hundred_count) : null,
            fifty_total: Number(fifty_total) != 0 ? Number(fifty_total) : null,
            fifty_count: Number(payFeesDenominationForm.values.fifty_count) != 0 ? Number(payFeesDenominationForm.values.fifty_count) : null,
            twenty_total: Number(twenty_total) != 0 ? Number(twenty_total) : null,
            twenty_count: Number(payFeesDenominationForm.values.twenty_count) != 0 ? Number(payFeesDenominationForm.values.twenty_count) : null,
            ten_total: Number(ten_total) != 0 ? Number(ten_total) : null,
            ten_count: Number(payFeesDenominationForm.values.ten_count) != 0 ? Number(payFeesDenominationForm.values.ten_count) : null,
            five_total: Number(five_total) != 0 ? Number(five_total) : null,
            five_count: Number(payFeesDenominationForm.values.five_count) != 0 ? Number(payFeesDenominationForm.values.five_count) : null,
            totalAmount: Number(totalFeesAmount) != 0 ? Number(totalFeesAmount) : Number(payFineFeesForm.values.amount),

            /// Cheque Payment ///
            chequeNumber: payFeesDenominationForm.values.chequeNumber != '' ? payFeesDenominationForm.values.chequeNumber : null,
            chequeDate: paymentTypeId == 2 ? payFeesDenominationForm.values.chequeDate : null,
            chequeAmount: Number(payFeesDenominationForm.values.chequeAmount) != 0 ? Number(payFeesDenominationForm.values.chequeAmount) : null,
            chequeBankName: payFeesDenominationForm.values.chequeBankName != '' ? payFeesDenominationForm.values.chequeBankName : null,

            /// Neft Payment ///
            neftRefNumber: payFeesDenominationForm.values.neftRefNumber != '' ? payFeesDenominationForm.values.neftRefNumber : null,
            neftTranDate: paymentTypeId == 3 ? payFeesDenominationForm.values.neftTranDate : null,
            neftAmount: Number(payFeesDenominationForm.values.neftAmount) != 0 ? Number(payFeesDenominationForm.values.neftAmount) : null,
            neftBankName: payFeesDenominationForm.values.neftBankName != '' ? payFeesDenominationForm.values.neftBankName : null,

            /// Card Payment ///
            cardNumber: payFeesDenominationForm.values.cardNumber != '' ? payFeesDenominationForm.values.cardNumber : null,
            cardDate: paymentTypeId == 4 ? payFeesDenominationForm.values.cardDate : null,
            cardAmount: Number(payFeesDenominationForm.values.cardAmount) != 0 ? Number(payFeesDenominationForm.values.cardAmount) : null,
            cardBankName: payFeesDenominationForm.values.cardBankName != '' ? payFeesDenominationForm.values.cardBankName : null,

            narration: payFeesDenominationForm.values.narration != '' ? payFeesDenominationForm.values.narration : null,
            balCashAmount: Number(balCashAmount) != 0 ? Number(balCashAmount) : null,

            /// Fine Fees ///
            courseId: studentProfileData[0].courseId != '' ? studentProfileData[0].courseId : null,
            batchMasterId: studentProfileData[0].batchMasterId != '' ? studentProfileData[0].batchMasterId : null,
            termDetailsId: studentProfileData[0].termDetailsId != '' ? studentProfileData[0].termDetailsId : null,
            fineTypeId: fineTypeId?.value != '' ? fineTypeId?.value : null,
            amount: Number(payFineFeesForm.values.amount) != 0 ? Number(payFineFeesForm.values.amount) : null,

            licenseKey: getLicenseKey,
            staffDetailsId: userAccountId,
            userAccountId: userAccountId
        })
    }

    function payFeesSubmit1(postData: any) {
        if (((payFeesDenominationForm.values.chequeAmount || payFeesDenominationForm.values.neftAmount || payFeesDenominationForm.values.cardAmount) == payFineFeesForm.values.amount) || balCashAmount >= 0) {
            let payFeesPostData = postData;
            addStudentFineFee(payFeesPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        getStudentFeeList(userTypeId, studentDetailsId);
                        getStudentFineFee(studentDetailsId);
                        formReset();
                        setStudentFeesCollectionDetails([]);
                        refreshAfterFeeCollection(data.message)
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        showLoader(false)
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else {
            showLoader(false)
            setAlertStatus({ message: 'Entered Amount Not Matching!', type: "error" });
            setIsOpen(true);
        }
    }

    function payFeesSubmit() {
        showLoader(true)

        if (isFineFees == 0) {
            if (((payFeesDenominationForm.values.chequeAmount || payFeesDenominationForm.values.neftAmount || payFeesDenominationForm.values.cardAmount) == totalFeesAmount) || balCashAmount >= 0) {
                let payFeesPostData = setPayFeesDetails();
                addStudentFeeCollection(payFeesPostData,
                    (response) => {
                        const data = response.data;
                        if (data.success == true) {
                            showLoader(false)
                            setAlertStatus({ message: data.message, type: "success" });
                            setIsOpen(true);
                            getStudentFeeList(userTypeId, studentDetailsId);
                            formReset();
                            setStudentFeesCollectionDetails([]);
                            refreshAfterFeeCollection(data.message);
                        }
                        else if (data.success == false) {
                            showLoader(false)
                            setAlertStatus({ message: data.message, type: "error" });
                            setIsOpen(true);
                        }
                        else {
                            showLoader(false)
                            let errorCode = response.data.error[0].error_code;
                            let errorDescription = response.data.error[0].error_description;
                            setAlertStatus({ message: errorDescription, type: "error" });
                            setIsOpen(true);
                        }
                    }
                    , (error) => {
                        showLoader(false)
                        setAlertStatus({ message: error, type: "error" });
                        setIsOpen(true);
                    }
                )
            } else {
                showLoader(false)
                setAlertStatus({ message: 'Entered Amount Not Matching!', type: "error" });
                setIsOpen(true);
            }

        } else {
            if (fineFeeTypeForm.values.fineType == '') {
                if (((payFeesDenominationForm.values.chequeAmount || payFeesDenominationForm.values.neftAmount || payFeesDenominationForm.values.cardAmount) == payFineFeesForm.values.amount) || balCashAmount >= 0) {
                    let payFeesPostData = setPayFeesDetails();
                    addStudentFineFee(payFeesPostData,
                        (response) => {
                            const data = response.data;
                            if (data.success == true) {
                                showLoader(false)
                                setAlertStatus({ message: data.message, type: "success" });
                                setIsOpen(true);
                                getStudentFeeList(userTypeId, studentDetailsId);
                                getStudentFineFee(studentDetailsId);
                                formReset();
                                setStudentFeesCollectionDetails([]);
                                refreshAfterFeeCollection(data.message)
                            }
                            else if (data.success == false) {
                                showLoader(false)
                                setAlertStatus({ message: data.message, type: "error" });
                                setIsOpen(true);
                            }
                            else {
                                showLoader(false)
                                let errorCode = response.data.error[0].error_code;
                                let errorDescription = response.data.error[0].error_description;
                                setAlertStatus({ message: errorDescription, type: "error" });
                                setIsOpen(true);
                            }
                        }
                        , (error) => {
                            showLoader(false)
                            setAlertStatus({ message: error, type: "error" });
                            setIsOpen(true);
                        }
                    )
                } else {
                    showLoader(false)
                    setAlertStatus({ message: 'Entered Amount Not Matching!', type: "error" });
                    setIsOpen(true);

                }

            } else {
                if (fineFeeTypeForm.isValid) {
                    let fineFeePostData = setFineFeeTypeDetails();
                    addFineFeeType(fineFeePostData,
                        (response) => {
                            const data = response.data;
                            if (data.success == true) {
                                setFineTypeId(data.data.fineFeeTypeId)

                                let postData = {
                                    studentDetailsId: studentDetailsId,
                                    receiptDate: payFeesDenominationForm.values.receiptDate != '' ? payFeesDenominationForm.values.receiptDate : null,
                                    studentFeesCollectionDetails: studentFeesCollectionDetails,
                                    paymentTypeId: paymentTypeId,
                                    ledgerRefId: null,
                                    twoThousand_total: Number(payFeesDenominationForm.values.twoThousand_total) != 0 ? Number(payFeesDenominationForm.values.twoThousand_total) : null,
                                    twoThousand_count: Number(payFeesDenominationForm.values.twoThousand_count) != 0 ? Number(payFeesDenominationForm.values.twoThousand_count) : null,
                                    thousand_total: Number(payFeesDenominationForm.values.thousand_total) != 0 ? Number(payFeesDenominationForm.values.thousand_total) : null,
                                    thousand_count: Number(payFeesDenominationForm.values.thousand_count) != 0 ? Number(payFeesDenominationForm.values.thousand_count) : null,
                                    fivehunderd_total: Number(fivehunderd_total) != 0 ? Number(fivehunderd_total) : null,
                                    fivehunderd_count: Number(payFeesDenominationForm.values.fivehunderd_count) != 0 ? Number(payFeesDenominationForm.values.fivehunderd_count) : null,
                                    twoHundred_total: Number(twoHundred_total) != 0 ? Number(twoHundred_total) : null,
                                    twoHundred_count: Number(payFeesDenominationForm.values.twoHundred_count) != 0 ? Number(payFeesDenominationForm.values.twoHundred_count) : null,
                                    hundred_total: Number(hundred_total) != 0 ? Number(hundred_total) : null,
                                    hundred_count: Number(payFeesDenominationForm.values.hundred_count) != 0 ? Number(payFeesDenominationForm.values.hundred_count) : null,
                                    fifty_total: Number(fifty_total) != 0 ? Number(fifty_total) : null,
                                    fifty_count: Number(payFeesDenominationForm.values.fifty_count) != 0 ? Number(payFeesDenominationForm.values.fifty_count) : null,
                                    twenty_total: Number(twenty_total) != 0 ? Number(twenty_total) : null,
                                    twenty_count: Number(payFeesDenominationForm.values.twenty_count) != 0 ? Number(payFeesDenominationForm.values.twenty_count) : null,
                                    ten_total: Number(ten_total) != 0 ? Number(ten_total) : null,
                                    ten_count: Number(payFeesDenominationForm.values.ten_count) != 0 ? Number(payFeesDenominationForm.values.ten_count) : null,
                                    five_total: Number(five_total) != 0 ? Number(five_total) : null,
                                    five_count: Number(payFeesDenominationForm.values.five_count) != 0 ? Number(payFeesDenominationForm.values.five_count) : null,
                                    totalAmount: Number(totalFeesAmount) != 0 ? Number(totalFeesAmount) : Number(payFineFeesForm.values.amount),

                                    /// Cheque Payment ///
                                    chequeNumber: payFeesDenominationForm.values.chequeNumber != '' ? payFeesDenominationForm.values.chequeNumber : null,
                                    chequeDate: paymentTypeId == 2 ? payFeesDenominationForm.values.chequeDate : null,
                                    chequeAmount: Number(payFeesDenominationForm.values.chequeAmount) != 0 ? Number(payFeesDenominationForm.values.chequeAmount) : null,
                                    chequeBankName: payFeesDenominationForm.values.chequeBankName != '' ? payFeesDenominationForm.values.chequeBankName : null,

                                    /// Neft Payment ///
                                    neftRefNumber: payFeesDenominationForm.values.neftRefNumber != '' ? payFeesDenominationForm.values.neftRefNumber : null,
                                    neftTranDate: paymentTypeId == 3 ? payFeesDenominationForm.values.neftTranDate : null,
                                    neftAmount: Number(payFeesDenominationForm.values.neftAmount) != 0 ? Number(payFeesDenominationForm.values.neftAmount) : null,
                                    neftBankName: payFeesDenominationForm.values.neftBankName != '' ? payFeesDenominationForm.values.neftBankName : null,

                                    /// Card Payment ///
                                    cardNumber: payFeesDenominationForm.values.cardNumber != '' ? payFeesDenominationForm.values.chequeNumber : null,
                                    cardDate: paymentTypeId == 4 ? payFeesDenominationForm.values.cardDate : null,
                                    cardAmount: Number(payFeesDenominationForm.values.cardAmount) != 0 ? Number(payFeesDenominationForm.values.cardAmount) : null,
                                    cardBankName: payFeesDenominationForm.values.cardBankName != '' ? payFeesDenominationForm.values.cardBankName : null,

                                    narration: payFeesDenominationForm.values.narration != '' ? payFeesDenominationForm.values.narration : null,
                                    balCashAmount: Number(balCashAmount) != 0 ? Number(balCashAmount) : null,

                                    /// Fine Fees ///
                                    courseId: studentProfileData[0].courseId != '' ? studentProfileData[0].courseId : null,
                                    batchMasterId: studentProfileData[0].batchMasterId != '' ? studentProfileData[0].batchMasterId : null,
                                    termDetailsId: studentProfileData[0].termDetailsId != '' ? studentProfileData[0].termDetailsId : null,
                                    fineTypeId: data.data.fineFeeTypeId != '' ? data.data.fineFeeTypeId : null,
                                    amount: Number(payFineFeesForm.values.amount) != 0 ? Number(payFineFeesForm.values.amount) : null,

                                    licenseKey: getLicenseKey,
                                    staffDetailsId: userAccountId
                                }
                                payFeesSubmit1(postData)
                                // showLoader(false)
                                // setAlertStatus({ message: data.message, type: "success" });
                                // setIsOpen(true);
                            }
                            else if (data.success == false) {
                                setFineTypeId(data.data.fineFeeTypeId)

                                let postData = {
                                    studentDetailsId: studentDetailsId,
                                    receiptDate: payFeesDenominationForm.values.receiptDate != '' ? payFeesDenominationForm.values.receiptDate : null,
                                    studentFeesCollectionDetails: studentFeesCollectionDetails,
                                    paymentTypeId: paymentTypeId,
                                    ledgerRefId: null,
                                    twoThousand_total: Number(payFeesDenominationForm.values.twoThousand_total) != 0 ? Number(payFeesDenominationForm.values.twoThousand_total) : null,
                                    twoThousand_count: Number(payFeesDenominationForm.values.twoThousand_count) != 0 ? Number(payFeesDenominationForm.values.twoThousand_count) : null,
                                    thousand_total: Number(payFeesDenominationForm.values.thousand_total) != 0 ? Number(payFeesDenominationForm.values.thousand_total) : null,
                                    thousand_count: Number(payFeesDenominationForm.values.thousand_count) != 0 ? Number(payFeesDenominationForm.values.thousand_count) : null,
                                    fivehunderd_total: Number(fivehunderd_total) != 0 ? Number(fivehunderd_total) : null,
                                    fivehunderd_count: Number(payFeesDenominationForm.values.fivehunderd_count) != 0 ? Number(payFeesDenominationForm.values.fivehunderd_count) : null,
                                    twoHundred_total: Number(twoHundred_total) != 0 ? Number(twoHundred_total) : null,
                                    twoHundred_count: Number(payFeesDenominationForm.values.twoHundred_count) != 0 ? Number(payFeesDenominationForm.values.twoHundred_count) : null,
                                    hundred_total: Number(hundred_total) != 0 ? Number(hundred_total) : null,
                                    hundred_count: Number(payFeesDenominationForm.values.hundred_count) != 0 ? Number(payFeesDenominationForm.values.hundred_count) : null,
                                    fifty_total: Number(fifty_total) != 0 ? Number(fifty_total) : null,
                                    fifty_count: Number(payFeesDenominationForm.values.fifty_count) != 0 ? Number(payFeesDenominationForm.values.fifty_count) : null,
                                    twenty_total: Number(twenty_total) != 0 ? Number(twenty_total) : null,
                                    twenty_count: Number(payFeesDenominationForm.values.twenty_count) != 0 ? Number(payFeesDenominationForm.values.twenty_count) : null,
                                    ten_total: Number(ten_total) != 0 ? Number(ten_total) : null,
                                    ten_count: Number(payFeesDenominationForm.values.ten_count) != 0 ? Number(payFeesDenominationForm.values.ten_count) : null,
                                    five_total: Number(five_total) != 0 ? Number(five_total) : null,
                                    five_count: Number(payFeesDenominationForm.values.five_count) != 0 ? Number(payFeesDenominationForm.values.five_count) : null,
                                    totalAmount: Number(totalFeesAmount) != 0 ? Number(totalFeesAmount) : Number(payFineFeesForm.values.amount),

                                    /// Cheque Payment ///
                                    chequeNumber: payFeesDenominationForm.values.chequeNumber != '' ? payFeesDenominationForm.values.chequeNumber : null,
                                    chequeDate: paymentTypeId == 2 ? payFeesDenominationForm.values.chequeDate : null,
                                    chequeAmount: Number(payFeesDenominationForm.values.chequeAmount) != 0 ? Number(payFeesDenominationForm.values.chequeAmount) : null,
                                    chequeBankName: payFeesDenominationForm.values.chequeBankName != '' ? payFeesDenominationForm.values.chequeBankName : null,

                                    /// Neft Payment ///
                                    neftRefNumber: payFeesDenominationForm.values.neftRefNumber != '' ? payFeesDenominationForm.values.neftRefNumber : null,
                                    neftTranDate: paymentTypeId == 3 ? payFeesDenominationForm.values.neftTranDate : null,
                                    neftAmount: Number(payFeesDenominationForm.values.neftAmount) != 0 ? Number(payFeesDenominationForm.values.neftAmount) : null,
                                    neftBankName: payFeesDenominationForm.values.neftBankName != '' ? payFeesDenominationForm.values.neftBankName : null,

                                    /// Card Payment ///
                                    cardNumber: payFeesDenominationForm.values.cardNumber != '' ? payFeesDenominationForm.values.chequeNumber : null,
                                    cardDate: paymentTypeId == 4 ? payFeesDenominationForm.values.cardDate : null,
                                    cardAmount: Number(payFeesDenominationForm.values.cardAmount) != 0 ? Number(payFeesDenominationForm.values.cardAmount) : null,
                                    cardBankName: payFeesDenominationForm.values.cardBankName != '' ? payFeesDenominationForm.values.cardBankName : null,

                                    narration: payFeesDenominationForm.values.narration != '' ? payFeesDenominationForm.values.narration : null,
                                    balCashAmount: Number(balCashAmount) != 0 ? Number(balCashAmount) : null,

                                    /// Fine Fees ///
                                    courseId: studentProfileData[0].courseId != '' ? studentProfileData[0].courseId : null,
                                    batchMasterId: studentProfileData[0].batchMasterId != '' ? studentProfileData[0].batchMasterId : null,
                                    termDetailsId: studentProfileData[0].termDetailsId != '' ? studentProfileData[0].termDetailsId : null,
                                    fineTypeId: data.data.fineFeeTypeId != '' ? data.data.fineFeeTypeId : null,
                                    amount: Number(payFineFeesForm.values.amount) != 0 ? Number(payFineFeesForm.values.amount) : null,

                                    licenseKey: getLicenseKey,
                                    staffDetailsId: userAccountId
                                }
                                payFeesSubmit1(postData)
                                // setAlertStatus({ message: data.message, type: "error" });
                                // setIsOpen(true);
                            }
                            else {
                                showLoader(false)
                                let errorCode = response.data.error[0].error_code;
                                let errorDescription = response.data.error[0].error_description;
                                setAlertStatus({ message: errorDescription, type: "error" });
                                setIsOpen(true);
                            }
                        }
                        , (error) => {
                            showLoader(false)
                            setAlertStatus({ message: error, type: "error" });
                            setIsOpen(true);
                        }
                    )
                } else if (fineFeeTypeForm.isValid == false) {
                    showLoader(false)
                    // setAlertStatus({ message: 'Please fill all the details!', type: "error" });
                    // setIsOpen(true);
                }
            }
        }
    }

    function selectTermFee() {
        getTermFeesForTutionFees(studentDetailsId, 1);
        getTermFeesForTransportFees(studentDetailsId, 4);
    }

    function addStudentAdvanceFeeForTutionValue(cell: any, feeMasterId: number, feeTypeId: number, standardDetailsId: any, feeAmount: number) {

        const newData: any = [];

        termFeesForTutionFeesData.forEach((item: any) => {

            if (item.termDetailsId == cell) {
                item.isChecked = true
            }
            newData.push(item)
            setFilteredDataToRefresh(newData)
        });

        if (studentAdvanceFee.length == 0) {

            var obj
            obj = {
                termDetailsId: cell,
                standardDetailsId: standardDetailsId,
                fmOrTrasportDetailsId: feeMasterId,
                feeTypeId: feeTypeId,
                amount: feeAmount,
            };
            studentAdvanceFee.push(obj);
        }
        else {
            let selectedItem: any
            studentAdvanceFee.forEach((item: any, index: number) => {
                if (item['termDetailsId'] == cell) {
                    selectedItem = item
                }
            });
            if (selectedItem != undefined) {
                const newData: any = [];

                termFeesForTutionFeesData.forEach((item: any) => {

                    if (item.termDetailsId == cell) {
                        item.isChecked = false
                    }
                    newData.push(item)
                    setFilteredDataToRefresh(newData)
                });

                studentAdvanceFee.splice(studentAdvanceFee.indexOf(selectedItem), 1);
                selectedItem['termDetailsId'] = cell
            }
            else {
                var obj
                obj = {
                    termDetailsId: cell,
                    standardDetailsId: standardDetailsId,
                    fmOrTrasportDetailsId: feeMasterId,
                    feeTypeId: feeTypeId,
                    amount: feeAmount,
                };
                studentAdvanceFee.push(obj);
            }
        }
    }

    function addStudentAdvanceFeeForTrasportValue(cell: number, fmOrTrasportDetailsId: any, feeTypeId: number, standardDetailsId: any, feeAmount: number) {

        const newData: any = [];

        termFeesForTransportFeesData.forEach((item: any) => {

            if (item.termDetailsId == cell) {
                item.fmOrTrasportDetailsId = fmOrTrasportDetailsId
                item.isChecked = true
            }
            newData.push(item)
            setFilteredDataToRefresh(newData)
        });

        if (studentAdvanceFee.length == 0) {

            var obj
            obj = {
                termDetailsId: cell,
                fmOrTrasportDetailsId: fmOrTrasportDetailsId,
                standardDetailsId: standardDetailsId,
                feeTypeId: feeTypeId,
                amount: feeAmount,
            };
            studentAdvanceFee.push(obj);
        }
        else {
            let selectedItem: any
            studentAdvanceFee.forEach((item: any, index: number) => {
                if (item['termDetailsId'] == cell) {
                    selectedItem = item
                }
            });
            if (selectedItem != undefined) {
                studentAdvanceFee.splice(studentAdvanceFee.indexOf(selectedItem), 1);

                const newData: any = [];

                termFeesForTransportFeesData.forEach((item: any) => {

                    if (item.termDetailsId == cell) {
                        item.fmOrTrasportDetailsId = fmOrTrasportDetailsId
                        item.isChecked = false
                    }
                    newData.push(item)
                    setFilteredDataToRefresh(newData)
                });

                selectedItem['termDetailsId'] = cell
            }
            else {
                var obj
                obj = {
                    termDetailsId: cell,
                    fmOrTrasportDetailsId: fmOrTrasportDetailsId,
                    standardDetailsId: standardDetailsId,
                    feeTypeId: feeTypeId,
                    amount: feeAmount,
                };
                studentAdvanceFee.push(obj);
            }
        }
    }

    function setCollectAdvanceFeeDetails() {
        return ({
            studentAdvanceFee: studentAdvanceFee,
            studentDetailsId: studentDetailsId,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId
        })
    }

    function payAdvanceFeesSubmit() {
        showLoader(true)
        if (studentAdvanceFee.length > 0) {
            let payAdvanceFeesPostData = setCollectAdvanceFeeDetails();
            addStudentAdvanceFee(payAdvanceFeesPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        getStudentFeeList(userTypeId, studentDetailsId);
                        selectTermFee()
                        setStudentAdvanceFee([])
                        getStudentTotalFeeCollectionList(studentDetailsId);
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        showLoader(false)
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (studentAdvanceFee.length == 0) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    const multiply500 = (e: any) => {
        const count500 = e.target.value;
        setFivehunderd_total(count500 * 500)
        multiply(count500 * 500, twoHundred_total, hundred_total, fifty_total, twenty_total, ten_total, five_total)
        balanceAmountCal(count500 * 500, twoHundred_total, hundred_total, fifty_total, twenty_total, ten_total, five_total)
    }

    const multiply200 = (e: any) => {
        const count200 = e.target.value;
        setTwoHundred_total(count200 * 200);
        multiply(fivehunderd_total, count200 * 200, hundred_total, fifty_total, twenty_total, ten_total, five_total)
        balanceAmountCal(fivehunderd_total, count200 * 200, hundred_total, fifty_total, twenty_total, ten_total, five_total)

    }

    const multiply100 = (e: any) => {
        const count100 = e.target.value;
        setHundred_total(count100 * 100);
        multiply(fivehunderd_total, twoHundred_total, count100 * 100, fifty_total, twenty_total, ten_total, five_total)
        balanceAmountCal(fivehunderd_total, twoHundred_total, count100 * 100, fifty_total, twenty_total, ten_total, five_total)

    }

    const multiply50 = (e: any) => {
        const count50 = e.target.value;
        setFifty_total(count50 * 50);
        multiply(fivehunderd_total, twoHundred_total, hundred_total, count50 * 50, twenty_total, ten_total, five_total)
        balanceAmountCal(fivehunderd_total, twoHundred_total, hundred_total, count50 * 50, twenty_total, ten_total, five_total)

    }

    const multiply20 = (e: any) => {
        const count20 = e.target.value;
        setTwenty_total(count20 * 20);
        multiply(fivehunderd_total, twoHundred_total, hundred_total, fifty_total, count20 * 20, ten_total, five_total)
        balanceAmountCal(fivehunderd_total, twoHundred_total, hundred_total, fifty_total, count20 * 20, ten_total, five_total)

    }

    const multiply10 = (e: any) => {
        const count10 = e.target.value;
        setTen_total(count10 * 10);
        multiply(fivehunderd_total, twoHundred_total, hundred_total, fifty_total, twenty_total, count10 * 10, five_total)
        balanceAmountCal(fivehunderd_total, twoHundred_total, hundred_total, fifty_total, twenty_total, count10 * 10, five_total)
    }

    const multiply5 = (e: any) => {
        const count5 = e.target.value;
        setFive_total(count5 * 5);
        multiply(fivehunderd_total, twoHundred_total, hundred_total, fifty_total, twenty_total, ten_total, count5 * 5)
        balanceAmountCal(fivehunderd_total, twoHundred_total, hundred_total, fifty_total, twenty_total, ten_total, count5 * 5)
    }

    const multiply = (fivehunderd_total: any, twoHundred_total: any, hundred_total: any, fifty_total: any, twenty_total: any, ten_total: any, five_total: any) => {
        setTotalAmount(Number(fivehunderd_total) + Number(twoHundred_total) + Number(hundred_total) + Number(fifty_total) + Number(twenty_total) + Number(ten_total) + Number(five_total));
    }

    const balanceAmountCal = (fivehunderd_total: any, twoHundred_total: any, hundred_total: any, fifty_total: any, twenty_total: any, ten_total: any, five_total: any) => {
        setBalCashAmount(Number(fivehunderd_total) + Number(twoHundred_total) + Number(hundred_total) + Number(fifty_total) + Number(twenty_total) + Number(ten_total) + Number(five_total) - Number(totalFeesAmount) - Number(payFineFeesForm.values.amount));
    }

    const balanceAmountCal1 = (fivehunderd_total: any, twoHundred_total: any, hundred_total: any, fifty_total: any, twenty_total: any, ten_total: any, five_total: any, totalFeesAmount: any) => {
        setBalCashAmount(Number(fivehunderd_total) + Number(twoHundred_total) + Number(hundred_total) + Number(fifty_total) + Number(twenty_total) + Number(ten_total) + Number(five_total) - Number(totalFeesAmount) - Number(payFineFeesForm.values.amount));
    }

    function formReset() {
        payFeesDenominationForm.resetForm();
        setPaymentTypeId(0)
        setFivehunderd_total("")
        setTwoHundred_total("")
        setHundred_total("")
        setFifty_total("")
        setTwenty_total("")
        setTen_total("")
        setFive_total("")
        setTotalAmount("")
        setBalCashAmount("")
    }

    function getFeeTypeList() {
        getFeeType(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.feeType;
                    if (data != undefined) {
                        setFeesTypeData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                } else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setFeesTypeData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getFineFeeTypeList() {
        getFineFeeType(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.fineFeeType;
                    if (data != undefined) {
                        setFineTypeData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                } else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setFineTypeData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getExtraFeeTypeList() {
        getExtraFeeType(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.extraFeeType;
                    if (data != undefined) {
                        setExtraFeeTypeData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                } else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setExtraFeeTypeData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStudentFineFee(studentDetailsId: number) {
        getStudentFineFeeList(studentDetailsId, 0,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentFineFeeList;
                    if (data != undefined) {
                        setStudentFineFeeListData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                } else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    const selectFeeType = (e: any) => {
        setExtraFeeTypeId('')
        let feeTypeId = e
        setFeeTypeId(feeTypeId)
    }

    function setExtraFeeDetails() {
        return ({
            feeTypeId: feeTypeId?.value,
            extraFeeTypeId: extraFeeTypeId?.value != "" ? extraFeeTypeId?.value : null,
            amount: addExtraFeesForm.values.amount,
            studentDetailsId: studentDetailsId,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId
        })
    }

    function addExtraFeeSubmit() {
        showLoader(true)
        if (addExtraFeesForm.isValid) {
            let extraFeePostData = setExtraFeeDetails();
            addExtraFeeDetails(extraFeePostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        getStudentFeeList(userTypeId, studentDetailsId);
                        getStudentTotalFeeCollectionList(studentDetailsId);
                        addExtraFeesForm.resetForm()
                        setFeeTypeId("")
                        setExtraFeeTypeId("")
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        showLoader(false)
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (addExtraFeesForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function selectExtraFee() {
        getExtraFeeTypeList()
        getFeeTypeList();
    }

    function selectFineFeeType(fineData: any) {
        let selectedFineFee = fineData;
        setFineFeeReport(true);
        getStudentFineFeeListByFineTypeId(studentDetailsId, selectedFineFee.fineTypeId)
    }

    function getStudentFineFeeListByFineTypeId(studentDetailsId: number, fineTypeId: number) {
        getStudentFineFeeList(studentDetailsId, fineTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentFineFeeList;
                    if (data != undefined) {
                        setStudentFineFeeByFineTypeIdData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function setFineFeeTypeDetails() {
        return {
            fineType: fineFeeTypeForm.values.fineType,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId,
        }
    }

    function addFineFeeTypeSubmit() {
        showLoader(true)
        if (fineFeeTypeForm.isValid) {
            let fineFeePostData = setFineFeeTypeDetails();
            addFineFeeType(fineFeePostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        showLoader(false)
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (fineFeeTypeForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function closeAndReset() {
        addExtraFeesForm.resetForm()
        setStudentAdvanceFee([])
        setFeeTypeId("")
        setExtraFeeTypeId("")

        const newData: any = [];

        termFeesForTutionFeesData.forEach((item: any) => {
            item.isChecked = false
            newData.push(item)
            setFilteredDataToRefresh(newData)
        });

        termFeesForTransportFeesData.forEach((item: any) => {
            item.isChecked = false
            newData.push(item)
            setFilteredDataToRefresh(newData)
        });
    }

    const [isVisible, setIsVisible] = useState<boolean>(true);

    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };

    return (
        <PageWrapper>
            <SubHeader>
                <SubHeaderLeft>
                    <Button color='info' isLink icon='ArrowBack' onClick={() => { setShowPayFees(false); refreshAfterFeeCollection('') }}>
                        Back to List
                    </Button>
                    <SubheaderSeparator />
                    {studentProfileData.map((student: any, index: any) => (
                        <div key={index}>
                            <img style={{ borderRadius: '50%' }}
                                src={student.profilePath != null ? student.profilePath : profilePic(student.genderId)}
                                height='40' width='40' onError={(e: any) => pictNotLoading(e, student.genderId)}
                            />
                            <span>
                                <strong className='ms-3'>{student.studentName} {''}</strong>
                            </span>
                            <span className='border border-success border-2 text-success fw-bold px-3 py-2 rounded'>
                                {student.admissionNo}
                            </span>
                            <span>
                                <strong className='ms-3 text-primary'>{student.semesterNumber}</strong>
                            </span>
                        </div>))}
                </SubHeaderLeft>
                <SubHeaderRight>
                    {showPayFees == "payFees" ?
                        <CardActions>
                            <Button color='storybook' icon='Add' isLight onClick={() => { setAddAdvanceFeeOffCanvas(true); selectTermFee() }}>
                                Add Advance Fee
                            </Button>&nbsp;
                            <Button color='info'
                                isOutline
                                icon='Add' onClick={() => { setAddExtraFeeOffCanvas(true); selectExtraFee() }} >
                                Add Extra Fee
                            </Button>
                        </CardActions> : <div className='col-lg-3 col-md-6'></div>}
                </SubHeaderRight>
            </SubHeader>

            <Page container='fluid'>
                <Card tag="form" noValidate onSubmit={payFeesDenominationForm.handleSubmit}>
                    <CardBody >
                        {showPayFees == "payFees" ? <>
                            {!hideLoader ?
                                <>
                                    {studentFeeDetails != '' ?
                                        <>
                                            {studentFeeDetails.map((feeType: any) => (
                                                <div key={feeType.feeTypeId}>
                                                    <table className='table table-modern table-hover text-center' >
                                                        <thead>
                                                            <tr className='table-primary'>

                                                                <th scope='col' style={{ width: '20%' }}>{feeType.feeType}</th>
                                                                <th scope='col' style={{ width: '15%' }}>TOTAL FEE</th>
                                                                <th scope='col' style={{ width: '15%' }}>TOTAL RECEIVED</th>
                                                                <th scope='col' style={{ width: '10%' }}>CONCESSION</th>
                                                                <th scope='col' style={{ width: '15%' }}>BALANCE</th>
                                                                <th scope='col' style={{ width: '15%' }}>AMOUNT</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody className='align-middle'>
                                                            {feeType.studentFees.map((feesData: any) => (
                                                                <tr key={feesData.studentFeeDetailsId}>
                                                                    <td className='fs-6 text-center'>
                                                                        {feesData.termName}
                                                                        {feeType.feeTypeId === 4 ? <p className="text-info">
                                                                            ({feesData.areaName})</p> : feeType.feeTypeId === 14 ? <p className="text-info">({feesData.extraFeeType})</p> : ''}
                                                                    </td>
                                                                    <td className='fs-6'>{feesData.totalFees}</td>
                                                                    <td className='fs-6 text-success'>{feesData.totalFeesReceived}</td>
                                                                    <td className='fs-6 text-info'>{feesData.concession}</td>
                                                                    <td className='fs-6 text-danger'>{feesData.balanceToBePaid}</td>
                                                                    {feesData.balanceToBePaid == 0 ? <td className='fs-6 fw-bold text-success'>PAID</td> :
                                                                        <td>
                                                                            <FormGroup id='amount'>
                                                                                <Input className="text-center w-100" placeholder='0' value={feesData.amount} onInput={(e: any) => updateFeesReceivedValue(feesData.studentFeeDetailsId, e.target.value)}
                                                                                    onKeyDown={(e: any) => onlyAllowNumber(e)} />
                                                                            </FormGroup>

                                                                        </td>}
                                                                </tr>))}
                                                        </tbody>
                                                    </table>
                                                    <br />
                                                </div>
                                            ))}
                                        </>
                                        : <p className='text-center'>{noDataMsgForStudentFeeDetails}</p>}
                                </>
                                : <TableLoader />}
                        </> : showPayFees == "payFineFees" ?
                            <>
                                <div className='row h-100 pb-3 '>
                                    <div className='col-lg-6 col-md-6'>
                                        <Card stretch>
                                            <CardHeader>
                                                <CardLabel icon='Report'>
                                                    <CardTitle tag='div' className='h5'>
                                                        Fine Fee List
                                                    </CardTitle>
                                                </CardLabel>
                                            </CardHeader>
                                            <CardBody className='text-center'>
                                                {studentFineFeeListData != '' ?
                                                    <div className="table-responsive">
                                                        <table className='table table-modern table-hover text-nowrap'>
                                                            <thead className='table-primary'>
                                                                <tr>
                                                                    <th className="text-center">
                                                                        Fee Type
                                                                    </th>
                                                                    <th className="text-center">
                                                                        Fine Amount
                                                                    </th>
                                                                    <th className="text-center">
                                                                        Report
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {studentFineFeeListData.map((fineData: any) => (
                                                                    <tr className='text-center' key={fineData.studentFineFeeCollectionId}>
                                                                        <td>{fineData.fineType}</td>
                                                                        <td>{fineData.totalFineAmount}</td>
                                                                        <td>
                                                                            <Button isOutline={!darkModeStatus}
                                                                                color='dark' isLight size='sm'
                                                                                icon='Report' onClick={() => selectFineFeeType(fineData)}>Report
                                                                            </Button>
                                                                        </td>
                                                                    </tr>
                                                                ))}

                                                            </tbody>
                                                        </table>
                                                    </div> :
                                                    <Alert color='danger' isLight icon='Report' className='mt-3'>
                                                        TILL NOW FINE FEE NOT COLLECTED
                                                    </Alert>}
                                            </CardBody>
                                        </Card>
                                    </div>
                                    <div className='col-lg-6 col-md-6'>
                                        <Card stretch>
                                            <CardHeader>
                                                <CardLabel icon='Style'>
                                                    <CardTitle tag='div' className='h5'>
                                                        Collect Fine Fee
                                                    </CardTitle>
                                                </CardLabel>
                                            </CardHeader>
                                            <CardBody>
                                                <div className='row g-4'>
                                                    {isVisible ? <div className='col-10'>
                                                        <FormGroup id='fineTypeId' label='Fine Type' isFloating >
                                                            <SearchableSelect isFloating
                                                                placeholder='Fine Type'
                                                                ariaLabel='Select Fine Type'
                                                                onChange={(e: any) => { setFineTypeId(e) }}
                                                                value={fineTypeId}
                                                                onBlur={payFineFeesForm.handleBlur}
                                                                isValid={payFineFeesForm.isValid}
                                                                isTouched={payFineFeesForm.touched.fineTypeId}
                                                                invalidFeedback={payFineFeesForm.errors.fineTypeId}
                                                                list={fineTypeData.map((data: any) => (
                                                                    { value: data.fineTypeId, label: data.fineType }
                                                                ))} />
                                                        </FormGroup>
                                                    </div> :
                                                        <div className='col-10'>
                                                            <FormGroup id='fineType' label='Fine Type' isFloating>
                                                                <Input type='text'
                                                                    onChange={fineFeeTypeForm.handleChange}
                                                                    value={fineFeeTypeForm.values.fineType} />
                                                            </FormGroup>
                                                        </div>}
                                                    <div className='col-1 d-flex align-items-center'>
                                                        <Icon icon='AddCircleOutline' className=' text-info h2 ' onClick={toggleVisibility} />
                                                    </div>
                                                    <div className='col-10'>
                                                        <FormGroup id='amount' label='Enter Amount' isFloating>
                                                            <Input placeholder='Enter Amount'
                                                                onChange={payFineFeesForm.handleChange}
                                                                value={payFineFeesForm.values.amount}
                                                                onBlur={payFineFeesForm.handleBlur}
                                                                isValid={payFineFeesForm.isValid}
                                                                isTouched={payFineFeesForm.touched.amount}
                                                                invalidFeedback={payFineFeesForm.errors.amount}
                                                                onKeyDown={(e: any) => onlyAllowNumber(e)} />
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </div>
                                </div>
                            </>
                            : null}
                    </CardBody>
                    {showPayFees == "payFees" ?
                        <CardBody>

                            {studentTotalFeeCollectionData.map((studentTotalFee: any) => (
                                <div className='row g-4' key={studentTotalFee.studentDetailsId}>
                                    <div className='col-md-3'>
                                        <Card className={`bg-l${darkModeStatus ? 'o25' : '25'
                                            }-warning bg-l${darkModeStatus ? 'o50' : '10'
                                            }-warning-hover transition-base rounded-2 mb-4`}
                                            shadow='sm'>
                                            <CardBody>
                                                <div className='d-flex align-items-center pb-3'>
                                                    <div className='flex-shrink-0'>
                                                        <Icon
                                                            icon='LocalOffer'
                                                            size='4x'
                                                            color='warning'
                                                        />
                                                    </div>
                                                    <div className='flex-grow-1 ms-3'>
                                                        <div className='fw-bold fs-3 text-warning mb-0'>
                                                            ₹{studentTotalFee.total_grossPayable}
                                                        </div>
                                                        <div className='text-muted'>
                                                            <b> Total fees</b>
                                                        </div>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </div>
                                    <div className='col-md-3'>
                                        <Card className={`bg-l${darkModeStatus ? 'o25' : '25'
                                            }-success bg-l${darkModeStatus ? 'o50' : '10'
                                            }-success-hover transition-base rounded-2 mb-4`}
                                            shadow='sm'>
                                            <CardBody>
                                                <div className='d-flex align-items-center pb-3'>
                                                    <div className='flex-shrink-0'>
                                                        <Icon
                                                            icon='ConfirmationNumber'
                                                            size='4x'
                                                            color='success'
                                                        />
                                                    </div>
                                                    <div className='flex-grow-1 ms-3'>
                                                        <div className='fw-bold fs-3 text-success mb-0'>
                                                            ₹ {studentTotalFee.total_amountPaid + studentTotalFee.total_concession}
                                                        </div>
                                                        <div className='text-muted'>
                                                            <b> Paid amount</b>
                                                        </div>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </div>
                                    <div className='col-md-3'>
                                        <Card className={`bg-l${darkModeStatus ? 'o25' : '25'
                                            }-info bg-l${darkModeStatus ? 'o50' : '10'
                                            }-info-hover transition-base rounded-2 mb-4`}
                                            shadow='sm'>
                                            <CardBody>
                                                <div className='d-flex align-items-center pb-3'>
                                                    <div className='flex-shrink-0'>
                                                        <Icon
                                                            icon='PointOfSale'
                                                            size='4x'
                                                            color='info'
                                                        />
                                                    </div>
                                                    <div className='flex-grow-1 ms-3'>
                                                        <div className='fw-bold fs-3 text-info mb-0'>
                                                            ₹ {totalFeesAmount}
                                                        </div>
                                                        <div className='text-muted'>
                                                            <b> Fees collected</b>
                                                        </div>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </div>
                                    <div className='col-md-3'>
                                        <Card className={`bg-l${darkModeStatus ? 'o25' : '25'
                                            }-danger bg-l${darkModeStatus ? 'o50' : '10'
                                            }-danger-hover transition-base rounded-2 mb-4`}
                                            shadow='sm'>
                                            <CardBody>
                                                <div className='d-flex align-items-center pb-3'>
                                                    <div className='flex-shrink-0'>
                                                        <Icon
                                                            icon='Report'
                                                            size='4x'
                                                            color='danger'
                                                        />
                                                    </div>
                                                    <div className='flex-grow-1 ms-3'>
                                                        <div className='fw-bold fs-3 text-danger mb-0'>
                                                            ₹ {finalBalanceAmount - totalFeesAmount}
                                                        </div>
                                                        <div className='text-muted'>
                                                            <b>Balance to be paid</b>
                                                        </div>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </div>
                                </div>
                            ))}

                        </CardBody>
                        : null}
                    <CardBody>
                        <div className='row h-100 pb-3'>
                            <div className='col-lg-4 col-md-6'>
                                <Card stretch>
                                    <CardHeader>
                                        <CardLabel icon='List'>
                                            <CardTitle tag='div' className='h5'>
                                                Payment Denomination
                                            </CardTitle>
                                        </CardLabel>
                                    </CardHeader>
                                    <CardBody>
                                        <div className='row g-3'>
                                            <div className='col-12'>
                                                <Button
                                                    icon='PendingActions'
                                                    color='info'
                                                    className='w-100 p-3'
                                                    isLight={TABS.CASH_PAYMENTS !== activeTab}
                                                    onClick={() => { setActiveTab(TABS.CASH_PAYMENTS); formReset(); setPaymentTypeId(1); }}>
                                                    {TABS.CASH_PAYMENTS}
                                                </Button>
                                            </div>
                                            <div className='col-12'>
                                                <Button
                                                    icon='LocalPolice'
                                                    color='info'
                                                    className='w-100 p-3'
                                                    isLight={TABS.CHEQUE !== activeTab}
                                                    onClick={() => { setActiveTab(TABS.CHEQUE); formReset(); setPaymentTypeId(2); }}>
                                                    {TABS.CHEQUE}
                                                </Button>
                                            </div>
                                            <div className='col-12'>
                                                <Button
                                                    icon='Style'
                                                    color='info'
                                                    className='w-100 p-3'
                                                    isLight={TABS.NEFT_UPI !== activeTab}
                                                    onClick={() => { setActiveTab(TABS.NEFT_UPI); formReset(); setPaymentTypeId(3); }}>
                                                    {TABS.NEFT_UPI}
                                                </Button>
                                            </div>
                                            <div className='col-12'>
                                                <Button
                                                    icon='CreditCard'
                                                    color='info'
                                                    className='w-100 p-3'
                                                    isLight={TABS.CARD_PAYMENT !== activeTab}
                                                    onClick={() => { setActiveTab(TABS.CARD_PAYMENT); formReset(); setPaymentTypeId(4); }}>
                                                    {TABS.CARD_PAYMENT}
                                                </Button>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                            <div className='col-lg-8 col-md-6'>
                                {TABS.CASH_PAYMENTS === activeTab && (
                                    <Card stretch>
                                        <CardHeader>
                                            <CardLabel icon='PendingActions'>
                                                <CardTitle tag='div' className='h5'>
                                                    Cash Denomination
                                                </CardTitle>
                                            </CardLabel>
                                        </CardHeader>
                                        <CardBody>
                                            <div className='row'>
                                                <div className="d-grid gap-4">
                                                    <div className="row justify-content-center">
                                                        <div className="col-lg-4 align-self-center h6">
                                                        </div>
                                                        <div className="col-lg-4 justify-content-center">
                                                            Count
                                                        </div>

                                                        <div className="col-lg-4 justify-content-center">
                                                            Amount
                                                        </div>
                                                    </div>
                                                    <div className="row justify-content-center">
                                                        <div className="col-lg-1 align-self-center h6">
                                                        </div>
                                                        <div className="col-lg-3 mt-2 align-self-center h6">
                                                            <p>500 X</p>
                                                        </div>
                                                        <div className="col-lg-4 justify-content-center">
                                                            <FormGroup id="fivehunderd_count" label="">
                                                                <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.fivehunderd_count} onInput={multiply500} onKeyDown={(e: any) => onlyAllowNumber(e)} />
                                                            </FormGroup>
                                                        </div>

                                                        <div className="col-lg-4 justify-content-center">
                                                            <FormGroup id="fivehunderd_total" label="">
                                                                <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={fivehunderd_total} disabled />
                                                            </FormGroup>
                                                        </div>
                                                    </div>
                                                    <div className="row justify-content-center">
                                                        <div className="col-lg-1 align-self-center h6">
                                                        </div>
                                                        <div className="col-lg-3 mt-2 align-self-center h6">
                                                            <p>200 X</p>
                                                        </div>
                                                        <div className="col-lg-4 justify-content-center">
                                                            <FormGroup id="twoHundred_total" label="">
                                                                <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.twoHundred_total} onInput={multiply200} onKeyDown={(e: any) => onlyAllowNumber(e)} />
                                                            </FormGroup>
                                                        </div>

                                                        <div className="col-lg-4 justify-content-center">
                                                            <FormGroup id="twoHundred_total" label="">
                                                                <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={twoHundred_total} disabled />
                                                            </FormGroup>
                                                        </div>
                                                    </div>
                                                    <div className="row justify-content-center">
                                                        <div className="col-lg-1 align-self-center h6">
                                                        </div>
                                                        <div className="col-lg-3 mt-2 align-self-center h6">
                                                            <p>100 X</p>
                                                        </div>
                                                        <div className="col-lg-4 justify-content-center">
                                                            <FormGroup id="hundred_count" label="">
                                                                <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.hundred_count} onInput={multiply100} onKeyDown={(e: any) => onlyAllowNumber(e)} />
                                                            </FormGroup>
                                                        </div>

                                                        <div className="col-lg-4 justify-content-center">
                                                            <FormGroup id="hundred_total" label="">
                                                                <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={hundred_total} disabled />
                                                            </FormGroup>
                                                        </div>
                                                    </div>
                                                    <div className="row justify-content-center">
                                                        <div className="col-lg-1 align-self-center h6">
                                                        </div>
                                                        <div className="col-lg-3 mt-2 align-self-center h6">
                                                            <p>50 X</p>
                                                        </div>
                                                        <div className="col-lg-4 justify-content-center">
                                                            <FormGroup id="fifty_count" label="">
                                                                <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.fifty_count} onInput={multiply50} onKeyDown={(e: any) => onlyAllowNumber(e)} />
                                                            </FormGroup>
                                                        </div>

                                                        <div className="col-lg-4 justify-content-center">
                                                            <FormGroup id="fifty_total" label="">
                                                                <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={fifty_total} disabled />
                                                            </FormGroup>
                                                        </div>
                                                    </div>
                                                    <div className="row justify-content-center">
                                                        <div className="col-lg-1 align-self-center h6">
                                                        </div>
                                                        <div className="col-lg-3 mt-2 align-self-center h6">
                                                            <p>20 X</p>
                                                        </div>
                                                        <div className="col-lg-4 justify-content-center">
                                                            <FormGroup id="twenty_count" label="">
                                                                <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.twenty_count} onInput={multiply20} onKeyDown={(e: any) => onlyAllowNumber(e)} />
                                                            </FormGroup>
                                                        </div>

                                                        <div className="col-lg-4 justify-content-center">
                                                            <FormGroup id="twenty_total" label="">
                                                                <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={twenty_total} disabled />
                                                            </FormGroup>
                                                        </div>
                                                    </div>
                                                    <div className="row justify-content-center">
                                                        <div className="col-lg-1 align-self-center h6">
                                                        </div>
                                                        <div className="col-lg-3 mt-2 align-self-center h6">
                                                            <p>10 X</p>
                                                        </div>
                                                        <div className="col-lg-4 justify-content-center">
                                                            <FormGroup id="ten_count" label="">
                                                                <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.ten_count} onInput={multiply10} onKeyDown={(e: any) => onlyAllowNumber(e)} />
                                                            </FormGroup>
                                                        </div>

                                                        <div className="col-lg-4 justify-content-center">
                                                            <FormGroup id="ten_total" label="">
                                                                <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={ten_total} disabled />
                                                            </FormGroup>
                                                        </div>
                                                    </div>
                                                    <div className="row justify-content-center">
                                                        <div className="col-lg-1 align-self-center h6">
                                                        </div>
                                                        <div className="col-lg-3 mt-2 align-self-center h6">
                                                            <p>5 X</p>
                                                        </div>
                                                        <div className="col-lg-4 justify-content-center">
                                                            <FormGroup id="five_count" label="">
                                                                <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.five_count} onInput={multiply5} onKeyDown={(e: any) => onlyAllowNumber(e)} />
                                                            </FormGroup>
                                                        </div>

                                                        <div className="col-lg-4 justify-content-center">
                                                            <FormGroup id="five_total" label="">
                                                                <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={five_total} disabled />
                                                            </FormGroup>
                                                        </div>
                                                    </div>
                                                    <div className="row justify-content-center">
                                                        <div className="col-lg-1 align-self-center h6">
                                                        </div>
                                                        <div className="col-lg-3 mt-2 align-self-center h6">
                                                            <p></p>
                                                        </div>
                                                        <div className="col-lg-4 mt-2 justify-content-center">
                                                            <p>Total Amount</p>
                                                        </div>
                                                        <div className="col-lg-4 justify-content-center">
                                                            <FormGroup id="totalAmount" label="">
                                                                <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={totalAmount} disabled />
                                                            </FormGroup>
                                                        </div>
                                                    </div>
                                                    <div className="row justify-content-center">
                                                        <div className="col-lg-1 align-self-center h6">
                                                        </div>
                                                        <div className="col-lg-3 mt-2 align-self-center h6">
                                                            <p></p>
                                                        </div>
                                                        <div className="col-lg-4 mt-2 justify-content-center">
                                                            <p>Balance Cash Amount</p>
                                                        </div>
                                                        <div className="col-lg-4 justify-content-center">
                                                            <FormGroup id="balCashAmount" label="">
                                                                <Input className={balCashAmount < 0 ? 'border border-danger' : ''} type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={balCashAmount} readOnly />
                                                                <>
                                                                    {balCashAmount < 0 ?
                                                                        <div className='ms-2 text-danger'>
                                                                            Amount Not Matching !{' '}<Icon icon='Report' color='danger' className='mx-1' size='lg' />
                                                                        </div> : null}
                                                                </>
                                                            </FormGroup>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                )}
                                {TABS.CHEQUE === activeTab && (
                                    <Card stretch>
                                        <CardHeader>
                                            <CardLabel icon='LocalPolice'>
                                                <CardTitle tag='div' className='h5'>
                                                    Cheque Details
                                                </CardTitle>
                                            </CardLabel>
                                        </CardHeader>
                                        <CardBody>
                                            <div className='row'>
                                                <div className="d-grid gap-4">
                                                    <div className="row justify-content-center">
                                                        <div className="col-lg-3 mt-2 align-self-center h6">
                                                            <p>Cheque Number</p>
                                                        </div>
                                                        <div className="col-lg-5 justify-content-center">
                                                            <FormGroup id="chequeNumber" label="">
                                                                <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.chequeNumber} />
                                                            </FormGroup>
                                                        </div>
                                                    </div>
                                                    <div className="row justify-content-center">
                                                        <div className="col-lg-3 mt-2 align-self-center h6">
                                                            <p>Amount</p>
                                                        </div>

                                                        <div className="col-lg-5 justify-content-center">
                                                            <FormGroup id="chequeAmount" label="">
                                                                <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} onBlur={payFeesDenominationForm.handleBlur} value={payFeesDenominationForm.values.chequeAmount} onKeyDown={(e: any) => onlyAllowNumber(e)} />

                                                            </FormGroup>
                                                            <>
                                                                {totalFeesAmount + Number(payFineFeesForm.values.amount) == Number(payFeesDenominationForm.values.chequeAmount) ? null : (
                                                                    <div className="ms-2 mt-2 text-danger">
                                                                        Amount Not Matching !{' '}
                                                                        <Icon icon="Report" color="danger" className="mx-1" size="lg" />
                                                                    </div>
                                                                )}
                                                            </>
                                                        </div>


                                                    </div>
                                                    <div className="row justify-content-center">
                                                        <div className="col-lg-3 mt-2 align-self-center h6">
                                                            <p>Cheque Date</p>
                                                        </div>
                                                        <div className="col-lg-5 justify-content-center">
                                                            <FormGroup id="chequeDate" label="">
                                                                <Input type="date" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.chequeDate} />
                                                            </FormGroup>
                                                        </div>

                                                    </div>
                                                    <div className="row justify-content-center">
                                                        <div className="col-lg-3 mt-2 align-self-center h6">
                                                            <p>Bank Name</p>
                                                        </div>

                                                        <div className="col-lg-5 justify-content-center">
                                                            <FormGroup id="chequeBankName" label="">
                                                                <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.chequeBankName} />
                                                            </FormGroup>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                )}
                                {TABS.NEFT_UPI === activeTab && (
                                    <Card stretch>
                                        <CardHeader>
                                            <CardLabel icon='Style' iconColor='primary'>
                                                <CardTitle tag='div' className='h5'>
                                                    NEFT / UPI Details
                                                </CardTitle>
                                            </CardLabel>
                                        </CardHeader>
                                        <CardBody>
                                            <div className='row'>
                                                <div className="d-grid gap-4">
                                                    <div className="row justify-content-center">
                                                        <div className="col-lg-3 mt-2 align-self-center h6">
                                                            <p>NEFT / UPI Ref Number</p>
                                                        </div>
                                                        <div className="col-lg-5 justify-content-center">
                                                            <FormGroup id="neftRefNumber" label="">
                                                                <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.neftRefNumber} />
                                                            </FormGroup>
                                                        </div>
                                                    </div>
                                                    <div className="row justify-content-center">
                                                        <div className="col-lg-3 mt-2 align-self-center h6">
                                                            <p>Amount</p>
                                                        </div>

                                                        <div className="col-lg-5 justify-content-center">
                                                            <FormGroup id="neftAmount" label="">
                                                                <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.neftAmount} onKeyDown={(e: any) => onlyAllowNumber(e)} />
                                                            </FormGroup>
                                                            <>
                                                                {totalFeesAmount + Number(payFineFeesForm.values.amount) == Number(payFeesDenominationForm.values.neftAmount) ? null : (
                                                                    <div className="ms-2 mt-2 text-danger">
                                                                        Amount Not Matching !{' '}
                                                                        <Icon icon="Report" color="danger" className="mx-1" size="lg" />
                                                                    </div>
                                                                )}
                                                            </>
                                                        </div>
                                                    </div>
                                                    <div className="row justify-content-center">
                                                        <div className="col-lg-3 mt-2 align-self-center h6">
                                                            <p>Transaction Date</p>
                                                        </div>

                                                        <div className="col-lg-5 justify-content-center">
                                                            <FormGroup id="neftTranDate" label="">
                                                                <Input type="date" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.neftTranDate} />
                                                            </FormGroup>
                                                        </div>
                                                    </div>
                                                    <div className="row justify-content-center">
                                                        <div className="col-lg-3 mt-2 align-self-center h6">
                                                            <p>Bank Name</p>
                                                        </div>

                                                        <div className="col-lg-5 justify-content-center">
                                                            <FormGroup id="neftBankName" label="">
                                                                <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.neftBankName} />
                                                            </FormGroup>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                )}
                                {TABS.CARD_PAYMENT === activeTab && (
                                    <Card stretch>
                                        <CardHeader>
                                            <CardLabel icon='CreditCard' iconColor='primary'>
                                                <CardTitle tag='div' className='h5'>
                                                    Card Details
                                                </CardTitle>
                                            </CardLabel>
                                        </CardHeader>
                                        <CardBody>
                                            <div className='row'>
                                                <div className="d-grid gap-4">
                                                    <div className="row justify-content-center">
                                                        <div className="col-lg-3 mt-2 align-self-center h6">
                                                            <p>Card Number</p>
                                                        </div>
                                                        <div className="col-lg-5 justify-content-center">
                                                            <FormGroup id="cardNumber" label="">
                                                                <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.cardNumber} />
                                                            </FormGroup>
                                                        </div>
                                                    </div>
                                                    <div className="row justify-content-center">
                                                        <div className="col-lg-3 mt-2 align-self-center h6">
                                                            <p>Amount</p>
                                                        </div>

                                                        <div className="col-lg-5 justify-content-center">
                                                            <FormGroup id="cardAmount" label="">
                                                                <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.cardAmount} onKeyDown={(e: any) => onlyAllowNumber(e)} />
                                                            </FormGroup>
                                                            <>
                                                                {totalFeesAmount + Number(payFineFeesForm.values.amount) == Number(payFeesDenominationForm.values.cardAmount) ? null : (
                                                                    <div className="ms-2 mt-2 text-danger">
                                                                        Amount Not Matching !{' '}
                                                                        <Icon icon="Report" color="danger" className="mx-1" size="lg" />
                                                                    </div>
                                                                )}
                                                            </>
                                                        </div>
                                                    </div>
                                                    <div className="row justify-content-center">
                                                        <div className="col-lg-3 mt-2 align-self-center h6">
                                                            <p>Transaction Date</p>
                                                        </div>

                                                        <div className="col-lg-5 justify-content-center">
                                                            <FormGroup id="cardDate" label="">
                                                                <Input type="date" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.cardDate} />
                                                            </FormGroup>
                                                        </div>
                                                    </div>
                                                    <div className="row justify-content-center">
                                                        <div className="col-lg-3 mt-2 align-self-center h6">
                                                            <p>Bank Name</p>
                                                        </div>

                                                        <div className="col-lg-5 justify-content-center">
                                                            <FormGroup id="cardBankName" label="">
                                                                <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.cardBankName} />
                                                            </FormGroup>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                )}
                            </div>
                        </div>
                    </CardBody>
                    <CardBody>
                        <div className='row h-100 pb-3'>
                            <div className="col-lg-3">
                                <FormGroup id="receiptDate" label="Receipt Date" isFloating>
                                    <Input type="date" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.receiptDate} />
                                </FormGroup>
                            </div>
                            <div className="col-lg-5">
                                <FormGroup id="narration" label="Narration" isFloating>
                                    <Textarea placeholder='Enter Narration' onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.narration} />
                                </FormGroup>
                            </div>

                            {paymentTypeId == 1 ?
                                <div className="col-lg-3 mt-2">
                                    <Button color='info' icon='Save' type="submit" onClick={payFeesSubmit} isDisable={totalAmount > 0 ? false : true}>Save</Button>
                                </div>
                                : null}
                            {paymentTypeId == 2 ?
                                <div className="col-lg-3 mt-2">
                                    <Button color='info' icon='Save' type="submit" onClick={payFeesSubmit} isDisable={Number(totalFeesAmount + Number(payFineFeesForm.values.amount) == Number(payFeesDenominationForm.values.chequeAmount)) ? false : true}>Save</Button>
                                </div>
                                : null}

                            {paymentTypeId == 3 ?
                                <div className="col-lg-3 mt-2">
                                    <Button color='info' icon='Save' type="submit" isDisable={Number(totalFeesAmount + Number(payFineFeesForm.values.amount) == Number(payFeesDenominationForm.values.neftAmount)) ? false : true} onClick={payFeesSubmit}>Save</Button>
                                </div>
                                : null}

                            {paymentTypeId == 4 ?
                                <div className="col-lg-3 mt-2">
                                    <Button color='info' icon='Save' type="submit" isDisable={Number(totalFeesAmount + Number(payFineFeesForm.values.amount) == Number(payFeesDenominationForm.values.cardAmount)) ? false : true} onClick={payFeesSubmit}>Save</Button>
                                </div>
                                : null}
                        </div>
                    </CardBody>
                </Card>

                <OffCanvas
                    setOpen={setAddAdvanceFeeOffCanvas}
                    isOpen={addAdvanceFeeOffCanvas}
                    titleId='addAdvanceFeeOffCanvas'
                    placement='end'
                >
                    <OffCanvasHeader setOpen={setAddAdvanceFeeOffCanvas} onClick={closeAndReset}>
                        <OffCanvasTitle id="addAdvanceFeeOffCanvas">Add Advance Fee</OffCanvasTitle>
                    </OffCanvasHeader>
                    <OffCanvasBody>
                        <div className='row'>
                            <div className='col-12'>
                                <Card className={`bg-l${darkModeStatus ? 'o25' : '25'
                                    }-success bg-l${darkModeStatus ? 'o50' : '10'
                                    }-success-hover transition-base rounded-2 mb-4`}
                                    shadow='sm'>
                                    <CardBody>
                                        <div className='d-flex align-items-center pb-3'>
                                            <div className='flex-shrink-0 ms-2'>
                                                <Icon icon='PushPin' size='3x' color='danger' />
                                            </div>
                                            <div className='flex-grow-1 ms-3'>
                                                <span className='text-muted'>
                                                    If advance fees not listed. <br /> Please add {''}<Icon icon='East' color='danger' className='mx-1' size='lg' /><Button color='link' tag='a'
                                                        to={`../general/feeMaster`} >Click Here
                                                    </Button>
                                                </span>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>

                            {termFeesForTutionFeesData != '' ?
                                <div className='col-md-12 mt-2'>
                                    <Card shadow='none' borderSize={0} stretch>
                                        <Label className="col-md-12 p-2 h5">
                                            <strong className='text-dark'><Icon icon='pending_actions' color='success' className='mx-1' size='2x' />{' '}Tution Fees</strong>
                                        </Label>

                                        <div className="table-responsive">
                                            <table className='table table-modern table-hover text-center'>
                                                <thead>
                                                    <tr className='table-primary text-center'>
                                                        <th></th>
                                                        <th>TERM </th>
                                                        <th>AMOUNT </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {termFeesForTutionFeesData.map((termFeesForTution: any) => (
                                                        <tr className='text-center' key={termFeesForTution.termDetailsId}>
                                                            <td width={"20px"}>
                                                                <ChecksGroup>
                                                                    <Checks className='border border-info' checked={termFeesForTution.isChecked} onChange={(e) => addStudentAdvanceFeeForTutionValue(termFeesForTution.termDetailsId, termFeesForTution.feeMasterId, termFeesForTution.feeTypeId, termFeesForTution.standardDetailsId, termFeesForTution.feeAmount)}
                                                                    />
                                                                </ChecksGroup>
                                                            </td>
                                                            <td>{termFeesForTution.termName}</td>
                                                            <td>{termFeesForTution.feeAmount}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>

                                    </Card>
                                </div> :
                                // <div className='text-danger col-12 d-flex justify-content-center'>
                                //     <p className='fw-bold fs-5 mb-0'>{advanceTutionFeesNoData}</p>
                                // </div>
                                null}


                            {termFeesForTransportFeesData != '' ?
                                <div className='col-md-12 mt-2'>
                                    <Card shadow='none' borderSize={0} stretch>
                                        <Label className="col-md-12 p-2 h5">
                                            <strong className='text-dark'><Icon icon='BusAlert' color='success' className='mx-1' size='2x' />{' '}Transport Fees</strong>
                                        </Label>

                                        <div className="table-responsive">
                                            <table className='table table-modern table-hover text-center'>
                                                <thead>
                                                    <tr className='table-primary text-center'>
                                                        <th></th>
                                                        <th>TERM </th>
                                                        <th>AMOUNT </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {termFeesForTransportFeesData.map((termFeesForTransport: any) => (
                                                        <tr className='text-center' key={termFeesForTransport.termDetailsId}>
                                                            <td width={"20px"}>
                                                                <ChecksGroup>
                                                                    <Checks className='border border-info' checked={termFeesForTransport.isChecked} onChange={(e) => addStudentAdvanceFeeForTrasportValue(termFeesForTransport.termDetailsId, termFeesForTransport.studentTrasportDetailsId, termFeesForTransport.feeTypeId, termFeesForTransport.standardDetailsId, termFeesForTransport.feeAmount)}
                                                                    />
                                                                </ChecksGroup>
                                                            </td>
                                                            <td>{termFeesForTransport.termName}</td>
                                                            <td>{termFeesForTransport.feeAmount}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </Card>
                                </div>
                                :
                                // <div className='text-danger col-12 d-flex justify-content-center'>
                                //     <p className='fw-bold fs-5 mb-0'>{advanceTransportFeesNoData}</p>
                                // </div>
                                null}
                        </div>

                    </OffCanvasBody>
                    {termFeesForTutionFeesData != '' || termFeesForTransportFeesData != '' ?
                        <div className='row m-0'>
                            <div className='col-12 p-3'>
                                <Button color='info' className='w-100' type="submit" icon="Save" onClick={payAdvanceFeesSubmit}>
                                    Save
                                </Button>
                            </div>
                        </div> : null}
                </OffCanvas>

                <OffCanvas
                    setOpen={setAddExtraFeeOffCanvas}
                    isOpen={addExtraFeeOffCanvas}
                    titleId='addExtraFeeOffCanvas'
                    placement='end' tag="form" noValidate onSubmit={addExtraFeesForm.handleSubmit}
                >
                    <OffCanvasHeader setOpen={setAddExtraFeeOffCanvas} onClick={closeAndReset}>
                        <OffCanvasTitle id="addExtraFeeOffCanvas">Add Extra Fee</OffCanvasTitle>
                    </OffCanvasHeader>
                    <OffCanvasBody>
                        <div className='row g-4'>
                            <div className='col-12'>
                                <FormGroup id='feeTypeId' label='Fee Type' onBlur={addExtraFeesForm.handleBlur}>
                                    <SearchableSelect
                                        placeholder='Select Fee Type'
                                        ariaLabel='Fee Type'
                                        onChange={selectFeeType}
                                        value={feeTypeId}
                                        onBlur={addExtraFeesForm.handleBlur}
                                        isValid={addExtraFeesForm.isValid}
                                        isTouched={addExtraFeesForm.touched.feeTypeId}
                                        invalidFeedback={addExtraFeesForm.errors.feeTypeId}
                                        list={feesTypeData.map((data: any) => (
                                            { value: data.feeTypeId, label: data.feeType }
                                        ))} />
                                </FormGroup>
                            </div>
                            {feeTypeId?.value == 14 ? <div className='col-12'>
                                <FormGroup id='extraFeeTypeId' label='Fine Type' onBlur={addExtraFeesForm.handleBlur}>
                                    <SearchableSelect
                                        placeholder='Select Fine Type'
                                        ariaLabel='Select Fine Type'
                                        onChange={(e: any) => setExtraFeeTypeId(e)}
                                        value={extraFeeTypeId}
                                        onBlur={addExtraFeesForm.handleBlur}
                                        isValid={addExtraFeesForm.isValid}
                                        isTouched={addExtraFeesForm.touched.extraFeeTypeId}
                                        invalidFeedback={addExtraFeesForm.errors.extraFeeTypeId}
                                        list={extraFeeTypeData.map((data: any) => (
                                            { value: data.extraFeeTypeId, label: data.feeType }
                                        ))} />
                                </FormGroup>
                            </div> : null}

                            <div className='col-12'>
                                <FormGroup id='amount' label='Amount'>
                                    <Input placeholder='Enter Amount'
                                        onChange={addExtraFeesForm.handleChange}
                                        value={addExtraFeesForm.values.amount}
                                        onBlur={addExtraFeesForm.handleBlur}
                                        isValid={addExtraFeesForm.isValid}
                                        isTouched={addExtraFeesForm.touched.amount}
                                        invalidFeedback={addExtraFeesForm.errors.amount}
                                        onKeyDown={(e: any) => onlyAllowNumber(e)} />
                                </FormGroup>
                            </div>

                        </div>
                    </OffCanvasBody>
                    <div className='row m-0'>
                        <div className='col-12 p-3'>
                            <Button color='info' className='w-100' type="submit" icon="Save" isDisable={!addExtraFeesForm.isValid && !!addExtraFeesForm.submitCount}>
                                Save
                            </Button>
                        </div>
                    </div>
                </OffCanvas>

                <Modal setIsOpen={setFineFeeReport} isOpen={fineFeeReport} titleId='addCourseCapacity' isStaticBackdrop fullScreen='sm'>
                    <ModalHeader className="'modal-header'" setIsOpen={() => { setFineFeeReport(false) }}>
                        <ModalTitle id='fineFeeReport'>Fine Fee Report</ModalTitle>
                    </ModalHeader>
                    <ModalBody>
                        <div className="table-responsive">
                            <table className='table table-modern table-hover text-nowrap'>
                                <thead className='table-primary text-uppercase text-center'>
                                    <tr>
                                        <th>Fee Type</th>
                                        <th>Receipt Date</th>
                                        <th>Fine Amount</th>
                                    </tr>
                                </thead>
                                <tbody className='text-center'>
                                    {studentFineFeeByFineTypeIdData.map((studentFineFeeReport: any) => (
                                        <tr key={studentFineFeeReport.studentFineFeeCollectionId}>
                                            <td>{studentFineFeeReport.fineType}</td>
                                            <td>{studentFineFeeReport.receiptDate}</td>
                                            <td>{studentFineFeeReport.totalFineAmount}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </ModalBody>
                </Modal>

                <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
            </Page>
        </PageWrapper>
    );
};

export default PayFees;