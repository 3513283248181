import { useContext, useEffect, useRef, useState } from "react";
import { toasts } from "../../../../../services/toast.service";
import PageWrapper from "../../../../../layout/PageWrapper/PageWrapper";
import Page from "../../../../../layout/Page/Page";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardSubTitle, CardTitle } from "../../../../bootstrap/Card";
import Button from "../../../../bootstrap/Button";
import { useReactToPrint } from 'react-to-print';
import { useParams } from "react-router-dom";
import { getStudentManualTcDetails, getStudentTransferCertificate, updateStudentTcStatus } from "../../../../../services/certificate.service";
import { getLicenseKey } from "../../../../../services/application.settings";
import AuthContext from "../../../../../contexts/authContext";
import AlertService from "../../../../../services/AlertService";
import { getSchoolDetails } from "../../../../../services/school.service";
import Icon from "../../../../icon/Icon";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "../../../../bootstrap/Modal";
import { showLoader } from "../../../../../services/loader.services";
import noImage from '../../../../../assets/img/noImage.png';
import './CertificateStyle.css'
import CollegeLogo from '../../../../../assets/logos/logo.jpg';
import { pictNotLoading, profilePic } from "../../../../../services/common.service";


const CommonTCToPrint = () => {
    const { userAccountId, userTypeId } = useContext(AuthContext);
    const { studentDetailsId, studentManualTcId } = useParams();

    useEffect(() => {
        getSchool();

        if (studentDetailsId != undefined) {
            getStudentTransferCertificateList(studentDetailsId);
        }

        if (studentManualTcId != undefined) {
            getStudentManualTcList(studentManualTcId);
        }
    }, [])

    const [studentTCData, setStudentTCData] = useState<any>([])
    const [isIssued, setIsIssued] = useState(false)
    const [schoolDetailsData, setSchoolDetailsData] = useState<any>([]);
    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);
    const [issueModal, setIssueModal] = useState(false)
    const [ugOrPgStudent, setUgOrPgStudent] = useState('')
    const componentRef = useRef(null);


    function getStudentTransferCertificateList(studentDetailsId: any) {
        getStudentTransferCertificate(studentDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentTransferCertificate;
                    let isIssued = response.data.data.studentTransferCertificate[0].isIssued;
                    if (data != undefined) {
                        setStudentTCData(data);
                        let graduationTypeId = data[0].graduationTypeId
                        if (graduationTypeId == 1) {
                            setUgOrPgStudent('isUgStudent');
                        }
                        else {
                            setUgOrPgStudent('isNonUgStudent');
                        }

                        if (isIssued == 1) {
                            setIsIssued(true);

                        } else {
                            setIsIssued(false);
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")

                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStudentManualTcList(studentManualTcId: any) {
        getStudentManualTcDetails(studentManualTcId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentManualTc;
                    let isIssued = response.data.data.studentManualTc[0].isIssued;
                    if (data != undefined) {
                        setStudentTCData(data);
                        if (isIssued == 1) {
                            setIsIssued(true);

                        } else {
                            setIsIssued(false);
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")

                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function setUpdateTCStatusDetails() {

        if (studentDetailsId != null) {
            return {
                studentDetailsId: studentDetailsId,
                isIssued: 1,
                licenseKey: getLicenseKey,
                userAccountId: userAccountId
            }
        } else {
            return {
                studentManualTcId: studentManualTcId,
                isIssued: 1,
                licenseKey: getLicenseKey,
                userAccountId: userAccountId
            }
        }
    }

    function updateStudentTcStatusSubmit() {
        showLoader(true)
        let studentTcStatusPostData = setUpdateTCStatusDetails()
        updateStudentTcStatus(studentTcStatusPostData,
            (response) => {
                const data = response.data;
                if (data.success == true) {
                    showLoader(false)
                    setAlertStatus({ message: data.message, type: "success" });
                    setIsOpen(true);
                    setIssueModal(false);
                    if (studentDetailsId != undefined) {
                        getStudentTransferCertificateList(studentDetailsId)
                    }
                    if (studentManualTcId != undefined) {
                        getStudentManualTcList(studentManualTcId)
                    }
                }
                else if (data.success == false) {
                    showLoader(false)
                    setAlertStatus({ message: data.message, type: "error" });
                    setIsOpen(true);
                }
                else {
                    showLoader(false)
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setAlertStatus({ message: errorDescription, type: "error" });
                    setIsOpen(true);
                }
            }
            , (error) => {
                showLoader(false)
                setAlertStatus({ message: error, type: "error" });
                setIsOpen(true);
            }
        )

    }

    function getSchool() {
        getSchoolDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.schoolDetails;
                    if (data != undefined) {
                        setSchoolDetailsData(data);
                    } else {
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    toasts(response.data.message, 'Error');
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, 'Error');
                }
            },
            (error) => {
                toasts(error, 'Error');
            },
        );
    }

    function closeModal() {
        setIssueModal(false)
    }

    // Print
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <PageWrapper title='TC View'>
            <Page>
                <Card stretch data-tour='list'>
                    <CardHeader borderSize={1}>
                        <CardLabel icon='StickyNote2' iconColor='info' className="col-lg-6">
                            <CardTitle tag='div' className='h5'>Student Transfer Certificate</CardTitle>
                            {isIssued ?
                                <CardTitle tag='div' className="text-danger h5">Issued !</CardTitle>
                                : null}
                        </CardLabel>&nbsp;&nbsp;

                        <CardActions className="d-print-none">
                            {isIssued ? null :
                                <Button icon="CheckCircle" isLight color="success" onClick={() => { setIssueModal(true) }}>Issue</Button>}
                            <Button icon="Print" isLight color="primary" onClick={handlePrint}></Button>
                        </CardActions>
                    </CardHeader>

                    <CardBody ref={componentRef}>
                        <div className="card-body">
                            {/* {isIssued ?
                                <div className="col-1">
                                    <div style={{ width: '100px', height: '80px', overflow: 'hidden', position: 'relative', top: '-20px', left: '-20px' }} className="ribbon ribbon-top-left">
                                        <span style={{ position: 'absolute', width: '200px', backgroundColor: 'green', color: '#fff', fontSize: '10px', textTransform: 'uppercase', textAlign: 'center', right: '-20px', top: '30px', transform: 'rotate(-45deg)' }}>Issued</span>
                                    </div>
                                </div> : null} */}

                            {schoolDetailsData.map((school: any) => (
                                <table className="text-nowrap" cellPadding="10" cellSpacing="10" width="100%" key={school.schoolDetailsId}>
                                    <tbody>
                                        <tr>
                                            <td align="center" width="15%">
                                                <img
                                                    height='120px'
                                                    width='120px'
                                                    src={CollegeLogo}
                                                />
                                            </td>
                                            <td width="74%" align="center" style={{
                                                fontFamily: 'Bookman Old Style',
                                                // color: 'rgb(61, 57, 57)'
                                            }}>
                                                <h1 className="text-uppercase">
                                                    <b style={{ fontSize: '120%' }}> {school.schoolName}</b>
                                                </h1>

                                                <p className="fs-6">({school.description})<br />{school.address}<br />
                                                    <Icon icon="Email" /> {school.website} <Icon icon="Phone" /> {school.phone} <Icon icon="PhoneAndroid" /> {school.cellNo}</p>
                                            </td>
                                            <td width="15%"></td>
                                        </tr>
                                        <tr>
                                            <td align="center" width="15%"></td>
                                            <td width="74%" align="center">
                                                <h3 className="text-center fs-2 justify-content-center">
                                                    <strong style={{
                                                        fontFamily: 'Bookman Old Style',
                                                        borderStyle: "Solid",
                                                        // color: 'rgb(61, 57, 57)',
                                                    }}>&nbsp;TRANSFER CERTIFICATE&nbsp;
                                                    </strong></h3>
                                            </td>
                                            <td width="15%"></td>
                                        </tr>
                                    </tbody>
                                </table>

                            ))}
                            <div className="row">
                                {studentTCData.map((student: any) => (
                                    <div className="row" key={student.studentDetailsId}>
                                        <div className="col-12 tcStyle" id='tdForReceiptNoBorder'>
                                            <table className="table text-nowrap text-md-nowrap mg-b-0">
                                                <tbody className="tcStyle fs-4">
                                                    <tr>
                                                        <td>
                                                            <h4>Serial No :  {student.serialNo}</h4>
                                                        </td>
                                                        <td width="70%"></td>
                                                        <td>
                                                            <h4 className="d-flex justify-content-end">Reg.No : {student.admissionNo}</h4>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <table className="table text-nowrap">
                                                <tbody className="fs-4">
                                                    <tr>
                                                        <td style={{ width: '1%' }}>1.</td>
                                                        <td style={{ width: '40%' }}> Name of the Student (in BLOCK LETTERS) <br />
                                                            (as entered in +2 or equivalent Certificate) </td>
                                                        <td style={{ width: '1%' }}>:</td>
                                                        <td style={{ width: '20%' }} className="fw-bold">{student.studentName}</td>
                                                        <td width="28%" rowSpan=
                                                            {4} align="right">
                                                            {student.profilePath != null ?
                                                                <img className="rounded-0" src={student.profilePath}
                                                                    width="140" height="140" onError={(e: any) => pictNotLoading(e, student.gender == 'Male' ? 1 : 2)} />
                                                                : <img className="rounded-0" src={profilePic(student.gender == 'Male' ? 1 : 2)}
                                                                    width="140" height="140" />}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td width="1%">2.</td>
                                                        <td width="40%">Name of the Father/Mother</td>
                                                        <td width="1%">:</td>
                                                        <td width="20%">{student.fatherOrMotherName}</td>
                                                    </tr>
                                                    <tr>
                                                        <td width="1%">3.</td>
                                                        <td width="40%">Nationality and Religion</td>
                                                        <td width="1%">:</td>
                                                        <td width="20%">{student.nationality} - {student.religion}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td width="1%">4.</td>
                                                        <td width="40%">Community and Caste</td>
                                                        <td width="1%">:</td>
                                                        {/* <td width="20%">{student.community} - {student.caste}</td> */}
                                                    </tr>

                                                    <tr>
                                                        <td width="1%">5.</td>
                                                        <td width="40%">Gender </td>
                                                        <td width="1%">:</td>
                                                        <td width="20%">{student.gender}</td>
                                                    </tr>
                                                    <tr>
                                                        <td width="1%">6.</td>
                                                        <td width="10%">Date of Birth as registered in <br /> the
                                                            admission Register
                                                        </td>
                                                        <td width="1%">:</td>
                                                        <td width="20%" colSpan={2}>
                                                            {student.dateOfBirth_label}<br />{student.dateOfBirthInWord}</td>
                                                    </tr>
                                                    <tr>
                                                        <td width="1%">7.</td>
                                                        <td width="40%">Date of Admission</td>
                                                        <td width="1%">:</td>
                                                        <td width="20%" colSpan={2}>{student.admissionDate_label}</td>
                                                    </tr>
                                                    <tr>
                                                        <td width="1%">8.</td>
                                                        <td width="40%">Degree and Programme to which <br /> the student
                                                            was admitted
                                                        </td>
                                                        <td width="1%">:</td>
                                                        <td width="20%" colSpan={2}>{student.degreeAndCourse}</td>
                                                    </tr>
                                                    <tr>
                                                        <td width="1%">9.</td>
                                                        <td width="40%">Class in which the student was studying <br /> at
                                                            the time of
                                                            leaving </td>
                                                        <td width="1%">:</td>
                                                        <td width="20%" colSpan={2}>{student.timeOfLeaving}</td>
                                                    </tr>
                                                    <tr>
                                                        <td width="1%">10.</td>
                                                        <td width="40%">a) Date on which the student actually left <br />
                                                            the College
                                                        </td>
                                                        <td width="1%">:</td>
                                                        <td width="20%">{student.leavingDate_label}</td>
                                                    </tr>
                                                    {ugOrPgStudent == 'isUgStudent' ?
                                                        <>
                                                            <tr>
                                                                <td width="1%"></td>
                                                                <td width="40%" >b) Language offered under Part-1 </td>
                                                                <td width="1%" >:</td>
                                                                <td width="20%" >{student.languageOfferedUnderPart1} </td>
                                                            </tr>
                                                            <tr>
                                                                <td width="1%"></td>
                                                                <td width="40%">c) Medium of Study </td>
                                                                <td width="1%">:</td>
                                                                <td width="20%">{student.medium}</td>
                                                            </tr>
                                                        </>
                                                        : null}

                                                    {ugOrPgStudent == 'isNonUgStudent' ?
                                                        <tr>
                                                            <td width="1%"></td>
                                                            <td width="40%">b) Medium of Study </td>
                                                            <td width="1%">:</td>
                                                            <td width="20%">{student.medium}</td>
                                                        </tr> : null}
                                                    <tr>
                                                        <td width="1%">11.</td>
                                                        <td width="40%"> Reason for which the student left <br /> the
                                                            College </td>
                                                        <td width="1%">:</td>
                                                        <td width="20%">{student.leavingReason}</td>
                                                    </tr>
                                                    <tr>
                                                        <td width="1%" >12.</td>
                                                        <td width="40%" >Whether qualified for promotion to higher
                                                            <br />studies
                                                        </td>
                                                        <td width="1%">:</td>
                                                        <td width="20%">{student.qualifiedForHigherStudy}</td>
                                                    </tr>
                                                    <tr>
                                                        <td width="1%">13.</td>
                                                        <td width="40%">Date of the Transfer Certificate </td>
                                                        <td width="1%">:</td>
                                                        <td width="20%">{student.transferCertificateDate_label}</td>
                                                    </tr>
                                                    <tr>
                                                        <td width="1%">14.</td>
                                                        <td width="40%">Conduct and Character </td>
                                                        <td width="1%">:</td>
                                                        <td width="20%">{student.conductAndCharacter}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <table className="table text-nowrap text-md-nowrap mg-b-0">
                                                <tbody className="tcStyle fs-4">
                                                    <tr>
                                                        <td>
                                                            <h4>College Seal with Date</h4>
                                                        </td>
                                                        <td width="70%"></td>
                                                        <td>
                                                            <h4>Signature of the Principal</h4>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <div className="col-12 tcStyle fs-4">
                                                <label className="mg-b-10">1. Erasures and Unauthenticated or fraudulent in the Certificate will lead to its cancellation</label>
                                            </div>
                                            <div className="col-12 tcStyle fs-4 mt-2">
                                                <label className="mg-b-10"> 2. Should be signed in ink by the Head or the Institution who will be held responsible for the <br />correctness of the entries</label>
                                            </div>
                                            <div className="col-12 tcStyle fs-4 mt-2">
                                                <label className="mg-b-10">3. Declaration by the Parent Or Guardian</label>
                                            </div>
                                            <div className="col-12 tcStyle fs-4 mt-2">
                                                <label className="mg-b-10">I hereby declare the particulars recorded against items 2 to 7 are correct and that no change will be demanded by me in future
                                                </label>
                                            </div>
                                            <br />
                                            <br />

                                            <table className="table text-nowrap text-md-nowrap mg-b-0" id='tdForReceiptNoBorder'>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <h4>Signature of the Student</h4>
                                                        </td>
                                                        <td width="70%"></td>
                                                        <td>
                                                            <h4>Signature of the Parent / Guardian</h4>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Page>

            <Modal
                setIsOpen={setIssueModal}
                isOpen={issueModal}
                titleId='issueModal'
                isStaticBackdrop
                size='sm'>
                <ModalHeader className="'modal-header'"
                    setIsOpen={() => { setIssueModal(false) }} onClick={closeModal}>
                    <ModalTitle id="issueModal">Issue TC</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <div className='row align-middle'>
                        <h6 className="main-content-label mb-1 text-center text-danger">Are you sure you want to issue?</h6>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color='info' isOutline className='border-0' onClick={closeModal}>Close</Button>
                    <Button color='info' icon='CheckCircleOutline' onClick={() => { updateStudentTcStatusSubmit() }}>Issue </Button>
                </ModalFooter>
            </Modal>

            <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
        </PageWrapper>
    )
}
export default CommonTCToPrint