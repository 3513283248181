import React, { useContext, useEffect, useRef, useState } from 'react';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from '../../bootstrap/Card';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Button, { ButtonGroup } from '../../bootstrap/Button';
import OpenCardComponent from '../../../common/components/OpenCardComponent';
import SearchableSelect from '../../../common/components/SearchableSelect';
import Collapse from '../../bootstrap/Collapse';
import { toasts } from '../../../services/toast.service';
import { getColumnsForDataTable, getMedium, getStandardDetails, updateColumnsForDataTable, updateFilter } from '../../../services/common.service';
import { getBatchMasterDetails, getSectionDetails } from '../../../services/master.service';
import AuthContext from '../../../contexts/authContext';
import Icon from '../../icon/Icon';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../../bootstrap/Dropdown';
import Checks, { ChecksGroup } from '../../bootstrap/forms/Checks';
import PaginationButtons, { dataPagination } from '../../PaginationButtons';
import NoDataMsg from '../../../common/components/NoDataMsg';
import useSortableData from '../../../hooks/useSortableData';
import { convertJsonToPdf, downloadExcel, downloadFile } from '../../../services/ExportService';
import { useReactToPrint } from 'react-to-print';
import { useFormik } from 'formik';
import { getLicenseKey } from '../../../services/application.settings';
import Input from '../../bootstrap/forms/Input';
import { getClassWiseQuizMarkList, getQuizDetails } from '../../../services/exam.service';

const ClassWiseQuizMarkList = () => {

	useEffect(() => {
		getBatchList();
	}, []);

	const { userAccountId, userTypeId } = useContext(AuthContext);
	const [academicPeriodId, setAcademicPeriodId] = useState<any>('');
	const [quizDetailsId, setQuizDetailsId] = useState<any>('');
	const [mediumId, setMediumId] = useState<any>('');
	const [standardDetailsId, setStandardDetailsId] = useState<any>('');
	const [sectionDetailsId, setSectionDetailsId] = useState<any>('');
	const [batchMasterId, setBatchMasterId] = useState<any>('');

	const [mediumData, setMediumData] = useState<any>([]);
	const [standardData, setStandardData] = useState<any>([]);
	const [sectionDetailsData, setSectionDetailsData] = useState<any>([]);
	const [batchMasterData, setBatchMasterData] = useState<any>([]);

	const [isOpenListCard, setIsOpenListCard] = useState(true);
	const [isLoader, setIsLoader] = useState(false);
	const [dataSuccess, setDataSuccess] = useState(false);

	const [classWiseMarkData, setClassWiseMarkData] = useState<any>([])

	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const { items, requestSort, getClassNamesFor } = useSortableData(classWiseMarkData);
	const onCurrentPageData = dataPagination(items, currentPage, perPage);
	const [noDataMsg, setNoDataMsg] = useState('')

	const [columnVisibilityData, setColumnVisibilityData] = useState([]);
	const [allColumnsData, setAllColumnsData] = useState([]);
	const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
	const [columnDataById, setColumnDataById] = useState([])
	const [isChecked, setIsChecked] = useState(false)
	const componentRef = useRef(null);

	const columnVisibilityForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			searchInput: '',
			available: false,
		},
		validate: (values) => { },

		//validateOnChange: false,
		onSubmit: () => { },
	});

	// Filter
	const filteredData = updateFilter(onCurrentPageData, columnVisibilityData, columnVisibilityForm);

	const selectBatch = (e: any) => {
		setDataSuccess(false)
		setMediumId('');
		setStandardDetailsId('');
		setSectionDetailsId('');
		setQuizDetailsId('')
		let batchMasterId = e;
		setBatchMasterId(batchMasterId);
		let batchData = batchMasterData.filter((item: any) => item.batchMasterId == batchMasterId?.value)
		setAcademicPeriodId(batchData != '' ? batchData[0]?.academicPeriodId : '');
		getMediumList();
	};

	const selectMedium = (e: any) => {
		setDataSuccess(false)
		setStandardDetailsId('');
		setSectionDetailsId('');
		setQuizDetailsId('')
		let mediumId = e;
		setMediumId(mediumId);
		getStandardList();
	};

	const selectStandard = (e: any) => {
		setDataSuccess(false)
		setSectionDetailsId('');
		setQuizDetailsId('')
		let standardDetailsId = e;
		setStandardDetailsId(standardDetailsId);
		if (mediumId?.value != undefined && standardDetailsId?.value != undefined) {
			getSectionList(mediumId?.value, standardDetailsId?.value);
		}
	};

	const selectSection = (e: any) => {
		setDataSuccess(false)
		setQuizDetailsId('')
		let sectionDetailsId = e;
		setSectionDetailsId(sectionDetailsId);
		if (batchMasterId?.value != undefined && mediumId?.value != undefined && standardDetailsId?.value != undefined) {
			getQuizDetailsList(academicPeriodId, batchMasterId?.value, mediumId?.value, standardDetailsId?.value)
		}
	};

	const selectQuizType = (e: any) => {
		setDataSuccess(false)
		let quizDetailsId = e;
		setQuizDetailsId(quizDetailsId);
	};

	function View() {
		if (batchMasterId?.value != undefined && standardDetailsId?.value != undefined && sectionDetailsId?.value != undefined && quizDetailsId?.value != undefined) {
			getClassWiseQuizMarkDetails(batchMasterId?.value, standardDetailsId?.value, sectionDetailsId?.value, quizDetailsId?.value)
		}
	}

	function getBatchList() {
		getBatchMasterDetails(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.batchMasterDetails;
					if (data != undefined) {
						setBatchMasterData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					// toasts(response.data.message, "Error")
					setBatchMasterData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getMediumList() {
		getMedium(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.medium;
					if (data != undefined) {
						setMediumData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setMediumData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getStandardList() {
		getStandardDetails(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.standardDetails;
					if (data != undefined) {
						setStandardData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setStandardData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getSectionList(mediumId: any, standardDetailsId: any) {
		getSectionDetails(mediumId, standardDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.sectionDetails;
					if (data != undefined) {
						setSectionDetailsData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setSectionDetailsData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	const [quizDetailsData, setQuizDetailsData] = useState<any>([])

	function getQuizDetailsList(academicPeriodId: any, batchMasterId: any, mediumId: any, standardDetailsId: any) {
		getQuizDetails(academicPeriodId, batchMasterId, mediumId, standardDetailsId, 0, 0,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.quizDetails;
					if (data != undefined) {
						setQuizDetailsData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setQuizDetailsData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getClassWiseQuizMarkDetails(batchMasterId: any, standardDetailsId: any, sectionDetailsId: any, quizDetailsId: any) {
		setIsLoader(true)
		getClassWiseQuizMarkList(batchMasterId, standardDetailsId, sectionDetailsId, quizDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.classWiseQuizMarkList;
					if (data != undefined) {
						setClassWiseMarkData(data);
						setIsOpenListCard(false);
						setDataSuccess(true);
						setIsLoader(false)
						getColumnsForTable('getClassWiseQuizMarkList', 'get');
						for (let i = 0; i < data.length; i++) {
							data[i].sno = i + 1;
						}
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setClassWiseMarkData([]);
					setIsOpenListCard(false);
					setDataSuccess(true);
					setIsLoader(false)
					getColumnsForTable('getClassWiseQuizMarkList', 'get');
					setNoDataMsg(response.data.message)
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
					setIsLoader(false)
				}
			},
			(error) => {
				toasts(error, 'Error');
				setIsLoader(false)
			},
		);
	}

	function getColumnsForTable(apiName: any, type: any) {
		getColumnsForDataTable(userTypeId, apiName,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.columnsForDataTable;
					if (data != undefined) {
						let allColumnsData = data;
						setAllColumnsData(allColumnsData)
						let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
						setColumnVisibilityData(columnVisibilityData)

						const allData = allColumnsData.map((item: any) => item.isDisplay);
						const allColumns = allData.filter((item: any) => item == false);

						if (type == 'get') {
							setIsChecked(allColumns == '' ? true : false);
						}
						else if (type == 'post') {
							const all = [];
							let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

							for (let i = 0; i < nonDefaultValue.length; i++) {

								const obj = {
									columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
									isDisplay: allColumns == '' ? 0 : 1
								}
								all.push(obj);
							}
							updateColumnsSubmit('', '', all)
						}
					} else {
						toasts("Undefined Data", "Error")
					}
				}
				else if (response.data.success === false) {
					toasts(response.data.message, "Error")
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, "Error")
				}
			}, error => {
				toasts(error, "Error")
			}
		)

	}

	function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {

		if (columnVisibilityForm.isValid) {

			setColumnDataById(isDisplay)
			const columnVisibilityPostData = {
				licenseKey: getLicenseKey,
				userAccountId: userAccountId,
				columns: type ? type : [
					{
						columnVisibilityId: columnVisibilityId,
						isDisplay: isDisplay ? 0 : 1
					}
				]
			}

			updateColumnsForDataTable(columnVisibilityPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						getColumnsForTable('getClassWiseQuizMarkList', 'get');
					}
					else if (data.success == false) {
						// toasts(data.success, "Error")
					}
					else {
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						toasts(errorDescription, "Error")
					}
				}
				, (error) => {
					toasts(error, "Error")
				}
			)
		} else if (columnVisibilityForm.isValid == false) {
			toasts("Please fill all the details!", "Error")
		}
	}

	// Print
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	return (
		<PageWrapper title='Add Quiz'>
			<Page container='fluid'>
				<Collapse isOpen={isOpenListCard}>
					<Card stretch data-tour='list'>
						<CardHeader borderSize={1}>
							<CardLabel icon='List' iconColor='info'>
								<CardTitle tag='div' className='h5'>
									Class Wise Quiz Mark List
								</CardTitle>
							</CardLabel>
						</CardHeader>
						<CardBody>
							<div className='row'>
								<div className='col-3'>
									<FormGroup id='batchMasterId' label='Batch' isFloating>
										<SearchableSelect
											isFloating
											ariaLabel='Batch'
											placeholder='Select Batch'
											onChange={selectBatch}
											value={batchMasterId}
											list={batchMasterData.map((data: any) => ({
												value: data.batchMasterId, label: data.batchName
											}))}
											required
										/>
									</FormGroup>
								</div>

								{batchMasterId?.value != undefined ? (
									<div className='col-3'>
										<FormGroup id='mediumId' label='Medium' isFloating>
											<SearchableSelect
												isFloating
												ariaLabel='Medium'
												onChange={selectMedium}
												value={mediumId}
												list={mediumData.map((data: any) => ({
													value: data.mediumId, label: data.medium
												}))}
											/>
										</FormGroup>
									</div>
								) : null}

								{mediumId?.value != undefined ? (
									<div className='col-3'>
										<FormGroup
											id='standardDetailsId'
											label='Standard'
											isFloating>
											<SearchableSelect
												isFloating
												ariaLabel='Standard'
												onChange={selectStandard}
												value={standardDetailsId}
												list={standardData.map((data: any) => ({
													value: data.standardDetailsId, label: data.standardName
												}))}
											/>
										</FormGroup>
									</div>
								) : null}

								{standardDetailsId?.value != undefined ? (
									<div className='col-3'>
										<FormGroup id='sectionDetailsId' label='Section' isFloating>
											<SearchableSelect
												isFloating
												ariaLabel='Section'
												onChange={selectSection}
												value={sectionDetailsId}
												list={sectionDetailsData.map((data: any) => ({
													value: data.sectionDetailsId, label: data.sectionName
												}))}
											/>
										</FormGroup>
									</div>
								) : null}

								{sectionDetailsId?.value != undefined ? (
									<div className='col-3 mt-3'>
										<FormGroup id='quizDetailsId' label='Quiz Type' isFloating>
											<SearchableSelect
												isFloating
												ariaLabel='Quiz Type'
												onChange={selectQuizType}
												value={quizDetailsId}
												list={quizDetailsData.map((data: any) => ({
													value: data.quizDetailsId, label: data.titleName + ' - ' + data.paperName
												}))}
											/>
										</FormGroup>
									</div>
								) : null}

								{quizDetailsId?.value != undefined ? (
									<div className='col-2 mt-3'>
										<Button className='mt-2' icon='ArrowDownward' color='primary' onClick={View}>
											View
										</Button>
									</div>
								) : null}
							</div>
						</CardBody>
					</Card>
				</Collapse>

				<OpenCardComponent isOpenListCard={isOpenListCard} setIsOpenListCard={setIsOpenListCard} isLoader={isLoader} />

				{dataSuccess ? (
					<>
						<Card stretch data-tour='list' ref={componentRef}>
							<CardHeader borderSize={1}>
								<CardLabel icon='List' iconColor='info' className="col-lg-6">
									<CardTitle tag='div' className='h5'>Mark List</CardTitle>
								</CardLabel>&nbsp;&nbsp;

								<ButtonGroup className="col-lg-3 d-print-none" color='primary'>
									<Icon className="mt-1" icon='Search' size='2x' color='primary' />
									<Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
								</ButtonGroup>

								<CardActions className="d-print-none">
									<Dropdown isOpen={columnVisibilityMenu} setIsOpen={setColumnVisibilityMenu} isButtonGroup>
										<DropdownToggle>
											<Button icon='FilterAlt' color='primary' isLight>Filter</Button>
										</DropdownToggle>
										<DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
											<DropdownItem>
												<div className='container py-2'>
													<form className='row g-3'>
														<div className='col-12'>
															<FormGroup>
																<h6>Select All</h6>
																<Checks id='available' type='switch' label='Select All Columns' onChange={() => getColumnsForTable('getClassWiseQuizMarkList', 'post')} checked={isChecked} ariaLabel='Available status' />
															</FormGroup>
														</div>
														<div className='col-12' style={{ maxHeight: '200px', overflowY: 'scroll' }}>
															<FormGroup>
																<h6>Columns</h6>
																<ChecksGroup>
																	{allColumnsData.map((i: any) => (
																		<Checks key={i.columnVisibilityId} id='{i.columnVisibilityId}' label={i.columnName} onChange={() => updateColumnsSubmit(i.columnVisibilityId, i.isDisplay, '')} checked={i.isDisplay} disabled={i.isDefault == true} />
																	))}
																</ChecksGroup>
															</FormGroup>
														</div>
													</form>
												</div>
											</DropdownItem>
										</DropdownMenu>
									</Dropdown>
									<Dropdown isButtonGroup>
										<DropdownToggle>
											<Button color='primary' isLight icon='CloudDownload'> Export </Button>
										</DropdownToggle>
										<DropdownMenu isAlignmentEnd>
											<DropdownItem>
												<Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel("ClassWiseQuizMarkList", classWiseMarkData, columnVisibilityData)}> Excel </Button>
											</DropdownItem>
											<DropdownItem>
												<Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(classWiseMarkData, columnVisibilityData, "ClassWiseQuizMarkList")}> PDF
												</Button>
											</DropdownItem>
											<DropdownItem>
												<Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile("ClassWiseQuizMarkList", classWiseMarkData, columnVisibilityData)}> CSV </Button>
											</DropdownItem>
											<DropdownItem>
												<Button color='primary' isLight icon='Print' onClick={handlePrint}> Print </Button>
											</DropdownItem>
										</DropdownMenu>
									</Dropdown>
								</CardActions>
							</CardHeader>

							<CardBody className='table-responsive' isScrollable>
								<table className='table table-modern table-hover text-nowrap'>
									<thead>
										<tr>
											<th scope='col' onClick={() => requestSort('sno')}
												className='cursor-pointer text-decoration-underline'>S.No{' '}
												<Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
											</th>
											{columnVisibilityData.map((column: any) => (
												<th key={column.keyName} scope='col' onClick={() => requestSort(column.keyName)}
													className='cursor-pointer text-decoration-underline'>
													{column.columnName}
													<Icon size='lg' className={getClassNamesFor(column.keyName)} icon='FilterList' />
												</th>
											))}
										</tr>
									</thead>
									<tbody>
										{filteredData != "" ?
											<>
												{filteredData.map((rowData: any) => (
													<tr key={rowData.studentDetailsId}>
														<td>{rowData.sno}</td>
														{columnVisibilityData.map((column: any) => (
															(column.isDisplay && rowData[column.keyName]) ?
																<td key={`${rowData.studentExamMarkTotalId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />
														))}
													</tr>
												))
												}</>
											:
											<NoDataMsg columnsCount={columnVisibilityData.length + 1} msg={noDataMsg} />}
									</tbody>
								</table>
							</CardBody>
							<PaginationButtons
								className="d-print-none"
								data={items}
								label='items'
								setCurrentPage={setCurrentPage}
								currentPage={currentPage}
								perPage={perPage}
								setPerPage={setPerPage}
							/>
						</Card>
					</>
				) : null}
			</Page>
		</PageWrapper>
	);
};

export default ClassWiseQuizMarkList;
